.date-search-dropdown {
  .btn {
    border-radius: 5px;
  }
  .btn.picker-button {
    display: inline-block;
    width: auto;
    font-size: 12px;
    padding: 5px;
    vertical-align: initial;
    span:first-child.glyphicon {
      font-size: 11px;
      vertical-align: initial;
      margin-left: 4px;
    }
  }
  .label-span {
    color: #000;
    font-size: 12px;
    font-weight: normal;
    &.right {
      margin-left: 5px;
    }
  }

  //hide input which we need to display datepicker
  input {
    width: 0;
    padding: 0;
    height: 0;
    border: none;
    position: absolute;
    margin: 0;
    top: 30px;
    &:not(.right) {
      right: -12px;
    }
    &.right {
      right: 8px;
    }
  }

  ul.uib-datepicker-popup.dropdown-menu li:hover {
    background-color: #fff;
  }
}
