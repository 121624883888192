player-freestyle-videos-home-team-detail > div {
  margin-top: 20px;

  .team-member-avatar {
    width: 60%;
    margin-left: 20%;
    margin-top: 10%;
  }

  .team-member-username {
    color: dodgerblue;
    text-align: center;
    font-weight: bold;
    margin-top: 10%;
  }

  .team-name {
    text-align: center;
    margin-top: 7%;
  }

  .team-country {
    text-align: center;
    margin-top: 10px;
  }

  .flag {
    height: 15px;
    width: 25px;
    margin-left: 5px;
  }
}