fundraiser-leaderboards {
  iframe {
    width: calc(~"100% + 4px");
    margin-left: -2px;
    margin-right: -2px;

    @media (max-width: 991px) {
      width: calc(~"100% + 8px");
      margin-left: -4px;
      margin-right: -4px;
    }
  }
}