terminate-user-dialog > div.modal-dialog {
  .modal-content.terminate-dialog {
    border-radius: 8px;

    button.btn {
      width: 150px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 14px;
    }

    .dialog-text {
      margin-top: 20px;
      text-align: center;
    }

    .dialog-buttons {
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: center;
    }
  }

}