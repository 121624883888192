make-pledge-buttons > div {
  .suggested-amount-holder {
    @media (max-width: 1199px) {
      padding: 0 5px;
    }
  }

  button.btn.btn-primary.make-pledge {
    background-color: #ff8827;
    border-color: #e47a24;
    font-size: 20px;
    padding: 15px;
    width: 100%;
    font-weight: bold;
    height: 100%;
    white-space: normal;
    word-break: break-word;

    &.suggested-amount-pledge {
      font-size: 13px;

      @media (max-width: 1199px) {
        font-size: 12px;
      }
    }
  }
}
