html-code-preview > div {
  button.btn {
    border-radius: 4px;

    &.btn-primary.cancel {
      color: dodgerblue;
      border-color: dodgerblue;
      background-color: #fff;
      font-weight: bold;
    }
  }

  textarea.form-control[readonly] {
    resize: none;
    border-radius: 4px;
    margin-top: 20px;
    height: 150px;
    background: #fff;
  }
}