.topya-tv-widget.widget-mobile {
  div[class^="col-"], div.container, div.container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }

  div.row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tv-header {
    border-bottom: 1px solid #6a6a87;
    height: 130px;
    & > div {
      height: 60px;
    }
    .topya-tv-logo, .primary-sponsor-logo {
      margin-top: 30px;
    }

    .dropdown-menu {
      width: 100%;
    }

    .channel-name {
      width: 85%;

      &.full-width {
        width: 100%;
        text-align: left;
      }
    }

    .channel-controls {
      text-align: left;
      & > span {
        margin-top: 30px;
      }
    }
  }

  .videos-holder {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
    &.scrollable-holder {
      -webkit-overflow-scrolling: touch;
    }
  }

  .tv-current-video {
    border-bottom: 1px solid #6a6a87;

    .tube-info-holder {
      min-height: 40px;
      .tube-info {
        max-width: calc(~"80% - 40px");
      }
    }
  }

  .tv-footer {
    .get-app-buttons {
      padding-left: 5px;
      padding-right: 5px;
      text-align: left;
      font-size: 12px;
      .get-app-text {
        vertical-align: sub;
      }

      .btn {
        &:not(:first-of-type) {
          margin-left: 0;
        }
        border-radius: 8px;
        float: right;
        .btn-text-lg {
          font-size: 6pt;
        }
      }
    }

    .channel-sponsors {
      & > div {
        height: 60px;
        img {
          margin-top: 30px;
          max-height: 60px;
        }
      }
    }
  }
}
