.supportInfoWrapper {
  background-color: #F1F0EF;
  text-align: center;
  .header {
    padding: 15px 0;
    background-color: #706E69;
    color: white;
    font-weight: bold;
  }

  .region {
    background-color: #E4E3DF;
    padding: 10px 0;
    font-weight: bold;
    color: black;
  }

  .content {
    text-align: center;
    color: #706E69;
    transition: all ease-in 400ms;
    word-break: break-all;
    .info {
      margin: 5px 0;
      display: block;
    }
  }

  @media (min-width: 700px) and (max-width: 1300px) {
    font-size: smaller;
  }

  .headerRegister {
    background-color: #3C69A4;
  }
}

.is-mobile .supportInfoWrapper {
  font-size: 25pt;
}
