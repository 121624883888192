player-subscriptions-list-header > div {
  width: 100%;
  border: 1px solid #E5E5E5;
  background: #fff;
  padding: 17px 24px;
  border-top: none;

  &.mobile {
    border-width: 2px;
    padding: 5vw 5vw;
    border-top: 2px solid #e5e5e5;
    margin-top: 7.5vw;
    border-radius: 0;
  }
}