.modal .modal-content.decline-modal {
  .modal-header {
    background-color: #db524b;
    padding-top: 25px;
    padding-bottom: 25px;
    color: #fff;

    h4 {
      display: inline-block;
    }

    .close {
      display: inline-block;
      color: #fff;
      opacity: 1;
      margin-top: 0;
    }
  }

  .modal-body h4 {
    color: #424242;
  }

  .btn {
    border-radius: 4px;
    @media(max-width: 1480px) {
      font-size: 14px;
    }
  }

  textarea.form-control {
    height: 120px;
    resize: none;
    &[disabled] {
      border: 1px solid #fff;
      background-color: #fff;
      cursor: default;
    }
  }
}
