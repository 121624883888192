my-fundraisers-list-item > div.container-fluid {
  background-color: #fff;
  padding: 30px 0 15px 0;
  &:hover {
    opacity: .8;
  }

  @media (max-width: 767px) {
    padding: 15px 0;
  }

  h4 {
    margin: 15px 0 0 15px;
    width: ~"calc(100% - 80px)";
    display: inline-block;

    @media (max-width: 767px) {
      margin: 15px 15px 0 15px;
    }
  }

  img {
    margin-top: 15px;
    margin-right: 30px;
  }

  hr {
    border-width: 2px;
    margin-top: 30px;
  }
}