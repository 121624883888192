.organization-details-tab-content {
  margin-top: 40px;
}

.organization-details {
  .title {
    color: #4A4A4A;
    font-weight: bold;
    margin-top: 6%;
  }

  .page-tabs {
    margin-top: 6%;
  }

  .btn {
    border-radius: 4px;
  }

  .organization-details-tab-content {
    margin-top: 6%;
  }
}
