.mobile-video-player {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  video {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    //fix for broken controls on orientation change in android
    @media all and (orientation: landscape) {
      width: calc(~"100% - 1px");
    }
    &.landscape {
      width: calc(~"100% - 1px");
    }
  }

  .customer-logo {
    position: absolute;
    width: 15%;
    left: 2%;
    bottom: 35%;
  }

  .topya-logo {
    position: absolute;
    width: 20%;
    bottom: 35%;
    right: 2%;
  }
}
