skill-status-indicator .skill-status {
  img {
    width: 25px;
  }
  h5 {
    display: inline-block;
    margin-left: 6%;
    font-weight: bold;
  }
  &.ready {
    color: #72A936;
  }

  &.pending-approval {
    color: #F8B32C;
  }

  &.approved {
    color: dodgerblue;
  }

  &.edits-required {
    color: #DA1F22;
  }
  &.in-progress {
    color: #1ca4b4;
  }
}