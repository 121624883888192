.app-video-player {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;

  videogular {
    position: absolute;
    width: 100%;
    height: 100%;

    .logo-watermark {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 25%;
      max-width: 150px;
    }
  }

  .pending-thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }

  .error-status-holder span {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 40%;
    text-align: center;
    font-weight: bold;
    color: red;
  }
}
