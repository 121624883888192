skill-scoring-form-fields > div {
  label {
    font-weight: bold;
    margin-right: 10px;
    width: 120px;
  }

  select.form-control {
    display: inline-block;
    width: 150px;
  }

  radio-button {
    & > div.radio-button {
      width: 150px;
      display: inline-block;
      margin-top: 10px;
    }

    &:first-child > div.radio-button {
      margin-right: 15px;
    }
  }
}