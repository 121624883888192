@media (min-width: 1441px) and (max-width: 1920px) {
    .container {
        width: 78vw;
        margin: 0 auto;
    }

    input.search {
        width: 80%;
        height: 4vh;
        font-weight: normal;
        border-width: 1px;
        padding-left: 1.5vw
    }

    .dl-horizontal dt {
        width: 35%;
        margin-right: 15px;
    }

    .dl-horizontal dd {
        margin-left: 45px;
        font-size: 14px;
    }
}

@media (min-width: 1280px) and (max-width: 1440px) {
    .container {
        width: 90vw;
        margin: 0 auto;
    }

    input.search {
        width: 80%;
        height: 4.5vh;
        font-weight: normal;
        border-width: 1px;
    }
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .container {
    width: 95vw !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .container {
        width: 98%;
    }

    .logo {
        width: 120%;
    }

    .dl-horizontal dt {
        width: 35%;
        margin-right: 0px;
    }

    .dl-horizontal dd {
        margin-left: 45px;
        font-size: 12px;
    }
}
