.overflow-visible {
  overflow: visible !important;
}

.no-margin {
  margin: 0 !important;
  padding:0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-sm {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.textarea-noresize {
  resize: none;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

[class*="inner-scroll"] {
  overflow-y: auto;
  overflow-x: hidden;
}

.inner-scroll-100 {
  max-height: 100px;
}

.inner-scroll-150 {
  max-height: 150px;
}

.inner-scroll-200 {
  max-height: 200px;
}

.inner-scroll-250 {
  max-height: 250px;
}

.inner-scroll-300 {
  max-height: 300px;
}

.inner-scroll-400 {
  max-height: 400px;
}

.inner-scroll-550 {
  max-height: 550px;
}

.relative {
  position: relative;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.image-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center-x, .round-image-holder .absolute-center-x {
  left: 50%;
  transform: translate(-50%, 0);
}

.absolute-center-y, .round-image-holder .absolute-center-y {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-center {
  margin-left: 50%;
  transform: translateX(-50%) !important;
}

.float-left {
  float: left;
}

.vertical-align-middle {
  vertical-align: middle;
}

.button-distance {
  padding-top: 0.5%;
  padding-bottom: 2%;
}

.button-distance-top {
  margin-top: 2%;
}

.button-distance-top-register {
  margin-top: 1%;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-right-padding {
  padding-right: 0 !important;
}

.padding-right-10 {
  padding-right: 10px;
}

.disabled-item {
  opacity: 0.4;
  cursor: default;
}

.normal-item {
  opacity: 1;
  cursor: pointer;
}

.transparent {
  opacity: 0;
  cursor: default;
}

.padding-bottom-80 {
  padding-bottom: 80px
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.float-right {
  float: right;
}

.no-top-border {
  border-top: none !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-right-10 {
  margin-right: 10px;
}

.inline-table {
  display: inline-table;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.no-disabled-opacity:disabled {
  opacity: 1;
}

.top-200 {
  top: 200px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right;
}

.vertical-align {
  &.text-top {
    vertical-align: text-top;
  }
}

.form-control:disabled.disabled-text-input {
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: default;
}

.full-width {
  width: 100%;
}

.dropdown-menu.rightmost-dropdown {
  @media (max-width: 1199px) {
    left: auto;
    right: 0;
  }
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-100 {
  padding-top: 100px;
}

.rounded-border-4 {
  border-radius: 4px !important;
  overflow: hidden;
}

.rounded-border-8 {
  border-radius: 8px !important;
  overflow: hidden;
}

.row-margin-30 {
  margin-right: -30px;
  margin-left: -30px;
}

.uppercase {
  text-transform: uppercase;
}

.checkbox-group {
  margin-top: 25px;
  position: relative;
  overflow: hidden;

  .checkbox-holder {
    position: absolute;
    height: 34px;
    width: 34px;
    &.green-background {
      background-color: #5fd842;
    }
    &.gray-background {
      background-color: #eee;
    }
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 5px;
  }

  label {
    margin-left: 34px;
    width: calc(~"100% - 60px");
  }
}

.horizontal-scroll-holder {
  min-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.font-bold {
  font-family: 'AvenirNextLTPro Bold';
}

.modal-auto-width .modal-lg,
.modal-auto-width .modal-md,
.modal-auto-width .modal-sm, {
  width: 100%;
}
