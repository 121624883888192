signup-invite-code > form {
  width: 100%;
  margin: 64px 0;
  display: grid;

  span.view-title {
   color: #333333;
   font-size: 28px;
   font-weight: 900;
   letter-spacing: 0;
   line-height: 38px;
   display: block;
  }

  span.helper-label {
    color: #999999;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 20px;
    display: block;
  }

  error-message > div {
    margin-top: 24px;
  }

  text-input > span.label {
    margin-top: 40px;
  }

  div.buttons-holder {
    margin-top: 40px;

    & > button.btn {
      width: 170px;
    }

    @media (max-width: 1274px) and (min-width: 1200px) {
      & > button.btn {
        width: 116px;
      }
    }

    @media (max-width: 445px) {
      & > button.btn {
        width: 100%;

        &.cancel {
          margin-bottom: 24px;
        }
      }
    }
  }

  span.skip-step {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 32px;
    text-align: right;

    &:hover {
      text-decoration: underline;
    }
  }

  &.mobile {
    span.view-title {
      margin-top: 12.5vw;
      font-size: 8.75vw;
      line-height: 11vw;
    }

    & > span.helper-label {
      font-size: 5vw;
      line-height: 7.5vw;
      margin-top: 2.5vw;
    }

    error-message > div {
      margin-top: 7.5vw;
    }

    text-input > span.label {
      margin-top: 12.5vw;
    }

    div.buttons-holder {
      margin-top: 12.5vw;

      & > button.btn.btn-primary {
        width: 100%;
        font-size: 5vw;
        line-height: 7.5vw;
        border-radius: 1.25vw;
        padding: 2.5vw 5vw;

        &:not(.cancel) {
          margin-top: 5vw;
        }
      }
    }

    span.skip-step {
      font-size: 5vw;
      line-height: 7.5vw;
      margin-top: 10vw;
      float: none;
      transform: translateX(-35%);
      display: block;
    }
  }
}