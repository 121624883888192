my-fundraiser-promotion > div.container-fluid {
  margin-top: 30px;
  @media (min-width: 768px) {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
  p {
    font-size: 16px;
  }
  a.btn {
    border-radius: 4px;
    width: auto;
  }
}