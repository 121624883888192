parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid {
  padding: 0;

  & > div.form-fields-holder {
    padding: 24px;
    padding-bottom: 40px;
    background-color: #fff;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    & > div.form-column {
      display: block;

      @media(max-width: 1199px) {
        width: 100%;
      }

      @media(min-width: 1200px) {
        width: 50%;
      }

      p.helper-label {
        color: #999;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }

      button.btn.btn-primary,
      text-input > span.label {
        margin-top: 24px;
      }

      div.or {
        margin: 40px 0;

        & > span.or-divider {
          display: inline-block;

          &.or-divider-text {
            color: #999;
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 24px;
            margin: 0 24px;
            vertical-align: text-top;
          }

          &.or-divider-left,
          &.or-divider-right {
            width: calc(~"50% - 40px");
            border: 1px solid #D8D8D8;
          }
        }
      }
    }
  }

  & > button.btn {
    width: auto;
    margin-top: 24px;

    &:not(.cancel) {
      margin-left: 16px;
    }
  }

  success-message > div,
  error-message > div {
    margin-bottom: 24px;
  }

  &.mobile {
    & > div.form-fields-holder {
      padding: 7.5vw;
      padding-bottom: 12.5vw;

      & > div.form-column {
        width: 100%;

        p.helper-label {
          font-size: 4.4vw;
          line-height: 6.6vw;
        }

        button.btn.btn-primary,
        text-input > span.label {
          margin-top: 7.5vw;
        }

        button.btn.btn-primary {
          font-size: 5vw;
          line-height: 7.5vw;
          padding: 2.5vw 5vw;
          border-radius: 2px;
        }

        div.or {
          margin-top: 12.5vw;

          & > span.or-divider {
            &.or-divider-text {
              font-size: 5vw;
              line-height: 7.5vw;
              margin: 0 7.5vw;
              vertical-align: middle;
            }

            &.or-divider-left,
            &.or-divider-right {
              width: calc(~"50% - 120px");
              border: 2px solid #D8D8D8;
            }
          }
        }
      }
    }

    & > button.btn {
      margin-top: 10vw;
      font-size: 5vw;
      line-height: 7.5vw;
      padding: 2.5vw 5vw;
      border-radius: 2px;

      &:not(.cancel) {
        margin-left: 5vw;
      }
    }

    success-message > div,
    error-message > div {
      margin-top: 7.5vw;
      margin-bottom: 7.5vw;
    }
  }
}