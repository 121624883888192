@checkboxGroupWidth: 270px;
.admin-fundraising-form {
  .checkbox-group {
    margin-top: 25px;
    position: relative;
    overflow: hidden;
    width: @checkboxGroupWidth;

    .checkbox-holder {
      position: absolute;
      height: 34px;
      width: 34px;
      background-color: #5fd842;
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 2px;
    }

    label {
      margin-left: 34px;
      width: calc(~"100% - 60px");
    }
  }

  .dropdown {
    width: 190px;
  }

  .pledges-selector {
    .checkbox-group {
      @media (min-width: 810px) {
        margin-left: 20px;
        display: inline-block;
        &:last-child {
          vertical-align: top;
          margin-top: 1px;
        }
      }
      @media (max-width: 809px) {
        &:last-child {
          margin-top: 20px;
        }
      }
      vertical-align: text-bottom;
      margin-bottom: 0;
      margin-top: 0;
      top: -1px;
    }
  }

  .suggested-amounts {
    span {
      @media (max-width: 556px) {
        display: block;
        margin-bottom: 20px;
      }
      @media (min-width: 557px) {
        display: inline-block;
      }
    }
    input {
      display: inline-block;
      width: 100px;
      margin-left: 20px;
      @media (max-width: 556px) {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .radio-button {
    display: inline-block;
    width: @checkboxGroupWidth;
    @media (max-width: 991px) {
      width: 210px;
    }
  }

  textarea {
    resize: none;
    height: 150px;
  }

  input.form-control.donation-goal-input {
    display: inline-block;
    width: auto;
  }
}
