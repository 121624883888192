.contest-team-select-dropdown {
  .header {
    padding: 10px 0;
    hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .scroll-holder {
    margin-top: 15px;
    overflow-y: auto;
  }

  .list-item {
    padding: 11px 0;
    border-bottom: 1px solid #eee;

    span {
      font-weight: bold;
      display: inline-block;
      font-size: 12px;
      width: calc(~"100% - 24px");
    }

    label {
      vertical-align: top;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        right: 5px;
        width: 14px;
        height: 14px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 8px;
        height: 8px;
        background: #40d61f;
        position: absolute;
        top: 3px;
        right: 8px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
  }

  .loading-overlay {
    position: absolute;
    background-color: rgba(220, 220, 220, 0.9);
    text-align: center;
    span {
      transform: translateY(-50%);
      display: block;
      font-weight: bold;
    }
  }
}

.contest-widget-dropdown .contest-team-select-dropdown {
  .header {
    padding: 0;
    h5 {
      font-size: 12px;
    }
  }

  input.form-control {
    padding: 3px;
    height: 28px;
  }

  .scroll-holder {
    margin-top: 10px;
    max-height: 100px;
  }

  .list-item {
    padding: 10px 0;

    span {
      font-size: 10px;
    }
  }

  a {
    font-size: 10px;
  }
}
