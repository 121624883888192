.new-team {
  border: 1px solid #DAD5D5;
  text-align: center;
  background-color:#F7F7FD;
  padding-top: 5%;
  .edit-team-name {
    text-align: left;
    h5 {
      margin-left: 5%;
    }
    input {
      width: 100%;
      height: 30px;
      border-color: #C3C3CA;
    }
  }
  .edit-team-logo h5 {
    text-align: left;
    margin-left: 8%;
  }
  button {
    width: 100%;
  }
  .top-button {
    margin-top: 10%;
  }
  .img-holder {
    width: 100%;
    padding-bottom: 100%;
    background-color: #454656;
    position: relative;
    margin-bottom: 10%;
    margin-top: 2%;
  }
  .team-logo {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
  .gallery-upload {
    margin-top: 35%;
    cursor: pointer;
  }
  .gallery-upload:hover {
    text-decoration: underline;
  }
  .bottom-button {
    margin-top: 90%;
  }
  .error-message {
    text-align: left;
  }

  @media (max-width: 1023px) {
    button {
      font-size: 10px;
      padding-left: 0;
      padding-right: 0;
    }

    .top-button {
      margin-top: 43%;
    }
  }
}
