parent-my-kids-list-item > div {
  width: 100%;
  padding: 12px 15px 11px 15px;
  border: 1px solid #E5E5E5;
  background: #fff;
  border-top: none;

  img.profile-picture {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }

  div.name {
    width: 16%;
    display: inline-block;
    vertical-align: middle;

    & > span {
      display: block;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;

      &.first-last-name {
        font-weight: 900;
        color: #333;
      }

      &.username {
        font-weight: 500;
        color: #999;
      }
    }

    & > img.link-arrow {
      display: none;
    }
  }

  &.mobile {
    padding: 3.75vw 3.75vw;
    border-width: 2px;

    img.profile-picture {
      width: 12.5vw;
      height: 12.5vw;
      margin-right: 3.75vw;
    }

    & > div.name {
      width: 74%;
      position: relative;
      padding-right: 8%;

      & > span {
        font-size: 4.4vw;
        line-height: 6.6vw;
      }

      & > img.link-arrow {
        display: block;
        position: absolute;
        right: 0;
        height: 5vw;
        top: 50%;
        transform: translateY(-50%);

        &.detail-expanded {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }
}