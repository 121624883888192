.yourSkillsPlayerInner {
  margin: 20px 0;
}

.yourSkillsPlayerInner .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}

.yourSkillsPlayerInner .content {
  border: 1px solid #DBDBEA;
  padding: 25px;
  margin-top: 20px;
  height: auto;
}

.yourSkillsPlayerInner h1 {
  font-size: 22px;
  font-weight: bold;
  margin: 3%;
}

.yourSkillsPlayerInner h2.skill {
  font-size: 14px;
  color: #A7A7A8;
}

.yourSkillsPlayerInner .first-div {
  margin-top: 20px;
  border-bottom: 1px solid #F7F7FD;
  padding-bottom: 15px;
}

.yourSkillsPlayerInner .margin-top-10 {
  margin-top: 10px;
}