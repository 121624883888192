.skill-info-component {
  padding-top: 30px;

  .skill-badge {
    max-width: 100%;
    max-height: 80px;
  }

  .skill-stats {
    font-weight: bold;
    font-size: 16px;
    .skill-stats-icon {
      &.glyphicon {
        color: #498FE1;
      }

      margin-right: 5px;
      &:not(:first-child) {
        margin-left: 10%;
        @media(max-width: 1023px) {
          margin-left: 5%;
        }
      }

      img {
        width: 16px;
        height: 16px;
        vertical-align: top;
      }
    }
  }

  .link-to-path {
    cursor: pointer;
    color: dodgerblue;
  }

  h5 {
    word-break: break-word;
  }
}
