signup-payment > form {
  width: 100%;
  margin: 64px 0;
  display: grid;

  span.view-title {
   color: #333333;
   font-size: 28px;
   font-weight: 900;
   letter-spacing: 0;
   line-height: 38px;
   display: block;
  }

  span.explanation-label {
    color: #999999;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    display: block;
    margin-bottom: 16px;
  }

  text-input > span.label {
    margin-top: 24px;
  }

  div.expiry-date-cvv-holder {
    margin-top: 40px;

    & > div {
      width: calc(~"50% - 8px");
      display: inline-block;

      &:nth-child(2) {
        float: right;
      }
    }
  }

  button.btn {
    margin-top: 40px;
  }

  error-message > div {
    margin-top: 24px;
  }

  &.mobile {
    span.view-title {
      margin-top: 12.5vw;
      font-size: 8.75vw;
      line-height: 11vw;
    }

    & > span.explanation-label {
      font-size: 5vw;
      line-height: 7.5vw;
      margin-top: 2.5vw;
    }

    error-message > div {
      margin-top: 7.5vw;
    }

    text-input > span.label {
      margin-top: 12.5vw;
    }

    & > button.btn.btn-primary {
      width: 100%;
      font-size: 5vw;
      line-height: 7.5vw;
      border-radius: 1.25vw;
      padding: 2.5vw 5vw;

      &:not(.cancel) {
        margin-top: 5vw;
      }
    }
  }
}