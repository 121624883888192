.videogular-container-challenge {
  width: 100%;
  height: 130px !important;
  margin: auto;
  overflow: hidden;
}

.videogular-container-skill {
  width: 100%;
  height: 300px !important;
  margin: auto;
  overflow: hidden;
}

.videogular-container-player-skill {
  width: 100%;
  height: 230px !important;
  margin: auto;
  overflow: hidden;
}

.skills-academy-video {
  width: 100%;
  height: 300px !important;
  margin: auto;
  overflow: hidden;
}
.videogular-vote-video {
  height: 150px !important;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.videogular-container-challenge videogular vg-overlay-play .play:before, [videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(.5);
  display: block;
  background-size: 48px !important;
}

.videogular-container-skill videogular vg-overlay-play .play:before, [videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  width: 64px !important;
  height: 64px !important;
  background-size: 48px !important;
}

videogular vg-volume-bar, [videogular] vg-volume-bar {
  transform: scale(0.4, 0.6) !important;
  top: -80px !important;
}

@media (min-width: 1000px) and (max-width: 1281px) {
  .videogular-container-player-skill {
    height: 155px !important;
  }
}

.videogular-container-player-skill videogular vg-overlay-play .play:before, [videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(.5);
  display: block;
  background-size: 48px !important;
}

.videogular-container-fan-challenge {
  width: 100%;
  height: 150px !important;
  margin: auto;
  overflow: hidden;
}

.videogular-container-fan-challenge videogular vg-overlay-play .play:before, [videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(.5);
}
.skills-academy-video videogular vg-overlay-play .play:before, [videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(.8);
  display: block;
  background-size: 48px !important;
}

@media (min-width: 1000px) and (max-width: 1441px) {
  .videogular-container-fan-challenge {
    height: 120px !important;
  }
}
@media (min-width: 700px) and (max-width:1220px)
{
  .skills-academy-video {
    height: 200px !important;
  }
}

@media (min-width: 1221px) and (max-width:1440px)
{
  .skills-academy-video {
    height: 230px !important;
  }
}
.videogular-vote-video videogular vg-overlay-play .play:before, [videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(.5);
  display: block;
  background-size: 48px !important;
}

.videogular-responsive {
  width: 100%;
  height: 100% !important;
  margin: auto;
  position: absolute;
  overflow: hidden;
}
.videogular-container-fan-challenge  videogular vg-overlay-play .play:before, [videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(.5);
  display: block;
  background-size: 48px !important;
}

.videogular-rounded-border {
  border-radius: 8px;
  overflow: hidden;
}

.videogular-bordered {
  &.thin-gray {
    border: 1px solid #ccc;
  }
}

.logo-watermark {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 25%;
}

videogular vg-time-display, [videogular] vg-time-display {
  width: 30px;
  font-size: 12px;
  padding: 0 4px;
  padding-top: 4px;
  @media (max-width: 1280px) {
    width: 25px;
    font-size: 10px;
    padding: 0 2px;
    padding-top: 2px;
  }
}

videogular vg-scrub-bar .container, [videogular] vg-scrub-bar .container {
  padding: 0;
}
