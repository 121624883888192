parent-my-kids-list-item-mobile-detail > div {
  font-size: 4.4vw;
  line-height: 6.6vw;
  border: 2px solid #E5E5E5;
  border-top: none;
  padding: 5vw 3.75vw 0 3.75vw;

  div.detail-group {
    margin-bottom: 10vw;

    span.detail-label {
      color: #999;
    }

    span.detail-value {
      color: #333;
      float: right;
    }

    img.detail-icon {
      width: 7vw;
      height: 7vw;
      margin-right: 3vw;
      display: inline-block;
    }

    div.subscription-status {
      width: 16vw;
      height: 7.5vw;
      border-radius: 5vw;
      color: #FFF;
      font-size: 3.75vw;
      line-height: 5vw;
      text-align: center;
      padding-top: 1vw;
      display: inline-block;
      float: right;

      &.subscription-yes {
        background-color: #6DD400;
      }

      &.subscription-no {
        background-color: #F55151;
      }
    }
  }
}