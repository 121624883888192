signup-orgs-list > div.container-fluid {
  padding: 0;

  spinner > div {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 24px;
  }

  error-message > div,
  text-input > input.form-control {
    margin-top: 24px;
  }

  & > div.list-header {
    display: block;
    width: 100%;
    margin-top: 24px;
    background-color: rgba(229,229,229,0.3);
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 24px 16px 24px;
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  & > div.items-holder {
    max-height: 640px;
    overflow-y: auto;
  }

  &.mobile {
    spinner > div,
    error-message > div,
    text-input > input.form-control {
      margin-top: 7.5vw;
    }

    & > div.list-header {
      margin-top: 7.5vw;
      padding: 5vw 7.5vw 5vw 7.5vw;
      font-size: 4.4vw;
      line-height: 6.6vw;
    }
  }
}