my-fundraiser-bank-account-initial-view > div.container-fluid {
  p {
    font-size: 20px;
    margin-bottom: 30px;
  }
  button.btn.btn-primary.cancel {
    width: auto;
    border-radius: 4px;
    border-width: 2px;
    font-weight: bold;
    margin-top: 10px;
    color: dodgerblue;
  }
  label {
    display: block;
    margin-top: 10px;
    color: #bb2e2e;
  }
}