.video-holder {
  margin: 10px 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.2%;
  &.is-manager {
    background-size: contain;
    background-position: 0, 0;
    background-repeat: no-repeat;
    background-color: #000;
    .uploading-message {
      background-color: rgba(51, 51, 51, 0.75);
    }
  }
}

.video-holder video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.video-holder .coach-border-color {
  border: 1px solid #86C546;
}

.video-holder .player-border-color {
  border: 1px solid dodgerblue;
}

.video-holder .manager-border {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.video-holder .uploading-message {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 25%;
  text-align: center;
}

.video-holder .coach-border-color h4, .video-holder .coach-border-color a {
  color: #86C546;
  font-weight: normal;
}

.video-holder .player-border-color h4, .video-holder .player-border-color a {
  color: dodgerblue;
  font-weight: normal;
}

.video-holder .manager-border h4, .video-holder .manager-border a {
  color: dodgerblue;
  font-weight: normal;
}

.video-holder .uploading-message a {
  font-size: 18px;
  cursor: pointer;
}

.video-holder .video-error-message {
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.video-holder .video-error-message h4 {
  color: #A94442;
  margin-top: 25%;
  font-weight: normal;
}

.video-holder img {
  width: 100%;
  position: absolute;
}

.video-holder .progress {
  margin-bottom: 0;
  border-radius: 0;
  height: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.uploads-info {
  margin-top: 20px;
  padding: 0;
  &.is-admin {
    position: fixed;
    width: 300px;
    bottom: 0;
    left: 15px;
  }
}
