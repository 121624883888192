.votes .vote-text-wrapper {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important;
  overflow: auto !important;
}

.votes .freestyle-text, .votes .top-skills-text {
  color: black;
  font-size: large;
  font-weight: bold;

}

.votes .top-skills-text .top-skills-gray-text {
  font-size: small !important;
  color: lightgray !important;
  margin-left: 5px;
}

.votes .skill-text {
  color: lightgrey;
  font-weight: bold;
}

.votes .challenge-name, .votes .skill-name {
  color: black;
  font-weight: bold;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.votes .btn-primary {
  text-align: center !important;
}

.votes .challenge-wrapper {
  border-bottom: 1px solid #EEEEEE;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.votes .one-video-button {
  position: absolute;
  bottom: 10px;
}

.votes {
  margin: 20px 0 !important;
}

.votes .back-arr {
  margin-right: 10px;
}

.votes .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}

.votes .content {
  margin-top: 10px !important;
  font-weight: bold;
  margin-bottom: 10px !important;
}

.votes .content-wrapper {
  border: 2px solid #DBDBEA;
  background-color: white;
}

.votes .breadcrumb {

}

.votes .chosenType {
  color: #498FE1 !important;
}

.votes .judge-freestyle-dropdown-wrapper {
  border-right: 1px solid #eeeeee;
}

.votes .vs-text {
  text-align: center !important;
  position: relative;
  top: 65px !important;
  font-size: xx-large !important;
  font-weight: bold !important;
}

.votes .voting {
  margin-top: 15px !important;
}

.votes button {
  background-color: #498FE1 !important;
  border-color: #498FE1 !important;
  border-radius: 0px !important;
}

.votes .single-video-vote .button-wrapper {
  position: relative;
  bottom: 1px !important;
}

.votes .label-votes {
  font-weight: bold;
  font-size: medium;
}

.votes .green-ok {
  color: #93CB52;
  position: relative;
  top: 10px;
}

.votes .percentage {
  color: #498FE1 !important;
  font-size: medium;
}

.votes .blue-line-percentage {
  height: 5px !important;
  background-color: #006dcc;
}

.votes .voting-enabled {
  margin-top: 10px !important;
}

.votes .button-wrapper, .votes .vote-percent-wrapper {
  position: absolute !important;
  right: 70px !important;
  bottom: 0px !important;
}

.votes .gray-dropdown {
  color: lightgray !important;
}
