my-fundraisers-block > div {
  .list-item {
    padding: 10px 15px;
    &:hover {
      background-color: #E4E4EF;
    }
    h4 {
      font-weight: bold;
    }
    span {
      color: dodgerblue;
    }
    hr {
      border-top: 1px solid #DAD5D5;
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
}