.skills-ordering-list {
  .item {
    margin-top: 10px;
    padding: 10px;
    &:hover {
      background-color: #deeff5;
    }
  }

  .published {
    color: #72A936;
  }

  .pending {
    color: #F8B32C;
  }

  .approved {
    color: dodgerblue;
  }

  .edits-req {
    color: #DA1F22;
  }

  .alert {
    margin-top: 20px;
  }

  .delete-skill {
    color: #E35659;
  }

  .status-icon img {
    max-width: 100%;
  }

  h5 {
    @media (max-width: 767px) and (min-width: 500px) {
      font-size: 12px;
    }

    @media (max-width: 499px) {
      font-size: 10px;
    }
  }
}
