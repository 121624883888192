edit-pledge-payment {
  h4 {
    display: inline-block;
  }

  div.container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  button.btn, input.form-control {
    border-radius: 4px;
  }

  button.btn {
    width: 150px;
  }

  input.ng-invalid {
    border-color: red;
    background: rgba(255,0,0,0.1);
  }
  input.ng-invalid.form-control:focus {
    border-color: red;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 0, 0, .6)
  }
}