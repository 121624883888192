.manage-skill {
  padding-top: 20px;

  .header {
    color: #4a4a4a;
  }

  .breadcrumb {
    padding-left: 0;
  }

  .btn {
    border-radius: 8px;
    &:focus, &:active:focus {
      outline: none;
    }
  }

  hr {
    border-color: #ccc;
  }

  .buttons-holder {
    min-height: 34px;
  }

  .skill-detail-frame {
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 30px;
    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      text-align: justify;
    }
  }

  .video-holder {
    border-radius: 8px;
    overflow: hidden;
  }

  .skill-stats-right {
    margin-top: 30px;
    h5 {
      line-height: 25px;
    }
  }

  .difficulty-icon {
    height: 20px;
    vertical-align: sub;
    margin-right: 10px;
  }
}
