player-freestyle-detail-footer > div {
  .description-label {
    color: #9f9f9f;
    margin-bottom: 10px;
    margin-top: 4%;
  }

  .points {
    font-weight: bold;

    .points-count {
      color: #000;
    }

    .points-text {
      color: #9f9f9f;
    }
  }
}