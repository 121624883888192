channels-list-item {
  &:nth-child(even) > div {
    background-color: #d8d8d8;
  }
  & > div {
    padding-top: 10px;
    padding-bottom: 10px;
    & > div {
      @media (max-width: 610px) {
        padding-left: 0;
        font-size: 10px;
      }
    }
  }
}