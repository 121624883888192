player-skills-filters > div {
  hr {
    border-top-width: 1px;
  }

  search-form {
    button.btn,
    input.form-control {
      border-radius: 4px;
    }
  }

  manager-column-head > div {
    padding: 0;
  }

  div.header-holder {
    margin-top: 20px;
    margin-bottom: -20px;
  }
}