.path-skills-component {
  manager-column-head {
    & > div {
      padding: 0 11px;
    }
    &:last-child {
      text-align: center;
    }
  }
}
