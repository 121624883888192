.radio-button {
  border-radius: 8px;
  width: 100%;
  border: 1px solid #dae2ea;
  overflow: hidden;
  height: 32px;
  background: #fff;
  .label {
    height: 30px;
    display: inline-block;
    vertical-align: top;
    padding-top: 8px;
    width: 70%;
    text-align: center;
    h5 {
      color: #000;
    }
  }
  .button {
    width: 30%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    height: 30px;
    .circle-outer {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    &.selected {
      background-color: #5fd842;
      .circle-outer {
        background-color: #5fd842;
        border: 2px solid #fff;
        .circle-inner {
          width: 12px;
          height: 12px;
          margin-top: 2px;
          background-color: #fff;
          border-radius: 50%;
          margin-left: 2px;
        }
      }
    }
    &.deselected {
      background-color: #eee;
      .circle-outer {
        background-color: #fff;
        border: 2px solid #dfdfdf;
        .circle-inner {
          display: none;
        }
      }
    }
  }
}
