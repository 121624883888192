manage-product-draggable-paths-list > div {
  margin-top: 30px;

  search-form {
    // TODO remove when classes refactored
    button.btn.btn-default {
      border-radius: 6px;
      background: linear-gradient(#fff 0%, #bbb 10%);
      border-color: #cecece;
      font-weight: bold;
      color: #5d5c5c;
      font-size: 15px;
      letter-spacing: .5px;
      border-width: 2px;
      padding: 10px;
      width: 100%;
      height: 44px;
    }

    input.form-control {
      height: 45px;
      border-radius: 6px;
    }
  }

  div.review-table {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  manager-list-header > div.container-fluid {
    padding: 10px 0;

    manager-column-head > div {
      padding: 0 15px;
      padding-right: 0;
      font-size: 12px;
    }
  }

  manage-product-draggable-paths-list-item {
    &:nth-child(odd) > div {
      background-color: darken(#f2f2f2, 5%);
    }
  }

  li.dndPlaceholder {
    display: none;
  }

  .spinner-holder {
    position: relative;
    width: 100%;
    height: 60px;

    .loading-paths-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%);
    }
  }

  .items-holder {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;

    &.has-scrollbar {
      manage-product-draggable-paths-list-item > div.container-fluid {
        margin-right: -10px;
      }
    }
  }
}