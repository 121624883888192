.menu {
  background: #000033;
  height: 80px;
  padding-top: 10px;
}


#main-dropdown-menu {
  width: 45px;
  height: 45px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  margin-top: 10px;
  margin-left: -15px;
  span {
    position: absolute;
    height: 5px;
    width: 100%;
    background: #498FE1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &.color {
      background: #2C2D45;
    }
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 13px;
    }
    &:nth-child(4) {
      top: 25px;
    }
  }
  &.open {
    span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    width: 36px;
    height: 36px;
    margin-top: 13px;

    span {
      height: 3px;
      &:nth-child(2), &:nth-child(3) {
        top: 10px;
      }
      &:nth-child(4) {
        top: 20px;
      }
    }
  }
}

.mainNavDropDown {
  padding-left: 0;
  list-style: none;
  display: none;
  position: absolute;
  z-index: 9999;
  width: 280px;
  left: 0px;
  li {
    background: #000033;
    a {
      padding: 18px;
      display: inline-block;
      font-size: 16px;
      color: #AAAAAA;
      cursor: pointer;
      text-align: left;
    }
    span {
      top: 22px;
      right: 20px;
      color: #AAAAAA;
    }
    &:hover {
      color: #AAAAAA;
    }
    &.expandable:hover a {
      text-decoration: underline;
    }
  }
  .nav-sub-menu {
    margin-top: -13px;
    margin-bottom: 13px;
    padding-right: 15px;
    list-style-type: none;
  }
}

.logo {
  position: relative;
}

.user-menu {
  img:not(.right-arrow) {
    width: 56px;
    height: 56px;
    margin-right: 10px;
    cursor: pointer;
    @media (min-width: 768px) and (max-width: 1280px) {
      width: 46px;
      height: 46px;
      margin-top: 5px;
    }
  }
  .user-details {
    color: #fff;
    .user-details-name {
      display: block;
      font-size: 13px;
      margin-top: 10px;
      margin-bottom: 5px;
      text-align: right;
    }
    .user-details-country {
      text-transform: uppercase;
      font-size: 13px;
    }
    i {
      right: -10px;
      top: 5px;
    }
  }

  .popover {
    max-width: 300px;
    margin-left: 22px;
    min-width: 300px;
  }

  .popover-content {
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown {
    width: 300px;
    .avatar-main {
      width: 50px;
      border-radius: 25px;
      height: 50px;
    }

    .avatar-small {
      width: 40px;
      border-radius: 20px;
      height: 40px;
    }

    .right-arrow {
      margin-top: 8px;
    }

    .acc-type-switcher {
      margin-bottom: 10px;
      h5 {
        color: #9E9E9E;
      }
    }
  }

  .dropdown h4 {
    color: #000;
  }

  .dropdown h5 {
    margin-top: 5px;
    margin-left: 5px;
  }

  .dropdown button {
    width: 100%;
    margin-top: 20px;
  }
}

.nav-notifications {
  background: url('../img/red_cloud.png') no-repeat;
  width: 26px;
  height: 24px;
  position: absolute;
  margin-left: -10px;
  margin-top: 0px;
  text-align: center;
  color: #fff;
}

.logo {
  width: 100%;
  max-width: 170px;
}
