.skills-academy {
  .back-arr {
    margin-top: 13px;
    margin-bottom: 10px;
  }

  .error-message {
    width: 100%;
  }

  textarea {
    width: 100%;
    resize: none;
    border: 1px solid #DBDBEA;
    overflow-y: auto;
    max-height: 100px;
    margin-top: 10px;
    &:disabled {
      background-color: #fff;
      border: 1px solid #fff;
    }
  }

  ol li {
    font-size: 10px;
  }

  h4 {
    font-weight: bold;
  }

  h6 {
    margin-top: 5px;
    font-weight: bold;
    color: dodgerblue;
  }
}
