parent-my-kids-list-filters {
  & > div {
    display: inline-block;

    span.filter {
      color: #333;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      display: inline-block;
      height: 48px;

      &:not(:first-child) {
        margin-left: 32px;
      }

      &.active-filter {
        font-weight: 900;
        border-bottom: 2px solid #4A90E2;
      }
    }

    &.mobile {
      display: block;
      width: 100%;
    }
  }

  span.mobile {
    a.btn.btn-default {
      height: 12.5vw;
      text-align: left;
      border-width: 2px;
      border-color: #e5e5e5;
      font-size: 5vw;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 7.5vw;
      padding: 2.5vw 5vw;
      color: #333;
      border-radius: 1.25vw;

      span.glyphicon {
        float: right;
        font-size: 32px;
        margin-top: 20px;
        color: #999;
      }
    }

    ul.dropdown-menu {
      width: 100%;
      top: 7vw;

      &::before,
      &::after {
        display: none;
      }

      li {
        font-size: 5vw;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 7.5vw;
        text-align: left;
        padding: 2.5vw 5vw;
        color: #333;
      }
    }
  }
}