.contest-widget.widget-mobile {
  &.padded-top-150 {
    padding-top: 150px;
  }

  div[class^="col-"], div.container, div.container-fluid, &.container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }

  .col-xs-offset-1 {
    margin-left: 8.33333333%
  }

  div.row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-eq-height {
    display: inherit;
  }

  .dropdown-menu {
    width: 100%;
    position: fixed;
    top: 0;
    height: 100%;
  }

  .iframes-holder {
    .tv-widget-col, .leaderboard-widget-col {
      padding: 0;
    }
  }

  .contest-first-sponsor {
    text-align: center;

    span {
      display: block;
    }

    img {
      margin-left: 0;
      margin-top: 5%;
      margin-bottom: 5%;
      max-width: 80%;
    }
  }

  .contest-info {
    .contest-name-and-deadline {
      display: block;
      text-align: center;
      padding-right: 0;
    }

    .contest-stats {
      display: block;
      text-align: center;
      margin-top: 10%;

      .stats-box {
        width: 40%;
        margin: auto 3%;
        padding: 3%;
      }
    }
  }

  .btn {
    font-weight: bold;
    &.btn-join {
      margin-top: 7%;
    }
  }

  .contest-sponsors {
    .sponsors-logo-holder {
      width: 50%;
      &:nth-child(even) {
        text-align: right;
      }
      &:nth-child(odd) {
        text-align: left;
      }

      .sponsors-logo {
        max-width: 70%;
      }
    }
  }

  iframe.full-screen {
    position: fixed;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .contest-ribbon {
    width: 100%;
  }
}
