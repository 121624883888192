parent-my-kids-profile > div.container-fluid {
  padding: 0;

  h3.title {
    color: #333333;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 6px;
  }

  parent-my-kids-profile-tabs > div {
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &.mobile {
    h3.title {
      font-size: 6.25vw;
      line-height: 7.25vw;
    }

    parent-my-kids-profile-tabs > span.mobile {
      margin-top: 7.5vw;
      margin-bottom: 7.5vw;
      display: inline-block;
    }
  }
}

parent-my-kids-profile > spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
}