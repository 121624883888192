.path-info-component {
  padding-top: 30px;

  .content-provider-logo {
    max-width: 100%;
    max-height: 80px;
  }

  .path-stats {
    font-weight: bold;
    font-size: 16px;
    .path-stats-icon {
      &.glyphicon {
        color: #498FE1;
      }

      margin-right: 5px;
      &:not(:first-child) {
        margin-left: 10%;
        @media(max-width: 1023px) {
          margin-left: 5%;
        }
      }

      img {
        width: 16px;
        height: 16px;
        vertical-align: top;
      }
    }
  }

  radio-button > div {
    margin-top: 10px;
    width: 200px;
  }
}
