manage-subcategory > div.panel-body {
  toggle-input {
    display: inline-block;
    margin-left: calc(~"100% - 563px");
  }

  toggle-input > span.label {
    margin-left: 0;
  }

  span.category-name {
    width: 300px;
  }
}