.contest-team-select-dropdown.mobile, .contest-widget-dropdown .contest-team-select-dropdown.mobile {
  .scroll-holder {
    -webkit-overflow-scrolling: touch;
  }
}

.contest-team-select-dropdown.mobile {
  position: absolute;
  width: calc(~"100% - 20px");
  height: calc(~"100% - 20px");
  left: 10px;
  top: 10px;
}
