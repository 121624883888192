my-fundraiser-pledge-list-item > div {
  &.deleted {
    opacity: .6;
    cursor: default;
  }
  &.expanded {
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #d7d7d7;
    hr {
      border-color: rgba(0,0,0,0);
    }
  }
  hr {
    margin-bottom: 0;
  }
  .header {
    padding: 15px 0;
    &.header-expanded {
      background-color: #c4d9f8;
      border-bottom: 1px solid #d7d7d7;
    }
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
}