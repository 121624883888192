resend-donor-receipt-modal .modal-body {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  span.modal-title {
    font-size: 20px;
    display: block;
    margin-bottom: 20px;
  }

  p.receipt-resent-detail {
    font-size: 16px;
  }

  button.btn {
    margin-top: 15px;
    width: 100px;
    border-radius: 4px;
  }
}