parent-my-kids-profile-orgs-and-teams-list > div.container-fluid {
  padding: 0;

  success-message > div {
    margin-bottom: 24px;
  }

  div.content-holder {
    padding: 24px;
    padding-bottom: 40px;
    background-color: #fff;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    spinner > div {
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: 24px;
    }

    info-message > span,
    error-message > div {
      margin-top: 24px;
    }

    div.button-holder {
      width: 100%;
      text-align: right;

      & > button.btn {
        width: auto;

        &:nth-child(2) {
          margin-left: 16px;
        }
      }
    }

    & > div.list-header {
      display: block;
      width: 100%;
      margin-top: 24px;
      background-color: rgba(229,229,229,0.3);
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      padding: 16px 24px 16px 24px;
      color: #999999;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
  }

  & > button.btn.cancel {
    margin-top: 24px;
    width: auto;
  }

  &.mobile {
    div.content-holder {
      success-message > div {
        margin-bottom: 7.5vw;
      }

      padding: 7.5vw;
      padding-bottom: 12.5vw;
      border-width: 2px;
      border-radius: 8px;

      spinner > div,
      info-message > span,
      error-message > div {
        margin-top: 7.5vw;
      }

      div.button-holder {
        text-align: left;

        & > button.btn {
          font-size: 5vw;
          line-height: 7.5vw;
          padding: 2.5vw 5vw;
          border-radius: 2px;
          width: 100%;

          &:nth-child(2) {
            margin-left: 0;
            margin-top: 5vw;
          }
        }
      }

      & > div.list-header {
        margin-top: 7.5vw;
        padding: 5vw 7.5vw 5vw 7.5vw;
        font-size: 4.4vw;
        line-height: 6.6vw;
      }
    }

    & > button.btn.cancel {
      margin-top: 7.5vw;
      width: 100%;
    }
  }
}