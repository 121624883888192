.additional-videos-selector {
  .drag-uploader {
    margin-top: 40px;
    width: 100%;
    padding: 6% 15px;
    text-align: center;
    border: 1px dashed #bbb;
    position: relative;
    &.drag-drop {
      opacity: 0.5;
    }

    .preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 64px;
      opacity: 0.7;
    }

    h2 {
      margin-bottom: 15px;
      font-family: 'AvenirNextLTPro Bold';
    }

    a {
      color: #8b3bf4;
      cursor: pointer;
    }
  }

  .additional-video-settings {
    margin-top: 40px;
    padding: 6% 15px;
    border: 1px dashed #bbb;
    position: relative;
    .seconds-repeat {
      text-align: left;
      margin-top: 30px;
      span:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .glyphicon.glyphicon-remove {
    margin-top: 34px;
    color: #e30000;
  }

  .checkbox-group {
    margin-top: 25px;
    position: relative;
    overflow: hidden;

    .checkbox-holder {
      position: absolute;
      height: 34px;
      width: 34px;
      &.green-background {
        background-color: #5fd842;
      }
      &.gray-background {
        background-color: #eee;
      }
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 5px;
    }

    label {
      margin-left: 34px;
      width: calc(~"100% - 60px");
    }
  }
}
