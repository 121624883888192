my-fundraiser-pledge-list-header > div {
  font-weight: bold;
  margin-top: 20px;
  @media (max-width: 991px) {
    font-size: 12px;
  }
  hr {
    margin: 10px 0 0 0;
    border-top: 2px solid #f5f5f5;
  }
}