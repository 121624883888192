.invite-player-modal {
  background-color: #f7f7fd;
  padding-bottom: 15px;
  .team-code-text {
    color: #f0ad4e;
    font-size: 16px;
  }
  .invite-by-email, .cancel {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .invitation-code {
    padding-top: 13px;
    padding-bottom: 13px;
    width: 100%;
  }
  .modal-body {
    padding-left: 40px;
    padding-right: 40px;
  }
  .buttons-wrapper {
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
  .buttons {
    margin-top: 20px;
    div {
      margin-bottom: 5px
    }
  }
  .invite-by-email-form {
    font-size: 16px;
    .glyphicon-plus {
      font-size: smaller;
    }
    .invite-by-email, .cancel {
      margin-bottom: 10px;
      margin-top: 20px;
    }
    .invited-players {
      margin-top: 20px ;
    }
    .invite-header {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  input {
    width: 100%;
  }
  .btn-link {
    position: relative;
    top: 10px;
    border: none;
    width: auto;
  }
  .invites-background {
    background-color: white;
    padding: 10px 0;
  }

  .invite-buttons {
    margin-bottom: 20px;
  }

  hr {
    margin: 8px 0;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    .buttons-wrapper {
      padding: 0;
    }

    .invite-by-email {
      font-size: 12px;
    }
  }
}
