.skills-academy .path-details {
  .back-arr {
    margin-left: 10px;
  }

  h6 {
    color: #000;
  }

  .organization-logo {
    width: 10%;
    display: inline-table;
    vertical-align: middle;
    .logo-holder {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .organization-name {
    width: 80%;
    margin-left: 2%;
    display: inline-table;
  }

  .path-name {
    width: 100%;
    font-weight: bold;
    font-size: larger;
  }

  .error {
    margin-top: 20px;
  }

  .edit-button, .description-field, .status {
    margin-top: 10px;
  }

  .organization {
    height: 140px;
  }

  .right-border {
    overflow-x: hidden;
    border-right: 1px solid #DBDBEA
  }

  .assign-path {
    padding-top: 10px;
    .assign-img {
      vertical-align: text-top;
    }
  }

  .skills-list {
    margin-top: 30px;
    padding: 0;
    .item {
      margin-top: 10px;
      padding: 10px;
      &:hover {
        background-color: #deeff5;
      }
    }

    .published {
      color: #72A936;
    }

    .pending {
      color: #F8B32C;
    }

    .approved {
      color: dodgerblue;
    }

    .edits-req {
      color: #DA1F22;
    }

    .alert {
      margin-top: 20px;
    }
  }

  @media (max-width: 1360px) {
    h5 {
      font-size: 12px;
    }
  }

  .border-edit {
    border: 1px solid #DBDBEA;
  }

  .border-default {
    border: 1px solid #fff
  }

  .path-buttons-holder {
    padding: 0;
    .row {
      height: 72px;
      position: relative;
    }
  }

  .path-name-input {
    width: 100%;
    height: auto;
    font-weight: bold;
    padding-left: 0px;
  }

  .alert-info {
    margin: 10px 0;
  }

  .delete-skill {
    color: #E35659;
  }

  input:disabled {
    opacity: 0.8;
    border-color: @pageContentBackgroundColor;
    background-color: inherit;
  }

  .panel-default {
    margin: 10px 0;
    border-radius: 0;
  }

  .ui-tree-clickable {
    display: inline-block;
    width: calc(~"100% - 29px");
    padding: 10px 0;
    &:hover h5 {
      text-decoration: underline;
    }
  }

  .angular-ui-tree {
    .tree-node.no-vert-padding {
      padding: 0 10px;
    }

    .btn {
      width: 24px;
    }

    &.no-drag .angular-ui-tree-handle.pointer {
      cursor: pointer;
      &:hover h5 {
        text-decoration: underline;
      }
    }
  }
}
