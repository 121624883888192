signup > div:not(.mobile) {
  width: 100%;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  & > div {
    width: 50%;
    display: inline-block;
    height: 100%;
    vertical-align: top;

    &.left-column {
      padding: 56px 120px 0 152px;
      overflow-y: auto;
    }

    @media(max-width: 1199px) {
      &.right-column {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 265px;
      }

      &.left-column {
        margin-top: 265px;
        padding: 56px 120px 0 120px;
        width: 100%;
        overflow-y: visible;
        max-width: 768px;
        margin-left: calc(~"50% - 384px");
      }
    }

    @media (max-width: 767px) {
      &.left-column {
        padding: 56px 32px;
        margin-left: 0;
      }
    }

    @media (max-width: 445px) {
      &.left-column {
        padding: 56px 8px;
      }
    }
  }
}

signup > div.mobile {
  width: 100%;

  & > div.form-holder {
    padding: 10vw 6.25vw;
  }
}