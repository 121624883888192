.review .review-table .freestyle-review-detail .review-video {
  height: 385px;
}

.freestyle-review-detail {
  videogular, videogular video {
    border-radius: 10px;
  }

  button.btn,
  input.form-control {
    border-radius: 4px;
  }

  video-with-download-links {
    button.btn {
      border-radius: 8px;
    }
  }

  .controls-container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .video-creator-name {
    margin-left: 7px;
  }

  .review-detail {
    margin-left: -29px;
    margin-right: -29px;
    margin-top: -5px;
    padding: 30px 0;
    background-color: #fff;
  }

  .active-video-header {
    color: dodgerblue;
  }

  .active-video-runner-icon {
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    top: 25px;
    left: 5px;
    width: 45px;
    height: 45px;
    z-index: 300;
  }

  .nomination-star img {
    width: 25px;
    margin-bottom: 5px;
  }

  .bordered-video {
    border: 5px solid dodgerblue;
    border-radius: 20px;
  }
}

.freestyle-revision-modal {
  .modal-header {
    background-color: #db524b;
    color: #fff;
  }

  button.btn {
    border-radius: 4px;
  }

  .glyphicon {
    color: #fff;
  }

  label {
    font-weight: normal;
  }

  h3 {
    font-weight: bold;
  }

  input[type="radio"] {
      display:none;
  }
  input[type="radio"] + label {
      color: #292321;
      font-size:14px;
  }
  input[type="radio"] + label span {
      display:inline-block;
      width:19px;
      height:19px;
      margin:-1px 4px 0 0;
      vertical-align:middle;
      cursor:pointer;
      -moz-border-radius:  50%;
      border-radius:  50%;
      border: 1px solid #ccc;
  }

  input[type="radio"] + label span {
       background-color:#fff;
  }

  input[type="radio"]:checked + label span{
       background-color:#84c542;
  }

  input[type="radio"] + label span,
  input[type="radio"]:checked + label span {
    -webkit-transition:background-color 0.4s linear;
    -o-transition:background-color 0.4s linear;
    -moz-transition:background-color 0.4s linear;
    transition:background-color 0.4s linear;
  }
}

.freestyle-review-table-header manager-column-head > div {
  padding: 0;
}

.review .review-table .review-row-odd .freestyle-review-detail .review-detail {
  border-bottom: 1px solid #E5E5E5;
}
