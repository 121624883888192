my-fundraisers-players-block-item {
  div.player-detail {
    padding: 15px 10px;

    img.player-avatar {
      height: 54px;
    }

    span.glyphicon {
      margin-top: 16px;
    }

    span.details-title {
      margin-left: 10px;
    }
  }

  &:not(:last-child) hr {
    border-top: 1px solid #DBDBEA;
    margin: 0;
  }

  &:last-child hr {
    display: none;
  }

  div.player-fundraisers {
    padding: 0 10px;

    div.player-fundraiser {
      padding: 10px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #dbdbea;
      }
    }
  }
}