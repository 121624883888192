header-bar > div {
  font-weight: bold;
  color: #fff;
  text-align: center;
  min-height: 50px;
  margin-top: 20px;
  background: #003;
  letter-spacing: 1px;

  &.side-block-header {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    text-transform: uppercase;
  }

  &:not(.side-block-header) {
    padding-top: 10px;
    font-size: 20px;
  }

  a, a:hover, a:focus {
    color: #fff;
    text-decoration: none;
  }
}