.path-filters-modal {
  .selected-item-icon {
    width: 16px;
    vertical-align: sub;
  }

  .modal-body {
    max-height: 250px;
    overflow-y: auto;
  }
}
