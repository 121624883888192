.contest-navbar {
  margin-top: 0;
  left: 0;
  top: 0;
  height: 100px;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-size: 9pt;
  border: none;
  position: absolute;
  .row.padded-row {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sponsors-height-holder {
    min-height: 60px;
  }
  .logos-holder {
    z-index: 11;
  }
  .logo {
    max-width: 112px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .header-background-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0,0,51,0.6);
  }
  &.docked {
    position: fixed;
    .header-background-holder {
      background-color: #003;
    }
  }

  &.docked-add, &.docked-remove {
    opacity: 0;
    -o-transition: opacity .5s;
    -ms-transition: opacity .5s;
    -webkit-transition: opacity .5s;
    -moz-transition: opacity .5s;
    transition: opacity .5s;
  }
  &.docked-add-active, &.docked-remove-active {
    opacity: 1;
  }
  width: 100%;
  z-index: 1100;

  .contest-logo-holder {
    position: absolute;
    width: 15em;
    background-color: #fff;
    height: 15em;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 1px 1px rgba(0,0,0,0.1);

    .contest-logo {
      position: absolute;
      width: 10em;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    @media (max-width: 1200px) {
      width: 10em;
      height: 10em;

      .contest-logo {
        width: 8em;
      }
    }

    @media(max-width: 991px) {
      width: 8em;
      height: 8em;

      .contest-logo {
        width: 6em;
      }
    }
  }
}
