.topya-tv-player.player-mobile {
  .player-controls {
    padding: 15pt;
    padding-top: 30pt;
    font-size: 25pt;

    .controls-button {
      font-size: 25pt;
    }

    .video-progress-holder {
      width: 93%;
      margin: 0;
      left: 3.5%;
      position: absolute;
      bottom: 60pt;
      height: 15pt;
    }

    .volume-holder {
      height: 15pt;
      width: 25%;
    }

    span[class*='glyphicon-volume'] {
      margin-left: 7%;
      font-size: 35pt;
      vertical-align: sub;
    }

    span[class*='glyphicon-resize'] {
      margin-top: 8pt;
    }
  }

  .topya-logo {
    bottom: 5%;
    width: 25%;
    &.topya-logo-upper {
      bottom: 20%;
    }
  }

  .customer-logo {
    bottom: 10%;
    &.customer-logo-upper {
      bottom: 25%;
    }
  }

  &.player-fullscreen {
    .topya-logo.topya-logo-upper {
      bottom:70pt;
    }

    .customer-logo.customer-logo-upper {
      bottom: 80pt;
    }
  }
}
