edit-permission-group {
  & > div {
    padding: 10px;
    border: 1px solid #a3a3a3;
    background-color: #fff;
    &.group-name {
      width: 100%;
    }
    &.permission {
      width: 95%;
      margin-left: 5%;
      border-top: none;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  &:last-of-type {
    & > div.permission {
      border-bottom: 1px solid #a3a3a3;
    }
  }
}