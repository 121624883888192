fundraiser-basics-preview > div {
  .contest-name {
    font-family: 'AvenirNextLTPro Bold';
    color: #4b4b4b;
  }

  p {
    font-size: 16px;
  }

  a {
    color: dodgerblue;
    font-size: 16px;
  }

  button.btn {
    width: auto;
    border-radius: 4px;
    min-width: 200px;

    &.btn-primary.cancel {
      background: #fff;
      display: block;
      color: dodgerblue;
    }
  }
}