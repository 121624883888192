roles-dropdown > div {
  &.dropdown {
    width: 250px;
  }
  button.btn {
    border-radius: 4px;
  }

  .dropdown-menu {
    width: 100%;
    &:before {
      left: 5px;
      right: auto;
    }
    &:after {
      left: 3px;
      right: auto;
    }
    li {
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}