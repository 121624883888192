.freestyle {
  .challenge-description {
    margin-top: 15px;

    .name-header {
      color: #9f9f9f;
    }

    .main-challenge-name {
      margin-top: 5px;
      word-wrap: break-word;
    }

    .members-count {
      margin-top: 0;
    }
  }

  .details-header-team {
    margin-top: 2%;
    word-wrap: break-word;
  }

  .details-back-arrow {
    margin-top: 50%;
  }

  .player-stats {
    img {
      display: inline;
      width: 20px;
      height: 20px;
    }

    h5 {
      display: inline-block;
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  .player-info {
    .country-details {
      margin-top: 20px;
    }

    h6 {
      margin-top: 25px;
      @media (max-width: 1023px) {
        font-size: 8px;
      }
    }

    .flag {
      width: 20%;
      margin-left: 5%;
    }
  }
}
