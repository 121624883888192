product-detail-expanded > div {
  border: 1px solid #d7d7d7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  padding-bottom: 50px;

  error-message > div {
    margin-top: 10px;
  }

  h4.product-name {
    font-weight: bold;
    letter-spacing: .7px;
  }

  a.product-link {
    color: dodgerblue;
    margin-top: 15px;
    display: block;
    text-align: left;
    cursor: pointer;
  }

  .logo-holder {
    height: 100px;
    width: 100%;
    margin-top: 40px;
    padding: 0 0 20px 0;

    img.content-provider-logo {
      max-width: 100%;
      max-height: 100%;
    }
  }

  span.stats-detail {
    display: block;
    text-align: left;
    color: #47454e;
  }

  h4.stats-label {
    font-weight: bold;
    letter-spacing: .4px;
  }

  span.inline-stats {
    display: block;

    h4.stats-label {
      display: inline-block;
    }

    span.stats-value {
      font-size: 18px;
      margin-left: 10px;
    }
  }
}
