.contest-ribbon.mobile {
  width: calc(~"100% + 10px");
  left: -5px;
  position: relative;
  height: 110pt;
  .triangle {
    display: none;
  }

  .stats-item {
    span {
      font-size: 10px;
    }
    .icon-group {
      max-width: 77px;
      width: 100%;
      display: inline-block;
      font-size: 11px;
      .icon-text.points-icon {
        top: 37%;
        left: 31%;
      }
      .icon-text.time-icon {
        top: 40%;
        left: 19%;
      }
      .icon-text.video-icon {
        top: 37%;
        left: 21%;
      }
      span.count {
        font-size: 13px;
      }
    }
    &:not(:first-child) .icon-group {
      max-width: 100px;
    }
    &:first-child.text-center {
      text-align: right;
    }
    &.mobile-centered.text-center {
      text-align: center;
      .icon-group .icon-text.time-icon {
        left: 20%;
      }
    }
    &:nth-child(3).text-center {
      text-align: left;
    }
  }
}
