.contest-forms {
  .custom-radio-selection {
    border-radius: 8px;
    width: 100%;
    border: 1px solid #dae2ea;
    overflow: hidden;
    height: 34px;
    .selection-label {
      height: 32px;
      display: inline-block;
      vertical-align: top;
      padding-top: 8px;
      width: 70%;
      text-align: center;
    }
    .selection-button {
      width: 15%;
      padding-top: 5px;
      padding-bottom: 5px;
      display: inline-block;
      height: 32px;
      .circle-outer {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &.selected {
        background-color: #5fd842;
        .circle-outer {
          background-color: #5fd842;
          border: 2px solid #fff;
          .circle-inner {
            width: 12px;
            height: 12px;
            margin-top: 2px;
            background-color: #fff;
            border-radius: 50%;
            margin-left: 2px;
          }
        }
      }
      &.deselected {
        background-color: #eee;
        .circle-outer {
          background-color: #fff;
          border: 2px solid #dfdfdf;
          .circle-inner {
            display: none;
          }
        }
      }
    }
  }
  & .btn {
    border-radius: 8px;
    font-weight: bold;
  }

  textarea {
    height: 150px;
    resize: none;
    content: none;
  }

  input {
    border-radius: 4px;
    &.ng-invalid-pattern {
      border-color: red;
    }

    &.reference-field {
      background-color: #eee;
    }
  }

  &.contest-basics-form {
    radio-input {
      & > div {
        margin-top: 10px;
      }
      &:nth-of-type(2) > div {
        margin-left: 10px;
      }
    }
    padding: 0;
    .delete-image-icon {
      position: absolute;
      left: 70px;
      top: 10px;
      color: #fb061f;
    }
    p {
      word-break: break-all;
    }
    .filter-options-selector {
      .checkbox-group:first-child {
        @media (min-width: 992px) {
          margin-top: 0;
        }
      }
    }
    .team-selector {
      @media (max-width: 767px) {
        margin-top: 25px;
      }
    }
  }

  &.contest-sponsor-form {
  }

  &.contest-submit {
    .header {
      font-family: 'AvenirNextLTPro Bold';
    }
  }
}
