manager-list-header > div.container-fluid {
  background-color: #003;
  color: #fff;
  font-weight: bold;
  padding: 10px 15px;;

  .dropdown {
    background: none;
    .dropdown-menu {
      min-width: 250px;
      &.rightmost {
        @media (max-width: 1080px) {
          right: 0;
          left: auto;
        }
      }
      @media (max-width: 610px) {
        width: 150px;
        &, .btn, h5 {
          font-size: 10px;
        }
      }
    }
  }

  & > div {
    @media (max-width: 610px) {
      padding-left: 0;
      font-size: 10px;
    }
  }
}