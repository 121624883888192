.organization-teams {
  .uib-tab {
    margin: 3% 0px;
  }

  .gear-icon {
    width: 25px;
    margin-left: 20px;
  }

  .members-count {
    padding: 10px 15px;
    border-left: 1px solid #ddd;
  }

  .team-info h5 {
    line-height: 25px;
  }

  .invitation-code {
    margin-top: 10px;
  }

  .team-settings-tabs {
    margin-top: 1%;
  }

  .panel {
    border-radius: 0;
  }

  .members-info {
    margin-top: 3%;
  }

  .coach-profile-image {
    width: 7%;
    border-radius: 50%;
    min-width: 30px;
  }

  .coach-profile-info {
    padding: 35px 30px;
    &.right {
      font-size: 18px;
      img {
        margin: 0 20px;
      }
    }
    a .glyphicon {
      color: #333;
    }
  }

  .coach-names {
    display: inline-table;
    margin-left: 10px;
    .username {
      display: block;
      color: #d5cde4;
    }
  }

  .teams-list {
    max-height: 500px;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
  }

  .teams-list.nav-pills > li {
    width: 100%;
    margin-top: 5px;
    cursor: pointer;
  }

  .panel:first-child {
    margin-bottom: 0;
  }
}
