my-fundraiser-pledges-filters > div {
  margin-top: 30px;
  color: #969696;
  font-weight: bold;

  span.title {
    margin-right: 5px;
  }

  .filter-holder {
    display: inline-block;
    @media (min-width: 768px) and (max-width: 991px) {
      display: block;
      margin-top: 10px;
      pledge-list-filter .main-group {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}