@ribbonAndLogoWidth: 112px;

.contest-ribbon {
  width: @ribbonAndLogoWidth;
  position: absolute;
  right: 30px;
  padding: 20px;
  color: #fff;
  opacity: 1;

  @media (max-width: 991px) {
    right: 0;
    width: 100%;
    padding: 20px 5px 20px 5px;
  }

  &.ng-hide {
    opacity: 0;
  }

  &.ng-hide-add,
  &.ng-hide-remove {
    -o-transition: all linear .5s;
    -ms-transition: all linear .5s;
    -webkit-transition: all linear .5s;
    -moz-transition: all linear .5s;
    transition: all linear 0.5s;
  }

  &.ng-hide-add, &.ng-hide-remove {
    -o-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }

  .stats-item {
    z-index: 3;
    @media (min-width: 992px) {
      padding: 0;
    }
    @media (max-width: 991px) {
      font-size: 11px;
    }
    &:not(:first-child) {
      @media (min-width: 992px) {
        margin-top: 15px;
      }
      @media (max-width: 991px) {
        .icon-group {
          max-width: 100px;
        }
      }
      @media (max-width: 406px) {
        .icon-group {
          max-width: 70px;
        }
      }
    }
    &:nth-child(3).text-center {
      @media (max-width: 991px) {
        text-align: left;
      }
    }
    &:first-child {
      //override bootstrap
      &.text-center.active-contest-item {
        @media (max-width: 991px) {
          text-align: right;
          .row {
            padding-right: 15px;
          }
        }
      }
      .icon-group {
        @media (min-width: 992px) {
          margin-top: 20px;
        }
        @media (max-width: 991px) {
          margin-top: 9px;
        }
      }
    }
    span {
      display: block;
      font-size: 10px;
      @media (max-width: 406px) {
        font-size: 7px;
      }
    }
    span.count {
      font-size: 13px;
      font-family: 'AvenirNextLTPro Bold';
      @media (max-width: 406px) {
        font-size: 10px;
      }
    }

    .icon-group {
      position: relative;
      img.stats-icon {
        width: 100%;
      }
      .icon-text {
        text-align: center;
        position: absolute;
        &.time-icon {
          top: 40%;
          left: 18%;
          width: 55%;
          text-align: center;;
        }
        &.points-icon {
          top: 28%;
          left: 30%;
          width: 50%;
          text-align: center;
        }
        &.video-icon {
          top: 23%;
          left: 22%;
          width: 50%;
          text-align: center;
        }
      }
      @media (max-width: 991px) {
        max-width: 75px;
        width: 100%;
        display: inline-block;
        font-size: 11px;
        .icon-text.points-icon {
          top: 37%;
          left: 31%;
        }
        .icon-text.time-icon {
          top: 40%;
          left: 19%;
        }
        .icon-text.video-icon {
          top: 37%;
          left: 21%;
        }
      }
      @media (max-width: 406px) {
        max-width: 47px;
        .icon-text.time-icon {
          top: 33%;
          left: 19%;
        }
        .icon-text.points-icon {
          top: 34%;
          left: 30%;
        }
        .icon-text.video-icon {
          top: 34%;
          left: 21%;
        }
      }
    }
  }

  .ribbon-background-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .triangle {
    position: absolute;
    @media (max-width: 991px) {
      display: none;
    }

    &.triangle-topright {
      width: 0;
      height: 0;
      border-left: 100px solid transparent;
      right: 0;
    }

    &.triangle-topleft {
      width: 0;
      height: 0;
      border-right: 100px solid transparent;
      left: 0;
    }
  }

  .left-triangle-holder {
    position: absolute;
    bottom: -99px;
    height: 100px;
    width: 51%;
    left: 0;
    overflow: hidden;
  }

  .right-triangle-holder {
    position: absolute;
    bottom: -99px;
    height: 100px;
    width: 51%;
    right: 0;
    overflow: hidden;
  }
}
