edit-product-details-form > div.container-fluid {
  border: 1px solid #d7d7d7;
  background: #fff;
  padding-bottom: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 4px;

  .logo-holder {
    height: 100px;
    width: 100%;
    margin-top: 40px;
    padding: 0 0 20px 0;

    img.content-provider-logo {
      max-width: 100%;
      max-height: 100%;
    }
  }

  form {
    input.form-control {
      margin-bottom: 20px;
      border-radius: 4px;
      max-width: 400px;
      &:nth-of-type(2) {
        margin-bottom: 50px;
      }
    }

    span.input-label {
      font-weight: bold;
    }
  }

  manager-product-availability-preview > span {
    margin-top: 40px;
  }
}