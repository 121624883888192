.signup {
  width: 100%;
  height: auto;
  background-color: #fff;
  text-align: center;
  border: 1px solid #E3E4EB;
}

.signup h3 {
  padding-top: 25px;
  color: #1B1C2C;
  font-size: large;
}

.signup h4 {
  padding-top: 25px;
  color: #1B1C2C;
  font-size: medium;
}

.signup h5 {
  padding-top: 25px;
  color: #A5A5A5;
  padding-bottom: 35px;
  font-size: small;
}

.signup .btn {
  margin-top: 25px;
  border-radius: 0;
  font-size: small;
}

.signup .free {
  color: #F4BD5B;
}
