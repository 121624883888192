.myTeamsDetails {
  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  .teamName a {
    color: black;
  }

  .teamInfo {
    position: relative;
    top: 10px;
  }

  .back-arr {
    margin-right: 10px;
  }

  .myTeamsDetailsBlock {
    background-color: white;
    border-bottom: 1px solid whitesmoke;
    padding: 10px 15px;
    font-size: 12px;
    min-height: 100px;
    .members {
      border-radius: 5px;
      padding: 5px 0;
      color: white;
      text-align: center;
      position: relative;
      top: 20px;
      &.green {
        background-color: #7DD220;
      }
      &.grey {
        background-color: #CFCFCF;
      }
      @media (min-width: 1280px) {
        font-size: smaller;
      }
    }

    .academy {
      color: #CFCFCF;
    }

    .arrowRight {
      position: relative;
      top: 22px;
    }

    @media (min-width: 1441px) {
      .members {
        padding: 6px 14px 6px 14px;
        font-size: 12px;
      }

      .arrowRight {
        top: 20px;
      }

      .teamInfo {
        font-size: 12px;
      }

      h4 {
        font-size: 18px;
      }
    }
  }
}

.coaches-scroller {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
