.video-download {
  .btn {
    border-radius: 8px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  .link-to-copy {
    text-align: center;
    h6 {
      word-break: break-word;
    }
  }

  .locked-video-message {
    h6 {
      color: #f54b74;
      display: inline-block;
      max-width: calc(~"100% - 22px");
    }

    img {
      display: inline-block;
      width: 16px;
      vertical-align: top;
      margin-right: 5px;
    }
  }

  padding-top: 15px;
  padding-bottom: 15px;
}
