.score-freestyles {
  .header {
    margin-top: 3%;
    color: #4a4a4a;
  }

  manager-column-head > div {
    padding-left: 0;

    @media (max-width: 1199px) {
      font-size: 10px;
    }

    @media (min-width: 1200px) and (max-width: 1299px) {
      font-size: 11px;
    }

    @media (min-width: 1300px) {
      font-size: 12px;
    }
  }

  .score-freestyle-detail {
    background-color: #fff;
    border: 1px solid;
    border-top: none;

    textarea {
      resize: none;
      height: 100px;
    }
  }

  .review-row-even .score-freestyle-detail {
    border-color: #e5e5e5;
  }

  .review-row-odd .score-freestyle-detail {
    border-color: #fff;
  }

  .expand-collapse-arrow {
    position: absolute;
    right: 0;
  }

  .glyphicon-star {
    margin-left: 5px;
    font-size: 20px;
    vertical-align: sub;
  }

  .star-gray {
    color: #ccc;
  }

  .star-yellow {
    color: #ff8827;
  }

  select {
    width: 150px;
    margin-left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  .difficulty-selector {
    h5 {
      display: inline-block;
      margin-right: 5px;
    }
  }
}
