player-freestyle-videos-away-video {
  .video-title {
    margin: 10px 0 15px 0;
    text-align: center;
    &.blue {
      color: dodgerblue;
    }
    &.green {
      color: #93CB53;
    }
    &.orange {
      color: #F7A722;
    }
  }

  .runner-icon {
    position: absolute;
    z-index: 100;
    border-radius: 50%;
    border: 2px solid #fff;
    top: 20px;
    left: -0.1vw;
    &.active {
      background-color: #86c442;
    }
    &.ended {
      background-color: #F7A722;
    }
    @media (min-width: 1200px)  {
      width: 10%;
    }
    @media (max-width: 1199px) and (min-width: 992px) {
      width: 12%;
    }
    @media (max-width: 991px) {
      width: 15%;
    }
  }

  .download-video-button {
    border-radius: 8px;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 1023px) {
      font-size: 12px;
    }
  }
}