.org-subscription-edit {
  .subscription-name {
    font-family: 'AvenirNextLTPro Bold';
    color: #4a4a4a;
    margin-top: 40px;
  }

  .edit-subscription-form {
    .existing-channel {
      @media (max-width: 863px) {
        font-size: 10px;
      }
      padding: 10px 0;
      &:nth-child(odd) {
        background-color: #d7d7d7;
      }
      .delete-channel-icon {
        float: right;
        margin-top: 2px;
        color: #e21927;
      }
    }
    .drop-channel-space {
      border: 1px dotted #d7d7d7;
      text-align: center;
      height: 200px;
      &.dndDragover {
        opacity: 0.7;
      }
      h4 {
        font-family: 'AvenirNextLTPro Bold';
        margin-top: 60px;
        color: #4c4c4c;
      }
      li.dndPlaceholder {
        display: none;
      }
    }
  }
}
