public-video > div {
  videogular vg-scrub-bar .container, [videogular] vg-scrub-bar .container {
    padding: 0;
  }

  videogular vg-scrub-bar-current-time, [videogular] vg-scrub-bar-current-time {
    height: 2px;
    padding: 0;
  }

  topya-video-component > div > div {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }
}