parent-activity-feed-card > div.card {
  width: 100%;

  div.content-holder {
    padding: 24px;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  spinner > div {
    margin-top: 24px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  error-message > div, info-message > span {
    margin: 24px 0;
  }
}