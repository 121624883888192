radio-input > div {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px 10px 2px 32px;
  min-width: 150px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  div.input-holder {
    margin-left: -32px;
    padding: 5px 10px;
    background: #eee;
    position: absolute;
    height: 100%;
    width: 32px;
    margin-top: -5px;
    border-right: 1px solid #ccc;
  }

  label {
    margin-left: 10px;
  }
}
