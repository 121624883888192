create-product-add-paths > div {
  h4 {
    color: #6c6c6c;
  }

  manage-product-paths-drop-area > div.container-fluid {
    height: 600px;
  }

  manage-product-draggable-paths-list > div .items-holder {
    max-height: 465px;
  }
}