menu-item {
  & > div.container-fluid {
    padding: 16px 24px;

    & > div.title {
      height: 32px;
      opacity: 0.7;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;

      &.active {
        opacity: 1;
        font-weight: 900;
      }

      & > span {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.mobile {
      padding: 0;

      & > div.title {
         height: 10vw;
         margin-bottom: 5vw;
         font-size: 5vw;
         font-weight: 900;
         line-height: 7.5vw;
      }
    }
  }
}