.manager-team-paths {
  margin-top: 3%;

  .filters {
    .btn {
      width: auto;
      padding-left: 30px;
      padding-right: 30px;
    }
    span {
      margin-left: 2%;
    }
  }

  .path-details {
    border: 1px solid #ababab;
    padding-bottom: 15px;

    .content-provider-logo {
      max-width: 45%;
      max-height: 60px;
    }

    span[class*='glyphicon-menu'] {
      color: #ababab;
    }

    .path-stats {
      font-size: 15px;
      span:last-child {
        margin-left: 5%;
      }
      .difficulty-icon {
        height: 20px;
        margin-right: 5px;
        vertical-align: sub;
      }
      .path-stats-icon {
        &.glyphicon {
          color: #498FE1;
        }

        margin-right: 5px;
        &:not(:first-child) {
          margin-left: 5%;
        }

        img {
          width: 15px;
          height: 15px;
          vertical-align: top;
        }
      }
    }

    .path-skills {
      margin-top: 20px;
      overflow-y: auto;
      max-height: 800px;
      border: 1px solid #e2deef;
      background-color: #f7f5fe;
    }
  }
}
