.freestyle {
  .my-freestyle-filter {
    text-align: center;
  }

  ol li {
    font-size: 10px;
  }

  .page-headers {
    padding: 0 15px;
  }

  .filter {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }

    h5 {
      display: inline;
    }
    img {
      margin-left: 5px;
    }
  }

  .challenge-name {
    color: #9f9f9f;
    margin-bottom: 10px;
  }

  .team-name {
    margin-top: 10%;
  }

  .arrow-right {
    margin-top: 70px;
    float: right;
  }

  .video-parent {
    width: 100%;
    padding-bottom: 56.2%;
    height: 0;
    position: relative;
    background-color: #30303F;
  }

  ul {
    font-family: 'AvenirNextLTPro Regular', sans-serif;
    list-style: none;
    padding: 0;
  }

  li:hover {
    background-color: #f7f7fd;
    cursor: pointer;
  }

  .active-tab {
    color: dodgerblue;
  }

  .active-dropdown {
    color: #000;
  }

  .inactive-tab {
    color: #000;
  }

  .img-holder {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    height: 0;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
    }
  }

  .stats {
    border: 1px solid;
    border-right: none;
    padding: 2%;
    margin-top: 2%;
  }

  .wins {
    border-color: #93CB53;
    color: #93CB53;
  }

  .draws {
    border-color: #F7A724;
    color: #F7A724;
  }

  .losses {
    border-color: red;
    color: red;
    border-right: 1px solid red;
  }

  .btn {
    width: 100%;
  }

  .error-message {
    width: 100%;
    margin-top: 15px;
  }

  .center-header {
    text-align: center;
  }

  @media (max-width: 1023px) {
    .stats {
      margin-top: 0;
    }

    .stats h6 {
      font-size: 7px;
    }

    .challenge-name {
      margin-bottom: 3px;
    }

    .page-headers h4,
    .filter h5
    {
      font-size: 10px;
    }
  }
}
