contest-fundraising-form > div {
  button.btn {
    border-radius: 4px;
    width: auto;
    min-width: 140px;
  }

  organization input.form-control {
    width: 300px;
    border-radius: 4px;
  }
}