toggle-input {
  & > span.label {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 8px;
    padding: 0;
    display: inline-block;
    text-align: left;
    width: auto;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-bottom: 2.5vw;
    }
  }

  & > span.helper-label {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 8px;
    margin-bottom: 8px;
    text-align: left;
    width: auto;
    padding: 0;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-bottom: 2.5vw;
    }
  }

  & > span.toggle-icon {
    &.selected {
      background: url('../img/Icon/Toggle/Blue/radio-checked-blue.png');
      background-position: center;
      background-size: cover;
    }

    &.disabled {
      cursor: default;
      background: url('../img/Icon/Toggle/Gray/radio-unchecked.png');
      background-position: center;
      background-size: cover;
    }

    &.selected.disabled {
      background: url('../img/Icon/Toggle/Gray/radio-checked.png');
      background-position: center;
      background-size: cover;
    }

    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: text-bottom;
    background: url('../img/Icon/Toggle/Blue/radio-unchecked-blue.png');
    background-position: center;
    background-size: cover;

    &.mobile {
      width: 5vw;
      height: 5vw;
      vertical-align: text-bottom;
    }
  }
}