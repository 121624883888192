.user-detail {
  margin-top: 10px;
  margin-bottom: 3%;

  button.btn.btn-default {
    width: auto;
    background: none;
    border: 1px solid;
    border-color: #498FE1;
    color: #2e6da4;
    font-weight: bold;
  }

  hr {
    border-width: 2px;
  }
}
