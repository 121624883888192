.paths-list {
  padding: 0;

  .topya-main-page-content {
    hr {
      border-top: 1px solid #DAD5D5;
    }
  }

  .path-name {
    overflow-x: hidden;
  }

  .path-item {
    padding: 10px 0;
    height: 80px;
    &:hover {
      cursor: pointer;
      background-color: #f7f7fd;
    }
  }
}
