player-skill-score-form > div {
  h5 {
    font-weight: bold;
  }

  textarea.form-control {
    resize: none;
    height: 75px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  div.buttons-holder {
    text-align: center;

    button.btn {
      width: 150px;
      display: inline-block;
      margin: 5px 3%;
    }
  }
}