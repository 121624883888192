manager-profile > div {
  input.form-control {
    border-radius: 4px;
  }

  button.btn {
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
  }
}