parent-subscriptions-card-item > div {
  padding: 18px 0 17px 0;
  border-bottom: 1px solid #e5e5e5;

  & > div {
    display: inline-block;
    width: 25%;
    color: #333;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 21px;
    vertical-align: text-top;
    padding-right: 8px;
  }
}