.addKid {
  padding-left: 0;

  input {
    font-size: 15px;
    margin-bottom: 7%;
    margin-top: 3%;
    height: 34px;
    width: 100%;
    &.invite-code {
      margin-top: 0;
      border-color: dodgerblue;
    }
  }

  .content {
    background-color: #F7F7FD;
    border-color: #D2D2D6;
    border-style: solid;
    border-width: 1px;
  }

  .column {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .col-left {
    padding-right: 30px;
  }

  .col-right {
    padding-left: 30px;
  }

  .uib-datepicker-popup.dropdown-menu {
    padding: 5px;
  }

  .btn {
    width: 100%;
    &.cancel {
      border: 1px solid dodgerblue;
    }
  }

  .errorMsg {
    margin: 0;
    border-radius: 0;
    height: 60px;
    text-align: center;
    position: absolute;
    z-index: 2;
    width: 100%;
  }

  .danger {
    position: absolute;
    bottom: 0;
    font-size: 10px;
    color: #b94a48;
  }

  .or {
    position: absolute;
    padding-top: 5px;
    width: 60px;
    text-align: center;
    right: -30px;
    top: 0;
  }

  .dropdown .disabled {
      opacity: 0.4;
    }

  .nav {
    border: 1px solid #ECECFB;
      height: 34px;
  }

  .navbar-nav > li > a {
    padding: 5px 15px;
    height: 32px;
    cursor: pointer;
    img {
      margin-top: -2px;
    }
  }

  .gender-wrapper {
    margin-top: 3%;
    .danger{
      position: relative;
      font-size: 10px;
    }
  }
}
