calendar-input {
  & > span.label {
    color: #333;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 8px;
    padding: 0;
    display: inline-block;
    text-align: left;
    width: auto;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-bottom: 2.5vw;
    }
  }

  & > span.helper-label {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 8px;
    margin-bottom: 8px;
    text-align: left;
    width: auto;
    padding: 0;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-bottom: 2.5vw;
      margin-left: 2.5vw;
    }
  }

  input.form-control {
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    background-color: #FFF;
    padding-right: 50px;
    height: 40px;
    background-image: url('../img/Icon/Content/Gray/schedule.png');
    background-repeat: no-repeat;
    background-position-x: calc(~"100% - 12px");
    background-position-y: 8px;
  }

  & > div.input-holder {
    position: relative;
    display: block;
    width: 100%;

    &.mobile {
      & > input.form-control {
        font-size: 5.6vw;
        line-height: 7.8vw;
        margin-bottom: 2.5vw;
        height: 10vw;
        border-width :2px;
        border-radius: .6vw;
        padding-right: 15vw;
        background-size: 8vw;
      }

      .bootstrap-datetimepicker-widget button[data-action] {
        font-size: 27px;
        padding: 2.5vw;
      }

      .bootstrap-datetimepicker-widget table th,
      .bootstrap-datetimepicker-widget table td {
        padding-bottom: 2.5vw;
        padding-top: 2.5vw;
        font-size: 32px;
      }

      .bootstrap-datetimepicker-widget table td span {
        font-size: 32px;
        width: auto;
        margin: 16px;
      }
    }

    div.bootstrap-datetimepicker-widget.dropdown-menu {
      width: 100%;
    }
  }

  .dropdown-menu button {
    width: auto;
  }

  ul.dropdown-menu {
    &:before {
      right: 9px;
    }
    &:after {
      right: 7px;
    }
  }

  .local-time-warning.message-hidden {
    opacity: 0;
  }
}