.product-details .arrow-left {
  transform: translateY(30%);
}

.product-details .skill {
  margin-bottom: 10px;
}

.product-details .skill-text {
  padding-top: 1%;
  font-weight: bold;
}

.product-details .skill-text span {
  margin-left: 5px;
  color: #CF011A;
}

.product-details .path-image {
  max-height: 58px;
  max-width: 100%;
}

.product-details .skills-count {
  color: dodgerblue;
}

.product-details .path-name {
  transform: translateY(15%);
  padding-left: 0;
  padding-right: 0;
}

.product-details .price-holder {
  transform: translateY(20%);
}

.product-details .video-parent {
  margin-bottom: 30px;
  width: 100%;
  padding-bottom: 56.2%;
  height: 0;
  position: relative;
  background-color: #30303F;
}

.product-details .description {
  color: #9D9D9D;
  margin-bottom: 30px;
}

.product-details .price {
  border: 1px solid dodgerblue;
  color: dodgerblue;
  padding: 5px 0;
  text-align: center;
  // margin-top: 30%;
}

.product-details .path-text h5 {
  color: #9D9D9D;
  margin-top: 45px;
  margin-bottom: 5px;
}

.product-details .path-text h6 {
  color: dodgerblue;
  margin-top: 5px;
}

.product-details .path-arrow-right {
  margin-top: 65px;
}

.product-details .arrow-left-skill {
  padding-top: 2%;
}

.product-details .skill h5 {
  color: #9D9D9D;
}

.product-details .skill h5, .product-details .skill h4 {
  margin-bottom: 10px;
}

.product-details .skill .lower-description {
  margin-top: 40px;
  margin-bottom: 40px;
}

.product-details .skill span b {
  margin-left: 5px;
}

.product-details .skill .virtual-coach-pic {
  position: absolute;
}

.product-details button {
  width: 100%;
  border-radius: 0;
}

.product-details .btn-primary {
  border-color: #DAE8F9;
  background-color: dodgerblue;
}

.product-details input {
  height: auto;
  width: 100%;
  margin-top: 1px;
  border: 1px solid dodgerblue;
  background-image: url('../img/magnifier_blue.png');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  padding-right: 30px;
}

.product-details .bundle-header {
  background-color: #F7F7FD;
  padding-top: 15px;
  padding-bottom: 45px;
  margin-top: 20px;
}

.product-details .skill-header {
  margin-bottom: 30px;
}

.product-details .recommend-dropdown {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
}

.product-details .recommend-dropdown .plus {
  width: 100%;
  max-width: 25px;
}

.product-details .recommend-dropdown .team-item {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F0F0F0;
}

.product-details .recommend-dropdown .team-item:hover {
  background-color: #F7F7FD;
}

.product-details .popover {
  min-width: 400px;
}

.product-details .recommend-dropdown h6 {
  color: #9D9D9D;
}

.product-details .recommend-dropdown .col-xs-9 {
  max-height: 80px;
  overflow-y: auto;
}

.product-details .recommend-dropdown .team-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-details .recommend-dropdown .team-header h5, .product-details .recommend-dropdown .team-header .col-xs-1 img {
  margin-top: 10px;
}

.product-details .recommend-dropdown .player-item {
  background-color: #F7F7FD;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F0F0F0;
}

.product-details .recommend-dropdown .scroll-holder {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}

.product-details .vid-top-margin {
  margin-top: 30px;
}

.recommend-dropdown .busy-opacity {
  opacity: 0.5;
}
