.modal-dialog {
  position: absolute;
}

.modal-content {
  border-radius: 0px;
  border: none;
  .close {
    margin-top: 5px;
    margin-right: 15px;
    &.close-disabled {
      cursor: default;
      opacity: 0.1;
    }
  }
}

.modal-dialog .error-message {
  margin-top: 10px;
}

.modal-open .modal {
  overflow-y: hidden;
}

@media (min-width: 700px ) and (max-width: 1480px) {
  .modal button {
    font-size: 11px;
  }
}

.app-modal-window {
  .modal-dialog {
    height: auto;
    margin: 0;
    border-radius: 0;
  }

  &.modal-smaller .modal-dialog {
    width: 35%;
    left: 35.5%;
    @media(max-width: 1199px) {
      width: 55%;
      left: 26.5%;
    }
  }
  @media (max-width: 1024px) {
    .modal-dialog, .modal-smaller .modal-dialog {
      width: 73.5%;
      left: 15.5%;
    }
  }

  @media (min-width: 1025px) and (max-width: 1281px) {
    .modal-dialog {
      width: 73.5%;
      left: 15.5% ;
      font-size: 16px;
    }
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    .modal-dialog {
      width: 73.5%;
      left: 15.5%;
    }
  }

  @media (min-width: 1442px) {
    .modal-dialog {
      height: auto;
      margin: 0;
      width: 63%;
      left: 20.4%;
    }
  }
}

.manager-modals {
  &.app-modal-window {
    top: 10%;
    .modal-dialog {
      border-radius: 10px;
      overflow: hidden;
    }
    &.overflow-visible {
      .modal-dialog {
        overflow: visible;
      }
    }
  }

  .modal-content {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }
}

.manager-modals:not(.overflow-visible) .modal-content {
  overflow: hidden;
}
