parent-activity-feed-card-filters > div.btn-group {
  button.btn.btn-default {
    padding: 8px 16px;
    line-height: 24px;
    text-align: left;

    img {
      float: right;
      margin-right: -8px;
    }
  }

  ul.dropdown-menu {
    margin: 0;
    width: 100%;
    border-radius: 0;
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
    z-index: 99;

    &::before,
    &::after {
      visibility: hidden;
    }

    li {
      text-align: left;
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background-color: #d4d4d4;
      }
    }
  }
}