refund-pledge-modal .modal-body {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  span.cancel-icon {
    position: absolute;
    right: 0;
    margin: 10px;
    background-color: red;
    padding: 5px;
    border-radius: 50%;
    color: white;
    &:hover {
      opacity: .6;
    }
  }

  span.prompt-text {
    font-size: 16px;
  }

  button.btn {
    width: 150px;
    border-radius: 4px;
    margin-top: 20px;
    display: inline-block;
    margin: 20px 5px 0 5px;
  }

  .alert.alert-danger .close {
    margin-top: -10px;
    margin-right: 0;
  }

  .input-holder {
    width: 150px;
    display: inline-block;
    margin-left: 5px;
    position: relative;

    span.currency {
      position: absolute;
      left: 8px;
      top: 10px;
      font-size: 12px;
      font-weight: bold;
    }

    input {
      padding-left: 18px;
    }
  }
}