signup-logo-panel > div {
  width: 100%;
  height: 100%;
  background-color: #010033;
  position: relative;

  div.logo-holder {
    width: 100%;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);

    & > img {
      max-width: 230px;
      max-height: 140px;
      display: block;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    & > span.main-label {
       color: #FFFFFF;
       font-size: 24px;
       font-weight: 900;
       letter-spacing: 0;
       line-height: 33px;
       text-align: center;
       display: block;
       width: 100%;
       margin-top: 64px;
    }

    & > span.helper-label {
       color: #FFFFFF;
       font-size: 16px;
       font-weight: 500;
       letter-spacing: 0;
       line-height: 24px;
       text-align: center;
       display: block;
       width: 100%;
    }
  }

  &.mobile {
    height: 75vw;

    div.logo-holder {
      & > img {
        max-width: 60vw;
        max-height: 25vw;
      }

      & > span.main-label {
        margin-top: 10vw;
        font-size: 7.5vw;
        line-height: 10vw;
      }

      & > span.helper-label {
        font-size: 5vw;
        line-height: 7.5vw;
        margin-top: 5vw;
      }
    }
  }
}