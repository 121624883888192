parent-add-kid-invitation-code > div {
  & > button.btn.btn-primary {
    background-color: #4A90E2;
    border-radius: 2px;
    color: #FFFFFF;
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding: 12px;
  }

  &.mobile {
    & > button.btn.btn-primary {
      font-size: 5vw;
      line-height: 7.5vw;
      border-radius: 1.25vw;
      margin-top: 10vw;
      padding: 2.5vw 5vw;
    }
  }
}