.myCoaches, .myCoachesItem {
  padding-top: 15px;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 15px;
}
.myCoaches-wrapper
{
  background-color:white;
}
.myCoaches .dropDownMenu-wrapper
{
  background-color: white;
}
.myCoaches-wrapper
{
  border:none !important;
}
.myCoaches-wrapper .myCoachesItem
{
  border-bottom: 1px solid #f7f7fd;
}
.myCoaches .block {
  margin: 5px 0;
  border: 1px solid #DBDBEA;
}

.myCoaches .block .header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.myCoaches .block .content {
  background: white;
  padding: 0;
}

.myCoachesItem .myCoaches-position {
  vertical-align: middle;
  font-size: 20px;
  height: 38px;
  padding-top: 8px;
  padding-left: 10px;
  margin-right: 5px;
}
.myCoachesItem .myCoaches-position
{
  margin:0 !important;
  margin-left:2px !important;
}

.myCoachesItem .myCoaches-player img {
  width: 38px;
  height: 38px;
  margin-right: 5px;
  float: left;
}

.myCoachesItem .myCoaches-player{
  padding-left: 0;
  padding-right: 0;
}

.myCoachesItem > .myCoaches-player > .myCoaches-player-info{
  display: table-row;
}

.myCoachesItem > .myCoaches-player > .myCoaches-player-info > span {
  display: block;
}

.myCoachesItem > .myCoaches-player > .myCoaches-player-info > span.myCoaches-username{
  color: #9e9e9e;
}

.myCoaches-wrapper {
  padding: 5px;
}
.myCoaches-wrapper .see_more {
  text-align: center;
}

@media (min-width: 1280px) and (max-width: 1440px) {

  .myCoaches .myCoaches-position {
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .myCoaches .myCoaches-position {
    font-size: 16px;
    padding: 0;
    padding-left: 5px;
    padding-right: 2px;
    margin-right: 1px;
  }

  .myCoaches .block .header {
    font-size: 14px;
  }

  .myCoachesItem .myCoaches-player img {
    width: 28px;
    height: 28px;
  }

  .myCoachesItem .myCoaches-name, .myCoaches .myCoaches-username {
    font-size: 11px;
  }
}