player-freestyle-detail-videos > div {
  padding-bottom: 30px;

  .challenge-name {
    color: #9f9f9f;
    margin-bottom: 0;
    margin-top: 4%;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .video-upper-label {
    color: #9f9f9f;
    margin-bottom: 2%;
    margin-top: 4%;
  }

  .video-holder {
    margin: 0;
  }


  .video-icon {
    position: absolute;
    bottom: -20px;
    width: 150px;
    text-align: right;
    right: 5px;
    z-index: 301;
    h5 {
      color: #93CB52;
      display: inline-block;
      margin-right: 10px;
    }

    img {
      width: 40px;
      vertical-align: bottom;
    }
  }

  .versus {
    text-align: center;
    padding-top: 13%;
  }

  .challenge-details {
    padding-top: 13%;

    img {
      display: inline-block;

      &.count-icon {
        width: 25px;
        margin: 0 7.5px;
      }

      &.team-image {
        width: 40px;
      }
    }

    .team-count {
      margin-top: 5%;

      h5 {
        color: #9f9f9f;
        margin-top: 4%;
      }
    }

    h5 {
      display: inline-block;
      margin-left: 10px;
    }
  }

  @media (max-width: 1023px) {
    .video-icon {
      bottom: -15px;

      img {
        width: 20px;
      }

      h5 {
        font-size: 10px;
      }
    }

    .challenge-details {
      padding-top: 8%;
    }
  }
}