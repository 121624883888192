countdown > div {
  position: relative;

  & > div {
    position: absolute;
    background-color: #4b4b4b;
    padding: 5px;
    color: #9a9a9a;
    border-radius: 8px;
    width: 150px;

    & > .time-unit-section {
      display: inline-block;
      margin: 5px;

      & > .time-left {
        display: block;
        font-size: 20px;
      }

      & > .time-unit {
        display: block;
      }
    }

    & > span.splitter {
      vertical-align: top;
      font-size: 20px;
      margin-top: 2px;
      display: inline-block;
    }
  }
}