edit-product-availabilities-list > div.container-fluid {
  padding-top: 30px;

  & > div.row {
    span.item {
      margin-bottom: 15px;
      font-size: 16px;
      display: block;
      float: left;
      clear: left;

      &.selected-item {
        font-weight: bold;
        border-bottom: 2px solid dodgerblue;
        padding-bottom: 5px;
      }
    }
  }

  error-message > div {
    margin-top: 20px;
    margin-left: -15px;
  }
}