.leaderboard-widget.mobile-widget {
  width: 100%;
  .upper-filters-row {
    height: auto;
  }

  .table-header span[class^='col'] {
    font-size: 7pt;
    .header-icon img {
      vertical-align: sub;
    }
  }

  .infinite-scroll-holder {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }
}
