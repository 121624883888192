.colorpicker.dropdown {
  width: auto;
}

.contest-widget-color-formatter {
  @inputBorderRadius: 4px;
  padding-bottom: 50px;

  .header {
    font-family: 'AvenirNextLTPro Bold';
    color: #000;
  }

  .input-group-holder {
    margin-top: 15px;
    border-radius: 4px;
    position: relative;
    width: 100%;
    overflow: hidden;

    input {
      border-radius: @inputBorderRadius;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      width: 34px;
      height: 34px;
      border-bottom-right-radius: @inputBorderRadius;
      border-top-right-radius: @inputBorderRadius;
      padding: 0;
      .selected-color-box {
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        margin-left: 6px;
      }
    }
  }
}
