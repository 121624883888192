.contest-preview {
  h1.header {
    font-family: 'AvenirNextLTPro Bold';
  }

  iframe {
    width: 100%;
  }

  span.app-buttons-preview {
    h4 {
      display: inline-block;
      margin-right: 5px;
      margin-top: 40px;
    }
    button {
      width: auto;
      display: inline-block;
      border-radius: 4px;
    }
  }

  .widget-code-btn {
    float: right;
    width: auto;
    border-radius: 4px;
  }

  textarea.widget-code-area {
    width: 100%;
    height: 120px;
    resize: none;
    &:disabled {
      background: #fff;
    }
  }

  .landing-page-holder {
    position: relative;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 130%;

    iframe {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }

  .checkbox-group {
    width: 320px;
    display: inline-flex;
    margin-left: 20px;
  }
}
