.top-skills-category-selector {
  .btn-group > .btn {
    text-align: left;
    border-radius: 4px;
    height: 44px;
    span.glyphicon {
      margin-top: 5px;
      margin-right: 7px;
    }
  }
  ul.dropdown-menu > li {
    height: 40px;
    input[type="radio"] {
      margin-right: 4px;
    }
    .category-selector {
      position: relative;
      border: 1px solid #a5a5a5;
      float: right;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-shadow: 0px 0px 1px 1px #e4e1e1;
      .green-circle {
        background-color: #84c542;
        border-radius: 50%;
        position: absolute;
        width: 14px;
        height: 14px;
        top: 2px;
        left: 2px;
      }
    }
  }

  ul.dropdown-menu > li, .btn-group > .btn {
    .category-logo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      display: inline-block;
    }

    span {
      display: inline-block;
      color: #676767;
      &.no-icon {
        margin-left: 42px;
        margin-top: 4px;
      }
    }
  }
}
