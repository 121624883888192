.org-subscription-channels-list {
  border: 1px solid #afafaf;

  .table-header {
    background-color: #003;
    color: #fff;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: 768px) and (max-width: 862px) {
      font-size: 11px;
    }

    .dropdown {
      background: none;
      .dropdown-menu {
        width: 250px;
        &.rightmost {
          @media (max-width: 1080px) {
            right: 0;
            left: auto;
          }
        }
        @media (min-width: 768px) and (max-width: 862px) {
          &, .btn, h5 {
            font-size: 11px;
          }
        }
        @media (max-width: 610px) {
          width: 150px;
          &, .btn, h5 {
            font-size: 10px;
          }
        }
      }
    }

    span.glyphicon {
      vertical-align: sub;
    }

    .active-column {
      color: dodgerblue;
    }

    & > div {
      @media (max-width: 610px) {
        padding-left: 0;
        font-size: 10px;
      }
    }
  }

  .inner-scroll-150 {
    background-color: #f5f4f4;
  }

  .table-item {
    padding-top: 15px;
    padding-bottom: 15px;
    @media (max-width: 863px) and (min-width: 768px) {
      & > div {
        font-size: 11px;
      }
    }

    & > div {
      @media (max-width: 610px) {
        padding-left: 0;
        font-size: 10px;
      }
    }

    &:nth-child(even) {
      background-color: #d8d8d8;
    }
    &:nth-child(odd) {
      background-color: #fff;
    }
  }
}
