signup-subscription-summary > div {
  width: 100%;
  margin: 64px 0;
  display: grid;

  span.view-title {
   color: #333333;
   font-size: 28px;
   font-weight: 900;
   letter-spacing: 0;
   line-height: 38px;
   display: block;
  }

  span.helper-label {
    color: #999999;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 20px;
    display: block;
  }

  error-message > div {
    margin-top: 24px;
  }

  spinner > div {
    margin-top: 24px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  dropdown-input > div {
    margin-top: 40px;
  }

  div.table-header {
    height: 56px;
    width: 100%;
    margin-top: 16px;
    border-bottom: 1px solid #e5e5e5;

    & > span.table-title {
      display: inline-block;
      color: #999999;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      margin-top: 28px;
      transform: translateY(-50%);

      &:last-child {
        text-align: right;
      }
    }
  }

  div.product-details {
    min-height: 64px;
    padding: 8px 0;
    border-bottom: 1px solid #e5e5e5;

    & > div.name-description {
      width: 40%;
      display: inline-block;

      & > div {
        & > span.name {
          color: #333333;
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 21px;
          text-transform: uppercase;
        }

        & > span.description {
          color: #999999;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
        }
      }
    }

    & > div.billing {
      width: 30%;
      display: inline-block;

      & > span {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    & > div.qty {
      width: 15%;
      display: inline-block;

      & > span {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    & > div.product-price {
      width: 15%;
      display: inline-block;
      text-align: right;

      & > span {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }

  div.subtotal {
    margin-top: 24px;

    & > span {
       color: #333333;
       font-size: 14px;
       font-weight: 500;
       letter-spacing: 0;
       line-height: 21px;

       &.subtotal-value {
         float: right;
       }
    }
  }

  div.total {
    min-height: 42px;
    border-top: 1px solid #e5e5e5;
    margin-top: 24px;

    & > span {
       color: #333333;
       font-size: 18px;
       font-weight: 900;
       letter-spacing: 0;
       line-height: 25px;
       text-align: right;
       float: left;
       margin-top: 42px;
       transform: translateY(-100%);

       &.total-value {
         float: right
       }
    }
  }

  button.btn {
    margin-top: 40px;
  }

  &.mobile {
    span.view-title {
      margin-top: 12.5vw;
      font-size: 8.75vw;
      line-height: 11vw;
    }

    & > span.helper-label {
      font-size: 5vw;
      line-height: 7.5vw;
      margin-top: 2.5vw;
    }

    dropdown-input > div {
      margin-top: 12.5vw;
    }

    div.product-details-mobile {
      margin-top: 12.5vw;

      & > div.detail-holder {
        padding: 2.5vw 0;
        border-bottom: 2px solid #e5e5e5;

        & > span.label {
          color: #999999;
          font-size: 4.4vw;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 6.6vw;
          padding: 0;
        }

        & > span.value {
          color: #333333;
          font-size: 4.4vw;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 6.6vw;
          float: right;
        }
      }
    }

    div.subtotal {
      margin-top: 7.5vw;

      & > span {
         font-size: 4.4vw;
         line-height: 6.6vw;
      }
    }



    div.total {
      min-height: 12.5vw;
      margin-top: 7.5vw;

      & > span {
         font-size: 5vw;
         line-height: 7.5vw;
         margin-top: 12.5vw;
      }
    }

    button.btn.btn-primary {
      width: 100%;
      font-size: 5vw;
      line-height: 7.5vw;
      border-radius: 1.25vw;
      padding: 2.5vw 5vw;
    }
  }
}