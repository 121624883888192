skill-scoring > div {
  h1 {
    font-family: 'AvenirNextLTPro Bold';
  }

  hr {
    border-top: 2px solid #dcdcdc;
  }

  .scoring-detail-holder {
    &:nth-child(even) {
      background-color: #d7d7d7;
    }
  }

  manager-list-header > div.container-fluid {
    padding: 10px 0;

    manager-column-head > div {
      padding: 0 15px;
    }
  }
}