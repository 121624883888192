my-fundraiser-bank-account-view > div.container-fluid {
  padding-bottom: 50px;
  span {
    display: block;
    margin-bottom: 10px;
    &.account-detail-label {
      font-size: 16px;
    }
    &.account-detail {
      font-size: 19px;
      font-weight: bold;
    }
  }
  button.btn.btn-primary.cancel {
    width: 160px;
    border-radius: 4px;
    border-width: 2px;
    font-weight: bold;
    margin-top: 10px;
    color: dodgerblue;
  }
  p {
    font-size: 16px;
  }
}