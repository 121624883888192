my-fundraiser-pledge-detail > div {
  button.btn {
    width: 100%;
    border-radius: 4px;
  }

  @media (max-width: 991px) {
    font-size: 12px;
  }

  .buttons-holder {
    text-align: center;
    div[class*='col-'] {
      @media (min-width: 1200px) {
        float: none;
        display: inline-block;
      }
    }
  }
}