@pageContentBackgroundColor: #fff;
@coachRecommendedItemImageBackgroundColor: #b9ceaa;
@coachRecommendedItemBackgroundColor: #e0f0d0;
@coachAssignedItemBackgroundColor: #E7EDF8;
@coachAssignedItemImageBackgroundColor: #ced7e6;
@pageTopOffset: 20px;
@disabledItemOpacity: 0.4;
.topya-main-page-header {
  background: #1B1C2C;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
  margin-top: @pageTopOffset;
  width: 100%;
}

.topya-main-page-content {
  border: 1px solid #DBDBEA;
  background-color: @pageContentBackgroundColor;
  padding: 15px;
}

.topya-side-block-header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.big-button {
  height: 60px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn {
  border-radius: 2px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12px 16px;
  border: none;
}

.btn.btn-primary {
  border: none;
  background-color: #4A90E2;

  &:hover, &:active {
    background-color: #306DB2;
    border-color: #498FE1;
  }

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }

  &.cancel {
    border: 2px solid #4A90E2;
    border-radius: 2px;
    background-color: #FFFFFF;
    padding: 10px 14px;
    color: #4A90E2;
  }
}

.btn.btn-success {
  background-color: #6DD400;

  &:hover, &:active {
    background-color: #3AB000;
  }

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }
}

.btn.btn-danger {
  background-color: #E10048;

  &:hover, &:active {
    background-color: #B00028;
    border-color: #E10048;
  }

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }
}

.btn.btn-primary.new-btn-primary {
  min-width: 250px;
  font-weight: bold;
  padding: 10px;
  border-radius: 6px;
  border-width: 2px;
  border-color: #2b8dff;
  font-size: 15px;
  letter-spacing: .5px;
  width: auto;
  background: linear-gradient(#85bdff 0%, dodgerblue 10%);
  &.cancel {
    background: #fff;
    color: dodgerblue;
    border-color: #18a2ff;
  }
}

input.form-control.new-form-control {
  border-radius: 6px;
  border-color: #969696;
  background: linear-gradient(#dcdbdb 0%, #fff 6%);
}

.btn.btn-default.new-btn-default {
  min-width: 250px;
  border-radius: 6px;
  background: linear-gradient(#fff 0%, #bbb 10%);
  border-color: #cecece;
  font-weight: bold;
  color: #5d5c5c;
  font-size: 15px;
  letter-spacing: .5px;
  border-width: 2px;
  padding: 10px;
  width: auto;
}

.breadcrumb {
  padding: 10px 15px;
  margin: 5px 0;
  list-style: none;
  background-color: transparent;
  text-transform: uppercase;
  color: #9D9D9D;
  font-size: 12px;
  border-radius: 0;

}

.breadcrumb a {
  color: #9D9D9D;
}


.breadcrumb li, .breadcrumb span {
  cursor: pointer;
}

.breadcrumb li.active, .breadcrumb span.active {
  cursor: default;
}

.topya-modal-window {
  border: 1px solid #DAD5D5;
  background-color:#F7F7FD;
  padding-bottom: 10px;
}

.modal {
  padding: 0;
}

button.outline {
    border: 1px solid #498FE1;
    padding: 5px 15px;
    margin-top:10px !important;
    height: 30px;
    background: none;
    color: #498FE1;
    font-size: 12px;
    border-radius: 0;
}

body {
  &.hide-loader-bar {
    #loading-bar,
    #loading-bar-spinner {
      display: none;
    }
  }
  &.hide-zendesk-help {
    iframe.zEWidget-launcher {
      display: none;
    }
  }
}
