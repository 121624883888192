product-detail > div {
  .detail-header {
    color: #59595a;
    padding: 10px 0;
    font-size: 16px;

    span.paths-count {
      width: 106px;
      text-align: center;
      display: block;
    }
  }
}