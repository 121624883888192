.teamPlayers {
  .back-arr {
    margin-right: 10px;
    cursor: pointer;
  }

  .playerDetails .big_img {
    width: 102px;
    height: 102px;
  }

  .centered {
    div, span {
      text-align: center;
    }
  }

  .achievements {
    margin-top: 20px;
  }

  .achievements-numbers, .settingsIcon {
    margin-top: 10px;
  }
}

.teamPlayers {
  .clicked {
    color: #488EE0;
  }

  .periods .span {
    cursor: pointer;
  }

  .blackText {
    color: black;
  }

  .greyText {
    color: #b1b1bd;
    padding-bottom: 3px;
  }

  .skill-paths-content, .heading {
    border-top: 1px solid #eeeef2;
    font-weight: bold;
  }

  .skill-paths-content {
    padding: 15px 10px;
  }

  .heading {
    padding: 15px;
  }

  .arrow {
    height: 24px;
    width: 15px;
  }

  .percents-wrapper {
    position: relative;
    top: 7px;
  }

  .arrow-wrapper {
    position: relative;
    top: 8px;
  }

  .skills-wrapper {
    background-color: #f7f7fd;
    margin-bottom: 20px;
    max-height: 601px;
    overflow-x: hidden;
    overflow-y: auto;

    .skill-paths-content {
      border-bottom: 1px solid #DBDBEA;
      margin-bottom: 10px;
    }
  }

  .skillImg {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
  }

  .skillDetails {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: #eeeef2 1px solid;

    & span {
      font-weight: bold;
      padding-left: 10px;
    }

    & .btn.btn-primary {
      width: auto;
    }
  }

  .playerFullName {
    font-weight: bold;
  }

  .playerUsername {
    color: #b1b1bd;
  }

  .achievements-numbers h3 {
    @media (min-width: 900px) and (max-width: 1281px) {
      font-size: 18px;
    }
    @media (min-width: 1282px) and (max-width: 1442px) {
      font-size: 20px;
    }
  }
}

.playerDetails {
  .removePlayer {
    color: #DBDBEA;
    &:hover {
      color: red;
    }
  }
}


.remove-player {
  display: none;
}

.remove-wrapper:hover .remove-player{
  display: inline;
  color: red;
  font-size:smaller;
  margin-left:2px;
}

.delete-player-button {
  height: 40px;
}

.delete-player-blocks {
  margin: 20px;
}
