channel-reorder-sponsors > ul {
  &.non-editable {
    input.form-control[disabled] {
      background-color: #fff;
      border: none;
      box-shadow: none;
      cursor: default;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(0,0,0,0);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(0,0,0,0);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(0,0,0,0);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(0,0,0,0);
    }
  }

  &:not(.non-editable) {
    input.form-control.ng-invalid {
      border-color: red;
    }
  }
}