.yourSkillsInner {
  margin: 20px 0;
}

.yourSkillsInner .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}

.yourSkillsInner .content {
  border: 1px solid #DBDBEA;
  padding: 25px;
  margin-top: 20px;
  height: auto;
}

.yourSkillsInner h1 {
  font-size: 26px;
  font-weight: bold;
  margin: 3%;
}

.yourSkillsInner h4 {
  font-weight: bold;
}

.yourSkillsInner .margin-right {
  margin-right: 10px;
}

.yourSkillsInner span.challenges {
  color: #468CDF;
  font-size: 14px;
}

.yourSkillsInner span.red {
  color: #CE0019;
  font-weight: bold;
}