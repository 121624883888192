subscription-status-indicator > div {
  padding: 8px 16px;
  border-radius: 24px;
  width: auto;
  display: inline;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;

  &.active-subscription {
    background-color: #6DD400;
  }

  &.inactive-subscription {
    background-color: #F55151;
  }

  &.mobile {
    font-size: 3.4vw;
  }
}