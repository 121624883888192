parent-subscriptions-list > div {
  width: 100%;

  h3.title {
    margin-top: 6px;
    color: #333;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
  }

  & > button.btn {
    margin-top: 24px;
    width: auto;
    display: block;
  }

  & > success-message > div {
    margin-top: 24px;
  }

  & > view-title > div {
    margin-top: 24px;
  }

  error-message > div, info-message > span {
    margin-top: 24px;
  }

  div.items-holder {
    max-height: 210px;
    overflow-y: auto;
    background: #e8e8e8;

    &.has-scrollbar {
      & > parent-subscriptions-list-item > div:not(.mobile) {
        & > span.price {
          width: 11%;
        }
      }
    }
  }

  spinner > div {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 24px;
  }

  div.no-subscriptions-message {
    background: #fff;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 40px 0;
    text-align: center;

    & > span {
      color: #999;
      font-size: 20px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 27px;
      display: block;
    }

    & > button.btn {
      width: auto;
      margin-top: 32px;
    }
  }

  &.mobile {
    h3.title {
      font-size: 6.25vw;
      line-height: 7.5vw;
    }

    & > button.btn {
      margin-top: 7.5vw;
      width: 100%;
      font-size: 5vw;
      line-height: 7.5vw;
      border-radius: 1.25vw;
      padding: 2.5vw 5vw;
    }

    & > view-title > div {
      margin-top: 7.5vw;
    }

    div.items-holder {
      max-height: 100vw;
    }

    div.no-subscriptions-message {
      border-width: 2px;
      padding: 12.5vw 7.5vw;
      text-align: center;

      & > span {
        font-size: 6.25vw;
        line-height: 8.5vw;
      }

      & > button.btn {
        margin-top: 7.5vw;
        width: 100%;
        font-size: 5vw;
        line-height: 7.5vw;
        border-radius: 1.25vw;
        padding: 2.5vw 5vw;
      }
    }

    error-message > div, info-message > span, success-message > div {
      margin-top: 7.5vw;
    }
  }
}