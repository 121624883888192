create-product-added-paths-list > div {
  manager-list-header > div.container-fluid {
    padding: 10px 0;

    manager-column-head > div {
      padding: 0 15px;
    }
  }

  manage-product-draggable-paths-list-item {
    &:nth-child(odd) > div {
      background-color: darken(#f2f2f2, 5%);
    }
  }
}
