.yourSkills {
  margin: 20px 0;
}

.yourSkills .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.yourSkills .content {
  border: 1px solid #DBDBEA;
  padding: 25px;
  margin-top: 20px;
  height: auto;
}

.yourSkills h4 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}
.yourSkills .skillsCount
{
  color: dodgerblue;
}
.yourSkills .skillPathBlock
{
  padding-top:10px;
}

.yourSkills hr {
  margin-top: 40px;
}
