.contest-content-view {
  button.btn {
    border-radius: 4px;
  }

  textarea {
    resize: none;
    height: 150px;
    margin-bottom: 80px;
  }

  .how-it-works {
    padding: 30px;
    margin-bottom: 80px;
    border: 1px solid;
    border-radius: 12px;
    background-color: #fff;

    .how-it-works-header {
      color: #ff8827;
      letter-spacing: 3px;
      text-align: center;
      font-family: 'AvenirNextLTPro Bold';
    }

    .how-it-works-body {
      margin-top: 20px;
      p {
        margin-top: 20px;
        text-align: justify;
        word-wrap: break-word;
      }
      h3 {
        margin-top: 20px;
      }
      .how-it-works-content h3 {
        font-family: 'AvenirNextLTPro Bold';
        text-align: left;
      }
    }
  }
}
