parent-profile-picture > div {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;

  &.disabled {
    opacity: .7;

    & > div.overlay {
      cursor: default;
    }
  }

  & > img.profile-picture {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
  }

  & > div.overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, .3);
    text-align: center;
    cursor: pointer;

    & > img.camera-icon {
      margin-top: 50%;
      transform: translateY(-50%);
    }
  }

  &.mobile {
    width: 30vw;
    height: 30vw;

    & > div.overlay {
      & > img.camera-icon {
        width: 7.5vw;
      }
    }
  }
}