.video-popover {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  text-align: center;
  width: 250px;

  h5 {
    margin: 10px 0;
    color: #000;
  }

  h4 {
    color: dodgerblue;
  }

  ul {
    padding-left: 20px;
    text-align: left;
  }

  li {
    color: #A5A5A5;
    margin-bottom: 8px;
  }

  .video {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .assign-team-font {
    @media (max-width: 1360px) {
      font-size: 12px;
    }
    @media (min-width: 1361px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1024px) {
    .save-submit {
      font-size: 11px;
      padding: 0;
    }
  }
}
