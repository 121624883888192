header > div {
  width: calc(~"100% - 232px");
  height: 72px;
  background-color: #fff;
  top: 0;
  left: 232px;
  position: fixed;
  padding: 12px 32px;
  border-bottom: 1px solid #E5E5E5;
  z-index: 100;

  img.menu-icon {
    display: none;
  }

  &.mobile {
    width: 100%;
    left: 0;
    height: 22.5vw;
    padding: 0 9.7vw 0 7vw;
    border-bottom-width: 2px;


    img.menu-icon {
      display: inline-block;
      width: 5.6vw;
      margin-top: 9.4vw;
    }
  }

  header-profile-detail > div {
    float: right;
  }
}
