.my-fundraiser-view {
  min-height: calc(~"100vh - 80px");
  width: 100%;
  background-color: #f7f6fc;
  .back-arrow {
    height: 12px;
    margin-top: -2px;
  }
  .supportInfoWrapper .header {
    padding: 10px 0;
  }
  .support-holder {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .support-and-leaderboards {
    @media (max-width: 767px) {
      padding: 0;
    }
  }

  .admin-side-block {
    margin-top: 20px;
    padding-right: 0;

    @media (max-width: 991px) {
      padding-left: 0;
    }
  }
}