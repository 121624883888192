.parent-verification {
  top: 180px;
  background-color: #F2F2F2;
  .info-text {
    margin-top: 5%;
    margin-bottom: 3%;
  }
  input {
    width: 100%;
    height: 30px;
    font-size: 16px;
  }
  .or {
    margin: 3% 0;
    color: #AEA9A7;
  }
  .btn-primary {
    margin: 5% 0;
  }
  .danger {
    position: absolute;
    font-size: 10px;
    color: #b94a48;
  }
}
