.create-organization {
  color: #484848;
  .header {
    margin: 40px 0;
  }

  .drag-uploader {
    margin-top: 40px;
    width: 100%;
    padding: 6% 15px;
    text-align: center;
    border: 1px dashed #bbb;
    position: relative;
    .delete-image-icon {
      position: absolute;
      left: 70px;
      top: 10px;
      color: #fb061f;
    }
    &.drag-drop {
      opacity: 0.5;
    }

    .preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 64px;
      opacity: 0.7;
    }

    h2 {
      margin-bottom: 15px;
    }

    a {
      color: #8b3bf4;
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 16px;
    }
  }

  @media (min-width: 768px) {
    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
