.freestyle {
  .joined-banner {
    background-color: #000;
    padding: 10px;
    margin-bottom: 20px;

    span {
      color: #93CB52;
    }

    h4 {
      display: inline;
      margin-left: 10px;
    }
  }

  .time-banner {
    background-color: #93cb52;
    color: #fff;
    padding: 15px;

    .stopwatch {
      width: 100%;
    }

    h2 {
      margin-top: 2%;
      display: inline-block;
    }

     h4 {
      display: inline-block;
      margin-left: 5px;
    }
  }

  .gray-text {
    color: #9f9f9f;
    margin-top: 30px;
  }

  .joined-challenge-name {
    margin-bottom: 30px;
  }

  .challenger-info {
    margin-top: 25%;

    .team-name {
      margin-top: 3%;
    }

    .lower {
      margin-top: 3%;

      h6 {
        color: #9f9f9f;
      }
    }
  }

  .joined-lower {
    margin-bottom: 7%;
  }

  .submit-video {
    margin-top: 60%;
    margin-bottom: 3%;
  }
}
