.player-team {
  .arrow-left {
    padding-top: 7%;
    text-align: center;
  }

  .team-img {
    margin-top: 10%;
  }

  .img-holder {
    padding-bottom: 100%;
    width: 100%;
    position: relative;
  }

  .team-logo {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .team-name {
    margin-top: 10%;
    word-wrap: break-word;
  }

  .team-status {
    color: #9f9f9f;
  }

  .user-icon {
    width: 20px;
    margin-left: 5px;
    margin-top: 3px;
  }

  .edit-button {
    margin-top: 30%;
  }

  .invite-label {
    text-align: right;
    h5 {
      margin-top: 7px;
    }
  }

  input {
    width: 100%;
    border: solid 2px #DBDBEA;
    border-radius: 5px;
    height: 30px;
    background-image: url('../img/magnifer_icon.png');
    background-repeat: no-repeat;
    background-position: left 7px center;
    padding-left: 30px;
  }

  .team-members-count {
    text-align: center;
    h5 {
      display: inline-block;
      margin-top: 7px;
    }
    img {
      display: inline;
    }
  }

  .member {
    padding: 5% 0;
    margin-bottom: 5px;
    .btn {
      margin-top: 10%;
    }
  }

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .username {
    margin-top: 7%;
  }

  .player-status {
    color: #9f9f9f;
  }

  .invite-button {
    background-color: #93CB52;
    border-color: #A2D26A;
  }

  .fans-holder {
    max-height: 250px;
    overflow-y: auto;
  }

  @media (max-width: 1023px) {
    .team-name {
      font-size: 12px;
    }

    .edit-button {
      font-size: 10px;
    }

    .btn {
      height: 40px;
    }

    .invite-label h5 {
      font-size: 10px;
    }
  }
}
