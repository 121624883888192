manage-category > div {
  span.category-name,
  input {
    height: 27px;
    display: inline-block;
  }

  span.category-name {
    width: 196px;
  }

  input {
    width: 200px;
  }

  toggle-input > span.toggle-icon {
    margin-left: calc(~"100% - 400px");
  }
}