.manager-create-skill {
  .btn {
    border-radius: 8px;
    &:focus, &:active:focus {
      outline: none;
    }
  }
  .header {
    margin-top: 3%;
    margin-bottom: 3%;
    color: #4a4a4a;
  }

  hr {
    border-color: #ccc;
  }

  .create-skill-frame {
    padding: 30px;
    padding-bottom: 80px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 30px;
    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      text-align: justify;
    }
  }

  .save-skill-buttons .btn {
    font-weight: bold;
  }

  .video-help {
    text-align: center;
    margin-top: 30px;
    a {
      color: dodgerblue;
      cursor: pointer;
    }
    span {
      margin-left: 5px;
      vertical-align: top;
    }
  }

  .link-to-path {
    cursor: pointer;
    color: dodgerblue;
  }
}
