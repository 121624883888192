.contest-basics-view {
  padding-bottom: 50px;
  button.btn {
    border-radius: 4px;
  }
  .contest-name {
    font-family: 'AvenirNextLTPro Bold';
  }

  .checkbox-holder {
    @media(max-width: 767px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  p {
    word-break: break-all;
  }

  .pre-roll-selector {
    @media (max-width: 767px) {
      margin-top: 15px;
    }
    @media (min-width: 768px) {
      margin-top: 15%;
    }
    max-width: 200px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    overflow: hidden;
    div:first-child {
      padding: 10px;
      background-color: #eee;
      border-right: 1px solid #d1d1d1;
      margin-right: 10px;
      display: inline-block;
    }
  }
}
