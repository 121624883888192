permissions-filter > div.btn-group {
  width: 200px;
  margin-bottom: 25px;

  button, li {
    text-align: left;
  }

  li {
    padding: 6px 12px;
    cursor: pointer;
    &:hover {
      background-color: dodgerblue;
      color: #fff;
    }
  }

  .dropdown-menu {
    width: 100%;
  }

  span.caret-holder {
    float: right;
    margin-top: 3px;
    margin-right: 8px;

    span.caret {
      display: block;
      margin: 2px;

      &.caret-top {
        transform: rotate(180deg);
      }
    }
  }
}