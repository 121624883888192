manager-product-availability-preview {
  span.inline-stats {
    display: block;

    h4.stats-label {
      display: inline-block;
      font-weight: bold;
    }

    span.stats-value {
      font-size: 18px;
      margin-left: 10px;
    }
  }
}