.contests-list {
  padding: 0;

  .btn {
    border-radius: 4px;
    font-weight: bold;
  }

  h1 {
    font-family: 'AvenirNextLTPro Bold';
  }

  .contests-table {
    & > list-loading-overlay > .list-loading-overlay {
      width: calc(~"100% - 30px");
      left: 15px;
    }
    padding: 0 15px;
  }
}
