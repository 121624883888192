.store-block {
  margin: 0 0 20px 0;
  border: 1px solid #DBDBEA;
  .header {
    text-align: center;
    padding: 6px 0;
    background: #E4E4EF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    a {
      color: #000;
    }
  }
  .logo {
    width: 100%;
  }
  .content {
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    position: relative;
  }
  .arrow-down {
    margin-left: 5px;
    vertical-align: text-top;
  }
  .menu {
    background: none;
    height: auto;
    div:nth-child(2) {
      @media (min-width: 1280px) and (max-width: 1440px) {
        font-size: 12px;
        padding-left: 26px;
      }
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 11px;
        padding-left: 30px;
      }
    }
  }

  .store-provider-img {
    max-width: 60%;
    position: absolute;
    top: 50%;
    max-height: 30px;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }

  .product-list-item {
    margin-bottom: 20px;
  }

  .products {
    .product {
      margin-bottom: 10px;
      border-top: 1px solid lightgrey;
      padding-top: 10px;
      padding:5px;
    }

    .product:nth-child(1) {
      border-top: none;
    }

    button {
      width: 100%;
    }
  }

  .dropDownMenu-wrapper {
    border-bottom: 1px solid lightgrey;
    &:hover {
      cursor: pointer;
      background-color: #f7f7fd;
    }
    text-align: center;
    span:nth-child(1) {
      display: inline-block;
    }
    span:nth-child(2) {
      display: inline-flex;
    }
  }
}

.store-block-dropdown {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  .item {
    margin-bottom: 10px;
    cursor: default;
    text-align: center;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (min-width: 1441px) {
    width: 190px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 125px;
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    width: 130px;
  }
}
