signup-tabs > div {
  width: 100%;
  display: block;

  & > signup-tab {
    &:not(:first-child) {
      margin-left: 56px;
    }
  }

  &.mobile {
    & > signup-tab {
      &:not(:first-child) {
        margin-left: 17.5vw;
      }
    }
  }
}