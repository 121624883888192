checkbox-group > div {
  margin-top: 25px;
  position: relative;
  overflow: hidden;

  .checkbox-holder {
    position: absolute;
    height: 34px;
    width: 34px;
    &.gray-background {
      background-color: #eee;
    }
    &.green-background {
      background-color: #5fd842;
    }
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 5px;
  }

  label {
    margin-left: 34px;
    width: calc(~"100% - 60px");
  }
}