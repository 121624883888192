register-additional-selection {
  .org-logo {
    width: 100%;
  }

  .additional-selection-item {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid #81819b;
    h4 {
      float: left;
      max-width: calc(~"100% - 30px");
    }
  }

  .skip-link {
    color: #fff;
    font-size: 18px;
    display: block;
  }

  .btn {
    margin-bottom: 30px;
    font-size: 18px;
  }
}

.is-mobile register-additional-selection {
  .margin-top-30 {
    margin-top: 30px;
  }
}