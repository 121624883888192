.edit-channel-filler-content {
  input {
    border-radius: 4px;
  }

  .channel-videos-count {
    span, input {
      display: inline-block;
    }
    input {
      width: 80px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
