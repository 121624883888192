@detailMarginTop: 15px;

player-fundraising-details > div {
  color: #4B4B4B;

  h1.title {
    margin-top: 40px;
    font-family: 'AvenirNextLTPro Bold';
  }

  p.description {
    margin-top: 40px;
    font-weight: bold;
  }

  span.contest-start-end {
    font-weight: bold;
    display: block;

    &:first-of-type{
      margin-top: 40px;
    }

    &:not(:first-of-type) {
      margin-top: @detailMarginTop;
    }
  }

  a.landing-page-link {
    color: dodgerblue;
    font-weight: bold;
    margin-top: @detailMarginTop;
    display: block;
    text-align: left;
  }
}