.thumbnail-upload {
  p {
    font-size: 12px;
    color: #7b7b7b;
    margin: 15px auto 25px;
  }

  .upload-icon {
    position: absolute;
    font-size: 25px;
    text-align: center;
    width: 100%;
    color: #a5c8f1;
  }

  .carousel-item {
    border-radius: 8px;
    width: 80%;
    margin-left: 10%;
    height: 55px;
    &.current {
      border: 2px solid dodgerblue;
    }
    &.upload-new {
      padding-bottom: 43%;
      position: relative;
      &:not(.disabled-item) {
        cursor: pointer;
      }
      &:not(.current) {
        border: 2px solid #b1aeae;
      }
    }
    .upload-icon {
      top: 25%;
    }
  }

  .new-thumbnail-preview {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.7;
  }

  span[class^="carousel-arrow-"] {
    position: absolute;
    top: 20%;
    color: #b1aeae;
    cursor: pointer;
    z-index: 900;
  }

  .carousel-arrow-left {
    left: -25px;
  }

  .carousel-arrow-right {
    right: -25px;
  }

  .upload-alternate {
    margin-left: calc(~"50% - 40px");
    padding-bottom: 78px; //take out 2px from the height due to top and bottom borders
    width: 80px;
    border: 1px solid #b1aeae;
    position: relative;
    border-radius: 8px;
    &:not(.disabled-item) {
      cursor: pointer;
    }
    .upload-icon {
      top: 35%;
      left: 0;
    }
    &.wider {
      margin-left: calc(~"50% - 50px");
      width: 100px;
    }
  }
}
