.zendesk-help.btn {
  margin-top: 10px;
  display: block;
  width: 180px;
  margin-left: auto;
  margin-right: auto;

  &.mobile {
    font-size: 4.4vw;
    line-height: 6.6vw;
    width: auto;
    padding: 2.5vw 5vw;
    margin-top: 5vw;
    border-radius: 1.25vw;
  }
}
