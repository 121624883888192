span.stats-group {
  &:first-child {
    width: 40%;
  }
  &:not(:first-child) {
    width: 19%;
  }
  display: inline-block;

  img.stats-icon {
    max-width: 15px;
    max-height: 20px;
    vertical-align: sub;
  }

  span.stats-value {
    font-size: 18px;
    margin-left: 6%;
  }
}