.finalist-skills-list {
  @fontSizeMax1020: 12px;
  @fontSizeMax745: 11px;
  @fontSizeMax666: 10px;

  .btn-success {
    width: 100%;
    background: #5cb85c;
  }

  manager-column-head > div {
    padding: 0;
  }

  padding-top: 40px;

  input, button {
    border-radius: 4px;
  }

  .table-item {
    &:nth-child(odd) {
      .detail-header {
        background-color: #d8d8d8;
      }
      .finalist-skill-detail .expanded-view {
        border: 2px solid #d8d8d8;
      }
    }
    &:nth-child(even) .finalist-skill-detail .expanded-view {
      border: 2px solid #fff;
    }
    .finalist-skill-detail {
      .expanded-view {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
    .table-item-footer {
      padding-top: 20px;
      padding-bottom: 50px;

      button {
        @media (min-width: 768px) {
          margin-top: 65px;
        }
        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }
    }
  }

  .finalist-skill-detail {
    .detail-header {
      padding: 15px 15px;
      span[class*="glyphicon-chevron"] {
        color: #4a4a4a;
      }

      span[class*="glyphicon-remove"] {
        color: #d4172e;
        margin-right: 30px;
      }

      @media (max-width: 745px) {
        padding: 15px 5px;
      }

      @media (max-width: 1020px) {
        font-size: @fontSizeMax1020;

        & div[class*="col-xs"] {
          padding-right: 5px;
        }
      }

      @media(max-width: 745px) {
        font-size: @fontSizeMax745;
      }

      @media(max-width: 666px) {
        font-size: @fontSizeMax666;
      }
    }
  }
}
