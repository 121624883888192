.top-skills {
  .header {
    font-family: 'AvenirNextLTPro Bold';
    margin-top: 40px;
  }

  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
    border-color: dodgerblue;
    border-bottom: none;
  }

  .nav-tabs.nav-justified > li > a {
    border-bottom-color: dodgerblue;
    &:hover {
      border-color: #c2dffb #c2dffb dodgerblue #c2dffb;
    }
  }

  a {
    color: #2b2b2b;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: dodgerblue;
    font-weight: bold;
  }
}
