my-fundraiser-distributions-item {
  & > div {
    h5 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    span {
      display: block;
      line-height: 20px;
      font-size: 16px;

      &.info {
        margin-top: 7px;
        margin-bottom: 15px;
      }
    }

    button.btn.btn-primary.cancel {
      width: 150px;
      border-radius: 4px;
      border-width: 2px;
      font-weight: bold;
      margin-top: 10px;
      color: dodgerblue;
    }

    &.selected {
      opacity: .7;
    }
  }

  &:not(:first-child) {
    & > div {
      margin-top: 50px;
    }
  }
}