manager-column-head > div {
  display: inline-block;
  padding: 0;

  span.glyphicon {
    vertical-align: sub;
  }

  span[class*='inverted-color'] {
    background-color: #fff;
    color: #003;
    border-radius: 50%;
    border: 1px solid #fff;
    vertical-align: sub;
  }

  &.active-column {
    color: dodgerblue;

    span[class*='inverted-color'] {
      background-color: dodgerblue;
      border-color: dodgerblue;
    }
  }

  &.last-column {
    .dropdown-menu {
      right: -15px;
      left: auto;
    }
  }
}