signup-choose-role > div {
  width: 100%;
  margin: 64px 0;
  display: grid;

  span.view-title {
   color: #333333;
   font-size: 28px;
   font-weight: 900;
   letter-spacing: 0;
   line-height: 38px;
   display: block;
  }

  div.buttons-holder {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    & > div.button-group-holder {
      display: inline-block;
      width: calc(~"33% - 16px");
      max-width: 142px;

      div.role-button {
        width: 100%;
        padding-bottom: 96%;

        &.player-role {
          &:not(.selected) {
            background: url('../img/signup/player-role.svg');
            background-size: contain;
          }

          &.selected {
            background: url('../img/signup/player-role-selected.svg');
            background-size: contain;
          }
        }

        &.parent-role {
          &:not(.selected) {
            background: url('../img/signup/parent-role.svg');
            background-size: contain;
          }

          &.selected {
            background: url('../img/signup/parent-role-selected.svg');
            background-size: contain;
          }
        }

        &.coach-role {
          &:not(.selected) {
            background: url('../img/signup/coach-role.svg');
            background-size: contain;
          }

          &.selected {
            background: url('../img/signup/coach-role-selected.svg');
            background-size: contain;
          }
        }
      }

      span.button-label {
       color: #4B4B4B;
       font-size: 14px;
       font-weight: 900;
       letter-spacing: 0;
       line-height: 19px;
       margin-top: 8px;
       display: block;
       text-align: center;
      }
    }
  }

  button.btn.btn-primary {
    margin-top: 80px;
  }

  &.mobile {
    span.view-title {
      margin-top: 12.5vw;
      font-size: 8.75vw;
      line-height: 11vw;
    }

    toggle-input {
      display: block;
      margin-top: 10vw;

      & > span.label.mobile {
        font-size: 6vw;
        margin-left: 2.5vw;
      }
    }

    button.btn.btn-primary {
      font-size: 5vw;
      line-height: 7.5vw;
      border-radius: 1.25vw;
      margin-top: 25vw;
      padding: 2.5vw 5vw;
    }
  }
}