range-ten-selector > div.container-fluid {
  label {
    display: inline-block;
    margin-right: 10px;
  }

  select.form-control {
    display: inline-block;
    width: 100px;
    border-radius: 4px;
  }
}