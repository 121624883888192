@dropdownMaxHeight: 150px;

.form-component {
  .dropdown .text-align-left {
    text-align: left;
  }

  ul li {
    list-style-type: none;
  }

  .second-dropdown {
    max-height: @dropdownMaxHeight;
    overflow-y: auto;

    .nav a {
      cursor: pointer;
    }
  }

  .dropdown.disabled {
    opacity: @disabledItemOpacity;
  }

  .nav a {
    cursor: pointer;
  }
}
