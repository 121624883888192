header-dropdown > div {
  position: absolute;
  right: 0;
  left: auto;
  top: 28px;
  width: 240px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 1px 3px 0px #888;
  padding: 16px;
  background: white;
  z-index: 1001;
}