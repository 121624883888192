view-title > div {
  display: inline-block;
  width: 100%;
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 16px 24px;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #e5e5e5;
  text-transform: uppercase;

  &.mobile {
    font-size: 5vw;
    line-height: 7.5vw;
    padding: 5vw 7.5vw;
    border-top-left-radius: 1.25vw;
    border-top-right-radius: 1.25vw;
    border-width: 2px;
  }
}