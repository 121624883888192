@statsGroupVertOffset: 30px;

pledge-stats-preview {
  & > .container-fluid {
    padding-bottom: @statsGroupVertOffset;
    background-color: #fff;
    border: 1px solid #b9b6b7;
    padding-right: 35px;
    padding-left: 35px;
    @media (max-width: 1199px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .alert {
    margin-top: @statsGroupVertOffset;
  }
}