.organization-teams.add-coach-modal {
  margin-top: 25px;

  .add-coach-header {
    padding: 30px 0;
  }

  input:not([type="radio"]) {
    width: 100%;
    padding: 5px 10px;
  }

  span.org-filter {
    display: block;
    margin: 5px;
  }

  .coach-profile-info {
    padding: 15px;
  }

  .coach-list-item {
    border-bottom: 1px solid #d5cde4;
  }

  .inner-scroll-400 {
    max-height: 450px;
  }

  .btn {
    margin: 30px 0;
  }

  @media(max-width: 1400px) {
    .inner-scroll-400 {
      max-height: 250px;
    }
  }
}
