.manage-content {
  manager-list-header > div.container-fluid {
    padding: 10px 0;
  }

  manager-column-head > div {
    padding: 0 15px;
  }

  .create-path-btn {
    padding: 10px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;

    span {
      margin-right: 10px;
    }

    @media(max-width: 1199px) {
      font-size: 14px;

      span {
        margin-right: 5px;
      }
    }
  }
  .review-detail {
    .content-provider-logo {
      max-width: 100%;
      max-height: 60px;
    }

    .path-info {
      padding-top: 35px;

      @media (max-width: 767px) {
        text-align: center;
      }

      .path-stats {
        font-weight: bold;
        font-size: 16px;
        .path-stats-icon {
          &.glyphicon {
            color: #498FE1;
          }

          margin-right: 5px;
          &:not(:first-child) {
            margin-left: 10%;
            @media(max-width: 1023px) {
              margin-left: 5%;
            }
          }

          img {
            width: 16px;
            height: 16px;
            vertical-align: top;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    h4, .review-detail .path-info .path-stats, p, a {
      font-size: 12px;
    }

    .review-detail .path-info .path-stats .path-stats-icon img {
      width: 14px;
      height: 14px;
    }
  }
}
