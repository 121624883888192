locked-player-skill-banner > div {
  background-color: #ffd3db;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: center;

  span.glyphicon-lock {
    color: dodgerblue;
    margin-right: 5px;
  }

  countdown > div {
    display: inline-block;
    & > div {
      top: -40px;
      left: 10px;
    }
  }
}