edit-pledge {
  & > div.row {
    padding-bottom: 40px;
  }
  hr {
    border-color: #66b2fb;
    margin-top: 10px;
    margin-bottom: 0;
  }
  a.cancel-pledge {
    color: dodgerblue;
  }
  input.ng-invalid {
    border-color: red;
    background: rgba(255,0,0,0.1);
  }
  input.ng-invalid.form-control:focus {
    border-color: red;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 0, 0, .6)
  }
}