user-subscriptions-list > div {
  span.cancel-subscription {
    float: right;
    color: red;
  }

  &.list-header {
    span.column-title {
      height: 20px;
      font-weight: bold;
      text-transform: uppercase;

      &:not(:last-child) {
        border-right: 1px solid #e5e5e5;
      }
    }
  }
}