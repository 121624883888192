pledge-amount-selector > div {
  .amount-btn {
    span {
      display: block;
      &:nth-child(2) {
        margin-top: 5px;
        white-space: normal;
      }
    }
  }

  span.amount-input-currency-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
  }

  input.amount-input {
    padding-left: 20px;
  }
}