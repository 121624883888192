.leaderboard-widget-filters.leaderboard-widget-filters-mobile {
  .upper-filters-row {
    .btn {
      width: auto;
    }

    .dropdown-menu {
      width: 100vw;
      position: fixed;
      left: 0;
      top: 140px !important;
    }
  }
}
