.manager-search-teams {
  .review .review-table {
    margin-top: 80px;

    .review-row-even, .review-row-odd {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px;

      .glyphicon {
        font-size: 14px;
        background-color: #efefef;
        border: 3px solid #efefef;
        border-radius: 4px;
      }

      .loading-text-placeholder {
        height: 20px;
        width: 25%;
        display: inline-block;
        background: #333;
      }
    }
  }
}
