.myTeams {
  .list-item {
    padding-top: 15px;
    border: solid 1px #F0F0F0;
    border-top: none;
    background-color: #fff;
  }

  .points {
    color: #fff;
    text-align: center;
    width: 20px;
    height: 20px;
    margin-top: 20px;
    &.green {
      background: #7DD220;
    }
    &.grey {
      background: #CFCFCF;
    }
  }

  h4 {
    color: #000;
  }

  .team-name:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    .points {
      margin-top: 0;
    }
  }
}
