.create-team-modal {
  margin-top: -30px;
  padding-bottom: 15px;
  background-color: #f7f7fd;

  .dropdown, input {
    margin-bottom: 10px;
    font-size: small;
  }

  .alert {
    margin-top: 10px;
  }

  fieldset:disabled input, .add-coach.disabled {
    background-color: #fff;
    opacity: 0.4;
  }

  .add-coach.disabled {
    cursor: not-allowed;
  }

  .btn {
    height: 40px;
  }

  @media (min-width: 700px) and (max-width: 1281px) {
    margin-top: -13px;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    margin-top: -30px;
  }

  @media (min-width: 1442px) {
    margin-top: -16px;
  }

  label {
    font-size: small;
    color: black;
  }

  input:not([type="checkbox"]) {
    height: 30px;
    border: 1px solid #a5a5a5;
    border-radius: 0px;
    padding: 0 5px;
    width: 100%;
  }

  .dropdown {
    height: 30px;
    border: 1px solid #a5a5a5;
    border-radius: 0px;
    a {
      top: 4px;
      padding: 0 5px;
      position: relative;
      span {
        top: 4px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &.disabled {
      opacity: 0.4;
      a {
        color: #555;
      }
    }
  }

  .add-coach {
    height: 30px;
    border: 1px solid #a5a5a5;
    background-color: #fff;
    padding: 0 5px;
  }

  .club-org-label {
    background-color: white;
    border: solid 1px #a5a5a5;
  }

  .team-code-text {
    color: #f0ad4e;
    font-size: 16px;
    text-align: center;
  }

  .invitation-code {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .primary-coach {
    font-size: 13px;
    vertical-align: sub;
  }

  .coaches-label {
    margin-bottom: 10px;
    border-bottom: 1px solid #DBDBEA;
    padding-bottom: 10px;
  }

  .gray-arrow {
    color: gray;
    float: right;
    line-height: 24px;
  }

  .add-another-coaches {
    margin-bottom: 5px;
  }

  .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-top: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      left: -9999px;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      border: 1px solid #ccc;
      background-color: #fff;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #eee;
    }

    /* When the checkbox is checked, add a blue background */
    & input:checked ~ .checkmark {
      background-color: #fff;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    & .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    & .checkmark:after {
      left: 5px;
      top: -4px;
      width: 8px;
      height: 13px;
      border: solid dodgerblue;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
