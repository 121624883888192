.payment input[type="month"]  {
  display:block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  height: 40pt;
  &.isAndroid:before {
    content: attr(placeholder) !important;
    color: #999;
  }

  &.isAndroid:focus:before {
    content: '' !important;
  }
  &.isIOS:after {
    content: attr(placeholder) !important;
    color: #999;
  }
}



.register-new .payment {
  input {
    border-radius: 0;
    margin-top: 20px;
    width: 100%;
  }

  input:disabled {
    opacity: 0.7;
  }

  input.background-padding {
    padding-left: 30px;
  }

  .form-group .input-background-image {
    position: absolute;
    top: 6px;
    width: 20px;
    left: 5px;
  }

  button {
    white-space: normal;
  }

  .logo {
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .help-icon {
    position: absolute;
    right: -1px;
    top: 0;
    width: 30px;
    height: 30px;
  }

  .help-icon:hover {
    opacity: 0.8;
  }

  .security-image {
    width: 100%;
    background-color: #fff;
    position: absolute;
    left: 0;
  }

  .animate-if.ng-enter, .delete-skill-inner.animate-if.ng-leave {
    transition: all linear 0.2s;
  }

  .animate-if.ng-leave-active {
    width: 0;
    padding: 5px 0;
    overflow: hidden;
    margin-left: 100%;
  }

  .marginBottom {
    margin-bottom: 15px;
  }

  .skip-link {
    cursor: pointer;
  }
}

.is-mobile .register-new .payment {
  .help-icon {
    height: 8vw;
    width: 8vw;
    right: -2px;
  }

  input {
    margin-top: 15%;
  }

  input.background-padding {
    background-size: 5vw;
    background-position-y: 2vw;
    padding-left: 7vw;
  }

  .form-group .input-background-image {
    position: absolute;
    top: 11pt;
    width: 6%;
    left: 1%;
  }
}
