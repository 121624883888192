//lw == leaderboard widget
@lwAvatarMarginLeft: 5px;
@lwAvatarMarginRight: 5px;
@lwAvatarWidth: 50px;
@lwSmAvatarWidth: 25px;
@lwPlayerInfoHeaderPaddingLeft: @lwAvatarWidth + @lwAvatarMarginRight + @lwAvatarMarginLeft;
@lwAvatarMarginBottom: 5px;
@lwStatsPaddingTop: @lwAvatarWidth + @lwAvatarMarginBottom;

.leaderboard-widget {
  border: 1px solid;
  background-color: #fff;
  min-width: 230px;
  overflow: hidden;
  //workaround for Firefox disappearing right border bug
  width: 99%;

  input[type="search"] {
    margin-top: 20px;
    border-radius: 4px;
    padding-right: 35px;
  }

  img.magnifier-icon {
    position: absolute;
    right: 25px;
    top: 30px;
    height: 15px;
  }

  .widget-header {
    color: #fff;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 35px;
  }

  .table-header {
    padding-top: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;

    span[class*='col-'] {
      padding: 0;
      font-size: 8pt;
      font-weight: bold;
      color: #9e9e9e;
      &:not(.player-info-header) {
        text-align: center;
      }
      @media (min-width: 285px) {
        &.player-info-header {
          padding-left: @lwPlayerInfoHeaderPaddingLeft;
        }
      }
      @media (max-width: 284px) {
        &.player-info-header {
          padding-left: 35px;
        }
      }

      @media (max-width: 767px) and (min-width: 341px) {
        &.rank-header {
          padding-left: 10px;
        }
      }

      @media (max-width: 319px) {
        font-size: 8pt;
      }

      @media (min-width: 550px) {
        font-size: 11pt;
      }

      .header-icon {
        img {
          width: 10pt;
          @media (min-width: 550px) {
            vertical-align: text-top;
            margin-top: 2px;
          }
        }
        &.glyphicon {
          width: 10pt;
          height: 10pt;
          background-color: #9e9e9e;
          color: #fff;
          border-radius: 50%;
          font-size: 9px;
          padding-top: 1.5px;
          vertical-align: text-bottom;
          @media (min-width: 550px) {
            vertical-align: text-top;
            margin-top: 2px;
          }
        }
      }
    }
  }
  .total-funds-raised {
    text-align: center;
    padding-top: 25px;
    color: #ff8827;
    font-size: 16px;
  }
  .table-content-item {
    padding-top: 20px;
    padding-bottom: 15px;
    &.hover-background:hover {
      background-color: #eee;
      &.highlighted-item {
        background-color: #ebf1f7;
      }
    }

    &.highlighted-item {
      background-color: #dbe9f9;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eaeaea;
    }

    .player-avatar,
    .organization-logo {
      max-width: @lwAvatarWidth;
      max-height: @lwAvatarWidth;
    }

    span[class*='col-'] {
      padding: 0;
      color: #46464e;
      &:not(.player-info),
      &:not(.organization-info) {
        text-align: center;
      }
      font-weight: bold;
      font-size: 10pt;

      @media (max-width: 284px) {
        font-size: 8pt;

        .player-avatar,
        .organization-logo {
          width: @lwSmAvatarWidth;
          vertical-align: bottom;
        }

        &:not(.player-info),
        &:not(.organization-info) {
          padding-top: 6px;
        }

        &.player-info span,
        &.organization-info span {
          max-width: calc(~'100% - 40px');
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          padding-top: 3px;
        }
      }

      .player-avatar,
      .organization-logo {
        margin-right: @lwAvatarMarginRight;
        margin-left: @lwAvatarMarginLeft;
      }

      @media (min-width: 285px) {
        &.player-info span,
        &.organization-inf span  {
          vertical-align: middle;
          display: inline-block;
          //leave 5px padding
          max-width: calc(~'100% - @{lwPlayerInfoHeaderPaddingLeft} - 5px');
          word-wrap: break-word;
          font-size: 9pt;
        }

        &:not(.player-info),
        &:not(.organization-info) {
          padding-top: 15px;
        }
      }
    }

    @media (max-width: 424px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .hide-on-small-screen {
    @media (max-width: 340px) {
      display: none;
    }
  }

  .show-on-small-screen {
    @media (min-width: 341px) {
      display: none;
    }
  }

  .infinite-scroll-holder {
    overflow-y: auto;
    max-height: 600px;
    @media (min-width: 321px) {
      min-height: 220px;
    }
    @media (max-width: 284px) {
      max-height: 440px;
    }
  }

  .leaderboard-message {
    font-weight: bold;
    color: #c4c4c4;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 35px;
    letter-spacing: 1px;
    height: 600px;

    & > span {
      display: block;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .currency-icon {
    width: 10pt;
    height: 10pt;
    background: #9e9e9e;
    color: #fff;
    display: inline-block;
    border-radius: 50%;
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 10px;
    vertical-align: middle;
    padding-right: 1px;
    @media (max-width: 549px) {
      margin-bottom: -3px;
    }
  }

  .arrow-holder {
    padding: 15px 0 0 0;
    text-align: center;
    span {
      color: #9e9e9e;
    }
    @media (max-width: 284px) {
      padding-top: 8px;
      font-size: 9px;
    }
  }
}
