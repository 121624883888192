edit-product-paths-form > div {
  padding-top: 30px;
  h4 {
    color: #6c6c6c;
  }

  radio-input:not(:nth-of-type(1)) > div {
    margin-left: 10px;
  }

  radio-input:nth-of-type(3) > div {
    width: 260px;
  }

  div.custom-head-to-head-count-holder {
    input.form-control {
      width: 260px;
      margin-left: calc(~"100% - 260px");
    }
  }

  manage-product-paths-drop-area > div.container-fluid {
    height: 600px;
  }

  manage-product-draggable-paths-list > div .items-holder {
    max-height: 465px;
  }
}