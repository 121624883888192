.edit-channel {
  ol {
    padding-left: 0;
    padding-right: 0;
  }

  .page-header {
    font-family: 'AvenirNextLTPro Bold';
    border-bottom: none;
  }

  .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border-color: dodgerblue;
    border-bottom: none;
  }

  @media (min-width: 768px) {
    .nav-tabs.nav-justified > li > a {
      border-bottom-color: dodgerblue;
    }
  }
}
