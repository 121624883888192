@menuWidth: 232px;

menu {
  width: @menuWidth;

  & > div {
    width: @menuWidth;
    height: 100%;
    background: #003;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 3000;

    img.logo-image {
      width: 152px;
      height: 48px;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: 32px;
      margin-bottom: 48px;
    }

    div.items-holder {
      max-height: calc(~"100% - 128px");
      overflow-x: hidden;
      overflow-y: auto;
    }

    &.mobile {
      width: 71.25vw;
      padding: 7.5vw;
      top: 22.5vw;
      height: calc(~"100% - 22.5vw");

      img.logo-image {
        width: 31vw;
        height: 31vw;
        margin: 0;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 11vw;
      }

      &.ng-enter,
      &.ng-leave {
        transition: left linear .2s;
        -webkit-transition: left linear .2s;
        -moz-transition: left linear .2s;
      }
      &.ng-enter,
      &.ng-leave.ng-leave-active {
        left: -72vw;
      }
      &.ng-leave,
      &.ng-enter.ng-enter-active {
        left: 0;
      }
    }
  }
}