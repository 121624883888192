.selection-icon {
  width: 25px;
  height: 25px;
  float: right;
  border-radius: 50%;
}

.is-mobile .selection-icon {
  width: 30pt;
  height: 30pt;
  margin-top: 15pt;
}