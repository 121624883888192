parent-my-kids-list > div.container-fluid {
  box-sizing: border-box;
  padding: 0;

  hr {
    margin: 0;
    margin-top: -1px;
    border-color: #E5E5E5;
  }

  div.filters-holder {
    width: 100%;
    display: inline-block;

    parent-my-kids-add-button > button {
      float: right;
    }
  }

  div.table-holder {
    width: 100%;
    display: inline-block;
    margin-top: 24px;
  }

  error-message > div, info-message > span {
    margin-top: 24px;
  }

  spinner > div {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 24px;
  }

  &.mobile {
    border: 2px solid #E5E5E5;
    border-radius: 1.25vw;
    background-color: #FFFFFF;
    padding: 12.5vw 7.5vw;

    hr {
      display: none;
    }
  }
}
