.freestyle {
  .header {
    background-color: #000;
    height: 40px;
    margin-top: 10px;
    &:hover {
      opacity: 0.7;
    }

    .rotate {
      transform: rotate(90deg);
    }

    h4 {
      display: inline-block;
      margin-top: 10px;
      margin-left: 15px;
    }

    img {
      float: right;
      margin-top: 10px;
      margin-right: 15px;
    }
  }

  .active-header {
    color: #93CB53;
  }

  .scored-header {
    color: #F7A722;
  }

  .pending-header {
    color: #488DDD;
  }

  @media (max-width: 1023px) {
    h4 {
      font-size: 18px;
    }
  }
}

#freestyle-noheader-video {
  margin-top: 13%;
}
