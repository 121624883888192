.contest-orgs-selector {
  .drop-area-holder {
    padding-left: 15px;
    padding-right: 15px;
  }
  .drop-area {
    @areaHeight: 300px;
    min-height: @areaHeight;
    border: 1px dotted #c6c4c6;
    margin-top: 10px;
    position: relative;
    span.area-title {
      position: absolute;
      z-index: 1;
      width: 100%;
      text-align: center;
      display: block;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      h2 {
        font-family: 'AvenirNextLTPro Bold';
        color: #434343;
      }
    }

    .selected-org {
      height: 40px;
      position: relative;
      z-index: 2;
      @removeIconMarginRight: 15px;
      span {
        display: inline-block;
        margin-top: 20px;
        transform: translateY(-50%);
        &:first-child {
          padding-left: 15px;
          width: calc(~"100% - (15px + @{removeIconMarginRight})");
        }
        &.glyphicon {
          color: #f70000;
          margin-right: @removeIconMarginRight;
        }
      }
      &:nth-of-type(even) {
        background-color: rgba(228,228,228, 0.7);
      }
      &:nth-of-type(odd) {
        background-color: rgba(255,255,255, 0.7);
      }
    }
  }

  .org-list-header {
    height: 40px;
    width: 100%;
    background-color: #003;
    color: #fff;
    & > div > span {
      margin-top: 20px;
      transform: translateY(-50%);
      display: block;
    }
  }

  .org-list-item {
    @orgInListHeight: 45px;
    min-height: @orgInListHeight;
    width: 100%;
    .row > div > span {
      margin-top: @orgInListHeight / 2;
      transform: translateY(-50%);
      display: inline-block;
    }
    .row > div > button {
      display: inline-block;
      width: auto;
      margin-top: @orgInListHeight / 2;
      font-size: 12px;
      transform: translateY(-50%);
    }
    &:nth-of-type(even) {
      background-color: #e4e4e4;
      .sub-org-item {
        &:nth-of-type(even) {
          background-color: #e4e4e4;
        }
        &:nth-of-type(odd) {
          background-color: #fff;
        }
      }
    }
    &:nth-of-type(odd) .sub-orgs-item {
      &:nth-of-type(odd) {
        background-color: #e4e4e4;
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
    }
    .selected> div > span {
      opacity: .4;
    }

    .sub-orgs-list {
      .sub-org-item {
        &:last-child {
          margin-bottom: 10px;
        }
        & > div[class*='col-xs-']:first-of-type {
          padding-left: 30px;
        }
      }
    }
  }

  li.dndPlaceholder {
    display: none;
  }
}
