my-fundraisers-players-list-item > div {
  span.details-title {
    color: #4b4b4b;
    font-size: 18px;
  }

  img.player-avatar {
    height: 80px;
    margin-right: 30px;
  }

  span.glyphicon {
    margin-top: 30px;
  }

  &:first-child {
    background-color: #e7e7e8;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #d2d2d2;
  }
}