player-skill-detail > div {
  error-message > div {
    width: calc(~"100% - 15px");
    margin-top: 10px;
  }

  span.glyphicon-lock {
    &.locked-icon {
      margin-left: -15px;
      margin-right: 2px;
      color: dodgerblue;
    }
  }

  button.btn.copy-id-btn {
    border-radius: 4px;
    margin-top: 20px;
  }

  locked-player-skill-banner > div {
    margin-top: 10px;
  }

  topya-video-component > div {
    border-radius: 8px;
    overflow: hidden;
  }

  player-skill-score-form > div {
    margin-top: 15px;
  }

  div.player-skills-col.content-col {
    width: 11.8%;
  }

  div.player-skills-col.content-col-sm {
    width: 3%;
  }
}