manager-coach-teams > div {
  button.btn {
    min-width: 150px;
    font-weight: bold;
    width: auto;
    border-radius: 4px;
  }

  .list-column-label {
    font-weight: bold;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  manager-add-coach-teams div.scrollbar-present > manager-add-coach-teams-item > div.row {
    margin-right: -25px;

    .add-icon {
      padding-right: 30px;
    }
  }

  manager-current-coach-teams div.scrollbar-present manager-current-coach-teams-item > div.row {
    margin-right: -25px;

    .remove-icon {
      padding-right: 30px;
    }
  }

  manager-add-coach-teams-item {
    &:nth-child(odd) > div {
      background-color: #eee;
    }

    & > div.row {
      padding: 10px 0;

      .add-icon {
        padding-right: 25px;
      }
    }
  }

  input.form-control {
    border-radius: 4px;
  }

  .modal-footer {
    text-align: left;
  }
}