topya-video-component {
  img {
    &.thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .bottom-left-watermark {
    position: absolute;
    left: 3%;
    bottom: 5%;
    z-index: 1;
    width: 10%;
    opacity: .5;
  }
}
