.manager-organizations {
  .btn {
    border-radius: 4px;
  }
  & > div > list-loading-overlay > .list-loading-overlay {
    width: calc(~"100% + 30px");
    left: -15px;
  }
  input.search-orgs {
    width: 100%;
    padding: 7px 30px;
  }
  .organizations-header {
    padding: 3% 0;
    border-bottom: 1px solid #969696;
    margin-bottom: 2%;
  }
  .apply-filters {
    padding: 11px 0;
    margin-left: 15px;
    background-color: #D1D1D1;
  }
  .list-header {
    margin-top: 3%;
    margin-bottom: 15px;

    manager-list-header > div.container-fluid {
      padding: 10px 0;

      manager-column-head > div {
        padding: 0 15px;
      }
    }
  }
  organizations-list-item {
    & > div {
      font-size: 16px;
      padding: 15px 0;
      a {
        color: #000;
      }
    }
    &:nth-child(odd) > div {
      background-color: #D7D7D7;
      .link-arrow {
        background-color: #E4E4E4;
      }
    }
  }
  .link-arrow {
    color: #000;
    padding: 0 5px;
  }
  #single-button {
    padding: 11px 0;
  }
  ul.dropdown-menu {
    width: 100%;
  }
}
