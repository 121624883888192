.animate-if {
 padding:0;
}

.animate-if.ng-enter, .animate-if.ng-leave {
 transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-if.ng-enter[id="registerErr"] {
  transition: none !important;
}

.animate-if.ng-leave[id="noanim"] {
  transition: none !important;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
 opacity:0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
 opacity:1;
}

[id="registerErr"]

.ng-hide:not(.ng-hide-animate)[id="dialog"] {
 display: initial !important;
 visibility: hidden;
 height: 20px;
}

.animate-show {
  line-height: 20px;
  opacity: 1;
  /*padding: 10px;*/
  color: #8C8C8C;
}

.animate-show.ng-hide-add, .animate-show.ng-hide-remove {
  transition: all linear 0.1s;
  height: 20px;
}

.animate-show.ng-hide-remove[id="errorteam"], .animate-show.ng-hide-remove[id="imgTooBig"] {
  transition: none !important;
}

.animate-show.ng-hide {
  line-height: 0;
  opacity: 0;
  padding: 0 ;
}
