header-profile-detail > div {
  position: relative;

  img.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
    vertical-align: bottom;
  }

  span.dropdown-arrow-mobile {
    display: none;
  }

  div.details-holder {
    display: inline-block;

    span.username {
      color: #333;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 24px;
      display: block;

      span.glyphicon {
        color: #CBCBCB;
        font-size: 10px;
        margin-left: 8px;
      }
    }

    span.rolename {
      color: #999;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      display: block;
    }
  }

  &.mobile {
    img.profile-image {
      width: 12.5vw;
      height: 12.5vw;
      margin-right: 0;
      margin-top: 6vw;
    }

    div.details-holder {
      display: none;
    }

    span.dropdown-arrow-mobile {
      display: inline-block;
      color: #CBCBCB;
      font-size: 24px;
      vertical-align: top;
      margin-left: 4.3vw;
      margin-top: 8.2vw;
    }
  }
}