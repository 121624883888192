.fans {
  margin: 20px 0;

  .header {
    background: #1B1C2C;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    height: 40px;
    padding-top: 10px;
  }

  .content {
   border: 2px solid #eeeeee;
   height: auto;
   background-color: #fff;
 }

 .headers-titles {
   padding: 15px 0;
   .topic {
     font-weight: bold;
     text-align: center;
     padding: 10px 0;
   }
   .alert {
     margin-bottom: 0;
     margin-top: 15px;
   }
 }

 .findFans {
   height: 34px;
   padding: 5px 0;
   border: 1px solid #498FE1;
   border-radius: 0px;
   text-align: center;
   color: #498FE1;
   &:hover {
     cursor: hand;
   }
 }

 .search-fields {
   z-index: 1000;
   position: relative;
   margin: 0;
   .form-control {
     height: 34px;
     border-radius: 0px;
     border: 1px solid #498FE1;
     border-right: none;
   }
   &.ng-hide {
     right: -200px;
   }
   &.ng-show-add-active {
     display: block;
   }
 }

 .search-fields-wrapper {
   padding-bottom: 10px;
   height: 40px;
 }

 .search-my-fans-input {
   height: 34px;
 }

 .input-group-btn .btn-default {
   border-radius: 0px;
   border: 1px solid #498FE1;
 }

 .chosenType {
   color: #498FE1;
 }

 .search-my-fans .search-btn {
   border: 1px solid #eeeeee;
 }

 .search-field-wrapper {
   padding: 20px 15px;
   border-top: 1px solid #eeeeee;
   border-bottom: 1px solid #eeeeee;
   margin-top: 10px;
 }

 .fan-wrapper {
   height: 95px;
   padding-top: 20px;
   padding-left: 0;
   padding-right: 0;
   border-bottom: 1px solid #eeeeee;
   word-break: break-all;
  }

 .btnFan {
   color: white;
   border-color: white;
   background-color: #488EE0;
 }

 .my-fans-user-img {
   margin-left: 4px;
   width: 18px;
   height: 18px;
   top: -2px;
 }

 .btnAddFan, .btnAddFan:disabled {
   background-color: white;
   border: 1px solid #488EE0;
   color: #488EE0;
 }

 .fan-info {
   a {
     font-size: smaller;
     color: black;
   }
   @media (min-width: 700px) and (max-width: 1440px) {
     font-size: smaller;
   }
   @media (min-width: 1441px) {
     position: relative;
     top: 10px;
   }
  }//fan-info

  input {
    font-family: 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #555860;
  }

  .search {
    input {
      width: 100%;
      padding: 0px;
      padding-left: 3px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -ms-box-sizing: border-box;
      -o-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
      -moz-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
      -ms-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
      -o-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
      box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
      &:focus {
        outline: none;
        border-color: #66b1ee;
        -webkit-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
        -moz-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
        -ms-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
        -o-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
        box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
      }
    }//input
    .results div:first-child:hover:before,
    .results div:first-child:hover:after {
      display: none
    }
  }//search
  .results {
    display: none;
    &.results-shown {
      display: block;
    }
    position: absolute;
    top: 35px;
    left: 0;
    min-width: 400px;
    padding-bottom: 15px;
    right: 0;
    z-index: 1000;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #cbcfe2 #c8cee7 #c4c7d7;
    border-radius: 3px;
    background-color: #fdfdfd;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, #eceef4));
    background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -ms-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: linear-gradient(top, #fdfdfd, #eceef4);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow-y: scroll;
    background-color: #F7F7FD;
    div {
      display: block;
      &:first-child:before {
        border-bottom: 5px solid #c4c7d7;
        top: -11px;
      }
      &:first-child:after {
        border-bottom: 5px solid #fdfdfd;
        top: -10px;
      }
      &:last-child {
        margin-bottom: -1px
      }
    }//div
    .item {
      border-bottom: 1px solid #eeeeee;
      padding: 5px 0;
      display: block;
      margin: auto;
      float: none;
      height: 80px;
      .user-full-name {
        text-align: left;
        position: relative;
        top: 20px;
      }
    }//item
    .button {
      position: relative;
      top: 17px;
      button {
        float: right;
      }
    }//button


  }//results

  :-moz-placeholder {
    color: #a7aabc;
    font-weight: 200;
  }
  ::-webkit-input-placeholder {
    color: #a7aabc;
    font-weight: 200;
  }

.fan-info-2 {
  margin-top: 20px;
  padding-bottom: 20px;
  .follow-img {
    width: 18px;
    height: 18px;
    position: relative;
    top: -2px;
    }
  }

  .fan-info-1 {
    margin-top: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
    .arrow-left {
      height: 30px;
      position: relative;
      top: 20px;
    }
    .fan-avatar {
      width: 70px;
      height: 70px;
    }
  }

  .teams-search {
    width: 50%;
  }

  .statistics {
    position: relative;
    top: 25px;
    span {
      &:first-child {
        display: inline;
      }
      &:nth-child(2) {
        display: inline-block;
      }
      img {
        width: 20px;
        vertical-align: sub;
      }
      h4 {
        margin-left: 3px
      }
    }
  }

  .statistics-country span {
    &:first-child {
      display: inline;
    }
    &:nth-child(2) {
      display: inline-block;
    }
    img {
      width: 30px;
      vertical-align: sub;
    }
  }

  .challenge {
    padding: 20px 0;
    .challenge-info {
      position: relative;
      top: 40px;
      @media (min-width: 1000px) and (max-width: 1442px) {
        top: 20px;
      }
    }

    .arrow-right {
      position: relative;
      top: 20px;

      img {
        position: relative;
        @media (min-width: 1000px) and (max-width: 1442px) {
          top: 30px;
        }
        @media (min-width: 1450px) {
          top: 45px;
        }
      }
    }
    .team-img {
      height: 40px;
      width: auto;
    }
    .challenge-name {
      text-transform: uppercase;
      margin-bottom: 10px;
      padding: 0;
    }
    .team-name {
      font-size: smaller;
      position: relative;
      top: 10px;
    }
  }//challenge

  .btn-following {
    border-color: #488EE0;
    background-color: #488EE0;
    color: white;
  }

  .fan-player-tabs {
    background-color: #F7F7FD;
    padding: 20px 0;
    text-align: center;
    font-weight: bold;
  }

  .challenge-info div {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .pull-left:nth-last-of-type(2), .pull-right:nth-last-of-type(1) {
    border-bottom: none;
  }

  .gray-text {
    color: lightgray;

    @media (min-width: 1442px) {
      span {
        position: relative;
        top: 10px;
      }
    }

    .team-size {
      margin-left: 10px;
    }
  }//gray-text

  .fan-names {
    padding-right: 3px;
    padding-left: 0;
    word-break: break-word;
  }

  .fan-avatar {
    border: none;
    width: 48px;
    height: 48px;
  }

  .dl-horizontal {
    margin-bottom: 10px;

    dt {
      width: auto;
      margin-right: 15px;
      text-align: start;
      img {
        width: 48px;
        height: 48px;
      }
    }

    dd {
      margin-left: 45px;
      font-size: 14px;
    }

    @media (min-width: 1280px) and (max-width: 1440px) {
      dt {
        width: auto;
        margin-right: 8px;
        text-align: start;
      }

      dd {
        margin-left: 45px;
        font-size: 14px;
      }
    }
  }//dl-horizontal

  .username {
    word-break: break-word;
    position: relative;
    top: 25px;
    font-weight: bold;
    font-size: large;
  }

  .basic-info {
    position: relative;
    top: 5px;
  }

  .pending-image {
    background-color: #eee;
  }

  #results .pending-image {
    float: right;
  }

  .fans-activity-feed {
    border-top: 1px solid #eee;
    .content {
      border: 2px solid #eee;
      &:first-of-type {
        border-top: none;
      }
    }
  }

  .activity-feed-wrapper {
    margin-bottom: 20px;
    padding-top: 20px;
  }

  .padding-top {
    padding-top: 15%;
  }

  .counts {
    color: #9e9e9e;
    padding-left: 20px;
    border-left: #F7F7FD 1px solid;
  }

  .blueFindFans {
    background-color: #498FE1;
    color: white;
  }
}//fans


.search-fields.ng-hide-add, .panel.ng-hide-remove {
  transition: 0s linear all;
}

.search-fields.ng-hide-add-active, .panel.ng-hide-remove-active {
  transition: all ease-in-out 2s;
}

.add-high-five {
  cursor: pointer;
  &:hover, &:focus {
    color: #4a90e2;
  }
}

.high-fived {
  color: #4a90e2;
}
