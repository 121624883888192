tab-links {
  span.link-holder {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    min-width: 50px;
  }
  span.link {
    height: 20px;
    display: inline-table;
    &.selected {
      border-bottom: 2px solid;
    }
  }
  span.glyphicon-info-sign {
    font-size: 12px;
    color: #ffe000;
  }
}