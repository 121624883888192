.app-store-link {
   display: block;
 }

.app-store-holder {
  margin-top: 10px;
}

.appStoreBoxes
{
  margin-top:20px;
}
.appStoreBoxes .download-topya-app-today-label
{
  color:#A7B1C1;
}
