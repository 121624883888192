player-home > div {
  width: 100%;

  div.column {
    width: calc(~"50% - 12px");
    margin-top: 24px;
    display: inline-grid;

    &.left-column {
      margin-right: 12px;
    }

    &.right-column {
      margin-left: 12px;
    }

    @media (max-width: 1199px) {
      &.left-column,
      &.right-column {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}