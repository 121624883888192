.manager-pledge-detail {
  .basic-info {
    div[class*='col-xs-'] {
      padding: 10px 5px 10px 0;
      word-break: break-all;
      &:first-child {
        padding-left: 10px;
      }
      span {
        font-size: 10px;
        margin-top: 5px;
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        font-size: 11px;
        span {
          margin-top: 2px;
        }
      }
      @media (max-width: 767px) {
        font-size: 9px;
        span {
          font-size: 6px;
          margin-top: 2px;
        }
      }
      @media (max-width: 620px) {
        span {
          display: none;
        }
      }
    }
  }
}