.widget-filter-dropdown.widget-filter-dropdown-mobile {
  .input-container {
    padding-bottom: 10px;
  }

  .list-item {
    &:not(:first-child) {
      border-top: 1px solid #ccc;
    }
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
