@contestWidgetItemPadding: 4%;
@contestWidgetSmallItemPadding: 2%;

@contestWidgetRowVerticalPadding: 6%;
@contestWidgetLargeRowVerticalPadding: 3%;

@statsBoxWidth: 115px;
@statsBoxHorizontalMargin: 10px;
@statsMinWidth: @statsBoxWidth * 2 + @statsBoxHorizontalMargin * 4;

@statsBoxSmallHorizontalMargin: 3px;
@statsBoxSmallWidth: 98px;
@statsSmallMinWidth: @statsBoxSmallWidth * 2 + @statsBoxSmallHorizontalMargin * 4;

@statsBoxLargeWidth: 140px;
@statsLargeMinWidth: @statsBoxLargeWidth * 2 + @statsBoxHorizontalMargin * 4;

@h4smallFontSize: 14pt;
@h5smallFontSize: 9pt;
@h6smallFontSize: 8pt;

@h4largeFontSize: 20pt;
@h5largeFontSize: 12pt;
@h6largeFontSize: 11pt;

@ribbonPositionThresh: 475px;

.contest-widget {
  position: relative;
  min-height: 400px;

  .contest-ribbon {
    top: 0;
    right: 0;
    z-index: 1200;
  }

  h4 {
    font-family: 'AvenirNextLTPro Bold';
  }

  &.padded-top-150 {
    @media (max-width: @ribbonPositionThresh) {
      padding-top: 150px;
    }
  }

  .dropdown {
    background: none;
  }

  padding-bottom: 50px;

  min-width: 320px;
  max-width: 1200px;
  // overflow-x: auto;
  // overflow-y: hidden;

  .dropdown-menu {
    padding: 10px;
    width: 100%;
  }

  .iframes-holder {
    padding-left: @contestWidgetItemPadding;
    padding-right: @contestWidgetItemPadding;
    padding-top: @contestWidgetRowVerticalPadding;

    .leaderboard-widget-col {
      padding: 0;
    }

    @media (min-width: 768px) {
      .tv-widget-col {
        padding-left: 0;
      }
    }

    @media (max-width: 767px) {
      .tv-widget-col {
        padding: 0;
      }

      .leaderboard-widget-col {
        margin-top: 20px;
      }
    }

    .tv-widget-col, .leaderboard-widget-col {
      background-clip: content-box;
    }

    iframe {
      width: 100%;
    }
  }

  .contest-first-sponsor {
    padding: @contestWidgetRowVerticalPadding @contestWidgetItemPadding;

    span {
      font-style: italic;
      color: #4a4a4a;
    }

    img {
      max-height: 70pt;
      max-width: 50%;
      margin-left: 4%;
    }
  }

  .contest-info {
    padding-left: @contestWidgetItemPadding;
    padding-right: @contestWidgetItemPadding;
    padding-top: @contestWidgetRowVerticalPadding;

    .contest-name-and-deadline {
      display: table-cell;
      @media (min-width: 640px) {
        padding-right: 5%;
      }
      vertical-align: top;
      width: 100%;

      .contest-name {
        margin-bottom: 5%;
      }

      .contest-deadline {
        color: #003;
        margin-bottom: 5%;
      }
    }

    .contest-stats {
      display: table-cell;
      width: 100%;
      min-width: @statsMinWidth;
      text-align: right;

      .stats-box {
        text-align: center;
        border: 1px solid #979797;
        border-radius: 5px;
        padding: 15px 5px;
        background-color: #fff;
        display: inline-block;
        width: @statsBoxWidth;
        margin: auto @statsBoxHorizontalMargin;

        .stats-value {
          color: #ff8827;
          margin-bottom: 5%;
        }

        .stats-label {
          color: #333;
        }
      }
    }
  }

  .btn {
    white-space: normal;
    border-radius: 4px;
    &.cancel {
      background-color: #fff;
    }
  }

  .contest-buttons {
    margin-top: 7%;
    margin-bottom: 3%;

    .btn-join {
      background-color: #ff8827;
      border-color: #ce6c1c;
      font-size: 16px;
      &:active, &:hover {
        background-color: #c36a21;
        border-color: #ad5b19;
      }
      &:focus {
        background-color: #a95d1f;
        border-color: #ad5b19;
      }
    }
  }

  @media (max-width: 609px) and (min-width: 475px) {
    h4 {
      font-size: @h4smallFontSize;
    }
    h5, .contest-first-sponsor span {
      font-size: @h5smallFontSize;
    }
    h6 {
      font-size: @h6smallFontSize;
    }

    .contest-first-sponsor {
      padding: @contestWidgetRowVerticalPadding @contestWidgetSmallItemPadding;

      img {
        max-height: 25pt;
      }
    }

    .contest-info {
      padding-left: @contestWidgetSmallItemPadding;
      padding-right: @contestWidgetSmallItemPadding;

      .contest-stats {
        min-width: @statsSmallMinWidth;

        .stats-box {
          padding: 15px 0;
          width: @statsBoxSmallWidth;
          margin: auto @statsBoxSmallHorizontalMargin;
        }
      }
    }

    .btn, .contest-buttons .btn-join {
      font-size: @h5smallFontSize;
    }
  }

  .contest-sponsors {
    margin-top: 5%;
    text-align: center;
    height: 250px;

    .sponsors-header {
      border-top: 1px solid #979797;
      padding-top: 15px;
      padding-bottom: 25px;
      font-style: italic;
    }

    .sponsors-logo-holder {
      display: inline-block;
      padding: 0 15px;
      @media (min-width: 768px) {
        width: 16.66666667%;
      }
      @media (max-width: 767px) {
        width: 33.33333333%;
      }
      .sponsors-logo {
        max-width: 100%;
        max-height: 60pt;
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 474px) {
    .contest-first-sponsor {
      text-align: center;

      span {
        display: block;
      }

      img {
        margin-left: 0;
        margin-top: 5%;
      }
    }

    .contest-info {
      .contest-name-and-deadline {
        display: block;
        text-align: center;
      }

      .contest-stats {
        display: block;
        text-align: center;
        margin-top: 10%
      }
    }
  }

  @media (max-width: 419px) {
    .col-xs-offset-1 {
      margin-left: 5%;
    }

    .col-xs-5 {
      width: 45%;
    }

    .btn, .contest-buttons .btn-join {
      font-size: 9pt;
    }

    .dropdown-menu {
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 768px) {
    h4 {
      font-size: @h4largeFontSize;
    }
    h5, .contest-first-sponsor span {
      font-size: @h5largeFontSize;
    }
    h6, .contest-first-sponsor span {
      font-size: @h6largeFontSize;
    }

    .contest-first-sponsor {
      padding-top: @contestWidgetLargeRowVerticalPadding;
      padding-bottom: @contestWidgetLargeRowVerticalPadding;

      img {
        margin-left: 1%;
      }
    }

    .contest-info {
      padding-top: @contestWidgetLargeRowVerticalPadding;

      .contest-name-and-deadline .contest-name {
        margin-bottom: 20px;
      }

      .contest-stats {
        min-width: @statsLargeMinWidth;

        .stats-box {
          width: @statsBoxLargeWidth;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .row-eq-height {
      display: inherit;
    }
  }

  // overriding ribbon classes
  &:not(.widget-mobile) .contest-ribbon {
    @media (max-width: 991px) and (min-width: @ribbonPositionThresh) {
      padding: 20px;
      width: 112px;

      .triangle {
        display: inherit;
      }

      .stats-item {
        z-index: 3;
        padding: 0;
        width: 100%;
        &:not(:first-child) {
          margin-top: 15px;
        }
        &:first-child {
          //override bootstrap
          &.text-center.active-contest-item {
            text-align: center;
            .row {
              padding-right: 0;
            }
          }
        }
        span {
          display: block;
          font-size: 9px;
        }
        span.count {
          font-size: 11px;
          font-family: 'AvenirNextLTPro Bold';
        }

        .icon-group {
          position: relative;
          img.stats-icon {
            width: 100%;
          }
          .icon-text {
            text-align: center;
            position: absolute;
            &.time-icon {
              top: 40%;
              left: 18%;
              width: 55%;
              text-align: center;;
            }
            &.points-icon {
              top: 34%;
              left: 30%;
              width: 50%;
              text-align: center;
            }
            &.video-icon {
              top: 29%;
              left: 22%;
              width: 50%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
