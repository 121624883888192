parent-my-kids-card-item > div {
  width: 100%;
  padding: 11px 0 10px 0;
  border-bottom: 1px solid #E5E5E5;
  background: #fff;

  img.profile-picture {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }

  div.name {
    width: 65%;
    display: inline-block;
    vertical-align: middle;

    & > span {
      display: block;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;

      &.first-last-name {
        font-weight: 900;
        color: #333;
      }

      &.username {
        font-weight: 500;
        color: #999;
      }
    }
  }

  div.subscription-status {
    width: 51px;
    height: 24px;
    border-radius: 16px;
    color: #FFF;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding-top: 4px;
    display: inline-block;

    &.subscription-yes {
      background-color: #6DD400;
    }

    &.subscription-no {
      background-color: #F55151;
    }
  }
}