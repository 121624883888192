success-message > div {
  position: relative;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  background-color: #FFF;
  padding: 12px 24px;
  overflow: hidden;

  & > img.check-icon {
    vertical-align: top;
  }

  & > span.green-track {
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #6DD400;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  & > span.message {
    color: #6DD400;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 24px;
    display: inline-block;
    max-width: calc(~"100% - 60px");
  }

  &.mobile {
    padding: 3.75vw 7.5vw;
    border-radius: 4px;
    border-width: 2px;

    & > span.message {
      font-size: 5vw;
      line-height: 7.5vw;
      margin-left: 7.5vw;
      max-width: calc(~"100% - 24vw");
    }

    & > img.check-icon {
      width: 7.5vw;
    }

    & > span.green-track {
      width: 2.5vw;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & > a.close {
      font-size: 7.5vw;
    }
  }
}