player-fundraising-view > div {
  &.hybrid-frame {
    margin-right: -30px;
    font-size: 20pt;
  }

  iframe {
    margin-top: 40px;
    width: 100%;
  }
}