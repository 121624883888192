.date-input-component {
  & > button {
    position: absolute;
    width: 104px;
    top: 1px;
    right: 1px;
    border-top: none;
    height: calc(~"100% - 2px");
    border-bottom: none;
    border-right: none;
    & > .glyphicon {
      margin-top: 4px;
    }
  }
  .dropdown-menu button {
    width: auto;
  }

  ul.dropdown-menu {
    &:before {
      right: 9px;
    }
    &:after {
      right: 7px;
    }
  }

  .local-time-warning.message-hidden {
    opacity: 0;
  }
}
