edit-product-price-and-availability-form > div.container-fluid {
  form {
    padding-top: 30px;
    padding-bottom: 30px;

    input.form-control.available-before-start-input {
      width: 100px;
      border-radius: 4px;
      margin-right: 10px;
      display: inline-block;
    }

    button.btn.btn-default.add-price {
      width: auto;
    }

    h2.currency-symbol {
      font-family: 'AvenirNextLTPro Bold';
      display: inline-block;
      vertical-align: bottom;
      margin-right: 10px;
    }

    span {
      &.retail-price-label {
        margin-left: 30px;
        margin-top: 5px;
        display: block;
      }

      &.subscription-term-label {
        margin-left: 15px;
      }
    }

    & > div > dropdown {
      &:first-of-type {
        & > div.dropdown {
          width: 80px;
        }
      }

      &:not(:first-of-type) {
        & > div.dropdown {
          width: 200px;
        }
      }

      & > div.dropdown {
        margin-left: 10px;
        height: 34px;

        button.btn {
          height: inherit;
        }

        ul.dropdown-menu {
          width: 100%;
          min-width: 0;
        }
      }
    }

    h4 {
      font-weight: bold;
    }

    radio-input:nth-of-type(2) > div {
      margin-left: 10px;
    }

    date-input {
      & > div.full-width {
        width: 200px;
        display: inline-block;

        input.form-control {
          border-radius: 4px;
        }
      }
      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }
  }
}