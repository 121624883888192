parent-my-kids > div {
  width: 100%;

  h3.title {
    margin-top: 6px;
    color: #333;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
  }

  parent-my-kids-list > div {
    margin-top: 24px;
  }

  &.mobile {
    h3.title {
      font-size: 6.25vw;
      line-height: 7.5vw;
    }

    parent-my-kids-list > div {
      margin-top: 7.5vw;
    }
  }
}