pledge-list-filter {
  .main-group {
    display: inline-block;
    padding: 4px 0;
    margin-right: 5px;
    margin-left: 20px;
    span.selected {
      text-decoration: underline;
      margin-left: 5px;
    }
  }

  .options {
    margin-right: 5px;
    background: #f7f6fc;
    color: #a8a8a9;
    border: 1px solid #a8a8a9;
    display: inline-block;
    padding: 3px 5px;

    span {
      margin: 0 5px;
      &.selected {
        text-decoration: underline;
      }
    }

    &.ng-hide {
      opacity: 0;
    }

    &.ng-hide-remove {
      transition: all linear .3s;
    }
  }
}