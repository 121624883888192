.create-freestyle {
  background-color:#F7F7FD;

  .teams-holder {
    max-height: 200px;
    overflow-y: auto;
  }

  .reupload-video {
    color: dodgerblue;
    float: right;
    cursor: pointer;
  }

  .btn {
    width: 100%;
    white-space: normal;
  }

  .challenge-type-header {
    color: dodgerblue;
  }

  .challenge-type-panel {
    padding-top: 3%;
  }

  .challenge-type-panel-lower {
    padding-bottom: 3%;
  }

  .challenge-type-desc {
    margin-top: 2%;
  }

  .challenge-button {
    margin-top: 4%;
  }

  .header {
    padding-top: 3%;
    h4, h5 {
      margin-left: 5%;
    }
  }

  input, textarea {
    width: 100%;
    font-size: 14px;
    border-color: #C3C3CA;
    &:disabled{
      background-color: #fff;
      opacity: 0.4;
    }
  }

  input {
    height: 30px;
  }

  textarea {
    resize: none;
    height: 70px;
  }

  .challenge-name, .challenge-description {
    margin-left: 3%;
    margin-bottom: 2px;
  }

  .challenge-description {
    margin-top: 10px;
  }

  .buttons-group {
    margin-bottom: 5%;
  }

  .reupload {
    color: dodgerblue;
    float: right;
    margin-top: 15px;
  }

  .reupload, .select-other {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .team-item {
    margin-top: 20px;
    border-bottom: 1px solid #DAD5D5;
    margin-bottom: 5px;
  }

  .team-logo-holder {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
  }

  .team-logo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .team-name {
    padding-top: 5%;
    word-wrap: break-word;
  }

  .team-item .glyphicon-ok {
    margin-top: 20px;
  }

  .item-lower {
    margin-top: 10px;

    .stats {
      margin-right: 10px;
    }

    img {
      width: 16px;
      margin-bottom: 2%;
    }
  }

  .win {
    color: #93CB53;
  }

  .loss {
    color: red;
  }

  .draw {
    color: #F7A724;
  }

  .team-selected {
    font-size: 12px;
    margin-left: 3%;
  }

  .selected-team-ok {
    font-size: 14px;
    color: dodgerblue;
  }

  .select-other {
    color: dodgerblue;
    float: right;
    margin-top: 3%;
  }

  .lower-teams {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .new-team-buttons {
    margin-top: 25%;
  }

  .team-logo-uploader {
    margin-top: 15px;
    position: relative;
    padding-bottom: 100%;
    border: 1px solid #000;
    .camera {
      position: absolute;
      top: 25%;
      width: 20%;
      left: 40%;
    }
    h6 {
      text-align: center;
      position: absolute;
      top: 50%;
      width: 80%;
      left: 10%;
    }
  }

  .new-team-form {
    margin-bottom: 25px;
  }

  .search-fans {
    span {
      display: inline-block;
    }

    .search-holder {
      width: 65%;
      margin-left: 2%;

      input {
        border-radius: 3%;
        background-image: url(../img/magnifer_icon.png);
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-position: left 7px center;
        padding-left: 30px;
      }
    }
  }

  .avatar-holder {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .username {
    margin-top: 7%;
  }

  .user-status {
    color: #C3C3CA;
  }

  .user-icon {
    width: 20px;
    margin-left: 5px;
    margin-top: 3px;
  }

  .add-player {
    padding-top: 3%;
  }

  .add-player-button {
    padding-left: 0;
    padding-right: 0;
  }

  .create-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .item-fan {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fans-list {
    max-height: 200px;
    overflow-y: auto;
  }

  .active-team {
    color: dodgerblue;
  }

  .team-selected-holder {
    word-wrap: break-word;
  }

  @media (max-width: 1023px) {
    .challenge-type-desc {
      font-size: 12px;
    }

    input {
      height: 25px;
    }

    .btn {
      font-size: 10px;
    }

    .item-lower {
      h6 {
        font-size: 10px;
      }

      .stats {
        margin-right: 2px;
      }

      img {
        width: 10px;
        margin-bottom: 1%;
      }
    }

    .team-name h4 {
      font-size: 12px;
    }

    .team-item .glyphicon-ok {
      top: 0;
    }

    .create-team-button {
      padding-left: 0;
      padding-right: 0;
    }

    .team-logo-uploader h6 {
      font-size: 8px;
    }
  }
}

.new-challenge-team-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
}
