manager-product-description-preview > span {
  display: block;

  span.stats-detail {
    display: block;
    text-align: left;
    color: #47454e;
  }

  h4.stats-label {
    font-weight: bold;
    letter-spacing: .4px;
  }
}