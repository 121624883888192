welcome-card > div.card {
  padding: 32px;
  color: #333;
  background-image: url('../img/icon-football.png');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 32px);
  background-position-y: 32px;
  width: 100%;

  h1.title {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 44px;
  }

  h3.subtitle {
    margin-top: 42px;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 27px;
  }

  p {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    max-width: 75%;

    @media(max-width: 1130px) {
      max-width: 100%;
    }
  }

  h3.download-title {
    margin-top: 40px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }

  div.text-holder {
    width: 63%;
    display: inline-block;

    @media(max-width: 1130px) {
      width: 100%;
      display: block;
    }
  }

  div.buttons-holder {
    width: 37%;
    display: inline-block;

    @media(max-width: 1130px) {
      width: 100%;
      display: block;
    }
  }

  app-store-download-button > button,
  google-play-download-button > button {
    margin-top: 24px;
  }

  google-play-download-button > button {
    margin-left: 24px;
  }

  &.mobile {
    padding: 10vw;
    background-size: 100%;
    background-position-x: calc(~"100% + 10vw");
    background-position-y: calc(~"100% + 40vw");

    h1.title {
      font-size: 10vw;
      line-height: 13.75vw;
    }

    h3.subtitle {
      margin-top: 13.75vw;
      font-size: 6.25vw;
      line-height: 7.5vw;
    }

    p {
      margin-top: 7.5vw;
      font-size: 5vw;
      line-height: 7.5vw;
      max-width: 100%;
    }

    div.text-holder {
      width: 100%;
      display: block;
    }

    div.buttons-holder {
      width: 100%;
      display: block;
    }

    h3.download-title {
      margin-top: 24vw;
      font-size: 5vw;
      line-height: 7.5vw;
    }

    app-store-download-button > button,
    google-play-download-button > button {
      margin-top: 5vw;
      width: calc(~"100% - 22vw");
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}