.delete-skill-modal {
  position: absolute;
  z-index: 200;
  left: 4px;
  right: 4px;
  height: 100%;
  top: 0;
  background-color: #F7F7FD;
  border: 1px solid #DBDBEA;
  border-right: none;
  padding: 1% 0;
  padding-left: 3%;
  display: flex;
}

.delete-skill-modal h5 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.delete-skill-modal .delete-skill-inner {
  background-color: #FFF;
  min-height: 52px;
  border: 1px solid #DBDBEA;
  float: right;
  align-items: stretch;
}

.delete-skill-modal .delete-prompt {
  color: #EA8082;
}

.delete-skill-inner.animate-show {
  padding: 5px 15px;
  width: 100%;
}

.delete-skill-inner.animate-show.ng-hide-add, .delete-skill-inner.animate-show.ng-hide-remove {
  transition: all linear 0.2s;
}

.delete-skill-inner.animate-show.ng-hide {
  width: 0;
  padding: 5px 0;
  overflow: hidden;
  margin-left: 100%;
}
