player-freestyle-videos-challenger-detail > div.challenger-detail {
  text-align: center;

  .challenger-avatar {
    width: 50%;
    margin-top: 30px;
  }

  .challenger-name {
    color: dodgerblue;
    margin: 20px 0;
  }

  .country {
    font-weight: bold;

    span {
      display: inline-block;
    }
  }

  .badges-count {
    display: inline-block;

    &.margin {
      margin-left: 5%;
    }

    span:nth-child(2) {
      margin-left: 5px;
    }
  }

  .flag {
    height: 15px;
    width: 25px;
    margin-left: 5px;
  }
}