.myFans {
  padding-top: 15px;
  margin-left: 0;
  margin-right: 0;

  .block {
    margin: 5px 0;
    border: 1px solid #DBDBEA;

    .header {
      text-align: center;
      padding: 6px 0;
      background: #E4E4EF;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;

      .my-fans-link {
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .content {
      background: #fff;
      padding: 0;
      padding-bottom: 10px;
    }
  }//block

  .myFans-player {
    padding: 0 5px;
  }

  .myFans-player-info {
    display: table-row;
    padding-top: 4%;
  }

  .black-plus {
    color: #000;
    margin-top: 25%;
  }

  .blue-ok {
    color:#498FE1;
    margin-top: 25%;
  }

  .myFans-username {
    color: #9f9f9f;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    .block .header {
      font-size: 14px;
    }

    .myFans-username {
      font-size: 11px;
      display: block;
    }

    .myFans-player-info {
      padding: 0 5px;
      margin-top: -5px;
      padding-top: 8%;
    }
  }
}//myFans
