organization-role-selector > div.row {
  margin-bottom: 10px;
  margin-right: 0;
  .suborgs-selection-holder {
    @media (min-width: 768px) {
      text-align: right;
      padding-right: 20px;
    }
  }
  span {
    font-size: 14px;
  }
  .rolename-holder {
    input {
      display: inline-block;
      vertical-align: top;
    }
    span {
      display: inline-block;
      width: calc(~"100% - 20px");
    }
  }
}