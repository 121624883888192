manager-create-wizard-steps > div {
  .bs-wizard {
    margin-top: 40px;
    padding-bottom: 10px;

    & > manager-create-wizard-steps-item {
      .bs-wizard-step {
        &.disabled > .bs-wizard-dot {
          background-color: #f5f5f5;
          &:after {
            opacity: 0;
          }
        }
        &.disabled a.bs-wizard-dot { pointer-events: none; }
        & > .progress > .progress-bar {
          transition: 0s;
        }
        &.complete > .progress > .progress-bar {
          left: auto;
          right: 0;
          width: 100%;
        }
        &.active > .progress > .progress-bar {
          width: 50%;
        }
        &.complete a.bs-wizard-dot {
          cursor: pointer;
        }
        padding: 0;
        position: relative;
        .bs-wizard-stepnum {
          color: #989898;
          font-size: 16px;
          margin-bottom: 5px;
          font-family: 'AvenirNextLTPro Bold';
        }
        &.active .bs-wizard-stepnum, &.complete .bs-wizard-stepnum {
          color: dodgerblue;
        }
        .bs-wizard-info {
          color: #999;
          font-size: 14px;
        }
        .bs-wizard-dot {
          position: absolute;
          width: 30px;
          height: 30px;
          display: block;
          background: dodgerblue;
          top: 45px;
          left: 50%;
          margin-top: -15px;
          margin-left: -15px;
          border-radius: 50%;
          &:after {
            content: ' ';
            width: 14px;
            height: 14px;
            background: #000;
            border-radius: 50px;
            position: absolute;
            top: 8px;
            left: 8px;
          }
        }

        .progress {
          position: relative;
          border-radius: 0px;
          height: 8px;
          box-shadow: none;
          margin: 20px 0;
          background-color: #f5f5f5;
          background-image: none;
          & > .progress-bar {
            box-shadow: none;
            background: dodgerblue;
          }
        }
      }
      &:first-child .bs-wizard-step {
        .bs-wizard-dot,
        .progress {
          left: 15px;
        }
        .bs-wizard-stepnum {
          text-align: left;
        }
        &.active > .progress > .progress-bar {
          width: 0%;
        }
      }
      &:last-child .bs-wizard-step {
        &.active > .progress > .progress-bar{
          width: 100%;
        }
        .progress { right: 15px; }
        .bs-wizard-dot {
          left: auto;
          right: 0;
        }
        .bs-wizard-stepnum {
          text-align: right;
        }
      }
    }
  }
}