players-list {
  input.form-control {
    border-radius: 4px;
  }

  div.inner-scroll-400 {
    overflow-y: scroll;
  }

  manager-list-header > div.container-fluid {
    padding: 10px 10px 10px 0;
  }
}