.contests {
  @headerHeight: 100px;
  @ribbonAndLogoWidth: 112px;
  @headerComponentBottomPadding: 450px;
  .row.padded-row {
    padding-left: 15px;
    padding-right: 15px;
  }

  .rules-link {
    a {
      color: dodgerblue;
    }
  }

  &.contest-landing-page {
    padding-bottom: 20%;
    .ribbon-holder {
      position: absolute;
      width: 100%;
      top: @headerHeight;
      left: 0;
      z-index: 11;
      &.docked {
        position: fixed;
        .header-background-holder {
          background-color: #003;
        }
      }

      &.docked-add, &.docked-remove {
        opacity: 0;
        -o-transition: opacity .5s;
        -ms-transition: opacity .5s;
        -webkit-transition: opacity .5s;
        -moz-transition: opacity .5s;
        transition: opacity .5s;
      }
      &.docked-add-active, &.docked-remove-active {
        opacity: 1;
      }
      &.ribbon-pushed-down {
        top: @headerHeight + 60px;
      }
      @media (max-width: 992px) {
        z-index: 10;
      }
      @media (max-width: 991px) and (min-width: 768px) {
        & > .container {
          width: 100%;
        }
      }
    }

    .logo {
      max-width: @ribbonAndLogoWidth;
      width: 100%;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
    .sponsors-height-holder {
      min-height: 60px;
    }
    .primary-sponsor-holder {
      text-align: center;
      display: inline;
      .brought-by {
        color: #fff;
        font-size: 12px;
        display: inline-block;
        vertical-align: sub;
        margin-top: 3px;
        @media (max-width: 600px) {
          font-size: 8px;
        }
        @media (max-width: 390px) {
          margin-top: 0;
          display: block;
        }
      }

      .contest-primary-sponsor {
        height: 8vw;
        max-height: 45px;
        display: inline-block;
        margin-left: 10px;
        @media (max-width: 400px) {
          margin: 0;
        }
        @media (max-width: 390px) {
          max-width: calc(~"100% - 60px");
        }
        @media (min-width: 391px) {
          max-width: calc(~"100% - 90px");
        }
      }
    }
  }
  .header-background-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0,0,51,0.6);
  }
  &.contest-landing-page {
    padding-bottom: 20%;
    .ribbon-holder {
      position: fixed;
      width: 100%;
      top: @headerHeight;
      left: 0;
      z-index: 11;
      &.ribbon-pushed-down {
        top: @headerHeight + 60px;
      }
      @media (max-width: 992px) {
        z-index: 10;
      }
      @media (max-width: 991px) and (min-width: 768px) {
        & > .container {
          width: 100%;
        }
      }
    }

    .tv-widget-col {
      background-clip: content-box;
      margin-top: 40px - @headerComponentBottomPadding;
      padding: 0;
      z-index: 9;
      @media (min-width: 992px) {
        padding-left: 126px;
        padding-right: 126px;
      }
    }

    .leaderboard-widget-col {
      background-clip: content-box;
      padding-right: 0;
      z-index: 9;
      @media (max-width: 767px) {
        margin-top: 30px;
        padding-left: 0;
      }
    }

    iframe {
      border: none;
      width: 100%;
      padding: 1px;
      @media (min-width: 1816px) {
        width: 99.8%;
      }
    }
  }

  &.contest-page-header {
    padding-bottom: @headerComponentBottomPadding;
    @media (max-width: 767px) {
      padding-left: 5px;
      padding-right: 5px;
    }
    & > .container {
      padding-bottom: 0;
    }
    .background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
    .contest-info {
      &.larger-padding-top {
        @media (max-width: 991px) {
          padding-top: 275px;
        }
      }
      text-align: center;
      padding-top: @headerHeight + 40px;
      .contest-name {
        font-family: 'AvenirNextLTPro Bold';
      }
      .contest-description {
        font-family: 'AvenirNextLTPro Bold';
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 18px;
      }
      .funds-raised {
        font-family: 'AvenirNextLTPro Bold';
        margin-top: 40px;
      }
    }

    .btn-join {
      background-color: #ff8827;
      border-color: #ce6c1c;
      font-size: 16px;
      min-width: 146px;
      max-width: 250px;
      white-space: normal;
      word-wrap: break-word;
      &:active, &:hover {
        background-color: #c36a21;
        border-color: #ad5b19;
      }
      &:focus {
        background-color: #a95d1f;
        border-color: #ad5b19;
      }
      width: auto;
    }

    .page-links {
      color: #fff;
      margin-top: 20px;
      padding-top: 20px;
      .page-link-holder {
        width: 33%;
        display: inline-block;
        text-align: center;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        &:only-child {
          text-align: center;
        }
      }
      a.page-link {
        cursor: pointer;
        @media (max-width: 362px) {
          font-size: 12px;
        }
      }
      &.fixed-top {
        position: fixed;
        width: 100%;
        height: 60px;
        top: 100px;
        margin: 0;
        left: 0;
        z-index: 10;
        padding: 20px 0 20px 0;
        background-color: #003;
        .container {
          padding-bottom: 0;
        }
      }
      &:not(.fixed-top) {
        height: 40px;
      }

      &.fixed-top.ng-hide-add, &.fixed-top.ng-hide-remove {
        opacity: 0;
        -o-transition: opacity .5s;
        -ms-transition: opacity .5s;
        -webkit-transition: opacity .5s;
        -moz-transition: opacity .5s;
        transition: opacity .5s;
      }

      &.fixed-top.ng-hide-remove-active {
        opacity: 1;
      }

      &.fixed-top.ng-hide {
        opacity: 0;
      }
    }
  }

  .page-footer {
    .anchor-link {
      display: block;
      position: absolute;
      visibility: hidden;
    }
    .how-it-works-leaderboard-padding {
      @media (min-width: 992px) {
        padding-left: 142px;
        padding-right: 142px;
      }
    }
    .footer-upper {
      border-bottom: 2px solid #ccc;
      padding: 20px 0;

      .main-sponsor-img {
        max-width: 100%;
        max-height: 200px;
      }

      .prizes-header {
        color: #ff8827;
        margin-bottom: 30px;
        display: block;
        letter-spacing: 3px;
        font-family: 'AvenirNextLTPro Bold';
      }

      .how-it-works-holder {
        padding-left: 0;
        @media (max-width: 767px) {
          padding-right: 0;
        }
        .how-it-works {
          padding: 30px;
          border: 1px solid;
          border-radius: 12px;
          width: 100%;
          background-color: #fff;

          .how-it-works-header {
            color: #ff8827;
            letter-spacing: 3px;
            text-align: center;
            font-family: 'AvenirNextLTPro Bold';
          }

          .how-it-works-body {
            margin-top: 20px;
            p {
              margin-top: 20px;
              text-align: center;
              word-wrap: break-word;
            }
            h3 {
              margin-top: 20px;
            }
            .how-it-works-content h3 {
              font-family: 'AvenirNextLTPro Bold';
              text-align: left;
            }
          }
        }
      }
    }

    .sponsors-header {
      text-align: center;
      padding: 10px 0;
      font-style: italic;
      color: #9a9898;
    }

    .sponsors-list img {
      height: 10em;
      margin: 1%;
    }
  }

  .dropdown-menu {
    z-index: 10;
    width: 50%;
    padding: 10px;
    margin-left: 25%;
    @media (max-width: 375px) {
      width: 100%;
      margin-left: 0;
    }
  }

  .dropdown {
    background: none;
  }
}
