same-product-paths-error-message > div {
  background-color: #ffeaee;
  padding-top: 35px;
  padding-bottom: 40px;
  text-align: center;

  h4.message {
    color: #eb494a;
  }

  button.btn {
    font-weight: bold;
    width: 200px;
    margin: 5px;
    border-radius: 4px;

    &.btn-outline-danger {
      background: #fff;
      color: #c9302c;
      border-color: #ac2925;
    }
  }
}