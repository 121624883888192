.widget-filter-dropdown {
  input {
    border-radius: 4px;
  }

  .input-container {
    @media(max-width: 320px) {
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
    }
  }

  .list-item {
    @media (min-width: 321px) {
      margin-top: 20px;
    }

    @media (max-width: 320px) {
      padding-top: 10px;
      padding-bottom: 10px;
      &:not(:first-child) {
        border-top: 1px solid #ccc;
      }
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  }

  @media (min-width: 321px) {
    padding-bottom: 25px;
  }
}
