.topya-tv-player {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 58.25%;

  .videogular-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .player-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    .play-icon {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      opacity: 0.7;
      &.animate-play-icon.ng-hide {
        opacity: 0;
      }

      &.animate-play-icon.ng-hide-add,
      &.animate-play-icon.ng-hide-remove {
        transition: all linear 0.2s;
      }

      @media (max-width: 425px) {
        width: 25%;
      }
    }
    &:hover .play-icon {
      opacity: 0.9;
    }
  }

  .player-controls {
    position: absolute;
    background-color: rgba(0,0,0,0.9);
    width: 100%;
    padding: 1%;
    bottom: 0;
    color: #fff;
    z-index: 3;

    &.animate-controls.ng-hide {
      opacity: 0;
    }

    &.animate-controls.ng-hide-add,
    &.animate-controls.ng-hide-remove {
      transition: all linear 0.1s;
    }

    .controls-button {
      margin: 0 10px;
    }

    .video-progress-holder {
      width: 35%;
      position: relative;
      height: 8px;
      margin-top: 2px;
      display: inline-block;
      vertical-align: text-top;
      margin-left: 2%;
    }

    .volume-holder {
      width: 15%;
      position: relative;
      height: 8px;
      margin-top: 2px;
      display: inline-block;
      vertical-align: text-top;
    }

    span[class*='glyphicon-volume'], span.glyphicon-backward {
      margin-left: 3%;
    }

    span.glyphicon-forward {
      margin-left: 2%;
    }

    .control-bar {
      position: absolute;
      height: 100%;
      &.background {
        background-color: #9b9b9b;
        width: 100%;
      }
      &.buffered-value {
        background-color: #b0afaf;
        width: 0%;
      }
      &.current-value {
        background-color: #fff;
      }
    }

    @media (max-width: 525px) {
      font-size: 8pt;

      .video-progress-holder, .volume-holder {
        height: 6pt;
        vertical-align: baseline;
      }

      span[class*='glyphicon'] {
        vertical-align: top;
      }
    }

    @media (max-width: 445px) {
      padding-top: 10pt;

      .video-progress-holder {
        width: 93%;
        margin: 0;
        left: 3.5%;
        position: absolute;
        bottom: 19pt;
        height: 3pt;
        &:hover {
          height: 5pt;
          bottom: 18pt;
        }
      }

      .volume-holder {
        width: 20%;
        height: 3pt;
        margin-bottom: 1.5pt;
        &:hover {
          height: 5pt;
          margin-bottom: 0;
        }
      }
    }

    @media (max-width: 249px) {
      .controls-button {
        margin: 0 5px;
      }

      span[class*='glyphicon'] {
        font-size: 7pt;
      }
    }

    @media (max-width: 199px) {
      .video-time {
        display: none;
      }
    }
  }

  .topya-logo {
    position: absolute;
    right: 2%;
    bottom: 10%;
    width: 15%;
  }

  .customer-logo {
    position: absolute;
    width: 10%;
    bottom: 10%;
    left: 3%;
    opacity: 0.6;
  }

  @media (max-width: 425px) {
    padding-bottom: 66%;

    .customer-logo {
      width: 13%;
    }

    .topya-logo {
      width: 25%;
    }
  }
}
