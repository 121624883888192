.player-fundraiser-stats {
  padding-bottom: 50px;
  .fundraiser-name {
    font-family: 'AvenirNextLTPro Bold';
    width: 100%;
    text-align: center;
    margin-top: 150px;
    a {
      color: #4a4a4a;
      cursor: pointer;
    }
  }

  .fundraiser-description {
    font-family: 'AvenirNextLTPro Bold';
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 18px;
    color: #4a4a4a;
    word-wrap: break-word;
  }

  .fundraiser-info {
    margin-top: 80px;

    .player-avatar {
      width: 100%;
      max-width: 10em;
    }

    .player-username {
      font-family: 'AvenirNextLTPro Bold';
      color: #4a4a4a;
      display: block;
      margin-top: 15px;
      font-size: 20px;
    }

    .stats-holder {
      button.btn.btn-primary.make-pledge {
        margin-top: 40px;
        background-color: #ff8827;
        border-color: #e47a24;
        font-size: 20px;
        padding: 15px;
        width: 100%;
        font-weight: bold;
        white-space: normal;
        word-break: break-word;
      }

      .contest-stats-box {
        border: 1px solid #a3a3a3;
        border-radius: 4px;
        text-align: center;
        padding: 10px 5px;
        max-width: 170px;
        width: 100%;
        display: inline-block;
        span {
          display: block;
          &.value {
            color: #ff8827;
            font-family: 'AvenirNextLTPro Bold';
            font-size: 30px;

            @media (max-width: 1199px) {
              font-size: 25px;
            }
          }
          &.type {
            color: #292929;
            font-size: 14px;
          }
        }
      }
      @media (max-width: 767px) {
        margin-top: 40px;
      }
    }
  }
}
