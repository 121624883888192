contest-sponsors-reorder ul.sponsors-sorting {
  &.bordered {
    border: 1px dashed #bbb;
  }

  input[readonly] {
    background-color: #fff;
    border: none;
    box-shadow: none;
    padding: 0;
    font-size: 15px;
    cursor: default;
  }

  li {
    padding-top: 30px;
    margin-bottom: 10px;
    min-height: 140px;

    .border-holder {
      border-bottom: 2px dashed #d1d1d1;
      position: absolute;
      bottom: 10px;
      width: calc(~"100% - 30px");
    }

    .primary-sponsor-selector {
      border: 1px solid #d1d1d1;
      border-radius: 8px;
      overflow: hidden;
      div:first-child {
        padding: 10px;
        background-color: #eee;
        border-right: 1px solid #d1d1d1;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }

  .primary-sponsor-selector {
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    overflow: hidden;
    div:first-child {
      padding: 10px;
      background-color: #eee;
      border-right: 1px solid #d1d1d1;
      margin-right: 10px;
      display: inline-block;
    }
  }

  image-drop-uploader img {
    max-height: 80px;
    max-width: 100%;
  }
  
  .delete-sponsor-icon {
    color: #e4000b;
  }
}