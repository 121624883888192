org-funds-distribution-input > div {
  input.form-control {
    border-radius: 4px;
    width: 80px;
    display: inline-block;
    margin-right: 10px;
  }

  div.btn-group.dropdown {
    display: inline-block;
    width: 250px;
    .dropdown-menu,
    button.btn {
      width: 100%;
    }
  }

  span.glyphicon {
    margin-left: 10px;
    vertical-align: middle;
    &.glyphicon-remove {
      color: red;
    }
  }

  account-status-indicator > div {
    margin-left: 10px;
  }

  .percentage-input-holder {
    display: inline-block;
    margin-left: 15px;
  }
}