.freestyle {
  .team-list-item {
    padding-bottom: 3%;
    margin-bottom: 3%;
    word-wrap: break-word;

    h3 {
      margin-top: 10%;
    }

    .team-info {
     margin-top: 2%;
    }

    .team-members-count {
      text-align: center;

      h4 {
       display: inline-block;
       margin-top: 10%;
      }
    }

    .team-member-avatar-holder {
      display: inline-block;
      width: 26%;
      min-width: 75px;
      margin-bottom: 5px;
    }

    .avatar-img {
      width: 50px;
      height: 50px;
    }

    .arrow-right {
      margin-top: 90%;
    }
  }

  .team-selected, .team-deselected {
    margin-top: 80%;
  }

  .team-selected {
    color: dodgerblue;
  }

  .my-teams-filter {
    text-align: right;
    border-top: none;
    border-bottom: none;
  }

  @media (max-width: 1023px) {
    .team-members-count img {
      width: 15px;
    }

    .team-list-item {
      .avatar-img {
        width: 25px;
        height: 25px;
      }

      .team-info {
        margin-top: 0;
      }
    }

    .team-members-count h4, .team-list-name {
      font-size: 10px;
    }

    .team-selected, .team-deselected {
      margin-top: 10%;
    }
  }
}

.my-teams-filter-holder {
  margin-top: -15px;
}

.my-teams-header {
  margin-bottom: 5%;
}
