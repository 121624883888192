search-form > form {
  button,
  button.btn.btn-default,
  button.btn.btn-default.new-btn-default {
    min-width: 0px;
    width: 100%;
  }

  button.btn.btn-default.new-btn-default {
    padding: 5px;
  }
}