player-fundraising-pledges > div {
  margin-top: 60px;
  font-weight: bold;
  color: #8B8B8B;

  .table {
    background-color: #fff;
    border: 1px solid #9C9C9C;
    padding: 15px;
  }

  .table-title {
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
    display: block;
  }

  span.table-subtitle {
    display: block;
    margin-top: 10px
  }

  .filter-buttons {
    margin-top: 40px;

    & > div {
      text-align: center;

      & > button.btn.fundraiser-stats-button {
        margin-top: 20px;
        width: 200px;
        display: block;
        font-weight: bold;
        margin-left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }
  }

  .status-messages-holder {
    widht: 100%;
    padding-right: 10px;
  }

  .pledges-list-holder {
    max-height: 405px;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(~"100% + 10px");
  }

  .table-header {
    color: #8B8B8B;
    font-weight: normal;
    margin-top: 40px;

    hr {
      border-top: 1px solid #bdbcbc;
      margin: 0;
    }
  }

  &.hybrid-frame {
    font-size: 20pt;
    border: none;
    padding: 0;
    margin-top: 0;

    & > .table {
      border: none;
    }

    & > div > span.table-subtitle {
      font-size: 25pt;
      letter-spacing: .5pt;
      margin-top: 25px;
      color: #5d5d5d;
      padding: 0 50pt;
    }

    .filter-buttons > div > button {
      width: 250px;
      font-size: 20pt;
      padding: 7pt 3pt;
      max-width: 100%;
    }
  }
}