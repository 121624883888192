breadcrumbs > div {
  margin-top: 8px;
  padding-top: 8px;
  height: 32px;
  display: inline-block;

  span.paths-holder {
    display: inline-block;
  }

  span.path {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    color: #999;

    &.active-path {
      color: #333;
      font-weight: 900;
    }
  }

  span.separator {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    color: #999;
    margin-left: 12px;
    margin-right: 12px;
  }

  &.mobile {
    height: 7.5vw;
    margin-top: 4.7vw;
    padding-top: .9vw;

    span.path,
    span.separator {
      font-size: 3.75vw;
      line-height: 6.6vw;
    }

    span.separator {
      margin-left: 4vw;
      margin-right: 4vw;
    }
  }
}
