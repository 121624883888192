.player-skill-videos {
  .player-skill-col-lg {
    width: 16%;
  }

  .player-skill-col-md {
    width: 13%;
  }

  .player-skill-col-sm {
    width: 5%;
  }

  manager-column-head > div {
    padding: 0;

    &:not(.active-column) span[class*='glyphicon-star'].nominated-icon {
      background-color: #003;
      color: #fff;
      border: none;
    }

    &.active-column span[class*='glyphicon-star'].nominated-icon {
      background-color: #003;
      color: dodgerblue;
      border: none;
    }
  }

  div[class^='player-skill-col'] {
    position: relative;
    display: inline-block;
    vertical-align: top;
    word-break: break-word;
    &.padded {
      padding-right: 10px;
    }

    @media(max-width: 1023px) {
      font-size: 12px;
    }

    span {
      @media(max-width: 1023px) {
        &:not(.glyphicon) {
          font-size: 12px;
        }
        &.glyphicon {
          font-size: 14px;
        }
      }

      &:nth-child(2) {
        vertical-align: sub;
        margin-left: 5px;
        @media(max-width: 879px) {
          display: none;
        }
      }

      &:first-child.glyphicon {
        font-size: 16px;
        vertical-align: sub;
      }
    }
  }

  .player-skill-info {
    .player-avatar {
      width: 20%;
      margin-top: 50px;
    }

    .player-name {
      color: dodgerblue;
    }

    .skill-stats {
      font-weight: bold;
      font-size: 16px;
      .skill-stats-icon {
        &.glyphicon {
          color: #498FE1;
        }

        margin-right: 5px;
        &:not(:first-child) {
          margin-left: 10%;
          @media(max-width: 1023px) {
            margin-left: 5%;
          }
        }

        img {
          width: 16px;
          height: 16px;
          vertical-align: top;
        }
      }
    }

    .difficulty-icon {
      height: 20px;
      vertical-align: sub;
      margin-right: 10px;
    }

    .skill-badge {
      margin-top: 100px;
      max-width: 30px;
      max-height: 50px;
    }

    .content-management-link {
      color: dodgerblue;
      cursor: pointer;
    }

    .star-gray {
      color: #ccc;
    }

    .star-yellow {
      color: #ff8827;
    }

    textarea {
      resize: vertical;
    }
  }
}

.player-skill-videos .active-filter .points-icon {
  background-color: dodgerblue;
  color: #fff;
  border-color: dodgerblue;
}

.player-skill-videos.review .review-table list-loading-overlay .list-loading-overlay {
    width: 100%;
    left: 0;
}
