role-builder > div.container {
  h1 {
    font-family: 'AvenirNextLTPro Bold';
  }

  button.btn {
    border-radius: 4px;
    &.add-role {
      width: auto;
      float: right;
      display: inline-block;
      width: 250px
    }
  }
}