player-freestyle-videos-detail-header > div {
  .player-challenge-col-lg {
    width: 17%;
  }

  .player-challenge-col-md {
    width: 11%;
  }

  .player-challenge-col-sm {
    width: 8%;
  }

  .player-challenge-col-xs {
    width: 5%;
  }

  div[class^='player-challenge-col'] {
    position: relative;
    display: inline-block;
    vertical-align: top;
    word-break: break-word;
    &.padded {
      padding-right: 10px;
    }
  }
}
