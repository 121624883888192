.player-fundraiser-stats.mobile {
  .fundraiser-info {
    .stats-holder {
      .contest-stats-box {
        margin-left: -10px;
        margin-right: -10px;
        width: calc(~"100% + 20px");
        margin-left: -10px;
        margin-right: -10px;
        span.value {
          font-size: 20px;
        }
        span.type {
          font-size: 11px;
        }
      }
    }
  }
}
