.recommend {
  .recommend-text-wrapper {
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 0;
    background-color: #F7F7FD;
  }

  .recommend-text, .my-skills-text {
    color: black;
    font-size: small;
    font-weight: bold;
  }

  .page-header-wrapper {
    margin: 10px 0;
    font-weight: bold;
  }

  .selectedTab {
    color: #498FE1;
  }

  .dropdown {
    width: 100px;
    a {
      text-align: left;
      padding-left: 0px;
    }
  }

  .dropdown-menu {
    min-width: 100px;
    li {
      list-style-type: none;
    }
  }

  .recommend-path-text-wrapper {
    background-color: #E7EDF8;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .arrow {
    position: relative;
    top: 15px;
    width: 15px;
  }

  .pathName {
    font-size: medium;
    color: black;
    font-weight: bold;
  }

  .skillsCount {
    color: #498FE1;
  }

  .recommend-path-text-wrapper .img-wrapper {
    text-align: center;
    position: relative;
    img {
      max-height: 58px;
      max-width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: auto;
      transform: translate(-50%, -50%);
    }
  }

  .pathName .gray {
    color: lightgray;
    font-size:smaller;
  }
}
