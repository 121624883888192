parent-add-kid > form.container-fluid {
  padding: 0;

  h3.title {
    color: #333333;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 6px;
  }

  view-title > div {
    margin-top: 24px;
  }

  div.form-fields-holder {
    padding: 24px;
    background-color: #fff;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    text-input:not(:first-of-type) > span.label,
    calendar-input > span.label,
    parent-add-kid-invitation-code text-input > span.label {
      margin-top: 24px;
    }

    div.or {
      margin-top: 40px;

      & > span.or-divider {
        display: inline-block;

        &.or-divider-text {
          color: #999;
          font-size: 16px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 24px;
          margin: 0 24px;
          vertical-align: text-top;
        }

        &.or-divider-left,
        &.or-divider-right {
          width: calc(~"50% - 40px");
          border: 1px solid #D8D8D8;
        }
      }
    }

    @media(min-width: 1200px) {
      text-input > input.form-control, hr, div.or,
      calendar-input > div.input-holder,
      parent-add-kid-invitation-code > div > button.btn.btn-primary {
        width: 50%;
      }
    }
  }

  & > button.btn {
    width: auto;
    margin-top: 24px;

    &:not(.cancel) {
      margin-left: 16px;
    }
  }

  error-message > div {
    margin-top: 40px;

    @media(min-width: 1200px) {
      width: 50%;
    }
  }

  div.gender-select {
    margin-top: 24px;

    span.gender-label {
      color: #333;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 25px;
      margin-bottom: 8px;
      padding: 0;
      display: block;
      text-align: left;
      width: auto;
    }

    toggle-input:nth-of-type(2) > span:first-child {
      margin-left: 16px;
    }
  }

  &.mobile {
    h3.title {
      font-size: 6.25vw;
      line-height: 7.5vw;
    }

    div.form-fields-holder {
      padding: 7.5vw;

      text-input:not(:first-of-type) > span.label,
      calendar-input > span.label,
      parent-add-kid-invitation-code text-input > span.label {
        margin-top: 7.5vw;
      }div.or {
        margin-top: 12.5vw;

        & > span.or-divider {
          &.or-divider-text {
            font-size: 5vw;
            line-height: 7.5vw;
            margin: 0 7.5vw;
            vertical-align: middle;
          }

          &.or-divider-left,
          &.or-divider-right {
            width: calc(~"50% - 120px");
            border: 2px solid #D8D8D8;
          }
        }
      }
    }

    view-title > div {
      margin-top: 7.5vw;
    }

    div.gender-select {
      margin-top: 7.5vw;

      span.gender-label {
        font-size: 4.4vw;
        line-height: 6.6vw;
        margin-bottom: 2.5vw;
      }

      toggle-input:nth-of-type(2) > span:first-child {
        margin-left: 5vw;
      }
    }

    & > button.btn {
      font-size: 5vw;
      line-height: 7.5vw;
      border-radius: 1.25vw;
      margin-top: 10vw;
      padding: 2.5vw 5vw;

      &:not(.cancel) {
        margin-left: 2.5vw;
      }
    }
  }
}