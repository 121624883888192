cancel-pledge-modal .modal-body {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  span.prompt-text {
    font-size: 16px;
  }

  button.btn {
    width: 260px;
    border-radius: 4px;
    margin-top: 20px;
    display: block;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .alert.alert-danger .close {
    margin-top: -10px;
    margin-right: 0;
  }
}