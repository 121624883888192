.activityFeed {
  .padding-top {
    padding-top: 15%;
  }

  .topya-main-page-content {
    margin-top: 20px;
  }

  .activity-feed-featured-username {
    margin-bottom:10px;
    display: block;
    margin-top: 5px;
  }

  .counts {
    color: #9e9e9e;
    padding-left: 20px;
    border-left: #F7F7FD 1px solid;
  }

  .feed-filters {
    max-height: 200px;
    overflow-y: auto;
    li {
      margin-top: 10px;
    }
  }

  .popover-content ul {
    list-style-type: none;
    padding: 0;
    font-family: 'AvenirNextLTPro Regular', sans-serif;
  }

  .popover-content li:hover {
   text-decoration: underline;
  }

  .filter {
   text-align: center;
   span {
    cursor: pointer;
   }
  }

  .empty-feed-message {
    margin-top: 10px;
  }

  .post_icons{
    margin: 15px 0;

    span {
      margin-right: 15px;
      &:last-child {
        margin-right: 0px;
        float: right;
      }
    }
  }

  .name-date-wrapper {
    margin-bottom:10px;
  }

  .badge-img {
    width: 150%;
  }

  .activity-feed-featured-date {
    color: #aaaaaa;
  }

  .activity-feed-challenge-highlight-description {
    font-weight: bold;
    padding-left: 0px;
    color: #9e9e9e;
    margin-bottom: 10px;
  }

  .activity-feed-challenge-heading {
    font-weight: normal;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .add-high-five {
    cursor: pointer;
  }


  .add-high-five:hover,
  .add-high-five:focus {
    color: #4a90e2;
  }

  .high-fived {
    color: #4a90e2;
  }

  .activity-feed-video-wrapper {
    border-top: 1px solid #f7f7fd;
    padding-top: 15px;
  }

  .username-clickable:hover {
    text-decoration: underline;
  }

  .activity-feed-challenge-heading {
    font-weight: normal;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .activity-feed-challenge-vs {
    font-weight: lighter;
    margin-top: 100px;
    @media (min-width: 1280px) and (max-width: 1440px) {
      margin-top: 130px;
    }
  }

  .inner-scroll-holder {
    position: relative;
    width: calc(~"100% + 6px");
    padding: 0 15px 15px 15px;
    margin-top: 15px;
    display: inline-block;

    .topya-main-page-content {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .inner-scroll-border {
    margin-top: 0;
    border-top-color: #dbdbea;
  }
}//activityFeed

.activityFeed.mobile {
  .add-high-five {
    img {
      height: 15px;
    }
  }
}
