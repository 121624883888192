fundraiser-preview-tabs {
  & > .container-fluid {
    background-color: #fff;
    border: 1px solid #b9b6b7;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 400px;
  }
  .fundraiser-preview-tabs {
    @media (max-width: 991px) and (min-width: 768px) {
      font-size: 11px;
    }
    @media (max-width: 597px) {
      font-size: 11px;
      text-align: center;
    }
    span.link-holder {
      min-width: 75px;
      margin-top: 5px;
    }
  }
}