.manager-dashboard {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  .overview {
    padding-bottom:5px;
  }
  .task-main {
    border-left: 1px solid darken(#f2f2f2,20%);
    border-right: 1px solid darken(#f2f2f2,20%);
    border-bottom: 1px solid darken(#f2f2f2,20%);
    border-top: 1px solid darken(#f2f2f2,20%);
    .center-graph {
      margin-top: 10%;
      margin-bottom: auto;
    }
    canvas {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 15%;
    }
  }
  .task-sub {
    position: relative;
    border-left: 1px solid darken(#f2f2f2,20%);
  }

}
