virtual-coach-categories > div {
  div.table-header {
    padding-right: 10px;
  }

  div.category-item {
    padding: 10px 0;

    &:nth-child(odd) {
      background-color: #f9f9f9;
    }
  }
}