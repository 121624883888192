parent-my-kids-profile-privacy-settings > form.container-fluid {
  padding: 0;

  success-message > div,
  error-message > div {
    margin-bottom: 24px;
  }

  & > div.form-fields-holder {
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background-color: #FFF;
    padding-top: 16px;

    & > div.input-group {
      padding: 24px 40px;
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px dashed #E5E5E5;
      }

      p.explanation-label {
        color: #999;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        padding-left: 43px;
        margin-top: 16px;

        @media (min-width: 1200px) {
          width: 50%;
        }

        @media (max-width: 1199) {
          width: 100%;
        }
      }

      a {
       color: #4A90E2;
       font-size: 14px;
       font-weight: 500;
       letter-spacing: 0;
       line-height: 24px;
       margin-left: 43px;
      }
    }
  }

  & > button.btn {
    width: auto;
    margin-top: 24px;

    &:not(.cancel) {
      margin-left: 16px;
    }
  }

  &.mobile {
    success-message > div,
    error-message > div {
      margin-bottom: 7.5vw;
    }

    & > div.form-fields-holder {
      border-radius: 8px;
      border-width: 2px;
      padding-top: 5vw;

      & > div.input-group {
        padding: 7.5vw;

        &:not(:last-child) {
          border-width: 2px;
        }

        p.explanation-label {
          font-size: 5vw;
          line-height: 7.5vw;
          padding-left: 43px;
          margin-top: 5vw;
          padding-left: 13.75vw;

          @media (min-width: 1200px) {
            width: 100%;
          }

          @media (max-width: 1199) {
            width: 100%;
          }
        }

        a {
         font-size: 4.4vw;
         line-height: 7.5vw;
         margin-left: 13.75vw;
        }
      }
    }

    & > button.btn {
      margin-top: 10vw;
      font-size: 5vw;
      line-height: 7.5vw;
      padding: 2.5vw 5vw;
      border-radius: 2px;

      &:not(.cancel) {
        margin-left: 5vw;
      }
    }
  }
}
