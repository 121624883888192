@assignTeamOffsetTop: 17px;

.create-skill {
  position: absolute;
  z-index: 200;
  text-align: left;
  background-color:#F7F7FD;

  a {
    cursor: pointer;
  }

  .error-div {
    margin-bottom: 10px;
  }

  .teams-button {
    font-size: 12px;
    margin-top: 8px;
  }

  .path-name {
    padding-top: 10px;
  }

  .content-main {
    padding-bottom: 15px;
  }

  input, textarea {
    font-size: 14px;
  }

  .error-message {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
  }

  .gray {
    color: #A5A5A5;
  }

  .button-div {
    margin-top: 20px + @assignTeamOffsetTop;
  }

  .assign-path {
    text-align: right;
    padding-top: @assignTeamOffsetTop;
    @media (max-width: 1199px) {
      text-align: center;
    }

    h5 {
      color: #A5A5A5;
    }
  }

  .video-footer-holder {
    min-height: 32px;
    padding-right: 15px;
  }

  .video-footer {
    color: dodgerblue;
    margin-top: 10px;
    float: right;
    a:disabled {
      cursor: default;
      color: rgb(165,165,165);
    }
  }

  hr {
    border-top: 1px solid #DAD5D5;
  }

  input {
    margin-top: 10px;
    width: 100%;
    height: 30px;
  }

  textarea {
    border: 1px solid #ECECFB;
    max-height: 60px;
    min-height: 60px;
    width: 100%;
    margin-top: 10px;
    max-width: 100%;
    min-width: 100%;
    &:disabled {
      opacity: 0.4;
      background: #fff;
    }
  }

  button {
    &:disabled, &:disabled:hover {
      border-color: #E4E4EF;
      background-color: #E4E4EF;
      color: #888C88;
    }
  }

  .btn-full-width {
    width: 100%;
  }

  .assign-team-font {
    @media (max-width: 1360px) {
      font-size: 12px;
    }
    @media (min-width: 1361px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1024px) {
    .save-submit {
      font-size: 11px;
      padding: 0;
    }
  }
}
