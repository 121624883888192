player-freestyle-videos-detail-info {
  img {
    height: 17px;
    position: absolute;
    bottom: -3px;
  }

  h5 {
    margin-bottom: 15px;
  }

  .is-international {
      margin-left: 25px;
    }
}