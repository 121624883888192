register-invite-code {
  input[type="tel"] {
    padding-right: 15%;
    background-image: url(/img/question-mark.png);
    background-repeat: no-repeat;
    background-position: 101%;
    border-style: solid;
    border-width: 1px;
    border-color: #498FE1;
  }
}

.is-mobile register-invite-code {
  input[type="tel"] {
    background-size: contain;
  }
}