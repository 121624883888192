country-input {
  & > span.label {
    color: #333;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 8px;
    padding: 0;
    display: inline-block;
    text-align: left;
    width: auto;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-bottom: 2.5vw;
      display: block;
    }
  }

  & > span.helper-label {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 8px;
    margin-bottom: 8px;
    text-align: left;
    width: auto;
    padding: 0;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-top: -2vw;
      margin-bottom: 2.5vw;
      margin-left: 2.5vw;
      display: block;
      margin-left: 0;
    }
  }

  & > input.form-control {
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    background-color: #FFF;
    height: 40px;

    &.search-input {
      background-image: url('../img/magnifer_icon.png');
      background-repeat: no-repeat;
      background-position-x: 10px;
      background-position-y: 10px;
      padding-left: 35px;
    }

    &.mobile {
      font-size: 5.6vw;
      line-height: 7.8vw;
      margin-bottom: 2.5vw;
      height: 10vw;
      border-width :2px;
      border-radius: .6vw;

      &.search-input {
        background-position-x: 3vw;
        background-position-y: 3vw;
        background-size: 4vw;
        padding-left: 10vw;
      }
    }
  }

  @media only screen and (hover: none) and (pointer: coarse){
    ul.dropdown-menu {
      font-size: 48px;
    }
  }
}