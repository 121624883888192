signup-summary-user-detail > div {
  margin-top: 40px;

  & > div.view-title {
     background-color: #E5E5E5;
     color: #333333;
     font-size: 18px;
     font-weight: 900;
     letter-spacing: 0;
     line-height: 25px;
     padding: 16px;
  }

  & > div.info-group {
    display: block;
    margin-top: 24px;
    padding: 0 16px;

    & > span.info-label {
      color: #999999;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      display: block;
    }

    & > span.info-item {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      display: block;
      margin-top: 8px;
    }
  }

  &.mobile {
    margin-top: 12.5vw;

    & > div.view-title {
       font-size: 5vw;
       line-height: 7.5vw;
       padding: 5vw;
    }

    & > div.info-group {
      margin-top: 7.5vw;
      padding: 0 5vw;

      & > span.info-label {
        font-size: 4.4vw;
        line-height: 6.6vw;
      }

      & > span.info-item {
        font-size: 5vw;
        line-height: 7.5vw;
        margin-top: 2.5vw;
      }
    }
  }
}