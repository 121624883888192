players-list-item > div {
  padding: 15px 0;

  &.gray-background {
    background: #d7d7d7;
  }

  &.cursor-draggable {
    cursor: grab;
  }
}