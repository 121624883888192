parent-my-kids-list-header-item > span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;

  &.mobile {
    font-size: 4.4vw;
    line-height: 6.6vw;
  }
}