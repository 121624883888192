@componentMaxWidth: 350px;

user-privacy-controls-modal {
  padding-top: 30px;
  padding-bottom: 30px;

  .modal-footer {
    text-align: center;
    button.btn {
      width: 150px;
      border-radius: 4px;
      @media (max-width: 469px) {
        margin-top: 10px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .modal-body {
    .checkbox-group {
      margin-left: 50%;
      transform: translateX(-50%);
      &:last-child {
        margin-bottom: 25px;
      }
      @media (max-width: 469px) {
        label {
          font-size: 10px;
        }
      }
    }
  }
}
