.freestyle {
  .members-count {
    margin-top: 10px;

    img {
      display: inline;
      width: 1.5vw;
    }

    h5 {
      color: #9f9f9f;
      display: inline;
    }
  }

  .list-item {
    margin-bottom: 10px;
  }
}
