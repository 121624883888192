.drag-uploader {
  margin-top: 40px;
  width: 100%;
  padding: 6% 15px;
  text-align: center;
  border: 1px dashed #bbb;
  position: relative;
  &.drag-drop {
    opacity: 0.5;
  }

  .preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    opacity: 0.7;
  }

  h2 {
    margin-bottom: 15px;
    font-family: 'AvenirNextLTPro Bold';
  }

  a {
    color: #8b3bf4;
    cursor: pointer;
  }
}