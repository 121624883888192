.playerSkillsAcademy {
  .back-arr {
    margin-right: 10px;
  }

  .round-image-holder {
    width: 90%;
    margin-left: 10%;
  }

  .content-wrapper {
    border: 2px solid #DBDBEA;
    background-color: white;
  }
  .skills-type-wrapper {
    padding: 20px;
    .skills-type {
      padding: 10px;
      text-align: center;
      font-weight: bold;
    }
    .chosenType {
      color: #498FE1;
    }
  }
  .skillsAcademyPaths {
    .blackText {
      margin-bottom: 0px;
      font-size: large;
      color: black;
      font-weight: bold;
    }
    .pathSkillsCount {
      padding: 5px 0;
      color: #498FE1;
    }
    .videoContent {
      margin-top: 10px;
    }
    .heading {
      padding:10px;
      .blackText {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .coach-img {
      width: 100%;
    }
  }
  .skill-badge {
    width: 100%;
  }
  .skillsWrapper {
    .skill {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      span {
        position: relative;
        top: 4px;
      }
      .red-color {
        color: red;
        font-weight: bold;
      }
      .gray-color {
        color: gray;
        font-weight: bold;
      }
      .opacity-30 {
        opacity: 0.3;
      }
    }
  }
  .skillInformation {
    padding: 15px 0;
    .skill-name {
      font-weight: bold;
      margin-top: 4px;
    }
  }
  .gray-text {
    font-weight: bold;
  }
  .skillDetails {
    .skill-status {
      display: block;
    }
    .skill-description {
      text-align: justify;
      display: block;
      margin-bottom: 20px;
    }
    .skill-status-holder {
      margin-bottom: 20px;
    }
    span {
      padding-top: 5px;
      font-weight: bold;
    }
  }
  .skill-wrapper {
    margin: 10px 0;
  }//skill-wrapper
  .clickable:hover {
    text-decoration: underline;
  }
  .red-points {
    top: 3px;
    color: #CF011A;
    padding: 0;
  }

  ul {
    list-style:none;
  }

  .upload-video-link {
    color: dodgerblue;
    text-align: left;
  }

  .nav > li > a {
    padding-left: 0;
    padding-right: 0;
  }
}

.playerSkillsAcademyNew {
  .gray-background {
    background-color: #E4E4EF;
  }

  .go-to-store {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 10px;
  }

  .skills-type-wrapper {
    .second-dropdown {
      max-height:150px;
      min-height: 100px;
      height:auto;
    }
    .dropdown-menu {
      min-width: 120px;
      width:auto;
    }
  }

  .recommend-path-text-wrapper {
    padding: 15px;
    background-color: @coachAssignedItemBackgroundColor;
    .round-image-holder {
      background-color: @coachAssignedItemImageBackgroundColor;
    }
  }

  .coach-info {
    padding: 15px;
  }

  .coach-name {
    font-weight: bold;
  }

  .recommend-text {
    color: #989899;
  }

  .green-path, .coach-info  {
    background-color: @coachRecommendedItemBackgroundColor;
    .round-image-holder {
      background-color: @coachRecommendedItemImageBackgroundColor;
    }
  }

  .skillsCount {
    color: #989899
  }

  .pathName {
    position: relative;
    top: 10px;
  }

  .path-name:hover {
    text-decoration: underline;
    font-weight: bold;
  }

  .arrow {
    position: relative;
  }

  .coach-name-wrapper {
    position: relative;
    top: 10px;
  }

  .gray-text {
    color: #989899;
    margin-bottom: 5px;
  }

  .progress-bar-wrapper {
    position: relative;
    top: 15px;
  }

  .dropdown-wrapper {
    margin: auto;
    float: none;
    display: block;
  }

  .gray-dropdown a {
    color: lightgrey;
  }

  .skill {
    border-top: 1px solid #eeeeee;
  }

  .pathDescription {
    margin-top:10px;
    margin-bottom:10px;
    color: darkgray;
  }
}//playerSkillsAcademyNew
.playerSkillsAcademyNew > .nav > li > a:hover,
.playerSkillsAcademyNew .nav > li > a:focus,
.playerSkillsAcademyNew .nav .open > a,
.playerSkillsAcademyNew .nav .open > a:hover,
.playerSkillsAcademyNew .nav .open > a:focus
{
  background-color:white;
}

.assigned-by{
  background-color: #E7EDF8;
  padding: 10px;
  font-size: 16px;
  text-align: left;
  border-radius: 0px;
  color:black;
}