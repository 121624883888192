signup-tab > span.tab {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: inline-block;
  height: 48px;

  &.active-tab {
    font-weight: 900;
    border-bottom: 2px solid #4A90E2;
  }

  &.mobile {
    font-size: 5vw;
    line-height: 7.5vw;
    height: 10vw;

    &.active-tab {
      border-width: 4px;
    }
  }
}