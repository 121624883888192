error-message > div.alert.alert-danger {
  span {
    font-weight: bold;
  }

  &.mobile {
    font-size: 4.4vw;
    line-height: 6.6vw;
    padding: 5vw;
    padding-top: 6vw;
    text-align: justify;
    position: relative;

    a.close {
      font-size: 5vw;
      position: absolute;
      top: 1vw;
      right: 2vw;
    }
  }
}
