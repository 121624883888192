.topya-tv-channel-dropdown {
  &, a {
    color: #7f7f7f;
  }

  input.form-control {
    border-radius: 4px;
  }

  .filter-header {
    padding-top: 15px;
    text-align: center;

    hr {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }

  .channel {
    hr {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .item-logo-holder {
      width: 20%;
      display: inline-block;
      text-align: center;

      img.item-logo {
        max-width: 100%;
        max-height: 15pt;
        vertical-align: sub;
      }
    }
    .item-name {
      font-size: 9pt;
      margin-left: 5px;
      display: inline-block;
      overflow: hidden;
      width: 60%;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      float: right;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
      box-shadow: 0px 0px 1px #888888;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: #84c542;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

  .inner-scroll-200 {
    margin-bottom: 20px;
  }
}
