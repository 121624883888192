range-five-selector > div.container-fluid {
  label {
    margin-left: -15px;
  }
  & > div.buttons-holder {
    margin: 0 -20px;

    radio-button {
      & > div.radio-button {
        max-width: 20%;
        width: 90px;
        display: inline-block;
        margin: 0 1%;
      }
    }
  }
}