.leaderboardItem {
  padding-top: 15px;
  padding-bottom: 15px;
}

.leaderboard {
  .topya-side-block-content {
    background-color: #fff;
  }

  .dropdown-wrapper {
    border-bottom: 1px solid #f7f7fd;
  }

  .leaderboardItem {
    border-bottom: 1px solid #f7f7fd;
    .leaderboard-position {
      padding-top: 6%;
      text-align: center;
    }

    .leaderboard-player {
      padding-left: 0;
      padding-right: 0;
    }

    .leaderboard-player-info {
      padding-top: 6%;
      padding-left: 5px;
      padding-right: 5px;
    }

    @media (min-width: 1170px) {
      .leaderboard-player-info, .leaderboard-position {
        padding-top: 3%;
      }

      .leaderboard-position {
        padding-left: 5px;
      }
    }
  }

  .no-results {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    color: #9a9a9a;
    text-align: center;
  }
}//leaderboard

.leaderboards {
  margin-top: 20px;

  .header {
    background: #1B1C2C;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    height: 40px;
    padding-top: 10px;
  }

  .leaderboards-wrapper {
    border: 1px solid #C1BCB7;
    background-color: white;
  }

  .leaderboards-list {
    padding-left: 10px;
    padding-right: 10px;
  }

  .leaderboars-header {
    margin: 15px 0;
    color: #C1BCB7;
    font-weight: bold;
  }

  .leaderboars-header div img {
    height: 25px;
  }

  .no-teams-message {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .leaderboard-wrapper {
    padding: 5px;
  }
}//leaderboards

@media (min-width: 768px) and (max-width: 1280px) {
  .leaderboard {
    .leaderboard-position {
      padding-left: 5px;
      padding-right: 2px;
    }

    .block .header {
      font-size: 14px;
    }
  }

  .leaderboardItem .leaderboard-name, .leaderboard .leaderboard-username {
    font-size: 11px;
  }
}

.leaderboards-list {
  .leaderboards-members {
    .member {
      border-top: 1px solid #F7F7FD;
      padding: 10px 0;
    }

    .leaderboard-position {
      vertical-align: middle;
      font-size: 20px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .no-results {
    padding-top: 90px;
    padding-bottom: 90px;
    width: 100%;
    color: #9a9a9a;
    text-align: center;
  }

  .arrow {
    position: relative;
    top: 10px;
  }
}

.leaderboards-members .message {
  color: #C1BCB7;
}

.nav {
  color: black;
}

.player-header, .dropdown  a {
  text-align: center;
}

.leaderboard, .leaderboards {
  .no-teams-message {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .width-100 {
    width:100%;
  }

  .width-200px {
    width: 200px;
  }

  .dropdown {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dropdown-menu {
    margin-left: 50%;
    transform: translateX(-50%);
    min-width: 220px;
  }

  ul {
    margin-left: 35%;
    list-style: none;
  }
}//universal

.leaderboard-username {
  color: #9e9e9e;
}

.leaderboard-dropdown-wrapper {
  background-color: #E4E4EF;
  .dropdown a {
    background-color: #E4E4EF;
  }
  .dropdown .section-title {
    border-bottom: 2px solid whitesmoke;
  }
}

.dropdown-wrapper > .nav > li > a:hover, .dropdown-wrapper .nav > li > a:focus, .dropdown-wrapper .nav .open > a,
.dropdown-wrapper .nav .open > a:hover, .dropdown-wrapper .nav .open > a:focus {
  background: #E4E4EF;
}
