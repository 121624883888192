edit-product-paths-preview > div {
  div.preview {
    padding-top: 30px;
    padding-bottom: 30px;

    h4.paths-label {
      font-weight: bold;
      margin-bottom: 30px;
    }

    h4.skills-count-info {
      margin-top: 30px;
    }
  }
}