role-permissions-edit {
  input.form-control {
    width: 300px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
  }

  .margin-top-34 {
    margin-top: 34px;
  }
}