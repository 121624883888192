parent-my-kids-list-item-actions > div {
  display: inline;

  span.dropdown {
    display: inline-block;
    float: right;
    width: auto;
    margin-top: 8px;

    & > a {
      color: #4A90E2;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;

      &:focus {
        text-decoration: none;
      }

      span.glyphicon {
        font-size: 10px;
        margin-left: 8px;
      }
    }

    ul.dropdown-menu {
      right: 0;
      left: auto;
      width: 240px;
      border: none;
      border-radius: 0;
      box-shadow: 0px 1px 3px 0px #888;
      padding: 0;
      margin: 0;
      z-index: 99;
      &:before,
      &:after {
        display: none;
      }

      & > li {
        padding: 8px;
        color: #333;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: left;
        border: none;

        div.icon-holder {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          vertical-align: sub;
          display: inline-block;

          &.profile-icon-holder {
            background: url('../img/Icon/Content/Blue/edit-blue.png');
            background-position: center;
          }

          &.privacy-settings-icon-holder {
            background: url('../img/Icon/Action/Blue/settings-blue.png');
            background-position: center;
          }

          &.orgs-and-teams-icon-holder {
            background: url('../img/Icon/Content/Blue/add-filled-blue.png');
            background-position: center;
          }

          &.remove-kid-icon-holder {
            background: url('../img/Icon/Action/Blue/delete-blue.png');
            background-position: center;
          }
        }

        &:hover {
          background-color: #4A90E2;
          color: #fff;
          cursor: pointer;

          div.icon-holder {
            &.profile-icon-holder {
              background: url('../img/Icon/Content/White/edit-white.png');
              background-position: center;
            }

            &.privacy-settings-icon-holder {
              background: url('../img/Icon/Action/White/settings-white.png');
              background-position: center;
            }

            &.orgs-and-teams-icon-holder {
              background: url('../img/Icon/Content/White/add-filled-white.png');
              background-position: center;
            }

            &.remove-kid-icon-holder {
              background: url('../img/Icon/Action/White/delete-white.png');
              background-position: center;
            }
          }
        }
      }
    }
  }

  &.mobile {
    padding: 5vw 3.75vw;
    margin-left: -3.75vw;
    margin-right: -3.75vw;
    display: flex;
    justify-content: space-between;
    border-top: 2px dashed #E5E5E5;

    img {
      width: 5vw;
      height: 5vw;
    }
  }
}