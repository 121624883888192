.manage-pledges {
  padding-top: 40px;
  input.form-control, button.btn {
    border-radius: 4px;
  }
  .pledges-list {
    margin-top: 40px;
    overflow-x: auto;
    overflow-y: visible;
  }
  .table-holder {
    min-width: 550px;
    min-height: 300px;
  }

  manager-list-header > div.container-fluid {
    padding: 10px 0;

    manager-column-head:not(:first-child) > div {
      padding: 0;
    }
  }

  pledge-detail {
    &:nth-child(odd) {
      & > div {
        background-color: #f5f5f5;
      }
    }
  }

  .pledges-list-items-holder {
    overflow: hidden;
  }
}