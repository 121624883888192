.teams-popover {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  text-align: center;
  min-width: 250px;

  input {
    border: 1px solid #DAD5D5;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 0;
    font-size: small;
    padding-left: 20px;
    height: 25px;
    border-radius: 4px;
    border-width: 2px;
    background-image: url(/img/magnifer_icon.png);
    background-repeat: no-repeat;
    background-position-x: 3px;
    background-position-y: 3px;
    &:disabled {
      opacity: initial;
      border-color: #DAD5D5;
      background-color: #ebebe4;
    }
  }

  label h6, .team-name {
    color: #000;
    font-weight: normal;
  }

  .filter {
    margin-top: 10px;
    overflow: hidden;
  }

  .team-loader {
    color: dodgerblue;
    font-weight: bold;
  }

  .search {
    padding-left: 5px;
    padding-right: 5px;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .sort {
    color: #A5A5A5;
    .disabled {
      opacity: 0.7;
      cursor: default;
    }
  }

  .teams-list {
    margin-bottom: 10px;
    max-height: 350px;
    overflow-y: auto;
    .item {
      text-align: left;
      color: #A5A5A5;
      margin-bottom: 10px;
      min-height: 40px;
      padding: 5px 5px 5px 0;
      cursor: default;
      & > div.col-xs-9 {
        padding-left: 0;
      }
      &:hover {
        background-color: #f7f7fd;
      }
    }
  }

  .reset {
    margin-top: 20px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .coach-popover {
    max-height: 100px;
    overflow-y: auto;
  }

  h6 {
    color: dodgerblue;
    font-weight: bold;

    span {
      margin-top: 5px;
      display: inline-block;
    }
  }

  .select-all-btn {
    margin-bottom: 20px;
  }
}
