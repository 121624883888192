register-join-organization .join-organization {
  @itemHeightInList: 55px;
  @arrowMarginLeft: 7px;
  @arrowMarginRight: 8px;
  @checkIconMarginRight: 5px;
  @checkboxMarginScrollable: 15px;
  @checkboxMarginNonScrollable: 19px;
  @checkboxSize: 25px;
  .org-item {
    hr {
      margin-top: 20px;
      margin-bottom: 5px;
    }
    margin-top: 10px;
    &:not(:first-of-type) {
      padding-top: 10px;
    }
    &.non-clickable {
      cursor: default;
    }
    .org-logo {
      height: @itemHeightInList;
      text-align: center;

      img {
        max-width: 100%;
        max-height: @itemHeightInList;
        margin-top: @itemHeightInList / 2;
        transform: translateY(-50%);
      }
    }

    .org-name-with-checkbox {
      text-align: left;
      height: @itemHeightInList;
      padding-left: 0;
      span {
        display: inline-block;
        margin-top: @itemHeightInList / 2;
        transform: translateY(-50%);
        &:not(.glyphicon) {
          font-size: 16px;
          padding-right: 15px;
          width: calc(~'100% - (@{checkboxSize} + 15px + @{arrowMarginLeft} + @{arrowMarginRight} + @{checkIconMarginRight})');
        }
        &.toggle-holder {
          width: 25px;
          padding: 0;
          margin: 0;
          transform: none;
        }
        &.glyphicon {
          float: right;
          margin-left: @arrowMarginLeft;
          margin-right: @arrowMarginRight;
          &::before {
            display: inline-block;
            transition-duration: .3s;
            transition-property: transform;
          }
          &.rotate::before {
            transform: rotate(180deg);
          }
        }
      }
    }

    .teams-holder {
      &.ng-enter {
        transition: .3s linear all;
        max-height: 0;
      }
      &.ng-enter.ng-enter-active {
        max-height: 200px;
      }
      &.ng-leave {
        transition: .3s linear all;
        max-height: 200px;
      }
      &.ng-leave.ng-leave-active {
        max-height: 0;
      }
      overflow: hidden;
    }
  }

  .join-organization-teams {
    input {
      margin-bottom: 5px;
      margin-top: 20px;
    }
    .team-item {
      height: @itemHeightInList;
      text-align: left;
      margin-top: 5px;
      span {
        width: calc(~'100% - (@{checkboxSize} + @{checkboxMarginScrollable})');
        margin-top: @itemHeightInList / 2;
        transform: translateY(-50%);
        display: inline-block;
        padding-right: 10px;
      }
      img.selection-icon {
        margin-right: @checkboxMarginScrollable;
        height: 20px;
        width: 20px;
        margin-top: @itemHeightInList / 2;
        transform: translateY(-50%);
      }
      &.non-scrollable {
        span {
          width: calc(~'100% - (@{checkboxSize} + @{checkboxMarginNonScrollable})');
        }
        img.selection-icon {
          margin-right: @checkboxMarginNonScrollable;
        }
      }
    }
  }

  .check-icon {
    height: @checkboxSize;
    margin-top: @itemHeightInList / 2;
    transform: translateY(-50%);
    float: right;
    margin-right: @checkIconMarginRight;
  }

  //FF-specific styles
  @-moz-document url-prefix() {
    &.join-organization .join-organization-teams .team-item:not(.non-scrollable) {
      @ffCheckIconMargin: 14px;
      span {
        width: calc(~'100% - (@{checkboxSize} + @{ffCheckIconMargin})');
      }
      .check-icon {
        margin-right: @ffCheckIconMargin;
      }
    }
  }
}

.is-mobile register-join-organization .join-organization {
  @m_itemHeightInList: 90px;
  @m_arrowMarginLeft: 15px;
  @m_arrowMarginRight: 15px;
  @m_checkIconMarginRight: 10px;
  @m_checkboxMarginScrollable: 29.5px;
  @m_checkboxMarginNonScrollable: 35px;
  @m_arrowSize: 25px;
  @m_itemFontSize: 25px;
  @m_checkboxSize: 40px;

  .inner-scroll-400 {
    margin-top: 80px;
    max-height: 800px;
  }
  .alert {
    font-size: 30px;
  }
  input {
    margin-top: 60px;
  }
  .org-item {
    margin-top: 20px;
    &:not(:first-of-type) {
      padding-top: 20px;
    }
    .org-logo {
      height: @m_itemHeightInList;

      img {
        max-height: @m_itemHeightInList;
        margin-top: @m_itemHeightInList / 2;
      }
    }

    .org-name-with-checkbox {
      height: @m_itemHeightInList;
      span {
        margin-top: @m_itemHeightInList / 2;
        &:not(.glyphicon) {
          font-size: @m_itemFontSize;
          width: calc(~'100% - (@{m_checkboxSize} + @{m_arrowSize} + @{m_arrowMarginLeft} + @{m_arrowMarginRight} + @{m_checkIconMarginRight})');
        }
        &.glyphicon {
          font-size: @m_arrowSize;
          margin-left: @m_arrowMarginLeft;
          margin-right: @m_arrowMarginRight;
        }
      }
    }

    .join-organization-teams {
      .inner-scroll-200 {
        max-height: 300px;
      }
    }

    .teams-holder {
      &.ng-enter.ng-enter-active {
        max-height: 400px;
      }
      &.ng-leave {
        transition: .3s linear all;
        max-height: 400px;
      }
    }
  }

  .join-organization-teams {
    input {
      margin-bottom: 10px;
    }
    .team-item {
      height: @m_itemHeightInList;
      margin-top: 10px;
      font-size: @m_itemFontSize;
      span {
        width: calc(~'100% - (@{m_checkboxSize} + @{m_arrowSize} + @{m_checkboxMarginScrollable})');
        margin-top: @m_itemHeightInList / 2;
        padding-right: 10px;
        &.glyphicon {
          font-size: @m_arrowSize;
        }
      }
      .check-icon {
        margin-right: @m_checkboxMarginScrollable;
        height: 35px;
      }
      &.non-scrollable {
        span {
          width: calc(~'100% - (@{m_checkboxSize} + @{m_arrowSize} + @{m_checkboxMarginNonScrollable})');
        }
        .check-icon {
          margin-right: @m_checkboxMarginNonScrollable;
        }
      }
    }
  }
}