.viewTeam {
  .alert {
    margin-top: 20px;
  }
  .teamDetails {
    padding: 14px;
    padding-top: 20px;
  }

  .topya-main-page-content:nth-of-type(2) {
    margin-top: 20px;
  }

  .gray-btn {
    background-color: #DBDBEA;
    border-color: #DBDBEA;
  }

  .coach-results {
    background: #F7F7FD;
    .col-xs-4 {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }

    span {
      display: inline-flex;
      vertical-align: middle;
      &:first-child {
        font-size: 21px;
      }

      &:not(:first-child) {
        text-transform: uppercase;
        font-size: 14px;
        text-align: left;
      }
    }
  }

  .active-button {
    height: 26px;
  }

  .dl-horizontal {
    margin: 20px 0;
    dt {
      font-size: 21px;
      width: 75px;
    }
    dd {
      text-transform: uppercase;
      margin-left: 60px;
    }
  }

  .period-links {
    a {
      cursor: pointer;
      color: black;
    }
    .clicked {
      color: #488EE0;
    }
  }

  h1 {
    font-size: 24px;
  }

  .padding {
    padding: 1%;
  }

  .padding-top {
    padding-top: 3%;
  }

  .big-text {
    font-size: 21px;
  }

  @media (min-width: 768px) and (max-width: 960px) {
    .big-text {
      font-size: 17px;
    }

    .dl-horizontal {
      dt {
        width: 35px;
      }
      dd {
        margin-left: 40px;
      }
    }
  }

  tr {
    .big-text {
      border-left: whitesmoke solid 1px;
    }

    &.head .center-aligned img {
      width: 16px;
    }
  }

  .center-aligned, .big-text {
    text-align: center;
  }

  tbody tr {
    border-top: whitesmoke solid 1px;
    padding: 4px 0;
  }

  .table > tbody > tr > td {
    vertical-align: middle;
  }

  .invite, .joined {
    border-left: 1px solid #DBDBEA;
    padding: 3px;
  }

  .persons .img-circle {
    width: 56px;
    height: 56px;
  }

  .persons div {
    display: inline-table;
    margin-right: 5px;
  }

  .persons div:last-child {
    float: right;
  }

  .inactive-wrapper {
    padding: 10px;
    margin: 10px 0;
  }

  .arrow {
    position: relative;
  }

  .username {
    color: #c7c7de;
  }

  .blueLink {
    color: #488EE0;
  }

  .invitedPlayers, .joinedPlayers {
    border-bottom: 1px solid #DBDBEA;
  }

  .btnRemind {
    color: #488EE0;
    border-color: #488EE0;
    border-radius: 0px;
  }

  .arrow-left {
    position: relative;
    top: 20px;
  }

  .settings-icon {
    position: relative;
    top: 11px;
    margin-left: 18px;
  }

  .btnResended {
    color: white;
    border-color: white;
    background-color: #488EE0;
    border-radius: 0px;
  }

  table {
    border-collapse: separate;
    border-spacing: 10px;
  }

  .imgMargin {
    margin-right: 5px;
  }

  .arrow-right {
    padding: 10px;
    .arrow {
      top: 9px;
    }
  }

  .persons {
    height: 100px;
  }

  .inner-scroll {
    overflow-y: auto;
    max-height: 400px;
  }

  .player-name {
    display: block;
  }

  .status-label
  {
    text-align:center;
    padding: 10px;
    font-weight:bold;
    padding-top:0px;
    font-size:large;
  }

  .back-arr {
    margin-right: 10px;
  }

  @media (min-width: 800px) and (max-width: 1400px) {
    .persons .img-circle {
      width: 44px;
      height: 44px;
    }

    .persons .arrow {
      top: 10px;
    }

    .smallerFont {
      font-size: smaller;
      .username {
        font-size: 9px;
      }
    }

    .btnRemind, .btnResended {
      font-size: 9px;
    }

    .big-text {
      font-size: 18px;
    }
  }

  @media (min-width: 1440px) and (max-width: 1800px) {
    .persons .img-circle {
      width: 44px;
      height: 44px;
    }

    .smallerFont {
      font-size: 13px;
      .username {
        font-size: 10px;
      }
    }

    .btnRemind, .btnResended {
      font-size: 9px;
    }

    .big-text {
      font-size: 20px;
    }
  }
}
