.popover-content {
  padding-left: 5px;
  padding-right: 5px;
}

.create-path {
  // border: 1px solid #DAD5D5;
  background-color:#F7F7FD;
  padding-bottom: 10px;

  .error-message h5 {
    color: #A94442;
  }

  .path-desc {
    padding-top: 15px;
    text-align: center;
  }

  .alert {
    width: 100%;
    margin-bottom: 10px;
  }

  h5 {
    color: #A5A5A5;
  }

  input, textarea {
    font-size: 14px;
  }

  button {
    border-radius: 0;
    width: 100%;
    &:disabled, &:disabled:hover {
      border-color: #888C88;
      background-color: #9B9B9B;
    }
    .cancel:disabled {
      color: #fff;
    }
  }

  hr {
    border-top: 1px solid #DAD5D5;
  }

  .new-path-form {
    input {
      border: 1px solid #DAD5D5;
      width: 100%;
      margin-bottom: 10px;
    }

    textarea {
      border: 1px solid #DAD5D5;
      width: 100%;
      margin-bottom: 10px;
      height: 85px;
      max-width: 100%;
      min-width: 100%;
      &:disabled {
        background-color: #fff;
        opacity: 0.4;
      }
    }

    .path-name {
      height: 30px;
    }

    .path-select {
      margin-bottom: 10px;
      .list-loading-overlay {
        border-radius: 4px;
      }
    }

    .input-label {
      text-align: left;
      margin-bottom: 5px;
      h5 {
        color: #000;
      }
    }

    .add-skill {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .video-footer {
      text-align: right;
      margin-right: 4%;
      margin-top: 10px;
      margin-bottom: 10px;
      color: dodgerblue;
      a {
        cursor: pointer;
        &:disabled {
          cursor: default;
          color: rgb(165,165,165);
        }
      }
    }

    .assign-path {
      text-align: right;
      padding-top: 17px;
      @media (max-width: 1199px) {
        text-align: center;
      }
    }

    .add-team {
      .btn {
        padding: 6px 0;
        margin-top: 8px;
        span {
          margin-right: 5px;
        }
      }
      .btn-success.finished-teams-disabled {
        background-color: #5cb85c;
        border-color: #4cae4c;
      }
    }
  }

  .video-footer-holder {
    min-height: 32px;
    padding-right: 15px;
  }
}
