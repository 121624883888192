.leaderboard-widget-filters {
  button {
    border-radius: 4px;
  }

  .inner-scroll-200 {
    max-height: 189px;
  }

  span.team-name-filter {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(~"100% - 42px");
    display: inline-block;
    vertical-align: bottom;
  }

  @media (max-width: 480px) and (min-width: 401px) {
    &, .btn {
      font-size: 12px;
    }
  }

  @media (max-width: 320px) {
    &, .btn {
      font-size: 12px;
    }
  }

  @media (max-width: 400px) and (min-width: 280px) {
    &, .btn {
      font-size: 9px;
    }
  }

  @media (max-width: 279px) {
    &, .btn {
      font-size: 8px;
    }
  }

  .upper-filters-row, .lower-filters-row {
    @media (min-width: 425px) {
      height: 86px;
    }

    @media (max-width: 424px) and (min-width: 285px) {
      height: 71px;
    }

    @media (max-width: 284px) {
      height: 46px;
    }
  }

  .upper-filters-row {
    text-align: center;

    @media (max-width: 279px) {
      padding-top: 6px;
    }

    @media (min-width: 280px) and (max-width: 284px) {
      padding-top: 5px;
    }

    @media (min-width: 285px) and (max-width: 400px) {
      padding-top: 16px;
    }

    @media (min-width: 401px) and (max-width: 424px) {
      padding-top: 15px;
    }

    @media (min-width: 425px) and (max-width: 480px) {
      padding-top: 25px;
    }

    @media (min-width: 481px) {
      padding-top: 22px;
    }

    .upper-filters-col {
      margin: 5px 0;
      width: 25%;
      display: inline-block;
      text-align: center;

      &:not(:last-child) {
        padding: 7px 5px;
        @media (max-width: 420px) {
          padding-left: 1px;
          padding-right: 1px;
        }
      }
      span.glyphicon {
        vertical-align: sub;
      }
    }
  }

  .lower-filters-row {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    text-align: center;

    .lower-filters-col {
      width: 20%;
      display: inline-block;
      text-align: center;
      @media (min-width: 481px) {
        padding-top: 32px;
      }
      @media (min-width: 425px) and (max-width: 480px) {
        padding-top: 34px;
      }
      @media (max-width: 424px) and (min-width: 401px) {
        padding-top: 28px;
      }
      @media (max-width: 400px) and (min-width: 285px) {
        padding-top: 28px;
      }
      @media (max-width: 284px) and (min-width: 280px) {
        padding-top: 15px;
      }
      @media (max-width: 279px) {
        padding-top: 17px;
      }
      @media (min-width: 425px) {
        height: 84px;
      }

      @media (max-width: 424px) and (min-width: 285px) {
        height: 69px;
      }

      @media (max-width: 284px) {
        height: 44px;
      }
      &:hover:not(.active) {
        background-color: #f3f3f3;
      }
      &.active {
        background-color: #d7d7d7;
      }
    }
  }

  @media (max-width: 320px) {
    .dropdown-menu {
      font-size: 8px;
      padding: 0;
    }
  }

  .dropdown-menu {
    width: 100%;

    @media (max-width: 480px) and (min-width: 401px) {
      font-size: 12px;
    }

    @media (max-width: 320px) {
      font-size: 12px;
    }

    @media (max-width: 400px) and (min-width: 280px) {
      font-size: 9px;
    }

    @media (max-width: 279px) {
      font-size: 8px;
    }

    &.teams-dropdown {
      right: 0;
      left: auto;
    }
  }
}
