category-selection-tree-item > div {
  & > div.item {
    padding: 0px 10px;
    color: rgb(92, 92, 92);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 226, 234);
    border-image: initial;
    margin: 5px 0px;
    border-radius: 8px;

    a.expand {
      color: rgb(92, 92, 92);
      width: 24px;
      height: 24px;
      margin-right: 5px;
      padding: 0px;
    }

    div.item-clickable {
      display: inline-block;
      width: calc(~"100% - 29px");
      padding: 10px 0px;

      &.full-width {
        width: 100%;
        padding-left: 29px;
      }

      h5 {
        display: inline-flex;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    a.btn.toggle-selection-button {
      padding-left: 1.5px;
      padding-top: 2px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      padding: 3px;
      float: right;

      & > span.glyphicon {
        margin-left: 50%;
        transform: translateX(-45%);

        &.glyphicon-minus {
          margin-left: 8px;
        }

        &.circle-filled {
          width: 16px;
          height: 16px;
          background-color: white;
          border-radius: 50%;
          margin-left: 8px;
        }

        &.circle-empty {
          width: 16px;
          height: 16px;
          border: 2px solid white;
          border-radius: 50%;
          margin-left: 8px;
        }
      }

      &.btn-default {
        background-color: rgb(155, 155, 155);
        color: rgb(255, 255, 255);
      }
    }
  }
}