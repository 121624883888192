@contentHeight: 650px;

.password-forms {
  .coach-content-bg {
    min-height: @contentHeight;
  }

  .invalid-input {
    border-color: red;
    color: red;
  }

  .warning-wrapper {
    height: 45px;
    span {
      color: #fff;
      width: 100%;
      display: inline-block;
      margin-top: 10px;
      text-align: left;
      font-size: 11px;
    }
  }

  .topya-logo {
    width: 150px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .confirmation-message {
    display: inline-block;
  }

  .forgot-password-text {
    color: #fff;
    margin-bottom: 30px;
    font-size: larger;
  }

  .btn {
    height: 40px;
    width: auto;
  }

  input {
    padding-left: 50px;
    width: 100%;
    height: 70px;
  }

  .forgot-password-email {
    background: url(../img/password-icon.png) no-repeat 3% 45% #fff

  }

  .password {
    background: url(../img/password-icon.png) no-repeat 3% 45% #fff
  }

  .email-wrapper {
    margin-bottom: 20px;
  }

  .email-wrapper, .forgot-password-button-wrapper {
    margin: 0 auto;
    float: none;
  }

  .forgot-password-title {
    margin: 20px 0;
    color: #fff;
  }

  .forgot-password-button-wrapper {
    margin-top: 20px;
  }

  .forgot-password-error-wrapper {
    margin-top: 30px;
  }

  &.mobile {
    .forgot-password-title {
      font-size: 30pt;
    }

    .confirmation-message {
      .ok-button {
        max-width: 330px;
      }
    }

    .coach-content-bg {
      margin: 0;
      height: 100vh;
    }

    input {
      font-size: 25pt;
      height: 50pt;
      background-size: 25pt;
      background-position-x: 5pt;
      background-position-y: 10pt;
      margin-top: 20pt;
    }

    .btn {
      font-size: 25pt;
      height: auto;
      width: 100%;
      margin: 10pt 0;
    }

    .forgot-password-text {
      font-size: 25pt;
    }

    .forgot-password-error-wrapper, .forgot-password-error-wrapper .close {
      font-size: 20pt;
    }

    .warning-wrapper span {
      font-size: 15pt;
    }

    .topya-logo {
      width: 40%;
      margin-top: 50px;
      margin-bottom: 180px;
    }
  }
}
