contests-list-item {
  & > div.container-fluid {
    padding-top: 10px;
    padding-bottom: 10px;
    & > div {
      @media (max-width: 610px) {
        padding-left: 0;
        font-size: 10px;
      }
    }
  }
  &:nth-child(even)  > div.container-fluid {
    background-color: #d8d8d8;
  }

  .team-contest-marker {
    background-color: dodgerblue;
    color: white;
    padding: 6px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    display: inline-block;
    width: 88px;
    @media (max-width: 610px) {
      width: 100%;
      font-size: 9px;
      width: 70px;
    }
  }
}