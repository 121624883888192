pledge-preview {
  & > div.row {
    padding-bottom: 40px;
  }
  hr {
    border-color: #66b2fb;
    margin-top: 10px;
  }
  div[class*='col-xs-'] {
    h4 {
      font-family: 'AvenirNextLTPro Bold';
    }
  }
  span {
    display: block;
  }
  @media (max-width: 1199px) {
    span, button.btn {
      font-size: 13px;
    }
    h4 {
      font-size: 15px;
    }
  }
  @media (max-width: 767px) {
    span, button.btn {
      font-size: 11px;
    }
    h4 {
      font-size: 13px;
    }
  }
  button.btn {
    width: 200px;
    margin: 0 10px;
    @media (max-width: 767px) {
      width: 110px;
      margin: 0 5px;
    }
  }
  .detailed-info-group {
    span {
      &:first-of-type {
        margin-top: 20px;
      }
      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }
}