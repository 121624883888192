.contest-pledge.pledge-mobile {
  button.btn {
    padding: 8px;
  }

  .fundraiser-name {
    margin-top: 115px;
  }

  .pledge-holder  .supported-player-avatar {
    float: none;
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 120px;
    margin-bottom: 40px;
  }
}
