.myFreestyle {
  padding-top: 15px;
  margin-left: 0;
  margin-right: 0;

  .img-circle {
    width: 40px;
    height: 40px;
    border: none;
  }

  .block {
    border: 1px solid #DBDBEA;
    border-bottom: none;

    .header {
      text-align: center;
      padding: 6px 0;
      background: #E4E4EF;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      width: 100%;
      color: white;

      &.Active {
        background-color: #7DD220;
      }

      &.Scored {
        background-color: #F7A722;
      }

      &.Pending {
        background-color: #ADC2E4;
        color: #498FE1;
      }
    }
  }

  .leftBlock {
    float: left;
  }
  .rightBlock {
    float: right;
  }

  .deadline {
    padding-left: 8px;
  }

  a {
    color: #000;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    color: #000;
    font-weight: bold;
  }

  .roundedQuad {
    border-radius: 5px;
    width: 45px;
    height: 45px
  }

  .active-in-my-court {
    background-color: #73ba32;
    color: #d9edc5;
  }

  .active-freestyle-right{
    color: #73ba32;
    background-color: #fff;
  }

  .active-in-their-court {
    background-color: #D9EDC5;
    color: #73BA32;
  }

  .scored-freestyle-win {
    background-color: #DC9116;
    color: #fff;
  }
  .scored-freestyle-draw {
    background-color: #D8AF5B;
    color: #DC9116;
  }

  .scored-freestyle-right{
    color: #F09533;
    background-color: #fff;
  }

  .pending-freestyle-text-color {
    color: #4A90E2;
  }

  .referee-denied-text-color {
    color: white;
  }

  .referee-denied-left-background {
    background-color: red;
  }

  .pending-freestyle-left-background {
    background-color: #A7C4EA;
  }

  .pending-freestyle-right {
    color: #498FE1;
    background-color: #fff;
  }

  .referee-denied-right {
    color: red;
    background-color: #fff;
  }

  .leftText, .challengeStatus, .name, .textAbove {
    font-size: 10px;
    word-break: break-word;
  }

  .challengeStatus {
    margin: 0px;
    padding-left: 4px;
  }

  .leftBlock, .rightBlock {
    padding: 3px;
    min-height:80px;
    border-bottom: lightgray solid thin;
  }

  .imgPadding {
    padding:5px;
  }
}
