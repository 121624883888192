.manage-categories {
  h3 {
    margin-top: @pageTopOffset;
    margin-bottom: 10px;
  }
  .panel-heading, .panel-body {
    margin: 5px 0;
    input {
      background: transparent;
      border: none;
    }
    a.disabled {
      color: #777;
      cursor: default;
      text-decoration: none;
    }
  }
  .panel-heading {
    font-size: large;
    a {
      margin-right: 15px;
    }
  }
  .panel-body {
    font-size: larger;
    input, span {
      margin-left: 30px;
    }
  }

  .categories-scroll-holder {
    max-height: 440px;
    overflow-y: auto;
  }
}
