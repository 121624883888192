.create-edit-team-contest {
  padding: 15px;

  input.form-control {
    border-radius: 4px;
    &[readonly] {
      background-color: #f9f9f9;
    }
  }

  button.btn {
    border-radius: 4px;
  }

  .close {
    margin-top: -10px;
    margin-right: 0;
    font-size: 15px;
    display: inline-block;
    width: 100%;
    text-align: right;
    margin-bottom: 5px;
  }
}