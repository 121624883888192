parent-profile > form.container-fluid {
  padding: 0;

  h3.title {
    color: #333333;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 6px;
  }

  view-title > div {
    margin-top: 24px;
  }

  div.form-fields-holder {
    padding: 38px 40px 40px 40px;
    background-color: #fff;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    & > div.profile-info-holder {
      margin-bottom: 40px;

      & > div.username-role-holder {
        margin-left: 24px;
        display: inline-block;
        height: 96px;
        vertical-align: top;
        max-width: calc(~"100% - 120px");

        & > div {
          margin-top: 48px;
          transform: translateY(-50%);

          & > span.username {
            color: #333;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 24px;
            display: block;
          }

          & > span.role {
             color: #999;
             font-size: 14px;
             font-weight: 500;
             letter-spacing: 0;
             line-height: 21px;
             display: block;
          }
        }
      }
    }

    div.form-column {
      display: block;

      @media(max-width: 1199px) {
        width: 100%;
      }

      @media(min-width: 1200px) {
        width: 50%;
      }

      div.section-title {
        display: block;

        &:nth-of-type(2) {
          margin-top: 56px;
        }

        & > span.title {
          color: #333;
          font-size: 18px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 25px;
        }

        & > hr {
          border-color: #E5E5E5;
          border-width: 1px;
          margin: 24px 0 16px 0;
        }
      }

      text-input > span.label,
      calendar-input > span.label {
        margin-top: 24px;
      }
    }
  }

  & > button.btn {
    width: auto;
    margin-top: 24px;

    &:not(.cancel) {
      margin-left: 16px;
    }
  }

  success-message > div,
  error-message > div {
    margin-top: 24px;
  }

  &.mobile {
    h3.title {
      font-size: 6.25vw;
      line-height: 7.5vw;
    }

    view-title > div {
      margin-top: 7.5vw;
    }

    div.form-fields-holder {
      padding: 7.5vw;

      & > div.profile-info-holder {
        margin-bottom: 15vw;

        & > div.username-role-holder {
          height: 30vw;
          margin-left: 7.5vw;
          max-width: calc(~"100% - 37.5vw");

          & > div {
            margin-top: 15vw;

            & > span.username {
              font-size: 6.25vw;
              line-height: 7.5vw;
            }

            & > span.role {
               font-size: 4.4vw;
               line-height: 6.6vw;
            }
          }
        }
      }

      div.form-column {
        text-input > span.label,
        calendar-input > span.label {
          margin-top: 7.5vw;
        }

        div.section-title {
          &:nth-of-type(2) {
            margin-top: 17.5vw;
          }

          & > span.title {
            font-size: 5.6vw;
            line-height: 7.5vw;
          }

          & > hr {
            border-color: #E5E5E5;
            border-width: 2px;
            margin: 7.5vw 0 5vw 0;
          }
        }
      }
    }

    & > button.btn {
      margin-top: 10vw;
      font-size: 5vw;
      line-height: 7.5vw;
      padding: 2.5vw 5vw;
      border-radius: 2px;

      &:not(.cancel) {
        margin-left: 5vw;
      }
    }

    success-message > div,
    error-message > div {
      margin-top: 7.5vw;
    }
  }
}