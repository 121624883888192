.channels-list {
  padding: 0;
  h1 {
    font-family: 'AvenirNextLTPro Bold';
  }
  .btn {
    font-weight: bold;
  }
  input, .btn {
    border-radius: 4px;
  }

  .channels-table {
    & > div > list-loading-overlay > .list-loading-overlay {
      width: calc(~"100% - 30px");
      left: 15px;
    }
  }
}
