dropdown-input > div.btn-group {
  & > button.btn.btn-default {
    width: auto;
    padding: 0;

    &:hover, &:focus, &:active {
      background-color: #fff;
      border: none;
      box-shadow: none;
    }

    & > span.btn-text {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }

    & > span.caret {
      margin-left: 16px;
      color: #cbcbcb;
    }
  }

  ul.dropdown-menu {
    border-radius: 0;
    &::before, &::after {
      display: none;
    }

    & > li {
      padding: 8px 16px;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }

  &.mobile {
    & > button.btn.btn-default {
      & > span.btn-text {
        font-size: 5vw;
        line-height: 7.5vw;
      }

      & > span.caret {
        margin-left: 5vw;
        border-left-width: 20px;
        border-right-width: 20px;
        border-top-width: 20px;
        vertical-align: initial;
      }
    }

    ul.dropdown-menu {
      & > li {
        padding: 2.5vw 5vw;
        font-size: 5vw;
        line-height: 7.5vw;
      }
    }
  }
}