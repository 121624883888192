.column-head-component, .skills-academy .column-head-component {
  padding: 15px;
  &.scroll-list {
    max-height: 450px;
    overflow-y: auto;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      color: #2c2c2c;
      padding: 15px 0 20px 0;
      cursor: pointer;
      text-align: left;
      &:hover {
        background-color: #f7f7fd;
      }
      .category-child {
        margin-left: 15px;
      }
      .item-label {
        width: 80%;
        display: inline-block;
        line-height: 1.5;
        &.full-width {
          width: 100%;
        }
      }
    }
  }
  .spinner-holder {
    height: 30px;

    spinner > div {
      margin-left: calc(~"50% - 10px");
    }
  }

  input {
    width: 100%;
    color: #2c2c2c;
    margin-top: 15px;
  }
  input, .btn {
    margin-bottom: 15px;
  }
  a {
    font-weight: normal;
  }
  h6 {
    margin: auto;
    color: #2c2c2c;
    font-weight: normal;
  }

  .selected-item-icon {
    width: 16px;
    margin-right: 10px
  }
}
