signup-player-contact > form {
  width: 100%;
  margin: 64px 0;
  display: grid;

  span.view-title {
   color: #333333;
   font-size: 28px;
   font-weight: 900;
   letter-spacing: 0;
   line-height: 38px;
   display: block;
  }

  span.explanation-label {
    color: #999999;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    display: block;
  }

  & > text-input > span.label {
    margin-top: 24px;
  }

  div.buttons-holder {
    margin-top: 32px;

    & > button.btn.btn-primary {
      width: 170px;
      width: calc(~"50% - 8px");

      &:not(.cancel) {
        float: right;
      }
    }

    @media (max-width: 1274px) and (min-width: 1200px) {
      & > button.btn.btn-primary {
        width: 116px;
      }
    }

    @media (max-width: 445px) {
      & > button.btn.btn-primary {
        width: 100%;

        &.cancel {
          margin-bottom: 24px;
        }
      }
    }
  }

  &.mobile {
    span.view-title {
      margin-top: 12.5vw;
      font-size: 8.75vw;
      line-height: 11vw;
    }

    & > span.explanation-label {
      font-size: 5vw;
      line-height: 7.5vw;
      margin-top: 7.5vw;
      margin-bottom: 5vw;
    }

    error-message > div {
      margin-top: 7.5vw;
    }

    & > text-input > span.label {
      margin-top: 7.5vw;
    }

    div.buttons-holder {
      margin-top: 7.5vw;
      width: 100%;

      & > button.btn.btn-primary {
        width: 100%;
        font-size: 5vw;
        line-height: 7.5vw;
        border-radius: 1.25vw;
        padding: 2.5vw 5vw;
        width: 100%;
        max-width: none;

        &:not(.cancel) {
          margin-top: 5vw;
        }
      }
    }
  }
}