parent-my-kids-list-item-desktop-detail > div {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: inline-block;

  &.points {
    width: 12%;
  }

  &.skills,
  &.caps,
  &.h2h {
    width: 11%;
  }

  &.subscription-status {
    width: 51px;
    height: 24px;
    border-radius: 16px;
    color: #FFF;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding-top: 4px;

    &.subscription-yes {
      background-color: #6DD400;
    }

    &.subscription-no {
      background-color: #F55151;
    }
  }
}