.nav>li>a:hover, .nav>li>a:focus, .nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
  background:#fff ;
}
 a{
  text-align:center;
}
.dropdown {
  border: none ;
  border-radius:4px ;
  width:100% ;
}
.dropdown-menu>li {
  color: black  ;
}
 ul.dropdown-menu {
  border-radius:4px ;
  box-shadow:none ;
}
 ul.dropdown-menu:before {
  content: "" ;
  border-bottom: 10px solid #fff ;
  border-right: 10px solid transparent ;
  border-left: 10px solid transparent ;
  position: absolute ;
  top: -10px ;
  right: 16px ;
  z-index: 10 ;
} ul.dropdown-menu:after {
  content: "" ;
  border-bottom: 12px solid #ccc ;
  border-right: 12px solid transparent ;
  border-left: 12px solid transparent ;
  position: absolute ;
  top: -12px ;
  right: 14px ;
  z-index: 9 ;
}
.dropdown-menu li{
  padding-top: 5px ;
  padding-bottom: 5px ;
  text-align: center;
  border-top: solid 1px whitesmoke;
}

.dropdown-menu li:first-child
{
  border-top:none ;
}

.dropdown a{
  color:black ;
}
