.contest-pledge {
  h2 {
    word-spacing: 4px;
    letter-spacing: 0.5px;
    line-height: 36px;
  }

  h3 {
    line-height: 32px;
    word-spacing: 2px;
    letter-spacing: 0.5px;
  }

  h4 {
    line-height: 26px;
  }

  .topya-logo-holder {
    height: 60px;
  }

  button.btn {
    border-radius: 4px;
    padding: 8px;
    height: 57px;
    @media (max-width: 425px) {
      padding: 8px 4px;
    }
    &:not(.submit-pledge-button) > span {
      font-size: 11px;
      font-weight: bold;
      display: block;
      margin-top: -2px;
    }
  }

  input, input.form-control {
    border-radius: 4px;
    &.ng-invalid:not(.ng-invalid-required) {
      border-color: red;
    }
    &.ng-invalid-required {
      border-width: 2px;
      border-color: #66afe9;
    }
  }

  .fundraiser-name {
    font-family: 'AvenirNextLTPro Bold';
    color: #4a4a4a;
    width: auto;
    display: inline-block;
    text-align: center;
    margin-top: 150px;
    &:hover {
      text-decoration: underline;
    }
  }

  .pledge-holder {
    margin-top: 60px;
    background-color: #edf4fc;
    padding: 50px 0 80px 0;
    & > div[class*='col-'] {
      &.padding-right {
        padding-right: 30px;
      }
      &.padding-left {
        padding-left: 30px;
      }
    }
    .supported-player-avatar {
      max-width: 130px;
      float: right;
      width: 100%;
      @media (max-width: 767px) {
        float: none;
        margin-left: 50%;
        transform: translateX(-50%);
        max-width: 120px;
        margin-bottom: 40px;
      }
    }

    .cvv-input {
      padding-right: 30px;
    }

    .cvv-info {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: #ccc;
    }
  }

  .pledge-payment {
    .charging-advice {
      display: block;
      margin-top: 5px;
      font-size: 13px;
    }

    label {
      font-size: 11px;
      vertical-align: middle;
    }

    .dropdown-menu {
      width: calc(~"100% - 30px");
      margin-left: 0;
    }

    span.amount-input-currency-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 25px;
    }

    input.amount-input {
      padding-left: 20px;
    }

    .payment-title,
    .payment-info-block,
    .receipt-address-inquiry {
      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .amount-values-buttons, .amount-type-buttons {
      @media (max-width: 991px) {
        padding-left: 10px;
        padding-right: 10px;

        div[class*='col-xs-'] {
          padding-left: 5px;
          padding-right: 5px;
        }

        button > h4 {
          font-size: 14px;
        }
      }
    }

    .amount-type-buttons {
      @media (max-width: 374px) {
        & > div[class*='col-xs-'] {
          width: 100%;
          &:nth-child(2) {
            margin-top: 20px;
          }
        }
      }
    }

    .amount-values-buttons {
      @media (max-width: 424px) {
        & > div[class*='col-xs-'] {
          width: 50%;
        }
      }
    }

    .or {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateX(-50%) translateY(-50%);
      @media (max-width: 767px) {
        top: calc(~"100% + 8px");
        left: 50%;
      }
    }
  }

  .submit-pledge-button {
    width: 200px;
    margin-left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 14px;
    &,
    &:hover,
    &:active {
      background-color: rgba(255,136,39,1);
      border-color: rgba(255,136,39,1);
    }
    &:disabled {
      opacity: 1;
    }
  }

  .pledge-completed {
    & > :not(button) {
      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .pledge-amount {
      color: dodgerblue;
    }
  }

  .share-text {
    color: #363636;
  }
}
