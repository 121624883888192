html {
  margin: 0;
  padding: 0;
  background-size: cover;
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  height: 100%;
  &.modal-open {
    overflow: auto;
  }

  &:not(.show-addthis) {
    .addthis-smartlayers {
      display: none;
    }
  }
  &.show-addthis {
    #at-share-dock {
      @media (min-width: 980px) {
        display: none;
      }
    }
  }
}

.dashboard-wrapper {
  background-color: #f7f7fd;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

input, input.form-control {
  border-radius: 0;
}

.menu {
  vertical-align: middle;
  background-color: #1B1C2C;
}

*:focus {
    outline: none;
}

.green_img {
  // border: 2px solid #7DD220
}

*.no-border {
  border: none;
}

.red_img {
  border: 2px solid #CF011A
}

.table > thead > tr > th, .table > tbody > tr > td {
  border-top: 0;
  border-bottom: 0;
}

.table .head {
  color: #9E9E9E;
  font-weight: normal;
}

.progress {
  background-image: -webkit-linear-gradient(top, #DDDDDD 0, #DDDDDD 100%);
  background-image: -o-linear-gradient(top, #DDDDDD 0, #DDDDDD 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#DDDDDD), to(#DDDDDD));
  background-image: linear-gradient(to bottom, #DDDDDD 0, #DDDDDD 100%);
}

.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar-success {
  background-image: -webkit-linear-gradient(top, #7DD220 0, #7DD220 100%);
  background-image: -o-linear-gradient(top, #7DD220 0, #7DD220 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7DD220), to(#7DD220));
  background-image: linear-gradient(to bottom, #7DD220 0, #7DD220 100%);
}

.profile_info, .list-inline, .profile_info_links, .profile_info_links span, .text-align-center {
  text-align: center;
}

.container {
  padding-bottom: 3%;
}

.coach-bg .menu {
  min-height: 65px;
}

.coach-content-bg {
  background: rgba(4, 6, 23, 0.9);
  padding-bottom: 50px;
  margin-top: 3%;
  margin-bottom: 3%;
  text-align: center;
}

.coach-content-bg h3.white {
  color: #fff;
}

.paragraph {
  color: #8C8C8C;
}

.view-content {
  padding-top: 2%;
}

.button-distance {
  padding-top: 0.5%;
  padding-bottom: 2%;
}

.button-distance-top {
  margin-top: 2%;
}

.button-distance-top-register {
  margin-top: 1%;
}

.button-distance-top-createTeam {
  margin-top: 2.5%;
}

.button-distance-bottom {
  padding-bottom: 0;
}

.display-profile-image {
  width: 150px;
  height: 150px;
}

.orange {
  color: #F4A522;
}

.red {
  color: #B53B3B;
}

.popup-window {
  width: 600px;
  height: 250px;
  background-color: #fff;
  margin-left: 23%;
  z-index: 200;
  position: absolute;
}

.x-icon {
  width: 50%;
}

.hidden {
  visibility: hidden;
}

.custom {
  border-radius: 0 !important;
  min-width: 30% !important;
  border-width: 0 !important;
  height: 30px !important;
  min-width: 100px !important;
}

.signup {
  border-radius: 0 !important;
}

.config_modal.open {
  display: block;
}

.add-kid-box {
  border: 1px solid #ccc;
  width: 100%;
  padding: 25px;
}

.add-kid-box div {
  width: 47%;
}

.add-kid-box div input {
  width: 100%;
  margin-bottom: 10px;
}

.ad-banner-space {
  min-height: 150px;
}

.coach-header {
  margin-top: 20px;
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}

.leftBlockWrapper {
  margin-top: 20px !important;
  padding-left: 0;
}

.dropdown {
  width: 100%;
}

.second-dropdown {
  overflow-y: auto !important;
}

.gender-dropdown {
  min-height: 10px !important;
  height: auto !important;
}

.update-country .dropdown-menu{
  height:150px !important;
  overflow-y: auto !important;
}

.dashboard-wrapper .menu, .forgot-password .menu {
  background-size: cover !important;
  min-height: 100% !important;
  background: #003;
  height: 80px;
  padding-top: 10px;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-right-padding {
  padding-right: 0 !important;
}

.disabled-item {
  opacity: @disabledItemOpacity;
  cursor: default;
}

.normal-item {
  opacity: 1;
  cursor: pointer;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.float-right {
  float: right;
}

.no-top-border {
  border-top: none !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

videogular vg-overlay-play .overlayPlayContainer, [videogular] vg-overlay-play .overlayPlayContainer {
  font-size: 60px;
}

.green_img {
  width: 58px;
  height: 58px;
  @media (min-width: 1441px) and (max-width: 1920px) {
    width: 54px;
    height: 54px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 38px;
    height: 38px;
  }
}

.round-image-holder {
  width: 100%;
  height: auto;
  position: relative;
  // border-radius: 50%;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

ul[dnd-list] {
  padding-left: 0;
  list-style-type: none;
}

ul[dnd-list],
ul[dnd-list] > li {
  position: relative;
}

ul[dnd-list] .dndDraggingSource {
  display: none;
}

ul[dnd-list] .dndPlaceholder {
  display: block;
  background-color: #ddd;
  min-height: 72px;
}

.modal-dialog.modal-sm {
  min-width: 350px !important;
  width: 20% !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
}


::-webkit-scrollbar {
  width: 6px;
  padding-right: 20px !important;
  margin: auto !important;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: lightgray;
}

@media (min-width: 1280px) {
  ::-webkit-scrollbar {
    width: 10px;
  }
}


videogular vg-overlay-play, [videogular] vg-overlay-play {
  left: 0;
}

.view-holder {
  width: 100%;
  min-height: 100vh;
  background-color: #F3F3F3;
  overflow-x: hidden;
  overflow-y: auto;

  .main-content-holder {
    // menu width
    margin-left: 232px;
    // header height
    margin-top: 72px;
    padding: 24px;
  }

  &.mobile {
    .main-content-holder {
      margin-left: 0;
      // header height
      margin-top: 22.5vw;
      padding: 7vw;
    }
  }
}
