parent-activity-feed-card-item-header > div {
  display: block;
  width: 100%;

  img.avatar {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    vertical-align: bottom;
  }

  div.details-holder {
    display: inline-block;

    span.username {
     color: #333;
     font-size: 14px;
     font-weight: 500;
     letter-spacing: 0;
     line-height: 21px;
     display: block;
    }

    span.activity-time {
     color: #999;
     font-size: 12px;
     font-weight: 500;
     letter-spacing: 0;
     line-height: 21px;
     display: block;
    }
  }
}