manage-product-draggable-paths-list-item > div {
  padding: 10px 0;
  min-height: 60px;

  span.remove-icon {
    float: right;
    color: red;
    font-size: 20px;
    font-weight: bold;
    margin-top: -5px;
  }
}