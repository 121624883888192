drop-area > div {
  border: 1px dashed #ccc;
  min-height: 250px;

  span.background-text {
    color: #4e4e4e;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    font-size: 25px;
    position: absolute;
    font-family: 'AvenirNextLTPro Bold';
    z-index: 0;
  }

  .items-holder {
    position: relative;
    z-index: 1;
  }

  li {
    visibility: hidden;
  }
}