.datepicker-component {
  .danger {
    text-align: left;
    padding-right: 15px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    width: 100%;
   -webkit-transition : border 100ms ease-out;
   -moz-transition : border 100ms ease-out;
   -o-transition : border 100ms ease-out;
   &.error {
     border: 1px solid red;
   }
  }
}
