parent-activity-feed-card-item-description > div {
  margin-top: 24px;
  display: block;
  width: 100%;

  img.skill-badge {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    vertical-align: top;
  }

  p.description {
    display: inline-block;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    width: calc(~"100% - 56px");
    margin: 0;
  }
}