dropdown > div {
  width: 250px;
  button.btn {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dropdown {
    width: 100%;
  }
  span.btn-text {
    max-width: 200px;
    margin: 0 5px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span.caret {
    vertical-align: text-top;
    margin-top: 2px;
  }
  li {
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  }
  ul.dropdown-menu {
    max-height: 130px;
    overflow-y: auto;
  }
}