select-input {
  & > span.label {
    color: #333;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 8px;
    padding: 0;
    display: inline-block;
    text-align: left;
    width: auto;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-bottom: 2.5vw;
      display: block;
    }
  }

  & > select.form-control {
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    background-color: #FFF;
    height: 40px;
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;

    &.mobile {
      font-size: 5.6vw;
      line-height: 7.8vw;
      margin-bottom: 2.5vw;
      height: 10vw;
      border-width :2px;
      border-radius: .6vw;

      & > option {
        font-size: 2vw;
        line-height: 2vw;
      }
    }
  }
}