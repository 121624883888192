manager-path-users > div.container-fluid {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 30px 35px;
  background-color: #fff;
  z-index: 100;

  drop-area > div, players-list > div {
    margin-top: 30px;
  }

  .save-button-group button.btn {
    display: inline-block;
    margin: 20px 5px;
    width: 200px;
  }
}