edit-skill-scoring-preview > div {
  h1 {
    font-family: 'AvenirNextLTPro Bold';
  }

  hr {
    border-top: 2px solid #dcdcdc;
  }

  .buttons-holder {
    margin-top: 30px;
    text-align: center;
  }

  div.preview {
    span.preview-group {
      display: block;
      margin-top: 20px;
      margin-left: -15px;

      span {
        display: inline-block;
        font-size: 16px;
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }

  error-message > div {
    margin-top: 30px;
  }
}