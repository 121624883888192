card-title > div {
  display: inline-block;
  width: 100%;
  color: #FFF;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 16px 24px;
  background-color: #003;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-transform: uppercase;

  img {
    float: right;
    margin-right: -8px;
  }
}