thumbnail-uploader > div.container-fluid {
  div.upload-zone {
    margin-top: 10px;
    margin-left: calc(~"50% - 40px");
    padding-bottom: 40px; //take out 2px from the height due to top and bottom borders
    width: 80px;
    border: 1px solid #b1aeae;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    &:not(.disabled-item) {
      cursor: pointer;
    }

    span.upload-icon {
      position: absolute;
      color: #9fc0f2;
      top: 22%;
      left: 50%;
      transform: translate(-50%);
      font-size: 20px;
    }

    img.thumbnail-preview {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      margin-left: 50%;
      transform: translate(-50%);
      z-index: 100;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
  }

  span.instruction {
    font-size: 12px;
    color: #5a5a5a;
    margin-top: 10px;
  }
}