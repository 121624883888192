manage-product-paths-drop-area > div.container-fluid {
  height: 300px;
  border: 2px dashed #ececec;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  overflow-y: auto;

  h3.title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    font-family: 'AvenirNextLTPro Bold';
    color: #bdbbbb;
  }

  li.dndPlaceholder {
    display: none;
  }

  manage-product-draggable-paths-list-item {
    &:nth-child(odd) > div {
      background-color: darken(#f2f2f2, 5%);
    }
  }
}