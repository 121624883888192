contests-list-search-filters {
  input.form-control, .btn {
    border-radius: 4px;
  }

  .fundraiser-filters {
    button.btn {
      width: auto;
      display: inline-block;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}