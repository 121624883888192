input[type=text], input[type=password], input[type=email], input[type=number], input[type=tel] {
    border: 1px solid #ccc;
}

textarea {
    border: 1px solid #9B9BA3;
}

button.submit {

    border: 1px solid #498FE1;
    padding: 5px 15px;
    width: 20%;
    height: 30px;
    background: #498FE1;
    color: #fff;
    font-size: 12px;
}

button.submit:disabled {
    opacity: 0.4;
}

select {
    padding: 0px 15px 0 0;
    border: 0;
    background: url('../img/arrow_down.png') no-repeat 100% 50%;
    appearance: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
}

.active .btn-success {
    border: 0;
    border-radius: 5px;
    width: 100px;
    height: 35px;
    padding: 5px;
    background: #7DD220;
    color: #fff;
    font-size: 14px;
}

.btn:disabled {
  cursor: default;
}
