.org-subscription-details {
  padding-bottom: 300px;
  padding-left: 0;
  padding-right: 0;

  @media(min-width: 768px) {
    padding-left: 15px;
  }

  .subscription-name {
    font-family: 'AvenirNextLTPro Bold';
    color: #4a4a4a;
    margin-top: 40px;
  }

  textarea {
    margin-top: 30px;
    resize: none;
    height: 100px;
    width: 100%;
  }

  iframe {
    width: 100%;
  }

  .table-label {
    color: #575757;
    margin-bottom: 15px;
  }
}
