info-message > span {
  color: #333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
  width: 100%;
  text-align: center;

  &.mobile {
    font-size: 4.3vw;
    line-height: 6.5vw;
  }
}