range-more-than-ten-selector > div.container-fluid {
  label {
    display: inline-block;
    margin-right: 10px;
  }

  input.form-control {
    display: inline-block;
    width: 100px;
    border-radius: 4px;
  }
}