player-skills manager-list-header > div.container-fluid {
  @media (max-width: 1220px) {
    font-size: 12px;
  }
}

.player-skills {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;

  .skills-search {

    input {
      width: 90%;
      margin-left:5%;
    }
  }

  player-skill-detail > div {
    error-message > div {
      margin-left: -15px;
      margin-top: 10px;
      width: 100%;
    }
  }

  .skills-table {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 20px;

    .skills-header {
      font-weight: bold;
      padding-bottom: 1%;
    }

    .skills-odd {
      background-color: darken(#f2f2f2, 5%);
      padding-left: 30px;
      margin-left: -30px;
      padding-right: 30px;
      margin-right: -30px;
    }
    .skills-even {
      padding-left: 30px;
      margin-left: -30px;
      padding-right: 30px;
      margin-right: -30px
    }

    .skills-summary {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .skill-detail {
      margin-top:1%;
      margin-bottom: 1%;

      strong {
        font-size:16px;
      }

      .skill-properties {
        h5 {
          text-align: center;
        }
      }
    }
  }
}

.player-skills-col {
  width: 14.28%;
  display: inline-flex;
  padding-right: 15px;
  span {
    display: inline-block;
    &.not(:first-child) {
      vertical-align: text-top;
    }
  }
  @media (max-width: 1199px) {
    &:not(.content-col) {
      padding-right: 5px;
    }

    &:not(.content-col) span {
      font-size: 12px;
      &:not(:first-child) {
        margin-left: 2%;
      }
    }
  }
}

.player-skills-col.smaller {
  width: 7.14%;
}

.player-skills-col.medium {
  width: 9.26%;
}

.player-skills-col.larger {
  width: 21.42%;
}

.player-skills-col.x-large {
  width: 28.56%;
}

.review-summary .player-skills-col span:not(:first-child) {
  margin: auto;
}
