edit-product-public-price > div {
  & > dropdown {
    &:first-of-type {
      & > div.dropdown {
        width: 80px;
      }
    }

    &:not(:first-of-type) {
      & > div.dropdown {
        width: 200px;
      }
    }

    & > div.dropdown {
      margin-left: 10px;
      height: 34px;

      button.btn {
        height: inherit;
      }

      ul.dropdown-menu {
        width: 100%;
        min-width: 0;
      }
    }
  }

  manager-product-pricing-dropdown dropdown > div.dropdown {
    width: 150px;
  }
}