my-fundraiser-pledge-order-items {
  .title {
    text-transform: uppercase;
  }

  .table-header {
    border-bottom: 1px solid #656565;
    color: #656565;
  }

  div.col-xs-12.table-header,
  div.col-xs-12.table, {
    padding: 0 5px;
  }
}