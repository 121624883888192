.html-input {
  .btn {
    width: auto;
  }

  .cleared-html-message {
    position: absolute;
    font-size: 11px;
    color: #940404;
    margin-top: 5px;
  }

  textarea {
   -webkit-transition : border 100ms ease-out;
   -moz-transition : border 100ms ease-out;
   -o-transition : border 100ms ease-out;
   &.error {
     border: 1px solid red;
   }
  }
}
