pledge-stats-item > div {
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  button {
    margin-top: 20px;
    display: block;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 140px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}