.register-new,
.register {
  background-color: #003;
  text-align: center;
  margin-bottom: 10%;
  width: 100%;
  min-height: 800px;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  color: #eee;
  max-width: 550px;
  @scrollbarWidth: 3px;
  @checkboxSize: 25px;

  .list-loading-overlay {
    background: rgba(0, 0, 51, 0.65);
  }

  ::-webkit-scrollbar {
    width: @scrollbarWidth;
    padding-right: 20px !important;
    margin: auto !important;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: lightgray;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: lightgray;
  }

  @media(max-width: 1199px) {
    min-height: 830px;
  }

  button:disabled, button:disabled:hover {
    border-color: #888C88;
    background-color: #9B9B9B;
  }

  .valid-input-img {
    height: 25px;
    position: absolute;
    right: -30px;
    top: 33px;
  }

  .alert a {
    color: #a94442;
    cursor: pointer;
  }

  h5 {
    line-height: 19px;
  }

  a {
    color: #eee;
  }

  a.disabled-item {
    cursor: default;
  }

  .payment {
    width: 85%;
    margin-left: 7.5%
  }

  input {
    font-size: 16px;
    color: #000;
    &.ng-invalid.ng-touched:not(:focus) {
      border: 1px solid red;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
  }

  input:not([type="radio"]) {
    width: 100%;
    height: 30px;
    padding-left: 10px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
  }

  input:focus {
    border: 1px solid dodgerblue;
  }

  .login-form input {
    padding-left: 40px;
  }

  .marginTopLarge {
    margin-top: 45px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .marginBottom {
    margin-bottom: 10%;
  }

  .marginBottomSmall {
    margin-bottom: 10px;
  }

  .marginTopSmall {
    margin-top: 15px;
  }

  .cropper {
    height: auto;
    width: 20%;
    border-radius: 50%;
  }

  .parent {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .complete-profile-input {
    background-repeat: no-repeat;
    padding-left: 50px;
    background-position: 10px 10px;
    height: 50px;
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
    border-top: solid 1px #ECECFB;
    border-bottom: solid 1px #ECECFB;
  }

  .or {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  input.complete-profile-input {
      background-repeat: no-repeat;
      background-position: 10px 7px;
      border: 1px solid #ECECFB;
      height: 50px;
      padding-left: 50px;
  }

  #parents-email {
    background-image: url(/img/parents_text.png);
    background-position: 10px 15px;
  }

  #parents-phone {
    background-image: url(/img/tel.png);
  }

  .header {
    padding-top: 25px;
  }

  .error-message {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .continue-to-topya .success-notification {
    margin-bottom:25px;
  }

  .danger {
    position: absolute;
    margin-top: 2px;
    font-size: 10px;
  }

  .back-link {
    text-align: left;
    padding-top: 10px;
    height: 30px;

    a {
      cursor: pointer;
    }
  }

  .gender-dropdown {
    h5 {
      color: #000;
    }
  }

  .gender span {
    display: inline-flex;
  }

  .gender span:not(.pull-left) {
    margin: 0 10px;
  }

  .gender label {
    margin-bottom: 0;
    vertical-align: top;
  }

  .gender input {
    margin-left: 5px;
  }

  &.additional-selection {
    .org-logo {
      width: 100%;
    }

    .additional-selection-item {
      padding-top: 25px;
      padding-bottom: 25px;
      border-top: 1px solid #81819b;
      h4 {
        float: left;
        max-width: calc(~"100% - 30px");
      }
    }

    .skip-link {
      color: #fff;
      font-size: 18px;
      display: block;
    }

    .btn {
      margin-bottom: 30px;
      font-size: 18px;
    }
  }

  .animation-if {
    &.ng-enter,
    &.ng-leave {
      -webkit-transition: max-height .5s;
      -moz-transition: max-height .5s;
      -ms-transition: max-height .5s;
      -o-transition: max-height .5s;
      transition: max-height .5s;
      overflow: hidden;
      max-height: 0;
    }
    &.ng-enter,
    &.ng-leave.ng-leave-active {
      overflow: hidden;
      max-height: 0;
    }
    &.ng-leave,
    &.ng-enter.ng-enter-active {
      max-height: 200px;
    }
  }
}

.margin-top-40px {
  margin-top: 40px;
}

.register.is-mobile {
    min-height: 100vh;
    margin: 0;
    max-width: none;

    .back-link {
      height: 35pt;
    }

    .btn {
     font-size: 25pt;
    }

    .valid-input-img {
      height: 25%;
      right: -7%;
      top: 68%;
    }

    .back-link a,
    a.tos-link {
      font-size: 25pt;
    }

    .login-form button {
      height: auto;
    }

    input:not([type="radio"]) {
      height: 40pt;
      font-size: 30pt;
    }

    input::-webkit-input-placeholder { font-size: 18pt; padding-top: 6pt; }
    input::-moz-placeholder { font-size: 18pt; padding-top: 6pt; }
    input:-ms-input-placeholder { font-size: 18pt; padding-top: 6pt; }
    input:-moz-placeholder { font-size: 18pt; padding-top: 6pt; }

    input[type="radio"] {
      width: 2em;
      height: 2em;
    }

    .gender label, .gender span.pull-left {
      font-size: 20pt;
    }

    input[id="inviteCode"] {
      background-size: contain;
    }

    h5 {
      font-size: 25pt;
      line-height: 30pt;
    }

    h4 {
      font-size: 28pt;
      line-height: 55pt;
    }

    h3 {
      font-size: 30pt;
    }

    ul.dropdown-menu {
      font-size: 25pt;
    }

    .margin-top-30 {
      margin-top: 15%;
    }

    .danger {
      font-size: 15pt;
    }

    .complete-profile-input {
      margin-top: 15%;
    }

    .error-message {
      font-size: 25pt;
    }

    .status-msg {
      font-size: 25pt;
    }

    h6 {
      font-size: 20pt;
    }

    ol {
      font-size: 25pt;
    }

    a.btn-success {
      margin-bottom: 20pt;
    }

    #parents-phone {
      margin-top: 0;
    }

    .or {
      margin-top: 6%;
      margin-bottom: 6%;
    }

    &.additional-selection {
      .additional-selection-item .selection-icon {
        width: 30pt;
        height: 30pt;
        margin-top: 15pt;
      }

      .skip-link {
        font-size: 25pt;
      }
    }

    // &.join-organization {
    //   @m_itemHeightInList: 90px;
    //   @m_arrowMarginLeft: 15px;
    //   @m_arrowMarginRight: 15px;
    //   @m_checkIconMarginRight: 10px;
    //   @m_checkboxMarginScrollable: 29.5px;
    //   @m_checkboxMarginNonScrollable: 35px;
    //   @m_arrowSize: 25px;
    //   @m_itemFontSize: 25px;
    //   .inner-scroll-400 {
    //     margin-top: 80px;
    //     max-height: 800px;
    //   }
    //   .alert {
    //     font-size: 30px;
    //   }
    //   input {
    //     margin-top: 40px;
    //   }
    //   .org-item {
    //     margin-top: 20px;
    //     &:not(:first-of-type) {
    //       padding-top: 20px;
    //     }
    //     .org-logo {
    //       height: @m_itemHeightInList;
    //
    //       img {
    //         max-height: @m_itemHeightInList;
    //         margin-top: @m_itemHeightInList / 2;
    //       }
    //     }
    //
    //     .org-name-with-checkbox {
    //       height: @m_itemHeightInList;
    //       span {
    //         margin-top: @m_itemHeightInList / 2;
    //         &:not(.glyphicon) {
    //           font-size: @m_itemFontSize;
    //           width: calc(~'100% - (@{m_checkboxSize} + @{m_arrowSize} + @{m_arrowMarginLeft} + @{m_arrowMarginRight} + @{m_checkIconMarginRight})');
    //         }
    //         &.glyphicon {
    //           font-size: @m_arrowSize;
    //           margin-left: @m_arrowMarginLeft;
    //           margin-right: @m_arrowMarginRight;
    //         }
    //       }
    //     }
    //
    //     .join-organization-teams {
    //       .inner-scroll-200 {
    //         max-height: 300px;
    //       }
    //     }
    //
    //     .teams-holder {
    //       &.ng-enter.ng-enter-active {
    //         max-height: 400px;
    //       }
    //       &.ng-leave {
    //         transition: .3s linear all;
    //         max-height: 400px;
    //       }
    //     }
    //   }
    //
    //   .join-organization-teams {
    //     input {
    //       margin-bottom: 10px;
    //     }
    //     .team-item {
    //       height: @m_itemHeightInList;
    //       margin-top: 10px;
    //       font-size: @m_itemFontSize;
    //       span {
    //         width: calc(~'100% - (@{m_checkboxSize} + @{m_arrowSize} + @{m_checkboxMarginScrollable})');
    //         margin-top: @m_itemHeightInList / 2;
    //         padding-right: 10px;
    //         &.glyphicon {
    //           font-size: @m_arrowSize;
    //         }
    //       }
    //       .check-icon {
    //         margin-right: @m_checkboxMarginScrollable;
    //         height: 35px;
    //       }
    //       &.non-scrollable {
    //         span {
    //           width: calc(~'100% - (@{m_checkboxSize} + @{m_arrowSize} + @{m_checkboxMarginNonScrollable})');
    //         }
    //         .check-icon {
    //           margin-right: @m_checkboxMarginNonScrollable;
    //         }
    //       }
    //     }
    //   }
    //
    //   .check-icon {
    //     height: @m_checkboxSize;
    //     margin-top: @m_itemHeightInList / 2;
    //     margin-right: @m_checkIconMarginRight;
    //   }
    //
    //   a {
    //     font-size: 25px;
    //   }
    // }

  a {
    font-size: 25px;
  }

  .btn-warning {
    margin-top: 5%;
  }

  .continue-to-topya p {
    font-size: 25pt;
  }
}// mobile

.register-new.pad-top-30 {
  padding-top: 30px;
}
