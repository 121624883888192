.team-contest-details {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #ececec;

  .contest-dates {
    font-size: 11px;
    margin-top: 10px;
    display: block;
    color: #888;
  }

  .contest-stats-description {
    margin-top: 10px;
    display: block;
    font-size: 11px;
    color: #cdcdcd;
  }

  .stats-item {
    padding: 0;
    &:not(:last-of-type) {
      border-right: 1px solid #cdcdcd;
    }
    @media (max-width: 991px) {
      margin-top: 10px;
    }
  }

  .contest-title {
    @media (max-width: 991px) {
      text-align: center;
    }
  }
}