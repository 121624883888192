edit-product > div {
  .page-header {
    font-family: 'AvenirNextLTPro Bold';
    border-bottom: none;
  }

  .nav-tabs.nav-justified > li > a {
    color: #555;
  }

  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
    border-color: dodgerblue;
    border-bottom: none;
    color: dodgerblue;
    font-weight: bold;
  }

  @media (min-width: 768px) {
    .nav-tabs.nav-justified > li > a {
      border-bottom-color: dodgerblue;
    }
  }
}