parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid {
  padding: 0;

  & > div.list-header {
    display: block;
    width: 100%;
    background-color: rgba(229,229,229,0.3);
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 24px 16px 80px;
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 0;
  }

  spinner > div {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 24px;
  }

  info-message > span,
  error-message > div {
    margin-top: 24px;
  }

  div.teams-holder {
    max-height: 250px;
    overflow-y: auto;
  }

  &.mobile {
    & > div.list-header {
      border-width: 2px;
      padding: 5vw 7.5vw 5vw 12.5vw;
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-top: 0;
    }

    spinner > div,
    info-message > span,
    error-message > div {
      margin-top: 7.5vw;
    }

    div.teams-holder {
      max-height: 70vw;
    }
  }
}