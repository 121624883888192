parent-my-kids-remove > div {
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(40,41,61,0.04), 0 8px 16px 0 rgba(96,97,112,0.16);
  padding: 24px;
  padding-top: 28px;

  & > span.title {
    color: #333;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
  }

  & > p.prompt-message {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 16px;
  }

  & > div.buttons-holder {
    margin-top: 32px;
    text-align: right;

    & > button.btn {
      width: auto;
      font-size: 13px;

      &:not(.cancel) {
        margin-left: 16px;
      }
    }
  }

  &.mobile {
    border-radius: 2.5vw;
    box-shadow: 0 4px 8px 0 rgba(40,41,61,0.04), 0 16px 32px 0 rgba(96,97,112,0.16);
    padding: 7.5vw;
    padding-top: 8.75vw;

    & > span.title {
      font-size: 5vw;
      line-height: 7.5vw;
    }

    & > p.prompt-message {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-top: 5vw;
    }


    & > div.buttons-holder {
      margin-top: 10vw;
      text-align: center;
      width: 100%;

      & > button.btn {
        width: 100%;
        font-size: 5vw;
        line-height: 7.5vw;
        padding: 2.5vw 5vw;
        border-radius: 2px;

        &:not(.cancel) {
          margin-left: 0;
          margin-top: 5vw;
        }
      }
    }
  }
}