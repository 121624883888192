.organization-users {
  padding-top: 30px;

  input, input.form-control {
    border-radius: 4px;
  }

  button.btn {
    border-radius: 4px;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .table-row {
    padding-right: 15px;
    padding-left: 15px;
    .table-header {
      background-color: #003;
      color: white;
      font-weight: bold;
      padding-top: 5px;
      padding-bottom: 5px;
      span.glyphicon {
        vertical-align: sub;
        margin-left: 5px;
      }
      .dropdown-menu {
        width: 100%;
        text-align: center;
      }
    }
    .table-item {
      padding-top: 8px;
      padding-bottom: 8px;
      border: 1px solid #969696;
      border-top: none;
      &:nth-child(even) {
        background-color: #d7d7d7;
      }
      .organization-user-detail {
        width: calc(~"100% + 40px");
        .user-names {
          word-break: break-all;
        }
      }
    }
  }

  div[class*='inner-scroll-'] {
    overflow-y: scroll;
  }
}