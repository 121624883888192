.channel-forms {
  .btn {
    border-radius: 8px;
    font-weight: bold;
  }

  &.channel-basics-form {
    .checkbox-group {
      width: 270px;
      .checkbox-holder {
        background: #eee;
      }
    }
    .video-types-selection {
      & > div {
        border: 1px solid #dae2ea;
        color: #5c5c5c;
        margin: 5px 0;
        border-radius: 8px;
        padding: 0 10px;
        .clickable {
          padding: 10px 0;
          &:hover h5 {
            text-decoration: underline;
          }
        }
      }
      h5 {
        display: inline-block;
        margin-bottom: 4px;
      }
      .btn {
        border-radius: 50%;
        padding-top: 2px;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        color: #fff;
        padding-left: 4px;
        &.selected {
          background-color: #5cb85c;
        }
        &.deselected {
          background-color: #9b9b9b;
        }
      }
    }
  }

  &.channel-sponsors-form {
    .brand-logo {
      max-height: 80px;
      max-width: 100%;
    }

    ul.sponsors-sorting {
      &.bordered {
        border: 1px dashed #bbb;
      }

      li {
        padding-top: 30px;
        margin-bottom: 10px;
        min-height: 140px;

        .border-holder {
          border-bottom: 2px dashed #d1d1d1;
          position: absolute;
          bottom: 10px;
          width: calc(~"100% - 30px");
        }

        .primary-sponsor-selector {
          border: 1px solid #d1d1d1;
          border-radius: 8px;
          overflow: hidden;
          div:first-child {
            padding: 10px;
            background-color: #eee;
            border-right: 1px solid #d1d1d1;
            margin-right: 10px;
            display: inline-block;
          }
        }
      }
    }
  }

  .drag-uploader {
    margin-top: 40px;
    width: 100%;
    padding: 6% 15px;
    text-align: center;
    border: 1px dashed #bbb;
    position: relative;
    .glyphicon-remove {
      color: #e30000;
      position: absolute;
      right: 5px;
      top: 5px;
    }
    &.drag-drop {
      opacity: 0.5;
    }

    .preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 64px;
      opacity: 0.7;
    }

    h2 {
      margin-bottom: 15px;
    }

    a {
      color: #8b3bf4;
      cursor: pointer;
    }
  }

  &.channel-basics-view {
    .header {
      margin: 40px 0 30px 0;
      font-family: 'AvenirNextLTPro Bold';
    }

    @media (max-width: 1024px) {
      h4 {
        font-size: 15px;
      }
    }

    .channel-logo {
      max-height: 140px;
      margin-top: 20px;
    }

    .subscription-table {
      margin-top: 35px;
      border: 1px solid #a3a3a3;

      .table-header {
        color: #fff;
        background-color: #003;
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .table-items {
        max-height: 190px;
        overflow-y: auto;
        overflow-x: hidden;
        .table-item {
          padding-top: 10px;
          padding-bottom: 10px;
          &:nth-child(odd) {
            background-color:#d7d7d7;
          }
        }
      }
    }
  }

  &.channel-edit-sponsors-form {
    ul.sponsors-sorting {
      &.bordered {
        border: 1px dashed #bbb;
      }

      li {
        .sponsor-logo-uploader.dragover {
          background-color: #ccc;
        }
        padding-top: 30px;
        margin-bottom: 10px;

        .brand-logo {
          max-height: 150px;
          max-width: 100%;
        }

        .border-holder {
          border-top: 2px dashed #d1d1d1;
          margin-top: 30px
        }

        .delete-sponsor-icon {
          color: #e4000b;
          margin-top: 60px;
          margin-right: 5%;
        }
      }
    }

    .drag-uploader {
      margin-top: 40px;
      width: 100%;
      padding: 6% 15px;
      text-align: center;
      border: 1px dashed #bbb;
      position: relative;
      &.drag-drop {
        opacity: 0.5;
      }

      .preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 64px;
        opacity: 0.7;
      }

      h2 {
        margin-bottom: 15px;
      }

      a {
        color: #8b3bf4;
        cursor: pointer;
      }
    }
  }

  input.form-control.ng-invalid {
    border-color: red;
  }
}
