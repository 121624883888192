signup-org-list-preview > div.container-fluid {
  padding: 0;

  & > span.list-label {
    color: #333333;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 40px;
    display: block;
  }

  & > div.list-header {
    display: block;
    width: 100%;
    margin-top: 24px;
    background-color: rgba(229,229,229,0.3);
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 24px 16px 24px;
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  & > div.items-holder {
    max-height: 640px;
    overflow-y: auto;
  }

  &.mobile {
    & > span.list-label {
      font-size: 5vw;
      line-height: 7.5vw;
      margin-top: 12.5vw;
    }

    & > div.list-header {
      margin-top: 7.5vw;
      padding: 5vw 7.5vw 5vw 7.5vw;
      font-size: 4.4vw;
      line-height: 6.6vw;
    }
  }
}