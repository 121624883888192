create-product-basics-form > div.container-fluid {
  margin-top: 30px;
  border: 2px solid #D7D7D7;
  border-radius: 6px;
  padding-top: 40px;
  padding-bottom: 40px;

  form {
    span {
      font-weight: bold;
    }

    input.form-control {
      margin-bottom: 20px;
      border-radius: 4px;
      max-width: 400px;
      &:nth-of-type(2) {
        margin-bottom: 50px;
      }
    }

    radio-input:not(:nth-of-type(1)) > div {
      margin-left: 10px;
    }

    .buttons-holder {
      width: 100%;
      max-width: 650px;
      text-align: right;
      margin-top: 40px;
    }

    div.custom-head-to-head-count-holder {
      input.form-control {
        margin-right: -5px;
        width: 260px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    form {
      checkbox-group {
        &:first-child > div {
          margin: 0;
        }
        & > div.form-control {
          display: block;
          margin-top: 5px;
          width: 400px;
        }
      }

      .buttons-holder {
        text-align: left;
        padding-left: 150px;

        .btn.btn-primary.new-btn-primary {
          margin-top: 10px;
        }
      }
    }
  }
}