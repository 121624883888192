.kid-settings-modal {
  font-size: 16px;

  .password-link {
    font-size: x-small;
    text-align: left;
  }

  .bottom-block {
    border-top: 1px solid #F1F1F8;
    padding-top: 10px;
    margin: 5px 0 10px 0;
  }

  .left-block {
    border-right: 1px solid #F1F1F8;
    padding-right: 10px;
    word-break: break-all;
    text-align: center;
  }

  .options-block .container-fluid {
    border-bottom: 1px solid #F1F1F8;
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 700px ) and (max-width: 1480px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &:last-child {
      border-bottom: none;
    }
    span:first-child {
      position: relative;
      top: 6px;
    }
  }

  .birth-date, .gender {
    margin: 10px 0;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      position: relative;
      top: 3px;
      margin-left: 3px;
    }
  }

  .user-name, .full-name {
    margin: 10px 0;
  }

  .user-name {
    color: lightgrey;
  }

  .big-img {
    width: 100%;
    border: 2px solid #7DD220;
  }

  input, select {
    height: 35px;
    border-radius: 0;
  }

  .dropdown {
    height: 34px;
    border-radius: 0px;
    border: 1px solid #ECECFB;
    a {
      height: 30px;
      padding-top: 7px;
    }
    .gender-icon {
      margin-top: -4px;
      margin-left: -10px;
    }
  }

  .coach-account-password, .coach-account-email, .buttons-group {
    margin-top: 10px;
  }

  .buttons-group button {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .coach-account-username {
    background: url('../img/username-icon.png') no-repeat 3px #fff;
    padding-left: 49px;
  }

  .btn-save-account {
    margin-bottom: 11px;
  }

  .error-login {
    position: absolute;
    top: 10vh;
  }

  .btn-edit {
    margin-bottom: 10px;
    height: 34px;
  }

  .no-border {
    border-right: none;
  }

  input {
    font-size: smaller;
  }

  button {
    font-size: medium;
  }

  .btn-activate-deactivate {
    color: darkgray;
    font-weight: bold;
  }
}
