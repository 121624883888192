.teamPlayers {
  .flag-url {
    height: 7%;
    width: 15%;
    min-width: 24px;
    vertical-align: text-top;
    min-height: 12px;
  }

  .big_img {
    width: 102px;
    height: 102px;
  }

  .info-wrapper {
    padding-bottom: 20px;
    border-bottom: #eeeeee 1px solid;
    font-weight: bold;
  }

  .profile_info span {
    display: block;
  }

  .statistics {
    margin-bottom: 20px;
  }

  .achievments-blocks {
    img {
     width: auto;
     height: 25px;
    }

    .label {
      color: lightgrey;
      margin-top: 5px;
      font-size: small;
      padding: 0;
      display: flex;
    }

    .achievment-value {
      vertical-align: middle;
    }

    .number {
      font-size: larger;
      font-weight: bold;
    }
  }

  .challenges-stats {
    margin-top: 10px;
    border-top: #eeeeee 1px solid;
  }

  .freestyle-label, .virtual-coach-label {
    margin-top: 20px;
  }

  .virtual-coach-image {
    margin-top: 40px;
  }

  .freestyle-challenges {
    margin-top: 20px;
    color: white;
    .wrapper {
      div {
        padding: 20px 0;
        border-left: 1px solid white;
        border-right: 1px solid white;
        text-align: center;
        img {
          display: block;
          margin: 10px auto;
          width: 56px;
          height: 56px;
        }
      }
    }

    .in_progress {
      background-color: #68B045;
    }

    .wins {
      background-color: #F4A522;
    }

    .draws {
      background-color: #F8C063;
    }

    .loss {
      background-color: #C3C3C3;
    }
    @media (max-width: 1281px) {
      font-size: smaller;
    }

    @media (min-width: 1282px) and (max-width: 1441px) {
      font-size: small;
    }

    @media (min-width: 1442px) {
      font-size: larger;
    }
  }

  .virtual-coach {
    margin-top: 20px;
    border-top: #eeeeee 1px solid;
    .virtual-coach-name {
      position: absolute;
      bottom: 20px;
      text-transform: uppercase;
      width: 60px;
      text-align: center;
    }
    img {
      margin: 20px 0;
    }
    .virtual-coaches-arrow-right img {
      position: relative;
      top: 70px;
    }

    .virtual-coach-green-checkbox {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
