signup-login > form {
  width: 100%;
  margin: 64px 0;
  display: grid;

  span.view-title {
   color: #333333;
   font-size: 28px;
   font-weight: 900;
   letter-spacing: 0;
   line-height: 38px;
   display: block;
  }

  text-input > span.label,
  error-message > div,
  toggle-input > span.label {
    margin-top: 24px;
  }

  toggle-input > span.label {
    display: inline-block;
    width: calc(~"100% - 30px");
  }

  button.btn {
    margin-top: 40px;
  }

  &.mobile {
    span.view-title {
      margin-top: 12.5vw;
      font-size: 8.75vw;
      line-height: 11vw;
    }

    error-message > div,
    text-input > span.label,
    toggle-input > span.label {
      margin-top: 7.5vw;
    }

    toggle-input > span.label {
      width: calc(~"100% - 100px");
    }

    button.btn {
      margin-top: 12.5vw;
      font-size: 5vw;
      line-height: 7.5vw;
      border-radius: 1.25vw;
      padding: 2.5vw 5vw;
    }
  }
}