.contest-teams-selector {
  .chosen-container {
    width: 100%;
  }
  .chosen-container-multi .chosen-choices {
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 34px;
    li.search-choice {
      margin: 6px 5px 3px 0;
      z-index: 1;
    }
    li.teams-selector-placeholder {
      margin-top: 7px;
      margin-left: 5px;
      color: #555;
    }
    span.dropdown-toggle-span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .remove-choice {
      margin-right: 5px;
      color: #555;
    }
  }

  .dropdown-menu {
    width: 100%;

    .contest-teams-selector-dropdown {
      color: #797979;
      padding-bottom: 20px;

      .btn {
        &:not(.toggle-selection-button) {
          margin-bottom: 15px;
        }
        &.toggle-selection-button {
          padding: 0;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          color: #fff;

          &.btn-default {
            background-color: #9b9b9b;
            padding-left: 1.5px;
            padding-top: 2px;
          }

          &.btn-success {
            background-color: #5cb85c;
            padding-top: 1px;
          }
        }

        font-weight: normal;
        border-radius: 4px;
      }
    }
  }
}
