new-high-five > button.btn.btn-primary,
new-high-five > button.btn.btn-primary.cancel {
  width: 104px;
  padding: 8px;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;

  & > img {
    vertical-align: sub;
    margin-right: 8px;
  }
}