.myKidsDetails {
  margin: 20px 0;

  .header {
    background: #1B1C2C;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    height: 40px;
    padding-top: 10px;
  }

  .namesWrapper {
    font-size: 16px;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  .buttonAddkid {
    padding: 20px 0;
    font-weight: bold;
    font-size: large;
  }

  .kid {
    padding-top: 10px;
  }

  .kidsWrapper {
    border: double 2px #E4E4EF;
    background-color: #fff;
    margin-bottom: 20px;
  }
}//myKidsDetails

.myKidsDetailsBlock {
  background-color: white;
  border-bottom: 1px solid whitesmoke;
  font-size: 12px;
  min-height: 100px;
  padding: 10px 2px 15px 2px;

  .arrowRight {
    position: relative;
    top: 22px;
  }

  .kidsWrapper:last-child {
    border: none;
  }

  &:nth-of-type(even) {
    float: right;
    margin-right: 20px;
  }

  &:nth-of-type(odd) {
    float: left;
    margin-left: 20px;
  }
}//myKidsDetailsBlock
