@virtualCoachVerticalPadding: 30px;

.player-profile-settings {
  font-size: small;
  background-color: #f7f7fd;
  margin-top: 20px;
  .change-avatar-button {
    display: block;
  }
  .player_img {
    width: 100px;
    height: 100px;
  }
  input {
    width: 100%;
    height: 30px;
  }
  .inputs {
    margin-top: 20px;
    input {
      margin-bottom: 5px;
      border: 1px #d6d6d6 solid;
      background-position-x: 1.3%;
      padding-left: 49px;
      border-radius: 0;
      text-overflow: ellipsis;
    }
  }

  .coach-account-username {
    background-image: url('../img/username-icon.png');
    background-repeat: no-repeat;
    background-position-y: 6px;
  }

  .gender-select {
    background-image: url('../img/gender_icon.png');
    background-repeat: no-repeat;
    background-position-y: 5px;
  }

  .dropdown {
    color: #A9A9A9;
    font-size: 15px;
    margin-bottom: 5px;
    height: 30px;
    border: 1px solid #d6d6d6;
    border-radius: 0;
    a {
      height: 26px;
      padding-top: 4px;
      color: #333 !important;
    }
    .gender-icon {
      margin-top: -4px;
      margin-left: -10px;
      width: 20px;
      top: 3px;
      position: relative;
      margin-right: 24px;
    }
  }

  .form-component .dropdown.disabled {
    opacity: 1;
    background-color: rgb(235, 235, 228);
  }

  .virtual-coaches-arrow-right img {
    margin-top: 70px + @virtualCoachVerticalPadding;
  }

  .virtual-coach-green-checkbox {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .country-dropdown {
    background-repeat: no-repeat;
    border-radius: 0;
    background-position: 4px 4px;
    background-size: 35px 20px;
  }

  .left-block {
    border-right: 1px solid #eeeeee;
  }

  .virtual-coach-name {
    text-transform: uppercase;
  }

  .separator {
    width: 110%;
    margin: 3px 0 8px 7px;
  }

  .save-player-account-btn {
    height:40px;
  }

  .virtual-coach-label {
    color: #d6d6d6;
    margin-left: 15px;
  }

  .gender-dropdown-triangle {
    color: lightgray;
    top: 4px;
  }

  .cancel {
    width: 100px;
    white-space: normal;
    margin: 10px auto 0 auto;
  }

  .error-message {
    margin-top: 10px;
  }

  .round-image-holder {
    width: 100px;
    margin: auto;
  }

  .virtual-coach-wrapper {
    text-align: center;
    padding: @virtualCoachVerticalPadding 15px;
    .virtual-coach-image-holder {
      height: 180px;
      width: 100%;
      position: relative;
      img:not(.virtual-coach-green-checkbox) {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  .nav, .dropdown-menu {
    width: 100%;
  }

  .primary-organization-logo {
    position: absolute;
    max-height: 70%;
    left: 5px;
    top: -5px;
    margin: auto;
    bottom: 0;
    max-width: 40px;
  }

  .country-flag {
    position: absolute;
    height: 40%;
    top: 20%;
    left: 5%;
  }
}
