.contest-navbar.mobile {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;

  .logo {
    max-width: 75%;
  }

  .container .contest-logo-holder {
    width: 80px;
    height: 80px;
    margin-top: -5px;
    .contest-logo {
      width: 50px;
    }
  }

  .primary-sponsor-holder {
    display: block;
    margin-top: 30px;
    transform: translateY(-50%);
  }
}
