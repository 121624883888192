.highlights.highlights-background {
  background-color: #f2f2f2;
}

.highlights .alert {
  margin-top: 95px;
}

.highlights .purchase {
  text-align: center;
  color: #5F7285;
  margin-top: 20px;
}

.highlights .topya-main-page-header {
  padding-left: 10px;
  margin-bottom: 15px;
  text-align: left;
}

.highlights .small-lower {
  margin-top: 5%;
}

.highlights .path-highlights {
  padding-top: 15px;
}

.highlights .small-highlight-path {
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: white;
  padding-top: 15px;
  border-left: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
}


@media (max-width: 1440px) {
  .highlights .arrow {
    margin-top: 135%;
  }
}

@media (min-width: 1441px) {
  .highlights .arrow {
    margin-top: 100%;
  }
}

.highlights .views {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.highlights .left {
  float: right;
}

.highlights .freestyles-header {
  margin-left: -15px;
}

.highlights .freestyle-highlights {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #F7F7FD;
}

.highlights .freestyle-highlight {
  margin-top: 20px;
  background-color: white;
  padding-top: 15px;
  border-left: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
}

.highlights .video-holder {
  width: 100%;
  padding-bottom: 56.7%;
  position: relative;
}

.highlights .video-holder:hover {
  z-index: 201;
  opacity: 0.9;
}

.highlights .highlight-description-holder {
  position: absolute;
  width: 100%;
  left: 0;
  height: 30px;
  overflow-y: hidden;
  bottom: 10px;
}

.highlights .highlight-description-holder:hover {
  overflow-y: visible;
}

.highlights .highlight-description:hover {
  top: auto;
  bottom: 0;
}

.highlights .highlight-description {
  z-index: 200;
  background-color: #1B1C2C;
  opacity: 0.9;
  color: white;
  padding: 1%;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 30px;
  position: absolute;
}

.highlights .freestyle-highlight .versus {
  position: absolute;
  width: 10%;
  top: 20%;
  left: 45%;
  z-index: 199;
}

.highlights .alternating-background:nth-child(odd) {
  background-color: #F7F7FD;
}
