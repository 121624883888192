slide-toggle-input > div {
  width: 35px;
  height: 20px;
  border-radius: 10px;
  background-color: #cbcbcb;
  position: relative;
  display: inline-block;

  & > div.circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
  }

  &.active {
    background-color: #4F82E4;

    & > div.circle {
      left: auto;
      right: 2px;
    }
  }

  &.mobile {
    width: 11.25vw;
    height: 6.25vw;
    border-radius: 3.125vw;

    & > div.circle {
      width: 5vw;
      height: 5vw;
      top: .625vw;
      left: .625vw;
    }

    &.active {
      & > div.circle {
        right: .625vw;
        left: auto;
      }
    }
  }
}

slide-toggle-input > span {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: super;

  &.mobile {
    font-size: 5vw;
    line-height: 7.5vw;
    margin-left: 2.5vw;
    width: calc(100% - 13.75vw);
    vertical-align: top;
  }
}