toggle > div {
  position: relative;
  border: 1px solid #dae2ea;
  color: #5c5c5c;
  margin: 5px 0;
  border-radius: 8px;
  padding: 0 10px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  &.invalid {
    border-color: red;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  }
  .clickable {
    padding: 10px 0;
    &:hover h5 {
      text-decoration: underline;
    }
  }
  .info-link {
    position: absolute;
    right: -30px;
    margin-top: 11px;
    top: 0;
    color: dodgerblue;
    font-weight: bold;
    @media (max-width: 767px) {
      left: 0;
      top: auto;
      bottom: -20px;
    }
  }
  h5 {
    display: inline-block;
    margin-bottom: 4px;
  }
  a.btn.selection-button {
    border-radius: 50%;
    padding-top: 1px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    color: #fff;
    padding-left: 4.5px;
    &.selected {
      background-color: #5cb85c;
    }
    &.deselected {
      background-color: #9b9b9b;
    }
    color: #fff;
  }
}