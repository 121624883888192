edit-my-pledge > div {
  background-color: #edf4fc;
  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 35px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;

  .modal-title {
    margin-bottom: 25px;
  }

  .modal-subtitle {
    margin-top: 30px;
  }

  .input-with-icon-holder {
    position: relative;
    span {
      position: absolute;
      right: 25px;
      top: 10px;
      color: #adaeae;
    }
    input {
      padding-right: 34px;
    }
  }

  button.btn, input.form-control {
    border-radius: 4px;
  }

  button.btn.btn-primary.cancel {
    background: #fff;
  }

  input.form-control.ng-invalid {
    border: 1px solid red;
  }
}