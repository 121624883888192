parent-subscriptions-list-item > div:not(.mobile) {
  padding: 16px 24px;
  background: #fff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;

  & > span {
    display: inline-block;
    color: #333333;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  & > span.name {
    width: 21.5%;
    font-weight: 900;
  }

  & > span.status {
    width: 15%;
    font-weight: 500;
  }

  & > span.price {
    width: 10.5%;
    font-weight: 500;
  }

  & > span.billing-term {
    width: 14.5%;
    font-weight: 500;
  }

  & > span.next-billing {
    width: 14%;
    font-weight: 500;
  }

  & > span.cancel-subscription {
    width: 22%;
    text-align: right;

    a {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      color: #4A90E2;
      margin-right: 32px;
    }

    span.glyphicon {
      color: #e5e5e5;
      font-size: 10px;
    }
  }
}

parent-subscriptions-list-item > div.mobile {
  padding: 5vw 7.5vw;
  background: #fff;
  border-left: 2px solid #e5e5e5;
  border-right: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;

  & > div {
    margin-bottom: 10vw;
    display: block;

    & > div.column-left {
      width: 50%;
      display: inline-block;
      vertical-align: top;

      span {
        color: #999999;
        font-size: 4.4vw;
        font-weight: 500;
        letter-spacing: 0;
        display: block;
        line-height: 6.6vw;
        margin-bottom: 10vw;
      }
    }

    & > div.column-right {
      width: 50%;
      display: inline-block;

      span {
        color: #333;
        font-size: 4.4vw;
        font-weight: 500;
        letter-spacing: 0;
        display: block;
        line-height: 6.6vw;
        margin-bottom: 10vw;
      }
    }
  }

  & > div.cancel-subscription {
    border-top: 2px dashed #e5e5e5;
    padding-top: 5vw;
    margin-bottom: 0;

    a {
      font-size: 4.4vw;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 7.5vw;
      color: #4A90E2;
    }

    span.glyphicon {
      color: #e5e5e5;
      font-size: 5vw;
      font-size: 3vw;
      float: right;
      top: 3vw;
    }
  }
}