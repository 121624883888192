player-freestyle-videos-nomination {
  .nomination-star {
    margin-left: 5px;
  }

  textarea {
    resize: vertical;
  }

  .save-button {
    margin-top: 50%;
    border-radius: 8px;
  }

  img {
    height: 17px;
    position: absolute;
    bottom: -3px;
  }

  h5 {
    margin-bottom: 15px;
  }
}
