.review {
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 100%;

  .scroll-holder {
    & > list-loading-overlay .list-loading-overlay {
      width: calc(~"100% + 30px");
      left: -15px;
    }
  }

  h1 {
    margin-top: 3%;
    color: #4a4a4a;

    &.page-title {
      font-family: 'AvenirNextLTPro Bold';
    }
  }

  hr {
    border-top-width: 2px;
  }

  .glyphicon-ok {
    color: #449d44;
    @media(max-width: 1150px) {
      font-size: 10px;
    }
  }

  .review-page-header {
    margin: 25px 5%;
    .row {
      margin: 0 -30px;
    }
  }

  .review-search {

      margin-right: 5%;
      margin-left: 5%;
  }
  .review-header-buttons {
    margin-left: 2%;
    margin-right: 2%
  }
  .review-sport-selector {
    margin-top: 0%;
  }

  .search-bar {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .review-table {
    margin-top: 20px;
    margin-bottom: 20px;

    list-loading-overlay .list-loading-overlay {
      width: calc(~"100% + 30px");
      left: -15px;
    }

    .review-header {
      font-weight: bold;
      background-color: #003;
      color: #fff;
      padding: 10px 15px;
      .dropdown-menu {
        width: 310px;
        &.far-right-dropdown {
          &.position-right-lg {
            @media (min-width: 1200px) and (max-width: 1440px) {
              right: 0;
              left: inherit;
            }
          }
          &.position-right-md {
            @media (min-width: 992px) and (max-width: 1199px) {
              right: 0;
              left: inherit;
            }
          }
          &.position-right-sm {
            @media (min-width: 768px) and (max-width: 991px) {
              right: 0;
              left: inherit;
            }
          }
          &.position-right-xs {
            @media (max-width: 767px) {
              right: 0;
              left: inherit;
            }
          }
        }
      }

      @media (max-width: 767px) {
        div[class^='col-'] {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      @media (max-width: 767px) and (min-width: 500px) {
        font-size: 12px;
      }

      @media (max-width: 499px) {
        font-size: 10px;
      }

      .active-filter {
        color: dodgerblue;
      }

      .dropdown {
        background: inherit;
      }
      .dropdown h4 {
        @media(max-width: 1150px) {
          font-size: 15px;
          font-weight: bold;
        }
      }

      span:nth-child(2), i {
        vertical-align: sub;
      }
    }

    div[class*='review-row'] {
      padding: 0 15px;
    }

    .review-row-even {
      background-color: darken(#f2f2f2, 5%);

      .review-detail .frame, .review-detail.frame {
        background-color: #fff;
        border: 1px solid #e5e5e5;
        border-top: none;
      }
    }

    .review-row-odd {
      .review-detail .frame, .review-detail.frame {
        background-color: #fff;
        border: 1px solid #fff;
        border-top: none;
      }
    }

    .review-summary {
      padding-top: 15px;
      padding-bottom: 15px;

      @media (max-width: 767px) {
        div[class^='col-'] {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      @media (max-width: 767px) and (min-width: 500px) {
        font-size: 12px;
      }

      @media (max-width: 499px) {
        font-size: 10px;
      }

    }

    .review-video {
      height: 225px;
      .video-holder {
        margin: 0;
      }
    }

    .responsive-text {
      font-size: 1vw;
    }

    .review-description {
      padding: 5px;
    }

    .description-row {
      min-height: 50px;
    }

    .review-badge {
      display:block;
      margin:auto;
      max-width: 100px;
      margin-top: 25px;
    }

    .review-detail {
      min-height: 400px;
      @media (min-width: 2100px) {
        min-height: 470px;
      }

      .frame {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .button-padding {
        margin: 4%;
      }

      .review-detail-properties {
        .path-description-header {
          margin-top: 10px;
        }

        .skill-stats {
          span {
            display: inline-block;
            &:nth-child(2), &:nth-child(4) {
              margin-left: 5px;
            }
            &:nth-child(3) {
              margin-left: 15px;
            }
          }

          .glyphicon {
            font-weight: bold;
            color: #498fe1;
            font-size: 18px;
            &.glyphicon-sm {
              font-size: 14px;
            }
          }

          img {
            height: 22px;
            vertical-align: sub;
            &.img-sm {
              height: 17px;
            }
          }
        }
      }
    }
    .edit-path-button {
      position: absolute;
      width: 100%;
      bottom: 15px;
      width: calc(~"100% - 30px");
      left: 30px;
    }
  }
  .flag-image {
    margin-left: 25%;
    margin-bottom: 5%;
    width: 55%;
  }
}
.popover-button {
  width: 220px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.popover-custom-date {
  margin-top:5px;

  .custom-date {
    margin: 5px;
    width: 95%;
  }
}

.finish-button {
    margin: 5px;
    width: 100px;
  }
