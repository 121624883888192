.manage-path {
  padding-top: 20px;

  .assign-path-to-users {
    @media (min-width: 992px) {
      text-align: center;
    }
  }

  div.popover {
    max-width: 700px;
    width: 700px;
    margin-left: 12px;
  }

  manager-path-users > div {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(~"100% + 2px");
  }

  .btn {
    border-radius: 8px;
    &:focus, &:active:focus {
      outline: none;
    }
  }

  hr {
    border-color: #ccc;
  }

  .buttons-holder {
    min-height: 34px;
  }

  .manage-path-header {
    margin-top: 1.5%;
    color: #4a4a4a;
  }

  .breadcrumb {
    padding-left: 0;
  }

  .path-stats-right {
    margin-top: 30px;
    h5 {
      line-height: 25px;
    }
  }

  .path-detail-frame {
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 30px;
    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      text-align: justify;
    }
  }

  .subheader {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .difficulty-icon {
    height: 20px;
    vertical-align: sub;
    margin-right: 10px;
  }
}
