.team-path-skill {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #e2deef;

  .skill-name {
    color: #b6b2c2;
  }

  .skill-badge {
    width: 100%;
  }
}
