register-select-type-button {
  .account-type-button {
    width: 30%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    background: none;
    padding: 0;
    border: 2px solid #fff;
    border-radius: 6%;
  }

  .selected-type {
    background-color: #fff;
  }

  .button-background {
    width: 100%;
  }
}