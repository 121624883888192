permissions-drag-drop > div {
  .drag-area {
    border: 1px solid #a3a3a3;
    padding: 10px 5px;
    &.dndDragover {
      opacity: .7;
    }
  }

  li.dndPlaceholder {
    display: none;
  }
}