.contests.contest-landing-page.landing-page-mobile {
  div[class^="col-"], div.container, div.container-fluid, &.container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }

  .contests.contest-page-header .page-links a.page-link {
    font-size: 11px;
  }

  div.row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .dropdown-menu {
    width: calc(~"100% - 10px");
    margin-left: 2px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
  }

  .tv-widget-col {
    padding: 0;
  }

  .leaderboard-widget-col {
    padding: 0;
  }

  .page-footer {

    .sponsors-list img {
      height: 6em;
    }

    .footer-upper {
      .how-it-works-holder {
        p {
          text-align: left;
        }
      }
    }
  }

  .ribbon-holder {
    top: 80px;
    position: relative;
  }
}
