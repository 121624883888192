fundraiser-widget-preview {
  button.btn {
    width: auto;
    border-radius: 4px;
  }

  textarea {
    width: 400px;
    height: 200px;
    resize: none;
  }

  iframe {
    width: 100%;
  }
}