edit-skill-scoring-form > div {
  h1 {
    font-family: 'AvenirNextLTPro Bold';
  }

  label {
    font-weight: bold;
    display: block;

    &.range-label {
      font-weight: normal;
      display: inline-block;
    }
  }

  input.form-control {
    width: 100%;
    max-width: 400px;
    border-radius: 4px;

    &[type="number"] {
      width: 80px;
      margin: 0 15px;
      display: inline-block;
    }
  }

  div.passing-input {
    label {
      display: inline-block;
    }
  }

  radio-input {
    &:not(:first-child) {
      & > div {
        margin-left: 15px;
      }
    }
  }

  div.buttons-holder {
    width: 100%;
    text-align: right;
  }

  error-message > div {
    margin-top: 30px;
  }
}