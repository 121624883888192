.skill-details {
  .topya-main-page-content {
    .review-message {
      margin-top: 30px;
    }

    input {
      font-weight: bold;
      color: #777777;
      width: 100%;
      margin: 0;
      height: 50px;
      padding: 0;
      border: 1px solid #DBDBEA;
      &:disabled {
        color: #000;
        border: 1px solid #fff;
        background-color: inherit;
      }
    }
  }

  button {
    width: 100%;
  }

  .button-div {
    padding: 0;
    margin-bottom: 20px;
  }

  .skill-header {
    margin-top: 20px;
    padding: 0;
    h4, h6 {
      color: #fff;
    }
    .edits {
      background-color: #DA1F22;
    }
    .approved {
      background-color: #11B0C0;
    }
    .saved {
      background-color: #1CA4B4;
    }
    .pending {
      background-color: #F8B32C;
    }
    .published {
      background-color: #72A936;
    }
  }

  .path-header {
    padding-left: 0;
  }

  .edits-comment {
    color: #DA1F22;
  }

  .approved-comment {
    color: dodgerblue;
  }
}
