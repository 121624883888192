.profile {
  .content {
    border: 1px solid #DBDBEA;
    padding: 5px;
    background-color: #fff;
  }

  .content-inner {
    padding-top: 10px;
    min-height: 310px;
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-top: 10px;
  }

  .x-icon {
    min-height: 22px;
  }

  .left-panel {
    text-align: center;
    a {
      float: left;
    }

    button {
      width: 100%;
    }
  }

  .right-panel {
    text-align: center;

    button {
      width: 50%;
    }
  }

  .settings {
    margin-top: 20px;
  }

  .signup-as-button {
    width: 100%;
    margin-top: 10px;
  }

  input {
    padding-left: 25px;
    width: 100%;
    height: 35px;
    background-color: #fff;
    border: 1px solid #DBDBEA;
    margin-bottom: 5px;
    &:disabled {
      border: 1px solid #fff;
      opacity: 1;
    }
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 3px;

    &[id="username-input"] {
      color: #A5A5A5;
    }

    &[type="email"] {
      background-image: url(../img/coach-setup-email-icon.png);
    }

    &[type="password"] {
      background-image: url(../img/coach-setup-password-icon.png);

      &:-moz-placeholder { /* Firefox 18- */
        color: #000;
      }

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #000;
      }

      &::-moz-placeholder { /* Firefox 19+ */
        color: #000;
      }

      &:-ms-input-placeholder { /* IE 10+ */
       color: #000;
      }
    }
  }

  .edit-active {
    background-color: #f7f7fd;
    border: 1px solid #DBDBEA;
  }

  .edit-inactive {
    background-color: #fff;
    border: 1px solid #fff;
  }

  .error-message {
    width: 100%;
  }
}
