.topya-side-block {
  height: auto;
  background-color: white;
  &.bordered {
    border: 1px solid  #dbdbea;
  }
  .topya-side-block-header {
    text-align: center;
    padding: 6px 0;
    background: #E4E4EF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    .block-title:hover {
      text-decoration: underline;
      cursor:pointer;
    }
  }
  .topya-side-block-content {
    background: #f0f0f0;
  }
  .glyphicon-plus {
    margin-right: 10px;
  }
  .white-plus {
    color: #fff;
  }
  .blue-plus {
    color: #498FE1;
  }

  @media (max-width: 999px) {
    .topya-side-block-header {
      font-size: 14px;
    }

    .topya-side-block-content h4 {
      font-size: 13px;
    }

    .topya-side-block-content {
      font-size: 12px;
    }
  }
}
