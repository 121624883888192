.contest-sponsors-and-leaderboard {
  .primary-sponsor {
    text-align: center;
    margin-bottom: 30px;
    span {
      color: #5e5e5e;
      display: block;
      margin-bottom: 40px;
    }
    img {
      max-width: 100%;
      max-height: 100px;
    }
  }

  iframe {
    width: 100%;
  }

  .sponsors {
    hr {
      border-top: 2px solid #e5e5e5;
      margin: 30px 0;
    }
    span {
      color: #5e5e5e;
      display: block;
      margin-bottom: 40px;
      text-align: center;
    }
    .sponsor-holder {
      height: 100px;
      &:nth-child(odd) {
        text-align: right;
      }
      &.centered {
        text-align: center;
      }
      img {
        margin-top: 50px;
        transform: translateY(-50%);
        max-width: 100%;
        max-height: 100px;
      }
    }
  }
}
