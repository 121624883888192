fundraiser-basics-modal > div {
  background-color: #ebf3fc;
  border-radius: 6px;
  padding-top: 30px;
  padding-bottom: 40px;

  input.form-control,
  textarea.form-control {
    border-radius: 4px;
    &.ng-invalid,
    &.invalid {
      border-color: red;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 0, 0, .6);
    }
  }

  textarea {
    resize: none;
    height: 100px;
  }

  button.btn {
    border-radius: 4px;

    &.btn-primary.cancel {
      background-color: #fff;
      color: dodgerblue;
    }
  }
}