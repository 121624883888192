typeahead-input > div {
  max-width: 400px;
  position: relative;

  & > span.label {
    color: #333;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 8px;
    padding: 0;
    display: inline-block;
    text-align: left;
    width: auto;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-bottom: 2.5vw;
      display: block;
    }
  }

  & > span.helper-label {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 8px;
    margin-bottom: 8px;
    text-align: left;
    width: auto;
    padding: 0;

    &.mobile {
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-top: -2vw;
      margin-bottom: 2.5vw;
      margin-left: 2.5vw;
      display: block;
      margin-left: 0;
    }
  }

  & > input.form-control {
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    background-color: #FFF;
    height: 40px;

    &.mobile {
      font-size: 5.6vw;
      line-height: 7.8vw;
      margin-bottom: 2.5vw;
      height: 10vw;
      border-width :2px;
      border-radius: .6vw;
    }
  }

  & > div.results {
    width: 100%;
    display: block;
    background: #fff;
    position: absolute;
    left: 0;
    transform: translateY(-22px);
    border: 1px solid #e5e5e5;
    z-index: 2000;
    max-height: 240px;
    overflow-y: auto;
    -webkit-box-shadow: 3px 3px 5px 0px rgba(229,229,229,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(229,229,229,1);
    box-shadow: 3px 3px 5px 0px rgba(229,229,229,1);

    & > span.result {
      display: block;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
      }

      &:hover, &.focused {
        color: #fff;
        background-color: #306DB2;
      }
    }
  }
}