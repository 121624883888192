/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  less dependencies when defined in the bower.json of your dependencies
 */
/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap/less/variables.less
 */
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.thumbnail {
  height: 200px;
}
.thumbnail img.pull-right {
  width: 50px;
}
/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your less files automatically
 */
.animate-if {
  padding: 0;
}
.animate-if.ng-enter,
.animate-if.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}
.animate-if.ng-enter[id="registerErr"] {
  transition: none !important;
}
.animate-if.ng-leave[id="noanim"] {
  transition: none !important;
}
.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity: 0;
}
.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity: 1;
}
[id="registerErr"] .ng-hide:not(.ng-hide-animate)[id="dialog"] {
  display: initial !important;
  visibility: hidden;
  height: 20px;
}
.animate-show {
  line-height: 20px;
  opacity: 1;
  /*padding: 10px;*/
  color: #8C8C8C;
}
.animate-show.ng-hide-add,
.animate-show.ng-hide-remove {
  transition: all linear 0.1s;
  height: 20px;
}
.animate-show.ng-hide-remove[id="errorteam"],
.animate-show.ng-hide-remove[id="imgTooBig"] {
  transition: none !important;
}
.animate-show.ng-hide {
  line-height: 0;
  opacity: 0;
  padding: 0 ;
}
.topya-side-block {
  height: auto;
  background-color: white;
}
.topya-side-block.bordered {
  border: 1px solid #dbdbea;
}
.topya-side-block .topya-side-block-header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.topya-side-block .topya-side-block-header .block-title:hover {
  text-decoration: underline;
  cursor: pointer;
}
.topya-side-block .topya-side-block-content {
  background: #f0f0f0;
}
.topya-side-block .glyphicon-plus {
  margin-right: 10px;
}
.topya-side-block .white-plus {
  color: #fff;
}
.topya-side-block .blue-plus {
  color: #498FE1;
}
@media (max-width: 999px) {
  .topya-side-block .topya-side-block-header {
    font-size: 14px;
  }
  .topya-side-block .topya-side-block-content h4 {
    font-size: 13px;
  }
  .topya-side-block .topya-side-block-content {
    font-size: 12px;
  }
}
.card {
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}
input.cmn-toggle-round + label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #CDCDCF;
  border-radius: 60px;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #CDCDCF;
  border-radius: 60px;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 70%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: margin 0.4s;
  transition: margin 0.4s;
  margin-top: 2px;
}
input.cmn-toggle-round:checked + label:before {
  background-color: #498FE1;
}
input.cmn-toggle-round:checked + label:after {
  margin-left: 30px;
}
.nav > li > a:hover,
.nav > li > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background: #fff;
}
a {
  text-align: center;
}
.dropdown {
  border: none ;
  border-radius: 4px ;
  width: 100% ;
}
.dropdown-menu > li {
  color: black  ;
}
ul.dropdown-menu {
  border-radius: 4px ;
  box-shadow: none ;
}
ul.dropdown-menu:before {
  content: "";
  border-bottom: 10px solid #fff;
  border-right: 10px solid transparent ;
  border-left: 10px solid transparent ;
  position: absolute ;
  top: -10px;
  right: 16px ;
  z-index: 10 ;
}
ul.dropdown-menu:after {
  content: "";
  border-bottom: 12px solid #ccc;
  border-right: 12px solid transparent ;
  border-left: 12px solid transparent ;
  position: absolute ;
  top: -12px;
  right: 14px ;
  z-index: 9 ;
}
.dropdown-menu li {
  padding-top: 5px ;
  padding-bottom: 5px ;
  text-align: center;
  border-top: solid 1px whitesmoke;
}
.dropdown-menu li:first-child {
  border-top: none ;
}
.dropdown a {
  color: black ;
}
@font-face {
  font-family: 'AvenirNextLTPro Bold';
  src: url(../fonts/avenir-next-bold.ttf);
}
@font-face {
  font-family: 'AvenirNextLTPro Regular';
  src: url(../fonts/avenir-next-regular.ttf);
}
input[type=text],
input[type=password],
input[type=email],
input[type=number],
input[type=tel] {
  border: 1px solid #ccc;
}
textarea {
  border: 1px solid #9B9BA3;
}
button.submit {
  border: 1px solid #498FE1;
  padding: 5px 15px;
  width: 20%;
  height: 30px;
  background: #498FE1;
  color: #fff;
  font-size: 12px;
}
button.submit:disabled {
  opacity: 0.4;
}
select {
  padding: 0px 15px 0 0;
  border: 0;
  background: url('../img/arrow_down.png') no-repeat 100% 50%;
  appearance: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
}
.active .btn-success {
  border: 0;
  border-radius: 5px;
  width: 100px;
  height: 35px;
  padding: 5px;
  background: #7DD220;
  color: #fff;
  font-size: 14px;
}
.btn:disabled {
  cursor: default;
}
.topya-main-page-header {
  background: #1B1C2C;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
  margin-top: 20px;
  width: 100%;
}
.topya-main-page-content {
  border: 1px solid #DBDBEA;
  background-color: #fff;
  padding: 15px;
}
.topya-side-block-header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.big-button {
  height: 60px;
  font-weight: bold;
  text-transform: uppercase;
}
.btn {
  border-radius: 2px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12px 16px;
  border: none;
}
.btn.btn-primary {
  border: none;
  background-color: #4A90E2;
}
.btn.btn-primary:hover,
.btn.btn-primary:active {
  background-color: #306DB2;
  border-color: #498FE1;
}
.btn.btn-primary:disabled {
  background-color: #ccc;
  border-color: #ccc;
}
.btn.btn-primary.cancel {
  border: 2px solid #4A90E2;
  border-radius: 2px;
  background-color: #FFFFFF;
  padding: 10px 14px;
  color: #4A90E2;
}
.btn.btn-success {
  background-color: #6DD400;
}
.btn.btn-success:hover,
.btn.btn-success:active {
  background-color: #3AB000;
}
.btn.btn-success:disabled {
  background-color: #ccc;
  border-color: #ccc;
}
.btn.btn-danger {
  background-color: #E10048;
}
.btn.btn-danger:hover,
.btn.btn-danger:active {
  background-color: #B00028;
  border-color: #E10048;
}
.btn.btn-danger:disabled {
  background-color: #ccc;
  border-color: #ccc;
}
.btn.btn-primary.new-btn-primary {
  min-width: 250px;
  font-weight: bold;
  padding: 10px;
  border-radius: 6px;
  border-width: 2px;
  border-color: #2b8dff;
  font-size: 15px;
  letter-spacing: 0.5px;
  width: auto;
  background: linear-gradient(#85bdff 0%, dodgerblue 10%);
}
.btn.btn-primary.new-btn-primary.cancel {
  background: #fff;
  color: dodgerblue;
  border-color: #18a2ff;
}
input.form-control.new-form-control {
  border-radius: 6px;
  border-color: #969696;
  background: linear-gradient(#dcdbdb 0%, #fff 6%);
}
.btn.btn-default.new-btn-default {
  min-width: 250px;
  border-radius: 6px;
  background: linear-gradient(#fff 0%, #bbb 10%);
  border-color: #cecece;
  font-weight: bold;
  color: #5d5c5c;
  font-size: 15px;
  letter-spacing: 0.5px;
  border-width: 2px;
  padding: 10px;
  width: auto;
}
.breadcrumb {
  padding: 10px 15px;
  margin: 5px 0;
  list-style: none;
  background-color: transparent;
  text-transform: uppercase;
  color: #9D9D9D;
  font-size: 12px;
  border-radius: 0;
}
.breadcrumb a {
  color: #9D9D9D;
}
.breadcrumb li,
.breadcrumb span {
  cursor: pointer;
}
.breadcrumb li.active,
.breadcrumb span.active {
  cursor: default;
}
.topya-modal-window {
  border: 1px solid #DAD5D5;
  background-color: #F7F7FD;
  padding-bottom: 10px;
}
.modal {
  padding: 0;
}
button.outline {
  border: 1px solid #498FE1;
  padding: 5px 15px;
  margin-top: 10px !important;
  height: 30px;
  background: none;
  color: #498FE1;
  font-size: 12px;
  border-radius: 0;
}
body.hide-loader-bar #loading-bar,
body.hide-loader-bar #loading-bar-spinner {
  display: none;
}
body.hide-zendesk-help iframe.zEWidget-launcher {
  display: none;
}
.overflow-visible {
  overflow: visible !important;
}
.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
@media (max-width: 767px) {
  .no-padding-sm {
    padding: 0 !important;
  }
}
.textarea-noresize {
  resize: none;
}
.cursor-move {
  cursor: move;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
[class*="inner-scroll"] {
  overflow-y: auto;
  overflow-x: hidden;
}
.inner-scroll-100 {
  max-height: 100px;
}
.inner-scroll-150 {
  max-height: 150px;
}
.inner-scroll-200 {
  max-height: 200px;
}
.inner-scroll-250 {
  max-height: 250px;
}
.inner-scroll-300 {
  max-height: 300px;
}
.inner-scroll-400 {
  max-height: 400px;
}
.inner-scroll-550 {
  max-height: 550px;
}
.relative {
  position: relative;
}
.center-vertical {
  display: flex;
  align-items: center;
}
.image-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.absolute-center-x,
.round-image-holder .absolute-center-x {
  left: 50%;
  transform: translate(-50%, 0);
}
.absolute-center-y,
.round-image-holder .absolute-center-y {
  top: 50%;
  transform: translateY(-50%);
}
.horizontal-center {
  margin-left: 50%;
  transform: translateX(-50%) !important;
}
.float-left {
  float: left;
}
.vertical-align-middle {
  vertical-align: middle;
}
.button-distance {
  padding-top: 0.5%;
  padding-bottom: 2%;
}
.button-distance-top {
  margin-top: 2%;
}
.button-distance-top-register {
  margin-top: 1%;
}
.no-left-padding {
  padding-left: 0 !important;
}
.no-right-padding {
  padding-right: 0 !important;
}
.padding-right-10 {
  padding-right: 10px;
}
.disabled-item {
  opacity: 0.4;
  cursor: default;
}
.normal-item {
  opacity: 1;
  cursor: pointer;
}
.transparent {
  opacity: 0;
  cursor: default;
}
.padding-bottom-80 {
  padding-bottom: 80px;
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.float-right {
  float: right;
}
.no-top-border {
  border-top: none !important;
}
.no-bottom-border {
  border-bottom: none !important;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-35 {
  margin-top: 35px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-right-10 {
  margin-right: 10px;
}
.inline-table {
  display: inline-table;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.no-disabled-opacity:disabled {
  opacity: 1;
}
.top-200 {
  top: 200px;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left !important;
}
.text-align-right {
  text-align: right;
}
.vertical-align.text-top {
  vertical-align: text-top;
}
.form-control:disabled.disabled-text-input {
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: default;
}
.full-width {
  width: 100%;
}
@media (max-width: 1199px) {
  .dropdown-menu.rightmost-dropdown {
    left: auto;
    right: 0;
  }
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-100 {
  padding-top: 100px;
}
.rounded-border-4 {
  border-radius: 4px !important;
  overflow: hidden;
}
.rounded-border-8 {
  border-radius: 8px !important;
  overflow: hidden;
}
.row-margin-30 {
  margin-right: -30px;
  margin-left: -30px;
}
.uppercase {
  text-transform: uppercase;
}
.checkbox-group {
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}
.checkbox-group .checkbox-holder {
  position: absolute;
  height: 34px;
  width: 34px;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 5px;
}
.checkbox-group .checkbox-holder.green-background {
  background-color: #5fd842;
}
.checkbox-group .checkbox-holder.gray-background {
  background-color: #eee;
}
.checkbox-group label {
  margin-left: 34px;
  width: calc(100% - 60px);
}
.horizontal-scroll-holder {
  min-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.horizontal-scroll-holder::-webkit-scrollbar {
  height: 5px;
}
.capitalize {
  text-transform: capitalize;
}
.font-bold {
  font-family: 'AvenirNextLTPro Bold';
}
.modal-auto-width .modal-lg,
.modal-auto-width .modal-md,
.modal-auto-width .modal-sm {
  width: 100%;
}
html {
  margin: 0;
  padding: 0;
  background-size: cover;
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
body {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  height: 100%;
}
body.modal-open {
  overflow: auto;
}
body:not(.show-addthis) .addthis-smartlayers {
  display: none;
}
@media (min-width: 980px) {
  body.show-addthis #at-share-dock {
    display: none;
  }
}
.dashboard-wrapper {
  background-color: #f7f7fd;
  min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
input,
input.form-control {
  border-radius: 0;
}
.menu {
  vertical-align: middle;
  background-color: #1B1C2C;
}
*:focus {
  outline: none;
}
*.no-border {
  border: none;
}
.red_img {
  border: 2px solid #CF011A;
}
.table > thead > tr > th,
.table > tbody > tr > td {
  border-top: 0;
  border-bottom: 0;
}
.table .head {
  color: #9E9E9E;
  font-weight: normal;
}
.progress {
  background-image: -webkit-linear-gradient(top, #DDDDDD 0, #DDDDDD 100%);
  background-image: -o-linear-gradient(top, #DDDDDD 0, #DDDDDD 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#DDDDDD), to(#DDDDDD));
  background-image: linear-gradient(to bottom, #DDDDDD 0, #DDDDDD 100%);
}
.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar-success {
  background-image: -webkit-linear-gradient(top, #7DD220 0, #7DD220 100%);
  background-image: -o-linear-gradient(top, #7DD220 0, #7DD220 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7DD220), to(#7DD220));
  background-image: linear-gradient(to bottom, #7DD220 0, #7DD220 100%);
}
.profile_info,
.list-inline,
.profile_info_links,
.profile_info_links span,
.text-align-center {
  text-align: center;
}
.container {
  padding-bottom: 3%;
}
.coach-bg .menu {
  min-height: 65px;
}
.coach-content-bg {
  background: rgba(4, 6, 23, 0.9);
  padding-bottom: 50px;
  margin-top: 3%;
  margin-bottom: 3%;
  text-align: center;
}
.coach-content-bg h3.white {
  color: #fff;
}
.paragraph {
  color: #8C8C8C;
}
.view-content {
  padding-top: 2%;
}
.button-distance {
  padding-top: 0.5%;
  padding-bottom: 2%;
}
.button-distance-top {
  margin-top: 2%;
}
.button-distance-top-register {
  margin-top: 1%;
}
.button-distance-top-createTeam {
  margin-top: 2.5%;
}
.button-distance-bottom {
  padding-bottom: 0;
}
.display-profile-image {
  width: 150px;
  height: 150px;
}
.orange {
  color: #F4A522;
}
.red {
  color: #B53B3B;
}
.popup-window {
  width: 600px;
  height: 250px;
  background-color: #fff;
  margin-left: 23%;
  z-index: 200;
  position: absolute;
}
.x-icon {
  width: 50%;
}
.hidden {
  visibility: hidden;
}
.custom {
  border-radius: 0 !important;
  min-width: 30% !important;
  border-width: 0 !important;
  height: 30px !important;
  min-width: 100px !important;
}
.signup {
  border-radius: 0 !important;
}
.config_modal.open {
  display: block;
}
.add-kid-box {
  border: 1px solid #ccc;
  width: 100%;
  padding: 25px;
}
.add-kid-box div {
  width: 47%;
}
.add-kid-box div input {
  width: 100%;
  margin-bottom: 10px;
}
.ad-banner-space {
  min-height: 150px;
}
.coach-header {
  margin-top: 20px;
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.leftBlockWrapper {
  margin-top: 20px !important;
  padding-left: 0;
}
.dropdown {
  width: 100%;
}
.second-dropdown {
  overflow-y: auto !important;
}
.gender-dropdown {
  min-height: 10px !important;
  height: auto !important;
}
.update-country .dropdown-menu {
  height: 150px !important;
  overflow-y: auto !important;
}
.dashboard-wrapper .menu,
.forgot-password .menu {
  background-size: cover !important;
  min-height: 100% !important;
  background: #003;
  height: 80px;
  padding-top: 10px;
}
.no-left-padding {
  padding-left: 0 !important;
}
.no-right-padding {
  padding-right: 0 !important;
}
.disabled-item {
  opacity: 0.4;
  cursor: default;
}
.normal-item {
  opacity: 1;
  cursor: pointer;
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.float-right {
  float: right;
}
.no-top-border {
  border-top: none !important;
}
.no-bottom-border {
  border-bottom: none !important;
}
videogular vg-overlay-play .overlayPlayContainer,
[videogular] vg-overlay-play .overlayPlayContainer {
  font-size: 60px;
}
.green_img {
  width: 58px;
  height: 58px;
}
@media (min-width: 1441px) and (max-width: 1920px) {
  .green_img {
    width: 54px;
    height: 54px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .green_img {
    width: 38px;
    height: 38px;
  }
}
.round-image-holder {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}
.round-image-holder::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.round-image-holder img {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
ul[dnd-list] {
  padding-left: 0;
  list-style-type: none;
}
ul[dnd-list],
ul[dnd-list] > li {
  position: relative;
}
ul[dnd-list] .dndDraggingSource {
  display: none;
}
ul[dnd-list] .dndPlaceholder {
  display: block;
  background-color: #ddd;
  min-height: 72px;
}
.modal-dialog.modal-sm {
  min-width: 350px !important;
  width: 20% !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
}
::-webkit-scrollbar {
  width: 6px;
  padding-right: 20px !important;
  margin: auto !important;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: lightgray;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: lightgray;
}
@media (min-width: 1280px) {
  ::-webkit-scrollbar {
    width: 10px;
  }
}
videogular vg-overlay-play,
[videogular] vg-overlay-play {
  left: 0;
}
.view-holder {
  width: 100%;
  min-height: 100vh;
  background-color: #F3F3F3;
  overflow-x: hidden;
  overflow-y: auto;
}
.view-holder .main-content-holder {
  margin-left: 232px;
  margin-top: 72px;
  padding: 24px;
}
.view-holder.mobile .main-content-holder {
  margin-left: 0;
  margin-top: 22.5vw;
  padding: 7vw;
}
.modal-dialog {
  position: absolute;
}
.modal-content {
  border-radius: 0px;
  border: none;
}
.modal-content .close {
  margin-top: 5px;
  margin-right: 15px;
}
.modal-content .close.close-disabled {
  cursor: default;
  opacity: 0.1;
}
.modal-dialog .error-message {
  margin-top: 10px;
}
.modal-open .modal {
  overflow-y: hidden;
}
@media (min-width: 700px) and (max-width: 1480px) {
  .modal button {
    font-size: 11px;
  }
}
.app-modal-window .modal-dialog {
  height: auto;
  margin: 0;
  border-radius: 0;
}
.app-modal-window.modal-smaller .modal-dialog {
  width: 35%;
  left: 35.5%;
}
@media (max-width: 1199px) {
  .app-modal-window.modal-smaller .modal-dialog {
    width: 55%;
    left: 26.5%;
  }
}
@media (max-width: 1024px) {
  .app-modal-window .modal-dialog,
  .app-modal-window .modal-smaller .modal-dialog {
    width: 73.5%;
    left: 15.5%;
  }
}
@media (min-width: 1025px) and (max-width: 1281px) {
  .app-modal-window .modal-dialog {
    width: 73.5%;
    left: 15.5%;
    font-size: 16px;
  }
}
@media (min-width: 1282px) and (max-width: 1441px) {
  .app-modal-window .modal-dialog {
    width: 73.5%;
    left: 15.5%;
  }
}
@media (min-width: 1442px) {
  .app-modal-window .modal-dialog {
    height: auto;
    margin: 0;
    width: 63%;
    left: 20.4%;
  }
}
.manager-modals.app-modal-window {
  top: 10%;
}
.manager-modals.app-modal-window .modal-dialog {
  border-radius: 10px;
  overflow: hidden;
}
.manager-modals.app-modal-window.overflow-visible .modal-dialog {
  overflow: visible;
}
.manager-modals .modal-content {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
}
.manager-modals:not(.overflow-visible) .modal-content {
  overflow: hidden;
}
@media (min-width: 1441px) and (max-width: 1920px) {
  .container {
    width: 78vw;
    margin: 0 auto;
  }
  input.search {
    width: 80%;
    height: 4vh;
    font-weight: normal;
    border-width: 1px;
    padding-left: 1.5vw;
  }
  .dl-horizontal dt {
    width: 35%;
    margin-right: 15px;
  }
  .dl-horizontal dd {
    margin-left: 45px;
    font-size: 14px;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .container {
    width: 90vw;
    margin: 0 auto;
  }
  input.search {
    width: 80%;
    height: 4.5vh;
    font-weight: normal;
    border-width: 1px;
  }
}
@media (min-width: 1025px) and (max-width: 1279px) {
  .container {
    width: 95vw !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 98%;
  }
  .logo {
    width: 120%;
  }
  .dl-horizontal dt {
    width: 35%;
    margin-right: 0px;
  }
  .dl-horizontal dd {
    margin-left: 45px;
    font-size: 12px;
  }
}
.activityFeed .padding-top {
  padding-top: 15%;
}
.activityFeed .topya-main-page-content {
  margin-top: 20px;
}
.activityFeed .activity-feed-featured-username {
  margin-bottom: 10px;
  display: block;
  margin-top: 5px;
}
.activityFeed .counts {
  color: #9e9e9e;
  padding-left: 20px;
  border-left: #F7F7FD 1px solid;
}
.activityFeed .feed-filters {
  max-height: 200px;
  overflow-y: auto;
}
.activityFeed .feed-filters li {
  margin-top: 10px;
}
.activityFeed .popover-content ul {
  list-style-type: none;
  padding: 0;
  font-family: 'AvenirNextLTPro Regular', sans-serif;
}
.activityFeed .popover-content li:hover {
  text-decoration: underline;
}
.activityFeed .filter {
  text-align: center;
}
.activityFeed .filter span {
  cursor: pointer;
}
.activityFeed .empty-feed-message {
  margin-top: 10px;
}
.activityFeed .post_icons {
  margin: 15px 0;
}
.activityFeed .post_icons span {
  margin-right: 15px;
}
.activityFeed .post_icons span:last-child {
  margin-right: 0px;
  float: right;
}
.activityFeed .name-date-wrapper {
  margin-bottom: 10px;
}
.activityFeed .badge-img {
  width: 150%;
}
.activityFeed .activity-feed-featured-date {
  color: #aaaaaa;
}
.activityFeed .activity-feed-challenge-highlight-description {
  font-weight: bold;
  padding-left: 0px;
  color: #9e9e9e;
  margin-bottom: 10px;
}
.activityFeed .activity-feed-challenge-heading {
  font-weight: normal;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.activityFeed .add-high-five {
  cursor: pointer;
}
.activityFeed .add-high-five:hover,
.activityFeed .add-high-five:focus {
  color: #4a90e2;
}
.activityFeed .high-fived {
  color: #4a90e2;
}
.activityFeed .activity-feed-video-wrapper {
  border-top: 1px solid #f7f7fd;
  padding-top: 15px;
}
.activityFeed .username-clickable:hover {
  text-decoration: underline;
}
.activityFeed .activity-feed-challenge-heading {
  font-weight: normal;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.activityFeed .activity-feed-challenge-vs {
  font-weight: lighter;
  margin-top: 100px;
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .activityFeed .activity-feed-challenge-vs {
    margin-top: 130px;
  }
}
.activityFeed .inner-scroll-holder {
  position: relative;
  width: calc(100% + 6px);
  padding: 0 15px 15px 15px;
  margin-top: 15px;
  display: inline-block;
}
.activityFeed .inner-scroll-holder .topya-main-page-content:first-of-type {
  margin-top: 0;
}
.activityFeed .inner-scroll-border {
  margin-top: 0;
  border-top-color: #dbdbea;
}
.activityFeed.mobile .add-high-five img {
  height: 15px;
}
.addKid {
  padding-left: 0;
}
.addKid input {
  font-size: 15px;
  margin-bottom: 7%;
  margin-top: 3%;
  height: 34px;
  width: 100%;
}
.addKid input.invite-code {
  margin-top: 0;
  border-color: dodgerblue;
}
.addKid .content {
  background-color: #F7F7FD;
  border-color: #D2D2D6;
  border-style: solid;
  border-width: 1px;
}
.addKid .column {
  margin-top: 2%;
  margin-bottom: 2%;
}
.addKid .col-left {
  padding-right: 30px;
}
.addKid .col-right {
  padding-left: 30px;
}
.addKid .uib-datepicker-popup.dropdown-menu {
  padding: 5px;
}
.addKid .btn {
  width: 100%;
}
.addKid .btn.cancel {
  border: 1px solid dodgerblue;
}
.addKid .errorMsg {
  margin: 0;
  border-radius: 0;
  height: 60px;
  text-align: center;
  position: absolute;
  z-index: 2;
  width: 100%;
}
.addKid .danger {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  color: #b94a48;
}
.addKid .or {
  position: absolute;
  padding-top: 5px;
  width: 60px;
  text-align: center;
  right: -30px;
  top: 0;
}
.addKid .dropdown .disabled {
  opacity: 0.4;
}
.addKid .nav {
  border: 1px solid #ECECFB;
  height: 34px;
}
.addKid .navbar-nav > li > a {
  padding: 5px 15px;
  height: 32px;
  cursor: pointer;
}
.addKid .navbar-nav > li > a img {
  margin-top: -2px;
}
.addKid .gender-wrapper {
  margin-top: 3%;
}
.addKid .gender-wrapper .danger {
  position: relative;
  font-size: 10px;
}
.analytics-iframe {
  margin-top: 20px;
  border: none;
}
.analytics-header {
  margin-top: 20px;
}
.app-store-link {
  display: block;
}
.app-store-holder {
  margin-top: 10px;
}
.appStoreBoxes {
  margin-top: 20px;
}
.appStoreBoxes .download-topya-app-today-label {
  color: #A7B1C1;
}
.freestyle .challenge-description {
  margin-top: 15px;
}
.freestyle .challenge-description .name-header {
  color: #9f9f9f;
}
.freestyle .challenge-description .main-challenge-name {
  margin-top: 5px;
  word-wrap: break-word;
}
.freestyle .challenge-description .members-count {
  margin-top: 0;
}
.freestyle .details-header-team {
  margin-top: 2%;
  word-wrap: break-word;
}
.freestyle .details-back-arrow {
  margin-top: 50%;
}
.freestyle .player-stats img {
  display: inline;
  width: 20px;
  height: 20px;
}
.freestyle .player-stats h5 {
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
}
.freestyle .player-info .country-details {
  margin-top: 20px;
}
.freestyle .player-info h6 {
  margin-top: 25px;
}
@media (max-width: 1023px) {
  .freestyle .player-info h6 {
    font-size: 8px;
  }
}
.freestyle .player-info .flag {
  width: 20%;
  margin-left: 5%;
}
.freestyle .joined-banner {
  background-color: #000;
  padding: 10px;
  margin-bottom: 20px;
}
.freestyle .joined-banner span {
  color: #93CB52;
}
.freestyle .joined-banner h4 {
  display: inline;
  margin-left: 10px;
}
.freestyle .time-banner {
  background-color: #93cb52;
  color: #fff;
  padding: 15px;
}
.freestyle .time-banner .stopwatch {
  width: 100%;
}
.freestyle .time-banner h2 {
  margin-top: 2%;
  display: inline-block;
}
.freestyle .time-banner h4 {
  display: inline-block;
  margin-left: 5px;
}
.freestyle .gray-text {
  color: #9f9f9f;
  margin-top: 30px;
}
.freestyle .joined-challenge-name {
  margin-bottom: 30px;
}
.freestyle .challenger-info {
  margin-top: 25%;
}
.freestyle .challenger-info .team-name {
  margin-top: 3%;
}
.freestyle .challenger-info .lower {
  margin-top: 3%;
}
.freestyle .challenger-info .lower h6 {
  color: #9f9f9f;
}
.freestyle .joined-lower {
  margin-bottom: 7%;
}
.freestyle .submit-video {
  margin-top: 60%;
  margin-bottom: 3%;
}
.freestyle .my-freestyle-filter {
  text-align: center;
}
.freestyle ol li {
  font-size: 10px;
}
.freestyle .page-headers {
  padding: 0 15px;
}
.freestyle .filter {
  cursor: pointer;
}
.freestyle .filter:hover {
  text-decoration: underline;
}
.freestyle .filter h5 {
  display: inline;
}
.freestyle .filter img {
  margin-left: 5px;
}
.freestyle .challenge-name {
  color: #9f9f9f;
  margin-bottom: 10px;
}
.freestyle .team-name {
  margin-top: 10%;
}
.freestyle .arrow-right {
  margin-top: 70px;
  float: right;
}
.freestyle .video-parent {
  width: 100%;
  padding-bottom: 56.2%;
  height: 0;
  position: relative;
  background-color: #30303F;
}
.freestyle ul {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  list-style: none;
  padding: 0;
}
.freestyle li:hover {
  background-color: #f7f7fd;
  cursor: pointer;
}
.freestyle .active-tab {
  color: dodgerblue;
}
.freestyle .active-dropdown {
  color: #000;
}
.freestyle .inactive-tab {
  color: #000;
}
.freestyle .img-holder {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  height: 0;
}
.freestyle .img-holder img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
}
.freestyle .stats {
  border: 1px solid;
  border-right: none;
  padding: 2%;
  margin-top: 2%;
}
.freestyle .wins {
  border-color: #93CB53;
  color: #93CB53;
}
.freestyle .draws {
  border-color: #F7A724;
  color: #F7A724;
}
.freestyle .losses {
  border-color: red;
  color: red;
  border-right: 1px solid red;
}
.freestyle .btn {
  width: 100%;
}
.freestyle .error-message {
  width: 100%;
  margin-top: 15px;
}
.freestyle .center-header {
  text-align: center;
}
@media (max-width: 1023px) {
  .freestyle .stats {
    margin-top: 0;
  }
  .freestyle .stats h6 {
    font-size: 7px;
  }
  .freestyle .challenge-name {
    margin-bottom: 3px;
  }
  .freestyle .page-headers h4,
  .freestyle .filter h5 {
    font-size: 10px;
  }
}
.profile .content {
  border: 1px solid #DBDBEA;
  padding: 5px;
  background-color: #fff;
}
.profile .content-inner {
  padding-top: 10px;
  min-height: 310px;
}
.profile .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-top: 10px;
}
.profile .x-icon {
  min-height: 22px;
}
.profile .left-panel {
  text-align: center;
}
.profile .left-panel a {
  float: left;
}
.profile .left-panel button {
  width: 100%;
}
.profile .right-panel {
  text-align: center;
}
.profile .right-panel button {
  width: 50%;
}
.profile .settings {
  margin-top: 20px;
}
.profile .signup-as-button {
  width: 100%;
  margin-top: 10px;
}
.profile input {
  padding-left: 25px;
  width: 100%;
  height: 35px;
  background-color: #fff;
  border: 1px solid #DBDBEA;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 3px;
}
.profile input:disabled {
  border: 1px solid #fff;
  opacity: 1;
}
.profile input[id="username-input"] {
  color: #A5A5A5;
}
.profile input[type="email"] {
  background-image: url(../img/coach-setup-email-icon.png);
}
.profile input[type="password"] {
  background-image: url(../img/coach-setup-password-icon.png);
}
.profile input[type="password"]:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}
.profile input[type="password"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
}
.profile input[type="password"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}
.profile input[type="password"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
}
.profile .edit-active {
  background-color: #f7f7fd;
  border: 1px solid #DBDBEA;
}
.profile .edit-inactive {
  background-color: #fff;
  border: 1px solid #fff;
}
.profile .error-message {
  width: 100%;
}
.recommend .recommend-text-wrapper {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 20px 0;
  background-color: #F7F7FD;
}
.recommend .recommend-text,
.recommend .my-skills-text {
  color: black;
  font-size: small;
  font-weight: bold;
}
.recommend .page-header-wrapper {
  margin: 10px 0;
  font-weight: bold;
}
.recommend .selectedTab {
  color: #498FE1;
}
.recommend .dropdown {
  width: 100px;
}
.recommend .dropdown a {
  text-align: left;
  padding-left: 0px;
}
.recommend .dropdown-menu {
  min-width: 100px;
}
.recommend .dropdown-menu li {
  list-style-type: none;
}
.recommend .recommend-path-text-wrapper {
  background-color: #E7EDF8;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.recommend .arrow {
  position: relative;
  top: 15px;
  width: 15px;
}
.recommend .pathName {
  font-size: medium;
  color: black;
  font-weight: bold;
}
.recommend .skillsCount {
  color: #498FE1;
}
.recommend .recommend-path-text-wrapper .img-wrapper {
  text-align: center;
  position: relative;
}
.recommend .recommend-path-text-wrapper .img-wrapper img {
  max-height: 58px;
  max-width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: auto;
  transform: translate(-50%, -50%);
}
.recommend .pathName .gray {
  color: lightgray;
  font-size: smaller;
}
.column-head-component,
.skills-academy .column-head-component {
  padding: 15px;
}
.column-head-component.scroll-list,
.skills-academy .column-head-component.scroll-list {
  max-height: 450px;
  overflow-y: auto;
}
.column-head-component ul,
.skills-academy .column-head-component ul {
  list-style-type: none;
  padding: 0;
}
.column-head-component ul li,
.skills-academy .column-head-component ul li {
  color: #2c2c2c;
  padding: 15px 0 20px 0;
  cursor: pointer;
  text-align: left;
}
.column-head-component ul li:hover,
.skills-academy .column-head-component ul li:hover {
  background-color: #f7f7fd;
}
.column-head-component ul li .category-child,
.skills-academy .column-head-component ul li .category-child {
  margin-left: 15px;
}
.column-head-component ul li .item-label,
.skills-academy .column-head-component ul li .item-label {
  width: 80%;
  display: inline-block;
  line-height: 1.5;
}
.column-head-component ul li .item-label.full-width,
.skills-academy .column-head-component ul li .item-label.full-width {
  width: 100%;
}
.column-head-component .spinner-holder,
.skills-academy .column-head-component .spinner-holder {
  height: 30px;
}
.column-head-component .spinner-holder spinner > div,
.skills-academy .column-head-component .spinner-holder spinner > div {
  margin-left: calc(50% - 10px);
}
.column-head-component input,
.skills-academy .column-head-component input {
  width: 100%;
  color: #2c2c2c;
  margin-top: 15px;
}
.column-head-component input,
.skills-academy .column-head-component input,
.column-head-component .btn,
.skills-academy .column-head-component .btn {
  margin-bottom: 15px;
}
.column-head-component a,
.skills-academy .column-head-component a {
  font-weight: normal;
}
.column-head-component h6,
.skills-academy .column-head-component h6 {
  margin: auto;
  color: #2c2c2c;
  font-weight: normal;
}
.column-head-component .selected-item-icon,
.skills-academy .column-head-component .selected-item-icon {
  width: 16px;
  margin-right: 10px;
}
.contests .row.padded-row {
  padding-left: 15px;
  padding-right: 15px;
}
.contests .rules-link a {
  color: dodgerblue;
}
.contests.contest-landing-page {
  padding-bottom: 20%;
}
.contests.contest-landing-page .ribbon-holder {
  position: absolute;
  width: 100%;
  top: 100px;
  left: 0;
  z-index: 11;
}
.contests.contest-landing-page .ribbon-holder.docked {
  position: fixed;
}
.contests.contest-landing-page .ribbon-holder.docked .header-background-holder {
  background-color: #003;
}
.contests.contest-landing-page .ribbon-holder.docked-add,
.contests.contest-landing-page .ribbon-holder.docked-remove {
  opacity: 0;
  -o-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.contests.contest-landing-page .ribbon-holder.docked-add-active,
.contests.contest-landing-page .ribbon-holder.docked-remove-active {
  opacity: 1;
}
.contests.contest-landing-page .ribbon-holder.ribbon-pushed-down {
  top: 160px;
}
@media (max-width: 992px) {
  .contests.contest-landing-page .ribbon-holder {
    z-index: 10;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .contests.contest-landing-page .ribbon-holder > .container {
    width: 100%;
  }
}
.contests.contest-landing-page .logo {
  max-width: 112px;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.contests.contest-landing-page .sponsors-height-holder {
  min-height: 60px;
}
.contests.contest-landing-page .primary-sponsor-holder {
  text-align: center;
  display: inline;
}
.contests.contest-landing-page .primary-sponsor-holder .brought-by {
  color: #fff;
  font-size: 12px;
  display: inline-block;
  vertical-align: sub;
  margin-top: 3px;
}
@media (max-width: 600px) {
  .contests.contest-landing-page .primary-sponsor-holder .brought-by {
    font-size: 8px;
  }
}
@media (max-width: 390px) {
  .contests.contest-landing-page .primary-sponsor-holder .brought-by {
    margin-top: 0;
    display: block;
  }
}
.contests.contest-landing-page .primary-sponsor-holder .contest-primary-sponsor {
  height: 8vw;
  max-height: 45px;
  display: inline-block;
  margin-left: 10px;
}
@media (max-width: 400px) {
  .contests.contest-landing-page .primary-sponsor-holder .contest-primary-sponsor {
    margin: 0;
  }
}
@media (max-width: 390px) {
  .contests.contest-landing-page .primary-sponsor-holder .contest-primary-sponsor {
    max-width: calc(100% - 60px);
  }
}
@media (min-width: 391px) {
  .contests.contest-landing-page .primary-sponsor-holder .contest-primary-sponsor {
    max-width: calc(100% - 90px);
  }
}
.contests .header-background-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 51, 0.6);
}
.contests.contest-landing-page {
  padding-bottom: 20%;
}
.contests.contest-landing-page .ribbon-holder {
  position: fixed;
  width: 100%;
  top: 100px;
  left: 0;
  z-index: 11;
}
.contests.contest-landing-page .ribbon-holder.ribbon-pushed-down {
  top: 160px;
}
@media (max-width: 992px) {
  .contests.contest-landing-page .ribbon-holder {
    z-index: 10;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .contests.contest-landing-page .ribbon-holder > .container {
    width: 100%;
  }
}
.contests.contest-landing-page .tv-widget-col {
  background-clip: content-box;
  margin-top: -410px;
  padding: 0;
  z-index: 9;
}
@media (min-width: 992px) {
  .contests.contest-landing-page .tv-widget-col {
    padding-left: 126px;
    padding-right: 126px;
  }
}
.contests.contest-landing-page .leaderboard-widget-col {
  background-clip: content-box;
  padding-right: 0;
  z-index: 9;
}
@media (max-width: 767px) {
  .contests.contest-landing-page .leaderboard-widget-col {
    margin-top: 30px;
    padding-left: 0;
  }
}
.contests.contest-landing-page iframe {
  border: none;
  width: 100%;
  padding: 1px;
}
@media (min-width: 1816px) {
  .contests.contest-landing-page iframe {
    width: 99.8%;
  }
}
.contests.contest-page-header {
  padding-bottom: 450px;
}
@media (max-width: 767px) {
  .contests.contest-page-header {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.contests.contest-page-header > .container {
  padding-bottom: 0;
}
.contests.contest-page-header .background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.contests.contest-page-header .contest-info {
  text-align: center;
  padding-top: 140px;
}
@media (max-width: 991px) {
  .contests.contest-page-header .contest-info.larger-padding-top {
    padding-top: 275px;
  }
}
.contests.contest-page-header .contest-info .contest-name {
  font-family: 'AvenirNextLTPro Bold';
}
.contests.contest-page-header .contest-info .contest-description {
  font-family: 'AvenirNextLTPro Bold';
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 18px;
}
.contests.contest-page-header .contest-info .funds-raised {
  font-family: 'AvenirNextLTPro Bold';
  margin-top: 40px;
}
.contests.contest-page-header .btn-join {
  background-color: #ff8827;
  border-color: #ce6c1c;
  font-size: 16px;
  min-width: 146px;
  max-width: 250px;
  white-space: normal;
  word-wrap: break-word;
  width: auto;
}
.contests.contest-page-header .btn-join:active,
.contests.contest-page-header .btn-join:hover {
  background-color: #c36a21;
  border-color: #ad5b19;
}
.contests.contest-page-header .btn-join:focus {
  background-color: #a95d1f;
  border-color: #ad5b19;
}
.contests.contest-page-header .page-links {
  color: #fff;
  margin-top: 20px;
  padding-top: 20px;
}
.contests.contest-page-header .page-links .page-link-holder {
  width: 33%;
  display: inline-block;
  text-align: center;
}
.contests.contest-page-header .page-links .page-link-holder:first-child {
  text-align: left;
}
.contests.contest-page-header .page-links .page-link-holder:last-child {
  text-align: right;
}
.contests.contest-page-header .page-links .page-link-holder:only-child {
  text-align: center;
}
.contests.contest-page-header .page-links a.page-link {
  cursor: pointer;
}
@media (max-width: 362px) {
  .contests.contest-page-header .page-links a.page-link {
    font-size: 12px;
  }
}
.contests.contest-page-header .page-links.fixed-top {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 100px;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 20px 0 20px 0;
  background-color: #003;
}
.contests.contest-page-header .page-links.fixed-top .container {
  padding-bottom: 0;
}
.contests.contest-page-header .page-links:not(.fixed-top) {
  height: 40px;
}
.contests.contest-page-header .page-links.fixed-top.ng-hide-add,
.contests.contest-page-header .page-links.fixed-top.ng-hide-remove {
  opacity: 0;
  -o-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.contests.contest-page-header .page-links.fixed-top.ng-hide-remove-active {
  opacity: 1;
}
.contests.contest-page-header .page-links.fixed-top.ng-hide {
  opacity: 0;
}
.contests .page-footer .anchor-link {
  display: block;
  position: absolute;
  visibility: hidden;
}
@media (min-width: 992px) {
  .contests .page-footer .how-it-works-leaderboard-padding {
    padding-left: 142px;
    padding-right: 142px;
  }
}
.contests .page-footer .footer-upper {
  border-bottom: 2px solid #ccc;
  padding: 20px 0;
}
.contests .page-footer .footer-upper .main-sponsor-img {
  max-width: 100%;
  max-height: 200px;
}
.contests .page-footer .footer-upper .prizes-header {
  color: #ff8827;
  margin-bottom: 30px;
  display: block;
  letter-spacing: 3px;
  font-family: 'AvenirNextLTPro Bold';
}
.contests .page-footer .footer-upper .how-it-works-holder {
  padding-left: 0;
}
@media (max-width: 767px) {
  .contests .page-footer .footer-upper .how-it-works-holder {
    padding-right: 0;
  }
}
.contests .page-footer .footer-upper .how-it-works-holder .how-it-works {
  padding: 30px;
  border: 1px solid;
  border-radius: 12px;
  width: 100%;
  background-color: #fff;
}
.contests .page-footer .footer-upper .how-it-works-holder .how-it-works .how-it-works-header {
  color: #ff8827;
  letter-spacing: 3px;
  text-align: center;
  font-family: 'AvenirNextLTPro Bold';
}
.contests .page-footer .footer-upper .how-it-works-holder .how-it-works .how-it-works-body {
  margin-top: 20px;
}
.contests .page-footer .footer-upper .how-it-works-holder .how-it-works .how-it-works-body p {
  margin-top: 20px;
  text-align: center;
  word-wrap: break-word;
}
.contests .page-footer .footer-upper .how-it-works-holder .how-it-works .how-it-works-body h3 {
  margin-top: 20px;
}
.contests .page-footer .footer-upper .how-it-works-holder .how-it-works .how-it-works-body .how-it-works-content h3 {
  font-family: 'AvenirNextLTPro Bold';
  text-align: left;
}
.contests .page-footer .sponsors-header {
  text-align: center;
  padding: 10px 0;
  font-style: italic;
  color: #9a9898;
}
.contests .page-footer .sponsors-list img {
  height: 10em;
  margin: 1%;
}
.contests .dropdown-menu {
  z-index: 10;
  width: 50%;
  padding: 10px;
  margin-left: 25%;
}
@media (max-width: 375px) {
  .contests .dropdown-menu {
    width: 100%;
    margin-left: 0;
  }
}
.contests .dropdown {
  background: none;
}
.contests.contest-landing-page.landing-page-mobile div[class^="col-"],
.contests.contest-landing-page.landing-page-mobile div.container,
.contests.contest-landing-page.landing-page-mobile div.container-fluid,
.contests.contest-landing-page.landing-page-mobile.container-fluid {
  padding-left: 5px;
  padding-right: 5px;
}
.contests.contest-landing-page.landing-page-mobile .contests.contest-page-header .page-links a.page-link {
  font-size: 11px;
}
.contests.contest-landing-page.landing-page-mobile div.row {
  margin-left: -5px;
  margin-right: -5px;
}
.contests.contest-landing-page.landing-page-mobile .dropdown-menu {
  width: calc(100% - 10px);
  margin-left: 2px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;
}
.contests.contest-landing-page.landing-page-mobile .tv-widget-col {
  padding: 0;
}
.contests.contest-landing-page.landing-page-mobile .leaderboard-widget-col {
  padding: 0;
}
.contests.contest-landing-page.landing-page-mobile .page-footer .sponsors-list img {
  height: 6em;
}
.contests.contest-landing-page.landing-page-mobile .page-footer .footer-upper .how-it-works-holder p {
  text-align: left;
}
.contests.contest-landing-page.landing-page-mobile .ribbon-holder {
  top: 80px;
  position: relative;
}
.create-freestyle {
  background-color: #F7F7FD;
}
.create-freestyle .teams-holder {
  max-height: 200px;
  overflow-y: auto;
}
.create-freestyle .reupload-video {
  color: dodgerblue;
  float: right;
  cursor: pointer;
}
.create-freestyle .btn {
  width: 100%;
  white-space: normal;
}
.create-freestyle .challenge-type-header {
  color: dodgerblue;
}
.create-freestyle .challenge-type-panel {
  padding-top: 3%;
}
.create-freestyle .challenge-type-panel-lower {
  padding-bottom: 3%;
}
.create-freestyle .challenge-type-desc {
  margin-top: 2%;
}
.create-freestyle .challenge-button {
  margin-top: 4%;
}
.create-freestyle .header {
  padding-top: 3%;
}
.create-freestyle .header h4,
.create-freestyle .header h5 {
  margin-left: 5%;
}
.create-freestyle input,
.create-freestyle textarea {
  width: 100%;
  font-size: 14px;
  border-color: #C3C3CA;
}
.create-freestyle input:disabled,
.create-freestyle textarea:disabled {
  background-color: #fff;
  opacity: 0.4;
}
.create-freestyle input {
  height: 30px;
}
.create-freestyle textarea {
  resize: none;
  height: 70px;
}
.create-freestyle .challenge-name,
.create-freestyle .challenge-description {
  margin-left: 3%;
  margin-bottom: 2px;
}
.create-freestyle .challenge-description {
  margin-top: 10px;
}
.create-freestyle .buttons-group {
  margin-bottom: 5%;
}
.create-freestyle .reupload {
  color: dodgerblue;
  float: right;
  margin-top: 15px;
}
.create-freestyle .reupload:hover,
.create-freestyle .select-other:hover {
  text-decoration: underline;
  cursor: pointer;
}
.create-freestyle .team-item {
  margin-top: 20px;
  border-bottom: 1px solid #DAD5D5;
  margin-bottom: 5px;
}
.create-freestyle .team-logo-holder {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.create-freestyle .team-logo {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.create-freestyle .team-name {
  padding-top: 5%;
  word-wrap: break-word;
}
.create-freestyle .team-item .glyphicon-ok {
  margin-top: 20px;
}
.create-freestyle .item-lower {
  margin-top: 10px;
}
.create-freestyle .item-lower .stats {
  margin-right: 10px;
}
.create-freestyle .item-lower img {
  width: 16px;
  margin-bottom: 2%;
}
.create-freestyle .win {
  color: #93CB53;
}
.create-freestyle .loss {
  color: red;
}
.create-freestyle .draw {
  color: #F7A724;
}
.create-freestyle .team-selected {
  font-size: 12px;
  margin-left: 3%;
}
.create-freestyle .selected-team-ok {
  font-size: 14px;
  color: dodgerblue;
}
.create-freestyle .select-other {
  color: dodgerblue;
  float: right;
  margin-top: 3%;
}
.create-freestyle .lower-teams {
  padding-top: 20px;
  padding-bottom: 20px;
}
.create-freestyle .new-team-buttons {
  margin-top: 25%;
}
.create-freestyle .team-logo-uploader {
  margin-top: 15px;
  position: relative;
  padding-bottom: 100%;
  border: 1px solid #000;
}
.create-freestyle .team-logo-uploader .camera {
  position: absolute;
  top: 25%;
  width: 20%;
  left: 40%;
}
.create-freestyle .team-logo-uploader h6 {
  text-align: center;
  position: absolute;
  top: 50%;
  width: 80%;
  left: 10%;
}
.create-freestyle .new-team-form {
  margin-bottom: 25px;
}
.create-freestyle .search-fans span {
  display: inline-block;
}
.create-freestyle .search-fans .search-holder {
  width: 65%;
  margin-left: 2%;
}
.create-freestyle .search-fans .search-holder input {
  border-radius: 3%;
  background-image: url(../img/magnifer_icon.png);
  background-repeat: no-repeat;
  background-position: left 7px center;
  padding-left: 30px;
}
.create-freestyle .avatar-holder {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.create-freestyle .avatar-holder img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.create-freestyle .username {
  margin-top: 7%;
}
.create-freestyle .user-status {
  color: #C3C3CA;
}
.create-freestyle .user-icon {
  width: 20px;
  margin-left: 5px;
  margin-top: 3px;
}
.create-freestyle .add-player {
  padding-top: 3%;
}
.create-freestyle .add-player-button {
  padding-left: 0;
  padding-right: 0;
}
.create-freestyle .create-button {
  margin-top: 20px;
  margin-bottom: 20px;
}
.create-freestyle .item-fan {
  padding-top: 5px;
  padding-bottom: 5px;
}
.create-freestyle .fans-list {
  max-height: 200px;
  overflow-y: auto;
}
.create-freestyle .active-team {
  color: dodgerblue;
}
.create-freestyle .team-selected-holder {
  word-wrap: break-word;
}
@media (max-width: 1023px) {
  .create-freestyle .challenge-type-desc {
    font-size: 12px;
  }
  .create-freestyle input {
    height: 25px;
  }
  .create-freestyle .btn {
    font-size: 10px;
  }
  .create-freestyle .item-lower h6 {
    font-size: 10px;
  }
  .create-freestyle .item-lower .stats {
    margin-right: 2px;
  }
  .create-freestyle .item-lower img {
    width: 10px;
    margin-bottom: 1%;
  }
  .create-freestyle .team-name h4 {
    font-size: 12px;
  }
  .create-freestyle .team-item .glyphicon-ok {
    top: 0;
  }
  .create-freestyle .create-team-button {
    padding-left: 0;
    padding-right: 0;
  }
  .create-freestyle .team-logo-uploader h6 {
    font-size: 8px;
  }
}
.new-challenge-team-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
}
.popover-content {
  padding-left: 5px;
  padding-right: 5px;
}
.create-path {
  background-color: #F7F7FD;
  padding-bottom: 10px;
}
.create-path .error-message h5 {
  color: #A94442;
}
.create-path .path-desc {
  padding-top: 15px;
  text-align: center;
}
.create-path .alert {
  width: 100%;
  margin-bottom: 10px;
}
.create-path h5 {
  color: #A5A5A5;
}
.create-path input,
.create-path textarea {
  font-size: 14px;
}
.create-path button {
  border-radius: 0;
  width: 100%;
}
.create-path button:disabled,
.create-path button:disabled:hover {
  border-color: #888C88;
  background-color: #9B9B9B;
}
.create-path button .cancel:disabled {
  color: #fff;
}
.create-path hr {
  border-top: 1px solid #DAD5D5;
}
.create-path .new-path-form input {
  border: 1px solid #DAD5D5;
  width: 100%;
  margin-bottom: 10px;
}
.create-path .new-path-form textarea {
  border: 1px solid #DAD5D5;
  width: 100%;
  margin-bottom: 10px;
  height: 85px;
  max-width: 100%;
  min-width: 100%;
}
.create-path .new-path-form textarea:disabled {
  background-color: #fff;
  opacity: 0.4;
}
.create-path .new-path-form .path-name {
  height: 30px;
}
.create-path .new-path-form .path-select {
  margin-bottom: 10px;
}
.create-path .new-path-form .path-select .list-loading-overlay {
  border-radius: 4px;
}
.create-path .new-path-form .input-label {
  text-align: left;
  margin-bottom: 5px;
}
.create-path .new-path-form .input-label h5 {
  color: #000;
}
.create-path .new-path-form .add-skill {
  margin-top: 10px;
  margin-bottom: 10px;
}
.create-path .new-path-form .video-footer {
  text-align: right;
  margin-right: 4%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: dodgerblue;
}
.create-path .new-path-form .video-footer a {
  cursor: pointer;
}
.create-path .new-path-form .video-footer a:disabled {
  cursor: default;
  color: #a5a5a5;
}
.create-path .new-path-form .assign-path {
  text-align: right;
  padding-top: 17px;
}
@media (max-width: 1199px) {
  .create-path .new-path-form .assign-path {
    text-align: center;
  }
}
.create-path .new-path-form .add-team .btn {
  padding: 6px 0;
  margin-top: 8px;
}
.create-path .new-path-form .add-team .btn span {
  margin-right: 5px;
}
.create-path .new-path-form .add-team .btn-success.finished-teams-disabled {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.create-path .video-footer-holder {
  min-height: 32px;
  padding-right: 15px;
}
.create-skill {
  position: absolute;
  z-index: 200;
  text-align: left;
  background-color: #F7F7FD;
}
.create-skill a {
  cursor: pointer;
}
.create-skill .error-div {
  margin-bottom: 10px;
}
.create-skill .teams-button {
  font-size: 12px;
  margin-top: 8px;
}
.create-skill .path-name {
  padding-top: 10px;
}
.create-skill .content-main {
  padding-bottom: 15px;
}
.create-skill input,
.create-skill textarea {
  font-size: 14px;
}
.create-skill .error-message {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.create-skill .gray {
  color: #A5A5A5;
}
.create-skill .button-div {
  margin-top: 37px;
}
.create-skill .assign-path {
  text-align: right;
  padding-top: 17px;
}
@media (max-width: 1199px) {
  .create-skill .assign-path {
    text-align: center;
  }
}
.create-skill .assign-path h5 {
  color: #A5A5A5;
}
.create-skill .video-footer-holder {
  min-height: 32px;
  padding-right: 15px;
}
.create-skill .video-footer {
  color: dodgerblue;
  margin-top: 10px;
  float: right;
}
.create-skill .video-footer a:disabled {
  cursor: default;
  color: #a5a5a5;
}
.create-skill hr {
  border-top: 1px solid #DAD5D5;
}
.create-skill input {
  margin-top: 10px;
  width: 100%;
  height: 30px;
}
.create-skill textarea {
  border: 1px solid #ECECFB;
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  margin-top: 10px;
  max-width: 100%;
  min-width: 100%;
}
.create-skill textarea:disabled {
  opacity: 0.4;
  background: #fff;
}
.create-skill button:disabled,
.create-skill button:disabled:hover {
  border-color: #E4E4EF;
  background-color: #E4E4EF;
  color: #888C88;
}
.create-skill .btn-full-width {
  width: 100%;
}
@media (max-width: 1360px) {
  .create-skill .assign-team-font {
    font-size: 12px;
  }
}
@media (min-width: 1361px) {
  .create-skill .assign-team-font {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .create-skill .save-submit {
    font-size: 11px;
    padding: 0;
  }
}
.datepicker-component .danger {
  text-align: left;
  padding-right: 15px;
}
.datepicker-component input[type="number"]::-webkit-outer-spin-button,
.datepicker-component input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.datepicker-component input[type=number] {
  -moz-appearance: textfield;
}
.datepicker-component input {
  width: 100%;
  -webkit-transition: border 100ms ease-out;
  -moz-transition: border 100ms ease-out;
  -o-transition: border 100ms ease-out;
}
.datepicker-component input.error {
  border: 1px solid red;
}
.fans {
  margin: 20px 0;
}
.fans .header {
  background: #1B1C2C;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.fans .content {
  border: 2px solid #eeeeee;
  height: auto;
  background-color: #fff;
}
.fans .headers-titles {
  padding: 15px 0;
}
.fans .headers-titles .topic {
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}
.fans .headers-titles .alert {
  margin-bottom: 0;
  margin-top: 15px;
}
.fans .findFans {
  height: 34px;
  padding: 5px 0;
  border: 1px solid #498FE1;
  border-radius: 0px;
  text-align: center;
  color: #498FE1;
}
.fans .findFans:hover {
  cursor: hand;
}
.fans .search-fields {
  z-index: 1000;
  position: relative;
  margin: 0;
}
.fans .search-fields .form-control {
  height: 34px;
  border-radius: 0px;
  border: 1px solid #498FE1;
  border-right: none;
}
.fans .search-fields.ng-hide {
  right: -200px;
}
.fans .search-fields.ng-show-add-active {
  display: block;
}
.fans .search-fields-wrapper {
  padding-bottom: 10px;
  height: 40px;
}
.fans .search-my-fans-input {
  height: 34px;
}
.fans .input-group-btn .btn-default {
  border-radius: 0px;
  border: 1px solid #498FE1;
}
.fans .chosenType {
  color: #498FE1;
}
.fans .search-my-fans .search-btn {
  border: 1px solid #eeeeee;
}
.fans .search-field-wrapper {
  padding: 20px 15px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin-top: 10px;
}
.fans .fan-wrapper {
  height: 95px;
  padding-top: 20px;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #eeeeee;
  word-break: break-all;
}
.fans .btnFan {
  color: white;
  border-color: white;
  background-color: #488EE0;
}
.fans .my-fans-user-img {
  margin-left: 4px;
  width: 18px;
  height: 18px;
  top: -2px;
}
.fans .btnAddFan,
.fans .btnAddFan:disabled {
  background-color: white;
  border: 1px solid #488EE0;
  color: #488EE0;
}
.fans .fan-info a {
  font-size: smaller;
  color: black;
}
@media (min-width: 700px) and (max-width: 1440px) {
  .fans .fan-info {
    font-size: smaller;
  }
}
@media (min-width: 1441px) {
  .fans .fan-info {
    position: relative;
    top: 10px;
  }
}
.fans input {
  font-family: 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #555860;
}
.fans .search input {
  width: 100%;
  padding: 0px;
  padding-left: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
  -moz-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
  -ms-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
  -o-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
  box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
}
.fans .search input:focus {
  outline: none;
  border-color: #66b1ee;
  -webkit-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
  -moz-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
  -ms-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
  -o-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
  box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
}
.fans .search .results div:first-child:hover:before,
.fans .search .results div:first-child:hover:after {
  display: none;
}
.fans .results {
  display: none;
  position: absolute;
  top: 35px;
  left: 0;
  min-width: 400px;
  padding-bottom: 15px;
  right: 0;
  z-index: 1000;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #cbcfe2 #c8cee7 #c4c7d7;
  border-radius: 3px;
  background-color: #fdfdfd;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, #eceef4));
  background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -ms-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: linear-gradient(top, #fdfdfd, #eceef4);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow-y: scroll;
  background-color: #F7F7FD;
}
.fans .results.results-shown {
  display: block;
}
.fans .results div {
  display: block;
}
.fans .results div:first-child:before {
  border-bottom: 5px solid #c4c7d7;
  top: -11px;
}
.fans .results div:first-child:after {
  border-bottom: 5px solid #fdfdfd;
  top: -10px;
}
.fans .results div:last-child {
  margin-bottom: -1px;
}
.fans .results .item {
  border-bottom: 1px solid #eeeeee;
  padding: 5px 0;
  display: block;
  margin: auto;
  float: none;
  height: 80px;
}
.fans .results .item .user-full-name {
  text-align: left;
  position: relative;
  top: 20px;
}
.fans .results .button {
  position: relative;
  top: 17px;
}
.fans .results .button button {
  float: right;
}
.fans :-moz-placeholder {
  color: #a7aabc;
  font-weight: 200;
}
.fans ::-webkit-input-placeholder {
  color: #a7aabc;
  font-weight: 200;
}
.fans .fan-info-2 {
  margin-top: 20px;
  padding-bottom: 20px;
}
.fans .fan-info-2 .follow-img {
  width: 18px;
  height: 18px;
  position: relative;
  top: -2px;
}
.fans .fan-info-1 {
  margin-top: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}
.fans .fan-info-1 .arrow-left {
  height: 30px;
  position: relative;
  top: 20px;
}
.fans .fan-info-1 .fan-avatar {
  width: 70px;
  height: 70px;
}
.fans .teams-search {
  width: 50%;
}
.fans .statistics {
  position: relative;
  top: 25px;
}
.fans .statistics span:first-child {
  display: inline;
}
.fans .statistics span:nth-child(2) {
  display: inline-block;
}
.fans .statistics span img {
  width: 20px;
  vertical-align: sub;
}
.fans .statistics span h4 {
  margin-left: 3px;
}
.fans .statistics-country span:first-child {
  display: inline;
}
.fans .statistics-country span:nth-child(2) {
  display: inline-block;
}
.fans .statistics-country span img {
  width: 30px;
  vertical-align: sub;
}
.fans .challenge {
  padding: 20px 0;
}
.fans .challenge .challenge-info {
  position: relative;
  top: 40px;
}
@media (min-width: 1000px) and (max-width: 1442px) {
  .fans .challenge .challenge-info {
    top: 20px;
  }
}
.fans .challenge .arrow-right {
  position: relative;
  top: 20px;
}
.fans .challenge .arrow-right img {
  position: relative;
}
@media (min-width: 1000px) and (max-width: 1442px) {
  .fans .challenge .arrow-right img {
    top: 30px;
  }
}
@media (min-width: 1450px) {
  .fans .challenge .arrow-right img {
    top: 45px;
  }
}
.fans .challenge .team-img {
  height: 40px;
  width: auto;
}
.fans .challenge .challenge-name {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 0;
}
.fans .challenge .team-name {
  font-size: smaller;
  position: relative;
  top: 10px;
}
.fans .btn-following {
  border-color: #488EE0;
  background-color: #488EE0;
  color: white;
}
.fans .fan-player-tabs {
  background-color: #F7F7FD;
  padding: 20px 0;
  text-align: center;
  font-weight: bold;
}
.fans .challenge-info div {
  margin-top: 3px;
  margin-bottom: 3px;
}
.fans .pull-left:nth-last-of-type(2),
.fans .pull-right:nth-last-of-type(1) {
  border-bottom: none;
}
.fans .gray-text {
  color: lightgray;
}
@media (min-width: 1442px) {
  .fans .gray-text span {
    position: relative;
    top: 10px;
  }
}
.fans .gray-text .team-size {
  margin-left: 10px;
}
.fans .fan-names {
  padding-right: 3px;
  padding-left: 0;
  word-break: break-word;
}
.fans .fan-avatar {
  border: none;
  width: 48px;
  height: 48px;
}
.fans .dl-horizontal {
  margin-bottom: 10px;
}
.fans .dl-horizontal dt {
  width: auto;
  margin-right: 15px;
  text-align: start;
}
.fans .dl-horizontal dt img {
  width: 48px;
  height: 48px;
}
.fans .dl-horizontal dd {
  margin-left: 45px;
  font-size: 14px;
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .fans .dl-horizontal dt {
    width: auto;
    margin-right: 8px;
    text-align: start;
  }
  .fans .dl-horizontal dd {
    margin-left: 45px;
    font-size: 14px;
  }
}
.fans .username {
  word-break: break-word;
  position: relative;
  top: 25px;
  font-weight: bold;
  font-size: large;
}
.fans .basic-info {
  position: relative;
  top: 5px;
}
.fans .pending-image {
  background-color: #eee;
}
.fans #results .pending-image {
  float: right;
}
.fans .fans-activity-feed {
  border-top: 1px solid #eee;
}
.fans .fans-activity-feed .content {
  border: 2px solid #eee;
}
.fans .fans-activity-feed .content:first-of-type {
  border-top: none;
}
.fans .activity-feed-wrapper {
  margin-bottom: 20px;
  padding-top: 20px;
}
.fans .padding-top {
  padding-top: 15%;
}
.fans .counts {
  color: #9e9e9e;
  padding-left: 20px;
  border-left: #F7F7FD 1px solid;
}
.fans .blueFindFans {
  background-color: #498FE1;
  color: white;
}
.search-fields.ng-hide-add,
.panel.ng-hide-remove {
  transition: 0s linear all;
}
.search-fields.ng-hide-add-active,
.panel.ng-hide-remove-active {
  transition: all ease-in-out 2s;
}
.add-high-five {
  cursor: pointer;
}
.add-high-five:hover,
.add-high-five:focus {
  color: #4a90e2;
}
.high-fived {
  color: #4a90e2;
}
.form-component .dropdown .text-align-left {
  text-align: left;
}
.form-component ul li {
  list-style-type: none;
}
.form-component .second-dropdown {
  max-height: 150px;
  overflow-y: auto;
}
.form-component .second-dropdown .nav a {
  cursor: pointer;
}
.form-component .dropdown.disabled {
  opacity: 0.4;
}
.form-component .nav a {
  cursor: pointer;
}
header > div {
  width: calc(100% - 232px);
  height: 72px;
  background-color: #fff;
  top: 0;
  left: 232px;
  position: fixed;
  padding: 12px 32px;
  border-bottom: 1px solid #E5E5E5;
  z-index: 100;
}
header > div img.menu-icon {
  display: none;
}
header > div.mobile {
  width: 100%;
  left: 0;
  height: 22.5vw;
  padding: 0 9.7vw 0 7vw;
  border-bottom-width: 2px;
}
header > div.mobile img.menu-icon {
  display: inline-block;
  width: 5.6vw;
  margin-top: 9.4vw;
}
header > div header-profile-detail > div {
  float: right;
}
.highlights.highlights-background {
  background-color: #f2f2f2;
}
.highlights .alert {
  margin-top: 95px;
}
.highlights .purchase {
  text-align: center;
  color: #5F7285;
  margin-top: 20px;
}
.highlights .topya-main-page-header {
  padding-left: 10px;
  margin-bottom: 15px;
  text-align: left;
}
.highlights .small-lower {
  margin-top: 5%;
}
.highlights .path-highlights {
  padding-top: 15px;
}
.highlights .small-highlight-path {
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: white;
  padding-top: 15px;
  border-left: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
}
@media (max-width: 1440px) {
  .highlights .arrow {
    margin-top: 135%;
  }
}
@media (min-width: 1441px) {
  .highlights .arrow {
    margin-top: 100%;
  }
}
.highlights .views {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.highlights .left {
  float: right;
}
.highlights .freestyles-header {
  margin-left: -15px;
}
.highlights .freestyle-highlights {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #F7F7FD;
}
.highlights .freestyle-highlight {
  margin-top: 20px;
  background-color: white;
  padding-top: 15px;
  border-left: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
}
.highlights .video-holder {
  width: 100%;
  padding-bottom: 56.7%;
  position: relative;
}
.highlights .video-holder:hover {
  z-index: 201;
  opacity: 0.9;
}
.highlights .highlight-description-holder {
  position: absolute;
  width: 100%;
  left: 0;
  height: 30px;
  overflow-y: hidden;
  bottom: 10px;
}
.highlights .highlight-description-holder:hover {
  overflow-y: visible;
}
.highlights .highlight-description:hover {
  top: auto;
  bottom: 0;
}
.highlights .highlight-description {
  z-index: 200;
  background-color: #1B1C2C;
  opacity: 0.9;
  color: white;
  padding: 1%;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 30px;
  position: absolute;
}
.highlights .freestyle-highlight .versus {
  position: absolute;
  width: 10%;
  top: 20%;
  left: 45%;
  z-index: 199;
}
.highlights .alternating-background:nth-child(odd) {
  background-color: #F7F7FD;
}
.leaderboardItem {
  padding-top: 15px;
  padding-bottom: 15px;
}
.leaderboard .topya-side-block-content {
  background-color: #fff;
}
.leaderboard .dropdown-wrapper {
  border-bottom: 1px solid #f7f7fd;
}
.leaderboard .leaderboardItem {
  border-bottom: 1px solid #f7f7fd;
}
.leaderboard .leaderboardItem .leaderboard-position {
  padding-top: 6%;
  text-align: center;
}
.leaderboard .leaderboardItem .leaderboard-player {
  padding-left: 0;
  padding-right: 0;
}
.leaderboard .leaderboardItem .leaderboard-player-info {
  padding-top: 6%;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 1170px) {
  .leaderboard .leaderboardItem .leaderboard-player-info,
  .leaderboard .leaderboardItem .leaderboard-position {
    padding-top: 3%;
  }
  .leaderboard .leaderboardItem .leaderboard-position {
    padding-left: 5px;
  }
}
.leaderboard .no-results {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  color: #9a9a9a;
  text-align: center;
}
.leaderboards {
  margin-top: 20px;
}
.leaderboards .header {
  background: #1B1C2C;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.leaderboards .leaderboards-wrapper {
  border: 1px solid #C1BCB7;
  background-color: white;
}
.leaderboards .leaderboards-list {
  padding-left: 10px;
  padding-right: 10px;
}
.leaderboards .leaderboars-header {
  margin: 15px 0;
  color: #C1BCB7;
  font-weight: bold;
}
.leaderboards .leaderboars-header div img {
  height: 25px;
}
.leaderboards .no-teams-message {
  padding-top: 10px;
  padding-bottom: 10px;
}
.leaderboards .leaderboard-wrapper {
  padding: 5px;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .leaderboard .leaderboard-position {
    padding-left: 5px;
    padding-right: 2px;
  }
  .leaderboard .block .header {
    font-size: 14px;
  }
  .leaderboardItem .leaderboard-name,
  .leaderboard .leaderboard-username {
    font-size: 11px;
  }
}
.leaderboards-list .leaderboards-members .member {
  border-top: 1px solid #F7F7FD;
  padding: 10px 0;
}
.leaderboards-list .leaderboards-members .leaderboard-position {
  vertical-align: middle;
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
.leaderboards-list .no-results {
  padding-top: 90px;
  padding-bottom: 90px;
  width: 100%;
  color: #9a9a9a;
  text-align: center;
}
.leaderboards-list .arrow {
  position: relative;
  top: 10px;
}
.leaderboards-members .message {
  color: #C1BCB7;
}
.nav {
  color: black;
}
.player-header,
.dropdown a {
  text-align: center;
}
.leaderboard .no-teams-message,
.leaderboards .no-teams-message {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
.leaderboard .width-100,
.leaderboards .width-100 {
  width: 100%;
}
.leaderboard .width-200px,
.leaderboards .width-200px {
  width: 200px;
}
.leaderboard .dropdown,
.leaderboards .dropdown {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.leaderboard .dropdown-menu,
.leaderboards .dropdown-menu {
  margin-left: 50%;
  transform: translateX(-50%);
  min-width: 220px;
}
.leaderboard ul,
.leaderboards ul {
  margin-left: 35%;
  list-style: none;
}
.leaderboard-username {
  color: #9e9e9e;
}
.leaderboard-dropdown-wrapper {
  background-color: #E4E4EF;
}
.leaderboard-dropdown-wrapper .dropdown a {
  background-color: #E4E4EF;
}
.leaderboard-dropdown-wrapper .dropdown .section-title {
  border-bottom: 2px solid whitesmoke;
}
.dropdown-wrapper > .nav > li > a:hover,
.dropdown-wrapper .nav > li > a:focus,
.dropdown-wrapper .nav .open > a,
.dropdown-wrapper .nav .open > a:hover,
.dropdown-wrapper .nav .open > a:focus {
  background: #E4E4EF;
}
.login-form button.submit {
  font-size: 14px;
}
.login-form .btn.btn-primary {
  width: 33%;
}
.login-form input {
  padding-left: 35px;
}
.login-form .password {
  background: url(../../img/password-icon.png) no-repeat 10px 50% #fff;
}
.login-form .username {
  background: url(../../img/username-icon.png) no-repeat 10px 50% #fff;
}
.login-form input[type=text],
.login-form input[type=password],
.login-form button {
  height: 35px;
}
.login-form span {
  color: #B3B3B3;
}
.login-form a {
  color: dodgerblue;
}
.login {
  margin-top: 30px;
}
.login.error-login {
  z-index: 200;
  width: 100%;
  position: absolute;
  top: 30px;
}
.register-buttons {
  background: none;
  border-style: none;
}
.register-buttons img {
  width: 100%;
}
.choose-role .start-subtitle {
  margin: 4% 0 2% 0;
  color: #fff;
}
.register-new .login-form input {
  background-image: none;
  margin-top: 30px;
}
.register-new .login-form .btn.btn-primary {
  margin-top: 30px;
  width: 100%;
}
.register-new .login-form span,
.register-new .login-form a {
  visibility: hidden;
}
login-page > div .marginTop {
  margin-top: 30px;
}
.review {
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 100%;
}
.review .scroll-holder > list-loading-overlay .list-loading-overlay {
  width: calc(100% + 30px);
  left: -15px;
}
.review h1 {
  margin-top: 3%;
  color: #4a4a4a;
}
.review h1.page-title {
  font-family: 'AvenirNextLTPro Bold';
}
.review hr {
  border-top-width: 2px;
}
.review .glyphicon-ok {
  color: #449d44;
}
@media (max-width: 1150px) {
  .review .glyphicon-ok {
    font-size: 10px;
  }
}
.review .review-page-header {
  margin: 25px 5%;
}
.review .review-page-header .row {
  margin: 0 -30px;
}
.review .review-search {
  margin-right: 5%;
  margin-left: 5%;
}
.review .review-header-buttons {
  margin-left: 2%;
  margin-right: 2%;
}
.review .review-sport-selector {
  margin-top: 0%;
}
.review .search-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}
.review .review-table {
  margin-top: 20px;
  margin-bottom: 20px;
}
.review .review-table list-loading-overlay .list-loading-overlay {
  width: calc(100% + 30px);
  left: -15px;
}
.review .review-table .review-header {
  font-weight: bold;
  background-color: #003;
  color: #fff;
  padding: 10px 15px;
}
.review .review-table .review-header .dropdown-menu {
  width: 310px;
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .review .review-table .review-header .dropdown-menu.far-right-dropdown.position-right-lg {
    right: 0;
    left: inherit;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .review .review-table .review-header .dropdown-menu.far-right-dropdown.position-right-md {
    right: 0;
    left: inherit;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .review .review-table .review-header .dropdown-menu.far-right-dropdown.position-right-sm {
    right: 0;
    left: inherit;
  }
}
@media (max-width: 767px) {
  .review .review-table .review-header .dropdown-menu.far-right-dropdown.position-right-xs {
    right: 0;
    left: inherit;
  }
}
@media (max-width: 767px) {
  .review .review-table .review-header div[class^='col-'] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 767px) and (min-width: 500px) {
  .review .review-table .review-header {
    font-size: 12px;
  }
}
@media (max-width: 499px) {
  .review .review-table .review-header {
    font-size: 10px;
  }
}
.review .review-table .review-header .active-filter {
  color: dodgerblue;
}
.review .review-table .review-header .dropdown {
  background: inherit;
}
@media (max-width: 1150px) {
  .review .review-table .review-header .dropdown h4 {
    font-size: 15px;
    font-weight: bold;
  }
}
.review .review-table .review-header span:nth-child(2),
.review .review-table .review-header i {
  vertical-align: sub;
}
.review .review-table div[class*='review-row'] {
  padding: 0 15px;
}
.review .review-table .review-row-even {
  background-color: #e5e5e5;
}
.review .review-table .review-row-even .review-detail .frame,
.review .review-table .review-row-even .review-detail.frame {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-top: none;
}
.review .review-table .review-row-odd .review-detail .frame,
.review .review-table .review-row-odd .review-detail.frame {
  background-color: #fff;
  border: 1px solid #fff;
  border-top: none;
}
.review .review-table .review-summary {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 767px) {
  .review .review-table .review-summary div[class^='col-'] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 767px) and (min-width: 500px) {
  .review .review-table .review-summary {
    font-size: 12px;
  }
}
@media (max-width: 499px) {
  .review .review-table .review-summary {
    font-size: 10px;
  }
}
.review .review-table .review-video {
  height: 225px;
}
.review .review-table .review-video .video-holder {
  margin: 0;
}
.review .review-table .responsive-text {
  font-size: 1vw;
}
.review .review-table .review-description {
  padding: 5px;
}
.review .review-table .description-row {
  min-height: 50px;
}
.review .review-table .review-badge {
  display: block;
  margin: auto;
  max-width: 100px;
  margin-top: 25px;
}
.review .review-table .review-detail {
  min-height: 400px;
}
@media (min-width: 2100px) {
  .review .review-table .review-detail {
    min-height: 470px;
  }
}
.review .review-table .review-detail .frame {
  padding-top: 20px;
  padding-bottom: 20px;
}
.review .review-table .review-detail .button-padding {
  margin: 4%;
}
.review .review-table .review-detail .review-detail-properties .path-description-header {
  margin-top: 10px;
}
.review .review-table .review-detail .review-detail-properties .skill-stats span {
  display: inline-block;
}
.review .review-table .review-detail .review-detail-properties .skill-stats span:nth-child(2),
.review .review-table .review-detail .review-detail-properties .skill-stats span:nth-child(4) {
  margin-left: 5px;
}
.review .review-table .review-detail .review-detail-properties .skill-stats span:nth-child(3) {
  margin-left: 15px;
}
.review .review-table .review-detail .review-detail-properties .skill-stats .glyphicon {
  font-weight: bold;
  color: #498fe1;
  font-size: 18px;
}
.review .review-table .review-detail .review-detail-properties .skill-stats .glyphicon.glyphicon-sm {
  font-size: 14px;
}
.review .review-table .review-detail .review-detail-properties .skill-stats img {
  height: 22px;
  vertical-align: sub;
}
.review .review-table .review-detail .review-detail-properties .skill-stats img.img-sm {
  height: 17px;
}
.review .review-table .edit-path-button {
  position: absolute;
  width: 100%;
  bottom: 15px;
  width: calc(100% - 30px);
  left: 30px;
}
.review .flag-image {
  margin-left: 25%;
  margin-bottom: 5%;
  width: 55%;
}
.popover-button {
  width: 220px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.popover-custom-date {
  margin-top: 5px;
}
.popover-custom-date .custom-date {
  margin: 5px;
  width: 95%;
}
.finish-button {
  margin: 5px;
  width: 100px;
}
menu {
  width: 232px;
}
menu > div {
  width: 232px;
  height: 100%;
  background: #003;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 3000;
}
menu > div img.logo-image {
  width: 152px;
  height: 48px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 32px;
  margin-bottom: 48px;
}
menu > div div.items-holder {
  max-height: calc(100% - 128px);
  overflow-x: hidden;
  overflow-y: auto;
}
menu > div.mobile {
  width: 71.25vw;
  padding: 7.5vw;
  top: 22.5vw;
  height: calc(100% - 22.5vw);
}
menu > div.mobile img.logo-image {
  width: 31vw;
  height: 31vw;
  margin: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 11vw;
}
menu > div.mobile.ng-enter,
menu > div.mobile.ng-leave {
  transition: left linear 0.2s;
  -webkit-transition: left linear 0.2s;
  -moz-transition: left linear 0.2s;
}
menu > div.mobile.ng-enter,
menu > div.mobile.ng-leave.ng-leave-active {
  left: -72vw;
}
menu > div.mobile.ng-leave,
menu > div.mobile.ng-enter.ng-enter-active {
  left: 0;
}
.myCoaches,
.myCoachesItem {
  padding-top: 15px;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 15px;
}
.myCoaches-wrapper {
  background-color: white;
}
.myCoaches .dropDownMenu-wrapper {
  background-color: white;
}
.myCoaches-wrapper {
  border: none !important;
}
.myCoaches-wrapper .myCoachesItem {
  border-bottom: 1px solid #f7f7fd;
}
.myCoaches .block {
  margin: 5px 0;
  border: 1px solid #DBDBEA;
}
.myCoaches .block .header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.myCoaches .block .content {
  background: white;
  padding: 0;
}
.myCoachesItem .myCoaches-position {
  vertical-align: middle;
  font-size: 20px;
  height: 38px;
  padding-top: 8px;
  padding-left: 10px;
  margin-right: 5px;
}
.myCoachesItem .myCoaches-position {
  margin: 0 !important;
  margin-left: 2px !important;
}
.myCoachesItem .myCoaches-player img {
  width: 38px;
  height: 38px;
  margin-right: 5px;
  float: left;
}
.myCoachesItem .myCoaches-player {
  padding-left: 0;
  padding-right: 0;
}
.myCoachesItem > .myCoaches-player > .myCoaches-player-info {
  display: table-row;
}
.myCoachesItem > .myCoaches-player > .myCoaches-player-info > span {
  display: block;
}
.myCoachesItem > .myCoaches-player > .myCoaches-player-info > span.myCoaches-username {
  color: #9e9e9e;
}
.myCoaches-wrapper {
  padding: 5px;
}
.myCoaches-wrapper .see_more {
  text-align: center;
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .myCoaches .myCoaches-position {
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .myCoaches .myCoaches-position {
    font-size: 16px;
    padding: 0;
    padding-left: 5px;
    padding-right: 2px;
    margin-right: 1px;
  }
  .myCoaches .block .header {
    font-size: 14px;
  }
  .myCoachesItem .myCoaches-player img {
    width: 28px;
    height: 28px;
  }
  .myCoachesItem .myCoaches-name,
  .myCoaches .myCoaches-username {
    font-size: 11px;
  }
}
.myFans {
  padding-top: 15px;
  margin-left: 0;
  margin-right: 0;
}
.myFans .block {
  margin: 5px 0;
  border: 1px solid #DBDBEA;
}
.myFans .block .header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.myFans .block .header .my-fans-link {
  color: #000;
}
.myFans .block .header .my-fans-link:hover {
  text-decoration: underline;
}
.myFans .block .content {
  background: #fff;
  padding: 0;
  padding-bottom: 10px;
}
.myFans .myFans-player {
  padding: 0 5px;
}
.myFans .myFans-player-info {
  display: table-row;
  padding-top: 4%;
}
.myFans .black-plus {
  color: #000;
  margin-top: 25%;
}
.myFans .blue-ok {
  color: #498FE1;
  margin-top: 25%;
}
.myFans .myFans-username {
  color: #9f9f9f;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .myFans .block .header {
    font-size: 14px;
  }
  .myFans .myFans-username {
    font-size: 11px;
    display: block;
  }
  .myFans .myFans-player-info {
    padding: 0 5px;
    margin-top: -5px;
    padding-top: 8%;
  }
}
.myFreestyle {
  padding-top: 15px;
  margin-left: 0;
  margin-right: 0;
}
.myFreestyle .img-circle {
  width: 40px;
  height: 40px;
  border: none;
}
.myFreestyle .block {
  border: 1px solid #DBDBEA;
  border-bottom: none;
}
.myFreestyle .block .header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  color: white;
}
.myFreestyle .block .header.Active {
  background-color: #7DD220;
}
.myFreestyle .block .header.Scored {
  background-color: #F7A722;
}
.myFreestyle .block .header.Pending {
  background-color: #ADC2E4;
  color: #498FE1;
}
.myFreestyle .leftBlock {
  float: left;
}
.myFreestyle .rightBlock {
  float: right;
}
.myFreestyle .deadline {
  padding-left: 8px;
}
.myFreestyle a {
  color: #000;
}
.myFreestyle ul {
  list-style: none;
  padding-left: 0;
}
.myFreestyle li {
  color: #000;
  font-weight: bold;
}
.myFreestyle .roundedQuad {
  border-radius: 5px;
  width: 45px;
  height: 45px;
}
.myFreestyle .active-in-my-court {
  background-color: #73ba32;
  color: #d9edc5;
}
.myFreestyle .active-freestyle-right {
  color: #73ba32;
  background-color: #fff;
}
.myFreestyle .active-in-their-court {
  background-color: #D9EDC5;
  color: #73BA32;
}
.myFreestyle .scored-freestyle-win {
  background-color: #DC9116;
  color: #fff;
}
.myFreestyle .scored-freestyle-draw {
  background-color: #D8AF5B;
  color: #DC9116;
}
.myFreestyle .scored-freestyle-right {
  color: #F09533;
  background-color: #fff;
}
.myFreestyle .pending-freestyle-text-color {
  color: #4A90E2;
}
.myFreestyle .referee-denied-text-color {
  color: white;
}
.myFreestyle .referee-denied-left-background {
  background-color: red;
}
.myFreestyle .pending-freestyle-left-background {
  background-color: #A7C4EA;
}
.myFreestyle .pending-freestyle-right {
  color: #498FE1;
  background-color: #fff;
}
.myFreestyle .referee-denied-right {
  color: red;
  background-color: #fff;
}
.myFreestyle .leftText,
.myFreestyle .challengeStatus,
.myFreestyle .name,
.myFreestyle .textAbove {
  font-size: 10px;
  word-break: break-word;
}
.myFreestyle .challengeStatus {
  margin: 0px;
  padding-left: 4px;
}
.myFreestyle .leftBlock,
.myFreestyle .rightBlock {
  padding: 3px;
  min-height: 80px;
  border-bottom: lightgray solid thin;
}
.myFreestyle .imgPadding {
  padding: 5px;
}
.myKidsDetails {
  margin: 20px 0;
}
.myKidsDetails .header {
  background: #1B1C2C;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.myKidsDetails .namesWrapper {
  font-size: 16px;
}
.myKidsDetails h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.myKidsDetails .buttonAddkid {
  padding: 20px 0;
  font-weight: bold;
  font-size: large;
}
.myKidsDetails .kid {
  padding-top: 10px;
}
.myKidsDetails .kidsWrapper {
  border: double 2px #E4E4EF;
  background-color: #fff;
  margin-bottom: 20px;
}
.myKidsDetailsBlock {
  background-color: white;
  border-bottom: 1px solid whitesmoke;
  font-size: 12px;
  min-height: 100px;
  padding: 10px 2px 15px 2px;
}
.myKidsDetailsBlock .arrowRight {
  position: relative;
  top: 22px;
}
.myKidsDetailsBlock .kidsWrapper:last-child {
  border: none;
}
.myKidsDetailsBlock:nth-of-type(even) {
  float: right;
  margin-right: 20px;
}
.myKidsDetailsBlock:nth-of-type(odd) {
  float: left;
  margin-left: 20px;
}
.myKids {
  border: 1px solid #DBDBEA;
}
.myKids .headerWrapper {
  background-color: #E4E4EF;
  padding-top: 6px;
  height: 40px;
  font-weight: bold;
}
.myKids .content {
  background: #fff;
  padding: 15px 10px;
}
.myKids .kid {
  border-bottom: 1px solid #f7f7fd;
  padding-bottom: 10px;
}
.myKids .kid:nth-last-of-type(1) {
  border-bottom: none;
}
@media (max-width: 1440px) {
  .myKids .kid {
    font-size: 12px;
  }
}
.myKids .plusButton {
  border: none;
  background: none;
}
.myKids .plusButton .plus {
  margin-right: 3px;
}
.myKids .namesWrapper {
  top: 5px;
  text-align: left;
  word-break: break-all;
}
.myKids .namesWrapper .userName {
  color: lightgray;
  font-size: 14px;
}
.myKids .my-kids-clickable {
  text-align: center;
}
.myKids .firstName:hover {
  text-decoration: underline;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .myKids .headerWrapper {
    font-size: 14px;
  }
  .myKids .namesWrapper .firstName {
    font-size: 14px;
  }
  .myKids .namesWrapper .userName {
    font-size: 11px;
  }
}
@media (min-width: 1280px) {
  .myKids {
    font-size: 16px;
  }
  .myKids .img-circle {
    width: 45px;
    height: 45px;
  }
}
.myTeams .list-item {
  padding-top: 15px;
  border: solid 1px #F0F0F0;
  border-top: none;
  background-color: #fff;
}
.myTeams .points {
  color: #fff;
  text-align: center;
  width: 20px;
  height: 20px;
  margin-top: 20px;
}
.myTeams .points.green {
  background: #7DD220;
}
.myTeams .points.grey {
  background: #CFCFCF;
}
.myTeams h4 {
  color: #000;
}
.myTeams .team-name:hover {
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .myTeams .points {
    margin-top: 0;
  }
}
.myTeamsDetails h4 {
  font-size: 16px;
  font-weight: bold;
}
.myTeamsDetails .teamName a {
  color: black;
}
.myTeamsDetails .teamInfo {
  position: relative;
  top: 10px;
}
.myTeamsDetails .back-arr {
  margin-right: 10px;
}
.myTeamsDetails .myTeamsDetailsBlock {
  background-color: white;
  border-bottom: 1px solid whitesmoke;
  padding: 10px 15px;
  font-size: 12px;
  min-height: 100px;
}
.myTeamsDetails .myTeamsDetailsBlock .members {
  border-radius: 5px;
  padding: 5px 0;
  color: white;
  text-align: center;
  position: relative;
  top: 20px;
}
.myTeamsDetails .myTeamsDetailsBlock .members.green {
  background-color: #7DD220;
}
.myTeamsDetails .myTeamsDetailsBlock .members.grey {
  background-color: #CFCFCF;
}
@media (min-width: 1280px) {
  .myTeamsDetails .myTeamsDetailsBlock .members {
    font-size: smaller;
  }
}
.myTeamsDetails .myTeamsDetailsBlock .academy {
  color: #CFCFCF;
}
.myTeamsDetails .myTeamsDetailsBlock .arrowRight {
  position: relative;
  top: 22px;
}
@media (min-width: 1441px) {
  .myTeamsDetails .myTeamsDetailsBlock .members {
    padding: 6px 14px 6px 14px;
    font-size: 12px;
  }
  .myTeamsDetails .myTeamsDetailsBlock .arrowRight {
    top: 20px;
  }
  .myTeamsDetails .myTeamsDetailsBlock .teamInfo {
    font-size: 12px;
  }
  .myTeamsDetails .myTeamsDetailsBlock h4 {
    font-size: 18px;
  }
}
.coaches-scroller {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu {
  background: #000033;
  height: 80px;
  padding-top: 10px;
}
#main-dropdown-menu {
  width: 45px;
  height: 45px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 10px;
  margin-left: -15px;
}
#main-dropdown-menu span {
  position: absolute;
  height: 5px;
  width: 100%;
  background: #498FE1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#main-dropdown-menu span.color {
  background: #2C2D45;
}
#main-dropdown-menu span:nth-child(1) {
  top: 0px;
}
#main-dropdown-menu span:nth-child(2),
#main-dropdown-menu span:nth-child(3) {
  top: 13px;
}
#main-dropdown-menu span:nth-child(4) {
  top: 25px;
}
#main-dropdown-menu.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
#main-dropdown-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#main-dropdown-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#main-dropdown-menu.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
@media (min-width: 1280px) and (max-width: 1440px) {
  #main-dropdown-menu {
    width: 36px;
    height: 36px;
    margin-top: 13px;
  }
  #main-dropdown-menu span {
    height: 3px;
  }
  #main-dropdown-menu span:nth-child(2),
  #main-dropdown-menu span:nth-child(3) {
    top: 10px;
  }
  #main-dropdown-menu span:nth-child(4) {
    top: 20px;
  }
}
.mainNavDropDown {
  padding-left: 0;
  list-style: none;
  display: none;
  position: absolute;
  z-index: 9999;
  width: 280px;
  left: 0px;
}
.mainNavDropDown li {
  background: #000033;
}
.mainNavDropDown li a {
  padding: 18px;
  display: inline-block;
  font-size: 16px;
  color: #AAAAAA;
  cursor: pointer;
  text-align: left;
}
.mainNavDropDown li span {
  top: 22px;
  right: 20px;
  color: #AAAAAA;
}
.mainNavDropDown li:hover {
  color: #AAAAAA;
}
.mainNavDropDown li.expandable:hover a {
  text-decoration: underline;
}
.mainNavDropDown .nav-sub-menu {
  margin-top: -13px;
  margin-bottom: 13px;
  padding-right: 15px;
  list-style-type: none;
}
.logo {
  position: relative;
}
.user-menu img:not(.right-arrow) {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  cursor: pointer;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .user-menu img:not(.right-arrow) {
    width: 46px;
    height: 46px;
    margin-top: 5px;
  }
}
.user-menu .user-details {
  color: #fff;
}
.user-menu .user-details .user-details-name {
  display: block;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: right;
}
.user-menu .user-details .user-details-country {
  text-transform: uppercase;
  font-size: 13px;
}
.user-menu .user-details i {
  right: -10px;
  top: 5px;
}
.user-menu .popover {
  max-width: 300px;
  margin-left: 22px;
  min-width: 300px;
}
.user-menu .popover-content {
  padding-left: 0;
  padding-right: 0;
}
.user-menu .dropdown {
  width: 300px;
}
.user-menu .dropdown .avatar-main {
  width: 50px;
  border-radius: 25px;
  height: 50px;
}
.user-menu .dropdown .avatar-small {
  width: 40px;
  border-radius: 20px;
  height: 40px;
}
.user-menu .dropdown .right-arrow {
  margin-top: 8px;
}
.user-menu .dropdown .acc-type-switcher {
  margin-bottom: 10px;
}
.user-menu .dropdown .acc-type-switcher h5 {
  color: #9E9E9E;
}
.user-menu .dropdown h4 {
  color: #000;
}
.user-menu .dropdown h5 {
  margin-top: 5px;
  margin-left: 5px;
}
.user-menu .dropdown button {
  width: 100%;
  margin-top: 20px;
}
.nav-notifications {
  background: url('../img/red_cloud.png') no-repeat;
  width: 26px;
  height: 24px;
  position: absolute;
  margin-left: -10px;
  margin-top: 0px;
  text-align: center;
  color: #fff;
}
.logo {
  width: 100%;
  max-width: 170px;
}
.parent-verification {
  top: 180px;
  background-color: #F2F2F2;
}
.parent-verification .info-text {
  margin-top: 5%;
  margin-bottom: 3%;
}
.parent-verification input {
  width: 100%;
  height: 30px;
  font-size: 16px;
}
.parent-verification .or {
  margin: 3% 0;
  color: #AEA9A7;
}
.parent-verification .btn-primary {
  margin: 5% 0;
}
.parent-verification .danger {
  position: absolute;
  font-size: 10px;
  color: #b94a48;
}
.password-forms .coach-content-bg {
  min-height: 650px;
}
.password-forms .invalid-input {
  border-color: red;
  color: red;
}
.password-forms .warning-wrapper {
  height: 45px;
}
.password-forms .warning-wrapper span {
  color: #fff;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  text-align: left;
  font-size: 11px;
}
.password-forms .topya-logo {
  width: 150px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.password-forms .confirmation-message {
  display: inline-block;
}
.password-forms .forgot-password-text {
  color: #fff;
  margin-bottom: 30px;
  font-size: larger;
}
.password-forms .btn {
  height: 40px;
  width: auto;
}
.password-forms input {
  padding-left: 50px;
  width: 100%;
  height: 70px;
}
.password-forms .forgot-password-email {
  background: url(../img/password-icon.png) no-repeat 3% 45% #fff;
}
.password-forms .password {
  background: url(../img/password-icon.png) no-repeat 3% 45% #fff;
}
.password-forms .email-wrapper {
  margin-bottom: 20px;
}
.password-forms .email-wrapper,
.password-forms .forgot-password-button-wrapper {
  margin: 0 auto;
  float: none;
}
.password-forms .forgot-password-title {
  margin: 20px 0;
  color: #fff;
}
.password-forms .forgot-password-button-wrapper {
  margin-top: 20px;
}
.password-forms .forgot-password-error-wrapper {
  margin-top: 30px;
}
.password-forms.mobile .forgot-password-title {
  font-size: 30pt;
}
.password-forms.mobile .confirmation-message .ok-button {
  max-width: 330px;
}
.password-forms.mobile .coach-content-bg {
  margin: 0;
  height: 100vh;
}
.password-forms.mobile input {
  font-size: 25pt;
  height: 50pt;
  background-size: 25pt;
  background-position-x: 5pt;
  background-position-y: 10pt;
  margin-top: 20pt;
}
.password-forms.mobile .btn {
  font-size: 25pt;
  height: auto;
  width: 100%;
  margin: 10pt 0;
}
.password-forms.mobile .forgot-password-text {
  font-size: 25pt;
}
.password-forms.mobile .forgot-password-error-wrapper,
.password-forms.mobile .forgot-password-error-wrapper .close {
  font-size: 20pt;
}
.password-forms.mobile .warning-wrapper span {
  font-size: 15pt;
}
.password-forms.mobile .topya-logo {
  width: 40%;
  margin-top: 50px;
  margin-bottom: 180px;
}
.skills-academy .path-details .back-arr {
  margin-left: 10px;
}
.skills-academy .path-details h6 {
  color: #000;
}
.skills-academy .path-details .organization-logo {
  width: 10%;
  display: inline-table;
  vertical-align: middle;
}
.skills-academy .path-details .organization-logo .logo-holder {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.skills-academy .path-details .organization-logo .logo-holder img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.skills-academy .path-details .organization-name {
  width: 80%;
  margin-left: 2%;
  display: inline-table;
}
.skills-academy .path-details .path-name {
  width: 100%;
  font-weight: bold;
  font-size: larger;
}
.skills-academy .path-details .error {
  margin-top: 20px;
}
.skills-academy .path-details .edit-button,
.skills-academy .path-details .description-field,
.skills-academy .path-details .status {
  margin-top: 10px;
}
.skills-academy .path-details .organization {
  height: 140px;
}
.skills-academy .path-details .right-border {
  overflow-x: hidden;
  border-right: 1px solid #DBDBEA;
}
.skills-academy .path-details .assign-path {
  padding-top: 10px;
}
.skills-academy .path-details .assign-path .assign-img {
  vertical-align: text-top;
}
.skills-academy .path-details .skills-list {
  margin-top: 30px;
  padding: 0;
}
.skills-academy .path-details .skills-list .item {
  margin-top: 10px;
  padding: 10px;
}
.skills-academy .path-details .skills-list .item:hover {
  background-color: #deeff5;
}
.skills-academy .path-details .skills-list .published {
  color: #72A936;
}
.skills-academy .path-details .skills-list .pending {
  color: #F8B32C;
}
.skills-academy .path-details .skills-list .approved {
  color: dodgerblue;
}
.skills-academy .path-details .skills-list .edits-req {
  color: #DA1F22;
}
.skills-academy .path-details .skills-list .alert {
  margin-top: 20px;
}
@media (max-width: 1360px) {
  .skills-academy .path-details h5 {
    font-size: 12px;
  }
}
.skills-academy .path-details .border-edit {
  border: 1px solid #DBDBEA;
}
.skills-academy .path-details .border-default {
  border: 1px solid #fff;
}
.skills-academy .path-details .path-buttons-holder {
  padding: 0;
}
.skills-academy .path-details .path-buttons-holder .row {
  height: 72px;
  position: relative;
}
.skills-academy .path-details .path-name-input {
  width: 100%;
  height: auto;
  font-weight: bold;
  padding-left: 0px;
}
.skills-academy .path-details .alert-info {
  margin: 10px 0;
}
.skills-academy .path-details .delete-skill {
  color: #E35659;
}
.skills-academy .path-details input:disabled {
  opacity: 0.8;
  border-color: #fff;
  background-color: inherit;
}
.skills-academy .path-details .panel-default {
  margin: 10px 0;
  border-radius: 0;
}
.skills-academy .path-details .ui-tree-clickable {
  display: inline-block;
  width: calc(100% - 29px);
  padding: 10px 0;
}
.skills-academy .path-details .ui-tree-clickable:hover h5 {
  text-decoration: underline;
}
.skills-academy .path-details .angular-ui-tree .tree-node.no-vert-padding {
  padding: 0 10px;
}
.skills-academy .path-details .angular-ui-tree .btn {
  width: 24px;
}
.skills-academy .path-details .angular-ui-tree.no-drag .angular-ui-tree-handle.pointer {
  cursor: pointer;
}
.skills-academy .path-details .angular-ui-tree.no-drag .angular-ui-tree-handle.pointer:hover h5 {
  text-decoration: underline;
}
.teams-popover {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  text-align: center;
  min-width: 250px;
}
.teams-popover input {
  border: 1px solid #DAD5D5;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: small;
  padding-left: 20px;
  height: 25px;
  border-radius: 4px;
  border-width: 2px;
  background-image: url(/img/magnifer_icon.png);
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 3px;
}
.teams-popover input:disabled {
  opacity: initial;
  border-color: #DAD5D5;
  background-color: #ebebe4;
}
.teams-popover label h6,
.teams-popover .team-name {
  color: #000;
  font-weight: normal;
}
.teams-popover .filter {
  margin-top: 10px;
  overflow: hidden;
}
.teams-popover .team-loader {
  color: dodgerblue;
  font-weight: bold;
}
.teams-popover .search {
  padding-left: 5px;
  padding-right: 5px;
}
.teams-popover hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.teams-popover .sort {
  color: #A5A5A5;
}
.teams-popover .sort .disabled {
  opacity: 0.7;
  cursor: default;
}
.teams-popover .teams-list {
  margin-bottom: 10px;
  max-height: 350px;
  overflow-y: auto;
}
.teams-popover .teams-list .item {
  text-align: left;
  color: #A5A5A5;
  margin-bottom: 10px;
  min-height: 40px;
  padding: 5px 5px 5px 0;
  cursor: default;
}
.teams-popover .teams-list .item > div.col-xs-9 {
  padding-left: 0;
}
.teams-popover .teams-list .item:hover {
  background-color: #f7f7fd;
}
.teams-popover .reset {
  margin-top: 20px;
}
.teams-popover ul {
  list-style-type: none;
  padding-left: 0;
}
.teams-popover .coach-popover {
  max-height: 100px;
  overflow-y: auto;
}
.teams-popover h6 {
  color: dodgerblue;
  font-weight: bold;
}
.teams-popover h6 span {
  margin-top: 5px;
  display: inline-block;
}
.teams-popover .select-all-btn {
  margin-bottom: 20px;
}
.paths-list {
  padding: 0;
}
.paths-list .topya-main-page-content hr {
  border-top: 1px solid #DAD5D5;
}
.paths-list .path-name {
  overflow-x: hidden;
}
.paths-list .path-item {
  padding: 10px 0;
  height: 80px;
}
.paths-list .path-item:hover {
  cursor: pointer;
  background-color: #f7f7fd;
}
.payment input[type="month"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  height: 40pt;
}
.payment input[type="month"].isAndroid:before {
  content: attr(placeholder) !important;
  color: #999;
}
.payment input[type="month"].isAndroid:focus:before {
  content: '' !important;
}
.payment input[type="month"].isIOS:after {
  content: attr(placeholder) !important;
  color: #999;
}
.register-new .payment input {
  border-radius: 0;
  margin-top: 20px;
  width: 100%;
}
.register-new .payment input:disabled {
  opacity: 0.7;
}
.register-new .payment input.background-padding {
  padding-left: 30px;
}
.register-new .payment .form-group .input-background-image {
  position: absolute;
  top: 6px;
  width: 20px;
  left: 5px;
}
.register-new .payment button {
  white-space: normal;
}
.register-new .payment .logo {
  margin-top: 40px;
  margin-bottom: 25px;
}
.register-new .payment .help-icon {
  position: absolute;
  right: -1px;
  top: 0;
  width: 30px;
  height: 30px;
}
.register-new .payment .help-icon:hover {
  opacity: 0.8;
}
.register-new .payment .security-image {
  width: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
}
.register-new .payment .animate-if.ng-enter,
.register-new .payment .delete-skill-inner.animate-if.ng-leave {
  transition: all linear 0.2s;
}
.register-new .payment .animate-if.ng-leave-active {
  width: 0;
  padding: 5px 0;
  overflow: hidden;
  margin-left: 100%;
}
.register-new .payment .marginBottom {
  margin-bottom: 15px;
}
.register-new .payment .skip-link {
  cursor: pointer;
}
.is-mobile .register-new .payment .help-icon {
  height: 8vw;
  width: 8vw;
  right: -2px;
}
.is-mobile .register-new .payment input {
  margin-top: 15%;
}
.is-mobile .register-new .payment input.background-padding {
  background-size: 5vw;
  background-position-y: 2vw;
  padding-left: 7vw;
}
.is-mobile .register-new .payment .form-group .input-background-image {
  position: absolute;
  top: 11pt;
  width: 6%;
  left: 1%;
}
.teamPlayers .back-arr {
  margin-right: 10px;
  cursor: pointer;
}
.teamPlayers .playerDetails .big_img {
  width: 102px;
  height: 102px;
}
.teamPlayers .centered div,
.teamPlayers .centered span {
  text-align: center;
}
.teamPlayers .achievements {
  margin-top: 20px;
}
.teamPlayers .achievements-numbers,
.teamPlayers .settingsIcon {
  margin-top: 10px;
}
.teamPlayers .clicked {
  color: #488EE0;
}
.teamPlayers .periods .span {
  cursor: pointer;
}
.teamPlayers .blackText {
  color: black;
}
.teamPlayers .greyText {
  color: #b1b1bd;
  padding-bottom: 3px;
}
.teamPlayers .skill-paths-content,
.teamPlayers .heading {
  border-top: 1px solid #eeeef2;
  font-weight: bold;
}
.teamPlayers .skill-paths-content {
  padding: 15px 10px;
}
.teamPlayers .heading {
  padding: 15px;
}
.teamPlayers .arrow {
  height: 24px;
  width: 15px;
}
.teamPlayers .percents-wrapper {
  position: relative;
  top: 7px;
}
.teamPlayers .arrow-wrapper {
  position: relative;
  top: 8px;
}
.teamPlayers .skills-wrapper {
  background-color: #f7f7fd;
  margin-bottom: 20px;
  max-height: 601px;
  overflow-x: hidden;
  overflow-y: auto;
}
.teamPlayers .skills-wrapper .skill-paths-content {
  border-bottom: 1px solid #DBDBEA;
  margin-bottom: 10px;
}
.teamPlayers .skillImg {
  max-width: 100%;
  max-height: 200px;
  margin-top: 10px;
}
.teamPlayers .skillDetails {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: #eeeef2 1px solid;
}
.teamPlayers .skillDetails span {
  font-weight: bold;
  padding-left: 10px;
}
.teamPlayers .skillDetails .btn.btn-primary {
  width: auto;
}
.teamPlayers .playerFullName {
  font-weight: bold;
}
.teamPlayers .playerUsername {
  color: #b1b1bd;
}
@media (min-width: 900px) and (max-width: 1281px) {
  .teamPlayers .achievements-numbers h3 {
    font-size: 18px;
  }
}
@media (min-width: 1282px) and (max-width: 1442px) {
  .teamPlayers .achievements-numbers h3 {
    font-size: 20px;
  }
}
.playerDetails .removePlayer {
  color: #DBDBEA;
}
.playerDetails .removePlayer:hover {
  color: red;
}
.remove-player {
  display: none;
}
.remove-wrapper:hover .remove-player {
  display: inline;
  color: red;
  font-size: smaller;
  margin-left: 2px;
}
.delete-player-button {
  height: 40px;
}
.delete-player-blocks {
  margin: 20px;
}
.new-team {
  border: 1px solid #DAD5D5;
  text-align: center;
  background-color: #F7F7FD;
  padding-top: 5%;
}
.new-team .edit-team-name {
  text-align: left;
}
.new-team .edit-team-name h5 {
  margin-left: 5%;
}
.new-team .edit-team-name input {
  width: 100%;
  height: 30px;
  border-color: #C3C3CA;
}
.new-team .edit-team-logo h5 {
  text-align: left;
  margin-left: 8%;
}
.new-team button {
  width: 100%;
}
.new-team .top-button {
  margin-top: 10%;
}
.new-team .img-holder {
  width: 100%;
  padding-bottom: 100%;
  background-color: #454656;
  position: relative;
  margin-bottom: 10%;
  margin-top: 2%;
}
.new-team .team-logo {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.new-team .gallery-upload {
  margin-top: 35%;
  cursor: pointer;
}
.new-team .gallery-upload:hover {
  text-decoration: underline;
}
.new-team .bottom-button {
  margin-top: 90%;
}
.new-team .error-message {
  text-align: left;
}
@media (max-width: 1023px) {
  .new-team button {
    font-size: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .new-team .top-button {
    margin-top: 43%;
  }
}
.playerSkillsAcademy .back-arr {
  margin-right: 10px;
}
.playerSkillsAcademy .round-image-holder {
  width: 90%;
  margin-left: 10%;
}
.playerSkillsAcademy .content-wrapper {
  border: 2px solid #DBDBEA;
  background-color: white;
}
.playerSkillsAcademy .skills-type-wrapper {
  padding: 20px;
}
.playerSkillsAcademy .skills-type-wrapper .skills-type {
  padding: 10px;
  text-align: center;
  font-weight: bold;
}
.playerSkillsAcademy .skills-type-wrapper .chosenType {
  color: #498FE1;
}
.playerSkillsAcademy .skillsAcademyPaths .blackText {
  margin-bottom: 0px;
  font-size: large;
  color: black;
  font-weight: bold;
}
.playerSkillsAcademy .skillsAcademyPaths .pathSkillsCount {
  padding: 5px 0;
  color: #498FE1;
}
.playerSkillsAcademy .skillsAcademyPaths .videoContent {
  margin-top: 10px;
}
.playerSkillsAcademy .skillsAcademyPaths .heading {
  padding: 10px;
}
.playerSkillsAcademy .skillsAcademyPaths .heading .blackText {
  padding-top: 10px;
  padding-bottom: 10px;
}
.playerSkillsAcademy .skillsAcademyPaths .coach-img {
  width: 100%;
}
.playerSkillsAcademy .skill-badge {
  width: 100%;
}
.playerSkillsAcademy .skillsWrapper .skill {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}
.playerSkillsAcademy .skillsWrapper .skill span {
  position: relative;
  top: 4px;
}
.playerSkillsAcademy .skillsWrapper .skill .red-color {
  color: red;
  font-weight: bold;
}
.playerSkillsAcademy .skillsWrapper .skill .gray-color {
  color: gray;
  font-weight: bold;
}
.playerSkillsAcademy .skillsWrapper .skill .opacity-30 {
  opacity: 0.3;
}
.playerSkillsAcademy .skillInformation {
  padding: 15px 0;
}
.playerSkillsAcademy .skillInformation .skill-name {
  font-weight: bold;
  margin-top: 4px;
}
.playerSkillsAcademy .gray-text {
  font-weight: bold;
}
.playerSkillsAcademy .skillDetails .skill-status {
  display: block;
}
.playerSkillsAcademy .skillDetails .skill-description {
  text-align: justify;
  display: block;
  margin-bottom: 20px;
}
.playerSkillsAcademy .skillDetails .skill-status-holder {
  margin-bottom: 20px;
}
.playerSkillsAcademy .skillDetails span {
  padding-top: 5px;
  font-weight: bold;
}
.playerSkillsAcademy .skill-wrapper {
  margin: 10px 0;
}
.playerSkillsAcademy .clickable:hover {
  text-decoration: underline;
}
.playerSkillsAcademy .red-points {
  top: 3px;
  color: #CF011A;
  padding: 0;
}
.playerSkillsAcademy ul {
  list-style: none;
}
.playerSkillsAcademy .upload-video-link {
  color: dodgerblue;
  text-align: left;
}
.playerSkillsAcademy .nav > li > a {
  padding-left: 0;
  padding-right: 0;
}
.playerSkillsAcademyNew .gray-background {
  background-color: #E4E4EF;
}
.playerSkillsAcademyNew .go-to-store {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
}
.playerSkillsAcademyNew .skills-type-wrapper .second-dropdown {
  max-height: 150px;
  min-height: 100px;
  height: auto;
}
.playerSkillsAcademyNew .skills-type-wrapper .dropdown-menu {
  min-width: 120px;
  width: auto;
}
.playerSkillsAcademyNew .recommend-path-text-wrapper {
  padding: 15px;
  background-color: #E7EDF8;
}
.playerSkillsAcademyNew .recommend-path-text-wrapper .round-image-holder {
  background-color: #ced7e6;
}
.playerSkillsAcademyNew .coach-info {
  padding: 15px;
}
.playerSkillsAcademyNew .coach-name {
  font-weight: bold;
}
.playerSkillsAcademyNew .recommend-text {
  color: #989899;
}
.playerSkillsAcademyNew .green-path,
.playerSkillsAcademyNew .coach-info {
  background-color: #e0f0d0;
}
.playerSkillsAcademyNew .green-path .round-image-holder,
.playerSkillsAcademyNew .coach-info .round-image-holder {
  background-color: #b9ceaa;
}
.playerSkillsAcademyNew .skillsCount {
  color: #989899;
}
.playerSkillsAcademyNew .pathName {
  position: relative;
  top: 10px;
}
.playerSkillsAcademyNew .path-name:hover {
  text-decoration: underline;
  font-weight: bold;
}
.playerSkillsAcademyNew .arrow {
  position: relative;
}
.playerSkillsAcademyNew .coach-name-wrapper {
  position: relative;
  top: 10px;
}
.playerSkillsAcademyNew .gray-text {
  color: #989899;
  margin-bottom: 5px;
}
.playerSkillsAcademyNew .progress-bar-wrapper {
  position: relative;
  top: 15px;
}
.playerSkillsAcademyNew .dropdown-wrapper {
  margin: auto;
  float: none;
  display: block;
}
.playerSkillsAcademyNew .gray-dropdown a {
  color: lightgrey;
}
.playerSkillsAcademyNew .skill {
  border-top: 1px solid #eeeeee;
}
.playerSkillsAcademyNew .pathDescription {
  margin-top: 10px;
  margin-bottom: 10px;
  color: darkgray;
}
.playerSkillsAcademyNew > .nav > li > a:hover,
.playerSkillsAcademyNew .nav > li > a:focus,
.playerSkillsAcademyNew .nav .open > a,
.playerSkillsAcademyNew .nav .open > a:hover,
.playerSkillsAcademyNew .nav .open > a:focus {
  background-color: white;
}
.assigned-by {
  background-color: #E7EDF8;
  padding: 10px;
  font-size: 16px;
  text-align: left;
  border-radius: 0px;
  color: black;
}
.player-team .arrow-left {
  padding-top: 7%;
  text-align: center;
}
.player-team .team-img {
  margin-top: 10%;
}
.player-team .img-holder {
  padding-bottom: 100%;
  width: 100%;
  position: relative;
}
.player-team .team-logo {
  width: 100%;
  height: 100%;
  position: absolute;
}
.player-team .team-name {
  margin-top: 10%;
  word-wrap: break-word;
}
.player-team .team-status {
  color: #9f9f9f;
}
.player-team .user-icon {
  width: 20px;
  margin-left: 5px;
  margin-top: 3px;
}
.player-team .edit-button {
  margin-top: 30%;
}
.player-team .invite-label {
  text-align: right;
}
.player-team .invite-label h5 {
  margin-top: 7px;
}
.player-team input {
  width: 100%;
  border: solid 2px #DBDBEA;
  border-radius: 5px;
  height: 30px;
  background-image: url('../img/magnifer_icon.png');
  background-repeat: no-repeat;
  background-position: left 7px center;
  padding-left: 30px;
}
.player-team .team-members-count {
  text-align: center;
}
.player-team .team-members-count h5 {
  display: inline-block;
  margin-top: 7px;
}
.player-team .team-members-count img {
  display: inline;
}
.player-team .member {
  padding: 5% 0;
  margin-bottom: 5px;
}
.player-team .member .btn {
  margin-top: 10%;
}
.player-team .avatar {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.player-team .username {
  margin-top: 7%;
}
.player-team .player-status {
  color: #9f9f9f;
}
.player-team .invite-button {
  background-color: #93CB52;
  border-color: #A2D26A;
}
.player-team .fans-holder {
  max-height: 250px;
  overflow-y: auto;
}
@media (max-width: 1023px) {
  .player-team .team-name {
    font-size: 12px;
  }
  .player-team .edit-button {
    font-size: 10px;
  }
  .player-team .btn {
    height: 40px;
  }
  .player-team .invite-label h5 {
    font-size: 10px;
  }
}
.product-details .arrow-left {
  transform: translateY(30%);
}
.product-details .skill {
  margin-bottom: 10px;
}
.product-details .skill-text {
  padding-top: 1%;
  font-weight: bold;
}
.product-details .skill-text span {
  margin-left: 5px;
  color: #CF011A;
}
.product-details .path-image {
  max-height: 58px;
  max-width: 100%;
}
.product-details .skills-count {
  color: dodgerblue;
}
.product-details .path-name {
  transform: translateY(15%);
  padding-left: 0;
  padding-right: 0;
}
.product-details .price-holder {
  transform: translateY(20%);
}
.product-details .video-parent {
  margin-bottom: 30px;
  width: 100%;
  padding-bottom: 56.2%;
  height: 0;
  position: relative;
  background-color: #30303F;
}
.product-details .description {
  color: #9D9D9D;
  margin-bottom: 30px;
}
.product-details .price {
  border: 1px solid dodgerblue;
  color: dodgerblue;
  padding: 5px 0;
  text-align: center;
}
.product-details .path-text h5 {
  color: #9D9D9D;
  margin-top: 45px;
  margin-bottom: 5px;
}
.product-details .path-text h6 {
  color: dodgerblue;
  margin-top: 5px;
}
.product-details .path-arrow-right {
  margin-top: 65px;
}
.product-details .arrow-left-skill {
  padding-top: 2%;
}
.product-details .skill h5 {
  color: #9D9D9D;
}
.product-details .skill h5,
.product-details .skill h4 {
  margin-bottom: 10px;
}
.product-details .skill .lower-description {
  margin-top: 40px;
  margin-bottom: 40px;
}
.product-details .skill span b {
  margin-left: 5px;
}
.product-details .skill .virtual-coach-pic {
  position: absolute;
}
.product-details button {
  width: 100%;
  border-radius: 0;
}
.product-details .btn-primary {
  border-color: #DAE8F9;
  background-color: dodgerblue;
}
.product-details input {
  height: auto;
  width: 100%;
  margin-top: 1px;
  border: 1px solid dodgerblue;
  background-image: url('../img/magnifier_blue.png');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  padding-right: 30px;
}
.product-details .bundle-header {
  background-color: #F7F7FD;
  padding-top: 15px;
  padding-bottom: 45px;
  margin-top: 20px;
}
.product-details .skill-header {
  margin-bottom: 30px;
}
.product-details .recommend-dropdown {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
}
.product-details .recommend-dropdown .plus {
  width: 100%;
  max-width: 25px;
}
.product-details .recommend-dropdown .team-item {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F0F0F0;
}
.product-details .recommend-dropdown .team-item:hover {
  background-color: #F7F7FD;
}
.product-details .popover {
  min-width: 400px;
}
.product-details .recommend-dropdown h6 {
  color: #9D9D9D;
}
.product-details .recommend-dropdown .col-xs-9 {
  max-height: 80px;
  overflow-y: auto;
}
.product-details .recommend-dropdown .team-header {
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-details .recommend-dropdown .team-header h5,
.product-details .recommend-dropdown .team-header .col-xs-1 img {
  margin-top: 10px;
}
.product-details .recommend-dropdown .player-item {
  background-color: #F7F7FD;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F0F0F0;
}
.product-details .recommend-dropdown .scroll-holder {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}
.product-details .vid-top-margin {
  margin-top: 30px;
}
.recommend-dropdown .busy-opacity {
  opacity: 0.5;
}
public-video > div videogular vg-scrub-bar .container,
public-video > div [videogular] vg-scrub-bar .container {
  padding: 0;
}
public-video > div videogular vg-scrub-bar-current-time,
public-video > div [videogular] vg-scrub-bar-current-time {
  height: 2px;
  padding: 0;
}
public-video > div topya-video-component > div > div {
  position: fixed;
  height: 100vh;
  width: 100vw;
}
.register-new,
.register {
  background-color: #003;
  text-align: center;
  margin-bottom: 10%;
  width: 100%;
  min-height: 800px;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  color: #eee;
  max-width: 550px;
}
.register-new .list-loading-overlay,
.register .list-loading-overlay {
  background: rgba(0, 0, 51, 0.65);
}
.register-new ::-webkit-scrollbar,
.register ::-webkit-scrollbar {
  width: 3px;
  padding-right: 20px !important;
  margin: auto !important;
}
.register-new ::-webkit-scrollbar-track,
.register ::-webkit-scrollbar-track {
  border-radius: 4px;
}
.register-new ::-webkit-scrollbar-thumb,
.register ::-webkit-scrollbar-thumb {
  background: lightgray;
}
.register-new ::-webkit-scrollbar-thumb:window-inactive,
.register ::-webkit-scrollbar-thumb:window-inactive {
  background: lightgray;
}
@media (max-width: 1199px) {
  .register-new,
  .register {
    min-height: 830px;
  }
}
.register-new button:disabled,
.register button:disabled,
.register-new button:disabled:hover,
.register button:disabled:hover {
  border-color: #888C88;
  background-color: #9B9B9B;
}
.register-new .valid-input-img,
.register .valid-input-img {
  height: 25px;
  position: absolute;
  right: -30px;
  top: 33px;
}
.register-new .alert a,
.register .alert a {
  color: #a94442;
  cursor: pointer;
}
.register-new h5,
.register h5 {
  line-height: 19px;
}
.register-new a,
.register a {
  color: #eee;
}
.register-new a.disabled-item,
.register a.disabled-item {
  cursor: default;
}
.register-new .payment,
.register .payment {
  width: 85%;
  margin-left: 7.5%;
}
.register-new input,
.register input {
  font-size: 16px;
  color: #000;
}
.register-new input.ng-invalid.ng-touched:not(:focus),
.register input.ng-invalid.ng-touched:not(:focus) {
  border: 1px solid red;
}
.register-new input:-webkit-autofill,
.register input:-webkit-autofill,
.register-new input:-webkit-autofill:hover,
.register input:-webkit-autofill:hover,
.register-new input:-webkit-autofill:focus,
.register input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.register-new input:not([type="radio"]),
.register input:not([type="radio"]) {
  width: 100%;
  height: 30px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
}
.register-new input:focus,
.register input:focus {
  border: 1px solid dodgerblue;
}
.register-new .login-form input,
.register .login-form input {
  padding-left: 40px;
}
.register-new .marginTopLarge,
.register .marginTopLarge {
  margin-top: 45px;
}
.register-new .margin-top-30,
.register .margin-top-30 {
  margin-top: 30px;
}
.register-new .marginBottom,
.register .marginBottom {
  margin-bottom: 10%;
}
.register-new .marginBottomSmall,
.register .marginBottomSmall {
  margin-bottom: 10px;
}
.register-new .marginTopSmall,
.register .marginTopSmall {
  margin-top: 15px;
}
.register-new .cropper,
.register .cropper {
  height: auto;
  width: 20%;
  border-radius: 50%;
}
.register-new .parent,
.register .parent {
  padding-top: 3%;
  padding-bottom: 3%;
}
.register-new .complete-profile-input,
.register .complete-profile-input {
  background-repeat: no-repeat;
  padding-left: 50px;
  background-position: 10px 10px;
  height: 50px;
  border-left: solid 1px #fff;
  border-right: solid 1px #fff;
  border-top: solid 1px #ECECFB;
  border-bottom: solid 1px #ECECFB;
}
.register-new .or,
.register .or {
  margin-top: 20px;
  margin-bottom: 20px;
}
.register-new input.complete-profile-input,
.register input.complete-profile-input {
  background-repeat: no-repeat;
  background-position: 10px 7px;
  border: 1px solid #ECECFB;
  height: 50px;
  padding-left: 50px;
}
.register-new #parents-email,
.register #parents-email {
  background-image: url(/img/parents_text.png);
  background-position: 10px 15px;
}
.register-new #parents-phone,
.register #parents-phone {
  background-image: url(/img/tel.png);
}
.register-new .header,
.register .header {
  padding-top: 25px;
}
.register-new .error-message,
.register .error-message {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.register-new .continue-to-topya .success-notification,
.register .continue-to-topya .success-notification {
  margin-bottom: 25px;
}
.register-new .danger,
.register .danger {
  position: absolute;
  margin-top: 2px;
  font-size: 10px;
}
.register-new .back-link,
.register .back-link {
  text-align: left;
  padding-top: 10px;
  height: 30px;
}
.register-new .back-link a,
.register .back-link a {
  cursor: pointer;
}
.register-new .gender-dropdown h5,
.register .gender-dropdown h5 {
  color: #000;
}
.register-new .gender span,
.register .gender span {
  display: inline-flex;
}
.register-new .gender span:not(.pull-left),
.register .gender span:not(.pull-left) {
  margin: 0 10px;
}
.register-new .gender label,
.register .gender label {
  margin-bottom: 0;
  vertical-align: top;
}
.register-new .gender input,
.register .gender input {
  margin-left: 5px;
}
.register-new.additional-selection .org-logo,
.register.additional-selection .org-logo {
  width: 100%;
}
.register-new.additional-selection .additional-selection-item,
.register.additional-selection .additional-selection-item {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #81819b;
}
.register-new.additional-selection .additional-selection-item h4,
.register.additional-selection .additional-selection-item h4 {
  float: left;
  max-width: calc(100% - 30px);
}
.register-new.additional-selection .skip-link,
.register.additional-selection .skip-link {
  color: #fff;
  font-size: 18px;
  display: block;
}
.register-new.additional-selection .btn,
.register.additional-selection .btn {
  margin-bottom: 30px;
  font-size: 18px;
}
.register-new .animation-if.ng-enter,
.register .animation-if.ng-enter,
.register-new .animation-if.ng-leave,
.register .animation-if.ng-leave {
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: hidden;
  max-height: 0;
}
.register-new .animation-if.ng-enter,
.register .animation-if.ng-enter,
.register-new .animation-if.ng-leave.ng-leave-active,
.register .animation-if.ng-leave.ng-leave-active {
  overflow: hidden;
  max-height: 0;
}
.register-new .animation-if.ng-leave,
.register .animation-if.ng-leave,
.register-new .animation-if.ng-enter.ng-enter-active,
.register .animation-if.ng-enter.ng-enter-active {
  max-height: 200px;
}
.margin-top-40px {
  margin-top: 40px;
}
.register.is-mobile {
  min-height: 100vh;
  margin: 0;
  max-width: none;
}
.register.is-mobile .back-link {
  height: 35pt;
}
.register.is-mobile .btn {
  font-size: 25pt;
}
.register.is-mobile .valid-input-img {
  height: 25%;
  right: -7%;
  top: 68%;
}
.register.is-mobile .back-link a,
.register.is-mobile a.tos-link {
  font-size: 25pt;
}
.register.is-mobile .login-form button {
  height: auto;
}
.register.is-mobile input:not([type="radio"]) {
  height: 40pt;
  font-size: 30pt;
}
.register.is-mobile input::-webkit-input-placeholder {
  font-size: 18pt;
  padding-top: 6pt;
}
.register.is-mobile input::-moz-placeholder {
  font-size: 18pt;
  padding-top: 6pt;
}
.register.is-mobile input:-ms-input-placeholder {
  font-size: 18pt;
  padding-top: 6pt;
}
.register.is-mobile input:-moz-placeholder {
  font-size: 18pt;
  padding-top: 6pt;
}
.register.is-mobile input[type="radio"] {
  width: 2em;
  height: 2em;
}
.register.is-mobile .gender label,
.register.is-mobile .gender span.pull-left {
  font-size: 20pt;
}
.register.is-mobile input[id="inviteCode"] {
  background-size: contain;
}
.register.is-mobile h5 {
  font-size: 25pt;
  line-height: 30pt;
}
.register.is-mobile h4 {
  font-size: 28pt;
  line-height: 55pt;
}
.register.is-mobile h3 {
  font-size: 30pt;
}
.register.is-mobile ul.dropdown-menu {
  font-size: 25pt;
}
.register.is-mobile .margin-top-30 {
  margin-top: 15%;
}
.register.is-mobile .danger {
  font-size: 15pt;
}
.register.is-mobile .complete-profile-input {
  margin-top: 15%;
}
.register.is-mobile .error-message {
  font-size: 25pt;
}
.register.is-mobile .status-msg {
  font-size: 25pt;
}
.register.is-mobile h6 {
  font-size: 20pt;
}
.register.is-mobile ol {
  font-size: 25pt;
}
.register.is-mobile a.btn-success {
  margin-bottom: 20pt;
}
.register.is-mobile #parents-phone {
  margin-top: 0;
}
.register.is-mobile .or {
  margin-top: 6%;
  margin-bottom: 6%;
}
.register.is-mobile.additional-selection .additional-selection-item .selection-icon {
  width: 30pt;
  height: 30pt;
  margin-top: 15pt;
}
.register.is-mobile.additional-selection .skip-link {
  font-size: 25pt;
}
.register.is-mobile a {
  font-size: 25px;
}
.register.is-mobile .btn-warning {
  margin-top: 5%;
}
.register.is-mobile .continue-to-topya p {
  font-size: 25pt;
}
.register-new.pad-top-30 {
  padding-top: 30px;
}
signup > div:not(.mobile) {
  width: 100%;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
signup > div:not(.mobile) > div {
  width: 50%;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}
signup > div:not(.mobile) > div.left-column {
  padding: 56px 120px 0 152px;
  overflow-y: auto;
}
@media (max-width: 1199px) {
  signup > div:not(.mobile) > div.right-column {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 265px;
  }
  signup > div:not(.mobile) > div.left-column {
    margin-top: 265px;
    padding: 56px 120px 0 120px;
    width: 100%;
    overflow-y: visible;
    max-width: 768px;
    margin-left: calc(50% - 384px);
  }
}
@media (max-width: 767px) {
  signup > div:not(.mobile) > div.left-column {
    padding: 56px 32px;
    margin-left: 0;
  }
}
@media (max-width: 445px) {
  signup > div:not(.mobile) > div.left-column {
    padding: 56px 8px;
  }
}
signup > div.mobile {
  width: 100%;
}
signup > div.mobile > div.form-holder {
  padding: 10vw 6.25vw;
}
.skill-details .topya-main-page-content .review-message {
  margin-top: 30px;
}
.skill-details .topya-main-page-content input {
  font-weight: bold;
  color: #777777;
  width: 100%;
  margin: 0;
  height: 50px;
  padding: 0;
  border: 1px solid #DBDBEA;
}
.skill-details .topya-main-page-content input:disabled {
  color: #000;
  border: 1px solid #fff;
  background-color: inherit;
}
.skill-details button {
  width: 100%;
}
.skill-details .button-div {
  padding: 0;
  margin-bottom: 20px;
}
.skill-details .skill-header {
  margin-top: 20px;
  padding: 0;
}
.skill-details .skill-header h4,
.skill-details .skill-header h6 {
  color: #fff;
}
.skill-details .skill-header .edits {
  background-color: #DA1F22;
}
.skill-details .skill-header .approved {
  background-color: #11B0C0;
}
.skill-details .skill-header .saved {
  background-color: #1CA4B4;
}
.skill-details .skill-header .pending {
  background-color: #F8B32C;
}
.skill-details .skill-header .published {
  background-color: #72A936;
}
.skill-details .path-header {
  padding-left: 0;
}
.skill-details .edits-comment {
  color: #DA1F22;
}
.skill-details .approved-comment {
  color: dodgerblue;
}
.skillPaths .list-item {
  padding: 10px 15px;
}
.skillPaths .list-item:hover {
  background-color: #E4E4EF;
}
.skillPaths .skills-count {
  color: dodgerblue;
}
.skillPaths hr {
  border-top: 1px solid #DAD5D5;
  margin-top: 5px;
  margin-bottom: 0;
}
.skills-academy .back-arr {
  margin-top: 13px;
  margin-bottom: 10px;
}
.skills-academy .error-message {
  width: 100%;
}
.skills-academy textarea {
  width: 100%;
  resize: none;
  border: 1px solid #DBDBEA;
  overflow-y: auto;
  max-height: 100px;
  margin-top: 10px;
}
.skills-academy textarea:disabled {
  background-color: #fff;
  border: 1px solid #fff;
}
.skills-academy ol li {
  font-size: 10px;
}
.skills-academy h4 {
  font-weight: bold;
}
.skills-academy h6 {
  margin-top: 5px;
  font-weight: bold;
  color: dodgerblue;
}
.store.topya-main-page-content {
  margin-top: 20px;
}
.store .alert {
  margin-top: 40px;
  text-align: center;
}
.store .product {
  padding: 5px;
  background-color: #fff;
  margin-bottom: 30px;
}
.store .product:hover {
  background-color: #f7f7fd;
}
.store .product img {
  max-width: 100%;
  max-height: 50px;
}
@media (min-width: 992px) {
  .store .product:nth-of-type(2n + 1) {
    border-right: 1px solid #EEEEEE;
  }
}
.store .border-holder {
  position: absolute;
  bottom: 2px;
  left: 2%;
  width: 96%;
  height: 1px;
  border-bottom: 1px solid #EEEEEE;
}
.store .path-back {
  margin-top: 10px;
}
.store img[name="arrow-right"] {
  width: 10px;
}
.store .arrow-container,
.store .product-header {
  text-align: center;
}
.store .product-type {
  margin-top: 5px;
  color: #DBDBEA;
}
.store .product-name {
  padding: 0 5px;
  text-align: center;
}
.store .btn {
  font-size: 10px;
}
.store .animate-if.ng-enter,
.store .animate-if.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}
.store .animate-if.ng-enter,
.store .animate-if.ng-leave.ng-leave-active {
  left: -100%;
}
.store .animate-if.ng-leave,
.store .animate-if.ng-enter.ng-enter-active {
  left: 0;
}
.store .error-message {
  width: 100%;
  margin-top: 20px;
}
.store .items-holder {
  max-height: 300px;
  overflow-y: auto;
}
.store .recommendation {
  margin-top: 3%;
  margin-left: 1%;
}
.store .video-holder {
  margin-top: 3%;
  margin-bottom: 1%;
  width: 100%;
  padding-bottom: 50%;
  background-color: #a7b1c1;
  position: relative;
}
.store .freestyle-name {
  margin-top: 2%;
  margin-left: 1%;
}
.store .freestyle-stats {
  margin-left: 1%;
  color: #a7b1c1;
}
.store .active-tab {
  color: dodgerblue;
}
.store .featured-item {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5%;
}
.store .round-image-holder {
  width: 90%;
  margin-left: 10%;
}
.store .free-banner {
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 300;
}
.store .headers {
  padding-top: 20px;
  padding-bottom: 20px;
}
.store .header-group {
  text-align: left;
}
.store .header-purchased {
  float: right;
}
.store .header-all {
  display: inline-block;
}
.store.recommend-background {
  background-color: #e0f0d0;
}
.store.recommend-background .round-image-holder {
  background-color: #b9ceaa;
}
.store.assign-background {
  background-color: #E7EDF8;
}
.store.assign-background .round-image-holder {
  background-color: #ced7e6;
}
.store .arrow-right {
  margin-top: 70%;
  float: right;
}
.store .video-parent {
  width: 100%;
  padding-bottom: 56.2%;
  height: 0;
  position: relative;
  background-color: #30303F;
}
.store.store-skill {
  padding-top: 3%;
  padding-bottom: 3%;
}
.store.store-skill .skill-back img {
  vertical-align: top;
}
.store.store-skill .skill-video-holder {
  margin: 3% 0;
}
.store.store-skill .description {
  margin-top: 8%;
}
.store.store-skill .description-catergory {
  color: #cbcbcb;
}
.store.store-skill .badge-img {
  max-width: 50px;
  float: right;
}
.store.store-skill .status {
  margin-bottom: 6%;
}
.store.store-skill .created-by-img {
  max-width: 60px;
}
.store.featured-border-top {
  border-top: 1px solid #dbdbea;
}
.store .store-filter {
  padding: 10px 0;
  margin-bottom: 30px;
  background-color: #fff;
  border-top: 1px solid #DBDBEA;
}
.store .store-filter.filter-all-available {
  text-align: center;
}
.store .store-filter.filter-purchased {
  text-align: right;
  padding-right: 15px;
}
.store .store-filter h4 {
  display: inline;
}
.store .store-filter span,
.store .featured-filter span {
  cursor: pointer;
}
.store .popover {
  min-width: 200px;
}
.store .popover img {
  max-width: 100%;
}
.store .popover .item {
  padding-top: 5px;
  padding-bottom: 20px;
}
.store .popover .item:hover {
  background-color: #f7f7fd;
}
.store .store-popover {
  text-align: center;
}
.store .store-popover .scroll-holder {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.store .store-popover .scroll-holder .item .col-xs-9 {
  text-align: left;
}
.store .store-popover .scroll-holder .item img {
  max-height: 40px;
  display: inline;
}
.store .bought h5 {
  margin-top: 9px;
}
.store.purchase {
  background-color: #f7f7fd;
  top: 150px;
}
.store.purchase .scroll-kids {
  overflow-y: auto;
}
.store.purchase img {
  max-width: 100%;
}
.store.purchase .purchase-header {
  margin-top: 10px;
}
.store.purchase .purchase-for {
  margin-top: 3%;
}
.store.purchase .purchase-for h5 {
  cursor: pointer;
}
.store.purchase h6 {
  margin-top: 10px;
}
.store.purchase hr {
  margin-top: 10px;
  border-top: 1px solid #DAD5D5;
}
.store.purchase .kid {
  margin-bottom: 10px;
  height: 80px;
}
.store .back-button {
  margin: 20px 0;
}
.store .back-button span:nth-of-type(2) {
  margin-top: 3px;
  display: inline-block;
  margin-left: 10px;
}
.store .path-header-lower {
  margin-top: 10px;
}
.store .path-header {
  border-bottom: 1px solid #DBDBEA;
  padding: 15px 0;
}
.store .path-info {
  margin-top: 30px;
  margin-bottom: 40px;
}
.store .path-info .skill {
  padding: 2% 0;
}
.store .path-info .skill .badge-img {
  width: 100%;
  max-width: 40px;
}
.store .path-info .skill .points-count h5 {
  color: #CF011A;
}
.store .path-desc {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #CBCBCB;
}
.store .path-info .skill .points-count,
.store.store-skill .points-count {
  display: inline-flex;
  margin-left: 5px;
  vertical-align: sub;
}
.store .blue-text {
  color: dodgerblue;
}
.store .bought {
  border: 1px solid green;
  color: green;
  cursor: default;
  text-align: center;
  min-height: 34px;
}
@media (max-width: 1439px) {
  .store .purchase-for h5,
  .store .bought h5 {
    font-size: 10px;
  }
}
@media (min-width: 1280px) {
  .store .product {
    min-height: 130px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .store .product-name h5 {
    font-size: 13px;
  }
  .store .product {
    min-height: 130px;
  }
}
@media (max-width: 1023px) {
  .store .product {
    min-height: 110px;
  }
  .store .btn {
    font-size: 8px;
  }
  .store .product-name h5 {
    font-size: 12px;
  }
}
.store .featured-filter {
  border-top: 1px solid #DBDBEA;
  padding: 10px;
}
.store .featured-filter span,
.store .featured-filter h4 {
  display: inline;
}
.store-block {
  margin: 0 0 20px 0;
  border: 1px solid #DBDBEA;
}
.store-block .header {
  text-align: center;
  padding: 6px 0;
  background: #E4E4EF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.store-block .header a {
  color: #000;
}
.store-block .logo {
  width: 100%;
}
.store-block .content {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.store-block .arrow-down {
  margin-left: 5px;
  vertical-align: text-top;
}
.store-block .menu {
  background: none;
  height: auto;
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .store-block .menu div:nth-child(2) {
    font-size: 12px;
    padding-left: 26px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .store-block .menu div:nth-child(2) {
    font-size: 11px;
    padding-left: 30px;
  }
}
.store-block .store-provider-img {
  max-width: 60%;
  position: absolute;
  top: 50%;
  max-height: 30px;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.store-block .product-list-item {
  margin-bottom: 20px;
}
.store-block .products .product {
  margin-bottom: 10px;
  border-top: 1px solid lightgrey;
  padding-top: 10px;
  padding: 5px;
}
.store-block .products .product:nth-child(1) {
  border-top: none;
}
.store-block .products button {
  width: 100%;
}
.store-block .dropDownMenu-wrapper {
  border-bottom: 1px solid lightgrey;
  text-align: center;
}
.store-block .dropDownMenu-wrapper:hover {
  cursor: pointer;
  background-color: #f7f7fd;
}
.store-block .dropDownMenu-wrapper span:nth-child(1) {
  display: inline-block;
}
.store-block .dropDownMenu-wrapper span:nth-child(2) {
  display: inline-flex;
}
.store-block-dropdown {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
}
.store-block-dropdown .item {
  margin-bottom: 10px;
  cursor: default;
  text-align: center;
}
.store-block-dropdown .item:hover {
  text-decoration: underline;
  cursor: pointer;
}
@media (min-width: 1441px) {
  .store-block-dropdown {
    width: 190px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .store-block-dropdown {
    width: 125px;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .store-block-dropdown {
    width: 130px;
  }
}
.supportInfoWrapper {
  background-color: #F1F0EF;
  text-align: center;
}
.supportInfoWrapper .header {
  padding: 15px 0;
  background-color: #706E69;
  color: white;
  font-weight: bold;
}
.supportInfoWrapper .region {
  background-color: #E4E3DF;
  padding: 10px 0;
  font-weight: bold;
  color: black;
}
.supportInfoWrapper .content {
  text-align: center;
  color: #706E69;
  transition: all ease-in 400ms;
  word-break: break-all;
}
.supportInfoWrapper .content .info {
  margin: 5px 0;
  display: block;
}
@media (min-width: 700px) and (max-width: 1300px) {
  .supportInfoWrapper {
    font-size: smaller;
  }
}
.supportInfoWrapper .headerRegister {
  background-color: #3C69A4;
}
.is-mobile .supportInfoWrapper {
  font-size: 25pt;
}
.invite-player-modal {
  background-color: #f7f7fd;
  padding-bottom: 15px;
}
.invite-player-modal .team-code-text {
  color: #f0ad4e;
  font-size: 16px;
}
.invite-player-modal .invite-by-email,
.invite-player-modal .cancel {
  padding-top: 10px;
  padding-bottom: 10px;
}
.invite-player-modal .invitation-code {
  padding-top: 13px;
  padding-bottom: 13px;
  width: 100%;
}
.invite-player-modal .modal-body {
  padding-left: 40px;
  padding-right: 40px;
}
.invite-player-modal .buttons-wrapper {
  margin-top: 10px;
}
.invite-player-modal .buttons-wrapper img {
  width: 100%;
}
.invite-player-modal .buttons {
  margin-top: 20px;
}
.invite-player-modal .buttons div {
  margin-bottom: 5px;
}
.invite-player-modal .invite-by-email-form {
  font-size: 16px;
}
.invite-player-modal .invite-by-email-form .glyphicon-plus {
  font-size: smaller;
}
.invite-player-modal .invite-by-email-form .invite-by-email,
.invite-player-modal .invite-by-email-form .cancel {
  margin-bottom: 10px;
  margin-top: 20px;
}
.invite-player-modal .invite-by-email-form .invited-players {
  margin-top: 20px ;
}
.invite-player-modal .invite-by-email-form .invite-header {
  text-align: center;
  margin-bottom: 10px;
}
.invite-player-modal input {
  width: 100%;
}
.invite-player-modal .btn-link {
  position: relative;
  top: 10px;
  border: none;
  width: auto;
}
.invite-player-modal .invites-background {
  background-color: white;
  padding: 10px 0;
}
.invite-player-modal .invite-buttons {
  margin-bottom: 20px;
}
.invite-player-modal hr {
  margin: 8px 0;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invite-player-modal .buttons-wrapper {
    padding: 0;
  }
  .invite-player-modal .invite-by-email {
    font-size: 12px;
  }
}
.viewTeam .alert {
  margin-top: 20px;
}
.viewTeam .teamDetails {
  padding: 14px;
  padding-top: 20px;
}
.viewTeam .topya-main-page-content:nth-of-type(2) {
  margin-top: 20px;
}
.viewTeam .gray-btn {
  background-color: #DBDBEA;
  border-color: #DBDBEA;
}
.viewTeam .coach-results {
  background: #F7F7FD;
}
.viewTeam .coach-results .col-xs-4 {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.viewTeam .coach-results span {
  display: inline-flex;
  vertical-align: middle;
}
.viewTeam .coach-results span:first-child {
  font-size: 21px;
}
.viewTeam .coach-results span:not(:first-child) {
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
}
.viewTeam .active-button {
  height: 26px;
}
.viewTeam .dl-horizontal {
  margin: 20px 0;
}
.viewTeam .dl-horizontal dt {
  font-size: 21px;
  width: 75px;
}
.viewTeam .dl-horizontal dd {
  text-transform: uppercase;
  margin-left: 60px;
}
.viewTeam .period-links a {
  cursor: pointer;
  color: black;
}
.viewTeam .period-links .clicked {
  color: #488EE0;
}
.viewTeam h1 {
  font-size: 24px;
}
.viewTeam .padding {
  padding: 1%;
}
.viewTeam .padding-top {
  padding-top: 3%;
}
.viewTeam .big-text {
  font-size: 21px;
}
@media (min-width: 768px) and (max-width: 960px) {
  .viewTeam .big-text {
    font-size: 17px;
  }
  .viewTeam .dl-horizontal dt {
    width: 35px;
  }
  .viewTeam .dl-horizontal dd {
    margin-left: 40px;
  }
}
.viewTeam tr .big-text {
  border-left: whitesmoke solid 1px;
}
.viewTeam tr.head .center-aligned img {
  width: 16px;
}
.viewTeam .center-aligned,
.viewTeam .big-text {
  text-align: center;
}
.viewTeam tbody tr {
  border-top: whitesmoke solid 1px;
  padding: 4px 0;
}
.viewTeam .table > tbody > tr > td {
  vertical-align: middle;
}
.viewTeam .invite,
.viewTeam .joined {
  border-left: 1px solid #DBDBEA;
  padding: 3px;
}
.viewTeam .persons .img-circle {
  width: 56px;
  height: 56px;
}
.viewTeam .persons div {
  display: inline-table;
  margin-right: 5px;
}
.viewTeam .persons div:last-child {
  float: right;
}
.viewTeam .inactive-wrapper {
  padding: 10px;
  margin: 10px 0;
}
.viewTeam .arrow {
  position: relative;
}
.viewTeam .username {
  color: #c7c7de;
}
.viewTeam .blueLink {
  color: #488EE0;
}
.viewTeam .invitedPlayers,
.viewTeam .joinedPlayers {
  border-bottom: 1px solid #DBDBEA;
}
.viewTeam .btnRemind {
  color: #488EE0;
  border-color: #488EE0;
  border-radius: 0px;
}
.viewTeam .arrow-left {
  position: relative;
  top: 20px;
}
.viewTeam .settings-icon {
  position: relative;
  top: 11px;
  margin-left: 18px;
}
.viewTeam .btnResended {
  color: white;
  border-color: white;
  background-color: #488EE0;
  border-radius: 0px;
}
.viewTeam table {
  border-collapse: separate;
  border-spacing: 10px;
}
.viewTeam .imgMargin {
  margin-right: 5px;
}
.viewTeam .arrow-right {
  padding: 10px;
}
.viewTeam .arrow-right .arrow {
  top: 9px;
}
.viewTeam .persons {
  height: 100px;
}
.viewTeam .inner-scroll {
  overflow-y: auto;
  max-height: 400px;
}
.viewTeam .player-name {
  display: block;
}
.viewTeam .status-label {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  padding-top: 0px;
  font-size: large;
}
.viewTeam .back-arr {
  margin-right: 10px;
}
@media (min-width: 800px) and (max-width: 1400px) {
  .viewTeam .persons .img-circle {
    width: 44px;
    height: 44px;
  }
  .viewTeam .persons .arrow {
    top: 10px;
  }
  .viewTeam .smallerFont {
    font-size: smaller;
  }
  .viewTeam .smallerFont .username {
    font-size: 9px;
  }
  .viewTeam .btnRemind,
  .viewTeam .btnResended {
    font-size: 9px;
  }
  .viewTeam .big-text {
    font-size: 18px;
  }
}
@media (min-width: 1440px) and (max-width: 1800px) {
  .viewTeam .persons .img-circle {
    width: 44px;
    height: 44px;
  }
  .viewTeam .smallerFont {
    font-size: 13px;
  }
  .viewTeam .smallerFont .username {
    font-size: 10px;
  }
  .viewTeam .btnRemind,
  .viewTeam .btnResended {
    font-size: 9px;
  }
  .viewTeam .big-text {
    font-size: 20px;
  }
}
.video-holder {
  margin: 10px 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.2%;
}
.video-holder.is-manager {
  background-size: contain;
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-color: #000;
}
.video-holder.is-manager .uploading-message {
  background-color: rgba(51, 51, 51, 0.75);
}
.video-holder video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.video-holder .coach-border-color {
  border: 1px solid #86C546;
}
.video-holder .player-border-color {
  border: 1px solid dodgerblue;
}
.video-holder .manager-border {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}
.video-holder .uploading-message {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 25%;
  text-align: center;
}
.video-holder .coach-border-color h4,
.video-holder .coach-border-color a {
  color: #86C546;
  font-weight: normal;
}
.video-holder .player-border-color h4,
.video-holder .player-border-color a {
  color: dodgerblue;
  font-weight: normal;
}
.video-holder .manager-border h4,
.video-holder .manager-border a {
  color: dodgerblue;
  font-weight: normal;
}
.video-holder .uploading-message a {
  font-size: 18px;
  cursor: pointer;
}
.video-holder .video-error-message {
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.video-holder .video-error-message h4 {
  color: #A94442;
  margin-top: 25%;
  font-weight: normal;
}
.video-holder img {
  width: 100%;
  position: absolute;
}
.video-holder .progress {
  margin-bottom: 0;
  border-radius: 0;
  height: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.uploads-info {
  margin-top: 20px;
  padding: 0;
}
.uploads-info.is-admin {
  position: fixed;
  width: 300px;
  bottom: 0;
  left: 15px;
}
topya-video-component img.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
topya-video-component .bottom-left-watermark {
  position: absolute;
  left: 3%;
  bottom: 5%;
  z-index: 1;
  width: 10%;
  opacity: 0.5;
}
.videogular-container-challenge {
  width: 100%;
  height: 130px !important;
  margin: auto;
  overflow: hidden;
}
.videogular-container-skill {
  width: 100%;
  height: 300px !important;
  margin: auto;
  overflow: hidden;
}
.videogular-container-player-skill {
  width: 100%;
  height: 230px !important;
  margin: auto;
  overflow: hidden;
}
.skills-academy-video {
  width: 100%;
  height: 300px !important;
  margin: auto;
  overflow: hidden;
}
.videogular-vote-video {
  height: 150px !important;
  width: 100%;
  margin: auto;
  overflow: hidden;
}
.videogular-container-challenge videogular vg-overlay-play .play:before,
[videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(0.5);
  display: block;
  background-size: 48px !important;
}
.videogular-container-skill videogular vg-overlay-play .play:before,
[videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  width: 64px !important;
  height: 64px !important;
  background-size: 48px !important;
}
videogular vg-volume-bar,
[videogular] vg-volume-bar {
  transform: scale(0.4, 0.6) !important;
  top: -80px !important;
}
@media (min-width: 1000px) and (max-width: 1281px) {
  .videogular-container-player-skill {
    height: 155px !important;
  }
}
.videogular-container-player-skill videogular vg-overlay-play .play:before,
[videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(0.5);
  display: block;
  background-size: 48px !important;
}
.videogular-container-fan-challenge {
  width: 100%;
  height: 150px !important;
  margin: auto;
  overflow: hidden;
}
.videogular-container-fan-challenge videogular vg-overlay-play .play:before,
[videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(0.5);
}
.skills-academy-video videogular vg-overlay-play .play:before,
[videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(0.8);
  display: block;
  background-size: 48px !important;
}
@media (min-width: 1000px) and (max-width: 1441px) {
  .videogular-container-fan-challenge {
    height: 120px !important;
  }
}
@media (min-width: 700px) and (max-width: 1220px) {
  .skills-academy-video {
    height: 200px !important;
  }
}
@media (min-width: 1221px) and (max-width: 1440px) {
  .skills-academy-video {
    height: 230px !important;
  }
}
.videogular-vote-video videogular vg-overlay-play .play:before,
[videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(0.5);
  display: block;
  background-size: 48px !important;
}
.videogular-responsive {
  width: 100%;
  height: 100% !important;
  margin: auto;
  position: absolute;
  overflow: hidden;
}
.videogular-container-fan-challenge videogular vg-overlay-play .play:before,
[videogular] vg-overlay-play .play:before {
  content: url(../../img/play_icon.png) !important;
  transform: scale(0.5);
  display: block;
  background-size: 48px !important;
}
.videogular-rounded-border {
  border-radius: 8px;
  overflow: hidden;
}
.videogular-bordered.thin-gray {
  border: 1px solid #ccc;
}
.logo-watermark {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 25%;
}
videogular vg-time-display,
[videogular] vg-time-display {
  width: 30px;
  font-size: 12px;
  padding: 0 4px;
  padding-top: 4px;
}
@media (max-width: 1280px) {
  videogular vg-time-display,
  [videogular] vg-time-display {
    width: 25px;
    font-size: 10px;
    padding: 0 2px;
    padding-top: 2px;
  }
}
videogular vg-scrub-bar .container,
[videogular] vg-scrub-bar .container {
  padding: 0;
}
.thumbnail-upload p {
  font-size: 12px;
  color: #7b7b7b;
  margin: 15px auto 25px;
}
.thumbnail-upload .upload-icon {
  position: absolute;
  font-size: 25px;
  text-align: center;
  width: 100%;
  color: #a5c8f1;
}
.thumbnail-upload .carousel-item {
  border-radius: 8px;
  width: 80%;
  margin-left: 10%;
  height: 55px;
}
.thumbnail-upload .carousel-item.current {
  border: 2px solid dodgerblue;
}
.thumbnail-upload .carousel-item.upload-new {
  padding-bottom: 43%;
  position: relative;
}
.thumbnail-upload .carousel-item.upload-new:not(.disabled-item) {
  cursor: pointer;
}
.thumbnail-upload .carousel-item.upload-new:not(.current) {
  border: 2px solid #b1aeae;
}
.thumbnail-upload .carousel-item .upload-icon {
  top: 25%;
}
.thumbnail-upload .new-thumbnail-preview {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.7;
}
.thumbnail-upload span[class^="carousel-arrow-"] {
  position: absolute;
  top: 20%;
  color: #b1aeae;
  cursor: pointer;
  z-index: 900;
}
.thumbnail-upload .carousel-arrow-left {
  left: -25px;
}
.thumbnail-upload .carousel-arrow-right {
  right: -25px;
}
.thumbnail-upload .upload-alternate {
  margin-left: calc(50% - 40px);
  padding-bottom: 78px;
  width: 80px;
  border: 1px solid #b1aeae;
  position: relative;
  border-radius: 8px;
}
.thumbnail-upload .upload-alternate:not(.disabled-item) {
  cursor: pointer;
}
.thumbnail-upload .upload-alternate .upload-icon {
  top: 35%;
  left: 0;
}
.thumbnail-upload .upload-alternate.wider {
  margin-left: calc(50% - 50px);
  width: 100px;
}
.video-download {
  padding-top: 15px;
  padding-bottom: 15px;
}
.video-download .btn {
  border-radius: 8px;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1024px) {
  .video-download .btn {
    font-size: 12px;
  }
}
.video-download .link-to-copy {
  text-align: center;
}
.video-download .link-to-copy h6 {
  word-break: break-word;
}
.video-download .locked-video-message h6 {
  color: #f54b74;
  display: inline-block;
  max-width: calc(100% - 22px);
}
.video-download .locked-video-message img {
  display: inline-block;
  width: 16px;
  vertical-align: top;
  margin-right: 5px;
}
.votes .vote-text-wrapper {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important;
  overflow: auto !important;
}
.votes .freestyle-text,
.votes .top-skills-text {
  color: black;
  font-size: large;
  font-weight: bold;
}
.votes .top-skills-text .top-skills-gray-text {
  font-size: small !important;
  color: lightgray !important;
  margin-left: 5px;
}
.votes .skill-text {
  color: lightgrey;
  font-weight: bold;
}
.votes .challenge-name,
.votes .skill-name {
  color: black;
  font-weight: bold;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}
.votes .btn-primary {
  text-align: center !important;
}
.votes .challenge-wrapper {
  border-bottom: 1px solid #EEEEEE;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.votes .one-video-button {
  position: absolute;
  bottom: 10px;
}
.votes {
  margin: 20px 0 !important;
}
.votes .back-arr {
  margin-right: 10px;
}
.votes .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.votes .content {
  margin-top: 10px !important;
  font-weight: bold;
  margin-bottom: 10px !important;
}
.votes .content-wrapper {
  border: 2px solid #DBDBEA;
  background-color: white;
}
.votes .chosenType {
  color: #498FE1 !important;
}
.votes .judge-freestyle-dropdown-wrapper {
  border-right: 1px solid #eeeeee;
}
.votes .vs-text {
  text-align: center !important;
  position: relative;
  top: 65px !important;
  font-size: xx-large !important;
  font-weight: bold !important;
}
.votes .voting {
  margin-top: 15px !important;
}
.votes button {
  background-color: #498FE1 !important;
  border-color: #498FE1 !important;
  border-radius: 0px !important;
}
.votes .single-video-vote .button-wrapper {
  position: relative;
  bottom: 1px !important;
}
.votes .label-votes {
  font-weight: bold;
  font-size: medium;
}
.votes .green-ok {
  color: #93CB52;
  position: relative;
  top: 10px;
}
.votes .percentage {
  color: #498FE1 !important;
  font-size: medium;
}
.votes .blue-line-percentage {
  height: 5px !important;
  background-color: #006dcc;
}
.votes .voting-enabled {
  margin-top: 10px !important;
}
.votes .button-wrapper,
.votes .vote-percent-wrapper {
  position: absolute !important;
  right: 70px !important;
  bottom: 0px !important;
}
.votes .gray-dropdown {
  color: lightgray !important;
}
.yourSkills {
  margin: 20px 0;
}
.yourSkills .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.yourSkills .content {
  border: 1px solid #DBDBEA;
  padding: 25px;
  margin-top: 20px;
  height: auto;
}
.yourSkills h4 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}
.yourSkills .skillsCount {
  color: dodgerblue;
}
.yourSkills .skillPathBlock {
  padding-top: 10px;
}
.yourSkills hr {
  margin-top: 40px;
}
.yourSkillsInner {
  margin: 20px 0;
}
.yourSkillsInner .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.yourSkillsInner .content {
  border: 1px solid #DBDBEA;
  padding: 25px;
  margin-top: 20px;
  height: auto;
}
.yourSkillsInner h1 {
  font-size: 26px;
  font-weight: bold;
  margin: 3%;
}
.yourSkillsInner h4 {
  font-weight: bold;
}
.yourSkillsInner .margin-right {
  margin-right: 10px;
}
.yourSkillsInner span.challenges {
  color: #468CDF;
  font-size: 14px;
}
.yourSkillsInner span.red {
  color: #CE0019;
  font-weight: bold;
}
.yourSkillsPlayerInner {
  margin: 20px 0;
}
.yourSkillsPlayerInner .header {
  background: #1B1C2C !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}
.yourSkillsPlayerInner .content {
  border: 1px solid #DBDBEA;
  padding: 25px;
  margin-top: 20px;
  height: auto;
}
.yourSkillsPlayerInner h1 {
  font-size: 22px;
  font-weight: bold;
  margin: 3%;
}
.yourSkillsPlayerInner h2.skill {
  font-size: 14px;
  color: #A7A7A8;
}
.yourSkillsPlayerInner .first-div {
  margin-top: 20px;
  border-bottom: 1px solid #F7F7FD;
  padding-bottom: 15px;
}
.yourSkillsPlayerInner .margin-top-10 {
  margin-top: 10px;
}
.zendesk-help.btn {
  margin-top: 10px;
  display: block;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
}
.zendesk-help.btn.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  width: auto;
  padding: 2.5vw 5vw;
  margin-top: 5vw;
  border-radius: 1.25vw;
}
.freestyle .members-count {
  margin-top: 10px;
}
.freestyle .members-count img {
  display: inline;
  width: 1.5vw;
}
.freestyle .members-count h5 {
  color: #9f9f9f;
  display: inline;
}
.freestyle .list-item {
  margin-bottom: 10px;
}
.freestyle .header {
  background-color: #000;
  height: 40px;
  margin-top: 10px;
}
.freestyle .header:hover {
  opacity: 0.7;
}
.freestyle .header .rotate {
  transform: rotate(90deg);
}
.freestyle .header h4 {
  display: inline-block;
  margin-top: 10px;
  margin-left: 15px;
}
.freestyle .header img {
  float: right;
  margin-top: 10px;
  margin-right: 15px;
}
.freestyle .active-header {
  color: #93CB53;
}
.freestyle .scored-header {
  color: #F7A722;
}
.freestyle .pending-header {
  color: #488DDD;
}
@media (max-width: 1023px) {
  .freestyle h4 {
    font-size: 18px;
  }
}
#freestyle-noheader-video {
  margin-top: 13%;
}
.freestyle .team-list-item {
  padding-bottom: 3%;
  margin-bottom: 3%;
  word-wrap: break-word;
}
.freestyle .team-list-item h3 {
  margin-top: 10%;
}
.freestyle .team-list-item .team-info {
  margin-top: 2%;
}
.freestyle .team-list-item .team-members-count {
  text-align: center;
}
.freestyle .team-list-item .team-members-count h4 {
  display: inline-block;
  margin-top: 10%;
}
.freestyle .team-list-item .team-member-avatar-holder {
  display: inline-block;
  width: 26%;
  min-width: 75px;
  margin-bottom: 5px;
}
.freestyle .team-list-item .avatar-img {
  width: 50px;
  height: 50px;
}
.freestyle .team-list-item .arrow-right {
  margin-top: 90%;
}
.freestyle .team-selected,
.freestyle .team-deselected {
  margin-top: 80%;
}
.freestyle .team-selected {
  color: dodgerblue;
}
.freestyle .my-teams-filter {
  text-align: right;
  border-top: none;
  border-bottom: none;
}
@media (max-width: 1023px) {
  .freestyle .team-members-count img {
    width: 15px;
  }
  .freestyle .team-list-item .avatar-img {
    width: 25px;
    height: 25px;
  }
  .freestyle .team-list-item .team-info {
    margin-top: 0;
  }
  .freestyle .team-members-count h4,
  .freestyle .team-list-name {
    font-size: 10px;
  }
  .freestyle .team-selected,
  .freestyle .team-deselected {
    margin-top: 10%;
  }
}
.my-teams-filter-holder {
  margin-top: -15px;
}
.my-teams-header {
  margin-bottom: 5%;
}
column-basic-sort .active-filter {
  color: dodgerblue;
}
column-basic-sort .disabled-filter {
  cursor: default;
  opacity: 0.7;
}
column-multiselect-sort .active-filter {
  color: dodgerblue;
}
column-multiselect-sort .disabled-filter {
  cursor: default;
  opacity: 0.7;
}
.date-search-dropdown .btn {
  border-radius: 5px;
}
.date-search-dropdown .btn.picker-button {
  display: inline-block;
  width: auto;
  font-size: 12px;
  padding: 5px;
  vertical-align: initial;
}
.date-search-dropdown .btn.picker-button span:first-child.glyphicon {
  font-size: 11px;
  vertical-align: initial;
  margin-left: 4px;
}
.date-search-dropdown .label-span {
  color: #000;
  font-size: 12px;
  font-weight: normal;
}
.date-search-dropdown .label-span.right {
  margin-left: 5px;
}
.date-search-dropdown input {
  width: 0;
  padding: 0;
  height: 0;
  border: none;
  position: absolute;
  margin: 0;
  top: 30px;
}
.date-search-dropdown input:not(.right) {
  right: -12px;
}
.date-search-dropdown input.right {
  right: 8px;
}
.date-search-dropdown ul.uib-datepicker-popup.dropdown-menu li:hover {
  background-color: #fff;
}
account-status-indicator > div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
}
.additional-videos-preview .checkbox-group {
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}
.additional-videos-preview .checkbox-group .checkbox-holder {
  position: absolute;
  height: 34px;
  width: 34px;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 5px;
}
.additional-videos-preview .checkbox-group .checkbox-holder.green-background {
  background-color: #5fd842;
}
.additional-videos-preview .checkbox-group .checkbox-holder.gray-background {
  background-color: #eee;
}
.additional-videos-preview .checkbox-group label {
  margin-left: 34px;
  width: calc(100% - 60px);
}
.additional-videos-selector .drag-uploader {
  margin-top: 40px;
  width: 100%;
  padding: 6% 15px;
  text-align: center;
  border: 1px dashed #bbb;
  position: relative;
}
.additional-videos-selector .drag-uploader.drag-drop {
  opacity: 0.5;
}
.additional-videos-selector .drag-uploader .preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  opacity: 0.7;
}
.additional-videos-selector .drag-uploader h2 {
  margin-bottom: 15px;
  font-family: 'AvenirNextLTPro Bold';
}
.additional-videos-selector .drag-uploader a {
  color: #8b3bf4;
  cursor: pointer;
}
.additional-videos-selector .additional-video-settings {
  margin-top: 40px;
  padding: 6% 15px;
  border: 1px dashed #bbb;
  position: relative;
}
.additional-videos-selector .additional-video-settings .seconds-repeat {
  text-align: left;
  margin-top: 30px;
}
.additional-videos-selector .additional-video-settings .seconds-repeat span:not(:last-child) {
  margin-right: 5px;
}
.additional-videos-selector .glyphicon.glyphicon-remove {
  margin-top: 34px;
  color: #e30000;
}
.additional-videos-selector .checkbox-group {
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}
.additional-videos-selector .checkbox-group .checkbox-holder {
  position: absolute;
  height: 34px;
  width: 34px;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 5px;
}
.additional-videos-selector .checkbox-group .checkbox-holder.green-background {
  background-color: #5fd842;
}
.additional-videos-selector .checkbox-group .checkbox-holder.gray-background {
  background-color: #eee;
}
.additional-videos-selector .checkbox-group label {
  margin-left: 34px;
  width: calc(100% - 60px);
}
.admin-fundraising-form .checkbox-group {
  margin-top: 25px;
  position: relative;
  overflow: hidden;
  width: 270px;
}
.admin-fundraising-form .checkbox-group .checkbox-holder {
  position: absolute;
  height: 34px;
  width: 34px;
  background-color: #5fd842;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 2px;
}
.admin-fundraising-form .checkbox-group label {
  margin-left: 34px;
  width: calc(100% - 60px);
}
.admin-fundraising-form .dropdown {
  width: 190px;
}
.admin-fundraising-form .pledges-selector .checkbox-group {
  vertical-align: text-bottom;
  margin-bottom: 0;
  margin-top: 0;
  top: -1px;
}
@media (min-width: 810px) {
  .admin-fundraising-form .pledges-selector .checkbox-group {
    margin-left: 20px;
    display: inline-block;
  }
  .admin-fundraising-form .pledges-selector .checkbox-group:last-child {
    vertical-align: top;
    margin-top: 1px;
  }
}
@media (max-width: 809px) {
  .admin-fundraising-form .pledges-selector .checkbox-group:last-child {
    margin-top: 20px;
  }
}
@media (max-width: 556px) {
  .admin-fundraising-form .suggested-amounts span {
    display: block;
    margin-bottom: 20px;
  }
}
@media (min-width: 557px) {
  .admin-fundraising-form .suggested-amounts span {
    display: inline-block;
  }
}
.admin-fundraising-form .suggested-amounts input {
  display: inline-block;
  width: 100px;
  margin-left: 20px;
}
@media (max-width: 556px) {
  .admin-fundraising-form .suggested-amounts input:first-of-type {
    margin-left: 0;
  }
}
.admin-fundraising-form .radio-button {
  display: inline-block;
  width: 270px;
}
@media (max-width: 991px) {
  .admin-fundraising-form .radio-button {
    width: 210px;
  }
}
.admin-fundraising-form textarea {
  resize: none;
  height: 150px;
}
.admin-fundraising-form input.form-control.donation-goal-input {
  display: inline-block;
  width: auto;
}
app-store-download-button > button,
google-play-download-button > button {
  padding: 0;
  border-width: 0;
}
app-store-download-button > button img,
google-play-download-button > button img {
  width: 100%;
}
.app-video-player {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.app-video-player videogular {
  position: absolute;
  width: 100%;
  height: 100%;
}
.app-video-player videogular .logo-watermark {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 25%;
  max-width: 150px;
}
.app-video-player .pending-thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
.app-video-player .error-status-holder span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 40%;
  text-align: center;
  font-weight: bold;
  color: red;
}
breadcrumbs > div {
  margin-top: 8px;
  padding-top: 8px;
  height: 32px;
  display: inline-block;
}
breadcrumbs > div span.paths-holder {
  display: inline-block;
}
breadcrumbs > div span.path {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  color: #999;
}
breadcrumbs > div span.path.active-path {
  color: #333;
  font-weight: 900;
}
breadcrumbs > div span.separator {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  color: #999;
  margin-left: 12px;
  margin-right: 12px;
}
breadcrumbs > div.mobile {
  height: 7.5vw;
  margin-top: 4.7vw;
  padding-top: 0.9vw;
}
breadcrumbs > div.mobile span.path,
breadcrumbs > div.mobile span.separator {
  font-size: 3.75vw;
  line-height: 6.6vw;
}
breadcrumbs > div.mobile span.separator {
  margin-left: 4vw;
  margin-right: 4vw;
}
cancel-pledge-modal .modal-body {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
cancel-pledge-modal .modal-body span.prompt-text {
  font-size: 16px;
}
cancel-pledge-modal .modal-body button.btn {
  width: 260px;
  border-radius: 4px;
  margin-top: 20px;
  display: block;
  margin-left: 50%;
  transform: translateX(-50%);
}
cancel-pledge-modal .modal-body .alert.alert-danger .close {
  margin-top: -10px;
  margin-right: 0;
}
checkbox-group > div {
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}
checkbox-group > div .checkbox-holder {
  position: absolute;
  height: 34px;
  width: 34px;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 5px;
}
checkbox-group > div .checkbox-holder.gray-background {
  background-color: #eee;
}
checkbox-group > div .checkbox-holder.green-background {
  background-color: #5fd842;
}
checkbox-group > div label {
  margin-left: 34px;
  width: calc(100% - 60px);
}
confirm-contact-change-view button.btn {
  width: auto;
  border-radius: 4px;
  margin: 0 5px;
}
.contest-orgs-selector .drop-area-holder {
  padding-left: 15px;
  padding-right: 15px;
}
.contest-orgs-selector .drop-area {
  min-height: 300px;
  border: 1px dotted #c6c4c6;
  margin-top: 10px;
  position: relative;
}
.contest-orgs-selector .drop-area span.area-title {
  position: absolute;
  z-index: 1;
  width: 100%;
  text-align: center;
  display: block;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.contest-orgs-selector .drop-area span.area-title h2 {
  font-family: 'AvenirNextLTPro Bold';
  color: #434343;
}
.contest-orgs-selector .drop-area .selected-org {
  height: 40px;
  position: relative;
  z-index: 2;
}
.contest-orgs-selector .drop-area .selected-org span {
  display: inline-block;
  margin-top: 20px;
  transform: translateY(-50%);
}
.contest-orgs-selector .drop-area .selected-org span:first-child {
  padding-left: 15px;
  width: calc(100% - (15px + 15px));
}
.contest-orgs-selector .drop-area .selected-org span.glyphicon {
  color: #f70000;
  margin-right: 15px;
}
.contest-orgs-selector .drop-area .selected-org:nth-of-type(even) {
  background-color: rgba(228, 228, 228, 0.7);
}
.contest-orgs-selector .drop-area .selected-org:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.7);
}
.contest-orgs-selector .org-list-header {
  height: 40px;
  width: 100%;
  background-color: #003;
  color: #fff;
}
.contest-orgs-selector .org-list-header > div > span {
  margin-top: 20px;
  transform: translateY(-50%);
  display: block;
}
.contest-orgs-selector .org-list-item {
  min-height: 45px;
  width: 100%;
}
.contest-orgs-selector .org-list-item .row > div > span {
  margin-top: 22.5px;
  transform: translateY(-50%);
  display: inline-block;
}
.contest-orgs-selector .org-list-item .row > div > button {
  display: inline-block;
  width: auto;
  margin-top: 22.5px;
  font-size: 12px;
  transform: translateY(-50%);
}
.contest-orgs-selector .org-list-item:nth-of-type(even) {
  background-color: #e4e4e4;
}
.contest-orgs-selector .org-list-item:nth-of-type(even) .sub-org-item:nth-of-type(even) {
  background-color: #e4e4e4;
}
.contest-orgs-selector .org-list-item:nth-of-type(even) .sub-org-item:nth-of-type(odd) {
  background-color: #fff;
}
.contest-orgs-selector .org-list-item:nth-of-type(odd) .sub-orgs-item:nth-of-type(odd) {
  background-color: #e4e4e4;
}
.contest-orgs-selector .org-list-item:nth-of-type(odd) .sub-orgs-item:nth-of-type(even) {
  background-color: #fff;
}
.contest-orgs-selector .org-list-item .selected > div > span {
  opacity: 0.4;
}
.contest-orgs-selector .org-list-item .sub-orgs-list .sub-org-item:last-child {
  margin-bottom: 10px;
}
.contest-orgs-selector .org-list-item .sub-orgs-list .sub-org-item > div[class*='col-xs-']:first-of-type {
  padding-left: 30px;
}
.contest-orgs-selector li.dndPlaceholder {
  display: none;
}
.contest-teams-selector .chosen-container {
  width: 100%;
}
.contest-teams-selector .chosen-container-multi .chosen-choices {
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 34px;
}
.contest-teams-selector .chosen-container-multi .chosen-choices li.search-choice {
  margin: 6px 5px 3px 0;
  z-index: 1;
}
.contest-teams-selector .chosen-container-multi .chosen-choices li.teams-selector-placeholder {
  margin-top: 7px;
  margin-left: 5px;
  color: #555;
}
.contest-teams-selector .chosen-container-multi .chosen-choices span.dropdown-toggle-span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.contest-teams-selector .chosen-container-multi .chosen-choices .remove-choice {
  margin-right: 5px;
  color: #555;
}
.contest-teams-selector .dropdown-menu {
  width: 100%;
}
.contest-teams-selector .dropdown-menu .contest-teams-selector-dropdown {
  color: #797979;
  padding-bottom: 20px;
}
.contest-teams-selector .dropdown-menu .contest-teams-selector-dropdown .btn {
  font-weight: normal;
  border-radius: 4px;
}
.contest-teams-selector .dropdown-menu .contest-teams-selector-dropdown .btn:not(.toggle-selection-button) {
  margin-bottom: 15px;
}
.contest-teams-selector .dropdown-menu .contest-teams-selector-dropdown .btn.toggle-selection-button {
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
}
.contest-teams-selector .dropdown-menu .contest-teams-selector-dropdown .btn.toggle-selection-button.btn-default {
  background-color: #9b9b9b;
  padding-left: 1.5px;
  padding-top: 2px;
}
.contest-teams-selector .dropdown-menu .contest-teams-selector-dropdown .btn.toggle-selection-button.btn-success {
  background-color: #5cb85c;
  padding-top: 1px;
}
contest-image-preview .contest-image {
  width: 100%;
}
contest-reference-change-modal > div.modal-content .modal-header h4 {
  width: 90%;
  display: inline-block;
  line-height: 24px;
}
contest-reference-change-modal > div.modal-content .modal-header .close {
  margin: 0;
}
contest-reference-change-modal > div.modal-content .modal-footer error-message .close {
  margin: 0;
}
contest-reference-change-modal > div.modal-content .modal-footer button.btn.btn-primary,
contest-reference-change-modal > div.modal-content .modal-footer button.btn.btn-primary.cancel {
  font-size: 14px;
  width: 250px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.colorpicker.dropdown {
  width: auto;
}
.contest-widget-color-formatter {
  padding-bottom: 50px;
}
.contest-widget-color-formatter .header {
  font-family: 'AvenirNextLTPro Bold';
  color: #000;
}
.contest-widget-color-formatter .input-group-holder {
  margin-top: 15px;
  border-radius: 4px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.contest-widget-color-formatter .input-group-holder input {
  border-radius: 4px;
}
.contest-widget-color-formatter .input-group-holder button {
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  height: 34px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0;
}
.contest-widget-color-formatter .input-group-holder button .selected-color-box {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  margin-left: 6px;
}
countdown > div {
  position: relative;
}
countdown > div > div {
  position: absolute;
  background-color: #4b4b4b;
  padding: 5px;
  color: #9a9a9a;
  border-radius: 8px;
  width: 150px;
}
countdown > div > div > .time-unit-section {
  display: inline-block;
  margin: 5px;
}
countdown > div > div > .time-unit-section > .time-left {
  display: block;
  font-size: 20px;
}
countdown > div > div > .time-unit-section > .time-unit {
  display: block;
}
countdown > div > div > span.splitter {
  vertical-align: top;
  font-size: 20px;
  margin-top: 2px;
  display: inline-block;
}
.date-input-component > button {
  position: absolute;
  width: 104px;
  top: 1px;
  right: 1px;
  border-top: none;
  height: calc(100% - 2px);
  border-bottom: none;
  border-right: none;
}
.date-input-component > button > .glyphicon {
  margin-top: 4px;
}
.date-input-component .dropdown-menu button {
  width: auto;
}
.date-input-component ul.dropdown-menu:before {
  right: 9px;
}
.date-input-component ul.dropdown-menu:after {
  right: 7px;
}
.date-input-component .local-time-warning.message-hidden {
  opacity: 0;
}
.drag-uploader {
  margin-top: 40px;
  width: 100%;
  padding: 6% 15px;
  text-align: center;
  border: 1px dashed #bbb;
  position: relative;
}
.drag-uploader.drag-drop {
  opacity: 0.5;
}
.drag-uploader .preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  opacity: 0.7;
}
.drag-uploader h2 {
  margin-bottom: 15px;
  font-family: 'AvenirNextLTPro Bold';
}
.drag-uploader a {
  color: #8b3bf4;
  cursor: pointer;
}
drop-area > div {
  border: 1px dashed #ccc;
  min-height: 250px;
}
drop-area > div span.background-text {
  color: #4e4e4e;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  font-size: 25px;
  position: absolute;
  font-family: 'AvenirNextLTPro Bold';
  z-index: 0;
}
drop-area > div .items-holder {
  position: relative;
  z-index: 1;
}
drop-area > div li {
  visibility: hidden;
}
dropdown > div {
  width: 250px;
}
dropdown > div button.btn {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
dropdown > div .dropdown {
  width: 100%;
}
dropdown > div span.btn-text {
  max-width: 200px;
  margin: 0 5px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
dropdown > div span.caret {
  vertical-align: text-top;
  margin-top: 2px;
}
dropdown > div li {
  cursor: pointer;
}
dropdown > div li:hover {
  opacity: 0.7;
}
dropdown > div ul.dropdown-menu {
  max-height: 130px;
  overflow-y: auto;
}
error-message > div.alert.alert-danger span {
  font-weight: bold;
}
error-message > div.alert.alert-danger.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  padding: 5vw;
  padding-top: 6vw;
  text-align: justify;
  position: relative;
}
error-message > div.alert.alert-danger.mobile a.close {
  font-size: 5vw;
  position: absolute;
  top: 1vw;
  right: 2vw;
}
fundraiser-widget-preview button.btn {
  width: auto;
  border-radius: 4px;
}
fundraiser-widget-preview textarea {
  width: 400px;
  height: 200px;
  resize: none;
}
fundraiser-widget-preview iframe {
  width: 100%;
}
header-bar > div {
  font-weight: bold;
  color: #fff;
  text-align: center;
  min-height: 50px;
  margin-top: 20px;
  background: #003;
  letter-spacing: 1px;
}
header-bar > div.side-block-header {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  text-transform: uppercase;
}
header-bar > div:not(.side-block-header) {
  padding-top: 10px;
  font-size: 20px;
}
header-bar > div a,
header-bar > div a:hover,
header-bar > div a:focus {
  color: #fff;
  text-decoration: none;
}
new-high-five > button.btn.btn-primary,
new-high-five > button.btn.btn-primary.cancel {
  width: 104px;
  padding: 8px;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;
}
new-high-five > button.btn.btn-primary > img,
new-high-five > button.btn.btn-primary.cancel > img {
  vertical-align: sub;
  margin-right: 8px;
}
html-code-preview > div button.btn {
  border-radius: 4px;
}
html-code-preview > div button.btn.btn-primary.cancel {
  color: dodgerblue;
  border-color: dodgerblue;
  background-color: #fff;
  font-weight: bold;
}
html-code-preview > div textarea.form-control[readonly] {
  resize: none;
  border-radius: 4px;
  margin-top: 20px;
  height: 150px;
  background: #fff;
}
.html-input .btn {
  width: auto;
}
.html-input .cleared-html-message {
  position: absolute;
  font-size: 11px;
  color: #940404;
  margin-top: 5px;
}
.html-input textarea {
  -webkit-transition: border 100ms ease-out;
  -moz-transition: border 100ms ease-out;
  -o-transition: border 100ms ease-out;
}
.html-input textarea.error {
  border: 1px solid red;
}
image-drop-uploader > div.dragover,
image-drop-uploader > div.busy {
  opacity: 0.6;
}
image-uploader > div {
  margin: 10px 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.2%;
  background-size: contain;
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-color: #282828;
  border-radius: 8px;
  overflow: hidden;
}
image-uploader > div h4.title {
  color: dodgerblue;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
image-uploader > div img.preview {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  margin-left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
info-message > span {
  color: #333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
  width: 100%;
  text-align: center;
}
info-message > span.mobile {
  font-size: 4.3vw;
  line-height: 6.5vw;
}
calendar-input > input.form-control.ng-invalid.ng-touched:not(:focus),
country-input > input.form-control.ng-invalid.ng-touched:not(:focus),
text-input > input.form-control.ng-invalid.ng-touched:not(:focus),
calendar-input > input.form-control.invalid,
country-input > input.form-control.invalid,
text-input > input.form-control.invalid {
  border-color: red;
}
.list-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.65);
  z-index: 999;
  text-align: center;
  overflow: hidden;
}
.list-loading-overlay span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
loading-placeholder > div {
  opacity: 0.5;
  transition: all linear 0.5s;
}
loading-placeholder > div.ng-hide {
  opacity: 0.35;
}
locked-player-skill-banner > div {
  background-color: #ffd3db;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: center;
}
locked-player-skill-banner > div span.glyphicon-lock {
  color: dodgerblue;
  margin-right: 5px;
}
locked-player-skill-banner > div countdown > div {
  display: inline-block;
}
locked-player-skill-banner > div countdown > div > div {
  top: -40px;
  left: 10px;
}
manager-search-in-list form button.btn {
  text-transform: uppercase;
}
org-funds-distribution-input > div input.form-control {
  border-radius: 4px;
  width: 80px;
  display: inline-block;
  margin-right: 10px;
}
org-funds-distribution-input > div div.btn-group.dropdown {
  display: inline-block;
  width: 250px;
}
org-funds-distribution-input > div div.btn-group.dropdown .dropdown-menu,
org-funds-distribution-input > div div.btn-group.dropdown button.btn {
  width: 100%;
}
org-funds-distribution-input > div span.glyphicon {
  margin-left: 10px;
  vertical-align: middle;
}
org-funds-distribution-input > div span.glyphicon.glyphicon-remove {
  color: red;
}
org-funds-distribution-input > div account-status-indicator > div {
  margin-left: 10px;
}
org-funds-distribution-input > div .percentage-input-holder {
  display: inline-block;
  margin-left: 15px;
}
.org-hierarchy-preview .expand-icon {
  margin-right: 15px;
}
.org-hierarchy-preview .expand-icon.larger-right-margin {
  margin-right: 29px;
}
.org-hierarchy-preview.disabled-item a {
  cursor: default;
}
.org-hierarchy-preview .list-group-item {
  text-align: left;
}
.org-hierarchy-preview .list-group-item:last-child {
  border-radius: 0;
  border-bottom: none;
}
.org-hierarchy-preview .list-group-item:first-child {
  border-radius: 0;
}
.org-hierarchy-preview .list-group-item.selected {
  background-color: #e0e0e0;
}
.org-hierarchy-preview .list-group {
  margin-bottom: 0;
}
.org-hierarchy-preview.list-group-root {
  border-bottom: 1px solid #ddd;
}
.org-hierarchy-preview.list-group-root > .list-group > div > .list-group-item {
  padding-left: 30px;
}
.org-hierarchy-preview.list-group-root > .list-group > div > .list-group > div > .list-group-item {
  padding-left: 45px;
}
.org-hierarchy-preview.list-group-root > .list-group > div > .list-group > div > .list-group > div > .list-group-item {
  padding-left: 60px;
}
players-list input.form-control {
  border-radius: 4px;
}
players-list div.inner-scroll-400 {
  overflow-y: scroll;
}
players-list manager-list-header > div.container-fluid {
  padding: 10px 10px 10px 0;
}
pledge-amount-selector > div .amount-btn span {
  display: block;
}
pledge-amount-selector > div .amount-btn span:nth-child(2) {
  margin-top: 5px;
  white-space: normal;
}
pledge-amount-selector > div span.amount-input-currency-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
}
pledge-amount-selector > div input.amount-input {
  padding-left: 20px;
}
pledge-list-filter .main-group {
  display: inline-block;
  padding: 4px 0;
  margin-right: 5px;
  margin-left: 20px;
}
pledge-list-filter .main-group span.selected {
  text-decoration: underline;
  margin-left: 5px;
}
pledge-list-filter .options {
  margin-right: 5px;
  background: #f7f6fc;
  color: #a8a8a9;
  border: 1px solid #a8a8a9;
  display: inline-block;
  padding: 3px 5px;
}
pledge-list-filter .options span {
  margin: 0 5px;
}
pledge-list-filter .options span.selected {
  text-decoration: underline;
}
pledge-list-filter .options.ng-hide {
  opacity: 0;
}
pledge-list-filter .options.ng-hide-remove {
  transition: all linear 0.3s;
}
.radio-button {
  border-radius: 8px;
  width: 100%;
  border: 1px solid #dae2ea;
  overflow: hidden;
  height: 32px;
  background: #fff;
}
.radio-button .label {
  height: 30px;
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
  width: 70%;
  text-align: center;
}
.radio-button .label h5 {
  color: #000;
}
.radio-button .button {
  width: 30%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  height: 30px;
}
.radio-button .button .circle-outer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.radio-button .button.selected {
  background-color: #5fd842;
}
.radio-button .button.selected .circle-outer {
  background-color: #5fd842;
  border: 2px solid #fff;
}
.radio-button .button.selected .circle-outer .circle-inner {
  width: 12px;
  height: 12px;
  margin-top: 2px;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 2px;
}
.radio-button .button.deselected {
  background-color: #eee;
}
.radio-button .button.deselected .circle-outer {
  background-color: #fff;
  border: 2px solid #dfdfdf;
}
.radio-button .button.deselected .circle-outer .circle-inner {
  display: none;
}
radio-input > div {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px 10px 2px 32px;
  min-width: 150px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
radio-input > div div.input-holder {
  margin-left: -32px;
  padding: 5px 10px;
  background: #eee;
  position: absolute;
  height: 100%;
  width: 32px;
  margin-top: -5px;
  border-right: 1px solid #ccc;
}
radio-input > div label {
  margin-left: 10px;
}
range-five-selector > div.container-fluid label {
  margin-left: -15px;
}
range-five-selector > div.container-fluid > div.buttons-holder {
  margin: 0 -20px;
}
range-five-selector > div.container-fluid > div.buttons-holder radio-button > div.radio-button {
  max-width: 20%;
  width: 90px;
  display: inline-block;
  margin: 0 1%;
}
range-more-than-ten-selector > div.container-fluid label {
  display: inline-block;
  margin-right: 10px;
}
range-more-than-ten-selector > div.container-fluid input.form-control {
  display: inline-block;
  width: 100px;
  border-radius: 4px;
}
range-ten-selector > div.container-fluid label {
  display: inline-block;
  margin-right: 10px;
}
range-ten-selector > div.container-fluid select.form-control {
  display: inline-block;
  width: 100px;
  border-radius: 4px;
}
refund-pledge-modal .modal-body {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
refund-pledge-modal .modal-body span.cancel-icon {
  position: absolute;
  right: 0;
  margin: 10px;
  background-color: red;
  padding: 5px;
  border-radius: 50%;
  color: white;
}
refund-pledge-modal .modal-body span.cancel-icon:hover {
  opacity: 0.6;
}
refund-pledge-modal .modal-body span.prompt-text {
  font-size: 16px;
}
refund-pledge-modal .modal-body button.btn {
  width: 150px;
  border-radius: 4px;
  margin-top: 20px;
  display: inline-block;
  margin: 20px 5px 0 5px;
}
refund-pledge-modal .modal-body .alert.alert-danger .close {
  margin-top: -10px;
  margin-right: 0;
}
refund-pledge-modal .modal-body .input-holder {
  width: 150px;
  display: inline-block;
  margin-left: 5px;
  position: relative;
}
refund-pledge-modal .modal-body .input-holder span.currency {
  position: absolute;
  left: 8px;
  top: 10px;
  font-size: 12px;
  font-weight: bold;
}
refund-pledge-modal .modal-body .input-holder input {
  padding-left: 18px;
}
resend-donor-receipt-modal .modal-body {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
resend-donor-receipt-modal .modal-body span.modal-title {
  font-size: 20px;
  display: block;
  margin-bottom: 20px;
}
resend-donor-receipt-modal .modal-body p.receipt-resent-detail {
  font-size: 16px;
}
resend-donor-receipt-modal .modal-body button.btn {
  margin-top: 15px;
  width: 100px;
  border-radius: 4px;
}
same-product-paths-error-message > div {
  background-color: #ffeaee;
  padding-top: 35px;
  padding-bottom: 40px;
  text-align: center;
}
same-product-paths-error-message > div h4.message {
  color: #eb494a;
}
same-product-paths-error-message > div button.btn {
  font-weight: bold;
  width: 200px;
  margin: 5px;
  border-radius: 4px;
}
same-product-paths-error-message > div button.btn.btn-outline-danger {
  background: #fff;
  color: #c9302c;
  border-color: #ac2925;
}
search-form > form button,
search-form > form button.btn.btn-default,
search-form > form button.btn.btn-default.new-btn-default {
  min-width: 0px;
  width: 100%;
}
search-form > form button.btn.btn-default.new-btn-default {
  padding: 5px;
}
skill-score-selector > div spinner > div {
  margin-left: -15px;
}
skill-scoring-form-fields > div label {
  font-weight: bold;
  margin-right: 10px;
  width: 120px;
}
skill-scoring-form-fields > div select.form-control {
  display: inline-block;
  width: 150px;
}
skill-scoring-form-fields > div radio-button > div.radio-button {
  width: 150px;
  display: inline-block;
  margin-top: 10px;
}
skill-scoring-form-fields > div radio-button:first-child > div.radio-button {
  margin-right: 15px;
}
skill-status-indicator .skill-status img {
  width: 25px;
}
skill-status-indicator .skill-status h5 {
  display: inline-block;
  margin-left: 6%;
  font-weight: bold;
}
skill-status-indicator .skill-status.ready {
  color: #72A936;
}
skill-status-indicator .skill-status.pending-approval {
  color: #F8B32C;
}
skill-status-indicator .skill-status.approved {
  color: dodgerblue;
}
skill-status-indicator .skill-status.edits-required {
  color: #DA1F22;
}
skill-status-indicator .skill-status.in-progress {
  color: #1ca4b4;
}
.skills-ordering-list .item {
  margin-top: 10px;
  padding: 10px;
}
.skills-ordering-list .item:hover {
  background-color: #deeff5;
}
.skills-ordering-list .published {
  color: #72A936;
}
.skills-ordering-list .pending {
  color: #F8B32C;
}
.skills-ordering-list .approved {
  color: dodgerblue;
}
.skills-ordering-list .edits-req {
  color: #DA1F22;
}
.skills-ordering-list .alert {
  margin-top: 20px;
}
.skills-ordering-list .delete-skill {
  color: #E35659;
}
.skills-ordering-list .status-icon img {
  max-width: 100%;
}
@media (max-width: 767px) and (min-width: 500px) {
  .skills-ordering-list h5 {
    font-size: 12px;
  }
}
@media (max-width: 499px) {
  .skills-ordering-list h5 {
    font-size: 10px;
  }
}
spinner > div {
  border: solid;
  border-top: solid;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
stripe-o-auth-callback > div.container,
stripe-o-auth-callback > div.container-fluid {
  padding-top: 40px;
}
submit-payment-button spinner > div {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}
subscription-status-indicator > div {
  padding: 8px 16px;
  border-radius: 24px;
  width: auto;
  display: inline;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
subscription-status-indicator > div.active-subscription {
  background-color: #6DD400;
}
subscription-status-indicator > div.inactive-subscription {
  background-color: #F55151;
}
subscription-status-indicator > div.mobile {
  font-size: 3.4vw;
}
success-message > div {
  position: relative;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  background-color: #FFF;
  padding: 12px 24px;
  overflow: hidden;
}
success-message > div > img.check-icon {
  vertical-align: top;
}
success-message > div > span.green-track {
  width: 8px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #6DD400;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
success-message > div > span.message {
  color: #6DD400;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 24px;
  display: inline-block;
  max-width: calc(100% - 60px);
}
success-message > div.mobile {
  padding: 3.75vw 7.5vw;
  border-radius: 4px;
  border-width: 2px;
}
success-message > div.mobile > span.message {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-left: 7.5vw;
  max-width: calc(100% - 24vw);
}
success-message > div.mobile > img.check-icon {
  width: 7.5vw;
}
success-message > div.mobile > span.green-track {
  width: 2.5vw;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
success-message > div.mobile > a.close {
  font-size: 7.5vw;
}
tab-links span.link-holder {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  min-width: 50px;
}
tab-links span.link {
  height: 20px;
  display: inline-table;
}
tab-links span.link.selected {
  border-bottom: 2px solid;
}
tab-links span.glyphicon-info-sign {
  font-size: 12px;
  color: #ffe000;
}
thumbnail-uploader > div.container-fluid div.upload-zone {
  margin-top: 10px;
  margin-left: calc(50% - 40px);
  padding-bottom: 40px;
  width: 80px;
  border: 1px solid #b1aeae;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
thumbnail-uploader > div.container-fluid div.upload-zone:not(.disabled-item) {
  cursor: pointer;
}
thumbnail-uploader > div.container-fluid div.upload-zone span.upload-icon {
  position: absolute;
  color: #9fc0f2;
  top: 22%;
  left: 50%;
  transform: translate(-50%);
  font-size: 20px;
}
thumbnail-uploader > div.container-fluid div.upload-zone img.thumbnail-preview {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin-left: 50%;
  transform: translate(-50%);
  z-index: 100;
}
thumbnail-uploader > div.container-fluid h4 {
  font-size: 14px;
  font-weight: bold;
}
thumbnail-uploader > div.container-fluid span.instruction {
  font-size: 12px;
  color: #5a5a5a;
  margin-top: 10px;
}
toggle > div {
  position: relative;
  border: 1px solid #dae2ea;
  color: #5c5c5c;
  margin: 5px 0;
  border-radius: 8px;
  padding: 0 10px;
}
@media (max-width: 767px) {
  toggle > div {
    margin-bottom: 20px;
  }
}
toggle > div.invalid {
  border-color: red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}
toggle > div .clickable {
  padding: 10px 0;
}
toggle > div .clickable:hover h5 {
  text-decoration: underline;
}
toggle > div .info-link {
  position: absolute;
  right: -30px;
  margin-top: 11px;
  top: 0;
  color: dodgerblue;
  font-weight: bold;
}
@media (max-width: 767px) {
  toggle > div .info-link {
    left: 0;
    top: auto;
    bottom: -20px;
  }
}
toggle > div h5 {
  display: inline-block;
  margin-bottom: 4px;
}
toggle > div a.btn.selection-button {
  border-radius: 50%;
  padding-top: 1px;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  padding-left: 4.5px;
  color: #fff;
}
toggle > div a.btn.selection-button.selected {
  background-color: #5cb85c;
}
toggle > div a.btn.selection-button.deselected {
  background-color: #9b9b9b;
}
.selection-icon {
  width: 25px;
  height: 25px;
  float: right;
  border-radius: 50%;
}
.is-mobile .selection-icon {
  width: 30pt;
  height: 30pt;
  margin-top: 15pt;
}
video-player > div {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}
video-player > div > videogular {
  position: absolute;
}
view-title > div {
  display: inline-block;
  width: 100%;
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 16px 24px;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #e5e5e5;
  text-transform: uppercase;
}
view-title > div.mobile {
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 5vw 7.5vw;
  border-top-left-radius: 1.25vw;
  border-top-right-radius: 1.25vw;
  border-width: 2px;
}
.player-landing-page {
  padding-bottom: 50px;
}
.player-landing-page button.btn {
  border-radius: 4px;
  width: auto;
}
.player-landing-page.mobile {
  padding-top: 50px;
}
.player-landing-page.mobile .contest-navbar {
  margin-top: 40px;
}
.contest-pledge h2 {
  word-spacing: 4px;
  letter-spacing: 0.5px;
  line-height: 36px;
}
.contest-pledge h3 {
  line-height: 32px;
  word-spacing: 2px;
  letter-spacing: 0.5px;
}
.contest-pledge h4 {
  line-height: 26px;
}
.contest-pledge .topya-logo-holder {
  height: 60px;
}
.contest-pledge button.btn {
  border-radius: 4px;
  padding: 8px;
  height: 57px;
}
@media (max-width: 425px) {
  .contest-pledge button.btn {
    padding: 8px 4px;
  }
}
.contest-pledge button.btn:not(.submit-pledge-button) > span {
  font-size: 11px;
  font-weight: bold;
  display: block;
  margin-top: -2px;
}
.contest-pledge input,
.contest-pledge input.form-control {
  border-radius: 4px;
}
.contest-pledge input.ng-invalid:not(.ng-invalid-required),
.contest-pledge input.form-control.ng-invalid:not(.ng-invalid-required) {
  border-color: red;
}
.contest-pledge input.ng-invalid-required,
.contest-pledge input.form-control.ng-invalid-required {
  border-width: 2px;
  border-color: #66afe9;
}
.contest-pledge .fundraiser-name {
  font-family: 'AvenirNextLTPro Bold';
  color: #4a4a4a;
  width: auto;
  display: inline-block;
  text-align: center;
  margin-top: 150px;
}
.contest-pledge .fundraiser-name:hover {
  text-decoration: underline;
}
.contest-pledge .pledge-holder {
  margin-top: 60px;
  background-color: #edf4fc;
  padding: 50px 0 80px 0;
}
.contest-pledge .pledge-holder > div[class*='col-'].padding-right {
  padding-right: 30px;
}
.contest-pledge .pledge-holder > div[class*='col-'].padding-left {
  padding-left: 30px;
}
.contest-pledge .pledge-holder .supported-player-avatar {
  max-width: 130px;
  float: right;
  width: 100%;
}
@media (max-width: 767px) {
  .contest-pledge .pledge-holder .supported-player-avatar {
    float: none;
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 120px;
    margin-bottom: 40px;
  }
}
.contest-pledge .pledge-holder .cvv-input {
  padding-right: 30px;
}
.contest-pledge .pledge-holder .cvv-info {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
}
.contest-pledge .pledge-payment .charging-advice {
  display: block;
  margin-top: 5px;
  font-size: 13px;
}
.contest-pledge .pledge-payment label {
  font-size: 11px;
  vertical-align: middle;
}
.contest-pledge .pledge-payment .dropdown-menu {
  width: calc(100% - 30px);
  margin-left: 0;
}
.contest-pledge .pledge-payment span.amount-input-currency-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
}
.contest-pledge .pledge-payment input.amount-input {
  padding-left: 20px;
}
@media (max-width: 767px) {
  .contest-pledge .pledge-payment .payment-title,
  .contest-pledge .pledge-payment .payment-info-block,
  .contest-pledge .pledge-payment .receipt-address-inquiry {
    text-align: center;
  }
}
@media (max-width: 991px) {
  .contest-pledge .pledge-payment .amount-values-buttons,
  .contest-pledge .pledge-payment .amount-type-buttons {
    padding-left: 10px;
    padding-right: 10px;
  }
  .contest-pledge .pledge-payment .amount-values-buttons div[class*='col-xs-'],
  .contest-pledge .pledge-payment .amount-type-buttons div[class*='col-xs-'] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .contest-pledge .pledge-payment .amount-values-buttons button > h4,
  .contest-pledge .pledge-payment .amount-type-buttons button > h4 {
    font-size: 14px;
  }
}
@media (max-width: 374px) {
  .contest-pledge .pledge-payment .amount-type-buttons > div[class*='col-xs-'] {
    width: 100%;
  }
  .contest-pledge .pledge-payment .amount-type-buttons > div[class*='col-xs-']:nth-child(2) {
    margin-top: 20px;
  }
}
@media (max-width: 424px) {
  .contest-pledge .pledge-payment .amount-values-buttons > div[class*='col-xs-'] {
    width: 50%;
  }
}
.contest-pledge .pledge-payment .or {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(-50%) translateY(-50%);
}
@media (max-width: 767px) {
  .contest-pledge .pledge-payment .or {
    top: calc(100% + 8px);
    left: 50%;
  }
}
.contest-pledge .submit-pledge-button {
  width: 200px;
  margin-left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 14px;
}
.contest-pledge .submit-pledge-button,
.contest-pledge .submit-pledge-button:hover,
.contest-pledge .submit-pledge-button:active {
  background-color: #ff8827;
  border-color: #ff8827;
}
.contest-pledge .submit-pledge-button:disabled {
  opacity: 1;
}
@media (max-width: 767px) {
  .contest-pledge .pledge-completed > :not(button) {
    text-align: center;
  }
}
.contest-pledge .pledge-completed .pledge-amount {
  color: dodgerblue;
}
.contest-pledge .share-text {
  color: #363636;
}
.contest-pledge.pledge-mobile button.btn {
  padding: 8px;
}
.contest-pledge.pledge-mobile .fundraiser-name {
  margin-top: 115px;
}
.contest-pledge.pledge-mobile .pledge-holder .supported-player-avatar {
  float: none;
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: 120px;
  margin-bottom: 40px;
}
.video-popover {
  font-family: 'AvenirNextLTPro Regular', sans-serif;
  text-align: center;
  width: 250px;
}
.video-popover h5 {
  margin: 10px 0;
  color: #000;
}
.video-popover h4 {
  color: dodgerblue;
}
.video-popover ul {
  padding-left: 20px;
  text-align: left;
}
.video-popover li {
  color: #A5A5A5;
  margin-bottom: 8px;
}
.video-popover .video {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1360px) {
  .video-popover .assign-team-font {
    font-size: 12px;
  }
}
@media (min-width: 1361px) {
  .video-popover .assign-team-font {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .video-popover .save-submit {
    font-size: 11px;
    padding: 0;
  }
}
header-dropdown > div {
  position: absolute;
  right: 0;
  left: auto;
  top: 28px;
  width: 240px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 1px 3px 0px #888;
  padding: 16px;
  background: white;
  z-index: 1001;
}
header-profile-detail > div {
  position: relative;
}
header-profile-detail > div img.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
  vertical-align: bottom;
}
header-profile-detail > div span.dropdown-arrow-mobile {
  display: none;
}
header-profile-detail > div div.details-holder {
  display: inline-block;
}
header-profile-detail > div div.details-holder span.username {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
header-profile-detail > div div.details-holder span.username span.glyphicon {
  color: #CBCBCB;
  font-size: 10px;
  margin-left: 8px;
}
header-profile-detail > div div.details-holder span.rolename {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
}
header-profile-detail > div.mobile img.profile-image {
  width: 12.5vw;
  height: 12.5vw;
  margin-right: 0;
  margin-top: 6vw;
}
header-profile-detail > div.mobile div.details-holder {
  display: none;
}
header-profile-detail > div.mobile span.dropdown-arrow-mobile {
  display: inline-block;
  color: #CBCBCB;
  font-size: 24px;
  vertical-align: top;
  margin-left: 4.3vw;
  margin-top: 8.2vw;
}
.manager-dashboard {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.manager-dashboard .overview {
  padding-bottom: 5px;
}
.manager-dashboard .task-main {
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  border-top: 1px solid #bfbfbf;
}
.manager-dashboard .task-main .center-graph {
  margin-top: 10%;
  margin-bottom: auto;
}
.manager-dashboard .task-main canvas {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15%;
}
.manager-dashboard .task-sub {
  position: relative;
  border-left: 1px solid #bfbfbf;
}
.review .review-table .freestyle-review-detail .review-video {
  height: 385px;
}
.freestyle-review-detail videogular,
.freestyle-review-detail videogular video {
  border-radius: 10px;
}
.freestyle-review-detail button.btn,
.freestyle-review-detail input.form-control {
  border-radius: 4px;
}
.freestyle-review-detail video-with-download-links button.btn {
  border-radius: 8px;
}
.freestyle-review-detail .controls-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.freestyle-review-detail .video-creator-name {
  margin-left: 7px;
}
.freestyle-review-detail .review-detail {
  margin-left: -29px;
  margin-right: -29px;
  margin-top: -5px;
  padding: 30px 0;
  background-color: #fff;
}
.freestyle-review-detail .active-video-header {
  color: dodgerblue;
}
.freestyle-review-detail .active-video-runner-icon {
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  top: 25px;
  left: 5px;
  width: 45px;
  height: 45px;
  z-index: 300;
}
.freestyle-review-detail .nomination-star img {
  width: 25px;
  margin-bottom: 5px;
}
.freestyle-review-detail .bordered-video {
  border: 5px solid dodgerblue;
  border-radius: 20px;
}
.freestyle-revision-modal .modal-header {
  background-color: #db524b;
  color: #fff;
}
.freestyle-revision-modal button.btn {
  border-radius: 4px;
}
.freestyle-revision-modal .glyphicon {
  color: #fff;
}
.freestyle-revision-modal label {
  font-weight: normal;
}
.freestyle-revision-modal h3 {
  font-weight: bold;
}
.freestyle-revision-modal input[type="radio"] {
  display: none;
}
.freestyle-revision-modal input[type="radio"] + label {
  color: #292321;
  font-size: 14px;
}
.freestyle-revision-modal input[type="radio"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.freestyle-revision-modal input[type="radio"] + label span {
  background-color: #fff;
}
.freestyle-revision-modal input[type="radio"]:checked + label span {
  background-color: #84c542;
}
.freestyle-revision-modal input[type="radio"] + label span,
.freestyle-revision-modal input[type="radio"]:checked + label span {
  -webkit-transition: background-color 0.4s linear;
  -o-transition: background-color 0.4s linear;
  -moz-transition: background-color 0.4s linear;
  transition: background-color 0.4s linear;
}
.freestyle-review-table-header manager-column-head > div {
  padding: 0;
}
.review .review-table .review-row-odd .freestyle-review-detail .review-detail {
  border-bottom: 1px solid #E5E5E5;
}
.manage-categories h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.manage-categories .panel-heading,
.manage-categories .panel-body {
  margin: 5px 0;
}
.manage-categories .panel-heading input,
.manage-categories .panel-body input {
  background: transparent;
  border: none;
}
.manage-categories .panel-heading a.disabled,
.manage-categories .panel-body a.disabled {
  color: #777;
  cursor: default;
  text-decoration: none;
}
.manage-categories .panel-heading {
  font-size: large;
}
.manage-categories .panel-heading a {
  margin-right: 15px;
}
.manage-categories .panel-body {
  font-size: larger;
}
.manage-categories .panel-body input,
.manage-categories .panel-body span {
  margin-left: 30px;
}
.manage-categories .categories-scroll-holder {
  max-height: 440px;
  overflow-y: auto;
}
.manage-content manager-list-header > div.container-fluid {
  padding: 10px 0;
}
.manage-content manager-column-head > div {
  padding: 0 15px;
}
.manage-content .create-path-btn {
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
}
.manage-content .create-path-btn span {
  margin-right: 10px;
}
@media (max-width: 1199px) {
  .manage-content .create-path-btn {
    font-size: 14px;
  }
  .manage-content .create-path-btn span {
    margin-right: 5px;
  }
}
.manage-content .review-detail .content-provider-logo {
  max-width: 100%;
  max-height: 60px;
}
.manage-content .review-detail .path-info {
  padding-top: 35px;
}
@media (max-width: 767px) {
  .manage-content .review-detail .path-info {
    text-align: center;
  }
}
.manage-content .review-detail .path-info .path-stats {
  font-weight: bold;
  font-size: 16px;
}
.manage-content .review-detail .path-info .path-stats .path-stats-icon {
  margin-right: 5px;
}
.manage-content .review-detail .path-info .path-stats .path-stats-icon.glyphicon {
  color: #498FE1;
}
.manage-content .review-detail .path-info .path-stats .path-stats-icon:not(:first-child) {
  margin-left: 10%;
}
@media (max-width: 1023px) {
  .manage-content .review-detail .path-info .path-stats .path-stats-icon:not(:first-child) {
    margin-left: 5%;
  }
}
.manage-content .review-detail .path-info .path-stats .path-stats-icon img {
  width: 16px;
  height: 16px;
  vertical-align: top;
}
@media (max-width: 1199px) {
  .manage-content h4,
  .manage-content .review-detail .path-info .path-stats,
  .manage-content p,
  .manage-content a {
    font-size: 12px;
  }
  .manage-content .review-detail .path-info .path-stats .path-stats-icon img {
    width: 14px;
    height: 14px;
  }
}
.manage-pledges {
  padding-top: 40px;
}
.manage-pledges input.form-control,
.manage-pledges button.btn {
  border-radius: 4px;
}
.manage-pledges .pledges-list {
  margin-top: 40px;
  overflow-x: auto;
  overflow-y: visible;
}
.manage-pledges .table-holder {
  min-width: 550px;
  min-height: 300px;
}
.manage-pledges manager-list-header > div.container-fluid {
  padding: 10px 0;
}
.manage-pledges manager-list-header > div.container-fluid manager-column-head:not(:first-child) > div {
  padding: 0;
}
.manage-pledges pledge-detail:nth-child(odd) > div {
  background-color: #f5f5f5;
}
.manage-pledges .pledges-list-items-holder {
  overflow: hidden;
}
.manager-organizations .btn {
  border-radius: 4px;
}
.manager-organizations > div > list-loading-overlay > .list-loading-overlay {
  width: calc(100% + 30px);
  left: -15px;
}
.manager-organizations input.search-orgs {
  width: 100%;
  padding: 7px 30px;
}
.manager-organizations .organizations-header {
  padding: 3% 0;
  border-bottom: 1px solid #969696;
  margin-bottom: 2%;
}
.manager-organizations .apply-filters {
  padding: 11px 0;
  margin-left: 15px;
  background-color: #D1D1D1;
}
.manager-organizations .list-header {
  margin-top: 3%;
  margin-bottom: 15px;
}
.manager-organizations .list-header manager-list-header > div.container-fluid {
  padding: 10px 0;
}
.manager-organizations .list-header manager-list-header > div.container-fluid manager-column-head > div {
  padding: 0 15px;
}
.manager-organizations organizations-list-item > div {
  font-size: 16px;
  padding: 15px 0;
}
.manager-organizations organizations-list-item > div a {
  color: #000;
}
.manager-organizations organizations-list-item:nth-child(odd) > div {
  background-color: #D7D7D7;
}
.manager-organizations organizations-list-item:nth-child(odd) > div .link-arrow {
  background-color: #E4E4E4;
}
.manager-organizations .link-arrow {
  color: #000;
  padding: 0 5px;
}
.manager-organizations #single-button {
  padding: 11px 0;
}
.manager-organizations ul.dropdown-menu {
  width: 100%;
}
@media (max-width: 1220px) {
  player-skills manager-list-header > div.container-fluid {
    font-size: 12px;
  }
}
.player-skills {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.player-skills .skills-search input {
  width: 90%;
  margin-left: 5%;
}
.player-skills player-skill-detail > div error-message > div {
  margin-left: -15px;
  margin-top: 10px;
  width: 100%;
}
.player-skills .skills-table {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.player-skills .skills-table .skills-header {
  font-weight: bold;
  padding-bottom: 1%;
}
.player-skills .skills-table .skills-odd {
  background-color: #e5e5e5;
  padding-left: 30px;
  margin-left: -30px;
  padding-right: 30px;
  margin-right: -30px;
}
.player-skills .skills-table .skills-even {
  padding-left: 30px;
  margin-left: -30px;
  padding-right: 30px;
  margin-right: -30px;
}
.player-skills .skills-table .skills-summary {
  padding-top: 10px;
  padding-bottom: 10px;
}
.player-skills .skills-table .skill-detail {
  margin-top: 1%;
  margin-bottom: 1%;
}
.player-skills .skills-table .skill-detail strong {
  font-size: 16px;
}
.player-skills .skills-table .skill-detail .skill-properties h5 {
  text-align: center;
}
.player-skills-col {
  width: 14.28%;
  display: inline-flex;
  padding-right: 15px;
}
.player-skills-col span {
  display: inline-block;
}
.player-skills-col span.not(:first-child) {
  vertical-align: text-top;
}
@media (max-width: 1199px) {
  .player-skills-col:not(.content-col) {
    padding-right: 5px;
  }
  .player-skills-col:not(.content-col) span {
    font-size: 12px;
  }
  .player-skills-col:not(.content-col) span:not(:first-child) {
    margin-left: 2%;
  }
}
.player-skills-col.smaller {
  width: 7.14%;
}
.player-skills-col.medium {
  width: 9.26%;
}
.player-skills-col.larger {
  width: 21.42%;
}
.player-skills-col.x-large {
  width: 28.56%;
}
.review-summary .player-skills-col span:not(:first-child) {
  margin: auto;
}
.player-skill-videos .player-skill-col-lg {
  width: 16%;
}
.player-skill-videos .player-skill-col-md {
  width: 13%;
}
.player-skill-videos .player-skill-col-sm {
  width: 5%;
}
.player-skill-videos manager-column-head > div {
  padding: 0;
}
.player-skill-videos manager-column-head > div:not(.active-column) span[class*='glyphicon-star'].nominated-icon {
  background-color: #003;
  color: #fff;
  border: none;
}
.player-skill-videos manager-column-head > div.active-column span[class*='glyphicon-star'].nominated-icon {
  background-color: #003;
  color: dodgerblue;
  border: none;
}
.player-skill-videos div[class^='player-skill-col'] {
  position: relative;
  display: inline-block;
  vertical-align: top;
  word-break: break-word;
}
.player-skill-videos div[class^='player-skill-col'].padded {
  padding-right: 10px;
}
@media (max-width: 1023px) {
  .player-skill-videos div[class^='player-skill-col'] {
    font-size: 12px;
  }
}
@media (max-width: 1023px) {
  .player-skill-videos div[class^='player-skill-col'] span:not(.glyphicon) {
    font-size: 12px;
  }
  .player-skill-videos div[class^='player-skill-col'] span.glyphicon {
    font-size: 14px;
  }
}
.player-skill-videos div[class^='player-skill-col'] span:nth-child(2) {
  vertical-align: sub;
  margin-left: 5px;
}
@media (max-width: 879px) {
  .player-skill-videos div[class^='player-skill-col'] span:nth-child(2) {
    display: none;
  }
}
.player-skill-videos div[class^='player-skill-col'] span:first-child.glyphicon {
  font-size: 16px;
  vertical-align: sub;
}
.player-skill-videos .player-skill-info .player-avatar {
  width: 20%;
  margin-top: 50px;
}
.player-skill-videos .player-skill-info .player-name {
  color: dodgerblue;
}
.player-skill-videos .player-skill-info .skill-stats {
  font-weight: bold;
  font-size: 16px;
}
.player-skill-videos .player-skill-info .skill-stats .skill-stats-icon {
  margin-right: 5px;
}
.player-skill-videos .player-skill-info .skill-stats .skill-stats-icon.glyphicon {
  color: #498FE1;
}
.player-skill-videos .player-skill-info .skill-stats .skill-stats-icon:not(:first-child) {
  margin-left: 10%;
}
@media (max-width: 1023px) {
  .player-skill-videos .player-skill-info .skill-stats .skill-stats-icon:not(:first-child) {
    margin-left: 5%;
  }
}
.player-skill-videos .player-skill-info .skill-stats .skill-stats-icon img {
  width: 16px;
  height: 16px;
  vertical-align: top;
}
.player-skill-videos .player-skill-info .difficulty-icon {
  height: 20px;
  vertical-align: sub;
  margin-right: 10px;
}
.player-skill-videos .player-skill-info .skill-badge {
  margin-top: 100px;
  max-width: 30px;
  max-height: 50px;
}
.player-skill-videos .player-skill-info .content-management-link {
  color: dodgerblue;
  cursor: pointer;
}
.player-skill-videos .player-skill-info .star-gray {
  color: #ccc;
}
.player-skill-videos .player-skill-info .star-yellow {
  color: #ff8827;
}
.player-skill-videos .player-skill-info textarea {
  resize: vertical;
}
.player-skill-videos .active-filter .points-icon {
  background-color: dodgerblue;
  color: #fff;
  border-color: dodgerblue;
}
.player-skill-videos.review .review-table list-loading-overlay .list-loading-overlay {
  width: 100%;
  left: 0;
}
products > div h1 {
  font-family: 'AvenirNextLTPro Bold';
}
products > div hr {
  border-top: 2px solid #dcdcdc;
}
products > div manager-list-header > div.container-fluid {
  padding: 10px 0;
}
products > div manager-list-header > div.container-fluid manager-column-head > div {
  padding: 0 15px;
}
products > div .product-detail-holder:nth-child(even) {
  background-color: #d7d7d7;
}
role-builder > div.container h1 {
  font-family: 'AvenirNextLTPro Bold';
}
role-builder > div.container button.btn {
  border-radius: 4px;
}
role-builder > div.container button.btn.add-role {
  width: auto;
  float: right;
  display: inline-block;
  width: 250px;
}
.score-freestyles .header {
  margin-top: 3%;
  color: #4a4a4a;
}
.score-freestyles manager-column-head > div {
  padding-left: 0;
}
@media (max-width: 1199px) {
  .score-freestyles manager-column-head > div {
    font-size: 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .score-freestyles manager-column-head > div {
    font-size: 11px;
  }
}
@media (min-width: 1300px) {
  .score-freestyles manager-column-head > div {
    font-size: 12px;
  }
}
.score-freestyles .score-freestyle-detail {
  background-color: #fff;
  border: 1px solid;
  border-top: none;
}
.score-freestyles .score-freestyle-detail textarea {
  resize: none;
  height: 100px;
}
.score-freestyles .review-row-even .score-freestyle-detail {
  border-color: #e5e5e5;
}
.score-freestyles .review-row-odd .score-freestyle-detail {
  border-color: #fff;
}
.score-freestyles .expand-collapse-arrow {
  position: absolute;
  right: 0;
}
.score-freestyles .glyphicon-star {
  margin-left: 5px;
  font-size: 20px;
  vertical-align: sub;
}
.score-freestyles .star-gray {
  color: #ccc;
}
.score-freestyles .star-yellow {
  color: #ff8827;
}
.score-freestyles select {
  width: 150px;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.score-freestyles .difficulty-selector h5 {
  display: inline-block;
  margin-right: 5px;
}
.manager-search-teams .review .review-table {
  margin-top: 80px;
}
.manager-search-teams .review .review-table .review-row-even,
.manager-search-teams .review .review-table .review-row-odd {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}
.manager-search-teams .review .review-table .review-row-even .glyphicon,
.manager-search-teams .review .review-table .review-row-odd .glyphicon {
  font-size: 14px;
  background-color: #efefef;
  border: 3px solid #efefef;
  border-radius: 4px;
}
.manager-search-teams .review .review-table .review-row-even .loading-text-placeholder,
.manager-search-teams .review .review-table .review-row-odd .loading-text-placeholder {
  height: 20px;
  width: 25%;
  display: inline-block;
  background: #333;
}
skill-scoring > div h1 {
  font-family: 'AvenirNextLTPro Bold';
}
skill-scoring > div hr {
  border-top: 2px solid #dcdcdc;
}
skill-scoring > div .scoring-detail-holder:nth-child(even) {
  background-color: #d7d7d7;
}
skill-scoring > div manager-list-header > div.container-fluid {
  padding: 10px 0;
}
skill-scoring > div manager-list-header > div.container-fluid manager-column-head > div {
  padding: 0 15px;
}
.top-skills .header {
  font-family: 'AvenirNextLTPro Bold';
  margin-top: 40px;
}
.top-skills .nav-tabs.nav-justified > .active > a,
.top-skills .nav-tabs.nav-justified > .active > a:hover,
.top-skills .nav-tabs.nav-justified > .active > a:focus {
  border-color: dodgerblue;
  border-bottom: none;
}
.top-skills .nav-tabs.nav-justified > li > a {
  border-bottom-color: dodgerblue;
}
.top-skills .nav-tabs.nav-justified > li > a:hover {
  border-color: #c2dffb #c2dffb dodgerblue #c2dffb;
}
.top-skills a {
  color: #2b2b2b;
}
.top-skills .nav-tabs > li.active > a,
.top-skills .nav-tabs > li.active > a:hover,
.top-skills .nav-tabs > li.active > a:focus {
  color: dodgerblue;
  font-weight: bold;
}
.user-detail {
  margin-top: 10px;
  margin-bottom: 3%;
}
.user-detail button.btn.btn-default {
  width: auto;
  background: none;
  border: 1px solid;
  border-color: #498FE1;
  color: #2e6da4;
  font-weight: bold;
}
.user-detail hr {
  border-width: 2px;
}
menu-item > div.container-fluid {
  padding: 16px 24px;
}
menu-item > div.container-fluid > div.title {
  height: 32px;
  opacity: 0.7;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
menu-item > div.container-fluid > div.title.active {
  opacity: 1;
  font-weight: 900;
}
menu-item > div.container-fluid > div.title > span:hover {
  text-decoration: underline;
}
menu-item > div.container-fluid.mobile {
  padding: 0;
}
menu-item > div.container-fluid.mobile > div.title {
  height: 10vw;
  margin-bottom: 5vw;
  font-size: 5vw;
  font-weight: 900;
  line-height: 7.5vw;
}
.my-fundraiser-view {
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #f7f6fc;
}
.my-fundraiser-view .back-arrow {
  height: 12px;
  margin-top: -2px;
}
.my-fundraiser-view .supportInfoWrapper .header {
  padding: 10px 0;
}
.my-fundraiser-view .support-holder {
  margin-top: 20px;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .my-fundraiser-view .support-and-leaderboards {
    padding: 0;
  }
}
.my-fundraiser-view .admin-side-block {
  margin-top: 20px;
  padding-right: 0;
}
@media (max-width: 991px) {
  .my-fundraiser-view .admin-side-block {
    padding-left: 0;
  }
}
my-fundraisers-block > div .list-item {
  padding: 10px 15px;
}
my-fundraisers-block > div .list-item:hover {
  background-color: #E4E4EF;
}
my-fundraisers-block > div .list-item h4 {
  font-weight: bold;
}
my-fundraisers-block > div .list-item span {
  color: dodgerblue;
}
my-fundraisers-block > div .list-item hr {
  border-top: 1px solid #DAD5D5;
  margin-top: 5px;
  margin-bottom: 0;
}
my-fundraisers-list div.contests-list {
  background-color: #fff;
  margin-top: 15px;
}
my-fundraisers-list div.alert {
  margin-top: 15px;
}
my-fundraisers-list-item > div.container-fluid {
  background-color: #fff;
  padding: 30px 0 15px 0;
}
my-fundraisers-list-item > div.container-fluid:hover {
  opacity: 0.8;
}
@media (max-width: 767px) {
  my-fundraisers-list-item > div.container-fluid {
    padding: 15px 0;
  }
}
my-fundraisers-list-item > div.container-fluid h4 {
  margin: 15px 0 0 15px;
  width: calc(100% - 80px);
  display: inline-block;
}
@media (max-width: 767px) {
  my-fundraisers-list-item > div.container-fluid h4 {
    margin: 15px 15px 0 15px;
  }
}
my-fundraisers-list-item > div.container-fluid img {
  margin-top: 15px;
  margin-right: 30px;
}
my-fundraisers-list-item > div.container-fluid hr {
  border-width: 2px;
  margin-top: 30px;
}
player-fundraising-view > div.hybrid-frame {
  margin-right: -30px;
  font-size: 20pt;
}
player-fundraising-view > div iframe {
  margin-top: 40px;
  width: 100%;
}
.create-team-modal {
  margin-top: -30px;
  padding-bottom: 15px;
  background-color: #f7f7fd;
}
.create-team-modal .dropdown,
.create-team-modal input {
  margin-bottom: 10px;
  font-size: small;
}
.create-team-modal .alert {
  margin-top: 10px;
}
.create-team-modal fieldset:disabled input,
.create-team-modal .add-coach.disabled {
  background-color: #fff;
  opacity: 0.4;
}
.create-team-modal .add-coach.disabled {
  cursor: not-allowed;
}
.create-team-modal .btn {
  height: 40px;
}
@media (min-width: 700px) and (max-width: 1281px) {
  .create-team-modal {
    margin-top: -13px;
  }
}
@media (min-width: 1282px) and (max-width: 1441px) {
  .create-team-modal {
    margin-top: -30px;
  }
}
@media (min-width: 1442px) {
  .create-team-modal {
    margin-top: -16px;
  }
}
.create-team-modal label {
  font-size: small;
  color: black;
}
.create-team-modal input:not([type="checkbox"]) {
  height: 30px;
  border: 1px solid #a5a5a5;
  border-radius: 0px;
  padding: 0 5px;
  width: 100%;
}
.create-team-modal .dropdown {
  height: 30px;
  border: 1px solid #a5a5a5;
  border-radius: 0px;
}
.create-team-modal .dropdown a {
  top: 4px;
  padding: 0 5px;
  position: relative;
}
.create-team-modal .dropdown a span {
  top: 4px;
}
.create-team-modal .dropdown a:hover {
  cursor: pointer;
}
.create-team-modal .dropdown.disabled {
  opacity: 0.4;
}
.create-team-modal .dropdown.disabled a {
  color: #555;
}
.create-team-modal .add-coach {
  height: 30px;
  border: 1px solid #a5a5a5;
  background-color: #fff;
  padding: 0 5px;
}
.create-team-modal .club-org-label {
  background-color: white;
  border: solid 1px #a5a5a5;
}
.create-team-modal .team-code-text {
  color: #f0ad4e;
  font-size: 16px;
  text-align: center;
}
.create-team-modal .invitation-code {
  padding-top: 13px;
  padding-bottom: 13px;
}
.create-team-modal .primary-coach {
  font-size: 13px;
  vertical-align: sub;
}
.create-team-modal .coaches-label {
  margin-bottom: 10px;
  border-bottom: 1px solid #DBDBEA;
  padding-bottom: 10px;
}
.create-team-modal .gray-arrow {
  color: gray;
  float: right;
  line-height: 24px;
}
.create-team-modal .add-another-coaches {
  margin-bottom: 5px;
}
.create-team-modal .custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.create-team-modal .custom-checkbox input {
  position: absolute;
  left: -9999px;
}
.create-team-modal .custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.create-team-modal .custom-checkbox:hover input ~ .checkmark {
  background-color: #eee;
}
.create-team-modal .custom-checkbox input:checked ~ .checkmark {
  background-color: #fff;
}
.create-team-modal .custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.create-team-modal .custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.create-team-modal .custom-checkbox .checkmark:after {
  left: 5px;
  top: -4px;
  width: 8px;
  height: 13px;
  border: solid dodgerblue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
parent-home > div {
  width: 100%;
}
parent-home > div div.column {
  width: calc(50% - 12px);
  margin-top: 24px;
  display: inline-grid;
}
parent-home > div div.column.left-column {
  margin-right: 12px;
}
parent-home > div div.column.right-column {
  margin-left: 12px;
}
@media (max-width: 1199px) {
  parent-home > div div.column.left-column,
  parent-home > div div.column.right-column {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
parent-home > div parent-subscriptions-card > div {
  margin-top: 24px;
}
parent-my-kids > div {
  width: 100%;
}
parent-my-kids > div h3.title {
  margin-top: 6px;
  color: #333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
parent-my-kids > div parent-my-kids-list > div {
  margin-top: 24px;
}
parent-my-kids > div.mobile h3.title {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
parent-my-kids > div.mobile parent-my-kids-list > div {
  margin-top: 7.5vw;
}
parent-profile > form.container-fluid {
  padding: 0;
}
parent-profile > form.container-fluid h3.title {
  color: #333333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 6px;
}
parent-profile > form.container-fluid view-title > div {
  margin-top: 24px;
}
parent-profile > form.container-fluid div.form-fields-holder {
  padding: 38px 40px 40px 40px;
  background-color: #fff;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
parent-profile > form.container-fluid div.form-fields-holder > div.profile-info-holder {
  margin-bottom: 40px;
}
parent-profile > form.container-fluid div.form-fields-holder > div.profile-info-holder > div.username-role-holder {
  margin-left: 24px;
  display: inline-block;
  height: 96px;
  vertical-align: top;
  max-width: calc(100% - 120px);
}
parent-profile > form.container-fluid div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div {
  margin-top: 48px;
  transform: translateY(-50%);
}
parent-profile > form.container-fluid div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.username {
  color: #333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
parent-profile > form.container-fluid div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.role {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
}
parent-profile > form.container-fluid div.form-fields-holder div.form-column {
  display: block;
}
@media (max-width: 1199px) {
  parent-profile > form.container-fluid div.form-fields-holder div.form-column {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  parent-profile > form.container-fluid div.form-fields-holder div.form-column {
    width: 50%;
  }
}
parent-profile > form.container-fluid div.form-fields-holder div.form-column div.section-title {
  display: block;
}
parent-profile > form.container-fluid div.form-fields-holder div.form-column div.section-title:nth-of-type(2) {
  margin-top: 56px;
}
parent-profile > form.container-fluid div.form-fields-holder div.form-column div.section-title > span.title {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
}
parent-profile > form.container-fluid div.form-fields-holder div.form-column div.section-title > hr {
  border-color: #E5E5E5;
  border-width: 1px;
  margin: 24px 0 16px 0;
}
parent-profile > form.container-fluid div.form-fields-holder div.form-column text-input > span.label,
parent-profile > form.container-fluid div.form-fields-holder div.form-column calendar-input > span.label {
  margin-top: 24px;
}
parent-profile > form.container-fluid > button.btn {
  width: auto;
  margin-top: 24px;
}
parent-profile > form.container-fluid > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-profile > form.container-fluid success-message > div,
parent-profile > form.container-fluid error-message > div {
  margin-top: 24px;
}
parent-profile > form.container-fluid.mobile h3.title {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
parent-profile > form.container-fluid.mobile view-title > div {
  margin-top: 7.5vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder {
  padding: 7.5vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder {
  margin-bottom: 15vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder {
  height: 30vw;
  margin-left: 7.5vw;
  max-width: calc(100% - 37.5vw);
}
parent-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div {
  margin-top: 15vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.username {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.role {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder div.form-column text-input > span.label,
parent-profile > form.container-fluid.mobile div.form-fields-holder div.form-column calendar-input > span.label {
  margin-top: 7.5vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.section-title:nth-of-type(2) {
  margin-top: 17.5vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.section-title > span.title {
  font-size: 5.6vw;
  line-height: 7.5vw;
}
parent-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.section-title > hr {
  border-color: #E5E5E5;
  border-width: 2px;
  margin: 7.5vw 0 5vw 0;
}
parent-profile > form.container-fluid.mobile > button.btn {
  margin-top: 10vw;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-profile > form.container-fluid.mobile > button.btn:not(.cancel) {
  margin-left: 5vw;
}
parent-profile > form.container-fluid.mobile success-message > div,
parent-profile > form.container-fluid.mobile error-message > div {
  margin-top: 7.5vw;
}
.delete-skill-modal {
  position: absolute;
  z-index: 200;
  left: 4px;
  right: 4px;
  height: 100%;
  top: 0;
  background-color: #F7F7FD;
  border: 1px solid #DBDBEA;
  border-right: none;
  padding: 1% 0;
  padding-left: 3%;
  display: flex;
}
.delete-skill-modal h5 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.delete-skill-modal .delete-skill-inner {
  background-color: #FFF;
  min-height: 52px;
  border: 1px solid #DBDBEA;
  float: right;
  align-items: stretch;
}
.delete-skill-modal .delete-prompt {
  color: #EA8082;
}
.delete-skill-inner.animate-show {
  padding: 5px 15px;
  width: 100%;
}
.delete-skill-inner.animate-show.ng-hide-add,
.delete-skill-inner.animate-show.ng-hide-remove {
  transition: all linear 0.2s;
}
.delete-skill-inner.animate-show.ng-hide {
  width: 0;
  padding: 5px 0;
  overflow: hidden;
  margin-left: 100%;
}
player-home > div {
  width: 100%;
}
player-home > div div.column {
  width: calc(50% - 12px);
  margin-top: 24px;
  display: inline-grid;
}
player-home > div div.column.left-column {
  margin-right: 12px;
}
player-home > div div.column.right-column {
  margin-left: 12px;
}
@media (max-width: 1199px) {
  player-home > div div.column.left-column,
  player-home > div div.column.right-column {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
.kid-settings-modal {
  font-size: 16px;
}
.kid-settings-modal .password-link {
  font-size: x-small;
  text-align: left;
}
.kid-settings-modal .bottom-block {
  border-top: 1px solid #F1F1F8;
  padding-top: 10px;
  margin: 5px 0 10px 0;
}
.kid-settings-modal .left-block {
  border-right: 1px solid #F1F1F8;
  padding-right: 10px;
  word-break: break-all;
  text-align: center;
}
.kid-settings-modal .options-block .container-fluid {
  border-bottom: 1px solid #F1F1F8;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 700px) and (max-width: 1480px) {
  .kid-settings-modal .options-block .container-fluid {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.kid-settings-modal .options-block .container-fluid:last-child {
  border-bottom: none;
}
.kid-settings-modal .options-block .container-fluid span:first-child {
  position: relative;
  top: 6px;
}
.kid-settings-modal .birth-date,
.kid-settings-modal .gender {
  margin: 10px 0;
}
.kid-settings-modal .birth-date img,
.kid-settings-modal .gender img {
  width: 18px;
  height: 18px;
}
.kid-settings-modal .birth-date span,
.kid-settings-modal .gender span {
  position: relative;
  top: 3px;
  margin-left: 3px;
}
.kid-settings-modal .user-name,
.kid-settings-modal .full-name {
  margin: 10px 0;
}
.kid-settings-modal .user-name {
  color: lightgrey;
}
.kid-settings-modal .big-img {
  width: 100%;
  border: 2px solid #7DD220;
}
.kid-settings-modal input,
.kid-settings-modal select {
  height: 35px;
  border-radius: 0;
}
.kid-settings-modal .dropdown {
  height: 34px;
  border-radius: 0px;
  border: 1px solid #ECECFB;
}
.kid-settings-modal .dropdown a {
  height: 30px;
  padding-top: 7px;
}
.kid-settings-modal .dropdown .gender-icon {
  margin-top: -4px;
  margin-left: -10px;
}
.kid-settings-modal .coach-account-password,
.kid-settings-modal .coach-account-email,
.kid-settings-modal .buttons-group {
  margin-top: 10px;
}
.kid-settings-modal .buttons-group button {
  padding-top: 5px;
  padding-bottom: 5px;
}
.kid-settings-modal .coach-account-username {
  background: url('../img/username-icon.png') no-repeat 3px #fff;
  padding-left: 49px;
}
.kid-settings-modal .btn-save-account {
  margin-bottom: 11px;
}
.kid-settings-modal .error-login {
  position: absolute;
  top: 10vh;
}
.kid-settings-modal .btn-edit {
  margin-bottom: 10px;
  height: 34px;
}
.kid-settings-modal .no-border {
  border-right: none;
}
.kid-settings-modal input {
  font-size: smaller;
}
.kid-settings-modal button {
  font-size: medium;
}
.kid-settings-modal .btn-activate-deactivate {
  color: darkgray;
  font-weight: bold;
}
.player-profile-settings {
  font-size: small;
  background-color: #f7f7fd;
  margin-top: 20px;
}
.player-profile-settings .change-avatar-button {
  display: block;
}
.player-profile-settings .player_img {
  width: 100px;
  height: 100px;
}
.player-profile-settings input {
  width: 100%;
  height: 30px;
}
.player-profile-settings .inputs {
  margin-top: 20px;
}
.player-profile-settings .inputs input {
  margin-bottom: 5px;
  border: 1px #d6d6d6 solid;
  background-position-x: 1.3%;
  padding-left: 49px;
  border-radius: 0;
  text-overflow: ellipsis;
}
.player-profile-settings .coach-account-username {
  background-image: url('../img/username-icon.png');
  background-repeat: no-repeat;
  background-position-y: 6px;
}
.player-profile-settings .gender-select {
  background-image: url('../img/gender_icon.png');
  background-repeat: no-repeat;
  background-position-y: 5px;
}
.player-profile-settings .dropdown {
  color: #A9A9A9;
  font-size: 15px;
  margin-bottom: 5px;
  height: 30px;
  border: 1px solid #d6d6d6;
  border-radius: 0;
}
.player-profile-settings .dropdown a {
  height: 26px;
  padding-top: 4px;
  color: #333 !important;
}
.player-profile-settings .dropdown .gender-icon {
  margin-top: -4px;
  margin-left: -10px;
  width: 20px;
  top: 3px;
  position: relative;
  margin-right: 24px;
}
.player-profile-settings .form-component .dropdown.disabled {
  opacity: 1;
  background-color: #ebebe4;
}
.player-profile-settings .virtual-coaches-arrow-right img {
  margin-top: 100px;
}
.player-profile-settings .virtual-coach-green-checkbox {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.player-profile-settings .country-dropdown {
  background-repeat: no-repeat;
  border-radius: 0;
  background-position: 4px 4px;
  background-size: 35px 20px;
}
.player-profile-settings .left-block {
  border-right: 1px solid #eeeeee;
}
.player-profile-settings .virtual-coach-name {
  text-transform: uppercase;
}
.player-profile-settings .separator {
  width: 110%;
  margin: 3px 0 8px 7px;
}
.player-profile-settings .save-player-account-btn {
  height: 40px;
}
.player-profile-settings .virtual-coach-label {
  color: #d6d6d6;
  margin-left: 15px;
}
.player-profile-settings .gender-dropdown-triangle {
  color: lightgray;
  top: 4px;
}
.player-profile-settings .cancel {
  width: 100px;
  white-space: normal;
  margin: 10px auto 0 auto;
}
.player-profile-settings .error-message {
  margin-top: 10px;
}
.player-profile-settings .round-image-holder {
  width: 100px;
  margin: auto;
}
.player-profile-settings .virtual-coach-wrapper {
  text-align: center;
  padding: 30px 15px;
}
.player-profile-settings .virtual-coach-wrapper .virtual-coach-image-holder {
  height: 180px;
  width: 100%;
  position: relative;
}
.player-profile-settings .virtual-coach-wrapper .virtual-coach-image-holder img:not(.virtual-coach-green-checkbox) {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.player-profile-settings .nav,
.player-profile-settings .dropdown-menu {
  width: 100%;
}
.player-profile-settings .primary-organization-logo {
  position: absolute;
  max-height: 70%;
  left: 5px;
  top: -5px;
  margin: auto;
  bottom: 0;
  max-width: 40px;
}
.player-profile-settings .country-flag {
  position: absolute;
  height: 40%;
  top: 20%;
  left: 5%;
}
.teamPlayers .flag-url {
  height: 7%;
  width: 15%;
  min-width: 24px;
  vertical-align: text-top;
  min-height: 12px;
}
.teamPlayers .big_img {
  width: 102px;
  height: 102px;
}
.teamPlayers .info-wrapper {
  padding-bottom: 20px;
  border-bottom: #eeeeee 1px solid;
  font-weight: bold;
}
.teamPlayers .profile_info span {
  display: block;
}
.teamPlayers .statistics {
  margin-bottom: 20px;
}
.teamPlayers .achievments-blocks img {
  width: auto;
  height: 25px;
}
.teamPlayers .achievments-blocks .label {
  color: lightgrey;
  margin-top: 5px;
  font-size: small;
  padding: 0;
  display: flex;
}
.teamPlayers .achievments-blocks .achievment-value {
  vertical-align: middle;
}
.teamPlayers .achievments-blocks .number {
  font-size: larger;
  font-weight: bold;
}
.teamPlayers .challenges-stats {
  margin-top: 10px;
  border-top: #eeeeee 1px solid;
}
.teamPlayers .freestyle-label,
.teamPlayers .virtual-coach-label {
  margin-top: 20px;
}
.teamPlayers .virtual-coach-image {
  margin-top: 40px;
}
.teamPlayers .freestyle-challenges {
  margin-top: 20px;
  color: white;
}
.teamPlayers .freestyle-challenges .wrapper div {
  padding: 20px 0;
  border-left: 1px solid white;
  border-right: 1px solid white;
  text-align: center;
}
.teamPlayers .freestyle-challenges .wrapper div img {
  display: block;
  margin: 10px auto;
  width: 56px;
  height: 56px;
}
.teamPlayers .freestyle-challenges .in_progress {
  background-color: #68B045;
}
.teamPlayers .freestyle-challenges .wins {
  background-color: #F4A522;
}
.teamPlayers .freestyle-challenges .draws {
  background-color: #F8C063;
}
.teamPlayers .freestyle-challenges .loss {
  background-color: #C3C3C3;
}
@media (max-width: 1281px) {
  .teamPlayers .freestyle-challenges {
    font-size: smaller;
  }
}
@media (min-width: 1282px) and (max-width: 1441px) {
  .teamPlayers .freestyle-challenges {
    font-size: small;
  }
}
@media (min-width: 1442px) {
  .teamPlayers .freestyle-challenges {
    font-size: larger;
  }
}
.teamPlayers .virtual-coach {
  margin-top: 20px;
  border-top: #eeeeee 1px solid;
}
.teamPlayers .virtual-coach .virtual-coach-name {
  position: absolute;
  bottom: 20px;
  text-transform: uppercase;
  width: 60px;
  text-align: center;
}
.teamPlayers .virtual-coach img {
  margin: 20px 0;
}
.teamPlayers .virtual-coach .virtual-coaches-arrow-right img {
  position: relative;
  top: 70px;
}
.teamPlayers .virtual-coach .virtual-coach-green-checkbox {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
register-additional-selection .org-logo {
  width: 100%;
}
register-additional-selection .additional-selection-item {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #81819b;
}
register-additional-selection .additional-selection-item h4 {
  float: left;
  max-width: calc(100% - 30px);
}
register-additional-selection .skip-link {
  color: #fff;
  font-size: 18px;
  display: block;
}
register-additional-selection .btn {
  margin-bottom: 30px;
  font-size: 18px;
}
.is-mobile register-additional-selection .margin-top-30 {
  margin-top: 30px;
}
.register.is-mobile register-ask-parents h5.or {
  margin-top: 12%;
  margin-bottom: 12%;
}
register-invite-code input[type="tel"] {
  padding-right: 15%;
  background-image: url(/img/question-mark.png);
  background-repeat: no-repeat;
  background-position: 101%;
  border-style: solid;
  border-width: 1px;
  border-color: #498FE1;
}
.is-mobile register-invite-code input[type="tel"] {
  background-size: contain;
}
register-join-organization .join-organization .org-item {
  margin-top: 10px;
}
register-join-organization .join-organization .org-item hr {
  margin-top: 20px;
  margin-bottom: 5px;
}
register-join-organization .join-organization .org-item:not(:first-of-type) {
  padding-top: 10px;
}
register-join-organization .join-organization .org-item.non-clickable {
  cursor: default;
}
register-join-organization .join-organization .org-item .org-logo {
  height: 55px;
  text-align: center;
}
register-join-organization .join-organization .org-item .org-logo img {
  max-width: 100%;
  max-height: 55px;
  margin-top: 27.5px;
  transform: translateY(-50%);
}
register-join-organization .join-organization .org-item .org-name-with-checkbox {
  text-align: left;
  height: 55px;
  padding-left: 0;
}
register-join-organization .join-organization .org-item .org-name-with-checkbox span {
  display: inline-block;
  margin-top: 27.5px;
  transform: translateY(-50%);
}
register-join-organization .join-organization .org-item .org-name-with-checkbox span:not(.glyphicon) {
  font-size: 16px;
  padding-right: 15px;
  width: calc(100% - (25px + 15px + 7px + 8px + 5px));
}
register-join-organization .join-organization .org-item .org-name-with-checkbox span.toggle-holder {
  width: 25px;
  padding: 0;
  margin: 0;
  transform: none;
}
register-join-organization .join-organization .org-item .org-name-with-checkbox span.glyphicon {
  float: right;
  margin-left: 7px;
  margin-right: 8px;
}
register-join-organization .join-organization .org-item .org-name-with-checkbox span.glyphicon::before {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;
}
register-join-organization .join-organization .org-item .org-name-with-checkbox span.glyphicon.rotate::before {
  transform: rotate(180deg);
}
register-join-organization .join-organization .org-item .teams-holder {
  overflow: hidden;
}
register-join-organization .join-organization .org-item .teams-holder.ng-enter {
  transition: 0.3s linear all;
  max-height: 0;
}
register-join-organization .join-organization .org-item .teams-holder.ng-enter.ng-enter-active {
  max-height: 200px;
}
register-join-organization .join-organization .org-item .teams-holder.ng-leave {
  transition: 0.3s linear all;
  max-height: 200px;
}
register-join-organization .join-organization .org-item .teams-holder.ng-leave.ng-leave-active {
  max-height: 0;
}
register-join-organization .join-organization .join-organization-teams input {
  margin-bottom: 5px;
  margin-top: 20px;
}
register-join-organization .join-organization .join-organization-teams .team-item {
  height: 55px;
  text-align: left;
  margin-top: 5px;
}
register-join-organization .join-organization .join-organization-teams .team-item span {
  width: calc(100% - (25px + 15px));
  margin-top: 27.5px;
  transform: translateY(-50%);
  display: inline-block;
  padding-right: 10px;
}
register-join-organization .join-organization .join-organization-teams .team-item img.selection-icon {
  margin-right: 15px;
  height: 20px;
  width: 20px;
  margin-top: 27.5px;
  transform: translateY(-50%);
}
register-join-organization .join-organization .join-organization-teams .team-item.non-scrollable span {
  width: calc(100% - (25px + 19px));
}
register-join-organization .join-organization .join-organization-teams .team-item.non-scrollable img.selection-icon {
  margin-right: 19px;
}
register-join-organization .join-organization .check-icon {
  height: 25px;
  margin-top: 27.5px;
  transform: translateY(-50%);
  float: right;
  margin-right: 5px;
}
@-moz-document url-prefix() {
  register-join-organization .join-organization.join-organization .join-organization-teams .team-item:not(.non-scrollable) span {
    width: calc(100% - (25px + 14px));
  }
  register-join-organization .join-organization.join-organization .join-organization-teams .team-item:not(.non-scrollable) .check-icon {
    margin-right: 14px;
  }
}
.is-mobile register-join-organization .join-organization .inner-scroll-400 {
  margin-top: 80px;
  max-height: 800px;
}
.is-mobile register-join-organization .join-organization .alert {
  font-size: 30px;
}
.is-mobile register-join-organization .join-organization input {
  margin-top: 60px;
}
.is-mobile register-join-organization .join-organization .org-item {
  margin-top: 20px;
}
.is-mobile register-join-organization .join-organization .org-item:not(:first-of-type) {
  padding-top: 20px;
}
.is-mobile register-join-organization .join-organization .org-item .org-logo {
  height: 90px;
}
.is-mobile register-join-organization .join-organization .org-item .org-logo img {
  max-height: 90px;
  margin-top: 45px;
}
.is-mobile register-join-organization .join-organization .org-item .org-name-with-checkbox {
  height: 90px;
}
.is-mobile register-join-organization .join-organization .org-item .org-name-with-checkbox span {
  margin-top: 45px;
}
.is-mobile register-join-organization .join-organization .org-item .org-name-with-checkbox span:not(.glyphicon) {
  font-size: 25px;
  width: calc(100% - (40px + 25px + 15px + 15px + 10px));
}
.is-mobile register-join-organization .join-organization .org-item .org-name-with-checkbox span.glyphicon {
  font-size: 25px;
  margin-left: 15px;
  margin-right: 15px;
}
.is-mobile register-join-organization .join-organization .org-item .join-organization-teams .inner-scroll-200 {
  max-height: 300px;
}
.is-mobile register-join-organization .join-organization .org-item .teams-holder.ng-enter.ng-enter-active {
  max-height: 400px;
}
.is-mobile register-join-organization .join-organization .org-item .teams-holder.ng-leave {
  transition: 0.3s linear all;
  max-height: 400px;
}
.is-mobile register-join-organization .join-organization .join-organization-teams input {
  margin-bottom: 10px;
}
.is-mobile register-join-organization .join-organization .join-organization-teams .team-item {
  height: 90px;
  margin-top: 10px;
  font-size: 25px;
}
.is-mobile register-join-organization .join-organization .join-organization-teams .team-item span {
  width: calc(100% - (40px + 25px + 29.5px));
  margin-top: 45px;
  padding-right: 10px;
}
.is-mobile register-join-organization .join-organization .join-organization-teams .team-item span.glyphicon {
  font-size: 25px;
}
.is-mobile register-join-organization .join-organization .join-organization-teams .team-item .check-icon {
  margin-right: 29.5px;
  height: 35px;
}
.is-mobile register-join-organization .join-organization .join-organization-teams .team-item.non-scrollable span {
  width: calc(100% - (40px + 25px + 35px));
}
.is-mobile register-join-organization .join-organization .join-organization-teams .team-item.non-scrollable .check-icon {
  margin-right: 35px;
}
register-login input:not([type="radio"]) {
  width: 100%;
  height: 30px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
  margin-top: 30px;
}
register-login login-directive .login-form button.btn.btn-primary {
  width: 100%;
  margin-top: 30px;
}
register-login login-directive span,
register-login login-directive a {
  display: none;
}
register-select-type .signup-button {
  width: 96%;
}
.signup {
  width: 100%;
  height: auto;
  background-color: #fff;
  text-align: center;
  border: 1px solid #E3E4EB;
}
.signup h3 {
  padding-top: 25px;
  color: #1B1C2C;
  font-size: large;
}
.signup h4 {
  padding-top: 25px;
  color: #1B1C2C;
  font-size: medium;
}
.signup h5 {
  padding-top: 25px;
  color: #A5A5A5;
  padding-bottom: 35px;
  font-size: small;
}
.signup .btn {
  margin-top: 25px;
  border-radius: 0;
  font-size: small;
}
.signup .free {
  color: #F4BD5B;
}
signup-account-form > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-account-form > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-account-form > form span.explanation-label {
  color: #999999;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
signup-account-form > form div.names-input-holder {
  margin-top: 40px;
}
signup-account-form > form div.names-input-holder > div {
  width: calc(50% - 8px);
  display: inline-block;
}
signup-account-form > form div.names-input-holder > div:nth-child(2) {
  float: right;
}
@media (max-width: 445px) {
  signup-account-form > form div.names-input-holder > div {
    width: 100%;
    display: block;
  }
  signup-account-form > form div.names-input-holder > div:nth-child(2) {
    float: none;
    margin-top: 24px;
  }
}
signup-account-form > form div.gender-select {
  margin-top: 24px;
}
signup-account-form > form div.gender-select span.gender-label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: block;
  text-align: left;
  width: auto;
}
signup-account-form > form div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 16px;
}
signup-account-form > form span.tos-label {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 32px;
  display: block;
}
signup-account-form > form > text-input > span.label,
signup-account-form > form > select-input > span.label,
signup-account-form > form > country-input > span.label {
  margin-top: 24px;
}
signup-account-form > form div.buttons-holder {
  margin-top: 32px;
}
signup-account-form > form div.buttons-holder > button.btn.btn-primary {
  width: 170px;
  width: calc(50% - 8px);
}
signup-account-form > form div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-account-form > form div.buttons-holder > button.btn.btn-primary {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-account-form > form div.buttons-holder > button.btn.btn-primary {
    width: 100%;
  }
  signup-account-form > form div.buttons-holder > button.btn.btn-primary.cancel {
    margin-bottom: 24px;
  }
}
signup-account-form > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-account-form > form.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 5vw;
}
signup-account-form > form.mobile span.tos-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 10vw;
}
signup-account-form > form.mobile div.names-input-holder {
  margin-top: 12.5vw;
  display: grid;
}
signup-account-form > form.mobile div.names-input-holder > div {
  width: 100%;
  display: block;
}
signup-account-form > form.mobile div.names-input-holder > div:nth-child(2) {
  margin-top: 7.5vw;
}
signup-account-form > form.mobile div.gender-select {
  margin-top: 7.5vw;
}
signup-account-form > form.mobile div.gender-select span.gender-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
signup-account-form > form.mobile div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 5vw;
}
signup-account-form > form.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-account-form > form.mobile > text-input > span.label,
signup-account-form > form.mobile > select-input > span.label,
signup-account-form > form.mobile > country-input > span.label {
  margin-top: 7.5vw;
}
signup-account-form > form.mobile div.buttons-holder {
  margin-top: 7.5vw;
  width: 100%;
}
signup-account-form > form.mobile div.buttons-holder > button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
  width: 100%;
  max-width: none;
}
signup-account-form > form.mobile div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-add-player > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-add-player > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-add-player > form span.explanation-label {
  color: #999999;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
signup-add-player > form div.names-input-holder {
  margin-top: 40px;
}
signup-add-player > form div.names-input-holder > div {
  width: calc(50% - 8px);
  display: inline-block;
}
signup-add-player > form div.names-input-holder > div:nth-child(2) {
  float: right;
}
@media (max-width: 445px) {
  signup-add-player > form div.names-input-holder > div {
    width: 100%;
    display: block;
  }
  signup-add-player > form div.names-input-holder > div:nth-child(2) {
    float: none;
    margin-top: 24px;
  }
}
signup-add-player > form > text-input > span.label,
signup-add-player > form > calendar-input > span.label {
  margin-top: 24px;
}
signup-add-player > form div.buttons-holder {
  margin-top: 32px;
}
signup-add-player > form div.buttons-holder > button.btn.btn-primary {
  width: 170px;
  width: calc(50% - 8px);
}
signup-add-player > form div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-add-player > form div.buttons-holder > button.btn.btn-primary {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-add-player > form div.buttons-holder > button.btn.btn-primary {
    width: 100%;
  }
  signup-add-player > form div.buttons-holder > button.btn.btn-primary.cancel {
    margin-bottom: 24px;
  }
}
signup-add-player > form div.gender-select {
  margin-top: 24px;
}
signup-add-player > form div.gender-select span.gender-label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: block;
  text-align: left;
  width: auto;
}
signup-add-player > form div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 16px;
}
signup-add-player > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-add-player > form.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 5vw;
}
signup-add-player > form.mobile div.names-input-holder {
  margin-top: 12.5vw;
  display: grid;
}
signup-add-player > form.mobile div.names-input-holder > div {
  width: 100%;
  display: block;
}
signup-add-player > form.mobile div.names-input-holder > div:nth-child(1) {
  margin-bottom: 7.5vw;
}
signup-add-player > form.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-add-player > form.mobile > text-input > span.label,
signup-add-player > form.mobile > calendar-input > span.label {
  margin-top: 7.5vw;
}
signup-add-player > form.mobile div.buttons-holder {
  margin-top: 7.5vw;
  width: 100%;
}
signup-add-player > form.mobile div.buttons-holder > button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
  width: 100%;
  max-width: none;
}
signup-add-player > form.mobile div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-add-player > form.mobile div.gender-select {
  margin-top: 7.5vw;
}
signup-add-player > form.mobile div.gender-select span.gender-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
signup-add-player > form.mobile div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 5vw;
}
signup-add-player-preview > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-add-player-preview > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-add-player-preview > form span.explanation-label {
  color: #999999;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
signup-add-player-preview > form div.names-input-holder {
  margin-top: 40px;
}
signup-add-player-preview > form div.names-input-holder > div {
  width: calc(50% - 8px);
  display: inline-block;
}
signup-add-player-preview > form div.names-input-holder > div:nth-child(2) {
  float: right;
}
@media (max-width: 445px) {
  signup-add-player-preview > form div.names-input-holder > div {
    width: 100%;
    display: block;
  }
  signup-add-player-preview > form div.names-input-holder > div:nth-child(2) {
    float: none;
    margin-top: 24px;
  }
}
signup-add-player-preview > form > text-input > span.label,
signup-add-player-preview > form > calendar-input > span.label,
signup-add-player-preview > form > error-message > div {
  margin-top: 24px;
}
signup-add-player-preview > form div.buttons-holder {
  margin-top: 32px;
}
signup-add-player-preview > form div.buttons-holder > button.btn.btn-primary {
  width: 170px;
  width: calc(50% - 8px);
}
signup-add-player-preview > form div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-add-player-preview > form div.buttons-holder > button.btn.btn-primary {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-add-player-preview > form div.buttons-holder > button.btn.btn-primary {
    width: 100%;
  }
  signup-add-player-preview > form div.buttons-holder > button.btn.btn-primary.cancel {
    margin-bottom: 24px;
  }
}
signup-add-player-preview > form div.gender-select {
  margin-top: 24px;
}
signup-add-player-preview > form div.gender-select span.gender-label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: block;
  text-align: left;
  width: auto;
}
signup-add-player-preview > form div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 16px;
}
signup-add-player-preview > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-add-player-preview > form.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 5vw;
}
signup-add-player-preview > form.mobile div.names-input-holder {
  margin-top: 12.5vw;
  display: grid;
}
signup-add-player-preview > form.mobile div.names-input-holder > div {
  width: 100%;
  display: block;
}
signup-add-player-preview > form.mobile div.names-input-holder > div:nth-child(1) {
  margin-bottom: 7.5vw;
}
signup-add-player-preview > form.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-add-player-preview > form.mobile > text-input > span.label,
signup-add-player-preview > form.mobile > calendar-input > span.label {
  margin-top: 7.5vw;
}
signup-add-player-preview > form.mobile div.buttons-holder {
  margin-top: 7.5vw;
  width: 100%;
}
signup-add-player-preview > form.mobile div.buttons-holder > button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
  width: 100%;
  max-width: none;
}
signup-add-player-preview > form.mobile div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-add-player-preview > form.mobile div.gender-select {
  margin-top: 7.5vw;
}
signup-add-player-preview > form.mobile div.gender-select span.gender-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
signup-add-player-preview > form.mobile div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 5vw;
}
signup-choose-role > div {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-choose-role > div span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-choose-role > div div.buttons-holder {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder {
  display: inline-block;
  width: calc(33% - 16px);
  max-width: 142px;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder div.role-button {
  width: 100%;
  padding-bottom: 96%;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder div.role-button.player-role:not(.selected) {
  background: url('../img/signup/player-role.svg');
  background-size: contain;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder div.role-button.player-role.selected {
  background: url('../img/signup/player-role-selected.svg');
  background-size: contain;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder div.role-button.parent-role:not(.selected) {
  background: url('../img/signup/parent-role.svg');
  background-size: contain;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder div.role-button.parent-role.selected {
  background: url('../img/signup/parent-role-selected.svg');
  background-size: contain;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder div.role-button.coach-role:not(.selected) {
  background: url('../img/signup/coach-role.svg');
  background-size: contain;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder div.role-button.coach-role.selected {
  background: url('../img/signup/coach-role-selected.svg');
  background-size: contain;
}
signup-choose-role > div div.buttons-holder > div.button-group-holder span.button-label {
  color: #4B4B4B;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 8px;
  display: block;
  text-align: center;
}
signup-choose-role > div button.btn.btn-primary {
  margin-top: 80px;
}
signup-choose-role > div.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-choose-role > div.mobile toggle-input {
  display: block;
  margin-top: 10vw;
}
signup-choose-role > div.mobile toggle-input > span.label.mobile {
  font-size: 6vw;
  margin-left: 2.5vw;
}
signup-choose-role > div.mobile button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  margin-top: 25vw;
  padding: 2.5vw 5vw;
}
signup-invite-code > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-invite-code > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-invite-code > form span.helper-label {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
  display: block;
}
signup-invite-code > form error-message > div {
  margin-top: 24px;
}
signup-invite-code > form text-input > span.label {
  margin-top: 40px;
}
signup-invite-code > form div.buttons-holder {
  margin-top: 40px;
}
signup-invite-code > form div.buttons-holder > button.btn {
  width: 170px;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-invite-code > form div.buttons-holder > button.btn {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-invite-code > form div.buttons-holder > button.btn {
    width: 100%;
  }
  signup-invite-code > form div.buttons-holder > button.btn.cancel {
    margin-bottom: 24px;
  }
}
signup-invite-code > form span.skip-step {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 32px;
  text-align: right;
}
signup-invite-code > form span.skip-step:hover {
  text-decoration: underline;
}
signup-invite-code > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-invite-code > form.mobile > span.helper-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
}
signup-invite-code > form.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-invite-code > form.mobile text-input > span.label {
  margin-top: 12.5vw;
}
signup-invite-code > form.mobile div.buttons-holder {
  margin-top: 12.5vw;
}
signup-invite-code > form.mobile div.buttons-holder > button.btn.btn-primary {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
signup-invite-code > form.mobile div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-invite-code > form.mobile span.skip-step {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 10vw;
  float: none;
  transform: translateX(-35%);
  display: block;
}
signup-login > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-login > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-login > form text-input > span.label,
signup-login > form error-message > div,
signup-login > form toggle-input > span.label {
  margin-top: 24px;
}
signup-login > form toggle-input > span.label {
  display: inline-block;
  width: calc(100% - 30px);
}
signup-login > form button.btn {
  margin-top: 40px;
}
signup-login > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-login > form.mobile error-message > div,
signup-login > form.mobile text-input > span.label,
signup-login > form.mobile toggle-input > span.label {
  margin-top: 7.5vw;
}
signup-login > form.mobile toggle-input > span.label {
  width: calc(100% - 100px);
}
signup-login > form.mobile button.btn {
  margin-top: 12.5vw;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
signup-logo-panel > div {
  width: 100%;
  height: 100%;
  background-color: #010033;
  position: relative;
}
signup-logo-panel > div div.logo-holder {
  width: 100%;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
signup-logo-panel > div div.logo-holder > img {
  max-width: 230px;
  max-height: 140px;
  display: block;
  margin-left: 50%;
  transform: translateX(-50%);
}
signup-logo-panel > div div.logo-holder > span.main-label {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 64px;
}
signup-logo-panel > div div.logo-holder > span.helper-label {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  display: block;
  width: 100%;
}
signup-logo-panel > div.mobile {
  height: 75vw;
}
signup-logo-panel > div.mobile div.logo-holder > img {
  max-width: 60vw;
  max-height: 25vw;
}
signup-logo-panel > div.mobile div.logo-holder > span.main-label {
  margin-top: 10vw;
  font-size: 7.5vw;
  line-height: 10vw;
}
signup-logo-panel > div.mobile div.logo-holder > span.helper-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 5vw;
}
signup-parent-contact > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-parent-contact > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-parent-contact > form span.explanation-label {
  color: #999999;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
signup-parent-contact > form text-input > span.label {
  margin-top: 24px;
}
signup-parent-contact > form div.buttons-holder {
  margin-top: 32px;
}
signup-parent-contact > form div.buttons-holder > button.btn.btn-primary {
  width: 170px;
  width: calc(50% - 8px);
}
signup-parent-contact > form div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-parent-contact > form div.buttons-holder > button.btn.btn-primary {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-parent-contact > form div.buttons-holder > button.btn.btn-primary {
    width: 100%;
  }
  signup-parent-contact > form div.buttons-holder > button.btn.btn-primary.cancel {
    margin-bottom: 24px;
  }
}
signup-parent-contact > form span.tos-label {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 32px;
  display: block;
}
signup-parent-contact > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-parent-contact > form.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 5vw;
}
signup-parent-contact > form.mobile text-input > span.label {
  margin-top: 7.5vw;
}
signup-parent-contact > form.mobile div.buttons-holder {
  margin-top: 7.5vw;
  width: 100%;
}
signup-parent-contact > form.mobile div.buttons-holder > button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
  width: 100%;
  max-width: none;
}
signup-parent-contact > form.mobile div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-parent-contact > form.mobile span.tos-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 10vw;
}
signup-payment > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-payment > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-payment > form span.explanation-label {
  color: #999999;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
  margin-bottom: 16px;
}
signup-payment > form text-input > span.label {
  margin-top: 24px;
}
signup-payment > form div.expiry-date-cvv-holder {
  margin-top: 40px;
}
signup-payment > form div.expiry-date-cvv-holder > div {
  width: calc(50% - 8px);
  display: inline-block;
}
signup-payment > form div.expiry-date-cvv-holder > div:nth-child(2) {
  float: right;
}
signup-payment > form button.btn {
  margin-top: 40px;
}
signup-payment > form error-message > div {
  margin-top: 24px;
}
signup-payment > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-payment > form.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
}
signup-payment > form.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-payment > form.mobile text-input > span.label {
  margin-top: 12.5vw;
}
signup-payment > form.mobile > button.btn.btn-primary {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
signup-payment > form.mobile > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-player-contact > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-player-contact > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-player-contact > form span.explanation-label {
  color: #999999;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
signup-player-contact > form > text-input > span.label {
  margin-top: 24px;
}
signup-player-contact > form div.buttons-holder {
  margin-top: 32px;
}
signup-player-contact > form div.buttons-holder > button.btn.btn-primary {
  width: 170px;
  width: calc(50% - 8px);
}
signup-player-contact > form div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-player-contact > form div.buttons-holder > button.btn.btn-primary {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-player-contact > form div.buttons-holder > button.btn.btn-primary {
    width: 100%;
  }
  signup-player-contact > form div.buttons-holder > button.btn.btn-primary.cancel {
    margin-bottom: 24px;
  }
}
signup-player-contact > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-player-contact > form.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 5vw;
}
signup-player-contact > form.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-player-contact > form.mobile > text-input > span.label {
  margin-top: 7.5vw;
}
signup-player-contact > form.mobile div.buttons-holder {
  margin-top: 7.5vw;
  width: 100%;
}
signup-player-contact > form.mobile div.buttons-holder > button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
  width: 100%;
  max-width: none;
}
signup-player-contact > form.mobile div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-player-date-of-birth-and-country > form {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-player-date-of-birth-and-country > form span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-player-date-of-birth-and-country > form span.explanation-label {
  color: #999999;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
signup-player-date-of-birth-and-country > form calendar-input > span.label,
signup-player-date-of-birth-and-country > form country-input > span.label {
  margin-top: 24px;
}
signup-player-date-of-birth-and-country > form div.buttons-holder {
  margin-top: 32px;
}
signup-player-date-of-birth-and-country > form div.buttons-holder > button.btn.btn-primary {
  width: 170px;
  width: calc(50% - 8px);
}
signup-player-date-of-birth-and-country > form div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-player-date-of-birth-and-country > form div.buttons-holder > button.btn.btn-primary {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-player-date-of-birth-and-country > form div.buttons-holder > button.btn.btn-primary {
    width: 100%;
  }
  signup-player-date-of-birth-and-country > form div.buttons-holder > button.btn.btn-primary.cancel {
    margin-bottom: 24px;
  }
}
signup-player-date-of-birth-and-country > form span.tos-label {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 32px;
  display: block;
}
signup-player-date-of-birth-and-country > form.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-player-date-of-birth-and-country > form.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 5vw;
}
signup-player-date-of-birth-and-country > form.mobile calendar-input > span.label,
signup-player-date-of-birth-and-country > form.mobile country-input > span.label {
  margin-top: 7.5vw;
}
signup-player-date-of-birth-and-country > form.mobile div.buttons-holder {
  margin-top: 7.5vw;
  width: 100%;
}
signup-player-date-of-birth-and-country > form.mobile div.buttons-holder > button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
  width: 100%;
  max-width: none;
}
signup-player-date-of-birth-and-country > form.mobile div.buttons-holder > button.btn.btn-primary:not(.cancel) {
  margin-top: 5vw;
}
signup-player-date-of-birth-and-country > form.mobile span.tos-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 10vw;
}
signup-select-org > div {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-select-org > div span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-select-org > div span.explanation-label {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
  display: block;
}
signup-select-org > div div.buttons-holder {
  margin-top: 40px;
}
signup-select-org > div div.buttons-holder > button.btn {
  width: 170px;
}
signup-select-org > div div.buttons-holder > button.btn:nth-child(2) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-select-org > div div.buttons-holder > button.btn {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-select-org > div div.buttons-holder > button.btn {
    width: 100%;
  }
  signup-select-org > div div.buttons-holder > button.btn.cancel {
    margin-bottom: 24px;
  }
}
signup-select-org > div span.skip-step {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 32px;
  text-align: right;
}
signup-select-org > div span.skip-step:hover {
  text-decoration: underline;
}
signup-select-org > div spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
signup-select-org > div error-message > div {
  margin-top: 24px;
}
signup-select-org > div.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-select-org > div.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
}
signup-select-org > div.mobile text-input > span.label {
  margin-top: 12.5vw;
}
signup-select-org > div.mobile div.buttons-holder {
  margin-top: 12.5vw;
  display: grid;
}
signup-select-org > div.mobile div.buttons-holder > button.btn.btn-primary {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
signup-select-org > div.mobile div.buttons-holder > button.btn.btn-primary:nth-child(2) {
  margin-top: 5vw;
}
signup-select-org > div.mobile span.skip-step {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 10vw;
  float: none;
  transform: translateX(-35%);
  display: block;
}
signup-select-org > div.mobile spinner > div,
signup-select-org > div.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-select-team > div {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-select-team > div span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-select-team > div span.explanation-label {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 40px;
  display: block;
}
signup-select-team > div > div.list-header {
  display: block;
  width: 100%;
  margin-top: 24px;
  background-color: rgba(229, 229, 229, 0.3);
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 24px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-select-team > div info-message > span {
  margin-top: 24px;
}
signup-select-team > div spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
signup-select-team > div > div.items-holder {
  max-height: 640px;
  overflow-y: auto;
}
signup-select-team > div div.buttons-holder {
  margin-top: 40px;
}
signup-select-team > div div.buttons-holder > button.btn {
  width: 170px;
}
signup-select-team > div div.buttons-holder > button.btn:nth-child(2) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-select-team > div div.buttons-holder > button.btn {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-select-team > div div.buttons-holder > button.btn {
    width: 100%;
  }
  signup-select-team > div div.buttons-holder > button.btn.cancel {
    margin-bottom: 24px;
  }
}
signup-select-team > div span.skip-step {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 32px;
  text-align: right;
}
signup-select-team > div span.skip-step:hover {
  text-decoration: underline;
}
signup-select-team > div.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-select-team > div.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
}
signup-select-team > div.mobile > span.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
  margin-bottom: 5vw;
}
signup-select-team > div.mobile text-input > span.label {
  margin-top: 12.5vw;
}
signup-select-team > div.mobile div.buttons-holder {
  margin-top: 12.5vw;
  display: grid;
}
signup-select-team > div.mobile div.buttons-holder > button.btn.btn-primary {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
signup-select-team > div.mobile div.buttons-holder > button.btn.btn-primary:nth-child(2) {
  margin-top: 5vw;
}
signup-select-team > div.mobile span.skip-step {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 10vw;
  float: none;
  transform: translateX(-35%);
  display: block;
}
signup-select-team > div.mobile > div.list-header {
  margin-top: 7.5vw;
  padding: 5vw 7.5vw 5vw 7.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
}
signup-select-team > div info-message > 7.5vw {
  margin-top: 24px;
}
signup-subscription-summary > div {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-subscription-summary > div span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-subscription-summary > div span.helper-label {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
  display: block;
}
signup-subscription-summary > div error-message > div {
  margin-top: 24px;
}
signup-subscription-summary > div spinner > div {
  margin-top: 24px;
  margin-left: 50%;
  transform: translateX(-50%);
}
signup-subscription-summary > div dropdown-input > div {
  margin-top: 40px;
}
signup-subscription-summary > div div.table-header {
  height: 56px;
  width: 100%;
  margin-top: 16px;
  border-bottom: 1px solid #e5e5e5;
}
signup-subscription-summary > div div.table-header > span.table-title {
  display: inline-block;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 28px;
  transform: translateY(-50%);
}
signup-subscription-summary > div div.table-header > span.table-title:last-child {
  text-align: right;
}
signup-subscription-summary > div div.product-details {
  min-height: 64px;
  padding: 8px 0;
  border-bottom: 1px solid #e5e5e5;
}
signup-subscription-summary > div div.product-details > div.name-description {
  width: 40%;
  display: inline-block;
}
signup-subscription-summary > div div.product-details > div.name-description > div > span.name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;
  text-transform: uppercase;
}
signup-subscription-summary > div div.product-details > div.name-description > div > span.description {
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-subscription-summary > div div.product-details > div.billing {
  width: 30%;
  display: inline-block;
}
signup-subscription-summary > div div.product-details > div.billing > span {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-subscription-summary > div div.product-details > div.qty {
  width: 15%;
  display: inline-block;
}
signup-subscription-summary > div div.product-details > div.qty > span {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-subscription-summary > div div.product-details > div.product-price {
  width: 15%;
  display: inline-block;
  text-align: right;
}
signup-subscription-summary > div div.product-details > div.product-price > span {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-subscription-summary > div div.subtotal {
  margin-top: 24px;
}
signup-subscription-summary > div div.subtotal > span {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-subscription-summary > div div.subtotal > span.subtotal-value {
  float: right;
}
signup-subscription-summary > div div.total {
  min-height: 42px;
  border-top: 1px solid #e5e5e5;
  margin-top: 24px;
}
signup-subscription-summary > div div.total > span {
  color: #333333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
  float: left;
  margin-top: 42px;
  transform: translateY(-100%);
}
signup-subscription-summary > div div.total > span.total-value {
  float: right;
}
signup-subscription-summary > div button.btn {
  margin-top: 40px;
}
signup-subscription-summary > div.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-subscription-summary > div.mobile > span.helper-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
}
signup-subscription-summary > div.mobile dropdown-input > div {
  margin-top: 12.5vw;
}
signup-subscription-summary > div.mobile div.product-details-mobile {
  margin-top: 12.5vw;
}
signup-subscription-summary > div.mobile div.product-details-mobile > div.detail-holder {
  padding: 2.5vw 0;
  border-bottom: 2px solid #e5e5e5;
}
signup-subscription-summary > div.mobile div.product-details-mobile > div.detail-holder > span.label {
  color: #999999;
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 6.6vw;
  padding: 0;
}
signup-subscription-summary > div.mobile div.product-details-mobile > div.detail-holder > span.value {
  color: #333333;
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 6.6vw;
  float: right;
}
signup-subscription-summary > div.mobile div.subtotal {
  margin-top: 7.5vw;
}
signup-subscription-summary > div.mobile div.subtotal > span {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
signup-subscription-summary > div.mobile div.total {
  min-height: 12.5vw;
  margin-top: 7.5vw;
}
signup-subscription-summary > div.mobile div.total > span {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 12.5vw;
}
signup-subscription-summary > div.mobile button.btn.btn-primary {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
signup-summary > div {
  width: 100%;
  margin: 64px 0;
  display: grid;
}
signup-summary > div span.view-title {
  color: #333333;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  display: block;
}
signup-summary > div span.helper-label {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
  display: block;
}
signup-summary > div div.buttons-holder {
  margin-top: 40px;
}
signup-summary > div div.buttons-holder > button.btn {
  width: 170px;
}
signup-summary > div div.buttons-holder > button.btn:nth-child(2) {
  float: right;
}
@media (max-width: 1274px) and (min-width: 1200px) {
  signup-summary > div div.buttons-holder > button.btn {
    width: 116px;
  }
}
@media (max-width: 445px) {
  signup-summary > div div.buttons-holder > button.btn {
    width: 100%;
  }
  signup-summary > div div.buttons-holder > button.btn.cancel {
    margin-bottom: 24px;
  }
}
signup-summary > div error-message > div {
  margin-top: 24px;
}
signup-summary > div.mobile span.view-title {
  margin-top: 12.5vw;
  font-size: 8.75vw;
  line-height: 11vw;
}
signup-summary > div.mobile > span.helper-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
}
signup-summary > div.mobile div.buttons-holder {
  margin-top: 12.5vw;
  display: grid;
}
signup-summary > div.mobile div.buttons-holder > button.btn.btn-primary {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
signup-summary > div.mobile div.buttons-holder > button.btn.btn-primary:nth-child(2) {
  margin-top: 5vw;
}
signup-summary > div.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-tabs > div {
  width: 100%;
  display: block;
}
signup-tabs > div > signup-tab:not(:first-child) {
  margin-left: 56px;
}
signup-tabs > div.mobile > signup-tab:not(:first-child) {
  margin-left: 17.5vw;
}
.purchase-modal-title {
  color: navy;
}
player-freestyle-detail > div {
  padding-bottom: 40px;
}
card-title > div {
  display: inline-block;
  width: 100%;
  color: #FFF;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 16px 24px;
  background-color: #003;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-transform: uppercase;
}
card-title > div img {
  float: right;
  margin-right: -8px;
}
welcome-card > div.card {
  padding: 32px;
  color: #333;
  background-image: url('../img/icon-football.png');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 32px);
  background-position-y: 32px;
  width: 100%;
}
welcome-card > div.card h1.title {
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 44px;
}
welcome-card > div.card h3.subtitle {
  margin-top: 42px;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 27px;
}
welcome-card > div.card p {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 75%;
}
@media (max-width: 1130px) {
  welcome-card > div.card p {
    max-width: 100%;
  }
}
welcome-card > div.card h3.download-title {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
welcome-card > div.card div.text-holder {
  width: 63%;
  display: inline-block;
}
@media (max-width: 1130px) {
  welcome-card > div.card div.text-holder {
    width: 100%;
    display: block;
  }
}
welcome-card > div.card div.buttons-holder {
  width: 37%;
  display: inline-block;
}
@media (max-width: 1130px) {
  welcome-card > div.card div.buttons-holder {
    width: 100%;
    display: block;
  }
}
welcome-card > div.card app-store-download-button > button,
welcome-card > div.card google-play-download-button > button {
  margin-top: 24px;
}
welcome-card > div.card google-play-download-button > button {
  margin-left: 24px;
}
welcome-card > div.card.mobile {
  padding: 10vw;
  background-size: 100%;
  background-position-x: calc(100% + 10vw);
  background-position-y: calc(100% + 40vw);
}
welcome-card > div.card.mobile h1.title {
  font-size: 10vw;
  line-height: 13.75vw;
}
welcome-card > div.card.mobile h3.subtitle {
  margin-top: 13.75vw;
  font-size: 6.25vw;
  line-height: 7.5vw;
}
welcome-card > div.card.mobile p {
  margin-top: 7.5vw;
  font-size: 5vw;
  line-height: 7.5vw;
  max-width: 100%;
}
welcome-card > div.card.mobile div.text-holder {
  width: 100%;
  display: block;
}
welcome-card > div.card.mobile div.buttons-holder {
  width: 100%;
  display: block;
}
welcome-card > div.card.mobile h3.download-title {
  margin-top: 24vw;
  font-size: 5vw;
  line-height: 7.5vw;
}
welcome-card > div.card.mobile app-store-download-button > button,
welcome-card > div.card.mobile google-play-download-button > button {
  margin-top: 5vw;
  width: calc(100% - 22vw);
  margin-left: 50%;
  transform: translateX(-50%);
}
category-selection-tree-item > div > div.item {
  padding: 0px 10px;
  color: #5c5c5c;
  border-width: 1px;
  border-style: solid;
  border-color: #dae2ea;
  border-image: initial;
  margin: 5px 0px;
  border-radius: 8px;
}
category-selection-tree-item > div > div.item a.expand {
  color: #5c5c5c;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  padding: 0px;
}
category-selection-tree-item > div > div.item div.item-clickable {
  display: inline-block;
  width: calc(100% - 29px);
  padding: 10px 0px;
}
category-selection-tree-item > div > div.item div.item-clickable.full-width {
  width: 100%;
  padding-left: 29px;
}
category-selection-tree-item > div > div.item div.item-clickable h5 {
  display: inline-flex;
}
category-selection-tree-item > div > div.item div.item-clickable h5:hover {
  cursor: pointer;
  text-decoration: underline;
}
category-selection-tree-item > div > div.item a.btn.toggle-selection-button {
  padding-left: 1.5px;
  padding-top: 2px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;
  float: right;
}
category-selection-tree-item > div > div.item a.btn.toggle-selection-button > span.glyphicon {
  margin-left: 50%;
  transform: translateX(-45%);
}
category-selection-tree-item > div > div.item a.btn.toggle-selection-button > span.glyphicon.glyphicon-minus {
  margin-left: 8px;
}
category-selection-tree-item > div > div.item a.btn.toggle-selection-button > span.glyphicon.circle-filled {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  margin-left: 8px;
}
category-selection-tree-item > div > div.item a.btn.toggle-selection-button > span.glyphicon.circle-empty {
  width: 16px;
  height: 16px;
  border: 2px solid white;
  border-radius: 50%;
  margin-left: 8px;
}
category-selection-tree-item > div > div.item a.btn.toggle-selection-button.btn-default {
  background-color: #9b9b9b;
  color: #ffffff;
}
.edit-channel-filler-content input {
  border-radius: 4px;
}
.edit-channel-filler-content .channel-videos-count span,
.edit-channel-filler-content .channel-videos-count input {
  display: inline-block;
}
.edit-channel-filler-content .channel-videos-count input {
  width: 80px;
  margin-left: 5px;
  margin-right: 5px;
}
drop-area-item > div.container-fluid {
  padding-top: 15px;
  padding-bottom: 15px;
}
drop-area-item > div.container-fluid.gray-background {
  background: rgba(215, 215, 215, 0.8);
}
drop-area-item > div.container-fluid.white-background {
  background: rgba(255, 255, 255, 0.8);
}
drop-area-item > div.container-fluid .item-property {
  padding: 0 15px;
  display: inline-block;
}
calendar-input > span.label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: inline-block;
  text-align: left;
  width: auto;
}
calendar-input > span.label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
calendar-input > span.helper-label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 8px;
  margin-bottom: 8px;
  text-align: left;
  width: auto;
  padding: 0;
}
calendar-input > span.helper-label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
  margin-left: 2.5vw;
}
calendar-input input.form-control {
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  background-color: #FFF;
  padding-right: 50px;
  height: 40px;
  background-image: url('../img/Icon/Content/Gray/schedule.png');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 12px);
  background-position-y: 8px;
}
calendar-input > div.input-holder {
  position: relative;
  display: block;
  width: 100%;
}
calendar-input > div.input-holder.mobile > input.form-control {
  font-size: 5.6vw;
  line-height: 7.8vw;
  margin-bottom: 2.5vw;
  height: 10vw;
  border-width: 2px;
  border-radius: 0.6vw;
  padding-right: 15vw;
  background-size: 8vw;
}
calendar-input > div.input-holder.mobile .bootstrap-datetimepicker-widget button[data-action] {
  font-size: 27px;
  padding: 2.5vw;
}
calendar-input > div.input-holder.mobile .bootstrap-datetimepicker-widget table th,
calendar-input > div.input-holder.mobile .bootstrap-datetimepicker-widget table td {
  padding-bottom: 2.5vw;
  padding-top: 2.5vw;
  font-size: 32px;
}
calendar-input > div.input-holder.mobile .bootstrap-datetimepicker-widget table td span {
  font-size: 32px;
  width: auto;
  margin: 16px;
}
calendar-input > div.input-holder div.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 100%;
}
calendar-input .dropdown-menu button {
  width: auto;
}
calendar-input ul.dropdown-menu:before {
  right: 9px;
}
calendar-input ul.dropdown-menu:after {
  right: 7px;
}
calendar-input .local-time-warning.message-hidden {
  opacity: 0;
}
country-input > span.label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: inline-block;
  text-align: left;
  width: auto;
}
country-input > span.label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
  display: block;
}
country-input > span.helper-label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 8px;
  margin-bottom: 8px;
  text-align: left;
  width: auto;
  padding: 0;
}
country-input > span.helper-label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: -2vw;
  margin-bottom: 2.5vw;
  margin-left: 2.5vw;
  display: block;
  margin-left: 0;
}
country-input > input.form-control {
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  background-color: #FFF;
  height: 40px;
}
country-input > input.form-control.search-input {
  background-image: url('../img/magnifer_icon.png');
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 10px;
  padding-left: 35px;
}
country-input > input.form-control.mobile {
  font-size: 5.6vw;
  line-height: 7.8vw;
  margin-bottom: 2.5vw;
  height: 10vw;
  border-width: 2px;
  border-radius: 0.6vw;
}
country-input > input.form-control.mobile.search-input {
  background-position-x: 3vw;
  background-position-y: 3vw;
  background-size: 4vw;
  padding-left: 10vw;
}
@media only screen and (hover: none) and (pointer: coarse) {
  country-input ul.dropdown-menu {
    font-size: 48px;
  }
}
dropdown-input > div.btn-group > button.btn.btn-default {
  width: auto;
  padding: 0;
}
dropdown-input > div.btn-group > button.btn.btn-default:hover,
dropdown-input > div.btn-group > button.btn.btn-default:focus,
dropdown-input > div.btn-group > button.btn.btn-default:active {
  background-color: #fff;
  border: none;
  box-shadow: none;
}
dropdown-input > div.btn-group > button.btn.btn-default > span.btn-text {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
dropdown-input > div.btn-group > button.btn.btn-default > span.caret {
  margin-left: 16px;
  color: #cbcbcb;
}
dropdown-input > div.btn-group ul.dropdown-menu {
  border-radius: 0;
}
dropdown-input > div.btn-group ul.dropdown-menu::before,
dropdown-input > div.btn-group ul.dropdown-menu::after {
  display: none;
}
dropdown-input > div.btn-group ul.dropdown-menu > li {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
dropdown-input > div.btn-group.mobile > button.btn.btn-default > span.btn-text {
  font-size: 5vw;
  line-height: 7.5vw;
}
dropdown-input > div.btn-group.mobile > button.btn.btn-default > span.caret {
  margin-left: 5vw;
  border-left-width: 20px;
  border-right-width: 20px;
  border-top-width: 20px;
  vertical-align: initial;
}
dropdown-input > div.btn-group.mobile ul.dropdown-menu > li {
  padding: 2.5vw 5vw;
  font-size: 5vw;
  line-height: 7.5vw;
}
select-input > span.label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: inline-block;
  text-align: left;
  width: auto;
}
select-input > span.label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
  display: block;
}
select-input > select.form-control {
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  background-color: #FFF;
  height: 40px;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
select-input > select.form-control.mobile {
  font-size: 5.6vw;
  line-height: 7.8vw;
  margin-bottom: 2.5vw;
  height: 10vw;
  border-width: 2px;
  border-radius: 0.6vw;
}
select-input > select.form-control.mobile > option {
  font-size: 2vw;
  line-height: 2vw;
}
slide-toggle-input > div {
  width: 35px;
  height: 20px;
  border-radius: 10px;
  background-color: #cbcbcb;
  position: relative;
  display: inline-block;
}
slide-toggle-input > div > div.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
}
slide-toggle-input > div.active {
  background-color: #4F82E4;
}
slide-toggle-input > div.active > div.circle {
  left: auto;
  right: 2px;
}
slide-toggle-input > div.mobile {
  width: 11.25vw;
  height: 6.25vw;
  border-radius: 3.125vw;
}
slide-toggle-input > div.mobile > div.circle {
  width: 5vw;
  height: 5vw;
  top: 0.625vw;
  left: 0.625vw;
}
slide-toggle-input > div.mobile.active > div.circle {
  right: 0.625vw;
  left: auto;
}
slide-toggle-input > span {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: super;
}
slide-toggle-input > span.mobile {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-left: 2.5vw;
  width: calc(100% - 13.75vw);
  vertical-align: top;
}
text-input > span.label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: inline-block;
  text-align: left;
  width: auto;
}
text-input > span.label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
  display: block;
}
text-input > span.helper-label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 8px;
  margin-bottom: 8px;
  text-align: left;
  width: auto;
  padding: 0;
}
text-input > span.helper-label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: -2vw;
  margin-bottom: 2.5vw;
  margin-left: 2.5vw;
  display: block;
  margin-left: 0;
}
text-input > input.form-control {
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  background-color: #FFF;
  height: 40px;
}
text-input > input.form-control.search-input {
  background-image: url('../img/magnifer_icon.png');
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 10px;
  padding-left: 35px;
}
text-input > input.form-control.mobile {
  font-size: 5.6vw;
  line-height: 7.8vw;
  margin-bottom: 2.5vw;
  height: 10vw;
  border-width: 2px;
  border-radius: 0.6vw;
}
text-input > input.form-control.mobile.search-input {
  background-position-x: 3vw;
  background-position-y: 3vw;
  background-size: 4vw;
  padding-left: 10vw;
}
text-input > span.validation-message {
  float: right;
  font-size: 10px;
  margin-top: 8px;
  color: red;
}
text-input > span.validation-message.mobile {
  font-size: 3vw;
}
toggle-input > span.label {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: inline-block;
  text-align: left;
  width: auto;
}
toggle-input > span.label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
toggle-input > span.helper-label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 8px;
  margin-bottom: 8px;
  text-align: left;
  width: auto;
  padding: 0;
}
toggle-input > span.helper-label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
toggle-input > span.toggle-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: text-bottom;
  background: url('../img/Icon/Toggle/Blue/radio-unchecked-blue.png');
  background-position: center;
  background-size: cover;
}
toggle-input > span.toggle-icon.selected {
  background: url('../img/Icon/Toggle/Blue/radio-checked-blue.png');
  background-position: center;
  background-size: cover;
}
toggle-input > span.toggle-icon.disabled {
  cursor: default;
  background: url('../img/Icon/Toggle/Gray/radio-unchecked.png');
  background-position: center;
  background-size: cover;
}
toggle-input > span.toggle-icon.selected.disabled {
  background: url('../img/Icon/Toggle/Gray/radio-checked.png');
  background-position: center;
  background-size: cover;
}
toggle-input > span.toggle-icon.mobile {
  width: 5vw;
  height: 5vw;
  vertical-align: text-bottom;
}
typeahead-input > div {
  max-width: 400px;
  position: relative;
}
typeahead-input > div > span.label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: inline-block;
  text-align: left;
  width: auto;
}
typeahead-input > div > span.label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
  display: block;
}
typeahead-input > div > span.helper-label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 8px;
  margin-bottom: 8px;
  text-align: left;
  width: auto;
  padding: 0;
}
typeahead-input > div > span.helper-label.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: -2vw;
  margin-bottom: 2.5vw;
  margin-left: 2.5vw;
  display: block;
  margin-left: 0;
}
typeahead-input > div > input.form-control {
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  background-color: #FFF;
  height: 40px;
}
typeahead-input > div > input.form-control.mobile {
  font-size: 5.6vw;
  line-height: 7.8vw;
  margin-bottom: 2.5vw;
  height: 10vw;
  border-width: 2px;
  border-radius: 0.6vw;
}
typeahead-input > div > div.results {
  width: 100%;
  display: block;
  background: #fff;
  position: absolute;
  left: 0;
  transform: translateY(-22px);
  border: 1px solid #e5e5e5;
  z-index: 2000;
  max-height: 240px;
  overflow-y: auto;
  -webkit-box-shadow: 3px 3px 5px 0px #e5e5e5;
  -moz-box-shadow: 3px 3px 5px 0px #e5e5e5;
  box-shadow: 3px 3px 5px 0px #e5e5e5;
}
typeahead-input > div > div.results > span.result {
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
typeahead-input > div > div.results > span.result:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
typeahead-input > div > div.results > span.result:hover,
typeahead-input > div > div.results > span.result.focused {
  color: #fff;
  background-color: #306DB2;
}
players-list-item > div {
  padding: 15px 0;
}
players-list-item > div.gray-background {
  background: #d7d7d7;
}
players-list-item > div.cursor-draggable {
  cursor: grab;
}
.contest-navbar {
  margin-top: 0;
  left: 0;
  top: 0;
  height: 100px;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-size: 9pt;
  border: none;
  position: absolute;
  width: 100%;
  z-index: 1100;
}
.contest-navbar .row.padded-row {
  padding-left: 15px;
  padding-right: 15px;
}
.contest-navbar .sponsors-height-holder {
  min-height: 60px;
}
.contest-navbar .logos-holder {
  z-index: 11;
}
.contest-navbar .logo {
  max-width: 112px;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.contest-navbar .header-background-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 51, 0.6);
}
.contest-navbar.docked {
  position: fixed;
}
.contest-navbar.docked .header-background-holder {
  background-color: #003;
}
.contest-navbar.docked-add,
.contest-navbar.docked-remove {
  opacity: 0;
  -o-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.contest-navbar.docked-add-active,
.contest-navbar.docked-remove-active {
  opacity: 1;
}
.contest-navbar .contest-logo-holder {
  position: absolute;
  width: 15em;
  background-color: #fff;
  height: 15em;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}
.contest-navbar .contest-logo-holder .contest-logo {
  position: absolute;
  width: 10em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
@media (max-width: 1200px) {
  .contest-navbar .contest-logo-holder {
    width: 10em;
    height: 10em;
  }
  .contest-navbar .contest-logo-holder .contest-logo {
    width: 8em;
  }
}
@media (max-width: 991px) {
  .contest-navbar .contest-logo-holder {
    width: 8em;
    height: 8em;
  }
  .contest-navbar .contest-logo-holder .contest-logo {
    width: 6em;
  }
}
.contest-navbar.mobile {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;
}
.contest-navbar.mobile .logo {
  max-width: 75%;
}
.contest-navbar.mobile .container .contest-logo-holder {
  width: 80px;
  height: 80px;
  margin-top: -5px;
}
.contest-navbar.mobile .container .contest-logo-holder .contest-logo {
  width: 50px;
}
.contest-navbar.mobile .primary-sponsor-holder {
  display: block;
  margin-top: 30px;
  transform: translateY(-50%);
}
.contest-org-select-dropdown .header {
  padding: 10px 0;
}
.contest-org-select-dropdown .header hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.contest-org-select-dropdown a {
  color: dodgerblue;
}
.contest-org-select-dropdown .scroll-holder {
  margin-top: 15px;
  overflow-y: auto;
}
.contest-org-select-dropdown .list-item {
  padding: 11px 0;
  border-bottom: 1px solid #eee;
  position: relative;
}
.contest-org-select-dropdown .list-item .clickable-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
}
.contest-org-select-dropdown .list-item span {
  font-weight: bold;
  display: inline-block;
  font-size: 12px;
  width: calc(100% - 24px);
}
.contest-org-select-dropdown .list-item label {
  vertical-align: top;
}
.contest-org-select-dropdown .list-item [type="radio"]:checked,
.contest-org-select-dropdown .list-item [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.contest-org-select-dropdown .list-item [type="radio"]:checked + label,
.contest-org-select-dropdown .list-item [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.contest-org-select-dropdown .list-item [type="radio"]:checked + label:before,
.contest-org-select-dropdown .list-item [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 5px;
  width: 14px;
  height: 14px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.contest-org-select-dropdown .list-item [type="radio"]:checked + label:after,
.contest-org-select-dropdown .list-item [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #40d61f;
  position: absolute;
  top: 3px;
  right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.contest-org-select-dropdown .list-item [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.contest-org-select-dropdown .list-item [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.contest-org-select-dropdown .loading-overlay {
  position: absolute;
  background-color: rgba(220, 220, 220, 0.9);
  text-align: center;
}
.contest-org-select-dropdown .loading-overlay span {
  transform: translateY(-50%);
  display: block;
  font-weight: bold;
}
.contest-widget-dropdown .contest-org-select-dropdown .header {
  padding: 0;
}
.contest-widget-dropdown .contest-org-select-dropdown .header h5 {
  font-size: 12px;
}
.contest-widget-dropdown .contest-org-select-dropdown input.form-control {
  padding: 3px;
  height: 28px;
}
.contest-widget-dropdown .contest-org-select-dropdown .scroll-holder {
  margin-top: 10px;
  max-height: 100px;
}
.contest-widget-dropdown .contest-org-select-dropdown .list-item {
  padding: 10px 0;
}
.contest-widget-dropdown .contest-org-select-dropdown a {
  font-size: 10px;
}
.contest-org-select-dropdown.mobile .scroll-holder,
.contest-widget-dropdown .contest-org-select-dropdown.mobile .scroll-holder {
  -webkit-overflow-scrolling: touch;
}
.contest-org-select-dropdown.mobile {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
}
.contest-team-select-dropdown .header {
  padding: 10px 0;
}
.contest-team-select-dropdown .header hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.contest-team-select-dropdown .scroll-holder {
  margin-top: 15px;
  overflow-y: auto;
}
.contest-team-select-dropdown .list-item {
  padding: 11px 0;
  border-bottom: 1px solid #eee;
}
.contest-team-select-dropdown .list-item span {
  font-weight: bold;
  display: inline-block;
  font-size: 12px;
  width: calc(100% - 24px);
}
.contest-team-select-dropdown .list-item label {
  vertical-align: top;
}
.contest-team-select-dropdown .list-item [type="radio"]:checked,
.contest-team-select-dropdown .list-item [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.contest-team-select-dropdown .list-item [type="radio"]:checked + label,
.contest-team-select-dropdown .list-item [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.contest-team-select-dropdown .list-item [type="radio"]:checked + label:before,
.contest-team-select-dropdown .list-item [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 5px;
  width: 14px;
  height: 14px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.contest-team-select-dropdown .list-item [type="radio"]:checked + label:after,
.contest-team-select-dropdown .list-item [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #40d61f;
  position: absolute;
  top: 3px;
  right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.contest-team-select-dropdown .list-item [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.contest-team-select-dropdown .list-item [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.contest-team-select-dropdown .loading-overlay {
  position: absolute;
  background-color: rgba(220, 220, 220, 0.9);
  text-align: center;
}
.contest-team-select-dropdown .loading-overlay span {
  transform: translateY(-50%);
  display: block;
  font-weight: bold;
}
.contest-widget-dropdown .contest-team-select-dropdown .header {
  padding: 0;
}
.contest-widget-dropdown .contest-team-select-dropdown .header h5 {
  font-size: 12px;
}
.contest-widget-dropdown .contest-team-select-dropdown input.form-control {
  padding: 3px;
  height: 28px;
}
.contest-widget-dropdown .contest-team-select-dropdown .scroll-holder {
  margin-top: 10px;
  max-height: 100px;
}
.contest-widget-dropdown .contest-team-select-dropdown .list-item {
  padding: 10px 0;
}
.contest-widget-dropdown .contest-team-select-dropdown .list-item span {
  font-size: 10px;
}
.contest-widget-dropdown .contest-team-select-dropdown a {
  font-size: 10px;
}
.contest-team-select-dropdown.mobile .scroll-holder,
.contest-widget-dropdown .contest-team-select-dropdown.mobile .scroll-holder {
  -webkit-overflow-scrolling: touch;
}
.contest-team-select-dropdown.mobile {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
}
.contest-ribbon {
  width: 112px;
  position: absolute;
  right: 30px;
  padding: 20px;
  color: #fff;
  opacity: 1;
}
@media (max-width: 991px) {
  .contest-ribbon {
    right: 0;
    width: 100%;
    padding: 20px 5px 20px 5px;
  }
}
.contest-ribbon.ng-hide {
  opacity: 0;
}
.contest-ribbon.ng-hide-add,
.contest-ribbon.ng-hide-remove {
  -o-transition: all linear 0.5s;
  -ms-transition: all linear 0.5s;
  -webkit-transition: all linear 0.5s;
  -moz-transition: all linear 0.5s;
  transition: all linear 0.5s;
}
.contest-ribbon.ng-hide-add,
.contest-ribbon.ng-hide-remove {
  -o-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.contest-ribbon .stats-item {
  z-index: 3;
}
@media (min-width: 992px) {
  .contest-ribbon .stats-item {
    padding: 0;
  }
}
@media (max-width: 991px) {
  .contest-ribbon .stats-item {
    font-size: 11px;
  }
}
@media (min-width: 992px) {
  .contest-ribbon .stats-item:not(:first-child) {
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .contest-ribbon .stats-item:not(:first-child) .icon-group {
    max-width: 100px;
  }
}
@media (max-width: 406px) {
  .contest-ribbon .stats-item:not(:first-child) .icon-group {
    max-width: 70px;
  }
}
@media (max-width: 991px) {
  .contest-ribbon .stats-item:nth-child(3).text-center {
    text-align: left;
  }
}
@media (max-width: 991px) {
  .contest-ribbon .stats-item:first-child.text-center.active-contest-item {
    text-align: right;
  }
  .contest-ribbon .stats-item:first-child.text-center.active-contest-item .row {
    padding-right: 15px;
  }
}
@media (min-width: 992px) {
  .contest-ribbon .stats-item:first-child .icon-group {
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .contest-ribbon .stats-item:first-child .icon-group {
    margin-top: 9px;
  }
}
.contest-ribbon .stats-item span {
  display: block;
  font-size: 10px;
}
@media (max-width: 406px) {
  .contest-ribbon .stats-item span {
    font-size: 7px;
  }
}
.contest-ribbon .stats-item span.count {
  font-size: 13px;
  font-family: 'AvenirNextLTPro Bold';
}
@media (max-width: 406px) {
  .contest-ribbon .stats-item span.count {
    font-size: 10px;
  }
}
.contest-ribbon .stats-item .icon-group {
  position: relative;
}
.contest-ribbon .stats-item .icon-group img.stats-icon {
  width: 100%;
}
.contest-ribbon .stats-item .icon-group .icon-text {
  text-align: center;
  position: absolute;
}
.contest-ribbon .stats-item .icon-group .icon-text.time-icon {
  top: 40%;
  left: 18%;
  width: 55%;
  text-align: center;
}
.contest-ribbon .stats-item .icon-group .icon-text.points-icon {
  top: 28%;
  left: 30%;
  width: 50%;
  text-align: center;
}
.contest-ribbon .stats-item .icon-group .icon-text.video-icon {
  top: 23%;
  left: 22%;
  width: 50%;
  text-align: center;
}
@media (max-width: 991px) {
  .contest-ribbon .stats-item .icon-group {
    max-width: 75px;
    width: 100%;
    display: inline-block;
    font-size: 11px;
  }
  .contest-ribbon .stats-item .icon-group .icon-text.points-icon {
    top: 37%;
    left: 31%;
  }
  .contest-ribbon .stats-item .icon-group .icon-text.time-icon {
    top: 40%;
    left: 19%;
  }
  .contest-ribbon .stats-item .icon-group .icon-text.video-icon {
    top: 37%;
    left: 21%;
  }
}
@media (max-width: 406px) {
  .contest-ribbon .stats-item .icon-group {
    max-width: 47px;
  }
  .contest-ribbon .stats-item .icon-group .icon-text.time-icon {
    top: 33%;
    left: 19%;
  }
  .contest-ribbon .stats-item .icon-group .icon-text.points-icon {
    top: 34%;
    left: 30%;
  }
  .contest-ribbon .stats-item .icon-group .icon-text.video-icon {
    top: 34%;
    left: 21%;
  }
}
.contest-ribbon .ribbon-background-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.contest-ribbon .triangle {
  position: absolute;
}
@media (max-width: 991px) {
  .contest-ribbon .triangle {
    display: none;
  }
}
.contest-ribbon .triangle.triangle-topright {
  width: 0;
  height: 0;
  border-left: 100px solid transparent;
  right: 0;
}
.contest-ribbon .triangle.triangle-topleft {
  width: 0;
  height: 0;
  border-right: 100px solid transparent;
  left: 0;
}
.contest-ribbon .left-triangle-holder {
  position: absolute;
  bottom: -99px;
  height: 100px;
  width: 51%;
  left: 0;
  overflow: hidden;
}
.contest-ribbon .right-triangle-holder {
  position: absolute;
  bottom: -99px;
  height: 100px;
  width: 51%;
  right: 0;
  overflow: hidden;
}
.contest-ribbon.mobile {
  width: calc(100% + 10px);
  left: -5px;
  position: relative;
  height: 110pt;
}
.contest-ribbon.mobile .triangle {
  display: none;
}
.contest-ribbon.mobile .stats-item span {
  font-size: 10px;
}
.contest-ribbon.mobile .stats-item .icon-group {
  max-width: 77px;
  width: 100%;
  display: inline-block;
  font-size: 11px;
}
.contest-ribbon.mobile .stats-item .icon-group .icon-text.points-icon {
  top: 37%;
  left: 31%;
}
.contest-ribbon.mobile .stats-item .icon-group .icon-text.time-icon {
  top: 40%;
  left: 19%;
}
.contest-ribbon.mobile .stats-item .icon-group .icon-text.video-icon {
  top: 37%;
  left: 21%;
}
.contest-ribbon.mobile .stats-item .icon-group span.count {
  font-size: 13px;
}
.contest-ribbon.mobile .stats-item:not(:first-child) .icon-group {
  max-width: 100px;
}
.contest-ribbon.mobile .stats-item:first-child.text-center {
  text-align: right;
}
.contest-ribbon.mobile .stats-item.mobile-centered.text-center {
  text-align: center;
}
.contest-ribbon.mobile .stats-item.mobile-centered.text-center .icon-group .icon-text.time-icon {
  left: 20%;
}
.contest-ribbon.mobile .stats-item:nth-child(3).text-center {
  text-align: left;
}
.contest-sponsors-and-leaderboard .primary-sponsor {
  text-align: center;
  margin-bottom: 30px;
}
.contest-sponsors-and-leaderboard .primary-sponsor span {
  color: #5e5e5e;
  display: block;
  margin-bottom: 40px;
}
.contest-sponsors-and-leaderboard .primary-sponsor img {
  max-width: 100%;
  max-height: 100px;
}
.contest-sponsors-and-leaderboard iframe {
  width: 100%;
}
.contest-sponsors-and-leaderboard .sponsors hr {
  border-top: 2px solid #e5e5e5;
  margin: 30px 0;
}
.contest-sponsors-and-leaderboard .sponsors span {
  color: #5e5e5e;
  display: block;
  margin-bottom: 40px;
  text-align: center;
}
.contest-sponsors-and-leaderboard .sponsors .sponsor-holder {
  height: 100px;
}
.contest-sponsors-and-leaderboard .sponsors .sponsor-holder:nth-child(odd) {
  text-align: right;
}
.contest-sponsors-and-leaderboard .sponsors .sponsor-holder.centered {
  text-align: center;
}
.contest-sponsors-and-leaderboard .sponsors .sponsor-holder img {
  margin-top: 50px;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100px;
}
.player-fundraiser-stats {
  padding-bottom: 50px;
}
.player-fundraiser-stats .fundraiser-name {
  font-family: 'AvenirNextLTPro Bold';
  width: 100%;
  text-align: center;
  margin-top: 150px;
}
.player-fundraiser-stats .fundraiser-name a {
  color: #4a4a4a;
  cursor: pointer;
}
.player-fundraiser-stats .fundraiser-description {
  font-family: 'AvenirNextLTPro Bold';
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 18px;
  color: #4a4a4a;
  word-wrap: break-word;
}
.player-fundraiser-stats .fundraiser-info {
  margin-top: 80px;
}
.player-fundraiser-stats .fundraiser-info .player-avatar {
  width: 100%;
  max-width: 10em;
}
.player-fundraiser-stats .fundraiser-info .player-username {
  font-family: 'AvenirNextLTPro Bold';
  color: #4a4a4a;
  display: block;
  margin-top: 15px;
  font-size: 20px;
}
.player-fundraiser-stats .fundraiser-info .stats-holder button.btn.btn-primary.make-pledge {
  margin-top: 40px;
  background-color: #ff8827;
  border-color: #e47a24;
  font-size: 20px;
  padding: 15px;
  width: 100%;
  font-weight: bold;
  white-space: normal;
  word-break: break-word;
}
.player-fundraiser-stats .fundraiser-info .stats-holder .contest-stats-box {
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  text-align: center;
  padding: 10px 5px;
  max-width: 170px;
  width: 100%;
  display: inline-block;
}
.player-fundraiser-stats .fundraiser-info .stats-holder .contest-stats-box span {
  display: block;
}
.player-fundraiser-stats .fundraiser-info .stats-holder .contest-stats-box span.value {
  color: #ff8827;
  font-family: 'AvenirNextLTPro Bold';
  font-size: 30px;
}
@media (max-width: 1199px) {
  .player-fundraiser-stats .fundraiser-info .stats-holder .contest-stats-box span.value {
    font-size: 25px;
  }
}
.player-fundraiser-stats .fundraiser-info .stats-holder .contest-stats-box span.type {
  color: #292929;
  font-size: 14px;
}
@media (max-width: 767px) {
  .player-fundraiser-stats .fundraiser-info .stats-holder {
    margin-top: 40px;
  }
}
.player-fundraiser-stats.mobile .fundraiser-info .stats-holder .contest-stats-box {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
}
.player-fundraiser-stats.mobile .fundraiser-info .stats-holder .contest-stats-box span.value {
  font-size: 20px;
}
.player-fundraiser-stats.mobile .fundraiser-info .stats-holder .contest-stats-box span.type {
  font-size: 11px;
}
.contest-widget {
  position: relative;
  min-height: 400px;
  padding-bottom: 50px;
  min-width: 320px;
  max-width: 1200px;
}
.contest-widget .contest-ribbon {
  top: 0;
  right: 0;
  z-index: 1200;
}
.contest-widget h4 {
  font-family: 'AvenirNextLTPro Bold';
}
@media (max-width: 475px) {
  .contest-widget.padded-top-150 {
    padding-top: 150px;
  }
}
.contest-widget .dropdown {
  background: none;
}
.contest-widget .dropdown-menu {
  padding: 10px;
  width: 100%;
}
.contest-widget .iframes-holder {
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 6%;
}
.contest-widget .iframes-holder .leaderboard-widget-col {
  padding: 0;
}
@media (min-width: 768px) {
  .contest-widget .iframes-holder .tv-widget-col {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .contest-widget .iframes-holder .tv-widget-col {
    padding: 0;
  }
  .contest-widget .iframes-holder .leaderboard-widget-col {
    margin-top: 20px;
  }
}
.contest-widget .iframes-holder .tv-widget-col,
.contest-widget .iframes-holder .leaderboard-widget-col {
  background-clip: content-box;
}
.contest-widget .iframes-holder iframe {
  width: 100%;
}
.contest-widget .contest-first-sponsor {
  padding: 6% 4%;
}
.contest-widget .contest-first-sponsor span {
  font-style: italic;
  color: #4a4a4a;
}
.contest-widget .contest-first-sponsor img {
  max-height: 70pt;
  max-width: 50%;
  margin-left: 4%;
}
.contest-widget .contest-info {
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 6%;
}
.contest-widget .contest-info .contest-name-and-deadline {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}
@media (min-width: 640px) {
  .contest-widget .contest-info .contest-name-and-deadline {
    padding-right: 5%;
  }
}
.contest-widget .contest-info .contest-name-and-deadline .contest-name {
  margin-bottom: 5%;
}
.contest-widget .contest-info .contest-name-and-deadline .contest-deadline {
  color: #003;
  margin-bottom: 5%;
}
.contest-widget .contest-info .contest-stats {
  display: table-cell;
  width: 100%;
  min-width: 270px;
  text-align: right;
}
.contest-widget .contest-info .contest-stats .stats-box {
  text-align: center;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 15px 5px;
  background-color: #fff;
  display: inline-block;
  width: 115px;
  margin: auto 10px;
}
.contest-widget .contest-info .contest-stats .stats-box .stats-value {
  color: #ff8827;
  margin-bottom: 5%;
}
.contest-widget .contest-info .contest-stats .stats-box .stats-label {
  color: #333;
}
.contest-widget .btn {
  white-space: normal;
  border-radius: 4px;
}
.contest-widget .btn.cancel {
  background-color: #fff;
}
.contest-widget .contest-buttons {
  margin-top: 7%;
  margin-bottom: 3%;
}
.contest-widget .contest-buttons .btn-join {
  background-color: #ff8827;
  border-color: #ce6c1c;
  font-size: 16px;
}
.contest-widget .contest-buttons .btn-join:active,
.contest-widget .contest-buttons .btn-join:hover {
  background-color: #c36a21;
  border-color: #ad5b19;
}
.contest-widget .contest-buttons .btn-join:focus {
  background-color: #a95d1f;
  border-color: #ad5b19;
}
@media (max-width: 609px) and (min-width: 475px) {
  .contest-widget h4 {
    font-size: 14pt;
  }
  .contest-widget h5,
  .contest-widget .contest-first-sponsor span {
    font-size: 9pt;
  }
  .contest-widget h6 {
    font-size: 8pt;
  }
  .contest-widget .contest-first-sponsor {
    padding: 6% 2%;
  }
  .contest-widget .contest-first-sponsor img {
    max-height: 25pt;
  }
  .contest-widget .contest-info {
    padding-left: 2%;
    padding-right: 2%;
  }
  .contest-widget .contest-info .contest-stats {
    min-width: 208px;
  }
  .contest-widget .contest-info .contest-stats .stats-box {
    padding: 15px 0;
    width: 98px;
    margin: auto 3px;
  }
  .contest-widget .btn,
  .contest-widget .contest-buttons .btn-join {
    font-size: 9pt;
  }
}
.contest-widget .contest-sponsors {
  margin-top: 5%;
  text-align: center;
  height: 250px;
}
.contest-widget .contest-sponsors .sponsors-header {
  border-top: 1px solid #979797;
  padding-top: 15px;
  padding-bottom: 25px;
  font-style: italic;
}
.contest-widget .contest-sponsors .sponsors-logo-holder {
  display: inline-block;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .contest-widget .contest-sponsors .sponsors-logo-holder {
    width: 16.66666667%;
  }
}
@media (max-width: 767px) {
  .contest-widget .contest-sponsors .sponsors-logo-holder {
    width: 33.33333333%;
  }
}
.contest-widget .contest-sponsors .sponsors-logo-holder .sponsors-logo {
  max-width: 100%;
  max-height: 60pt;
  margin-bottom: 40px;
}
@media (max-width: 474px) {
  .contest-widget .contest-first-sponsor {
    text-align: center;
  }
  .contest-widget .contest-first-sponsor span {
    display: block;
  }
  .contest-widget .contest-first-sponsor img {
    margin-left: 0;
    margin-top: 5%;
  }
  .contest-widget .contest-info .contest-name-and-deadline {
    display: block;
    text-align: center;
  }
  .contest-widget .contest-info .contest-stats {
    display: block;
    text-align: center;
    margin-top: 10%;
  }
}
@media (max-width: 419px) {
  .contest-widget .col-xs-offset-1 {
    margin-left: 5%;
  }
  .contest-widget .col-xs-5 {
    width: 45%;
  }
  .contest-widget .btn,
  .contest-widget .contest-buttons .btn-join {
    font-size: 9pt;
  }
  .contest-widget .dropdown-menu {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .contest-widget h4 {
    font-size: 20pt;
  }
  .contest-widget h5,
  .contest-widget .contest-first-sponsor span {
    font-size: 12pt;
  }
  .contest-widget h6,
  .contest-widget .contest-first-sponsor span {
    font-size: 11pt;
  }
  .contest-widget .contest-first-sponsor {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .contest-widget .contest-first-sponsor img {
    margin-left: 1%;
  }
  .contest-widget .contest-info {
    padding-top: 3%;
  }
  .contest-widget .contest-info .contest-name-and-deadline .contest-name {
    margin-bottom: 20px;
  }
  .contest-widget .contest-info .contest-stats {
    min-width: 320px;
  }
  .contest-widget .contest-info .contest-stats .stats-box {
    width: 140px;
  }
}
@media (max-width: 767px) {
  .contest-widget .row-eq-height {
    display: inherit;
  }
}
@media (max-width: 991px) and (min-width: 475px) {
  .contest-widget:not(.widget-mobile) .contest-ribbon {
    padding: 20px;
    width: 112px;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .triangle {
    display: inherit;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item {
    z-index: 3;
    padding: 0;
    width: 100%;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item:not(:first-child) {
    margin-top: 15px;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item:first-child.text-center.active-contest-item {
    text-align: center;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item:first-child.text-center.active-contest-item .row {
    padding-right: 0;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item span {
    display: block;
    font-size: 9px;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item span.count {
    font-size: 11px;
    font-family: 'AvenirNextLTPro Bold';
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item .icon-group {
    position: relative;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item .icon-group img.stats-icon {
    width: 100%;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item .icon-group .icon-text {
    text-align: center;
    position: absolute;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item .icon-group .icon-text.time-icon {
    top: 40%;
    left: 18%;
    width: 55%;
    text-align: center;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item .icon-group .icon-text.points-icon {
    top: 34%;
    left: 30%;
    width: 50%;
    text-align: center;
  }
  .contest-widget:not(.widget-mobile) .contest-ribbon .stats-item .icon-group .icon-text.video-icon {
    top: 29%;
    left: 22%;
    width: 50%;
    text-align: center;
  }
}
.contest-widget.widget-mobile.padded-top-150 {
  padding-top: 150px;
}
.contest-widget.widget-mobile div[class^="col-"],
.contest-widget.widget-mobile div.container,
.contest-widget.widget-mobile div.container-fluid,
.contest-widget.widget-mobile.container-fluid {
  padding-left: 5px;
  padding-right: 5px;
}
.contest-widget.widget-mobile .col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.contest-widget.widget-mobile div.row {
  margin-left: -5px;
  margin-right: -5px;
}
.contest-widget.widget-mobile .row-eq-height {
  display: inherit;
}
.contest-widget.widget-mobile .dropdown-menu {
  width: 100%;
  position: fixed;
  top: 0;
  height: 100%;
}
.contest-widget.widget-mobile .iframes-holder .tv-widget-col,
.contest-widget.widget-mobile .iframes-holder .leaderboard-widget-col {
  padding: 0;
}
.contest-widget.widget-mobile .contest-first-sponsor {
  text-align: center;
}
.contest-widget.widget-mobile .contest-first-sponsor span {
  display: block;
}
.contest-widget.widget-mobile .contest-first-sponsor img {
  margin-left: 0;
  margin-top: 5%;
  margin-bottom: 5%;
  max-width: 80%;
}
.contest-widget.widget-mobile .contest-info .contest-name-and-deadline {
  display: block;
  text-align: center;
  padding-right: 0;
}
.contest-widget.widget-mobile .contest-info .contest-stats {
  display: block;
  text-align: center;
  margin-top: 10%;
}
.contest-widget.widget-mobile .contest-info .contest-stats .stats-box {
  width: 40%;
  margin: auto 3%;
  padding: 3%;
}
.contest-widget.widget-mobile .btn {
  font-weight: bold;
}
.contest-widget.widget-mobile .btn.btn-join {
  margin-top: 7%;
}
.contest-widget.widget-mobile .contest-sponsors .sponsors-logo-holder {
  width: 50%;
}
.contest-widget.widget-mobile .contest-sponsors .sponsors-logo-holder:nth-child(even) {
  text-align: right;
}
.contest-widget.widget-mobile .contest-sponsors .sponsors-logo-holder:nth-child(odd) {
  text-align: left;
}
.contest-widget.widget-mobile .contest-sponsors .sponsors-logo-holder .sponsors-logo {
  max-width: 70%;
}
.contest-widget.widget-mobile iframe.full-screen {
  position: fixed;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  z-index: 3;
}
.contest-widget.widget-mobile .contest-ribbon {
  width: 100%;
}
.leaderboard-widget.mobile-widget {
  width: 100%;
}
.leaderboard-widget.mobile-widget .upper-filters-row {
  height: auto;
}
.leaderboard-widget.mobile-widget .table-header span[class^='col'] {
  font-size: 7pt;
}
.leaderboard-widget.mobile-widget .table-header span[class^='col'] .header-icon img {
  vertical-align: sub;
}
.leaderboard-widget.mobile-widget .infinite-scroll-holder {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
.leaderboard-widget {
  border: 1px solid;
  background-color: #fff;
  min-width: 230px;
  overflow: hidden;
  width: 99%;
}
.leaderboard-widget input[type="search"] {
  margin-top: 20px;
  border-radius: 4px;
  padding-right: 35px;
}
.leaderboard-widget img.magnifier-icon {
  position: absolute;
  right: 25px;
  top: 30px;
  height: 15px;
}
.leaderboard-widget .widget-header {
  color: #fff;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 35px;
}
.leaderboard-widget .table-header {
  padding-top: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}
.leaderboard-widget .table-header span[class*='col-'] {
  padding: 0;
  font-size: 8pt;
  font-weight: bold;
  color: #9e9e9e;
}
.leaderboard-widget .table-header span[class*='col-']:not(.player-info-header) {
  text-align: center;
}
@media (min-width: 285px) {
  .leaderboard-widget .table-header span[class*='col-'].player-info-header {
    padding-left: 60px;
  }
}
@media (max-width: 284px) {
  .leaderboard-widget .table-header span[class*='col-'].player-info-header {
    padding-left: 35px;
  }
}
@media (max-width: 767px) and (min-width: 341px) {
  .leaderboard-widget .table-header span[class*='col-'].rank-header {
    padding-left: 10px;
  }
}
@media (max-width: 319px) {
  .leaderboard-widget .table-header span[class*='col-'] {
    font-size: 8pt;
  }
}
@media (min-width: 550px) {
  .leaderboard-widget .table-header span[class*='col-'] {
    font-size: 11pt;
  }
}
.leaderboard-widget .table-header span[class*='col-'] .header-icon img {
  width: 10pt;
}
@media (min-width: 550px) {
  .leaderboard-widget .table-header span[class*='col-'] .header-icon img {
    vertical-align: text-top;
    margin-top: 2px;
  }
}
.leaderboard-widget .table-header span[class*='col-'] .header-icon.glyphicon {
  width: 10pt;
  height: 10pt;
  background-color: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  font-size: 9px;
  padding-top: 1.5px;
  vertical-align: text-bottom;
}
@media (min-width: 550px) {
  .leaderboard-widget .table-header span[class*='col-'] .header-icon.glyphicon {
    vertical-align: text-top;
    margin-top: 2px;
  }
}
.leaderboard-widget .total-funds-raised {
  text-align: center;
  padding-top: 25px;
  color: #ff8827;
  font-size: 16px;
}
.leaderboard-widget .table-content-item {
  padding-top: 20px;
  padding-bottom: 15px;
}
.leaderboard-widget .table-content-item.hover-background:hover {
  background-color: #eee;
}
.leaderboard-widget .table-content-item.hover-background:hover.highlighted-item {
  background-color: #ebf1f7;
}
.leaderboard-widget .table-content-item.highlighted-item {
  background-color: #dbe9f9;
}
.leaderboard-widget .table-content-item:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}
.leaderboard-widget .table-content-item .player-avatar,
.leaderboard-widget .table-content-item .organization-logo {
  max-width: 50px;
  max-height: 50px;
}
.leaderboard-widget .table-content-item span[class*='col-'] {
  padding: 0;
  color: #46464e;
  font-weight: bold;
  font-size: 10pt;
}
.leaderboard-widget .table-content-item span[class*='col-']:not(.player-info),
.leaderboard-widget .table-content-item span[class*='col-']:not(.organization-info) {
  text-align: center;
}
@media (max-width: 284px) {
  .leaderboard-widget .table-content-item span[class*='col-'] {
    font-size: 8pt;
  }
  .leaderboard-widget .table-content-item span[class*='col-'] .player-avatar,
  .leaderboard-widget .table-content-item span[class*='col-'] .organization-logo {
    width: 25px;
    vertical-align: bottom;
  }
  .leaderboard-widget .table-content-item span[class*='col-']:not(.player-info),
  .leaderboard-widget .table-content-item span[class*='col-']:not(.organization-info) {
    padding-top: 6px;
  }
  .leaderboard-widget .table-content-item span[class*='col-'].player-info span,
  .leaderboard-widget .table-content-item span[class*='col-'].organization-info span {
    max-width: calc(100% - 40px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-top: 3px;
  }
}
.leaderboard-widget .table-content-item span[class*='col-'] .player-avatar,
.leaderboard-widget .table-content-item span[class*='col-'] .organization-logo {
  margin-right: 5px;
  margin-left: 5px;
}
@media (min-width: 285px) {
  .leaderboard-widget .table-content-item span[class*='col-'].player-info span,
  .leaderboard-widget .table-content-item span[class*='col-'].organization-inf span {
    vertical-align: middle;
    display: inline-block;
    max-width: calc(100% - 60px - 5px);
    word-wrap: break-word;
    font-size: 9pt;
  }
  .leaderboard-widget .table-content-item span[class*='col-']:not(.player-info),
  .leaderboard-widget .table-content-item span[class*='col-']:not(.organization-info) {
    padding-top: 15px;
  }
}
@media (max-width: 424px) {
  .leaderboard-widget .table-content-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 340px) {
  .leaderboard-widget .hide-on-small-screen {
    display: none;
  }
}
@media (min-width: 341px) {
  .leaderboard-widget .show-on-small-screen {
    display: none;
  }
}
.leaderboard-widget .infinite-scroll-holder {
  overflow-y: auto;
  max-height: 600px;
}
@media (min-width: 321px) {
  .leaderboard-widget .infinite-scroll-holder {
    min-height: 220px;
  }
}
@media (max-width: 284px) {
  .leaderboard-widget .infinite-scroll-holder {
    max-height: 440px;
  }
}
.leaderboard-widget .leaderboard-message {
  font-weight: bold;
  color: #c4c4c4;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 35px;
  letter-spacing: 1px;
  height: 600px;
}
.leaderboard-widget .leaderboard-message > span {
  display: block;
}
.leaderboard-widget .leaderboard-message > span:first-child {
  margin-bottom: 20px;
}
.leaderboard-widget .currency-icon {
  width: 10pt;
  height: 10pt;
  background: #9e9e9e;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 10px;
  vertical-align: middle;
  padding-right: 1px;
}
@media (max-width: 549px) {
  .leaderboard-widget .currency-icon {
    margin-bottom: -3px;
  }
}
.leaderboard-widget .arrow-holder {
  padding: 15px 0 0 0;
  text-align: center;
}
.leaderboard-widget .arrow-holder span {
  color: #9e9e9e;
}
@media (max-width: 284px) {
  .leaderboard-widget .arrow-holder {
    padding-top: 8px;
    font-size: 9px;
  }
}
.topya-tv-widget .container {
  max-width: 1200px;
}
.topya-tv-widget .container .height-holder {
  padding-bottom: 86%;
}
.topya-tv-widget .addthis-icon {
  background-color: #6aea00;
  width: 36px;
  height: 36px;
  padding: 5px;
}
.topya-tv-widget .addthis-icon:not(.disabled-item) {
  cursor: pointer;
}
.topya-tv-widget .no-videos-info {
  width: 100%;
  padding-top: 40%;
  padding-bottom: 40%;
  text-align: center;
}
.topya-tv-widget .tv-header {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 80px;
}
@media (max-width: 425px) {
  .topya-tv-widget .tv-header {
    height: 130px;
  }
}
.topya-tv-widget .tv-header > div {
  height: 80px;
}
@media (max-width: 425px) {
  .topya-tv-widget .tv-header > div {
    height: 60px;
  }
}
.topya-tv-widget .tv-header .topya-tv-logo,
.topya-tv-widget .tv-header .primary-sponsor-logo {
  max-width: 100%;
  margin-top: 40px;
  transform: translateY(-50%);
  max-height: 50px;
}
.topya-tv-widget .tv-header .channel-switch {
  text-align: center;
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
}
.topya-tv-widget .tv-header .channel-switch span {
  display: block;
}
.topya-tv-widget .tv-header .channel-switch span:nth-child(2) {
  margin-top: 2px;
}
.topya-tv-widget .tv-header .channel-switch span:nth-child(3) {
  margin-top: -2px;
}
.topya-tv-widget .tv-header .channel-name {
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
}
.topya-tv-widget .tv-header .channel-name:not(.full-width) {
  text-align: center;
}
@media (min-width: 426px) {
  .topya-tv-widget .tv-header .channel-name.full-width {
    text-align: right;
  }
}
@media (max-width: 425px) {
  .topya-tv-widget .tv-header .channel-name.full-width {
    text-align: left;
  }
}
.topya-tv-widget .tv-header .channel-name span:first-child {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.topya-tv-widget .tv-header .channel-selector.glyphicon-chevron-down {
  vertical-align: top;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.topya-tv-widget .tv-header .channel-selector.glyphicon-chevron-down.chevron-rotated {
  transform: rotate(180deg);
}
.topya-tv-widget .tv-header .channel-controls {
  text-align: right;
}
.topya-tv-widget .tv-header .channel-controls > span {
  margin-top: 40px;
  transform: translateY(-50%);
}
@media (max-width: 425px) {
  .topya-tv-widget .tv-header .primary-sponsor-logo-holder {
    text-align: right;
  }
}
@media (min-width: 426px) {
  .topya-tv-widget .tv-header .primary-sponsor-logo-holder {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .topya-tv-widget .tv-header .channel-name:not(.full-width) {
    max-width: calc(90% - 30px);
  }
}
@media (max-width: 767px) {
  .topya-tv-widget .tv-header .channel-name {
    font-size: 14px;
  }
  .topya-tv-widget .tv-header .channel-name:not(.full-width) {
    max-width: calc(80% - 14px);
  }
  .topya-tv-widget .tv-header .channel-selector {
    margin-top: 7px;
  }
  .topya-tv-widget .tv-header .channel-switch span:nth-child(2) {
    font-size: 10px;
  }
}
@media (max-width: 425px) {
  .topya-tv-widget .tv-header .channel-name {
    font-size: 12px;
  }
  .topya-tv-widget .tv-header .channel-controls.controls-left {
    text-align: left;
  }
}
.topya-tv-widget .tv-header .dropdown-menu {
  z-index: 10;
}
@media (min-width: 1200px) {
  .topya-tv-widget .tv-header .dropdown-menu {
    width: 75%;
  }
}
@media (min-width: 426px) {
  .topya-tv-widget .tv-header .dropdown-menu {
    right: 0;
    left: auto;
  }
}
@media (max-width: 425px) {
  .topya-tv-widget .tv-header .dropdown-menu {
    width: 100%;
  }
}
.topya-tv-widget .tv-current-video .pending-thumbnail-holder {
  padding-bottom: 58.75%;
  width: 100%;
}
.topya-tv-widget .tv-current-video .pending-thumbnail-holder img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.topya-tv-widget .tv-current-video .error-status-holder {
  padding-bottom: 58.75%;
  width: 100%;
  position: relative;
}
.topya-tv-widget .tv-current-video .error-status-holder span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 40%;
  text-align: center;
  font-weight: bold;
}
.topya-tv-widget .tv-current-video .user-avatar {
  height: 70px;
  vertical-align: top;
}
@media (max-width: 767px) {
  .topya-tv-widget .tv-current-video .user-avatar {
    height: 40px;
  }
}
.topya-tv-widget .tv-current-video .tube-info-holder {
  margin: 20px 0;
  height: 80px;
}
@media (max-width: 319px) {
  .topya-tv-widget .tv-current-video .tube-info-holder {
    padding: 0;
  }
}
.topya-tv-widget .tv-current-video .tube-info-holder .tube-info {
  margin-left: 2%;
  font-size: 12pt;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 460px) {
  .topya-tv-widget .tv-current-video .tube-info-holder .tube-info {
    max-width: calc(95% - 80px);
  }
}
@media (min-width: 461px) {
  .topya-tv-widget .tv-current-video .tube-info-holder .tube-info {
    max-width: calc(85% - 40px);
  }
}
@media (max-width: 767px) {
  .topya-tv-widget .tv-current-video .tube-info-holder .tube-info {
    font-size: 14px;
  }
}
@media (max-width: 319px) {
  .topya-tv-widget .tv-current-video .tube-info-holder .tube-info {
    font-size: 10px;
  }
}
.topya-tv-widget .tv-current-video .tube-info-holder .tube-info .username {
  display: grid;
}
.topya-tv-widget .tv-current-video .tube-info-holder .tube-info .skillname {
  display: block;
}
@media (max-width: 425px) {
  .topya-tv-widget .col-xxs-6 {
    width: 50%;
  }
  .topya-tv-widget .col-xxs-12 {
    width: 100%;
  }
}
.topya-tv-widget .tv-gallery .gallery-holder {
  min-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.topya-tv-widget .tv-gallery .gallery-holder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a4a4ca;
}
.topya-tv-widget .tv-gallery .gallery-holder::-webkit-scrollbar {
  height: 5px;
  background-color: #a4a4ca;
}
.topya-tv-widget .tv-gallery .gallery-holder::-webkit-scrollbar-thumb {
  background-color: #2e51a0;
}
.topya-tv-widget .tv-gallery .gallery-holder .gallery-thumbnail-row {
  margin-left: -1%;
  margin-right: -1%;
}
.topya-tv-widget .tv-gallery .gallery-holder .gallery-thumbnail {
  width: 20%;
  padding-bottom: 11.25%;
  display: inline-block;
  margin: 1%;
  min-width: 80px;
  position: relative;
}
.topya-tv-widget .tv-gallery .gallery-holder .gallery-thumbnail img {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
}
.topya-tv-widget .tv-gallery .gallery-holder .gallery-thumbnail .thumbnail-overlay-lg {
  color: #fff;
  position: absolute;
  bottom: 4%;
  left: 7%;
  font-size: 9pt;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 500px) {
  .topya-tv-widget .tv-gallery .gallery-holder .gallery-thumbnail .thumbnail-overlay-lg {
    display: none;
  }
}
.topya-tv-widget .tv-gallery .gallery-holder .gallery-thumbnail .thumbnail-overlay-sm {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 501px) {
  .topya-tv-widget .tv-gallery .gallery-holder .gallery-thumbnail .thumbnail-overlay-sm {
    display: none;
  }
}
.topya-tv-widget .tv-footer {
  margin-top: 20px;
}
.topya-tv-widget .tv-footer .get-app-buttons {
  font-size: 11pt;
}
.topya-tv-widget .tv-footer .get-app-buttons .btn {
  border-radius: 8px;
  width: auto;
  color: #003;
  padding: 3px;
  padding-right: 5px;
  margin-left: 5px;
}
.topya-tv-widget .tv-footer .get-app-buttons .btn span[class^='btn-text-'] {
  display: block;
}
.topya-tv-widget .tv-footer .get-app-buttons .btn .btn-text-sm {
  font-size: 6pt;
}
.topya-tv-widget .tv-footer .get-app-buttons .btn .btn-text-lg {
  font-size: 7pt;
}
.topya-tv-widget .tv-footer .get-app-buttons .btn img {
  height: 18pt;
  margin-right: 2pt;
  vertical-align: sub;
}
@media (min-width: 768px) {
  .topya-tv-widget .tv-footer .get-app-buttons {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .topya-tv-widget .tv-footer .get-app-buttons {
    text-align: center;
    font-size: 14px;
  }
}
@media (max-width: 424px) {
  .topya-tv-widget .tv-footer .get-app-buttons {
    padding: 0;
  }
  .topya-tv-widget .tv-footer .get-app-buttons .btn {
    margin-left: 5px;
  }
}
@media (max-width: 374px) {
  .topya-tv-widget .tv-footer .get-app-buttons .get-app-text {
    display: block;
    margin-bottom: 5px;
  }
  .topya-tv-widget .tv-footer .get-app-buttons .btn {
    margin-left: 0;
  }
}
.topya-tv-widget .tv-footer .channel-sponsors {
  text-align: center;
}
.topya-tv-widget .tv-footer .channel-sponsors > div {
  width: 25%;
  display: inline-block;
  height: 40px;
  text-align: center;
}
.topya-tv-widget .tv-footer .channel-sponsors > div img {
  margin-top: 20px;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 40px;
}
@media (max-width: 767px) {
  .topya-tv-widget .tv-footer.row-eq-height {
    display: inherit;
  }
  .topya-tv-widget .tv-footer .channel-sponsors {
    margin-top: 30px;
  }
}
.topya-tv-widget.widget-mobile div[class^="col-"],
.topya-tv-widget.widget-mobile div.container,
.topya-tv-widget.widget-mobile div.container-fluid {
  padding-left: 5px;
  padding-right: 5px;
}
.topya-tv-widget.widget-mobile div.row {
  margin-left: -5px;
  margin-right: -5px;
}
.topya-tv-widget.widget-mobile .tv-header {
  border-bottom: 1px solid #6a6a87;
  height: 130px;
}
.topya-tv-widget.widget-mobile .tv-header > div {
  height: 60px;
}
.topya-tv-widget.widget-mobile .tv-header .topya-tv-logo,
.topya-tv-widget.widget-mobile .tv-header .primary-sponsor-logo {
  margin-top: 30px;
}
.topya-tv-widget.widget-mobile .tv-header .dropdown-menu {
  width: 100%;
}
.topya-tv-widget.widget-mobile .tv-header .channel-name {
  width: 85%;
}
.topya-tv-widget.widget-mobile .tv-header .channel-name.full-width {
  width: 100%;
  text-align: left;
}
.topya-tv-widget.widget-mobile .tv-header .channel-controls {
  text-align: left;
}
.topya-tv-widget.widget-mobile .tv-header .channel-controls > span {
  margin-top: 30px;
}
.topya-tv-widget.widget-mobile .videos-holder {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
}
.topya-tv-widget.widget-mobile .videos-holder.scrollable-holder {
  -webkit-overflow-scrolling: touch;
}
.topya-tv-widget.widget-mobile .tv-current-video {
  border-bottom: 1px solid #6a6a87;
}
.topya-tv-widget.widget-mobile .tv-current-video .tube-info-holder {
  min-height: 40px;
}
.topya-tv-widget.widget-mobile .tv-current-video .tube-info-holder .tube-info {
  max-width: calc(80% - 40px);
}
.topya-tv-widget.widget-mobile .tv-footer .get-app-buttons {
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  font-size: 12px;
}
.topya-tv-widget.widget-mobile .tv-footer .get-app-buttons .get-app-text {
  vertical-align: sub;
}
.topya-tv-widget.widget-mobile .tv-footer .get-app-buttons .btn {
  border-radius: 8px;
  float: right;
}
.topya-tv-widget.widget-mobile .tv-footer .get-app-buttons .btn:not(:first-of-type) {
  margin-left: 0;
}
.topya-tv-widget.widget-mobile .tv-footer .get-app-buttons .btn .btn-text-lg {
  font-size: 6pt;
}
.topya-tv-widget.widget-mobile .tv-footer .channel-sponsors > div {
  height: 60px;
}
.topya-tv-widget.widget-mobile .tv-footer .channel-sponsors > div img {
  margin-top: 30px;
  max-height: 60px;
}
manager-create-wizard-steps > div .bs-wizard {
  margin-top: 40px;
  padding-bottom: 10px;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step {
  padding: 0;
  position: relative;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #f5f5f5;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 0;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step > .progress > .progress-bar {
  transition: 0s;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.complete > .progress > .progress-bar {
  left: auto;
  right: 0;
  width: 100%;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.complete a.bs-wizard-dot {
  cursor: pointer;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step .bs-wizard-stepnum {
  color: #989898;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: 'AvenirNextLTPro Bold';
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.active .bs-wizard-stepnum,
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step.complete .bs-wizard-stepnum {
  color: dodgerblue;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 14px;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step .bs-wizard-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: dodgerblue;
  top: 45px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step .bs-wizard-dot:after {
  content: ' ';
  width: 14px;
  height: 14px;
  background: #000;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step .progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
  background-color: #f5f5f5;
  background-image: none;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item .bs-wizard-step .progress > .progress-bar {
  box-shadow: none;
  background: dodgerblue;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:first-child .bs-wizard-step .bs-wizard-dot,
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:first-child .bs-wizard-step .progress {
  left: 15px;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:first-child .bs-wizard-step .bs-wizard-stepnum {
  text-align: left;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:first-child .bs-wizard-step.active > .progress > .progress-bar {
  width: 0%;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:last-child .bs-wizard-step.active > .progress > .progress-bar {
  width: 100%;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:last-child .bs-wizard-step .progress {
  right: 15px;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:last-child .bs-wizard-step .bs-wizard-dot {
  left: auto;
  right: 0;
}
manager-create-wizard-steps > div .bs-wizard > manager-create-wizard-steps-item:last-child .bs-wizard-step .bs-wizard-stepnum {
  text-align: right;
}
manager-list-header > div.container-fluid {
  background-color: #003;
  color: #fff;
  font-weight: bold;
  padding: 10px 15px;
}
manager-list-header > div.container-fluid .dropdown {
  background: none;
}
manager-list-header > div.container-fluid .dropdown .dropdown-menu {
  min-width: 250px;
}
@media (max-width: 1080px) {
  manager-list-header > div.container-fluid .dropdown .dropdown-menu.rightmost {
    right: 0;
    left: auto;
  }
}
@media (max-width: 610px) {
  manager-list-header > div.container-fluid .dropdown .dropdown-menu {
    width: 150px;
  }
  manager-list-header > div.container-fluid .dropdown .dropdown-menu,
  manager-list-header > div.container-fluid .dropdown .dropdown-menu .btn,
  manager-list-header > div.container-fluid .dropdown .dropdown-menu h5 {
    font-size: 10px;
  }
}
@media (max-width: 610px) {
  manager-list-header > div.container-fluid > div {
    padding-left: 0;
    font-size: 10px;
  }
}
manage-category > div span.category-name,
manage-category > div input {
  height: 27px;
  display: inline-block;
}
manage-category > div span.category-name {
  width: 196px;
}
manage-category > div input {
  width: 200px;
}
manage-category > div toggle-input > span.toggle-icon {
  margin-left: calc(100% - 400px);
}
.channels-list {
  padding: 0;
}
.channels-list h1 {
  font-family: 'AvenirNextLTPro Bold';
}
.channels-list .btn {
  font-weight: bold;
}
.channels-list input,
.channels-list .btn {
  border-radius: 4px;
}
.channels-list .channels-table > div > list-loading-overlay > .list-loading-overlay {
  width: calc(100% - 30px);
  left: 15px;
}
.channel-forms .btn {
  border-radius: 8px;
  font-weight: bold;
}
.channel-forms.channel-basics-form .checkbox-group {
  width: 270px;
}
.channel-forms.channel-basics-form .checkbox-group .checkbox-holder {
  background: #eee;
}
.channel-forms.channel-basics-form .video-types-selection > div {
  border: 1px solid #dae2ea;
  color: #5c5c5c;
  margin: 5px 0;
  border-radius: 8px;
  padding: 0 10px;
}
.channel-forms.channel-basics-form .video-types-selection > div .clickable {
  padding: 10px 0;
}
.channel-forms.channel-basics-form .video-types-selection > div .clickable:hover h5 {
  text-decoration: underline;
}
.channel-forms.channel-basics-form .video-types-selection h5 {
  display: inline-block;
  margin-bottom: 4px;
}
.channel-forms.channel-basics-form .video-types-selection .btn {
  border-radius: 50%;
  padding-top: 2px;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  color: #fff;
  padding-left: 4px;
}
.channel-forms.channel-basics-form .video-types-selection .btn.selected {
  background-color: #5cb85c;
}
.channel-forms.channel-basics-form .video-types-selection .btn.deselected {
  background-color: #9b9b9b;
}
.channel-forms.channel-sponsors-form .brand-logo {
  max-height: 80px;
  max-width: 100%;
}
.channel-forms.channel-sponsors-form ul.sponsors-sorting.bordered {
  border: 1px dashed #bbb;
}
.channel-forms.channel-sponsors-form ul.sponsors-sorting li {
  padding-top: 30px;
  margin-bottom: 10px;
  min-height: 140px;
}
.channel-forms.channel-sponsors-form ul.sponsors-sorting li .border-holder {
  border-bottom: 2px dashed #d1d1d1;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 30px);
}
.channel-forms.channel-sponsors-form ul.sponsors-sorting li .primary-sponsor-selector {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: hidden;
}
.channel-forms.channel-sponsors-form ul.sponsors-sorting li .primary-sponsor-selector div:first-child {
  padding: 10px;
  background-color: #eee;
  border-right: 1px solid #d1d1d1;
  margin-right: 10px;
  display: inline-block;
}
.channel-forms .drag-uploader {
  margin-top: 40px;
  width: 100%;
  padding: 6% 15px;
  text-align: center;
  border: 1px dashed #bbb;
  position: relative;
}
.channel-forms .drag-uploader .glyphicon-remove {
  color: #e30000;
  position: absolute;
  right: 5px;
  top: 5px;
}
.channel-forms .drag-uploader.drag-drop {
  opacity: 0.5;
}
.channel-forms .drag-uploader .preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  opacity: 0.7;
}
.channel-forms .drag-uploader h2 {
  margin-bottom: 15px;
}
.channel-forms .drag-uploader a {
  color: #8b3bf4;
  cursor: pointer;
}
.channel-forms.channel-basics-view .header {
  margin: 40px 0 30px 0;
  font-family: 'AvenirNextLTPro Bold';
}
@media (max-width: 1024px) {
  .channel-forms.channel-basics-view h4 {
    font-size: 15px;
  }
}
.channel-forms.channel-basics-view .channel-logo {
  max-height: 140px;
  margin-top: 20px;
}
.channel-forms.channel-basics-view .subscription-table {
  margin-top: 35px;
  border: 1px solid #a3a3a3;
}
.channel-forms.channel-basics-view .subscription-table .table-header {
  color: #fff;
  background-color: #003;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
.channel-forms.channel-basics-view .subscription-table .table-items {
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}
.channel-forms.channel-basics-view .subscription-table .table-items .table-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.channel-forms.channel-basics-view .subscription-table .table-items .table-item:nth-child(odd) {
  background-color: #d7d7d7;
}
.channel-forms.channel-edit-sponsors-form ul.sponsors-sorting.bordered {
  border: 1px dashed #bbb;
}
.channel-forms.channel-edit-sponsors-form ul.sponsors-sorting li {
  padding-top: 30px;
  margin-bottom: 10px;
}
.channel-forms.channel-edit-sponsors-form ul.sponsors-sorting li .sponsor-logo-uploader.dragover {
  background-color: #ccc;
}
.channel-forms.channel-edit-sponsors-form ul.sponsors-sorting li .brand-logo {
  max-height: 150px;
  max-width: 100%;
}
.channel-forms.channel-edit-sponsors-form ul.sponsors-sorting li .border-holder {
  border-top: 2px dashed #d1d1d1;
  margin-top: 30px;
}
.channel-forms.channel-edit-sponsors-form ul.sponsors-sorting li .delete-sponsor-icon {
  color: #e4000b;
  margin-top: 60px;
  margin-right: 5%;
}
.channel-forms.channel-edit-sponsors-form .drag-uploader {
  margin-top: 40px;
  width: 100%;
  padding: 6% 15px;
  text-align: center;
  border: 1px dashed #bbb;
  position: relative;
}
.channel-forms.channel-edit-sponsors-form .drag-uploader.drag-drop {
  opacity: 0.5;
}
.channel-forms.channel-edit-sponsors-form .drag-uploader .preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  opacity: 0.7;
}
.channel-forms.channel-edit-sponsors-form .drag-uploader h2 {
  margin-bottom: 15px;
}
.channel-forms.channel-edit-sponsors-form .drag-uploader a {
  color: #8b3bf4;
  cursor: pointer;
}
.channel-forms input.form-control.ng-invalid {
  border-color: red;
}
.create-channel .page-header {
  font-family: 'AvenirNextLTPro Bold';
  border-bottom: none;
}
.edit-channel ol {
  padding-left: 0;
  padding-right: 0;
}
.edit-channel .page-header {
  font-family: 'AvenirNextLTPro Bold';
  border-bottom: none;
}
.edit-channel .nav-tabs.nav-justified > .active > a,
.edit-channel .nav-tabs.nav-justified > .active > a:hover,
.edit-channel .nav-tabs.nav-justified > .active > a:focus {
  border-color: dodgerblue;
  border-bottom: none;
}
@media (min-width: 768px) {
  .edit-channel .nav-tabs.nav-justified > li > a {
    border-bottom-color: dodgerblue;
  }
}
.contests-list {
  padding: 0;
}
.contests-list .btn {
  border-radius: 4px;
  font-weight: bold;
}
.contests-list h1 {
  font-family: 'AvenirNextLTPro Bold';
}
.contests-list .contests-table {
  padding: 0 15px;
}
.contests-list .contests-table > list-loading-overlay > .list-loading-overlay {
  width: calc(100% - 30px);
  left: 15px;
}
.create-contest .page-header {
  font-family: 'AvenirNextLTPro Bold';
  border-bottom: none;
}
.create-contest button.btn,
.create-contest input.form-control {
  border-radius: 4px;
}
.create-contest textarea {
  resize: none;
  height: 150px;
}
.create-contest radio-input > div {
  margin-top: 10px;
}
.create-contest radio-input:nth-of-type(2) > div {
  margin-left: 10px;
}
.create-contest .video-types-selection > div {
  position: relative;
  border: 1px solid #dae2ea;
  color: #5c5c5c;
  margin: 5px 0;
  border-radius: 8px;
  padding: 0 10px;
}
@media (max-width: 767px) {
  .create-contest .video-types-selection > div {
    margin-bottom: 20px;
  }
}
.create-contest .video-types-selection > div .clickable {
  padding: 10px 0;
}
.create-contest .video-types-selection > div .clickable:hover h5 {
  text-decoration: underline;
}
.create-contest .video-types-selection h5 {
  display: inline-block;
  margin-bottom: 4px;
}
.create-contest .video-types-selection .btn {
  border-radius: 50%;
  padding-top: 1px;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  padding-left: 4.5px;
  color: #fff;
}
.create-contest .video-types-selection .btn.selected {
  background-color: #5cb85c;
}
.create-contest .video-types-selection .btn.deselected {
  background-color: #9b9b9b;
}
.create-contest .video-types-selection .info-link {
  position: absolute;
  right: -30px;
  margin-top: 11px;
  top: 0;
  color: dodgerblue;
  font-weight: bold;
}
@media (max-width: 767px) {
  .create-contest .video-types-selection .info-link {
    left: 0;
    top: auto;
    bottom: -20px;
  }
}
.edit-contest ol {
  padding-left: 0;
  padding-right: 0;
}
.edit-contest .page-header {
  font-family: 'AvenirNextLTPro Bold';
  border-bottom: none;
}
.edit-contest .nav-tabs.nav-justified > .active > a,
.edit-contest .nav-tabs.nav-justified > .active > a:hover,
.edit-contest .nav-tabs.nav-justified > .active > a:focus {
  border-color: dodgerblue;
  border-bottom: none;
}
@media (min-width: 768px) {
  .edit-contest .nav-tabs.nav-justified > li > a {
    border-bottom-color: dodgerblue;
  }
}
.manager-create-path .btn {
  border-radius: 8px;
}
.manager-create-path .btn:focus,
.manager-create-path .btn:active:focus {
  outline: none;
}
.manager-create-path .header {
  margin-top: 3%;
  margin-bottom: 3%;
  color: #4a4a4a;
}
.manager-create-path .sub-header {
  color: #5c5c5c;
}
.manager-create-path hr {
  border-color: #ccc;
}
.manager-create-path .create-path-frame {
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .manager-create-path .create-path-frame {
    padding-left: 0;
    padding-right: 0;
    text-align: justify;
  }
}
.manager-create-path .save-path-buttons .btn {
  font-weight: bold;
}
.manager-create-path .video-help {
  text-align: center;
  margin-top: 30px;
}
.manager-create-path .video-help a {
  color: dodgerblue;
  cursor: pointer;
}
.manager-create-path .video-help span {
  margin-left: 5px;
  vertical-align: top;
}
.manage-path-form {
  padding-top: 30px;
}
.manage-path-form .content-provider-logo-holder {
  height: 80px;
}
.manage-path-form .content-provider-logo {
  max-width: 100%;
  max-height: 80px;
}
.manage-path-form .path-stats {
  font-weight: bold;
  font-size: 16px;
}
.manage-path-form .path-stats .path-stats-icon {
  margin-right: 5px;
}
.manage-path-form .path-stats .path-stats-icon.glyphicon {
  color: #498FE1;
}
.manage-path-form .path-stats .path-stats-icon:not(:first-child) {
  margin-left: 10%;
}
@media (max-width: 1023px) {
  .manage-path-form .path-stats .path-stats-icon:not(:first-child) {
    margin-left: 5%;
  }
}
.manage-path-form .path-stats .path-stats-icon img {
  width: 16px;
  height: 16px;
  vertical-align: top;
}
.manage-path-form textarea {
  resize: vertical;
  min-height: 100px;
}
.manage-path-form input {
  border-color: #ccc;
  border-radius: 4px;
}
.manage-path-form radio-button > div {
  margin-top: 10px;
  width: 200px;
}
.path-info-component {
  padding-top: 30px;
}
.path-info-component .content-provider-logo {
  max-width: 100%;
  max-height: 80px;
}
.path-info-component .path-stats {
  font-weight: bold;
  font-size: 16px;
}
.path-info-component .path-stats .path-stats-icon {
  margin-right: 5px;
}
.path-info-component .path-stats .path-stats-icon.glyphicon {
  color: #498FE1;
}
.path-info-component .path-stats .path-stats-icon:not(:first-child) {
  margin-left: 10%;
}
@media (max-width: 1023px) {
  .path-info-component .path-stats .path-stats-icon:not(:first-child) {
    margin-left: 5%;
  }
}
.path-info-component .path-stats .path-stats-icon img {
  width: 16px;
  height: 16px;
  vertical-align: top;
}
.path-info-component radio-button > div {
  margin-top: 10px;
  width: 200px;
}
.path-skills-component manager-column-head > div {
  padding: 0 11px;
}
.path-skills-component manager-column-head:last-child {
  text-align: center;
}
manager-path-users > div.container-fluid {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 30px 35px;
  background-color: #fff;
  z-index: 100;
}
manager-path-users > div.container-fluid drop-area > div,
manager-path-users > div.container-fluid players-list > div {
  margin-top: 30px;
}
manager-path-users > div.container-fluid .save-button-group button.btn {
  display: inline-block;
  margin: 20px 5px;
  width: 200px;
}
.manage-path {
  padding-top: 20px;
}
@media (min-width: 992px) {
  .manage-path .assign-path-to-users {
    text-align: center;
  }
}
.manage-path div.popover {
  max-width: 700px;
  width: 700px;
  margin-left: 12px;
}
.manage-path manager-path-users > div {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
}
.manage-path .btn {
  border-radius: 8px;
}
.manage-path .btn:focus,
.manage-path .btn:active:focus {
  outline: none;
}
.manage-path hr {
  border-color: #ccc;
}
.manage-path .buttons-holder {
  min-height: 34px;
}
.manage-path .manage-path-header {
  margin-top: 1.5%;
  color: #4a4a4a;
}
.manage-path .breadcrumb {
  padding-left: 0;
}
.manage-path .path-stats-right {
  margin-top: 30px;
}
.manage-path .path-stats-right h5 {
  line-height: 25px;
}
.manage-path .path-detail-frame {
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .manage-path .path-detail-frame {
    padding-left: 0;
    padding-right: 0;
    text-align: justify;
  }
}
@media (max-width: 767px) {
  .manage-path .subheader {
    font-size: 12px;
  }
}
.manage-path .difficulty-icon {
  height: 20px;
  vertical-align: sub;
  margin-right: 10px;
}
edit-pledge > div.row {
  padding-bottom: 40px;
}
edit-pledge hr {
  border-color: #66b2fb;
  margin-top: 10px;
  margin-bottom: 0;
}
edit-pledge a.cancel-pledge {
  color: dodgerblue;
}
edit-pledge input.ng-invalid {
  border-color: red;
  background: rgba(255, 0, 0, 0.1);
}
edit-pledge input.ng-invalid.form-control:focus {
  border-color: red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}
.manager-pledge-detail .basic-info div[class*='col-xs-'] {
  padding: 10px 5px 10px 0;
  word-break: break-all;
}
.manager-pledge-detail .basic-info div[class*='col-xs-']:first-child {
  padding-left: 10px;
}
.manager-pledge-detail .basic-info div[class*='col-xs-'] span {
  font-size: 10px;
  margin-top: 5px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .manager-pledge-detail .basic-info div[class*='col-xs-'] {
    font-size: 11px;
  }
  .manager-pledge-detail .basic-info div[class*='col-xs-'] span {
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .manager-pledge-detail .basic-info div[class*='col-xs-'] {
    font-size: 9px;
  }
  .manager-pledge-detail .basic-info div[class*='col-xs-'] span {
    font-size: 6px;
    margin-top: 2px;
  }
}
@media (max-width: 620px) {
  .manager-pledge-detail .basic-info div[class*='col-xs-'] span {
    display: none;
  }
}
pledge-preview > div.row {
  padding-bottom: 40px;
}
pledge-preview hr {
  border-color: #66b2fb;
  margin-top: 10px;
}
pledge-preview div[class*='col-xs-'] h4 {
  font-family: 'AvenirNextLTPro Bold';
}
pledge-preview span {
  display: block;
}
@media (max-width: 1199px) {
  pledge-preview span,
  pledge-preview button.btn {
    font-size: 13px;
  }
  pledge-preview h4 {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  pledge-preview span,
  pledge-preview button.btn {
    font-size: 11px;
  }
  pledge-preview h4 {
    font-size: 13px;
  }
}
pledge-preview button.btn {
  width: 200px;
  margin: 0 10px;
}
@media (max-width: 767px) {
  pledge-preview button.btn {
    width: 110px;
    margin: 0 5px;
  }
}
pledge-preview .detailed-info-group span:first-of-type {
  margin-top: 20px;
}
pledge-preview .detailed-info-group span:not(:first-of-type) {
  margin-top: 10px;
}
.manager-create-skill .btn {
  border-radius: 8px;
}
.manager-create-skill .btn:focus,
.manager-create-skill .btn:active:focus {
  outline: none;
}
.manager-create-skill .header {
  margin-top: 3%;
  margin-bottom: 3%;
  color: #4a4a4a;
}
.manager-create-skill hr {
  border-color: #ccc;
}
.manager-create-skill .create-skill-frame {
  padding: 30px;
  padding-bottom: 80px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .manager-create-skill .create-skill-frame {
    padding-left: 0;
    padding-right: 0;
    text-align: justify;
  }
}
.manager-create-skill .save-skill-buttons .btn {
  font-weight: bold;
}
.manager-create-skill .video-help {
  text-align: center;
  margin-top: 30px;
}
.manager-create-skill .video-help a {
  color: dodgerblue;
  cursor: pointer;
}
.manager-create-skill .video-help span {
  margin-left: 5px;
  vertical-align: top;
}
.manager-create-skill .link-to-path {
  cursor: pointer;
  color: dodgerblue;
}
.manage-skill-form .skill-stats {
  font-weight: bold;
  font-size: 16px;
}
.manage-skill-form .skill-stats .skill-stats-icon {
  margin-right: 5px;
}
.manage-skill-form .skill-stats .skill-stats-icon.glyphicon {
  color: #498FE1;
}
.manage-skill-form .skill-stats .skill-stats-icon:not(:first-child) {
  margin-left: 10%;
}
@media (max-width: 1023px) {
  .manage-skill-form .skill-stats .skill-stats-icon:not(:first-child) {
    margin-left: 5%;
  }
}
.manage-skill-form .skill-stats .skill-stats-icon img {
  width: 16px;
  height: 16px;
  vertical-align: top;
}
.manage-skill-form textarea {
  resize: vertical;
  min-height: 100px;
}
.manage-skill-form input {
  border-color: #ccc;
  border-radius: 4px;
}
.manage-skill-form radio-button > div.radio-button {
  width: 200px;
  margin-top: 10px;
}
.manage-skill-form date-input > div {
  margin-top: 10px;
  font-weight: normal;
}
.skill-info-component {
  padding-top: 30px;
}
.skill-info-component .skill-badge {
  max-width: 100%;
  max-height: 80px;
}
.skill-info-component .skill-stats {
  font-weight: bold;
  font-size: 16px;
}
.skill-info-component .skill-stats .skill-stats-icon {
  margin-right: 5px;
}
.skill-info-component .skill-stats .skill-stats-icon.glyphicon {
  color: #498FE1;
}
.skill-info-component .skill-stats .skill-stats-icon:not(:first-child) {
  margin-left: 10%;
}
@media (max-width: 1023px) {
  .skill-info-component .skill-stats .skill-stats-icon:not(:first-child) {
    margin-left: 5%;
  }
}
.skill-info-component .skill-stats .skill-stats-icon img {
  width: 16px;
  height: 16px;
  vertical-align: top;
}
.skill-info-component .link-to-path {
  cursor: pointer;
  color: dodgerblue;
}
.skill-info-component h5 {
  word-break: break-word;
}
.manage-skill {
  padding-top: 20px;
}
.manage-skill .header {
  color: #4a4a4a;
}
.manage-skill .breadcrumb {
  padding-left: 0;
}
.manage-skill .btn {
  border-radius: 8px;
}
.manage-skill .btn:focus,
.manage-skill .btn:active:focus {
  outline: none;
}
.manage-skill hr {
  border-color: #ccc;
}
.manage-skill .buttons-holder {
  min-height: 34px;
}
.manage-skill .skill-detail-frame {
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .manage-skill .skill-detail-frame {
    padding-left: 0;
    padding-right: 0;
    text-align: justify;
  }
}
.manage-skill .video-holder {
  border-radius: 8px;
  overflow: hidden;
}
.manage-skill .skill-stats-right {
  margin-top: 30px;
}
.manage-skill .skill-stats-right h5 {
  line-height: 25px;
}
.manage-skill .difficulty-icon {
  height: 20px;
  vertical-align: sub;
  margin-right: 10px;
}
.create-organization {
  color: #484848;
}
.create-organization .header {
  margin: 40px 0;
}
.create-organization .drag-uploader {
  margin-top: 40px;
  width: 100%;
  padding: 6% 15px;
  text-align: center;
  border: 1px dashed #bbb;
  position: relative;
}
.create-organization .drag-uploader .delete-image-icon {
  position: absolute;
  left: 70px;
  top: 10px;
  color: #fb061f;
}
.create-organization .drag-uploader.drag-drop {
  opacity: 0.5;
}
.create-organization .drag-uploader .preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  opacity: 0.7;
}
.create-organization .drag-uploader h2 {
  margin-bottom: 15px;
}
.create-organization .drag-uploader a {
  color: #8b3bf4;
  cursor: pointer;
}
@media (max-width: 767px) {
  .create-organization h2 {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .create-organization.container {
    padding-left: 0;
    padding-right: 0;
  }
}
.organization-details-tab-content {
  margin-top: 40px;
}
.organization-details .title {
  color: #4A4A4A;
  font-weight: bold;
  margin-top: 6%;
}
.organization-details .page-tabs {
  margin-top: 6%;
}
.organization-details .btn {
  border-radius: 4px;
}
.organization-details .organization-details-tab-content {
  margin-top: 6%;
}
player-skill-detail > div error-message > div {
  width: calc(100% - 15px);
  margin-top: 10px;
}
player-skill-detail > div span.glyphicon-lock.locked-icon {
  margin-left: -15px;
  margin-right: 2px;
  color: dodgerblue;
}
player-skill-detail > div button.btn.copy-id-btn {
  border-radius: 4px;
  margin-top: 20px;
}
player-skill-detail > div locked-player-skill-banner > div {
  margin-top: 10px;
}
player-skill-detail > div topya-video-component > div {
  border-radius: 8px;
  overflow: hidden;
}
player-skill-detail > div player-skill-score-form > div {
  margin-top: 15px;
}
player-skill-detail > div div.player-skills-col.content-col {
  width: 11.8%;
}
player-skill-detail > div div.player-skills-col.content-col-sm {
  width: 3%;
}
player-skills-filters > div hr {
  border-top-width: 1px;
}
player-skills-filters > div search-form button.btn,
player-skills-filters > div search-form input.form-control {
  border-radius: 4px;
}
player-skills-filters > div manager-column-head > div {
  padding: 0;
}
player-skills-filters > div div.header-holder {
  margin-top: 20px;
  margin-bottom: -20px;
}
create-product > div .page-header {
  font-family: 'AvenirNextLTPro Bold';
  border-bottom: none;
}
edit-product > div .page-header {
  font-family: 'AvenirNextLTPro Bold';
  border-bottom: none;
}
edit-product > div .nav-tabs.nav-justified > li > a {
  color: #555;
}
edit-product > div .nav-tabs.nav-justified > .active > a,
edit-product > div .nav-tabs.nav-justified > .active > a:hover,
edit-product > div .nav-tabs.nav-justified > .active > a:focus {
  border-color: dodgerblue;
  border-bottom: none;
  color: dodgerblue;
  font-weight: bold;
}
@media (min-width: 768px) {
  edit-product > div .nav-tabs.nav-justified > li > a {
    border-bottom-color: dodgerblue;
  }
}
product-detail > div .detail-header {
  color: #59595a;
  padding: 10px 0;
  font-size: 16px;
}
product-detail > div .detail-header span.paths-count {
  width: 106px;
  text-align: center;
  display: block;
}
add-role-modal > div {
  text-align: center;
}
add-role-modal > div .title {
  font-family: 'AvenirNextLTPro Bold';
}
add-role-modal > div input.form-control,
add-role-modal > div button.btn.get-started-btn {
  width: 250px;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}
add-role-modal > div hr {
  border-width: 2px;
}
add-role-modal > div error-message > div {
  text-align: left;
}
permissions-drag-drop > div .drag-area {
  border: 1px solid #a3a3a3;
  padding: 10px 5px;
}
permissions-drag-drop > div .drag-area.dndDragover {
  opacity: 0.7;
}
permissions-drag-drop > div li.dndPlaceholder {
  display: none;
}
role-create > div.container-fluid {
  padding: 0;
}
role-create > div.container-fluid button.btn {
  border-radius: 4px;
}
role-edit > div.container-fluid {
  padding: 0;
}
role-edit > div.container-fluid button.btn {
  border-radius: 4px;
}
role-permissions-edit input.form-control {
  width: 300px;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
}
role-permissions-edit .margin-top-34 {
  margin-top: 34px;
}
roles-dropdown > div.dropdown {
  width: 250px;
}
roles-dropdown > div button.btn {
  border-radius: 4px;
}
roles-dropdown > div .dropdown-menu {
  width: 100%;
}
roles-dropdown > div .dropdown-menu:before {
  left: 5px;
  right: auto;
}
roles-dropdown > div .dropdown-menu:after {
  left: 3px;
  right: auto;
}
roles-dropdown > div .dropdown-menu li {
  padding: 10px 15px;
  cursor: pointer;
}
create-skill-scoring > div h1 {
  font-family: 'AvenirNextLTPro Bold';
}
create-skill-scoring > div label {
  font-weight: bold;
  display: block;
}
create-skill-scoring > div label.range-label {
  font-weight: normal;
  display: inline-block;
}
create-skill-scoring > div input.form-control {
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
}
create-skill-scoring > div input.form-control[type="number"] {
  width: 80px;
  margin: 0 15px;
  display: inline-block;
}
create-skill-scoring > div div.passing-input label {
  display: inline-block;
}
create-skill-scoring > div radio-input:not(:first-child) > div {
  margin-left: 15px;
}
create-skill-scoring > div div.buttons-holder {
  width: 100%;
  text-align: right;
}
create-skill-scoring > div error-message > div {
  margin-top: 30px;
}
edit-skill-scoring > div error-message > div {
  margin-top: 30px;
}
skill-scoring-detail > div {
  color: #59595a;
  padding: 10px 0;
  font-size: 16px;
}
.current-contest {
  padding-top: 40px;
}
.current-contest input,
.current-contest button {
  border-radius: 4px;
}
.current-contest manager-column-head > div {
  padding: 0;
}
.current-contest .table-header {
  padding: 10px 15px;
  background-color: #003;
  color: #fff;
}
.current-contest .table-header .active-item {
  color: dodgerblue;
}
@media (max-width: 745px) {
  .current-contest .table-header {
    padding: 10px 5px;
  }
}
@media (max-width: 1020px) {
  .current-contest .table-header {
    font-size: 12px;
  }
  .current-contest .table-header div[class*="col-xs"] {
    padding-right: 5px;
  }
}
@media (max-width: 745px) {
  .current-contest .table-header {
    font-size: 11px;
  }
}
@media (max-width: 666px) {
  .current-contest .table-header {
    font-size: 10px;
  }
}
.current-contest .table-header div > span[class*='glyphicon-triangle'] {
  vertical-align: middle;
}
.current-contest .table-header div > span[class*='glyphicon-star'] {
  background-color: #fff;
  color: #003;
  border-radius: 50%;
  border: 1px solid #fff;
  vertical-align: sub;
}
.current-contest .table-header .active-item span[class*='glyphicon-star'] {
  background-color: dodgerblue;
  border-color: dodgerblue;
}
.current-contest .table-item:nth-child(odd) .detail-header {
  background-color: #d8d8d8;
}
.current-contest .table-item:nth-child(odd) .contest-skill-detail .expanded-view {
  border: 2px solid #d8d8d8;
}
.current-contest .table-item:nth-child(even) .contest-skill-detail .expanded-view {
  border: 2px solid #fff;
}
.current-contest .table-item .contest-skill-detail .expanded-view {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.current-contest .table-item .table-item-footer {
  padding-top: 20px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .current-contest .table-item .table-item-footer button {
    margin-top: 65px;
  }
}
@media (max-width: 767px) {
  .current-contest .table-item .table-item-footer button {
    margin-top: 20px;
  }
}
.current-contest .table-item input[type="checkbox"] {
  margin-left: 50%;
  transform: translateX(-50%);
}
.current-contest .contest-skill-detail .detail-header {
  padding: 15px 15px;
  position: relative;
}
.current-contest .contest-skill-detail .detail-header span[class*="glyphicon-chevron"] {
  color: #4a4a4a;
}
.current-contest .contest-skill-detail .detail-header input[type="checkbox"] {
  z-index: 2;
}
@media (max-width: 745px) {
  .current-contest .contest-skill-detail .detail-header {
    padding: 15px 5px;
  }
}
@media (max-width: 1020px) {
  .current-contest .contest-skill-detail .detail-header {
    font-size: 12px;
  }
  .current-contest .contest-skill-detail .detail-header div[class*="col-xs"] {
    padding-right: 5px;
  }
}
@media (max-width: 745px) {
  .current-contest .contest-skill-detail .detail-header {
    font-size: 11px;
  }
}
@media (max-width: 666px) {
  .current-contest .contest-skill-detail .detail-header {
    font-size: 10px;
  }
}
.finalist-skills-list {
  padding-top: 40px;
}
.finalist-skills-list .btn-success {
  width: 100%;
  background: #5cb85c;
}
.finalist-skills-list manager-column-head > div {
  padding: 0;
}
.finalist-skills-list input,
.finalist-skills-list button {
  border-radius: 4px;
}
.finalist-skills-list .table-item:nth-child(odd) .detail-header {
  background-color: #d8d8d8;
}
.finalist-skills-list .table-item:nth-child(odd) .finalist-skill-detail .expanded-view {
  border: 2px solid #d8d8d8;
}
.finalist-skills-list .table-item:nth-child(even) .finalist-skill-detail .expanded-view {
  border: 2px solid #fff;
}
.finalist-skills-list .table-item .finalist-skill-detail .expanded-view {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.finalist-skills-list .table-item .table-item-footer {
  padding-top: 20px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .finalist-skills-list .table-item .table-item-footer button {
    margin-top: 65px;
  }
}
@media (max-width: 767px) {
  .finalist-skills-list .table-item .table-item-footer button {
    margin-top: 20px;
  }
}
.finalist-skills-list .finalist-skill-detail .detail-header {
  padding: 15px 15px;
}
.finalist-skills-list .finalist-skill-detail .detail-header span[class*="glyphicon-chevron"] {
  color: #4a4a4a;
}
.finalist-skills-list .finalist-skill-detail .detail-header span[class*="glyphicon-remove"] {
  color: #d4172e;
  margin-right: 30px;
}
@media (max-width: 745px) {
  .finalist-skills-list .finalist-skill-detail .detail-header {
    padding: 15px 5px;
  }
}
@media (max-width: 1020px) {
  .finalist-skills-list .finalist-skill-detail .detail-header {
    font-size: 12px;
  }
  .finalist-skills-list .finalist-skill-detail .detail-header div[class*="col-xs"] {
    padding-right: 5px;
  }
}
@media (max-width: 745px) {
  .finalist-skills-list .finalist-skill-detail .detail-header {
    font-size: 11px;
  }
}
@media (max-width: 666px) {
  .finalist-skills-list .finalist-skill-detail .detail-header {
    font-size: 10px;
  }
}
.nominated-skills-list {
  padding-top: 30px;
}
.nominated-skills-list input,
.nominated-skills-list button {
  border-radius: 4px;
}
.nominated-skills-list manager-column-head > div {
  padding: 0;
}
.nominated-skills-list .table-item:nth-child(odd) .detail-header {
  background-color: #d8d8d8;
}
.nominated-skills-list .table-item:nth-child(odd) .nominated-skill-detail .expanded-view {
  border: 2px solid #d8d8d8;
}
.nominated-skills-list .table-item:nth-child(even) .nominated-skill-detail .expanded-view {
  border: 2px solid #fff;
}
.nominated-skills-list .table-item .nominated-skill-detail .expanded-view {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.nominated-skills-list .table-item .table-item-footer {
  padding-top: 20px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .nominated-skills-list .table-item .table-item-footer button {
    margin-top: 65px;
  }
}
@media (max-width: 767px) {
  .nominated-skills-list .table-item .table-item-footer button {
    margin-top: 20px;
  }
}
.nominated-skills-list .nominated-skill-detail .detail-header {
  padding: 15px 15px;
}
.nominated-skills-list .nominated-skill-detail .detail-header span[class*="glyphicon-chevron"] {
  color: #4a4a4a;
}
@media (max-width: 745px) {
  .nominated-skills-list .nominated-skill-detail .detail-header {
    padding: 15px 5px;
  }
}
@media (max-width: 1020px) {
  .nominated-skills-list .nominated-skill-detail .detail-header {
    font-size: 12px;
  }
  .nominated-skills-list .nominated-skill-detail .detail-header div[class*="col-xs"] {
    padding-right: 5px;
  }
}
@media (max-width: 745px) {
  .nominated-skills-list .nominated-skill-detail .detail-header {
    font-size: 11px;
  }
}
@media (max-width: 666px) {
  .nominated-skills-list .nominated-skill-detail .detail-header {
    font-size: 10px;
  }
}
.top-skill-detail {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .top-skill-detail .margin-top-xsmall {
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .top-skill-detail .skill-info {
    padding-top: 50px;
  }
}
@media (max-width: 991px) {
  .top-skill-detail .skill-info {
    padding-top: 15px;
  }
}
.top-skill-detail .skill-info .skill-stats > * {
  display: inline-block;
}
.top-skill-detail .skill-info .skill-stats h5 {
  margin-left: 5px;
}
.top-skill-detail .skill-info .skill-stats i.stats-icon {
  color: #498fe1;
}
.top-skill-detail .skill-info .skill-stats img.stats-icon {
  margin-left: 15px;
  vertical-align: sub;
}
.top-skills-category-selector .btn-group > .btn {
  text-align: left;
  border-radius: 4px;
  height: 44px;
}
.top-skills-category-selector .btn-group > .btn span.glyphicon {
  margin-top: 5px;
  margin-right: 7px;
}
.top-skills-category-selector ul.dropdown-menu > li {
  height: 40px;
}
.top-skills-category-selector ul.dropdown-menu > li input[type="radio"] {
  margin-right: 4px;
}
.top-skills-category-selector ul.dropdown-menu > li .category-selector {
  position: relative;
  border: 1px solid #a5a5a5;
  float: right;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #e4e1e1;
}
.top-skills-category-selector ul.dropdown-menu > li .category-selector .green-circle {
  background-color: #84c542;
  border-radius: 50%;
  position: absolute;
  width: 14px;
  height: 14px;
  top: 2px;
  left: 2px;
}
.top-skills-category-selector ul.dropdown-menu > li .category-logo,
.top-skills-category-selector .btn-group > .btn .category-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: inline-block;
}
.top-skills-category-selector ul.dropdown-menu > li span,
.top-skills-category-selector .btn-group > .btn span {
  display: inline-block;
  color: #676767;
}
.top-skills-category-selector ul.dropdown-menu > li span.no-icon,
.top-skills-category-selector .btn-group > .btn span.no-icon {
  margin-left: 42px;
  margin-top: 4px;
}
user-privacy-controls-modal {
  padding-top: 30px;
  padding-bottom: 30px;
}
user-privacy-controls-modal .modal-footer {
  text-align: center;
}
user-privacy-controls-modal .modal-footer button.btn {
  width: 150px;
  border-radius: 4px;
}
@media (max-width: 469px) {
  user-privacy-controls-modal .modal-footer button.btn {
    margin-top: 10px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
user-privacy-controls-modal .modal-body .checkbox-group {
  margin-left: 50%;
  transform: translateX(-50%);
}
user-privacy-controls-modal .modal-body .checkbox-group:last-child {
  margin-bottom: 25px;
}
@media (max-width: 469px) {
  user-privacy-controls-modal .modal-body .checkbox-group label {
    font-size: 10px;
  }
}
.review .review-table list-users-item > div[class*='review-row'] {
  padding-top: 10px;
  padding-bottom: 10px;
}
my-fundraiser-distributions > div {
  margin-bottom: 30px;
  border-right: 1px solid #b9b6b7;
  height: 400px;
}
fundraiser-basics-modal > div {
  background-color: #ebf3fc;
  border-radius: 6px;
  padding-top: 30px;
  padding-bottom: 40px;
}
fundraiser-basics-modal > div input.form-control,
fundraiser-basics-modal > div textarea.form-control {
  border-radius: 4px;
}
fundraiser-basics-modal > div input.form-control.ng-invalid,
fundraiser-basics-modal > div textarea.form-control.ng-invalid,
fundraiser-basics-modal > div input.form-control.invalid,
fundraiser-basics-modal > div textarea.form-control.invalid {
  border-color: red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}
fundraiser-basics-modal > div textarea {
  resize: none;
  height: 100px;
}
fundraiser-basics-modal > div button.btn {
  border-radius: 4px;
}
fundraiser-basics-modal > div button.btn.btn-primary.cancel {
  background-color: #fff;
  color: dodgerblue;
}
fundraiser-basics-preview > div .contest-name {
  font-family: 'AvenirNextLTPro Bold';
  color: #4b4b4b;
}
fundraiser-basics-preview > div p {
  font-size: 16px;
}
fundraiser-basics-preview > div a {
  color: dodgerblue;
  font-size: 16px;
}
fundraiser-basics-preview > div button.btn {
  width: auto;
  border-radius: 4px;
  min-width: 200px;
}
fundraiser-basics-preview > div button.btn.btn-primary.cancel {
  background: #fff;
  display: block;
  color: dodgerblue;
}
fundraiser-leaderboards iframe {
  width: calc(100% + 4px);
  margin-left: -2px;
  margin-right: -2px;
}
@media (max-width: 991px) {
  fundraiser-leaderboards iframe {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }
}
fundraiser-preview-tabs > .container-fluid {
  background-color: #fff;
  border: 1px solid #b9b6b7;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 400px;
}
@media (max-width: 991px) and (min-width: 768px) {
  fundraiser-preview-tabs .fundraiser-preview-tabs {
    font-size: 11px;
  }
}
@media (max-width: 597px) {
  fundraiser-preview-tabs .fundraiser-preview-tabs {
    font-size: 11px;
    text-align: center;
  }
}
fundraiser-preview-tabs .fundraiser-preview-tabs span.link-holder {
  min-width: 75px;
  margin-top: 5px;
}
pledge-stats-preview > .container-fluid {
  padding-bottom: 30px;
  background-color: #fff;
  border: 1px solid #b9b6b7;
  padding-right: 35px;
  padding-left: 35px;
}
@media (max-width: 1199px) {
  pledge-stats-preview > .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
pledge-stats-preview .alert {
  margin-top: 30px;
}
my-fundraisers-players-block > div div.content {
  background-color: #fff;
  border: 1px solid #DBDBEA;
  border-top: none;
}
my-fundraisers-players-list-item > div span.details-title {
  color: #4b4b4b;
  font-size: 18px;
}
my-fundraisers-players-list-item > div img.player-avatar {
  height: 80px;
  margin-right: 30px;
}
my-fundraisers-players-list-item > div span.glyphicon {
  margin-top: 30px;
}
my-fundraisers-players-list-item > div:first-child {
  background-color: #e7e7e8;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #d2d2d2;
}
player-fundraising-details > div {
  color: #4B4B4B;
}
player-fundraising-details > div h1.title {
  margin-top: 40px;
  font-family: 'AvenirNextLTPro Bold';
}
player-fundraising-details > div p.description {
  margin-top: 40px;
  font-weight: bold;
}
player-fundraising-details > div span.contest-start-end {
  font-weight: bold;
  display: block;
}
player-fundraising-details > div span.contest-start-end:first-of-type {
  margin-top: 40px;
}
player-fundraising-details > div span.contest-start-end:not(:first-of-type) {
  margin-top: 15px;
}
player-fundraising-details > div a.landing-page-link {
  color: dodgerblue;
  font-weight: bold;
  margin-top: 15px;
  display: block;
  text-align: left;
}
player-fundraising-pledges > div {
  margin-top: 60px;
  font-weight: bold;
  color: #8B8B8B;
}
player-fundraising-pledges > div .table {
  background-color: #fff;
  border: 1px solid #9C9C9C;
  padding: 15px;
}
player-fundraising-pledges > div .table-title {
  color: #333333;
  margin-bottom: 30px;
  display: block;
}
player-fundraising-pledges > div span.table-subtitle {
  display: block;
  margin-top: 10px;
}
player-fundraising-pledges > div .filter-buttons {
  margin-top: 40px;
}
player-fundraising-pledges > div .filter-buttons > div {
  text-align: center;
}
player-fundraising-pledges > div .filter-buttons > div > button.btn.fundraiser-stats-button {
  margin-top: 20px;
  width: 200px;
  display: block;
  font-weight: bold;
  margin-left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
player-fundraising-pledges > div .status-messages-holder {
  widht: 100%;
  padding-right: 10px;
}
player-fundraising-pledges > div .pledges-list-holder {
  max-height: 405px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 10px);
}
player-fundraising-pledges > div .table-header {
  color: #8B8B8B;
  font-weight: normal;
  margin-top: 40px;
}
player-fundraising-pledges > div .table-header hr {
  border-top: 1px solid #bdbcbc;
  margin: 0;
}
player-fundraising-pledges > div.hybrid-frame {
  font-size: 20pt;
  border: none;
  padding: 0;
  margin-top: 0;
}
player-fundraising-pledges > div.hybrid-frame > .table {
  border: none;
}
player-fundraising-pledges > div.hybrid-frame > div > span.table-subtitle {
  font-size: 25pt;
  letter-spacing: 0.5pt;
  margin-top: 25px;
  color: #5d5d5d;
  padding: 0 50pt;
}
player-fundraising-pledges > div.hybrid-frame .filter-buttons > div > button {
  width: 250px;
  font-size: 20pt;
  padding: 7pt 3pt;
  max-width: 100%;
}
parent-activity-feed-card > div.card {
  width: 100%;
}
parent-activity-feed-card > div.card div.content-holder {
  padding: 24px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
parent-activity-feed-card > div.card spinner > div {
  margin-top: 24px;
  margin-left: 50%;
  transform: translateX(-50%);
}
parent-activity-feed-card > div.card error-message > div,
parent-activity-feed-card > div.card info-message > span {
  margin: 24px 0;
}
parent-add-kid > form.container-fluid {
  padding: 0;
}
parent-add-kid > form.container-fluid h3.title {
  color: #333333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 6px;
}
parent-add-kid > form.container-fluid view-title > div {
  margin-top: 24px;
}
parent-add-kid > form.container-fluid div.form-fields-holder {
  padding: 24px;
  background-color: #fff;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
parent-add-kid > form.container-fluid div.form-fields-holder text-input:not(:first-of-type) > span.label,
parent-add-kid > form.container-fluid div.form-fields-holder calendar-input > span.label,
parent-add-kid > form.container-fluid div.form-fields-holder parent-add-kid-invitation-code text-input > span.label {
  margin-top: 24px;
}
parent-add-kid > form.container-fluid div.form-fields-holder div.or {
  margin-top: 40px;
}
parent-add-kid > form.container-fluid div.form-fields-holder div.or > span.or-divider {
  display: inline-block;
}
parent-add-kid > form.container-fluid div.form-fields-holder div.or > span.or-divider.or-divider-text {
  color: #999;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 24px;
  vertical-align: text-top;
}
parent-add-kid > form.container-fluid div.form-fields-holder div.or > span.or-divider.or-divider-left,
parent-add-kid > form.container-fluid div.form-fields-holder div.or > span.or-divider.or-divider-right {
  width: calc(50% - 40px);
  border: 1px solid #D8D8D8;
}
@media (min-width: 1200px) {
  parent-add-kid > form.container-fluid div.form-fields-holder text-input > input.form-control,
  parent-add-kid > form.container-fluid div.form-fields-holder hr,
  parent-add-kid > form.container-fluid div.form-fields-holder div.or,
  parent-add-kid > form.container-fluid div.form-fields-holder calendar-input > div.input-holder,
  parent-add-kid > form.container-fluid div.form-fields-holder parent-add-kid-invitation-code > div > button.btn.btn-primary {
    width: 50%;
  }
}
parent-add-kid > form.container-fluid > button.btn {
  width: auto;
  margin-top: 24px;
}
parent-add-kid > form.container-fluid > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-add-kid > form.container-fluid error-message > div {
  margin-top: 40px;
}
@media (min-width: 1200px) {
  parent-add-kid > form.container-fluid error-message > div {
    width: 50%;
  }
}
parent-add-kid > form.container-fluid div.gender-select {
  margin-top: 24px;
}
parent-add-kid > form.container-fluid div.gender-select span.gender-label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: block;
  text-align: left;
  width: auto;
}
parent-add-kid > form.container-fluid div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 16px;
}
parent-add-kid > form.container-fluid.mobile h3.title {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
parent-add-kid > form.container-fluid.mobile div.form-fields-holder {
  padding: 7.5vw;
}
parent-add-kid > form.container-fluid.mobile div.form-fields-holder text-input:not(:first-of-type) > span.label,
parent-add-kid > form.container-fluid.mobile div.form-fields-holder calendar-input > span.label,
parent-add-kid > form.container-fluid.mobile div.form-fields-holder parent-add-kid-invitation-code text-input > span.label {
  margin-top: 7.5vw;
}
parent-add-kid > form.container-fluid.mobile div.form-fields-holder div.or {
  margin-top: 12.5vw;
}
parent-add-kid > form.container-fluid.mobile div.form-fields-holder div.or > span.or-divider.or-divider-text {
  font-size: 5vw;
  line-height: 7.5vw;
  margin: 0 7.5vw;
  vertical-align: middle;
}
parent-add-kid > form.container-fluid.mobile div.form-fields-holder div.or > span.or-divider.or-divider-left,
parent-add-kid > form.container-fluid.mobile div.form-fields-holder div.or > span.or-divider.or-divider-right {
  width: calc(50% - 120px);
  border: 2px solid #D8D8D8;
}
parent-add-kid > form.container-fluid.mobile view-title > div {
  margin-top: 7.5vw;
}
parent-add-kid > form.container-fluid.mobile div.gender-select {
  margin-top: 7.5vw;
}
parent-add-kid > form.container-fluid.mobile div.gender-select span.gender-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
parent-add-kid > form.container-fluid.mobile div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 5vw;
}
parent-add-kid > form.container-fluid.mobile > button.btn {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  margin-top: 10vw;
  padding: 2.5vw 5vw;
}
parent-add-kid > form.container-fluid.mobile > button.btn:not(.cancel) {
  margin-left: 2.5vw;
}
parent-my-kids-card > div.card {
  width: 100%;
}
parent-my-kids-card > div.card div.content-holder {
  padding: 0 24px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
parent-my-kids-card > div.card div.content-holder parent-my-kids-card-item:last-of-type > div {
  border-bottom: none;
}
parent-my-kids-card > div.card spinner > div {
  margin: 24px 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
parent-my-kids-card > div.card error-message > div,
parent-my-kids-card > div.card info-message > span {
  margin: 24px 0;
}
parent-my-kids-list > div.container-fluid {
  box-sizing: border-box;
  padding: 0;
}
parent-my-kids-list > div.container-fluid hr {
  margin: 0;
  margin-top: -1px;
  border-color: #E5E5E5;
}
parent-my-kids-list > div.container-fluid div.filters-holder {
  width: 100%;
  display: inline-block;
}
parent-my-kids-list > div.container-fluid div.filters-holder parent-my-kids-add-button > button {
  float: right;
}
parent-my-kids-list > div.container-fluid div.table-holder {
  width: 100%;
  display: inline-block;
  margin-top: 24px;
}
parent-my-kids-list > div.container-fluid error-message > div,
parent-my-kids-list > div.container-fluid info-message > span {
  margin-top: 24px;
}
parent-my-kids-list > div.container-fluid spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
parent-my-kids-list > div.container-fluid.mobile {
  border: 2px solid #E5E5E5;
  border-radius: 1.25vw;
  background-color: #FFFFFF;
  padding: 12.5vw 7.5vw;
}
parent-my-kids-list > div.container-fluid.mobile hr {
  display: none;
}
parent-my-kids-profile > div.container-fluid {
  padding: 0;
}
parent-my-kids-profile > div.container-fluid h3.title {
  color: #333333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 6px;
}
parent-my-kids-profile > div.container-fluid parent-my-kids-profile-tabs > div {
  margin-top: 40px;
  margin-bottom: 24px;
}
parent-my-kids-profile > div.container-fluid.mobile h3.title {
  font-size: 6.25vw;
  line-height: 7.25vw;
}
parent-my-kids-profile > div.container-fluid.mobile parent-my-kids-profile-tabs > span.mobile {
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
  display: inline-block;
}
parent-my-kids-profile > spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
}
parent-profile-confirm-modal > div {
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(40, 41, 61, 0.04), 0 8px 16px 0 rgba(96, 97, 112, 0.16);
  padding: 24px;
  padding-top: 28px;
}
parent-profile-confirm-modal > div > span.title {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
parent-profile-confirm-modal > div > p.prompt-message {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 16px;
}
parent-profile-confirm-modal > div > div.buttons-holder {
  margin-top: 32px;
  text-align: right;
}
parent-profile-confirm-modal > div > div.buttons-holder > button.btn {
  width: auto;
  font-size: 16px;
}
parent-profile-confirm-modal > div > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-profile-confirm-modal > div.mobile {
  border-radius: 2.5vw;
  box-shadow: 0 4px 8px 0 rgba(40, 41, 61, 0.04), 0 16px 32px 0 rgba(96, 97, 112, 0.16);
  padding: 7.5vw;
  padding-top: 8.75vw;
}
parent-profile-confirm-modal > div.mobile > span.title {
  font-size: 5vw;
  line-height: 7.5vw;
}
parent-profile-confirm-modal > div.mobile > p.prompt-message {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 5vw;
}
parent-profile-confirm-modal > div.mobile > div.buttons-holder {
  margin-top: 10vw;
  text-align: center;
  width: 100%;
}
parent-profile-confirm-modal > div.mobile > div.buttons-holder > button.btn {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-profile-confirm-modal > div.mobile > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 0;
  margin-top: 5vw;
}
parent-profile-picture > div {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
parent-profile-picture > div.disabled {
  opacity: 0.7;
}
parent-profile-picture > div.disabled > div.overlay {
  cursor: default;
}
parent-profile-picture > div > img.profile-picture {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}
parent-profile-picture > div > div.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
}
parent-profile-picture > div > div.overlay > img.camera-icon {
  margin-top: 50%;
  transform: translateY(-50%);
}
parent-profile-picture > div.mobile {
  width: 30vw;
  height: 30vw;
}
parent-profile-picture > div.mobile > div.overlay > img.camera-icon {
  width: 7.5vw;
}
parent-subscriptions-card > div.card {
  width: 100%;
}
parent-subscriptions-card > div.card div.content-holder {
  padding: 0 24px 24px 24px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
parent-subscriptions-card > div.card spinner > div {
  margin: 24px 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
parent-subscriptions-card > div.card error-message > div {
  margin: 24px 0;
}
parent-subscriptions-card > div.card button.btn,
parent-subscriptions-card > div.card info-message > span {
  margin-top: 24px;
}
parent-subscriptions-list > div {
  width: 100%;
}
parent-subscriptions-list > div h3.title {
  margin-top: 6px;
  color: #333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
parent-subscriptions-list > div > button.btn {
  margin-top: 24px;
  width: auto;
  display: block;
}
parent-subscriptions-list > div > success-message > div {
  margin-top: 24px;
}
parent-subscriptions-list > div > view-title > div {
  margin-top: 24px;
}
parent-subscriptions-list > div error-message > div,
parent-subscriptions-list > div info-message > span {
  margin-top: 24px;
}
parent-subscriptions-list > div div.items-holder {
  max-height: 210px;
  overflow-y: auto;
  background: #e8e8e8;
}
parent-subscriptions-list > div div.items-holder.has-scrollbar > parent-subscriptions-list-item > div:not(.mobile) > span.price {
  width: 11%;
}
parent-subscriptions-list > div spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
parent-subscriptions-list > div div.no-subscriptions-message {
  background: #fff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 40px 0;
  text-align: center;
}
parent-subscriptions-list > div div.no-subscriptions-message > span {
  color: #999;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 27px;
  display: block;
}
parent-subscriptions-list > div div.no-subscriptions-message > button.btn {
  width: auto;
  margin-top: 32px;
}
parent-subscriptions-list > div.mobile h3.title {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
parent-subscriptions-list > div.mobile > button.btn {
  margin-top: 7.5vw;
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
parent-subscriptions-list > div.mobile > view-title > div {
  margin-top: 7.5vw;
}
parent-subscriptions-list > div.mobile div.items-holder {
  max-height: 100vw;
}
parent-subscriptions-list > div.mobile div.no-subscriptions-message {
  border-width: 2px;
  padding: 12.5vw 7.5vw;
  text-align: center;
}
parent-subscriptions-list > div.mobile div.no-subscriptions-message > span {
  font-size: 6.25vw;
  line-height: 8.5vw;
}
parent-subscriptions-list > div.mobile div.no-subscriptions-message > button.btn {
  margin-top: 7.5vw;
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
parent-subscriptions-list > div.mobile error-message > div,
parent-subscriptions-list > div.mobile info-message > span,
parent-subscriptions-list > div.mobile success-message > div {
  margin-top: 7.5vw;
}
player-subscriptions-card > div.card {
  width: 100%;
}
player-subscriptions-card > div.card div.content-holder {
  padding: 0 24px 24px 24px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
player-subscriptions-card > div.card spinner > div {
  margin: 24px 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
player-subscriptions-card > div.card error-message > div {
  margin: 24px 0;
}
player-subscriptions-card > div.card button.btn,
player-subscriptions-card > div.card info-message > span {
  margin-top: 24px;
}
player-subscriptions-list > div {
  width: 100%;
}
player-subscriptions-list > div h3.title {
  margin-top: 6px;
  color: #333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
player-subscriptions-list > div > button.btn {
  margin-top: 24px;
  width: auto;
  display: block;
}
player-subscriptions-list > div > success-message > div {
  margin-top: 24px;
}
player-subscriptions-list > div > view-title > div {
  margin-top: 24px;
}
player-subscriptions-list > div error-message > div,
player-subscriptions-list > div info-message > span {
  margin-top: 24px;
}
player-subscriptions-list > div div.items-holder {
  max-height: 210px;
  overflow-y: auto;
  background: #e8e8e8;
}
player-subscriptions-list > div div.items-holder.has-scrollbar > player-subscriptions-list-item > div:not(.mobile) > span.price {
  width: 11%;
}
player-subscriptions-list > div spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
player-subscriptions-list > div div.no-subscriptions-message {
  background: #fff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 40px 0;
  text-align: center;
}
player-subscriptions-list > div div.no-subscriptions-message > span {
  color: #999;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 27px;
  display: block;
}
player-subscriptions-list > div div.no-subscriptions-message > button.btn {
  width: auto;
  margin-top: 32px;
}
player-subscriptions-list > div.mobile h3.title {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
player-subscriptions-list > div.mobile > button.btn {
  margin-top: 7.5vw;
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
player-subscriptions-list > div.mobile > view-title > div {
  margin-top: 7.5vw;
}
player-subscriptions-list > div.mobile div.items-holder {
  max-height: 100vw;
}
player-subscriptions-list > div.mobile div.no-subscriptions-message {
  border-width: 2px;
  padding: 12.5vw 7.5vw;
  text-align: center;
}
player-subscriptions-list > div.mobile div.no-subscriptions-message > span {
  font-size: 6.25vw;
  line-height: 8.5vw;
}
player-subscriptions-list > div.mobile div.no-subscriptions-message > button.btn {
  margin-top: 7.5vw;
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  padding: 2.5vw 5vw;
}
player-subscriptions-list > div.mobile error-message > div,
player-subscriptions-list > div.mobile info-message > span,
player-subscriptions-list > div.mobile success-message > div {
  margin-top: 7.5vw;
}
register-view-header .logo {
  margin-top: 40px;
  margin-bottom: 25px;
}
register-view-header h3 {
  font-weight: bold;
}
register-select-type-links .tos-link {
  text-decoration: underline;
}
register-select-type-links .tos-link.close {
  text-decoration: none;
}
register-select-type-button .account-type-button {
  width: 30%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  background: none;
  padding: 0;
  border: 2px solid #fff;
  border-radius: 6%;
}
register-select-type-button .selected-type {
  background-color: #fff;
}
register-select-type-button .button-background {
  width: 100%;
}
signup-org-list-preview > div.container-fluid {
  padding: 0;
}
signup-org-list-preview > div.container-fluid > span.list-label {
  color: #333333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 40px;
  display: block;
}
signup-org-list-preview > div.container-fluid > div.list-header {
  display: block;
  width: 100%;
  margin-top: 24px;
  background-color: rgba(229, 229, 229, 0.3);
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 24px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-org-list-preview > div.container-fluid > div.items-holder {
  max-height: 640px;
  overflow-y: auto;
}
signup-org-list-preview > div.container-fluid.mobile > span.list-label {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 12.5vw;
}
signup-org-list-preview > div.container-fluid.mobile > div.list-header {
  margin-top: 7.5vw;
  padding: 5vw 7.5vw 5vw 7.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
}
signup-orgs-list > div.container-fluid {
  padding: 0;
}
signup-orgs-list > div.container-fluid spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
signup-orgs-list > div.container-fluid error-message > div,
signup-orgs-list > div.container-fluid text-input > input.form-control {
  margin-top: 24px;
}
signup-orgs-list > div.container-fluid > div.list-header {
  display: block;
  width: 100%;
  margin-top: 24px;
  background-color: rgba(229, 229, 229, 0.3);
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 24px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
signup-orgs-list > div.container-fluid > div.items-holder {
  max-height: 640px;
  overflow-y: auto;
}
signup-orgs-list > div.container-fluid.mobile spinner > div,
signup-orgs-list > div.container-fluid.mobile error-message > div,
signup-orgs-list > div.container-fluid.mobile text-input > input.form-control {
  margin-top: 7.5vw;
}
signup-orgs-list > div.container-fluid.mobile > div.list-header {
  margin-top: 7.5vw;
  padding: 5vw 7.5vw 5vw 7.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
}
signup-select-team-item > div {
  padding: 12px 24px;
  border-bottom: 1px solid #e5e5e5;
}
signup-select-team-item > div > div.action-holder {
  height: 40px;
  margin-right: 16px;
  display: inline-block;
}
signup-select-team-item > div > div.action-holder.action-disabled {
  opacity: 0.7;
}
signup-select-team-item > div > div.action-holder > img {
  margin-top: 8px;
}
signup-select-team-item > div > div.logo-holder {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
signup-select-team-item > div > div.logo-holder > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
}
signup-select-team-item > div > span.team-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 56px);
}
signup-select-team-item > div.mobile {
  padding: 3.75vw 7.5vw;
  border-width: 2px;
}
signup-select-team-item > div.mobile > div.action-holder {
  margin-right: 5vw;
}
signup-select-team-item > div.mobile > div.action-holder > img {
  margin-top: 3vw;
  height: 5vw;
}
signup-select-team-item > div.mobile > span.team-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 10vw);
}
signup-summary-user-detail > div {
  margin-top: 40px;
}
signup-summary-user-detail > div > div.view-title {
  background-color: #E5E5E5;
  color: #333333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  padding: 16px;
}
signup-summary-user-detail > div > div.info-group {
  display: block;
  margin-top: 24px;
  padding: 0 16px;
}
signup-summary-user-detail > div > div.info-group > span.info-label {
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
}
signup-summary-user-detail > div > div.info-group > span.info-item {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
  margin-top: 8px;
}
signup-summary-user-detail > div.mobile {
  margin-top: 12.5vw;
}
signup-summary-user-detail > div.mobile > div.view-title {
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 5vw;
}
signup-summary-user-detail > div.mobile > div.info-group {
  margin-top: 7.5vw;
  padding: 0 5vw;
}
signup-summary-user-detail > div.mobile > div.info-group > span.info-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
signup-summary-user-detail > div.mobile > div.info-group > span.info-item {
  font-size: 5vw;
  line-height: 7.5vw;
  margin-top: 2.5vw;
}
signup-tab > span.tab {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: inline-block;
  height: 48px;
}
signup-tab > span.tab.active-tab {
  font-weight: 900;
  border-bottom: 2px solid #4A90E2;
}
signup-tab > span.tab.mobile {
  font-size: 5vw;
  line-height: 7.5vw;
  height: 10vw;
}
signup-tab > span.tab.mobile.active-tab {
  border-width: 4px;
}
player-freestyle-detail-active-buttons > div button.btn {
  white-space: normal;
  height: 100%;
}
player-freestyle-detail-footer > div .description-label {
  color: #9f9f9f;
  margin-bottom: 10px;
  margin-top: 4%;
}
player-freestyle-detail-footer > div .points {
  font-weight: bold;
}
player-freestyle-detail-footer > div .points .points-count {
  color: #000;
}
player-freestyle-detail-footer > div .points .points-text {
  color: #9f9f9f;
}
player-freestyle-detail-header > div {
  padding: 25px 0;
  position: relative;
}
player-freestyle-detail-header > div h3 {
  color: #fff;
}
player-freestyle-detail-header > div.active {
  background-color: #93CB52;
}
player-freestyle-detail-header > div.pending {
  background-color: #F7A722;
}
player-freestyle-detail-header > div.scored {
  background-color: #F7A722;
}
player-freestyle-detail-header > div.declined {
  background-color: #DA1F22;
}
player-freestyle-detail-header > div.ready-to-be-judged {
  background-color: #9B9C9B;
}
player-freestyle-detail-header > div.draw {
  background-color: #F7AA47;
}
player-freestyle-detail-header > div.draw h3 {
  color: #ED8D0A;
}
player-freestyle-detail-header > div .header-text {
  padding-top: 3%;
}
@media (max-width: 1023px) {
  player-freestyle-detail-header > div .header-text {
    padding-top: 1.2%;
  }
  player-freestyle-detail-header > div h3 {
    font-size: 18px;
  }
}
player-freestyle-detail-header > div img.bottom-arrow {
  position: absolute;
  width: 20px;
  bottom: -9px;
  height: 10px;
}
player-freestyle-detail-header > div .arrow-player {
  left: 15%;
}
player-freestyle-detail-header > div .arrow-non-player {
  left: 85%;
}
player-freestyle-detail-videos > div {
  padding-bottom: 30px;
}
player-freestyle-detail-videos > div .challenge-name {
  color: #9f9f9f;
  margin-bottom: 0;
  margin-top: 4%;
}
player-freestyle-detail-videos > div hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
player-freestyle-detail-videos > div .video-upper-label {
  color: #9f9f9f;
  margin-bottom: 2%;
  margin-top: 4%;
}
player-freestyle-detail-videos > div .video-holder {
  margin: 0;
}
player-freestyle-detail-videos > div .video-icon {
  position: absolute;
  bottom: -20px;
  width: 150px;
  text-align: right;
  right: 5px;
  z-index: 301;
}
player-freestyle-detail-videos > div .video-icon h5 {
  color: #93CB52;
  display: inline-block;
  margin-right: 10px;
}
player-freestyle-detail-videos > div .video-icon img {
  width: 40px;
  vertical-align: bottom;
}
player-freestyle-detail-videos > div .versus {
  text-align: center;
  padding-top: 13%;
}
player-freestyle-detail-videos > div .challenge-details {
  padding-top: 13%;
}
player-freestyle-detail-videos > div .challenge-details img {
  display: inline-block;
}
player-freestyle-detail-videos > div .challenge-details img.count-icon {
  width: 25px;
  margin: 0 7.5px;
}
player-freestyle-detail-videos > div .challenge-details img.team-image {
  width: 40px;
}
player-freestyle-detail-videos > div .challenge-details .team-count {
  margin-top: 5%;
}
player-freestyle-detail-videos > div .challenge-details .team-count h5 {
  color: #9f9f9f;
  margin-top: 4%;
}
player-freestyle-detail-videos > div .challenge-details h5 {
  display: inline-block;
  margin-left: 10px;
}
@media (max-width: 1023px) {
  player-freestyle-detail-videos > div .video-icon {
    bottom: -15px;
  }
  player-freestyle-detail-videos > div .video-icon img {
    width: 20px;
  }
  player-freestyle-detail-videos > div .video-icon h5 {
    font-size: 10px;
  }
  player-freestyle-detail-videos > div .challenge-details {
    padding-top: 8%;
  }
}
@media (max-width: 1199px) {
  make-pledge-buttons > div .suggested-amount-holder {
    padding: 0 5px;
  }
}
make-pledge-buttons > div button.btn.btn-primary.make-pledge {
  background-color: #ff8827;
  border-color: #e47a24;
  font-size: 20px;
  padding: 15px;
  width: 100%;
  font-weight: bold;
  height: 100%;
  white-space: normal;
  word-break: break-word;
}
make-pledge-buttons > div button.btn.btn-primary.make-pledge.suggested-amount-pledge {
  font-size: 13px;
}
@media (max-width: 1199px) {
  make-pledge-buttons > div button.btn.btn-primary.make-pledge.suggested-amount-pledge {
    font-size: 12px;
  }
}
make-pledge-buttons > div.mobile .suggested-amount-holder {
  padding: 0 5px;
}
make-pledge-buttons > div.mobile button.btn.btn-primary.make-pledge {
  padding: 10px;
}
.leaderboard-widget-filters button {
  border-radius: 4px;
}
.leaderboard-widget-filters .inner-scroll-200 {
  max-height: 189px;
}
.leaderboard-widget-filters span.team-name-filter {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 42px);
  display: inline-block;
  vertical-align: bottom;
}
@media (max-width: 480px) and (min-width: 401px) {
  .leaderboard-widget-filters,
  .leaderboard-widget-filters .btn {
    font-size: 12px;
  }
}
@media (max-width: 320px) {
  .leaderboard-widget-filters,
  .leaderboard-widget-filters .btn {
    font-size: 12px;
  }
}
@media (max-width: 400px) and (min-width: 280px) {
  .leaderboard-widget-filters,
  .leaderboard-widget-filters .btn {
    font-size: 9px;
  }
}
@media (max-width: 279px) {
  .leaderboard-widget-filters,
  .leaderboard-widget-filters .btn {
    font-size: 8px;
  }
}
@media (min-width: 425px) {
  .leaderboard-widget-filters .upper-filters-row,
  .leaderboard-widget-filters .lower-filters-row {
    height: 86px;
  }
}
@media (max-width: 424px) and (min-width: 285px) {
  .leaderboard-widget-filters .upper-filters-row,
  .leaderboard-widget-filters .lower-filters-row {
    height: 71px;
  }
}
@media (max-width: 284px) {
  .leaderboard-widget-filters .upper-filters-row,
  .leaderboard-widget-filters .lower-filters-row {
    height: 46px;
  }
}
.leaderboard-widget-filters .upper-filters-row {
  text-align: center;
}
@media (max-width: 279px) {
  .leaderboard-widget-filters .upper-filters-row {
    padding-top: 6px;
  }
}
@media (min-width: 280px) and (max-width: 284px) {
  .leaderboard-widget-filters .upper-filters-row {
    padding-top: 5px;
  }
}
@media (min-width: 285px) and (max-width: 400px) {
  .leaderboard-widget-filters .upper-filters-row {
    padding-top: 16px;
  }
}
@media (min-width: 401px) and (max-width: 424px) {
  .leaderboard-widget-filters .upper-filters-row {
    padding-top: 15px;
  }
}
@media (min-width: 425px) and (max-width: 480px) {
  .leaderboard-widget-filters .upper-filters-row {
    padding-top: 25px;
  }
}
@media (min-width: 481px) {
  .leaderboard-widget-filters .upper-filters-row {
    padding-top: 22px;
  }
}
.leaderboard-widget-filters .upper-filters-row .upper-filters-col {
  margin: 5px 0;
  width: 25%;
  display: inline-block;
  text-align: center;
}
.leaderboard-widget-filters .upper-filters-row .upper-filters-col:not(:last-child) {
  padding: 7px 5px;
}
@media (max-width: 420px) {
  .leaderboard-widget-filters .upper-filters-row .upper-filters-col:not(:last-child) {
    padding-left: 1px;
    padding-right: 1px;
  }
}
.leaderboard-widget-filters .upper-filters-row .upper-filters-col span.glyphicon {
  vertical-align: sub;
}
.leaderboard-widget-filters .lower-filters-row {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
}
.leaderboard-widget-filters .lower-filters-row .lower-filters-col {
  width: 20%;
  display: inline-block;
  text-align: center;
}
@media (min-width: 481px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    padding-top: 32px;
  }
}
@media (min-width: 425px) and (max-width: 480px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    padding-top: 34px;
  }
}
@media (max-width: 424px) and (min-width: 401px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    padding-top: 28px;
  }
}
@media (max-width: 400px) and (min-width: 285px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    padding-top: 28px;
  }
}
@media (max-width: 284px) and (min-width: 280px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    padding-top: 15px;
  }
}
@media (max-width: 279px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    padding-top: 17px;
  }
}
@media (min-width: 425px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    height: 84px;
  }
}
@media (max-width: 424px) and (min-width: 285px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    height: 69px;
  }
}
@media (max-width: 284px) {
  .leaderboard-widget-filters .lower-filters-row .lower-filters-col {
    height: 44px;
  }
}
.leaderboard-widget-filters .lower-filters-row .lower-filters-col:hover:not(.active) {
  background-color: #f3f3f3;
}
.leaderboard-widget-filters .lower-filters-row .lower-filters-col.active {
  background-color: #d7d7d7;
}
@media (max-width: 320px) {
  .leaderboard-widget-filters .dropdown-menu {
    font-size: 8px;
    padding: 0;
  }
}
.leaderboard-widget-filters .dropdown-menu {
  width: 100%;
}
@media (max-width: 480px) and (min-width: 401px) {
  .leaderboard-widget-filters .dropdown-menu {
    font-size: 12px;
  }
}
@media (max-width: 320px) {
  .leaderboard-widget-filters .dropdown-menu {
    font-size: 12px;
  }
}
@media (max-width: 400px) and (min-width: 280px) {
  .leaderboard-widget-filters .dropdown-menu {
    font-size: 9px;
  }
}
@media (max-width: 279px) {
  .leaderboard-widget-filters .dropdown-menu {
    font-size: 8px;
  }
}
.leaderboard-widget-filters .dropdown-menu.teams-dropdown {
  right: 0;
  left: auto;
}
.leaderboard-widget-filters.leaderboard-widget-filters-mobile .upper-filters-row .btn {
  width: auto;
}
.leaderboard-widget-filters.leaderboard-widget-filters-mobile .upper-filters-row .dropdown-menu {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 140px !important;
}
.topya-tv-channel-dropdown,
.topya-tv-channel-dropdown a {
  color: #7f7f7f;
}
.topya-tv-channel-dropdown input.form-control {
  border-radius: 4px;
}
.topya-tv-channel-dropdown .filter-header {
  padding-top: 15px;
  text-align: center;
}
.topya-tv-channel-dropdown .filter-header hr {
  margin-top: 10px;
  margin-bottom: 15px;
}
.topya-tv-channel-dropdown .channel hr {
  margin-bottom: 10px;
  margin-top: 10px;
}
.topya-tv-channel-dropdown .channel .item-logo-holder {
  width: 20%;
  display: inline-block;
  text-align: center;
}
.topya-tv-channel-dropdown .channel .item-logo-holder img.item-logo {
  max-width: 100%;
  max-height: 15pt;
  vertical-align: sub;
}
.topya-tv-channel-dropdown .channel .item-name {
  font-size: 9pt;
  margin-left: 5px;
  display: inline-block;
  overflow: hidden;
  width: 60%;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.topya-tv-channel-dropdown [type="radio"]:checked,
.topya-tv-channel-dropdown [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.topya-tv-channel-dropdown [type="radio"]:checked + label,
.topya-tv-channel-dropdown [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  float: right;
}
.topya-tv-channel-dropdown [type="radio"]:checked + label:before,
.topya-tv-channel-dropdown [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0px 0px 1px #888888;
}
.topya-tv-channel-dropdown [type="radio"]:checked + label:after,
.topya-tv-channel-dropdown [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #84c542;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.topya-tv-channel-dropdown [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.topya-tv-channel-dropdown [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.topya-tv-channel-dropdown .inner-scroll-200 {
  margin-bottom: 20px;
}
.mobile-video-player {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.mobile-video-player video {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
@media all and (orientation: landscape) {
  .mobile-video-player video {
    width: calc(100% - 1px);
  }
}
.mobile-video-player video.landscape {
  width: calc(100% - 1px);
}
.mobile-video-player .customer-logo {
  position: absolute;
  width: 15%;
  left: 2%;
  bottom: 35%;
}
.mobile-video-player .topya-logo {
  position: absolute;
  width: 20%;
  bottom: 35%;
  right: 2%;
}
.topya-tv-player {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 58.25%;
}
.topya-tv-player .videogular-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.topya-tv-player .player-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.topya-tv-player .player-overlay .play-icon {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  opacity: 0.7;
}
.topya-tv-player .player-overlay .play-icon.animate-play-icon.ng-hide {
  opacity: 0;
}
.topya-tv-player .player-overlay .play-icon.animate-play-icon.ng-hide-add,
.topya-tv-player .player-overlay .play-icon.animate-play-icon.ng-hide-remove {
  transition: all linear 0.2s;
}
@media (max-width: 425px) {
  .topya-tv-player .player-overlay .play-icon {
    width: 25%;
  }
}
.topya-tv-player .player-overlay:hover .play-icon {
  opacity: 0.9;
}
.topya-tv-player .player-controls {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  padding: 1%;
  bottom: 0;
  color: #fff;
  z-index: 3;
}
.topya-tv-player .player-controls.animate-controls.ng-hide {
  opacity: 0;
}
.topya-tv-player .player-controls.animate-controls.ng-hide-add,
.topya-tv-player .player-controls.animate-controls.ng-hide-remove {
  transition: all linear 0.1s;
}
.topya-tv-player .player-controls .controls-button {
  margin: 0 10px;
}
.topya-tv-player .player-controls .video-progress-holder {
  width: 35%;
  position: relative;
  height: 8px;
  margin-top: 2px;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 2%;
}
.topya-tv-player .player-controls .volume-holder {
  width: 15%;
  position: relative;
  height: 8px;
  margin-top: 2px;
  display: inline-block;
  vertical-align: text-top;
}
.topya-tv-player .player-controls span[class*='glyphicon-volume'],
.topya-tv-player .player-controls span.glyphicon-backward {
  margin-left: 3%;
}
.topya-tv-player .player-controls span.glyphicon-forward {
  margin-left: 2%;
}
.topya-tv-player .player-controls .control-bar {
  position: absolute;
  height: 100%;
}
.topya-tv-player .player-controls .control-bar.background {
  background-color: #9b9b9b;
  width: 100%;
}
.topya-tv-player .player-controls .control-bar.buffered-value {
  background-color: #b0afaf;
  width: 0%;
}
.topya-tv-player .player-controls .control-bar.current-value {
  background-color: #fff;
}
@media (max-width: 525px) {
  .topya-tv-player .player-controls {
    font-size: 8pt;
  }
  .topya-tv-player .player-controls .video-progress-holder,
  .topya-tv-player .player-controls .volume-holder {
    height: 6pt;
    vertical-align: baseline;
  }
  .topya-tv-player .player-controls span[class*='glyphicon'] {
    vertical-align: top;
  }
}
@media (max-width: 445px) {
  .topya-tv-player .player-controls {
    padding-top: 10pt;
  }
  .topya-tv-player .player-controls .video-progress-holder {
    width: 93%;
    margin: 0;
    left: 3.5%;
    position: absolute;
    bottom: 19pt;
    height: 3pt;
  }
  .topya-tv-player .player-controls .video-progress-holder:hover {
    height: 5pt;
    bottom: 18pt;
  }
  .topya-tv-player .player-controls .volume-holder {
    width: 20%;
    height: 3pt;
    margin-bottom: 1.5pt;
  }
  .topya-tv-player .player-controls .volume-holder:hover {
    height: 5pt;
    margin-bottom: 0;
  }
}
@media (max-width: 249px) {
  .topya-tv-player .player-controls .controls-button {
    margin: 0 5px;
  }
  .topya-tv-player .player-controls span[class*='glyphicon'] {
    font-size: 7pt;
  }
}
@media (max-width: 199px) {
  .topya-tv-player .player-controls .video-time {
    display: none;
  }
}
.topya-tv-player .topya-logo {
  position: absolute;
  right: 2%;
  bottom: 10%;
  width: 15%;
}
.topya-tv-player .customer-logo {
  position: absolute;
  width: 10%;
  bottom: 10%;
  left: 3%;
  opacity: 0.6;
}
@media (max-width: 425px) {
  .topya-tv-player {
    padding-bottom: 66%;
  }
  .topya-tv-player .customer-logo {
    width: 13%;
  }
  .topya-tv-player .topya-logo {
    width: 25%;
  }
}
.topya-tv-player.player-mobile .player-controls {
  padding: 15pt;
  padding-top: 30pt;
  font-size: 25pt;
}
.topya-tv-player.player-mobile .player-controls .controls-button {
  font-size: 25pt;
}
.topya-tv-player.player-mobile .player-controls .video-progress-holder {
  width: 93%;
  margin: 0;
  left: 3.5%;
  position: absolute;
  bottom: 60pt;
  height: 15pt;
}
.topya-tv-player.player-mobile .player-controls .volume-holder {
  height: 15pt;
  width: 25%;
}
.topya-tv-player.player-mobile .player-controls span[class*='glyphicon-volume'] {
  margin-left: 7%;
  font-size: 35pt;
  vertical-align: sub;
}
.topya-tv-player.player-mobile .player-controls span[class*='glyphicon-resize'] {
  margin-top: 8pt;
}
.topya-tv-player.player-mobile .topya-logo {
  bottom: 5%;
  width: 25%;
}
.topya-tv-player.player-mobile .topya-logo.topya-logo-upper {
  bottom: 20%;
}
.topya-tv-player.player-mobile .customer-logo {
  bottom: 10%;
}
.topya-tv-player.player-mobile .customer-logo.customer-logo-upper {
  bottom: 25%;
}
.topya-tv-player.player-mobile.player-fullscreen .topya-logo.topya-logo-upper {
  bottom: 70pt;
}
.topya-tv-player.player-mobile.player-fullscreen .customer-logo.customer-logo-upper {
  bottom: 80pt;
}
manager-column-head > div {
  display: inline-block;
  padding: 0;
}
manager-column-head > div span.glyphicon {
  vertical-align: sub;
}
manager-column-head > div span[class*='inverted-color'] {
  background-color: #fff;
  color: #003;
  border-radius: 50%;
  border: 1px solid #fff;
  vertical-align: sub;
}
manager-column-head > div.active-column {
  color: dodgerblue;
}
manager-column-head > div.active-column span[class*='inverted-color'] {
  background-color: dodgerblue;
  border-color: dodgerblue;
}
manager-column-head > div.last-column .dropdown-menu {
  right: -15px;
  left: auto;
}
manage-subcategory > div.panel-body toggle-input {
  display: inline-block;
  margin-left: calc(100% - 563px);
}
manage-subcategory > div.panel-body toggle-input > span.label {
  margin-left: 0;
}
manage-subcategory > div.panel-body span.category-name {
  width: 300px;
}
channels-list-item:nth-child(even) > div {
  background-color: #d8d8d8;
}
channels-list-item > div {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 610px) {
  channels-list-item > div > div {
    padding-left: 0;
    font-size: 10px;
  }
}
channel-reorder-sponsors > ul.non-editable input.form-control[disabled] {
  background-color: #fff;
  border: none;
  box-shadow: none;
  cursor: default;
}
channel-reorder-sponsors > ul.non-editable ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0);
}
channel-reorder-sponsors > ul.non-editable ::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0);
}
channel-reorder-sponsors > ul.non-editable :-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0);
}
channel-reorder-sponsors > ul.non-editable :-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0);
}
channel-reorder-sponsors > ul:not(.non-editable) input.form-control.ng-invalid {
  border-color: red;
}
contests-list-item > div.container-fluid {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 610px) {
  contests-list-item > div.container-fluid > div {
    padding-left: 0;
    font-size: 10px;
  }
}
contests-list-item:nth-child(even) > div.container-fluid {
  background-color: #d8d8d8;
}
contests-list-item .team-contest-marker {
  background-color: dodgerblue;
  color: white;
  padding: 6px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  display: inline-block;
  width: 88px;
}
@media (max-width: 610px) {
  contests-list-item .team-contest-marker {
    width: 100%;
    font-size: 9px;
    width: 70px;
  }
}
contests-list-search-filters input.form-control,
contests-list-search-filters .btn {
  border-radius: 4px;
}
contests-list-search-filters .fundraiser-filters button.btn {
  width: auto;
  display: inline-block;
}
contests-list-search-filters .fundraiser-filters button.btn:not(:first-child) {
  margin-left: 15px;
}
.contest-forms .custom-radio-selection {
  border-radius: 8px;
  width: 100%;
  border: 1px solid #dae2ea;
  overflow: hidden;
  height: 34px;
}
.contest-forms .custom-radio-selection .selection-label {
  height: 32px;
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
  width: 70%;
  text-align: center;
}
.contest-forms .custom-radio-selection .selection-button {
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  height: 32px;
}
.contest-forms .custom-radio-selection .selection-button .circle-outer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.contest-forms .custom-radio-selection .selection-button.selected {
  background-color: #5fd842;
}
.contest-forms .custom-radio-selection .selection-button.selected .circle-outer {
  background-color: #5fd842;
  border: 2px solid #fff;
}
.contest-forms .custom-radio-selection .selection-button.selected .circle-outer .circle-inner {
  width: 12px;
  height: 12px;
  margin-top: 2px;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 2px;
}
.contest-forms .custom-radio-selection .selection-button.deselected {
  background-color: #eee;
}
.contest-forms .custom-radio-selection .selection-button.deselected .circle-outer {
  background-color: #fff;
  border: 2px solid #dfdfdf;
}
.contest-forms .custom-radio-selection .selection-button.deselected .circle-outer .circle-inner {
  display: none;
}
.contest-forms .btn {
  border-radius: 8px;
  font-weight: bold;
}
.contest-forms textarea {
  height: 150px;
  resize: none;
  content: none;
}
.contest-forms input {
  border-radius: 4px;
}
.contest-forms input.ng-invalid-pattern {
  border-color: red;
}
.contest-forms input.reference-field {
  background-color: #eee;
}
.contest-forms.contest-basics-form {
  padding: 0;
}
.contest-forms.contest-basics-form radio-input > div {
  margin-top: 10px;
}
.contest-forms.contest-basics-form radio-input:nth-of-type(2) > div {
  margin-left: 10px;
}
.contest-forms.contest-basics-form .delete-image-icon {
  position: absolute;
  left: 70px;
  top: 10px;
  color: #fb061f;
}
.contest-forms.contest-basics-form p {
  word-break: break-all;
}
@media (min-width: 992px) {
  .contest-forms.contest-basics-form .filter-options-selector .checkbox-group:first-child {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .contest-forms.contest-basics-form .team-selector {
    margin-top: 25px;
  }
}
.contest-forms.contest-submit .header {
  font-family: 'AvenirNextLTPro Bold';
}
edit-pledge-payment h4 {
  display: inline-block;
}
edit-pledge-payment div.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
edit-pledge-payment button.btn,
edit-pledge-payment input.form-control {
  border-radius: 4px;
}
edit-pledge-payment button.btn {
  width: 150px;
}
edit-pledge-payment input.ng-invalid {
  border-color: red;
  background: rgba(255, 0, 0, 0.1);
}
edit-pledge-payment input.ng-invalid.form-control:focus {
  border-color: red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}
player-skill-score-form > div h5 {
  font-weight: bold;
}
player-skill-score-form > div textarea.form-control {
  resize: none;
  height: 75px;
  margin-top: 15px;
  margin-bottom: 10px;
}
player-skill-score-form > div div.buttons-holder {
  text-align: center;
}
player-skill-score-form > div div.buttons-holder button.btn {
  width: 150px;
  display: inline-block;
  margin: 5px 3%;
}
.modal .modal-content.approve-modal .modal-header {
  background-color: #84c542;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #fff;
}
.modal .modal-content.approve-modal .modal-header h4 {
  display: inline-block;
}
.modal .modal-content.approve-modal .modal-header .close {
  display: inline-block;
  color: #fff;
  opacity: 1;
  margin-top: 0;
}
.modal .modal-content.approve-modal .modal-body h4 {
  color: #424242;
}
.modal .modal-content.approve-modal .btn {
  border-radius: 4px;
}
@media (max-width: 1480px) {
  .modal .modal-content.approve-modal .btn {
    font-size: 14px;
  }
}
.modal .modal-content.approve-modal textarea.form-control {
  height: 80px;
  resize: none;
}
.modal .modal-content.approve-modal textarea.form-control[disabled] {
  border: 1px solid #fff;
  background-color: #fff;
  cursor: default;
}
.modal .modal-content.approve-modal .glyphicon {
  font-size: 30px;
  vertical-align: bottom;
  margin-right: 5px;
  color: #ff8827;
}
.modal .modal-content.approve-modal span {
  display: inline-block;
}
.modal .modal-content.approve-modal button {
  font-size: 14px;
}
.modal .modal-content.approve-modal .star-gray {
  color: #ccc;
}
.modal .modal-content.approve-modal .star-yellow {
  color: #ff8827;
}
.modal .modal-content.decline-modal .modal-header {
  background-color: #db524b;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #fff;
}
.modal .modal-content.decline-modal .modal-header h4 {
  display: inline-block;
}
.modal .modal-content.decline-modal .modal-header .close {
  display: inline-block;
  color: #fff;
  opacity: 1;
  margin-top: 0;
}
.modal .modal-content.decline-modal .modal-body h4 {
  color: #424242;
}
.modal .modal-content.decline-modal .btn {
  border-radius: 4px;
}
@media (max-width: 1480px) {
  .modal .modal-content.decline-modal .btn {
    font-size: 14px;
  }
}
.modal .modal-content.decline-modal textarea.form-control {
  height: 120px;
  resize: none;
}
.modal .modal-content.decline-modal textarea.form-control[disabled] {
  border: 1px solid #fff;
  background-color: #fff;
  cursor: default;
}
player-freestyle-videos-away-team-detail > div {
  margin-top: 20px;
}
player-freestyle-videos-away-team-detail > div .team-member-avatar {
  width: 60%;
  margin-left: 20%;
  margin-top: 10%;
}
player-freestyle-videos-away-team-detail > div .team-member-username {
  color: dodgerblue;
  text-align: center;
  font-weight: bold;
  margin-top: 10%;
}
player-freestyle-videos-away-team-detail > div .team-name {
  text-align: center;
  margin-top: 7%;
}
player-freestyle-videos-away-team-detail > div .team-country {
  text-align: center;
  margin-top: 10px;
}
player-freestyle-videos-away-team-detail > div .flag {
  height: 15px;
  width: 25px;
  margin-left: 5px;
}
player-freestyle-videos-away-video .video-title {
  margin: 10px 0 15px 0;
  text-align: center;
}
player-freestyle-videos-away-video .video-title.blue {
  color: dodgerblue;
}
player-freestyle-videos-away-video .video-title.green {
  color: #93CB53;
}
player-freestyle-videos-away-video .video-title.orange {
  color: #F7A722;
}
player-freestyle-videos-away-video .runner-icon {
  position: absolute;
  z-index: 100;
  border-radius: 50%;
  border: 2px solid #fff;
  top: 20px;
  left: -0.1vw;
}
player-freestyle-videos-away-video .runner-icon.active {
  background-color: #86c442;
}
player-freestyle-videos-away-video .runner-icon.ended {
  background-color: #F7A722;
}
@media (min-width: 1200px) {
  player-freestyle-videos-away-video .runner-icon {
    width: 10%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  player-freestyle-videos-away-video .runner-icon {
    width: 12%;
  }
}
@media (max-width: 991px) {
  player-freestyle-videos-away-video .runner-icon {
    width: 15%;
  }
}
player-freestyle-videos-away-video .download-video-button {
  border-radius: 8px;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1023px) {
  player-freestyle-videos-away-video .download-video-button {
    font-size: 12px;
  }
}
player-freestyle-videos-challenger-detail > div.challenger-detail {
  text-align: center;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .challenger-avatar {
  width: 50%;
  margin-top: 30px;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .challenger-name {
  color: dodgerblue;
  margin: 20px 0;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .country {
  font-weight: bold;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .country span {
  display: inline-block;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .badges-count {
  display: inline-block;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .badges-count.margin {
  margin-left: 5%;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .badges-count span:nth-child(2) {
  margin-left: 5px;
}
player-freestyle-videos-challenger-detail > div.challenger-detail .flag {
  height: 15px;
  width: 25px;
  margin-left: 5px;
}
player-freestyle-videos-creator-detail > div {
  text-align: center;
}
player-freestyle-videos-creator-detail > div .creator-avatar {
  width: 50%;
  margin-top: 30px;
}
player-freestyle-videos-creator-detail > div .creator-name {
  color: dodgerblue;
  margin: 20px 0;
}
player-freestyle-videos-creator-detail > div .country {
  font-weight: bold;
}
player-freestyle-videos-creator-detail > div .country span {
  display: inline-block;
}
player-freestyle-videos-creator-detail > div .badges-count {
  display: inline-block;
}
player-freestyle-videos-creator-detail > div .badges-count.margin {
  margin-left: 5%;
}
player-freestyle-videos-creator-detail > div .badges-count span:nth-child(2) {
  margin-left: 5px;
}
player-freestyle-videos-creator-detail > div .flag {
  height: 15px;
  width: 25px;
  margin-left: 5px;
}
player-freestyle-videos-detail-header > div .player-challenge-col-lg {
  width: 17%;
}
player-freestyle-videos-detail-header > div .player-challenge-col-md {
  width: 11%;
}
player-freestyle-videos-detail-header > div .player-challenge-col-sm {
  width: 8%;
}
player-freestyle-videos-detail-header > div .player-challenge-col-xs {
  width: 5%;
}
player-freestyle-videos-detail-header > div div[class^='player-challenge-col'] {
  position: relative;
  display: inline-block;
  vertical-align: top;
  word-break: break-word;
}
player-freestyle-videos-detail-header > div div[class^='player-challenge-col'].padded {
  padding-right: 10px;
}
player-freestyle-videos-home-team-detail > div {
  margin-top: 20px;
}
player-freestyle-videos-home-team-detail > div .team-member-avatar {
  width: 60%;
  margin-left: 20%;
  margin-top: 10%;
}
player-freestyle-videos-home-team-detail > div .team-member-username {
  color: dodgerblue;
  text-align: center;
  font-weight: bold;
  margin-top: 10%;
}
player-freestyle-videos-home-team-detail > div .team-name {
  text-align: center;
  margin-top: 7%;
}
player-freestyle-videos-home-team-detail > div .team-country {
  text-align: center;
  margin-top: 10px;
}
player-freestyle-videos-home-team-detail > div .flag {
  height: 15px;
  width: 25px;
  margin-left: 5px;
}
player-freestyle-videos-home-video .video-title {
  margin: 10px 0 15px 0;
  text-align: center;
}
player-freestyle-videos-home-video .video-title.blue {
  color: dodgerblue;
}
player-freestyle-videos-home-video .video-title.green {
  color: #93CB53;
}
player-freestyle-videos-home-video .video-title.orange {
  color: #F7A722;
}
player-freestyle-videos-home-video .runner-icon {
  position: absolute;
  z-index: 100;
  border-radius: 50%;
  border: 2px solid #fff;
  top: 20px;
  left: -0.1vw;
}
player-freestyle-videos-home-video .runner-icon.active {
  background-color: #86c442;
}
player-freestyle-videos-home-video .runner-icon.ended {
  background-color: #F7A722;
}
@media (min-width: 1200px) {
  player-freestyle-videos-home-video .runner-icon {
    width: 10%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  player-freestyle-videos-home-video .runner-icon {
    width: 12%;
  }
}
@media (max-width: 991px) {
  player-freestyle-videos-home-video .runner-icon {
    width: 15%;
  }
}
player-freestyle-videos-home-video .download-video-button {
  border-radius: 8px;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1023px) {
  player-freestyle-videos-home-video .download-video-button {
    font-size: 12px;
  }
}
player-freestyle-videos-detail-info img {
  height: 17px;
  position: absolute;
  bottom: -3px;
}
player-freestyle-videos-detail-info h5 {
  margin-bottom: 15px;
}
player-freestyle-videos-detail-info .is-international {
  margin-left: 25px;
}
player-freestyle-videos-nomination .nomination-star {
  margin-left: 5px;
}
player-freestyle-videos-nomination textarea {
  resize: vertical;
}
player-freestyle-videos-nomination .save-button {
  margin-top: 50%;
  border-radius: 8px;
}
player-freestyle-videos-nomination img {
  height: 17px;
  position: absolute;
  bottom: -3px;
}
player-freestyle-videos-nomination h5 {
  margin-bottom: 15px;
}
manager-product-availability-preview span.inline-stats {
  display: block;
}
manager-product-availability-preview span.inline-stats h4.stats-label {
  display: inline-block;
  font-weight: bold;
}
manager-product-availability-preview span.inline-stats span.stats-value {
  font-size: 18px;
  margin-left: 10px;
}
manager-product-categories-preview span.stats-detail {
  display: block;
  text-align: left;
  color: #47454e;
}
manager-product-categories-preview h4.stats-label {
  font-weight: bold;
  letter-spacing: 0.4px;
}
manager-product-created-modified-preview span.inline-stats {
  display: block;
}
manager-product-created-modified-preview span.inline-stats h4.stats-label {
  display: inline-block;
  font-weight: bold;
}
manager-product-created-modified-preview span.inline-stats span.stats-value {
  font-size: 18px;
  margin-left: 10px;
}
manager-product-description-preview > span {
  display: block;
}
manager-product-description-preview > span span.stats-detail {
  display: block;
  text-align: left;
  color: #47454e;
}
manager-product-description-preview > span h4.stats-label {
  font-weight: bold;
  letter-spacing: 0.4px;
}
manage-product-draggable-paths-list > div {
  margin-top: 30px;
}
manage-product-draggable-paths-list > div search-form button.btn.btn-default {
  border-radius: 6px;
  background: linear-gradient(#fff 0%, #bbb 10%);
  border-color: #cecece;
  font-weight: bold;
  color: #5d5c5c;
  font-size: 15px;
  letter-spacing: 0.5px;
  border-width: 2px;
  padding: 10px;
  width: 100%;
  height: 44px;
}
manage-product-draggable-paths-list > div search-form input.form-control {
  height: 45px;
  border-radius: 6px;
}
manage-product-draggable-paths-list > div div.review-table {
  margin-top: 30px;
  margin-bottom: 40px;
}
manage-product-draggable-paths-list > div manager-list-header > div.container-fluid {
  padding: 10px 0;
}
manage-product-draggable-paths-list > div manager-list-header > div.container-fluid manager-column-head > div {
  padding: 0 15px;
  padding-right: 0;
  font-size: 12px;
}
manage-product-draggable-paths-list > div manage-product-draggable-paths-list-item:nth-child(odd) > div {
  background-color: #e5e5e5;
}
manage-product-draggable-paths-list > div li.dndPlaceholder {
  display: none;
}
manage-product-draggable-paths-list > div .spinner-holder {
  position: relative;
  width: 100%;
  height: 60px;
}
manage-product-draggable-paths-list > div .spinner-holder .loading-paths-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}
manage-product-draggable-paths-list > div .items-holder {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
manage-product-draggable-paths-list > div .items-holder.has-scrollbar manage-product-draggable-paths-list-item > div.container-fluid {
  margin-right: -10px;
}
manage-product-draggable-paths-list-item > div {
  padding: 10px 0;
  min-height: 60px;
}
manage-product-draggable-paths-list-item > div span.remove-icon {
  float: right;
  color: red;
  font-size: 20px;
  font-weight: bold;
  margin-top: -5px;
}
manage-product-paths-drop-area > div.container-fluid {
  height: 300px;
  border: 2px dashed #ececec;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  overflow-y: auto;
}
manage-product-paths-drop-area > div.container-fluid h3.title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  font-family: 'AvenirNextLTPro Bold';
  color: #bdbbbb;
}
manage-product-paths-drop-area > div.container-fluid li.dndPlaceholder {
  display: none;
}
manage-product-paths-drop-area > div.container-fluid manage-product-draggable-paths-list-item:nth-child(odd) > div {
  background-color: #e5e5e5;
}
manager-product-paths-preview span.stats-detail {
  display: block;
  text-align: left;
  color: #47454e;
}
manager-product-paths-preview h4.stats-label {
  font-weight: bold;
  letter-spacing: 0.4px;
}
manage-product-reorder-paths-list > div manage-product-reorder-paths-list-item:nth-child(odd) > div {
  background-color: #e5e5e5;
}
manage-product-reorder-paths-list > div manager-list-header > div.container-fluid {
  padding: 10px 0;
}
manage-product-reorder-paths-list > div manager-list-header > div.container-fluid manager-column-head > div {
  padding: 0 15px;
}
manage-product-reorder-paths-list-item > div {
  padding: 10px 0;
  min-height: 60px;
}
span.stats-group {
  display: inline-block;
}
span.stats-group:first-child {
  width: 40%;
}
span.stats-group:not(:first-child) {
  width: 19%;
}
span.stats-group img.stats-icon {
  max-width: 15px;
  max-height: 20px;
  vertical-align: sub;
}
span.stats-group span.stats-value {
  font-size: 18px;
  margin-left: 6%;
}
manager-product-video > div thumbnail-uploader > div {
  margin-top: 40px;
}
create-product-add-paths > div h4 {
  color: #6c6c6c;
}
create-product-add-paths > div manage-product-paths-drop-area > div.container-fluid {
  height: 600px;
}
create-product-add-paths > div manage-product-draggable-paths-list > div .items-holder {
  max-height: 465px;
}
create-product-added-paths-list > div manager-list-header > div.container-fluid {
  padding: 10px 0;
}
create-product-added-paths-list > div manager-list-header > div.container-fluid manager-column-head > div {
  padding: 0 15px;
}
create-product-added-paths-list > div manage-product-draggable-paths-list-item:nth-child(odd) > div {
  background-color: #e5e5e5;
}
create-product-basics-form > div.container-fluid {
  margin-top: 30px;
  border: 2px solid #D7D7D7;
  border-radius: 6px;
  padding-top: 40px;
  padding-bottom: 40px;
}
create-product-basics-form > div.container-fluid form span {
  font-weight: bold;
}
create-product-basics-form > div.container-fluid form input.form-control {
  margin-bottom: 20px;
  border-radius: 4px;
  max-width: 400px;
}
create-product-basics-form > div.container-fluid form input.form-control:nth-of-type(2) {
  margin-bottom: 50px;
}
create-product-basics-form > div.container-fluid form radio-input:not(:nth-of-type(1)) > div {
  margin-left: 10px;
}
create-product-basics-form > div.container-fluid form .buttons-holder {
  width: 100%;
  max-width: 650px;
  text-align: right;
  margin-top: 40px;
}
create-product-basics-form > div.container-fluid form div.custom-head-to-head-count-holder input.form-control {
  margin-right: -5px;
  width: 260px;
}
@media (min-width: 768px) and (max-width: 991px) {
  create-product-basics-form > div.container-fluid form checkbox-group:first-child > div {
    margin: 0;
  }
  create-product-basics-form > div.container-fluid form checkbox-group > div.form-control {
    display: block;
    margin-top: 5px;
    width: 400px;
  }
  create-product-basics-form > div.container-fluid form .buttons-holder {
    text-align: left;
    padding-left: 150px;
  }
  create-product-basics-form > div.container-fluid form .buttons-holder .btn.btn-primary.new-btn-primary {
    margin-top: 10px;
  }
}
edit-product-paths > div .loading-spinner {
  margin-left: 50%;
  transform: translateX(-50%);
}
edit-product-price-and-availability > div .loading-spinner {
  margin-left: 50%;
  transform: translateX(-50%);
}
product-detail-expanded > div {
  border: 1px solid #d7d7d7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  padding-bottom: 50px;
}
product-detail-expanded > div error-message > div {
  margin-top: 10px;
}
product-detail-expanded > div h4.product-name {
  font-weight: bold;
  letter-spacing: 0.7px;
}
product-detail-expanded > div a.product-link {
  color: dodgerblue;
  margin-top: 15px;
  display: block;
  text-align: left;
  cursor: pointer;
}
product-detail-expanded > div .logo-holder {
  height: 100px;
  width: 100%;
  margin-top: 40px;
  padding: 0 0 20px 0;
}
product-detail-expanded > div .logo-holder img.content-provider-logo {
  max-width: 100%;
  max-height: 100%;
}
product-detail-expanded > div span.stats-detail {
  display: block;
  text-align: left;
  color: #47454e;
}
product-detail-expanded > div h4.stats-label {
  font-weight: bold;
  letter-spacing: 0.4px;
}
product-detail-expanded > div span.inline-stats {
  display: block;
}
product-detail-expanded > div span.inline-stats h4.stats-label {
  display: inline-block;
}
product-detail-expanded > div span.inline-stats span.stats-value {
  font-size: 18px;
  margin-left: 10px;
}
edit-permission-group > div {
  padding: 10px;
  border: 1px solid #a3a3a3;
  background-color: #fff;
}
edit-permission-group > div.group-name {
  width: 100%;
}
edit-permission-group > div.permission {
  width: 95%;
  margin-left: 5%;
  border-top: none;
}
edit-permission-group > div.permission:last-of-type {
  border-bottom: none;
}
edit-permission-group:last-of-type > div.permission {
  border-bottom: 1px solid #a3a3a3;
}
permissions-filter > div.btn-group {
  width: 200px;
  margin-bottom: 25px;
}
permissions-filter > div.btn-group button,
permissions-filter > div.btn-group li {
  text-align: left;
}
permissions-filter > div.btn-group li {
  padding: 6px 12px;
  cursor: pointer;
}
permissions-filter > div.btn-group li:hover {
  background-color: dodgerblue;
  color: #fff;
}
permissions-filter > div.btn-group .dropdown-menu {
  width: 100%;
}
permissions-filter > div.btn-group span.caret-holder {
  float: right;
  margin-top: 3px;
  margin-right: 8px;
}
permissions-filter > div.btn-group span.caret-holder span.caret {
  display: block;
  margin: 2px;
}
permissions-filter > div.btn-group span.caret-holder span.caret.caret-top {
  transform: rotate(180deg);
}
edit-skill-scoring-form > div h1 {
  font-family: 'AvenirNextLTPro Bold';
}
edit-skill-scoring-form > div label {
  font-weight: bold;
  display: block;
}
edit-skill-scoring-form > div label.range-label {
  font-weight: normal;
  display: inline-block;
}
edit-skill-scoring-form > div input.form-control {
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
}
edit-skill-scoring-form > div input.form-control[type="number"] {
  width: 80px;
  margin: 0 15px;
  display: inline-block;
}
edit-skill-scoring-form > div div.passing-input label {
  display: inline-block;
}
edit-skill-scoring-form > div radio-input:not(:first-child) > div {
  margin-left: 15px;
}
edit-skill-scoring-form > div div.buttons-holder {
  width: 100%;
  text-align: right;
}
edit-skill-scoring-form > div error-message > div {
  margin-top: 30px;
}
edit-skill-scoring-preview > div h1 {
  font-family: 'AvenirNextLTPro Bold';
}
edit-skill-scoring-preview > div hr {
  border-top: 2px solid #dcdcdc;
}
edit-skill-scoring-preview > div .buttons-holder {
  margin-top: 30px;
  text-align: center;
}
edit-skill-scoring-preview > div div.preview span.preview-group {
  display: block;
  margin-top: 20px;
  margin-left: -15px;
}
edit-skill-scoring-preview > div div.preview span.preview-group span {
  display: inline-block;
  font-size: 16px;
}
edit-skill-scoring-preview > div div.preview span.preview-group span:first-child {
  font-weight: bold;
}
edit-skill-scoring-preview > div error-message > div {
  margin-top: 30px;
}
manager-profile > div input.form-control {
  border-radius: 4px;
}
manager-profile > div button.btn {
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}
manager-coach-teams > div button.btn {
  min-width: 150px;
  font-weight: bold;
  width: auto;
  border-radius: 4px;
}
manager-coach-teams > div .list-column-label {
  font-weight: bold;
}
manager-coach-teams > div hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
manager-coach-teams > div manager-add-coach-teams div.scrollbar-present > manager-add-coach-teams-item > div.row {
  margin-right: -25px;
}
manager-coach-teams > div manager-add-coach-teams div.scrollbar-present > manager-add-coach-teams-item > div.row .add-icon {
  padding-right: 30px;
}
manager-coach-teams > div manager-current-coach-teams div.scrollbar-present manager-current-coach-teams-item > div.row {
  margin-right: -25px;
}
manager-coach-teams > div manager-current-coach-teams div.scrollbar-present manager-current-coach-teams-item > div.row .remove-icon {
  padding-right: 30px;
}
manager-coach-teams > div manager-add-coach-teams-item:nth-child(odd) > div {
  background-color: #eee;
}
manager-coach-teams > div manager-add-coach-teams-item > div.row {
  padding: 10px 0;
}
manager-coach-teams > div manager-add-coach-teams-item > div.row .add-icon {
  padding-right: 25px;
}
manager-coach-teams > div input.form-control {
  border-radius: 4px;
}
manager-coach-teams > div .modal-footer {
  text-align: left;
}
manage-organizations > div button.btn {
  border-radius: 4px;
}
manager-player-teams > div button.btn {
  min-width: 150px;
  font-weight: bold;
  width: auto;
  border-radius: 4px;
}
manager-player-teams > div input.form-control {
  border-radius: 4px;
}
manager-player-teams > div hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
manager-player-teams > div manager-add-player-teams div.scrollbar-present > manager-add-player-teams-item > div.row {
  margin-right: -25px;
}
manager-player-teams > div manager-add-player-teams div.scrollbar-present > manager-add-player-teams-item > div.row .add-icon {
  padding-right: 30px;
}
manager-player-teams > div manager-add-player-teams-item:nth-child(odd) > div {
  background-color: #eee;
}
manager-player-teams > div manager-add-player-teams-item > div.row {
  padding: 10px 0;
}
manager-player-teams > div manager-add-player-teams-item > div.row .add-icon {
  padding-right: 25px;
}
user-subscriptions-list > div span.cancel-subscription {
  float: right;
  color: red;
}
user-subscriptions-list > div.list-header span.column-title {
  height: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
user-subscriptions-list > div.list-header span.column-title:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}
virtual-coach-categories > div div.table-header {
  padding-right: 10px;
}
virtual-coach-categories > div div.category-item {
  padding: 10px 0;
}
virtual-coach-categories > div div.category-item:nth-child(odd) {
  background-color: #f9f9f9;
}
my-fundraiser-distributions-item > div h5 {
  font-weight: bold;
  margin-bottom: 20px;
}
my-fundraiser-distributions-item > div span {
  display: block;
  line-height: 20px;
  font-size: 16px;
}
my-fundraiser-distributions-item > div span.info {
  margin-top: 7px;
  margin-bottom: 15px;
}
my-fundraiser-distributions-item > div button.btn.btn-primary.cancel {
  width: 150px;
  border-radius: 4px;
  border-width: 2px;
  font-weight: bold;
  margin-top: 10px;
  color: dodgerblue;
}
my-fundraiser-distributions-item > div.selected {
  opacity: 0.7;
}
my-fundraiser-distributions-item:not(:first-child) > div {
  margin-top: 50px;
}
my-fundraiser-bank-account > div.container-fluid {
  margin-top: 30px;
}
@media (min-width: 768px) {
  my-fundraiser-bank-account > div.container-fluid {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
}
my-fundraiser-pledges > div.container-fluid {
  padding: 30px 30px 0 30px;
}
my-fundraiser-promotion > div.container-fluid {
  margin-top: 30px;
}
@media (min-width: 768px) {
  my-fundraiser-promotion > div.container-fluid {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
}
my-fundraiser-promotion > div.container-fluid p {
  font-size: 16px;
}
my-fundraiser-promotion > div.container-fluid a.btn {
  border-radius: 4px;
  width: auto;
}
pledge-stats-item > div {
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}
pledge-stats-item > div button {
  margin-top: 20px;
  display: block;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 140px;
  margin-left: 50%;
  transform: translateX(-50%);
}
my-fundraisers-players-block-item div.player-detail {
  padding: 15px 10px;
}
my-fundraisers-players-block-item div.player-detail img.player-avatar {
  height: 54px;
}
my-fundraisers-players-block-item div.player-detail span.glyphicon {
  margin-top: 16px;
}
my-fundraisers-players-block-item div.player-detail span.details-title {
  margin-left: 10px;
}
my-fundraisers-players-block-item:not(:last-child) hr {
  border-top: 1px solid #DBDBEA;
  margin: 0;
}
my-fundraisers-players-block-item:last-child hr {
  display: none;
}
my-fundraisers-players-block-item div.player-fundraisers {
  padding: 0 10px;
}
my-fundraisers-players-block-item div.player-fundraisers div.player-fundraiser {
  padding: 10px 0;
}
my-fundraisers-players-block-item div.player-fundraisers div.player-fundraiser:not(:last-child) {
  border-bottom: 1px solid #dbdbea;
}
my-player-fundraisers > div {
  max-height: 500px;
  overflow-y: auto;
  background-color: #fff;
}
my-players-fundraisers-filters > div {
  padding: 20px 0 10px 0;
  background: white;
  border-bottom: 2px solid #eee;
}
my-players-fundraisers-filters > div button.btn {
  font-weight: bold;
  border-radius: 4px;
}
my-players-fundraisers-filters > div.side-block > div {
  padding: 2px;
}
my-players-fundraisers-filters > div.side-block button.btn {
  padding: 2px;
}
player-fundraising-pledge-detail > div {
  color: #8B8B8B;
  font-weight: normal;
  margin-top: 10px;
}
player-fundraising-pledge-detail > div hr {
  border-top: 1px solid #bdbcbc;
  margin-top: 10px;
  margin-bottom: 10px;
}
parent-activity-feed-card-filters > div.btn-group button.btn.btn-default {
  padding: 8px 16px;
  line-height: 24px;
  text-align: left;
}
parent-activity-feed-card-filters > div.btn-group button.btn.btn-default img {
  float: right;
  margin-right: -8px;
}
parent-activity-feed-card-filters > div.btn-group ul.dropdown-menu {
  margin: 0;
  width: 100%;
  border-radius: 0;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  z-index: 99;
}
parent-activity-feed-card-filters > div.btn-group ul.dropdown-menu::before,
parent-activity-feed-card-filters > div.btn-group ul.dropdown-menu::after {
  visibility: hidden;
}
parent-activity-feed-card-filters > div.btn-group ul.dropdown-menu li {
  text-align: left;
  padding: 8px 16px;
  cursor: pointer;
}
parent-activity-feed-card-filters > div.btn-group ul.dropdown-menu li:hover {
  background-color: #d4d4d4;
}
parent-activity-feed-card-item > div {
  display: block;
  width: 100%;
  margin-top: 24px;
}
parent-activity-feed-card-item > div video-player > div {
  margin-top: 24px;
}
parent-add-kid-invitation-code > div > button.btn.btn-primary {
  background-color: #4A90E2;
  border-radius: 2px;
  color: #FFFFFF;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12px;
}
parent-add-kid-invitation-code > div.mobile > button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  border-radius: 1.25vw;
  margin-top: 10vw;
  padding: 2.5vw 5vw;
}
parent-my-kids-card-header > div {
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
  background: #fff;
  padding: 19px 0 20px 0;
}
parent-my-kids-card-item > div {
  width: 100%;
  padding: 11px 0 10px 0;
  border-bottom: 1px solid #E5E5E5;
  background: #fff;
}
parent-my-kids-card-item > div img.profile-picture {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
parent-my-kids-card-item > div div.name {
  width: 65%;
  display: inline-block;
  vertical-align: middle;
}
parent-my-kids-card-item > div div.name > span {
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
parent-my-kids-card-item > div div.name > span.first-last-name {
  font-weight: 900;
  color: #333;
}
parent-my-kids-card-item > div div.name > span.username {
  font-weight: 500;
  color: #999;
}
parent-my-kids-card-item > div div.subscription-status {
  width: 51px;
  height: 24px;
  border-radius: 16px;
  color: #FFF;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding-top: 4px;
  display: inline-block;
}
parent-my-kids-card-item > div div.subscription-status.subscription-yes {
  background-color: #6DD400;
}
parent-my-kids-card-item > div div.subscription-status.subscription-no {
  background-color: #F55151;
}
parent-my-kids-add-button > button.btn.btn-primary {
  width: auto;
  color: #FFFFFF;
  padding: 7px 15px 7px 11px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
parent-my-kids-add-button > button.btn.btn-primary.mobile {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  background-color: #51C8E9;
  border-radius: 1.25vw;
  margin-top: 5vw;
  padding: 2.5vw 5vw;
}
parent-my-kids-add-button > button.btn.btn-primary.mobile > span.plus {
  float: left;
}
parent-my-kids-list-filters > div {
  display: inline-block;
}
parent-my-kids-list-filters > div span.filter {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: inline-block;
  height: 48px;
}
parent-my-kids-list-filters > div span.filter:not(:first-child) {
  margin-left: 32px;
}
parent-my-kids-list-filters > div span.filter.active-filter {
  font-weight: 900;
  border-bottom: 2px solid #4A90E2;
}
parent-my-kids-list-filters > div.mobile {
  display: block;
  width: 100%;
}
parent-my-kids-list-filters span.mobile a.btn.btn-default {
  height: 12.5vw;
  text-align: left;
  border-width: 2px;
  border-color: #e5e5e5;
  font-size: 5vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  color: #333;
  border-radius: 1.25vw;
}
parent-my-kids-list-filters span.mobile a.btn.btn-default span.glyphicon {
  float: right;
  font-size: 32px;
  margin-top: 20px;
  color: #999;
}
parent-my-kids-list-filters span.mobile ul.dropdown-menu {
  width: 100%;
  top: 7vw;
}
parent-my-kids-list-filters span.mobile ul.dropdown-menu::before,
parent-my-kids-list-filters span.mobile ul.dropdown-menu::after {
  display: none;
}
parent-my-kids-list-filters span.mobile ul.dropdown-menu li {
  font-size: 5vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 7.5vw;
  text-align: left;
  padding: 2.5vw 5vw;
  color: #333;
}
parent-my-kids-list-header > div {
  width: 100%;
  border: 1px solid #E5E5E5;
  background: #fff;
  padding: 17px 15px;
  border-top: none;
}
parent-my-kids-list-header > div parent-my-kids-list-header-item:first-child > span {
  text-align: center;
  margin-right: 16px;
}
parent-my-kids-list-header > div.mobile {
  border-width: 2px;
  padding: 5vw 5vw;
  border-top: 2px solid #e5e5e5;
  margin-top: 7.5vw;
  border-radius: 0;
}
parent-my-kids-list-header > div.mobile parent-my-kids-list-header-item:first-child > span {
  margin-left: 25%;
  margin-right: 0;
  text-align: left;
}
parent-my-kids-list-item > div {
  width: 100%;
  padding: 12px 15px 11px 15px;
  border: 1px solid #E5E5E5;
  background: #fff;
  border-top: none;
}
parent-my-kids-list-item > div img.profile-picture {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
parent-my-kids-list-item > div div.name {
  width: 16%;
  display: inline-block;
  vertical-align: middle;
}
parent-my-kids-list-item > div div.name > span {
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
parent-my-kids-list-item > div div.name > span.first-last-name {
  font-weight: 900;
  color: #333;
}
parent-my-kids-list-item > div div.name > span.username {
  font-weight: 500;
  color: #999;
}
parent-my-kids-list-item > div div.name > img.link-arrow {
  display: none;
}
parent-my-kids-list-item > div.mobile {
  padding: 3.75vw 3.75vw;
  border-width: 2px;
}
parent-my-kids-list-item > div.mobile img.profile-picture {
  width: 12.5vw;
  height: 12.5vw;
  margin-right: 3.75vw;
}
parent-my-kids-list-item > div.mobile > div.name {
  width: 74%;
  position: relative;
  padding-right: 8%;
}
parent-my-kids-list-item > div.mobile > div.name > span {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-my-kids-list-item > div.mobile > div.name > img.link-arrow {
  display: block;
  position: absolute;
  right: 0;
  height: 5vw;
  top: 50%;
  transform: translateY(-50%);
}
parent-my-kids-list-item > div.mobile > div.name > img.link-arrow.detail-expanded {
  transform: translateY(-50%) rotate(90deg);
}
parent-my-kids-remove > div {
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(40, 41, 61, 0.04), 0 8px 16px 0 rgba(96, 97, 112, 0.16);
  padding: 24px;
  padding-top: 28px;
}
parent-my-kids-remove > div > span.title {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
parent-my-kids-remove > div > p.prompt-message {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 16px;
}
parent-my-kids-remove > div > div.buttons-holder {
  margin-top: 32px;
  text-align: right;
}
parent-my-kids-remove > div > div.buttons-holder > button.btn {
  width: auto;
  font-size: 13px;
}
parent-my-kids-remove > div > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-my-kids-remove > div.mobile {
  border-radius: 2.5vw;
  box-shadow: 0 4px 8px 0 rgba(40, 41, 61, 0.04), 0 16px 32px 0 rgba(96, 97, 112, 0.16);
  padding: 7.5vw;
  padding-top: 8.75vw;
}
parent-my-kids-remove > div.mobile > span.title {
  font-size: 5vw;
  line-height: 7.5vw;
}
parent-my-kids-remove > div.mobile > p.prompt-message {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 5vw;
}
parent-my-kids-remove > div.mobile > div.buttons-holder {
  margin-top: 10vw;
  text-align: center;
  width: 100%;
}
parent-my-kids-remove > div.mobile > div.buttons-holder > button.btn {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-my-kids-remove > div.mobile > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 0;
  margin-top: 5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid {
  padding: 0;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder {
  padding: 38px 40px 40px 40px;
  background-color: #fff;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder > div.profile-info-holder {
  margin-bottom: 40px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder > div.profile-info-holder > div.username-role-holder {
  margin-left: 24px;
  display: inline-block;
  height: 96px;
  vertical-align: top;
  max-width: calc(100% - 120px);
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div {
  margin-top: 48px;
  transform: translateY(-50%);
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.username {
  color: #333;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.role {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column {
  display: block;
}
@media (max-width: 1199px) {
  parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column {
    width: 50%;
  }
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column div.section-title {
  display: block;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column div.section-title:nth-of-type(2) {
  margin-top: 56px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column div.section-title > span.title {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column div.section-title > hr {
  border-color: #E5E5E5;
  border-width: 1px;
  margin: 24px 0 16px 0;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column text-input > span.label,
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column calendar-input > span.label {
  margin-top: 24px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column div.gender-select {
  margin-top: 24px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column div.gender-select span.gender-label {
  color: #333;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 8px;
  padding: 0;
  display: block;
  text-align: left;
  width: auto;
}
parent-my-kids-profile-edit-profile > form.container-fluid > div.form-fields-holder div.form-column div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 16px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > button.btn {
  width: auto;
  margin-top: 24px;
}
parent-my-kids-profile-edit-profile > form.container-fluid > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-my-kids-profile-edit-profile > form.container-fluid success-message > div,
parent-my-kids-profile-edit-profile > form.container-fluid error-message > div {
  margin-bottom: 24px;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile h3.title {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile view-title > div {
  margin-top: 7.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder {
  padding: 7.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder {
  margin-bottom: 15vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder {
  height: 30vw;
  margin-left: 7.5vw;
  max-width: calc(100% - 37.5vw);
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div {
  margin-top: 15vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.username {
  font-size: 6.25vw;
  line-height: 7.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder > div.profile-info-holder > div.username-role-holder > div > span.role {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column text-input > span.label,
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column calendar-input > span.label {
  margin-top: 7.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.section-title:nth-of-type(2) {
  margin-top: 17.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.section-title > span.title {
  font-size: 5.6vw;
  line-height: 7.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.section-title > hr {
  border-color: #E5E5E5;
  border-width: 2px;
  margin: 7.5vw 0 5vw 0;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.gender-select {
  margin-top: 7.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.gender-select span.gender-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-bottom: 2.5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile div.form-fields-holder div.form-column div.gender-select toggle-input:nth-of-type(2) > span:first-child {
  margin-left: 5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile > button.btn {
  margin-top: 10vw;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile > button.btn:not(.cancel) {
  margin-left: 5vw;
}
parent-my-kids-profile-edit-profile > form.container-fluid.mobile success-message > div,
parent-my-kids-profile-edit-profile > form.container-fluid.mobile error-message > div {
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
}
parent-my-kids-profile-privacy-settings > form.container-fluid {
  padding: 0;
}
parent-my-kids-profile-privacy-settings > form.container-fluid success-message > div,
parent-my-kids-profile-privacy-settings > form.container-fluid error-message > div {
  margin-bottom: 24px;
}
parent-my-kids-profile-privacy-settings > form.container-fluid > div.form-fields-holder {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  background-color: #FFF;
  padding-top: 16px;
}
parent-my-kids-profile-privacy-settings > form.container-fluid > div.form-fields-holder > div.input-group {
  padding: 24px 40px;
  width: 100%;
}
parent-my-kids-profile-privacy-settings > form.container-fluid > div.form-fields-holder > div.input-group:not(:last-child) {
  border-bottom: 1px dashed #E5E5E5;
}
parent-my-kids-profile-privacy-settings > form.container-fluid > div.form-fields-holder > div.input-group p.explanation-label {
  color: #999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 43px;
  margin-top: 16px;
}
@media (min-width: 1200px) {
  parent-my-kids-profile-privacy-settings > form.container-fluid > div.form-fields-holder > div.input-group p.explanation-label {
    width: 50%;
  }
}
@media (max-width: 1199) {
  parent-my-kids-profile-privacy-settings > form.container-fluid > div.form-fields-holder > div.input-group p.explanation-label {
    width: 100%;
  }
}
parent-my-kids-profile-privacy-settings > form.container-fluid > div.form-fields-holder > div.input-group a {
  color: #4A90E2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 43px;
}
parent-my-kids-profile-privacy-settings > form.container-fluid > button.btn {
  width: auto;
  margin-top: 24px;
}
parent-my-kids-profile-privacy-settings > form.container-fluid > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile success-message > div,
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile error-message > div {
  margin-bottom: 7.5vw;
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > div.form-fields-holder {
  border-radius: 8px;
  border-width: 2px;
  padding-top: 5vw;
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > div.form-fields-holder > div.input-group {
  padding: 7.5vw;
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > div.form-fields-holder > div.input-group:not(:last-child) {
  border-width: 2px;
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > div.form-fields-holder > div.input-group p.explanation-label {
  font-size: 5vw;
  line-height: 7.5vw;
  padding-left: 43px;
  margin-top: 5vw;
  padding-left: 13.75vw;
}
@media (min-width: 1200px) {
  parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > div.form-fields-holder > div.input-group p.explanation-label {
    width: 100%;
  }
}
@media (max-width: 1199) {
  parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > div.form-fields-holder > div.input-group p.explanation-label {
    width: 100%;
  }
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > div.form-fields-holder > div.input-group a {
  font-size: 4.4vw;
  line-height: 7.5vw;
  margin-left: 13.75vw;
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > button.btn {
  margin-top: 10vw;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-my-kids-profile-privacy-settings > form.container-fluid.mobile > button.btn:not(.cancel) {
  margin-left: 5vw;
}
parent-my-kids-profile-tabs > div span.tab {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: inline-block;
  height: 48px;
}
parent-my-kids-profile-tabs > div span.tab:not(:first-child) {
  margin-left: 32px;
}
parent-my-kids-profile-tabs > div span.tab.active-tab {
  font-weight: 900;
  border-bottom: 2px solid #4A90E2;
}
parent-my-kids-profile-tabs > div hr {
  margin: 0;
  margin-top: -1px;
  border-color: #E5E5E5;
}
parent-my-kids-profile-tabs > span.mobile a.btn.btn-default {
  height: 12.5vw;
  text-align: left;
  border-width: 2px;
  border-color: #e5e5e5;
  font-size: 5vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  color: #333;
  border-radius: 4px;
}
parent-my-kids-profile-tabs > span.mobile a.btn.btn-default span.glyphicon {
  float: right;
  font-size: 32px;
  margin-top: 20px;
  color: #999;
}
parent-my-kids-profile-tabs > span.mobile ul.dropdown-menu {
  width: 100%;
  top: 7vw;
}
parent-my-kids-profile-tabs > span.mobile ul.dropdown-menu::before,
parent-my-kids-profile-tabs > span.mobile ul.dropdown-menu::after {
  display: none;
}
parent-my-kids-profile-tabs > span.mobile ul.dropdown-menu li {
  font-size: 5vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 7.5vw;
  text-align: left;
  padding: 2.5vw 5vw;
  color: #333;
}
parent-subscriptions-card-header > div {
  border-bottom: 1px solid #e5e5e5;
}
parent-subscriptions-card-item > div {
  padding: 18px 0 17px 0;
  border-bottom: 1px solid #e5e5e5;
}
parent-subscriptions-card-item > div > div {
  display: inline-block;
  width: 25%;
  color: #333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;
  vertical-align: text-top;
  padding-right: 8px;
}
parent-subscriptions-list-header > div {
  width: 100%;
  border: 1px solid #E5E5E5;
  background: #fff;
  padding: 17px 24px;
  border-top: none;
}
parent-subscriptions-list-header > div.mobile {
  border-width: 2px;
  padding: 5vw 5vw;
  border-top: 2px solid #e5e5e5;
  margin-top: 7.5vw;
  border-radius: 0;
}
parent-subscriptions-list-item > div:not(.mobile) {
  padding: 16px 24px;
  background: #fff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
parent-subscriptions-list-item > div:not(.mobile) > span {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
parent-subscriptions-list-item > div:not(.mobile) > span.name {
  width: 21.5%;
  font-weight: 900;
}
parent-subscriptions-list-item > div:not(.mobile) > span.status {
  width: 15%;
  font-weight: 500;
}
parent-subscriptions-list-item > div:not(.mobile) > span.price {
  width: 10.5%;
  font-weight: 500;
}
parent-subscriptions-list-item > div:not(.mobile) > span.billing-term {
  width: 14.5%;
  font-weight: 500;
}
parent-subscriptions-list-item > div:not(.mobile) > span.next-billing {
  width: 14%;
  font-weight: 500;
}
parent-subscriptions-list-item > div:not(.mobile) > span.cancel-subscription {
  width: 22%;
  text-align: right;
}
parent-subscriptions-list-item > div:not(.mobile) > span.cancel-subscription a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  color: #4A90E2;
  margin-right: 32px;
}
parent-subscriptions-list-item > div:not(.mobile) > span.cancel-subscription span.glyphicon {
  color: #e5e5e5;
  font-size: 10px;
}
parent-subscriptions-list-item > div.mobile {
  padding: 5vw 7.5vw;
  background: #fff;
  border-left: 2px solid #e5e5e5;
  border-right: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
}
parent-subscriptions-list-item > div.mobile > div {
  margin-bottom: 10vw;
  display: block;
}
parent-subscriptions-list-item > div.mobile > div > div.column-left {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
parent-subscriptions-list-item > div.mobile > div > div.column-left span {
  color: #999999;
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  display: block;
  line-height: 6.6vw;
  margin-bottom: 10vw;
}
parent-subscriptions-list-item > div.mobile > div > div.column-right {
  width: 50%;
  display: inline-block;
}
parent-subscriptions-list-item > div.mobile > div > div.column-right span {
  color: #333;
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  display: block;
  line-height: 6.6vw;
  margin-bottom: 10vw;
}
parent-subscriptions-list-item > div.mobile > div.cancel-subscription {
  border-top: 2px dashed #e5e5e5;
  padding-top: 5vw;
  margin-bottom: 0;
}
parent-subscriptions-list-item > div.mobile > div.cancel-subscription a {
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 7.5vw;
  color: #4A90E2;
}
parent-subscriptions-list-item > div.mobile > div.cancel-subscription span.glyphicon {
  color: #e5e5e5;
  font-size: 5vw;
  font-size: 3vw;
  float: right;
  top: 3vw;
}
player-subscriptions-card-header > div {
  border-bottom: 1px solid #e5e5e5;
}
player-subscriptions-card-item > div {
  padding: 18px 0 17px 0;
  border-bottom: 1px solid #e5e5e5;
}
player-subscriptions-card-item > div > div {
  display: inline-block;
  width: 25%;
  color: #333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;
  vertical-align: text-top;
  padding-right: 8px;
}
player-subscriptions-list-header > div {
  width: 100%;
  border: 1px solid #E5E5E5;
  background: #fff;
  padding: 17px 24px;
  border-top: none;
}
player-subscriptions-list-header > div.mobile {
  border-width: 2px;
  padding: 5vw 5vw;
  border-top: 2px solid #e5e5e5;
  margin-top: 7.5vw;
  border-radius: 0;
}
player-subscriptions-list-item > div:not(.mobile) {
  padding: 16px 24px;
  background: #fff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
player-subscriptions-list-item > div:not(.mobile) > span {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
player-subscriptions-list-item > div:not(.mobile) > span.name {
  width: 21.5%;
  font-weight: 900;
}
player-subscriptions-list-item > div:not(.mobile) > span.status {
  width: 15%;
  font-weight: 500;
}
player-subscriptions-list-item > div:not(.mobile) > span.price {
  width: 10.5%;
  font-weight: 500;
}
player-subscriptions-list-item > div:not(.mobile) > span.billing-term {
  width: 14.5%;
  font-weight: 500;
}
player-subscriptions-list-item > div:not(.mobile) > span.next-billing {
  width: 14%;
  font-weight: 500;
}
player-subscriptions-list-item > div:not(.mobile) > span.cancel-subscription {
  width: 22%;
  text-align: right;
}
player-subscriptions-list-item > div:not(.mobile) > span.cancel-subscription a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  color: #4A90E2;
  margin-right: 32px;
}
player-subscriptions-list-item > div:not(.mobile) > span.cancel-subscription span.glyphicon {
  color: #e5e5e5;
  font-size: 10px;
}
player-subscriptions-list-item > div.mobile {
  padding: 5vw 7.5vw;
  background: #fff;
  border-left: 2px solid #e5e5e5;
  border-right: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
}
player-subscriptions-list-item > div.mobile > div {
  margin-bottom: 10vw;
  display: block;
}
player-subscriptions-list-item > div.mobile > div > div.column-left {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
player-subscriptions-list-item > div.mobile > div > div.column-left span {
  color: #999999;
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  display: block;
  line-height: 6.6vw;
  margin-bottom: 10vw;
}
player-subscriptions-list-item > div.mobile > div > div.column-right {
  width: 50%;
  display: inline-block;
}
player-subscriptions-list-item > div.mobile > div > div.column-right span {
  color: #333;
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  display: block;
  line-height: 6.6vw;
  margin-bottom: 10vw;
}
player-subscriptions-list-item > div.mobile > div.cancel-subscription {
  border-top: 2px dashed #e5e5e5;
  padding-top: 5vw;
  margin-bottom: 0;
}
player-subscriptions-list-item > div.mobile > div.cancel-subscription a {
  font-size: 4.4vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 7.5vw;
  color: #4A90E2;
}
player-subscriptions-list-item > div.mobile > div.cancel-subscription span.glyphicon {
  color: #e5e5e5;
  font-size: 5vw;
  font-size: 3vw;
  float: right;
  top: 3vw;
}
signup-org-list-preview-item > div.container-fluid {
  padding: 12px 24px;
  border-bottom: 1px solid #e5e5e5;
}
signup-org-list-preview-item > div.container-fluid > span.item-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
}
signup-org-list-preview-item > div.container-fluid > span.glyphicon.expand-indicator {
  font-size: 10px;
  color: #e5e5e5;
  line-height: 40px;
  vertical-align: top;
  float: right;
}
signup-org-list-preview-item > div.container-fluid.mobile {
  padding: 3.75vw 7.5vw;
  border-width: 2px;
}
signup-org-list-preview-item > div.container-fluid.mobile > span.item-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 13vw);
}
signup-org-list-preview-item > div.container-fluid.mobile > span.glyphicon.expand-indicator {
  font-size: 3vw;
  line-height: 12.5vw;
}
signup-org-list-preview-item > div.expanded-view > div.list-header {
  display: block;
  width: 100%;
  background-color: rgba(229, 229, 229, 0.3);
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 80px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 0;
}
signup-org-list-preview-item > div.expanded-view > div.team {
  padding: 12px 24px 12px 80px;
  border-bottom: 1px solid #e5e5e5;
}
signup-org-list-preview-item > div.expanded-view > div.team span.team-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 56px);
}
signup-org-list-preview-item > div.expanded-view.mobile > div.list-header {
  border-width: 2px;
  padding: 5vw 7.5vw 5vw 12.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 0;
}
signup-org-list-preview-item > div.expanded-view.mobile > div.team {
  padding: 3.75vw 7.5vw 3.75vw 12.5vw;
  border-width: 2px;
}
signup-org-list-preview-item > div.expanded-view.mobile > div.team span.team-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 10vw);
}
signup-orgs-list-item > div.container-fluid {
  padding: 12px 24px;
  border-bottom: 1px solid #e5e5e5;
}
signup-orgs-list-item > div.container-fluid > div.action-holder {
  height: 40px;
  margin-right: 16px;
  display: inline-block;
}
signup-orgs-list-item > div.container-fluid > div.action-holder.action-disabled {
  opacity: 0.7;
}
signup-orgs-list-item > div.container-fluid > div.action-holder > img {
  margin-top: 8px;
}
signup-orgs-list-item > div.container-fluid > div.logo-holder {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
signup-orgs-list-item > div.container-fluid > div.logo-holder > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
}
signup-orgs-list-item > div.container-fluid > span.item-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
}
signup-orgs-list-item > div.container-fluid > span.glyphicon.expand-indicator {
  font-size: 10px;
  color: #e5e5e5;
  line-height: 40px;
  vertical-align: top;
  float: right;
}
signup-orgs-list-item > div.container-fluid.mobile {
  padding: 3.75vw 7.5vw;
  border-width: 2px;
}
signup-orgs-list-item > div.container-fluid.mobile > div.action-holder {
  margin-right: 5vw;
}
signup-orgs-list-item > div.container-fluid.mobile > div.action-holder > img {
  margin-top: 3vw;
  height: 5vw;
}
signup-orgs-list-item > div.container-fluid.mobile > span.item-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 13vw);
}
signup-orgs-list-item > div.container-fluid.mobile > span.glyphicon.expand-indicator {
  font-size: 3vw;
  line-height: 12.5vw;
}
.widget-filter-dropdown input {
  border-radius: 4px;
}
@media (max-width: 320px) {
  .widget-filter-dropdown .input-container {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
}
@media (min-width: 321px) {
  .widget-filter-dropdown .list-item {
    margin-top: 20px;
  }
}
@media (max-width: 320px) {
  .widget-filter-dropdown .list-item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  .widget-filter-dropdown .list-item:not(:first-child) {
    border-top: 1px solid #ccc;
  }
}
@media (min-width: 321px) {
  .widget-filter-dropdown {
    padding-bottom: 25px;
  }
}
.widget-filter-dropdown.widget-filter-dropdown-mobile .input-container {
  padding-bottom: 10px;
}
.widget-filter-dropdown.widget-filter-dropdown-mobile .list-item {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.widget-filter-dropdown.widget-filter-dropdown-mobile .list-item:not(:first-child) {
  border-top: 1px solid #ccc;
}
contest-fundraising .fundraising-stats button.btn,
contest-fundraising button.btn.make-fundraiser-btn {
  border-radius: 4px;
  width: auto;
  margin-top: 20px;
}
.contest-preview h1.header {
  font-family: 'AvenirNextLTPro Bold';
}
.contest-preview iframe {
  width: 100%;
}
.contest-preview span.app-buttons-preview h4 {
  display: inline-block;
  margin-right: 5px;
  margin-top: 40px;
}
.contest-preview span.app-buttons-preview button {
  width: auto;
  display: inline-block;
  border-radius: 4px;
}
.contest-preview .widget-code-btn {
  float: right;
  width: auto;
  border-radius: 4px;
}
.contest-preview textarea.widget-code-area {
  width: 100%;
  height: 120px;
  resize: none;
}
.contest-preview textarea.widget-code-area:disabled {
  background: #fff;
}
.contest-preview .landing-page-holder {
  position: relative;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 130%;
}
.contest-preview .landing-page-holder iframe {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
}
.contest-preview .checkbox-group {
  width: 320px;
  display: inline-flex;
  margin-left: 20px;
}
.org-details h4 {
  line-height: 30px;
}
.org-details img {
  width: 100%;
}
@media (min-width: 768px) {
  .org-suborgs div[class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}
.organization-teams .uib-tab {
  margin: 3% 0px;
}
.organization-teams .gear-icon {
  width: 25px;
  margin-left: 20px;
}
.organization-teams .members-count {
  padding: 10px 15px;
  border-left: 1px solid #ddd;
}
.organization-teams .team-info h5 {
  line-height: 25px;
}
.organization-teams .invitation-code {
  margin-top: 10px;
}
.organization-teams .team-settings-tabs {
  margin-top: 1%;
}
.organization-teams .panel {
  border-radius: 0;
}
.organization-teams .members-info {
  margin-top: 3%;
}
.organization-teams .coach-profile-image {
  width: 7%;
  border-radius: 50%;
  min-width: 30px;
}
.organization-teams .coach-profile-info {
  padding: 35px 30px;
}
.organization-teams .coach-profile-info.right {
  font-size: 18px;
}
.organization-teams .coach-profile-info.right img {
  margin: 0 20px;
}
.organization-teams .coach-profile-info a .glyphicon {
  color: #333;
}
.organization-teams .coach-names {
  display: inline-table;
  margin-left: 10px;
}
.organization-teams .coach-names .username {
  display: block;
  color: #d5cde4;
}
.organization-teams .teams-list {
  max-height: 500px;
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}
.organization-teams .teams-list.nav-pills > li {
  width: 100%;
  margin-top: 5px;
  cursor: pointer;
}
.organization-teams .panel:first-child {
  margin-bottom: 0;
}
.organization-users {
  padding-top: 30px;
}
.organization-users input,
.organization-users input.form-control {
  border-radius: 4px;
}
.organization-users button.btn {
  border-radius: 4px;
}
@media (max-width: 767px) {
  .organization-users button.btn {
    font-size: 12px;
  }
}
.organization-users .table-row {
  padding-right: 15px;
  padding-left: 15px;
}
.organization-users .table-row .table-header {
  background-color: #003;
  color: white;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}
.organization-users .table-row .table-header span.glyphicon {
  vertical-align: sub;
  margin-left: 5px;
}
.organization-users .table-row .table-header .dropdown-menu {
  width: 100%;
  text-align: center;
}
.organization-users .table-row .table-item {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #969696;
  border-top: none;
}
.organization-users .table-row .table-item:nth-child(even) {
  background-color: #d7d7d7;
}
.organization-users .table-row .table-item .organization-user-detail {
  width: calc(100% + 40px);
}
.organization-users .table-row .table-item .organization-user-detail .user-names {
  word-break: break-all;
}
.organization-users div[class*='inner-scroll-'] {
  overflow-y: scroll;
}
edit-product-details-form > div.container-fluid {
  border: 1px solid #d7d7d7;
  background: #fff;
  padding-bottom: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 4px;
}
edit-product-details-form > div.container-fluid .logo-holder {
  height: 100px;
  width: 100%;
  margin-top: 40px;
  padding: 0 0 20px 0;
}
edit-product-details-form > div.container-fluid .logo-holder img.content-provider-logo {
  max-width: 100%;
  max-height: 100%;
}
edit-product-details-form > div.container-fluid form input.form-control {
  margin-bottom: 20px;
  border-radius: 4px;
  max-width: 400px;
}
edit-product-details-form > div.container-fluid form input.form-control:nth-of-type(2) {
  margin-bottom: 50px;
}
edit-product-details-form > div.container-fluid form span.input-label {
  font-weight: bold;
}
edit-product-details-form > div.container-fluid manager-product-availability-preview > span {
  margin-top: 40px;
}
edit-product-details-preview > div product-detail-expanded > div {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 4px;
}
edit-product-paths-form > div {
  padding-top: 30px;
}
edit-product-paths-form > div h4 {
  color: #6c6c6c;
}
edit-product-paths-form > div radio-input:not(:nth-of-type(1)) > div {
  margin-left: 10px;
}
edit-product-paths-form > div radio-input:nth-of-type(3) > div {
  width: 260px;
}
edit-product-paths-form > div div.custom-head-to-head-count-holder input.form-control {
  width: 260px;
  margin-left: calc(100% - 260px);
}
edit-product-paths-form > div manage-product-paths-drop-area > div.container-fluid {
  height: 600px;
}
edit-product-paths-form > div manage-product-draggable-paths-list > div .items-holder {
  max-height: 465px;
}
edit-product-paths-preview > div div.preview {
  padding-top: 30px;
  padding-bottom: 30px;
}
edit-product-paths-preview > div div.preview h4.paths-label {
  font-weight: bold;
  margin-bottom: 30px;
}
edit-product-paths-preview > div div.preview h4.skills-count-info {
  margin-top: 30px;
}
edit-product-availabilities-list > div.container-fluid {
  padding-top: 30px;
}
edit-product-availabilities-list > div.container-fluid > div.row span.item {
  margin-bottom: 15px;
  font-size: 16px;
  display: block;
  float: left;
  clear: left;
}
edit-product-availabilities-list > div.container-fluid > div.row span.item.selected-item {
  font-weight: bold;
  border-bottom: 2px solid dodgerblue;
  padding-bottom: 5px;
}
edit-product-availabilities-list > div.container-fluid error-message > div {
  margin-top: 20px;
  margin-left: -15px;
}
edit-product-price-and-availability-form > div.container-fluid form {
  padding-top: 30px;
  padding-bottom: 30px;
}
edit-product-price-and-availability-form > div.container-fluid form input.form-control.available-before-start-input {
  width: 100px;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
}
edit-product-price-and-availability-form > div.container-fluid form button.btn.btn-default.add-price {
  width: auto;
}
edit-product-price-and-availability-form > div.container-fluid form h2.currency-symbol {
  font-family: 'AvenirNextLTPro Bold';
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
}
edit-product-price-and-availability-form > div.container-fluid form span.retail-price-label {
  margin-left: 30px;
  margin-top: 5px;
  display: block;
}
edit-product-price-and-availability-form > div.container-fluid form span.subscription-term-label {
  margin-left: 15px;
}
edit-product-price-and-availability-form > div.container-fluid form > div > dropdown:first-of-type > div.dropdown {
  width: 80px;
}
edit-product-price-and-availability-form > div.container-fluid form > div > dropdown:not(:first-of-type) > div.dropdown {
  width: 200px;
}
edit-product-price-and-availability-form > div.container-fluid form > div > dropdown > div.dropdown {
  margin-left: 10px;
  height: 34px;
}
edit-product-price-and-availability-form > div.container-fluid form > div > dropdown > div.dropdown button.btn {
  height: inherit;
}
edit-product-price-and-availability-form > div.container-fluid form > div > dropdown > div.dropdown ul.dropdown-menu {
  width: 100%;
  min-width: 0;
}
edit-product-price-and-availability-form > div.container-fluid form h4 {
  font-weight: bold;
}
edit-product-price-and-availability-form > div.container-fluid form radio-input:nth-of-type(2) > div {
  margin-left: 10px;
}
edit-product-price-and-availability-form > div.container-fluid form date-input > div.full-width {
  width: 200px;
  display: inline-block;
}
edit-product-price-and-availability-form > div.container-fluid form date-input > div.full-width input.form-control {
  border-radius: 4px;
}
edit-product-price-and-availability-form > div.container-fluid form date-input:not(:first-of-type) {
  margin-left: 10px;
}
edit-product-price-and-availability-preview > div div.row.preview {
  padding-top: 30px;
  padding-bottom: 30px;
}
edit-product-price-and-availability-preview > div div.row.preview h4.view-title {
  font-weight: bold;
}
edit-product-price-and-availability-preview > div div.row.preview h2.price {
  font-family: 'AvenirNextLTPro Bold';
}
edit-product-price-and-availability-preview > div span.inline-stats {
  display: block;
}
edit-product-price-and-availability-preview > div span.inline-stats h4.stats-label {
  display: inline-block;
  font-weight: bold;
}
edit-product-price-and-availability-preview > div span.inline-stats span.stats-value {
  font-size: 18px;
  margin-left: 10px;
}
terminate-user-dialog > div.modal-dialog .modal-content.terminate-dialog {
  border-radius: 8px;
}
terminate-user-dialog > div.modal-dialog .modal-content.terminate-dialog button.btn {
  width: 150px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
}
terminate-user-dialog > div.modal-dialog .modal-content.terminate-dialog .dialog-text {
  margin-top: 20px;
  text-align: center;
}
terminate-user-dialog > div.modal-dialog .modal-content.terminate-dialog .dialog-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}
manager-user-add-organizations > div input.form-control {
  border-radius: 4px;
}
organization-role-selector > div.row {
  margin-bottom: 10px;
  margin-right: 0;
}
@media (min-width: 768px) {
  organization-role-selector > div.row .suborgs-selection-holder {
    text-align: right;
    padding-right: 20px;
  }
}
organization-role-selector > div.row span {
  font-size: 14px;
}
organization-role-selector > div.row .rolename-holder input {
  display: inline-block;
  vertical-align: top;
}
organization-role-selector > div.row .rolename-holder span {
  display: inline-block;
  width: calc(100% - 20px);
}
my-fundraiser-bank-account-view > div.container-fluid {
  padding-bottom: 50px;
}
my-fundraiser-bank-account-view > div.container-fluid span {
  display: block;
  margin-bottom: 10px;
}
my-fundraiser-bank-account-view > div.container-fluid span.account-detail-label {
  font-size: 16px;
}
my-fundraiser-bank-account-view > div.container-fluid span.account-detail {
  font-size: 19px;
  font-weight: bold;
}
my-fundraiser-bank-account-view > div.container-fluid button.btn.btn-primary.cancel {
  width: 160px;
  border-radius: 4px;
  border-width: 2px;
  font-weight: bold;
  margin-top: 10px;
  color: dodgerblue;
}
my-fundraiser-bank-account-view > div.container-fluid p {
  font-size: 16px;
}
my-fundraiser-bank-account-initial-view > div.container-fluid p {
  font-size: 20px;
  margin-bottom: 30px;
}
my-fundraiser-bank-account-initial-view > div.container-fluid button.btn.btn-primary.cancel {
  width: auto;
  border-radius: 4px;
  border-width: 2px;
  font-weight: bold;
  margin-top: 10px;
  color: dodgerblue;
}
my-fundraiser-bank-account-initial-view > div.container-fluid label {
  display: block;
  margin-top: 10px;
  color: #bb2e2e;
}
edit-my-pledge > div {
  background-color: #edf4fc;
  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 35px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
edit-my-pledge > div .modal-title {
  margin-bottom: 25px;
}
edit-my-pledge > div .modal-subtitle {
  margin-top: 30px;
}
edit-my-pledge > div .input-with-icon-holder {
  position: relative;
}
edit-my-pledge > div .input-with-icon-holder span {
  position: absolute;
  right: 25px;
  top: 10px;
  color: #adaeae;
}
edit-my-pledge > div .input-with-icon-holder input {
  padding-right: 34px;
}
edit-my-pledge > div button.btn,
edit-my-pledge > div input.form-control {
  border-radius: 4px;
}
edit-my-pledge > div button.btn.btn-primary.cancel {
  background: #fff;
}
edit-my-pledge > div input.form-control.ng-invalid {
  border: 1px solid red;
}
my-fundraiser-pledge-detail > div button.btn {
  width: 100%;
  border-radius: 4px;
}
@media (max-width: 991px) {
  my-fundraiser-pledge-detail > div {
    font-size: 12px;
  }
}
my-fundraiser-pledge-detail > div .buttons-holder {
  text-align: center;
}
@media (min-width: 1200px) {
  my-fundraiser-pledge-detail > div .buttons-holder div[class*='col-'] {
    float: none;
    display: inline-block;
  }
}
my-fundraiser-pledge-list-header > div {
  font-weight: bold;
  margin-top: 20px;
}
@media (max-width: 991px) {
  my-fundraiser-pledge-list-header > div {
    font-size: 12px;
  }
}
my-fundraiser-pledge-list-header > div hr {
  margin: 10px 0 0 0;
  border-top: 2px solid #f5f5f5;
}
my-fundraiser-pledge-list-item > div.deleted {
  opacity: 0.6;
  cursor: default;
}
my-fundraiser-pledge-list-item > div.expanded {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #d7d7d7;
}
my-fundraiser-pledge-list-item > div.expanded hr {
  border-color: rgba(0, 0, 0, 0);
}
my-fundraiser-pledge-list-item > div hr {
  margin-bottom: 0;
}
my-fundraiser-pledge-list-item > div .header {
  padding: 15px 0;
}
my-fundraiser-pledge-list-item > div .header.header-expanded {
  background-color: #c4d9f8;
  border-bottom: 1px solid #d7d7d7;
}
@media (max-width: 991px) {
  my-fundraiser-pledge-list-item > div .header {
    font-size: 12px;
  }
}
my-fundraiser-pledge-order-items .title {
  text-transform: uppercase;
}
my-fundraiser-pledge-order-items .table-header {
  border-bottom: 1px solid #656565;
  color: #656565;
}
my-fundraiser-pledge-order-items div.col-xs-12.table-header,
my-fundraiser-pledge-order-items div.col-xs-12.table {
  padding: 0 5px;
}
my-fundraiser-pledges-filters > div {
  margin-top: 30px;
  color: #969696;
  font-weight: bold;
}
my-fundraiser-pledges-filters > div span.title {
  margin-right: 5px;
}
my-fundraiser-pledges-filters > div .filter-holder {
  display: inline-block;
}
@media (min-width: 768px) and (max-width: 991px) {
  my-fundraiser-pledges-filters > div .filter-holder {
    display: block;
    margin-top: 10px;
  }
  my-fundraiser-pledges-filters > div .filter-holder pledge-list-filter .main-group {
    margin-left: 0;
    margin-right: 0;
  }
}
parent-activity-feed-card-item-description > div {
  margin-top: 24px;
  display: block;
  width: 100%;
}
parent-activity-feed-card-item-description > div img.skill-badge {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  vertical-align: top;
}
parent-activity-feed-card-item-description > div p.description {
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  width: calc(100% - 56px);
  margin: 0;
}
parent-activity-feed-card-item-header > div {
  display: block;
  width: 100%;
}
parent-activity-feed-card-item-header > div img.avatar {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  vertical-align: bottom;
}
parent-activity-feed-card-item-header > div div.details-holder {
  display: inline-block;
}
parent-activity-feed-card-item-header > div div.details-holder span.username {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
}
parent-activity-feed-card-item-header > div div.details-holder span.activity-time {
  color: #999;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
}
parent-activity-feed-card-item-highfives > div {
  margin-top: 40px;
  display: inline-block;
  width: 100%;
}
parent-activity-feed-card-item-highfives > div div.all-counts-holder {
  display: inline-block;
  float: right;
  margin-top: 8px;
}
parent-activity-feed-card-item-highfives > div div.all-counts-holder span.count-holder span {
  color: #999;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 4px;
}
parent-activity-feed-card-item-highfives > div div.all-counts-holder span.count-holder:not(:first-child) {
  margin-left: 16px;
}
parent-my-kids-card-header-item > span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
parent-my-kids-list-header-item > span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
parent-my-kids-list-header-item > span.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-my-kids-list-item-actions > div {
  display: inline;
}
parent-my-kids-list-item-actions > div span.dropdown {
  display: inline-block;
  float: right;
  width: auto;
  margin-top: 8px;
}
parent-my-kids-list-item-actions > div span.dropdown > a {
  color: #4A90E2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
parent-my-kids-list-item-actions > div span.dropdown > a:focus {
  text-decoration: none;
}
parent-my-kids-list-item-actions > div span.dropdown > a span.glyphicon {
  font-size: 10px;
  margin-left: 8px;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu {
  right: 0;
  left: auto;
  width: 240px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 1px 3px 0px #888;
  padding: 0;
  margin: 0;
  z-index: 99;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu:before,
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu:after {
  display: none;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li {
  padding: 8px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  border: none;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li div.icon-holder {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: sub;
  display: inline-block;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li div.icon-holder.profile-icon-holder {
  background: url('../img/Icon/Content/Blue/edit-blue.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li div.icon-holder.privacy-settings-icon-holder {
  background: url('../img/Icon/Action/Blue/settings-blue.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li div.icon-holder.orgs-and-teams-icon-holder {
  background: url('../img/Icon/Content/Blue/add-filled-blue.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li div.icon-holder.remove-kid-icon-holder {
  background: url('../img/Icon/Action/Blue/delete-blue.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li:hover {
  background-color: #4A90E2;
  color: #fff;
  cursor: pointer;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li:hover div.icon-holder.profile-icon-holder {
  background: url('../img/Icon/Content/White/edit-white.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li:hover div.icon-holder.privacy-settings-icon-holder {
  background: url('../img/Icon/Action/White/settings-white.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li:hover div.icon-holder.orgs-and-teams-icon-holder {
  background: url('../img/Icon/Content/White/add-filled-white.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div span.dropdown ul.dropdown-menu > li:hover div.icon-holder.remove-kid-icon-holder {
  background: url('../img/Icon/Action/White/delete-white.png');
  background-position: center;
}
parent-my-kids-list-item-actions > div.mobile {
  padding: 5vw 3.75vw;
  margin-left: -3.75vw;
  margin-right: -3.75vw;
  display: flex;
  justify-content: space-between;
  border-top: 2px dashed #E5E5E5;
}
parent-my-kids-list-item-actions > div.mobile img {
  width: 5vw;
  height: 5vw;
}
parent-my-kids-list-item-desktop-detail > div {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: inline-block;
}
parent-my-kids-list-item-desktop-detail > div.points {
  width: 12%;
}
parent-my-kids-list-item-desktop-detail > div.skills,
parent-my-kids-list-item-desktop-detail > div.caps,
parent-my-kids-list-item-desktop-detail > div.h2h {
  width: 11%;
}
parent-my-kids-list-item-desktop-detail > div.subscription-status {
  width: 51px;
  height: 24px;
  border-radius: 16px;
  color: #FFF;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding-top: 4px;
}
parent-my-kids-list-item-desktop-detail > div.subscription-status.subscription-yes {
  background-color: #6DD400;
}
parent-my-kids-list-item-desktop-detail > div.subscription-status.subscription-no {
  background-color: #F55151;
}
parent-my-kids-list-item-mobile-detail > div {
  font-size: 4.4vw;
  line-height: 6.6vw;
  border: 2px solid #E5E5E5;
  border-top: none;
  padding: 5vw 3.75vw 0 3.75vw;
}
parent-my-kids-list-item-mobile-detail > div div.detail-group {
  margin-bottom: 10vw;
}
parent-my-kids-list-item-mobile-detail > div div.detail-group span.detail-label {
  color: #999;
}
parent-my-kids-list-item-mobile-detail > div div.detail-group span.detail-value {
  color: #333;
  float: right;
}
parent-my-kids-list-item-mobile-detail > div div.detail-group img.detail-icon {
  width: 7vw;
  height: 7vw;
  margin-right: 3vw;
  display: inline-block;
}
parent-my-kids-list-item-mobile-detail > div div.detail-group div.subscription-status {
  width: 16vw;
  height: 7.5vw;
  border-radius: 5vw;
  color: #FFF;
  font-size: 3.75vw;
  line-height: 5vw;
  text-align: center;
  padding-top: 1vw;
  display: inline-block;
  float: right;
}
parent-my-kids-list-item-mobile-detail > div div.detail-group div.subscription-status.subscription-yes {
  background-color: #6DD400;
}
parent-my-kids-list-item-mobile-detail > div div.detail-group div.subscription-status.subscription-no {
  background-color: #F55151;
}
parent-my-kids-profile-edit-profile-picture > div {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
parent-my-kids-profile-edit-profile-picture > div.disabled {
  opacity: 0.7;
}
parent-my-kids-profile-edit-profile-picture > div.disabled > div.overlay {
  cursor: default;
}
parent-my-kids-profile-edit-profile-picture > div > img.profile-picture {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}
parent-my-kids-profile-edit-profile-picture > div > div.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
}
parent-my-kids-profile-edit-profile-picture > div > div.overlay > img.camera-icon {
  margin-top: 50%;
  transform: translateY(-50%);
}
parent-my-kids-profile-edit-profile-picture > div.mobile {
  width: 30vw;
  height: 30vw;
}
parent-my-kids-profile-edit-profile-picture > div.mobile > div.overlay > img.camera-icon {
  width: 7.5vw;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid {
  padding: 0;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder {
  padding: 24px;
  padding-bottom: 40px;
  background-color: #fff;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column {
  display: block;
}
@media (max-width: 1199px) {
  parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column {
    width: 50%;
  }
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column p.helper-label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column button.btn.btn-primary,
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column text-input > span.label {
  margin-top: 24px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column div.or {
  margin: 40px 0;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column div.or > span.or-divider {
  display: inline-block;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column div.or > span.or-divider.or-divider-text {
  color: #999;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 24px;
  vertical-align: text-top;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column div.or > span.or-divider.or-divider-left,
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > div.form-fields-holder > div.form-column div.or > span.or-divider.or-divider-right {
  width: calc(50% - 40px);
  border: 1px solid #D8D8D8;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > button.btn {
  width: auto;
  margin-top: 24px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid success-message > div,
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid error-message > div {
  margin-bottom: 24px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder {
  padding: 7.5vw;
  padding-bottom: 12.5vw;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column {
  width: 100%;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column p.helper-label {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column button.btn.btn-primary,
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column text-input > span.label {
  margin-top: 7.5vw;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column button.btn.btn-primary {
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column div.or {
  margin-top: 12.5vw;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column div.or > span.or-divider.or-divider-text {
  font-size: 5vw;
  line-height: 7.5vw;
  margin: 0 7.5vw;
  vertical-align: middle;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column div.or > span.or-divider.or-divider-left,
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > div.form-fields-holder > div.form-column div.or > span.or-divider.or-divider-right {
  width: calc(50% - 120px);
  border: 2px solid #D8D8D8;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > button.btn {
  margin-top: 10vw;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile > button.btn:not(.cancel) {
  margin-left: 5vw;
}
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile success-message > div,
parent-my-kids-profile-orgs-and-teams-invitation-code > form.container-fluid.mobile error-message > div {
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
}
parent-my-kids-profile-join-orgs > div.container-fluid {
  padding: 0;
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder {
  padding: 24px;
  padding-bottom: 40px;
  background-color: #fff;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder info-message > span,
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder error-message > div {
  margin-top: 24px;
}
@media (max-width: 1199px) {
  parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder form {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder form {
    width: 50%;
    display: inline-block;
  }
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder form text-input > span.helper-label {
  display: block;
  margin-left: 0;
  margin-bottom: 0;
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder form text-input > input {
  margin-top: 24px;
}
@media (max-width: 1199px) {
  parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder div.button-holder {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder div.button-holder {
    width: 50%;
    text-align: right;
    display: inline-block;
  }
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder div.button-holder > button.btn {
  width: auto;
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder div.button-holder > button.btn:nth-child(2) {
  margin-left: 16px;
}
parent-my-kids-profile-join-orgs > div.container-fluid div.content-holder > div.list-header {
  display: block;
  width: 100%;
  margin-top: 24px;
  background-color: rgba(229, 229, 229, 0.3);
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 24px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder {
  padding: 7.5vw;
  padding-bottom: 12.5vw;
  border-width: 2px;
  border-radius: 8px;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder spinner > div,
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder info-message > span,
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder error-message > div {
  margin-top: 7.5vw;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder form text-input > span.helper-label {
  margin-top: 2.5vw;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder form text-input > input {
  margin-top: 7.5vw;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder div.button-holder {
  text-align: left;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder div.button-holder > button.btn {
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
  width: 100%;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder div.button-holder > button.btn:nth-child(1) {
  margin-top: 7.5vw;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder div.button-holder > button.btn:nth-child(2) {
  margin-left: 0;
  margin-top: 5vw;
}
parent-my-kids-profile-join-orgs > div.container-fluid.mobile div.content-holder > div.list-header {
  margin-top: 7.5vw;
  padding: 5vw 7.5vw 5vw 7.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid {
  padding: 0;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid success-message > div {
  margin-bottom: 24px;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder {
  padding: 24px;
  padding-bottom: 40px;
  background-color: #fff;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder info-message > span,
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder error-message > div {
  margin-top: 24px;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder div.button-holder {
  width: 100%;
  text-align: right;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder div.button-holder > button.btn {
  width: auto;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder div.button-holder > button.btn:nth-child(2) {
  margin-left: 16px;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid div.content-holder > div.list-header {
  display: block;
  width: 100%;
  margin-top: 24px;
  background-color: rgba(229, 229, 229, 0.3);
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 24px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid > button.btn.cancel {
  margin-top: 24px;
  width: auto;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder {
  padding: 7.5vw;
  padding-bottom: 12.5vw;
  border-width: 2px;
  border-radius: 8px;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder success-message > div {
  margin-bottom: 7.5vw;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder spinner > div,
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder info-message > span,
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder error-message > div {
  margin-top: 7.5vw;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder div.button-holder {
  text-align: left;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder div.button-holder > button.btn {
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
  width: 100%;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder div.button-holder > button.btn:nth-child(2) {
  margin-left: 0;
  margin-top: 5vw;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile div.content-holder > div.list-header {
  margin-top: 7.5vw;
  padding: 5vw 7.5vw 5vw 7.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-my-kids-profile-orgs-and-teams-list > div.container-fluid.mobile > button.btn.cancel {
  margin-top: 7.5vw;
  width: 100%;
}
parent-subscriptions-card-header-item > span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 17px;
  padding-bottom: 19px;
}
parent-subscriptions-list-header-item > span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
parent-subscriptions-list-header-item > span.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
parent-subscriptions-cancel-modal > div {
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(40, 41, 61, 0.04), 0 8px 16px 0 rgba(96, 97, 112, 0.16);
  padding: 24px;
  padding-top: 28px;
}
parent-subscriptions-cancel-modal > div > span.title {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
parent-subscriptions-cancel-modal > div > p.prompt-message {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 16px;
}
parent-subscriptions-cancel-modal > div > div.buttons-holder {
  margin-top: 32px;
  text-align: right;
}
parent-subscriptions-cancel-modal > div > div.buttons-holder > button.btn {
  width: auto;
  font-size: 13px;
}
parent-subscriptions-cancel-modal > div > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-subscriptions-cancel-modal > div.mobile {
  border-radius: 2.5vw;
  box-shadow: 0 4px 8px 0 rgba(40, 41, 61, 0.04), 0 16px 32px 0 rgba(96, 97, 112, 0.16);
  padding: 7.5vw;
  padding-top: 8.75vw;
}
parent-subscriptions-cancel-modal > div.mobile > span.title {
  font-size: 5vw;
  line-height: 7.5vw;
}
parent-subscriptions-cancel-modal > div.mobile > p.prompt-message {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 5vw;
}
parent-subscriptions-cancel-modal > div.mobile > div.buttons-holder {
  margin-top: 10vw;
  text-align: center;
  width: 100%;
}
parent-subscriptions-cancel-modal > div.mobile > div.buttons-holder > button.btn {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-subscriptions-cancel-modal > div.mobile > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 0;
  margin-top: 5vw;
}
player-subscriptions-card-header-item > span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 17px;
  padding-bottom: 19px;
}
player-subscriptions-list-header-item > span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
player-subscriptions-list-header-item > span.mobile {
  font-size: 4.4vw;
  line-height: 6.6vw;
}
player-subscriptions-cancel-modal > div {
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(40, 41, 61, 0.04), 0 8px 16px 0 rgba(96, 97, 112, 0.16);
  padding: 24px;
  padding-top: 28px;
}
player-subscriptions-cancel-modal > div > span.title {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
player-subscriptions-cancel-modal > div > p.prompt-message {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 16px;
}
player-subscriptions-cancel-modal > div > div.buttons-holder {
  margin-top: 32px;
  text-align: right;
}
player-subscriptions-cancel-modal > div > div.buttons-holder > button.btn {
  width: auto;
  font-size: 13px;
}
player-subscriptions-cancel-modal > div > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 16px;
}
player-subscriptions-cancel-modal > div.mobile {
  border-radius: 2.5vw;
  box-shadow: 0 4px 8px 0 rgba(40, 41, 61, 0.04), 0 16px 32px 0 rgba(96, 97, 112, 0.16);
  padding: 7.5vw;
  padding-top: 8.75vw;
}
player-subscriptions-cancel-modal > div.mobile > span.title {
  font-size: 5vw;
  line-height: 7.5vw;
}
player-subscriptions-cancel-modal > div.mobile > p.prompt-message {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 5vw;
}
player-subscriptions-cancel-modal > div.mobile > div.buttons-holder {
  margin-top: 10vw;
  text-align: center;
  width: 100%;
}
player-subscriptions-cancel-modal > div.mobile > div.buttons-holder > button.btn {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
player-subscriptions-cancel-modal > div.mobile > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 0;
  margin-top: 5vw;
}
signup-orgs-list-item-expanded > div.container-fluid {
  padding: 0;
}
signup-orgs-list-item-expanded > div.container-fluid > div.list-header {
  display: block;
  width: 100%;
  background-color: rgba(229, 229, 229, 0.3);
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 80px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 0;
}
signup-orgs-list-item-expanded > div.container-fluid spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
signup-orgs-list-item-expanded > div.container-fluid info-message > span,
signup-orgs-list-item-expanded > div.container-fluid error-message > div {
  margin-top: 24px;
}
signup-orgs-list-item-expanded > div.container-fluid div.teams-holder {
  max-height: 250px;
  overflow-y: auto;
}
signup-orgs-list-item-expanded > div.container-fluid.mobile > div.list-header {
  border-width: 2px;
  padding: 5vw 7.5vw 5vw 12.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 0;
}
signup-orgs-list-item-expanded > div.container-fluid.mobile spinner > div,
signup-orgs-list-item-expanded > div.container-fluid.mobile info-message > span,
signup-orgs-list-item-expanded > div.container-fluid.mobile error-message > div {
  margin-top: 7.5vw;
}
signup-orgs-list-item-expanded > div.container-fluid.mobile div.teams-holder {
  max-height: 70vw;
}
.contest-basics-view {
  padding-bottom: 50px;
}
.contest-basics-view button.btn {
  border-radius: 4px;
}
.contest-basics-view .contest-name {
  font-family: 'AvenirNextLTPro Bold';
}
@media (max-width: 767px) {
  .contest-basics-view .checkbox-holder {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.contest-basics-view p {
  word-break: break-all;
}
.contest-basics-view .pre-roll-selector {
  max-width: 200px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .contest-basics-view .pre-roll-selector {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .contest-basics-view .pre-roll-selector {
    margin-top: 15%;
  }
}
.contest-basics-view .pre-roll-selector div:first-child {
  padding: 10px;
  background-color: #eee;
  border-right: 1px solid #d1d1d1;
  margin-right: 10px;
  display: inline-block;
}
.contest-content-view button.btn {
  border-radius: 4px;
}
.contest-content-view textarea {
  resize: none;
  height: 150px;
  margin-bottom: 80px;
}
.contest-content-view .how-it-works {
  padding: 30px;
  margin-bottom: 80px;
  border: 1px solid;
  border-radius: 12px;
  background-color: #fff;
}
.contest-content-view .how-it-works .how-it-works-header {
  color: #ff8827;
  letter-spacing: 3px;
  text-align: center;
  font-family: 'AvenirNextLTPro Bold';
}
.contest-content-view .how-it-works .how-it-works-body {
  margin-top: 20px;
}
.contest-content-view .how-it-works .how-it-works-body p {
  margin-top: 20px;
  text-align: justify;
  word-wrap: break-word;
}
.contest-content-view .how-it-works .how-it-works-body h3 {
  margin-top: 20px;
}
.contest-content-view .how-it-works .how-it-works-body .how-it-works-content h3 {
  font-family: 'AvenirNextLTPro Bold';
  text-align: left;
}
contest-fundraising-form > div button.btn {
  border-radius: 4px;
  width: auto;
  min-width: 140px;
}
contest-fundraising-form > div organization input.form-control {
  width: 300px;
  border-radius: 4px;
}
contest-fundraising-view .fundraising-stats button.btn {
  border-radius: 4px;
  width: auto;
  margin-top: 20px;
  padding: 6px 12px;
}
.contest-sponsor-view button {
  border-radius: 4px;
}
.contest-sponsor-view .brand-logo {
  max-height: 80px;
  max-width: 100%;
}
.contest-sponsor-view ul.sponsors-sorting.bordered {
  border: 1px dashed #bbb;
}
.contest-sponsor-view ul.sponsors-sorting li {
  padding-top: 30px;
  margin-bottom: 10px;
  min-height: 140px;
}
.contest-sponsor-view ul.sponsors-sorting li .border-holder {
  border-bottom: 2px dashed #d1d1d1;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 30px);
}
.contest-sponsor-view ul.sponsors-sorting li .primary-sponsor-selector {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: hidden;
}
.contest-sponsor-view ul.sponsors-sorting li .primary-sponsor-selector div:first-child {
  padding: 10px;
  background-color: #eee;
  border-right: 1px solid #d1d1d1;
  margin-right: 10px;
  display: inline-block;
}
.contest-sponsor-view ul.sponsors-sorting .primary-sponsor-selector {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: hidden;
}
.contest-sponsor-view ul.sponsors-sorting .primary-sponsor-selector div:first-child {
  padding: 10px;
  background-color: #eee;
  border-right: 1px solid #d1d1d1;
  margin-right: 10px;
  display: inline-block;
}
contest-sponsors-reorder ul.sponsors-sorting.bordered {
  border: 1px dashed #bbb;
}
contest-sponsors-reorder ul.sponsors-sorting input[readonly] {
  background-color: #fff;
  border: none;
  box-shadow: none;
  padding: 0;
  font-size: 15px;
  cursor: default;
}
contest-sponsors-reorder ul.sponsors-sorting li {
  padding-top: 30px;
  margin-bottom: 10px;
  min-height: 140px;
}
contest-sponsors-reorder ul.sponsors-sorting li .border-holder {
  border-bottom: 2px dashed #d1d1d1;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 30px);
}
contest-sponsors-reorder ul.sponsors-sorting li .primary-sponsor-selector {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: hidden;
}
contest-sponsors-reorder ul.sponsors-sorting li .primary-sponsor-selector div:first-child {
  padding: 10px;
  background-color: #eee;
  border-right: 1px solid #d1d1d1;
  margin-right: 10px;
  display: inline-block;
}
contest-sponsors-reorder ul.sponsors-sorting .primary-sponsor-selector {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: hidden;
}
contest-sponsors-reorder ul.sponsors-sorting .primary-sponsor-selector div:first-child {
  padding: 10px;
  background-color: #eee;
  border-right: 1px solid #d1d1d1;
  margin-right: 10px;
  display: inline-block;
}
contest-sponsors-reorder ul.sponsors-sorting image-drop-uploader img {
  max-height: 80px;
  max-width: 100%;
}
contest-sponsors-reorder ul.sponsors-sorting .delete-sponsor-icon {
  color: #e4000b;
}
.organization-teams.add-coach-modal {
  margin-top: 25px;
}
.organization-teams.add-coach-modal .add-coach-header {
  padding: 30px 0;
}
.organization-teams.add-coach-modal input:not([type="radio"]) {
  width: 100%;
  padding: 5px 10px;
}
.organization-teams.add-coach-modal span.org-filter {
  display: block;
  margin: 5px;
}
.organization-teams.add-coach-modal .coach-profile-info {
  padding: 15px;
}
.organization-teams.add-coach-modal .coach-list-item {
  border-bottom: 1px solid #d5cde4;
}
.organization-teams.add-coach-modal .inner-scroll-400 {
  max-height: 450px;
}
.organization-teams.add-coach-modal .btn {
  margin: 30px 0;
}
@media (max-width: 1400px) {
  .organization-teams.add-coach-modal .inner-scroll-400 {
    max-height: 250px;
  }
}
.team-contests {
  padding-top: 15px;
}
.team-contests button.btn {
  width: auto;
}
.manager-team-paths {
  margin-top: 3%;
}
.manager-team-paths .filters .btn {
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}
.manager-team-paths .filters span {
  margin-left: 2%;
}
.manager-team-paths .path-details {
  border: 1px solid #ababab;
  padding-bottom: 15px;
}
.manager-team-paths .path-details .content-provider-logo {
  max-width: 45%;
  max-height: 60px;
}
.manager-team-paths .path-details span[class*='glyphicon-menu'] {
  color: #ababab;
}
.manager-team-paths .path-details .path-stats {
  font-size: 15px;
}
.manager-team-paths .path-details .path-stats span:last-child {
  margin-left: 5%;
}
.manager-team-paths .path-details .path-stats .difficulty-icon {
  height: 20px;
  margin-right: 5px;
  vertical-align: sub;
}
.manager-team-paths .path-details .path-stats .path-stats-icon {
  margin-right: 5px;
}
.manager-team-paths .path-details .path-stats .path-stats-icon.glyphicon {
  color: #498FE1;
}
.manager-team-paths .path-details .path-stats .path-stats-icon:not(:first-child) {
  margin-left: 5%;
}
.manager-team-paths .path-details .path-stats .path-stats-icon img {
  width: 15px;
  height: 15px;
  vertical-align: top;
}
.manager-team-paths .path-details .path-skills {
  margin-top: 20px;
  overflow-y: auto;
  max-height: 800px;
  border: 1px solid #e2deef;
  background-color: #f7f5fe;
}
.org-subscriptions-list .nav > li > a {
  color: #2f2f2f;
}
manager-product-pricing-dropdown > div {
  width: 150px;
  display: inline;
}
manager-product-pricing-dropdown > div > dropdown > div.dropdown {
  width: 150px;
  height: 34px;
}
manager-product-pricing-dropdown > div > dropdown > div.dropdown button.btn {
  height: inherit;
}
manager-product-pricing-dropdown > div > dropdown > div.dropdown ul.dropdown-menu {
  width: 100%;
  min-width: 0;
}
edit-product-public-price > div > dropdown:first-of-type > div.dropdown {
  width: 80px;
}
edit-product-public-price > div > dropdown:not(:first-of-type) > div.dropdown {
  width: 200px;
}
edit-product-public-price > div > dropdown > div.dropdown {
  margin-left: 10px;
  height: 34px;
}
edit-product-public-price > div > dropdown > div.dropdown button.btn {
  height: inherit;
}
edit-product-public-price > div > dropdown > div.dropdown ul.dropdown-menu {
  width: 100%;
  min-width: 0;
}
edit-product-public-price > div manager-product-pricing-dropdown dropdown > div.dropdown {
  width: 150px;
}
my-fundraiser-pledge-order-item {
  color: #656565;
}
my-fundraiser-pledge-order-item > div {
  padding-top: 10px;
}
parent-my-kids-profile-join-org-confirmation-modal > div {
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(40, 41, 61, 0.04), 0 8px 16px 0 rgba(96, 97, 112, 0.16);
  padding: 24px;
  padding-top: 28px;
}
parent-my-kids-profile-join-org-confirmation-modal > div > span.title {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
parent-my-kids-profile-join-org-confirmation-modal > div > p.prompt-message {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 16px;
}
parent-my-kids-profile-join-org-confirmation-modal > div > div.buttons-holder {
  margin-top: 32px;
  text-align: right;
}
parent-my-kids-profile-join-org-confirmation-modal > div > div.buttons-holder > button.btn {
  width: auto;
  font-size: 16px;
}
parent-my-kids-profile-join-org-confirmation-modal > div > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-my-kids-profile-join-org-confirmation-modal > div.mobile {
  border-radius: 2.5vw;
  box-shadow: 0 4px 8px 0 rgba(40, 41, 61, 0.04), 0 16px 32px 0 rgba(96, 97, 112, 0.16);
  padding: 7.5vw;
  padding-top: 8.75vw;
}
parent-my-kids-profile-join-org-confirmation-modal > div.mobile > span.title {
  font-size: 5vw;
  line-height: 7.5vw;
}
parent-my-kids-profile-join-org-confirmation-modal > div.mobile > p.prompt-message {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 5vw;
}
parent-my-kids-profile-join-org-confirmation-modal > div.mobile > div.buttons-holder {
  margin-top: 10vw;
  text-align: center;
  width: 100%;
}
parent-my-kids-profile-join-org-confirmation-modal > div.mobile > div.buttons-holder > button.btn {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-my-kids-profile-join-org-confirmation-modal > div.mobile > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 0;
  margin-top: 5vw;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid {
  padding: 12px 24px;
  border-bottom: 1px solid #e5e5e5;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid > div.action-holder {
  height: 40px;
  margin-right: 16px;
  display: inline-block;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid > div.action-holder.action-disabled {
  opacity: 0.7;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid > div.action-holder > img {
  margin-top: 8px;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid > div.logo-holder {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid > div.logo-holder > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
}
parent-my-kids-profile-join-orgs-item > div.container-fluid > span.item-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid > span.glyphicon.expand-indicator {
  font-size: 10px;
  color: #e5e5e5;
  line-height: 40px;
  vertical-align: top;
  float: right;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid.mobile {
  padding: 3.75vw 7.5vw;
  border-width: 2px;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid.mobile > div.action-holder {
  margin-right: 5vw;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid.mobile > div.action-holder > img {
  margin-top: 3vw;
  height: 5vw;
}
parent-my-kids-profile-join-orgs-item > div.container-fluid.mobile > span.item-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 13vw);
}
parent-my-kids-profile-join-orgs-item > div.container-fluid.mobile > span.glyphicon.expand-indicator {
  font-size: 3vw;
  line-height: 12.5vw;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid {
  padding: 12px 24px;
  border-bottom: 1px solid #e5e5e5;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid > div.action-holder {
  height: 40px;
  margin-right: 16px;
  display: inline-block;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid > div.action-holder.action-disabled {
  opacity: 0.7;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid > div.action-holder > img {
  margin-top: 8px;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid > div.logo-holder {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid > div.logo-holder > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid > span.item-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid > span.glyphicon.expand-indicator {
  font-size: 10px;
  color: #e5e5e5;
  line-height: 40px;
  vertical-align: top;
  float: right;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid.mobile {
  padding: 3.75vw 7.5vw;
  border-width: 2px;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid.mobile > div.action-holder {
  margin-right: 5vw;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid.mobile > div.action-holder > img {
  margin-top: 3vw;
  height: 5vw;
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid.mobile > span.item-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 13vw);
}
parent-my-kids-profile-orgs-and-teams-list-item > div.container-fluid.mobile > span.glyphicon.expand-indicator {
  font-size: 3vw;
  line-height: 12.5vw;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div {
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(40, 41, 61, 0.04), 0 8px 16px 0 rgba(96, 97, 112, 0.16);
  padding: 24px;
  padding-top: 28px;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div > span.title {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div > p.prompt-message {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 16px;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div > div.buttons-holder {
  margin-top: 32px;
  text-align: right;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div > div.buttons-holder > button.btn {
  width: auto;
  font-size: 16px;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 16px;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div.mobile {
  border-radius: 2.5vw;
  box-shadow: 0 4px 8px 0 rgba(40, 41, 61, 0.04), 0 16px 32px 0 rgba(96, 97, 112, 0.16);
  padding: 7.5vw;
  padding-top: 8.75vw;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div.mobile > span.title {
  font-size: 5vw;
  line-height: 7.5vw;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div.mobile > p.prompt-message {
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 5vw;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div.mobile > div.buttons-holder {
  margin-top: 10vw;
  text-align: center;
  width: 100%;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div.mobile > div.buttons-holder > button.btn {
  width: 100%;
  font-size: 5vw;
  line-height: 7.5vw;
  padding: 2.5vw 5vw;
  border-radius: 2px;
}
parent-my-kids-profile-orgs-remove-confirmation-modal > div.mobile > div.buttons-holder > button.btn:not(.cancel) {
  margin-left: 0;
  margin-top: 5vw;
}
signup-orgs-list-item-team > div {
  padding: 12px 24px 12px 80px;
  border-bottom: 1px solid #e5e5e5;
}
signup-orgs-list-item-team > div > div.action-holder {
  height: 40px;
  margin-right: 16px;
  display: inline-block;
}
signup-orgs-list-item-team > div > div.action-holder.action-disabled {
  opacity: 0.7;
}
signup-orgs-list-item-team > div > div.action-holder > img {
  margin-top: 8px;
}
signup-orgs-list-item-team > div > div.logo-holder {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
signup-orgs-list-item-team > div > div.logo-holder > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
}
signup-orgs-list-item-team > div > span.team-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 56px);
}
signup-orgs-list-item-team > div.mobile {
  padding: 3.75vw 7.5vw 3.75vw 12.5vw;
  border-width: 2px;
}
signup-orgs-list-item-team > div.mobile > div.action-holder {
  margin-right: 5vw;
}
signup-orgs-list-item-team > div.mobile > div.action-holder > img {
  margin-top: 3vw;
  height: 5vw;
}
signup-orgs-list-item-team > div.mobile > span.team-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 10vw);
}
.create-edit-team-contest {
  padding: 15px;
}
.create-edit-team-contest input.form-control {
  border-radius: 4px;
}
.create-edit-team-contest input.form-control[readonly] {
  background-color: #f9f9f9;
}
.create-edit-team-contest button.btn {
  border-radius: 4px;
}
.create-edit-team-contest .close {
  margin-top: -10px;
  margin-right: 0;
  font-size: 15px;
  display: inline-block;
  width: 100%;
  text-align: right;
  margin-bottom: 5px;
}
.team-contest-details {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #ececec;
}
.team-contest-details .contest-dates {
  font-size: 11px;
  margin-top: 10px;
  display: block;
  color: #888;
}
.team-contest-details .contest-stats-description {
  margin-top: 10px;
  display: block;
  font-size: 11px;
  color: #cdcdcd;
}
.team-contest-details .stats-item {
  padding: 0;
}
.team-contest-details .stats-item:not(:last-of-type) {
  border-right: 1px solid #cdcdcd;
}
@media (max-width: 991px) {
  .team-contest-details .stats-item {
    margin-top: 10px;
  }
}
@media (max-width: 991px) {
  .team-contest-details .contest-title {
    text-align: center;
  }
}
.team-contests-list .panel-heading {
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  color: #444448;
}
.path-filters-modal .selected-item-icon {
  width: 16px;
  vertical-align: sub;
}
.path-filters-modal .modal-body {
  max-height: 250px;
  overflow-y: auto;
}
.org-subscription-channels-list {
  border: 1px solid #afafaf;
}
.org-subscription-channels-list .table-header {
  background-color: #003;
  color: #fff;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 862px) {
  .org-subscription-channels-list .table-header {
    font-size: 11px;
  }
}
.org-subscription-channels-list .table-header .dropdown {
  background: none;
}
.org-subscription-channels-list .table-header .dropdown .dropdown-menu {
  width: 250px;
}
@media (max-width: 1080px) {
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu.rightmost {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) and (max-width: 862px) {
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu,
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu .btn,
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu h5 {
    font-size: 11px;
  }
}
@media (max-width: 610px) {
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu {
    width: 150px;
  }
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu,
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu .btn,
  .org-subscription-channels-list .table-header .dropdown .dropdown-menu h5 {
    font-size: 10px;
  }
}
.org-subscription-channels-list .table-header span.glyphicon {
  vertical-align: sub;
}
.org-subscription-channels-list .table-header .active-column {
  color: dodgerblue;
}
@media (max-width: 610px) {
  .org-subscription-channels-list .table-header > div {
    padding-left: 0;
    font-size: 10px;
  }
}
.org-subscription-channels-list .inner-scroll-150 {
  background-color: #f5f4f4;
}
.org-subscription-channels-list .table-item {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 863px) and (min-width: 768px) {
  .org-subscription-channels-list .table-item > div {
    font-size: 11px;
  }
}
@media (max-width: 610px) {
  .org-subscription-channels-list .table-item > div {
    padding-left: 0;
    font-size: 10px;
  }
}
.org-subscription-channels-list .table-item:nth-child(even) {
  background-color: #d8d8d8;
}
.org-subscription-channels-list .table-item:nth-child(odd) {
  background-color: #fff;
}
.org-subscription-details {
  padding-bottom: 300px;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .org-subscription-details {
    padding-left: 15px;
  }
}
.org-subscription-details .subscription-name {
  font-family: 'AvenirNextLTPro Bold';
  color: #4a4a4a;
  margin-top: 40px;
}
.org-subscription-details textarea {
  margin-top: 30px;
  resize: none;
  height: 100px;
  width: 100%;
}
.org-subscription-details iframe {
  width: 100%;
}
.org-subscription-details .table-label {
  color: #575757;
  margin-bottom: 15px;
}
.org-subscription-edit .subscription-name {
  font-family: 'AvenirNextLTPro Bold';
  color: #4a4a4a;
  margin-top: 40px;
}
.org-subscription-edit .edit-subscription-form .existing-channel {
  padding: 10px 0;
}
@media (max-width: 863px) {
  .org-subscription-edit .edit-subscription-form .existing-channel {
    font-size: 10px;
  }
}
.org-subscription-edit .edit-subscription-form .existing-channel:nth-child(odd) {
  background-color: #d7d7d7;
}
.org-subscription-edit .edit-subscription-form .existing-channel .delete-channel-icon {
  float: right;
  margin-top: 2px;
  color: #e21927;
}
.org-subscription-edit .edit-subscription-form .drop-channel-space {
  border: 1px dotted #d7d7d7;
  text-align: center;
  height: 200px;
}
.org-subscription-edit .edit-subscription-form .drop-channel-space.dndDragover {
  opacity: 0.7;
}
.org-subscription-edit .edit-subscription-form .drop-channel-space h4 {
  font-family: 'AvenirNextLTPro Bold';
  margin-top: 60px;
  color: #4c4c4c;
}
.org-subscription-edit .edit-subscription-form .drop-channel-space li.dndPlaceholder {
  display: none;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid {
  padding: 0;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid > div.list-header {
  display: block;
  width: 100%;
  background-color: rgba(229, 229, 229, 0.3);
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 80px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 0;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid info-message > span,
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid error-message > div {
  margin-top: 24px;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid div.teams-holder {
  max-height: 250px;
  overflow-y: auto;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid.mobile > div.list-header {
  border-width: 2px;
  padding: 5vw 7.5vw 5vw 12.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 0;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid.mobile spinner > div,
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid.mobile info-message > span,
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid.mobile error-message > div {
  margin-top: 7.5vw;
}
parent-my-kids-profile-join-orgs-item-expanded > div.container-fluid.mobile div.teams-holder {
  max-height: 70vw;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid {
  padding: 0;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid > div.list-header {
  display: block;
  width: 100%;
  background-color: rgba(229, 229, 229, 0.3);
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 24px 16px 80px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 0;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid spinner > div {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid info-message > span,
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid error-message > div {
  margin-top: 24px;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid div.teams-holder {
  max-height: 250px;
  overflow-y: auto;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid.mobile > div.list-header {
  border-width: 2px;
  padding: 5vw 7.5vw 5vw 12.5vw;
  font-size: 4.4vw;
  line-height: 6.6vw;
  margin-top: 0;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid.mobile spinner > div,
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid.mobile info-message > span,
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid.mobile error-message > div {
  margin-top: 7.5vw;
}
parent-my-kids-profile-orgs-and-teams-list-item-expanded > div.container-fluid.mobile div.teams-holder {
  max-height: 70vw;
}
.team-contest-leaderboard {
  margin-top: 20px;
}
.team-contest-leaderboard iframe {
  width: 100%;
}
.team-path-skill {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #e2deef;
}
.team-path-skill .skill-name {
  color: #b6b2c2;
}
.team-path-skill .skill-badge {
  width: 100%;
}
parent-my-kids-profile-join-orgs-team > div {
  padding: 12px 24px 12px 80px;
  border-bottom: 1px solid #e5e5e5;
}
parent-my-kids-profile-join-orgs-team > div > div.action-holder {
  height: 40px;
  margin-right: 16px;
  display: inline-block;
}
parent-my-kids-profile-join-orgs-team > div > div.action-holder.action-disabled {
  opacity: 0.7;
}
parent-my-kids-profile-join-orgs-team > div > div.action-holder > img {
  margin-top: 8px;
}
parent-my-kids-profile-join-orgs-team > div > div.logo-holder {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
parent-my-kids-profile-join-orgs-team > div > div.logo-holder > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
}
parent-my-kids-profile-join-orgs-team > div > span.team-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
}
parent-my-kids-profile-join-orgs-team > div.mobile {
  padding: 3.75vw 7.5vw 3.75vw 12.5vw;
  border-width: 2px;
}
parent-my-kids-profile-join-orgs-team > div.mobile > div.action-holder {
  margin-right: 5vw;
}
parent-my-kids-profile-join-orgs-team > div.mobile > div.action-holder > img {
  margin-top: 3vw;
  height: 5vw;
}
parent-my-kids-profile-join-orgs-team > div.mobile > span.team-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 10vw);
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div {
  padding: 12px 24px 12px 80px;
  border-bottom: 1px solid #e5e5e5;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div > div.action-holder {
  height: 40px;
  margin-right: 16px;
  display: inline-block;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div > div.action-holder.action-disabled {
  opacity: 0.7;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div > div.action-holder > img {
  margin-top: 8px;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div > div.logo-holder {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div > div.logo-holder > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div > span.team-name {
  color: #333333;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div.mobile {
  padding: 3.75vw 7.5vw 3.75vw 12.5vw;
  border-width: 2px;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div.mobile > div.action-holder {
  margin-right: 5vw;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div.mobile > div.action-holder > img {
  margin-top: 3vw;
  height: 5vw;
}
parent-my-kids-profile-orgs-and-teams-list-item-team > div.mobile > span.team-name {
  font-size: 4.4vw;
  line-height: 12.5vw;
  max-width: calc(100% - 10vw);
}
