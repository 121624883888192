player-freestyle-detail-header > div {
  padding: 25px 0;
  position: relative;

  h3 {
    color: #fff;
  }

  &.active {
    background-color: #93CB52;
  }
  &.pending {
    background-color: #F7A722;
  }
  &.scored {
    background-color: #F7A722;
  }
  &.declined {
    background-color: #DA1F22;
  }
  &.ready-to-be-judged {
    background-color: #9B9C9B;
  }
  &.draw {
    background-color: #F7AA47;

    h3 {
      color: #ED8D0A;
    }
  }

  .header-text {
    padding-top: 3%;
  }

  @media (max-width: 1023px) {
    .header-text {
      padding-top: 1.2%;
    }

    h3 {
      font-size: 18px;
    }
  }

  img.bottom-arrow {
    position: absolute;
    width: 20px;
    bottom: -9px;
    height: 10px;
  }

  .arrow-player {
    left: 15%;
  }

  .arrow-non-player {
    left: 85%;
  }
}