.myKids {
  border: 1px solid #DBDBEA;

  .headerWrapper {
    background-color: #E4E4EF;
    padding-top: 6px;
    height: 40px;
    font-weight: bold;
  }

  .content {
    background: #fff;
    padding: 15px 10px;
  }

  .kid {
    border-bottom: 1px solid #f7f7fd;
    padding-bottom: 10px;
    &:nth-last-of-type(1) {
      border-bottom: none;
    }
    @media (max-width: 1440px) {
      font-size: 12px;
    }
  }

  .plusButton {
    border: none;
    background: none;

    .plus {
      margin-right: 3px;
    }
  }

  .namesWrapper {
    top: 5px;
    text-align: left;
    word-break: break-all;

    .userName {
      color: lightgray;
      font-size: 14px;
    }
  }

  .my-kids-clickable {
    text-align:center;
  }

  .firstName:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    .headerWrapper {
      font-size: 14px;
    }

    .namesWrapper {
      .firstName {
        font-size: 14px
      }

      .userName {
        font-size: 11px;
      }
    }
  }

  @media (min-width: 1280px) {
    font-size: 16px;
    .img-circle {
      width: 45px;
      height: 45px;
    }
  }
} //myKids
