.topya-tv-widget {
  .container {
    max-width: 1200px;

    .height-holder {
      padding-bottom: 86%;
    }
  }

  .addthis-icon {
    background-color: #6aea00;
    width: 36px;
    height: 36px;
    padding: 5px;
    &:not(.disabled-item) {
      cursor: pointer;
    }
  }

  .no-videos-info {
    width: 100%;
    padding-top: 40%;
    padding-bottom: 40%;
    text-align: center;
  }

  .tv-header {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 80px;
    @media (max-width: 425px) {
      height: 130px;
    }
    & > div {
      height: 80px;
      @media (max-width: 425px) {
        height: 60px;
      }
    }

    .topya-tv-logo, .primary-sponsor-logo {
      max-width: 100%;
      margin-top: 40px;
      transform: translateY(-50%);
      max-height: 50px;
    }

    .channel-switch {
      text-align: center;
      display: inline-block;
      font-size: 12px;
      margin-right: 10px;

      span {
        display: block;
        &:nth-child(2) {
          margin-top: 2px;
        }
        &:nth-child(3) {
          margin-top: -2px;
        }
      }
    }

    .channel-name {
      display: inline-block;
      vertical-align: top;
      font-size: 15px;
      &:not(.full-width) {
        text-align: center;
      }
      &.full-width {
        @media (min-width: 426px) {
          text-align: right;
        }
        @media (max-width: 425px) {
          text-align: left;
        }
      }

      span:first-child {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }

    .channel-selector.glyphicon-chevron-down {
      vertical-align: top;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      &.chevron-rotated {
        transform: rotate(180deg);
      }
    }

    .channel-controls {
      & > span {
        margin-top: 40px;
        transform: translateY(-50%);
      }
      text-align: right;
    }

    .primary-sponsor-logo-holder {
      @media (max-width: 425px) {
        text-align: right;
      }

      @media (min-width: 426px) {
        text-align: center;
      }
    }

    @media (min-width: 768px) {
      .channel-name:not(.full-width) {
        max-width: calc(~"90% - 30px");
      }
    }

    @media (max-width: 767px) {
      .channel-name {
        font-size: 14px;
        &:not(.full-width) {
          max-width: calc(~"80% - 14px");
        }
      }

      .channel-selector {
        margin-top: 7px;
      }

      .channel-switch {
        span:nth-child(2) {
          font-size: 10px;
        }
      }
    }

    @media (max-width: 425px) {
      .channel-name {
        font-size: 12px;
      }

      .channel-controls.controls-left {
        text-align: left;
      }
    }

    .dropdown-menu {
      @media (min-width: 1200px) {
        width: 75%;
      }
      z-index: 10;

      @media (min-width: 426px) {
        right: 0;
        left: auto;
      }

      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }

  .tv-current-video {
    .pending-thumbnail-holder {
      padding-bottom: 58.75%;
      width: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .error-status-holder {
      padding-bottom: 58.75%;
      width: 100%;
      position: relative;
      span {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 40%;
        text-align: center;
        font-weight: bold;
      }
    }
    .user-avatar {
      height: 70px;
      vertical-align: top;

      @media (max-width: 767px) {
        height: 40px;
      }
    }

    .tube-info-holder {
      margin: 20px 0;
      height: 80px;

      @media (max-width: 319px) {
        padding: 0;
      }

      .tube-info {
        // color: #fff;
        margin-left: 2%;
        font-size: 12pt;
        display: inline-block;
        vertical-align: middle;
        @media (max-width: 460px) {
          max-width: calc(~"95% - 80px");
        }

        @media (min-width: 461px) {
          max-width: calc(~"85% - 40px");
        }

        @media (max-width: 767px) {
          font-size: 14px;
        }

        @media (max-width: 319px) {
          font-size: 10px;
        }

        .username {
          display: grid;
        }

        .skillname {
          display: block;
        }
      }
    }
  }

  @media(max-width: 425px) {
    .col-xxs-6 {
      width: 50%;
    }

    .col-xxs-12 {
      width: 100%;
    }
  }

  .tv-gallery {
    .gallery-holder {
      min-width: 100%;
      overflow-x: auto;
      white-space: nowrap;

      &::-webkit-scrollbar-track
      {
      	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      	background-color: #a4a4ca;
      }

      &::-webkit-scrollbar
      {
  	    height: 5px;
      	background-color: #a4a4ca;
      }

      &::-webkit-scrollbar-thumb
      {
      	background-color: #2e51a0;
      }

      .gallery-thumbnail-row {
        margin-left: -1%;
        margin-right: -1%;
      }

      .gallery-thumbnail {
        width: 20%;
        padding-bottom: 11.25%;
        display: inline-block;
        margin: 1%;
        min-width: 80px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 4px;
        }

        .thumbnail-overlay-lg {
          color: #fff;
          position: absolute;
          bottom: 4%;
          left: 7%;
          font-size: 9pt;
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 500px) {
            display: none;
          }
        }

        .thumbnail-overlay-sm {
          color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          @media (min-width: 501px) {
            display: none;
          }
        }
      }
    }
  }

  .tv-footer {
    margin-top: 20px;

    .get-app-buttons {
      font-size: 11pt;

      .btn {
        border-radius: 8px;
        width: auto;
        color: #003;
        padding: 3px;
        padding-right: 5px;
        margin-left: 5px;

        span[class^='btn-text-'] {
          display: block;
        }

        .btn-text-sm {
          font-size: 6pt;
        }

        .btn-text-lg {
          font-size: 7pt;
        }

        img {
          height: 18pt;
          margin-right: 2pt;
          vertical-align: sub;
        }
      }

      @media (min-width: 768px) {
        padding-right: 0;
      }

      @media (max-width: 767px) {
        text-align: center;
        font-size: 14px;
      }

      @media (max-width: 424px) {
        padding: 0;

        .btn {
          margin-left: 5px;
        }
      }

      @media (max-width: 374px) {
        .get-app-text {
          display: block;
          margin-bottom: 5px;
        }

        .btn {
          margin-left: 0;
        }
      }
    }

    .channel-sponsors {
      text-align: center;
      & > div {
        width: 25%;
        display: inline-block;
        height: 40px;
        text-align: center;
        img {
          margin-top: 20px;
          transform: translateY(-50%;);
          max-width: 100%;
          max-height: 40px;
        }
      }
    }

    @media (max-width: 767px) {
      &.row-eq-height {
        display: inherit;
      }

      .channel-sponsors {
        margin-top: 30px;
      }
    }
  }
}
