.top-skill-detail {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #fff;

  .margin-top-xsmall {
    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .skill-info {
    @media (min-width: 992px) {
      padding-top: 50px;
    }
    @media (max-width: 991px) {
      padding-top: 15px;
    }
    .skill-stats {
      & > * {
        display: inline-block;
      }
      h5 {
        margin-left: 5px;
      }
      i.stats-icon {
        color: #498fe1;
      }
      img.stats-icon {
        margin-left: 15px;
        vertical-align: sub;
      }
    }
  }
}
