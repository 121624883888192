.login-form {
  button.submit {
    font-size: 14px;
  }
  .btn.btn-primary {
    width: 33%;
  }
  input {
    padding-left: 35px;
  }
  .password {
    background: url(../../img/password-icon.png) no-repeat 10px 50% #fff;
  }
  .username {
    background: url(../../img/username-icon.png) no-repeat 10px 50% #fff;
  }
  input[type=text],
  input[type=password],
  button {
    height: 35px;
  }
  span {
     color: #B3B3B3;
  }
  a {
    color: dodgerblue;
  }
}

.login {
  margin-top: 30px;
}

.login.error-login {
  z-index: 200;
width: 100%;
position: absolute;
top: 30px;
}

.register-buttons {
    background: none;
    border-style: none;
}

.register-buttons img {
  width: 100%;
}

.choose-role .start-subtitle {
  margin: 4% 0 2% 0;
  color: #fff;
}

.register-new .login-form {
  input {
    background-image: none;
    margin-top: 30px;
  }

  .btn.btn-primary {
    margin-top: 30px;
    width: 100%;
  }

  span, a {
    visibility: hidden;
  }
}
