register-login {
  input:not([type="radio"]) {
    width: 100%;
    height: 30px;
    padding-left: 10px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    margin-top: 30px;
  }

  login-directive {
    .login-form button.btn.btn-primary {
      width: 100%;
      margin-top: 30px;
    }
    span, a {
      display: none;
    }
  }
}