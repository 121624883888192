parent-activity-feed-card-item-highfives > div {
  margin-top: 40px;
  display: inline-block;
  width: 100%;

  div.all-counts-holder {
    display: inline-block;
    float: right;
    margin-top: 8px;

    span.count-holder {
      span {
        color: #999;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 4px;
      }

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
}