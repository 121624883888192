player-fundraising-pledge-detail > div {
  color: #8B8B8B;
  font-weight: normal;
  margin-top: 10px;

  hr {
    border-top: 1px solid #bdbcbc;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}