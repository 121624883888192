.org-hierarchy-preview {
  .expand-icon {
    margin-right: 15px;
    &.larger-right-margin {
      margin-right: 29px;
    }
  }
  &.disabled-item a {
    cursor: default;
  }
  .list-group-item {
    text-align: left;
    &:last-child {
      border-radius: 0;
      border-bottom: none;
    }
    &:first-child {
      border-radius: 0;
    }
    &.selected {
      background-color: #e0e0e0;
    }
  }

  .list-group {
    margin-bottom: 0;
  }

  &.list-group-root {
    border-bottom: 1px solid #ddd;
    & > .list-group {
      & > div {
        & > .list-group-item {
          padding-left: 30px;
        }
        & > .list-group {
          & > div {
            & > .list-group-item {
              padding-left: 45px;
            }
            & > .list-group  > div > .list-group-item {
              padding-left: 60px;
            }
          }
        }
      }
    }
  }
}
