parent-my-kids-add-button > button.btn.btn-primary {
  width: auto;
  color: #FFFFFF;
  padding: 7px 15px 7px 11px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

  &.mobile {
    width: 100%;
    font-size: 5vw;
    line-height: 7.5vw;
    background-color: #51C8E9;
    border-radius: 1.25vw;
    margin-top: 5vw;
    padding: 2.5vw 5vw;

    & > span.plus {
      float: left;
    }
  }
}