my-players-fundraisers-filters > div {
  padding: 20px 0 10px 0;
  background: white;
  border-bottom: 2px solid #eee;

  button.btn {
    font-weight: bold;
    border-radius: 4px;
  }

  &.side-block {
    & > div {
      padding: 2px;
    }

    button.btn {
      padding: 2px;
    }
  }
}