add-role-modal > div {
  text-align: center;

  .title {
    font-family: 'AvenirNextLTPro Bold';
  }

  input.form-control,
  button.btn.get-started-btn {
    width: 250px;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  hr {
    border-width: 2px;
  }

  error-message > div {
    text-align: left;
  }
}