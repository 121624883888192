contest-reference-change-modal > div.modal-content {
  .modal-header {
    h4 {
      width: 90%;
      display: inline-block;
      line-height: 24px;
    }

    .close {
      margin: 0;
    }
  }

  .modal-footer {
    error-message > {
      .close {
        margin: 0;
      }
    }

    button.btn.btn-primary,
    button.btn.btn-primary.cancel {
      font-size: 14px;
      width: 250px;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 0px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}