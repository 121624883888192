drop-area-item > div.container-fluid {
  padding-top: 15px;
  padding-bottom: 15px;

  &.gray-background {
    background: rgba(215, 215, 215, .8);
  }

  &.white-background {
    background: rgba(255, 255, 255, .8);
  }

  .item-property {
    padding: 0 15px;
    display: inline-block;
  }
}