signup-org-list-preview-item > div.container-fluid {
  padding: 12px 24px;
  border-bottom: 1px solid #e5e5e5;

  & > span.item-name {
    color: #333333;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 40px;
    display: inline-block;
    vertical-align: top;
  }

  & > span.glyphicon.expand-indicator {
    font-size: 10px;
    color: #e5e5e5;
    line-height: 40px;
    vertical-align: top;
    float: right;
  }



  &.mobile {
    padding: 3.75vw 7.5vw;
    border-width: 2px;

    & > span.item-name {
      font-size: 4.4vw;
      line-height: 12.5vw;
      max-width: calc(~"100% - 13vw");
    }

    & > span.glyphicon.expand-indicator {
      font-size: 3vw;
      line-height: 12.5vw;
    }
  }
}

signup-org-list-preview-item > div.expanded-view {
  & > div.list-header {
    display: block;
    width: 100%;
    background-color: rgba(229,229,229,0.3);
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 24px 16px 80px;
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 0;
  }

  & > div.team {
    padding: 12px 24px 12px 80px;
    border-bottom: 1px solid #e5e5e5;

    span.team-name {
      color: #333333;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 40px;
      display: inline-block;
      vertical-align: top;
      max-width: calc(100% - 56px);
    }
  }

  &.mobile {
    & > div.list-header {
      border-width: 2px;
      padding: 5vw 7.5vw 5vw 12.5vw;
      font-size: 4.4vw;
      line-height: 6.6vw;
      margin-top: 0;
    }

    & > div.team {
      padding: 3.75vw 7.5vw 3.75vw 12.5vw;
      border-width: 2px;

      span.team-name {
        font-size: 4.4vw;
        line-height: 12.5vw;
        max-width: calc(~"100% - 10vw");
      }
    }
  }
}