parent-my-kids-profile-join-orgs-item > div.container-fluid {
  padding: 12px 24px;
  border-bottom: 1px solid #e5e5e5;

  & > div.action-holder {
    height: 40px;
    margin-right: 16px;
    display: inline-block;

    &.action-disabled {
      opacity: .7;
    }

    & > img {
      margin-top: 8px;
    }
  }

  & > div.logo-holder {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    margin-right: 16px;

    & > img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 20px;
      transform: translateY(-50%);
    }
  }

  & > span.item-name {
    color: #333333;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 40px;
    display: inline-block;
    vertical-align: top;
  }

  & > span.glyphicon.expand-indicator {
    font-size: 10px;
    color: #e5e5e5;
    line-height: 40px;
    vertical-align: top;
    float: right;
  }

  &.mobile {
    padding: 3.75vw 7.5vw;
    border-width: 2px;

    & > div.action-holder {
      margin-right: 5vw;

      & > img {
        margin-top: 3vw;
        height: 5vw;
      }
    }

    & > span.item-name {
      font-size: 4.4vw;
      line-height: 12.5vw;
      max-width: calc(~"100% - 13vw");
    }

    & > span.glyphicon.expand-indicator {
      font-size: 3vw;
      line-height: 12.5vw;
    }
  }
}