edit-product-price-and-availability-preview > div {
  div.row.preview {
    padding-top: 30px;
    padding-bottom: 30px;

    h4.view-title {
      font-weight: bold;
    }

    h2.price {
      font-family: 'AvenirNextLTPro Bold';
    }
  }

  span.inline-stats {
    display: block;

    h4.stats-label {
      display: inline-block;
      font-weight: bold;
    }

    span.stats-value {
      font-size: 18px;
      margin-left: 10px;
    }
  }
}