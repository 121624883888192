image-uploader > div {
  margin: 10px 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.2%;
  background-size: contain;
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-color: #282828;
  border-radius: 8px;
  overflow: hidden;

  h4.title {
    color: dodgerblue;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }

  img.preview {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    margin-left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}