manager-product-pricing-dropdown {
  & > div {
    width: 150px;
    display: inline;
  }

  & > div > dropdown > div.dropdown {
    width: 150px;
    height: 34px;

    button.btn {
      height: inherit;
    }

    ul.dropdown-menu {
      width: 100%;
      min-width: 0;
    }
  }
}