.store {
  &.topya-main-page-content {
    margin-top: 20px;
  }

  .alert {
    margin-top: 40px;
    text-align: center;
  }

  .product {
    padding: 5px;
    background-color: #fff;
    margin-bottom: 30px;
    &:hover {
      background-color: #f7f7fd;
    }
    img {
      max-width: 100%;
      max-height: 50px;
    }
    &:nth-of-type(2n + 1) {
      @media (min-width: 992px) {
        border-right: 1px solid #EEEEEE;
      }
    }
  }

  .border-holder {
    position: absolute;
    bottom: 2px;
    left: 2%;
    width: 96%;
    height: 1px;
    border-bottom: 1px solid #EEEEEE;
  }

  .path-back {
    margin-top: 10px;
  }

  img[name="arrow-right"] {
    width: 10px;
  }

  .arrow-container, .product-header {
    text-align: center;
  }

  .product-type {
    margin-top: 5px;
    color: #DBDBEA;
  }

  .product-name {
    padding: 0 5px;
    text-align: center;
  }

  .btn {
    font-size: 10px;
  }

  .animate-if.ng-enter, .animate-if.ng-leave {
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  }

  .animate-if.ng-enter,
  .animate-if.ng-leave.ng-leave-active {
    left: -100%;
  }

  .animate-if.ng-leave,
  .animate-if.ng-enter.ng-enter-active {
    left: 0;
  }

  .error-message {
    width: 100%;
    margin-top: 20px;
  }

  .items-holder {
    max-height: 300px;
    overflow-y: auto;
  }

  .recommendation {
    margin-top: 3%;
    margin-left: 1%;
  }

  .video-holder {
    margin-top: 3%;
    margin-bottom: 1%;
    width: 100%;
    padding-bottom: 50%;
    background-color: #a7b1c1;
    position: relative;
  }

  .freestyle-name {
    margin-top: 2%;
    margin-left: 1%;
  }

  .freestyle-stats {
    margin-left: 1%;
    color: #a7b1c1;
  }

  .active-tab {
    color: dodgerblue;
  }

  .featured-item {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 5%;
  }

  .round-image-holder {
    width: 90%;
    margin-left: 10%;
  }

  .free-banner {
    position: absolute;
    left: 0;
    top: 10%;
    z-index: 300;
  }

  .headers {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-group {
    text-align: left;
  }

  .header-purchased {
    float: right;
  }

  .header-all {
    display: inline-block;
  }

  &.recommend-background {
    background-color: @coachRecommendedItemBackgroundColor;
    .round-image-holder {
      background-color: @coachRecommendedItemImageBackgroundColor;
    }
  }

  &.assign-background {
    background-color: @coachAssignedItemBackgroundColor;
    .round-image-holder {
      background-color: @coachAssignedItemImageBackgroundColor;
    }
  }

  .arrow-right {
    margin-top: 70%;
    float: right;
  }

  .video-parent {
    width: 100%;
    padding-bottom: 56.2%;
    height: 0;
    position: relative;
    background-color: #30303F;
  }

  &.store-skill {
    padding-top: 3%;
    padding-bottom: 3%;
    .skill-back img {
      vertical-align: top;
    }
    .skill-video-holder {
      margin: 3% 0;
    }
    .description {
      margin-top: 8%;
    }
    .description-catergory {
      color: #cbcbcb;
    }
    .badge-img {
      max-width: 50px;
      float: right;
    }
    .status {
      margin-bottom: 6%;
    }
    .created-by-img {
      max-width: 60px;
    }
  }
  &.featured-border-top {
    border-top: 1px solid #dbdbea;
  }

  .store-filter {
    padding: 10px 0;
    margin-bottom: 30px;
    background-color: #fff;
    border-top: 1px solid #DBDBEA;
    &.filter-all-available {
      text-align: center;
    }
    &.filter-purchased {
      text-align: right;
      padding-right: 15px;
    }
    h4 {
      display: inline;
    }
  }

  .store-filter, .featured-filter {
    span {
      cursor: pointer;
    }
  }

  .popover {
    min-width: 200px;
    img {
      max-width: 100%;
    }
    .item {
      padding-top: 5px;
      padding-bottom: 20px;
      &:hover {
        background-color:#f7f7fd;
      }
    }
  }

  .store-popover {
    text-align: center;
    .scroll-holder {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      .item {
        .col-xs-9 {
          text-align: left;
        }
        img {
          max-height: 40px;
          display: inline;
        }
      }
    }
  }

  .bought h5 {
    margin-top: 9px;
  }

  &.purchase {
    background-color: #f7f7fd;
    top: 150px;

    .scroll-kids {
      overflow-y: auto;
    }

    img {
      max-width: 100%;
    }

    .purchase-header {
      margin-top: 10px;
    }

    .purchase-for {
      margin-top: 3%;
      h5 {
        cursor: pointer;
      }
    }

    h6 {
      margin-top: 10px;
    }

    hr {
      margin-top: 10px;
      border-top: 1px solid #DAD5D5;
    }

    .kid {
      margin-bottom: 10px;
      height: 80px;
    }
  }//purchase

  .back-button {
    margin: 20px 0;

    span:nth-of-type(2) {
      margin-top: 3px;
      display: inline-block;
      margin-left: 10px;
    }
  }

  .path-header-lower {
    margin-top: 10px;
  }

  .path-header {
    border-bottom: 1px solid #DBDBEA;
    padding: 15px 0;
  }

  .path-info {
    margin-top: 30px;
    margin-bottom: 40px;
    .skill {
      padding: 2% 0;
      .badge-img {
        width: 100%;
        max-width: 40px;
      }
      .points-count h5 {
        color: #CF011A;
      }
    }
  }

  .path-desc {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #CBCBCB;
  }

  .path-info .skill .points-count, &.store-skill .points-count {
    display: inline-flex;
    margin-left: 5px;
    vertical-align: sub;
  }

  .blue-text {
    color: dodgerblue;
  }

  .bought {
    border: 1px solid green;
    color: green;
    cursor: default;
    text-align: center;
    min-height: 34px
  }

  @media (max-width: 1439px) {
    .purchase-for h5, .bought h5 {
      font-size: 10px;
    }
  }

  @media (min-width: 1280px) {
    .product {
      min-height: 130px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    .product-name h5 {
      font-size: 13px;
    }

    .product {
      min-height: 130px;
    }
  }

  @media (max-width: 1023px) {
    .product {
      min-height: 110px;
    }

    .btn {
      font-size: 8px;
    }

    .product-name h5 {
      font-size: 12px;
    }

  }

  .featured-filter {
    border-top: 1px solid #DBDBEA;
    padding: 10px;
    span, h4 {
      display: inline;
    }
  }
}
